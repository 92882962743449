import * as React from 'react';

import { FormGroup, InputGroup } from '@dabapps/roe';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';

export interface IInputWrapperProps extends React.HTMLProps<JSX.Element> {
  errors?: FormErrors;
}

export default class SearchableCenterInputWrapper extends React.PureComponent<
  IInputWrapperProps,
  void
> {
  public render() {
    const { children, errors } = this.props;
    return (
      <div className="margin-vertical-large searchable-centre-switcher">
        <FormGroup className="display-inline">
          <label className="bold centre-label">Centre:</label>
          <InputGroup>
            {children}
            <FormErrorsRenderer
              formErrors={errors}
              errorKey="non_field_errors"
            />
            <FormErrorsRenderer formErrors={errors} errorKey="current_centre" />
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}
