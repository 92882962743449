import {
  anyPending,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { LOAD_ITEM, UPDATE_ITEM } from '../../items/actions';
import { canAssignIQAs, canSelectClassTeacher } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IItemsState, itemsModule } from '../../reducers/items';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { IClassRecord } from '../../store/data-types/classes';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { FormErrors } from '../../utils';
import Loading from '../loading';
import Term from '../terminology';
import ClassForm, { IClassFormData } from './class-form';
import { IPostTransformFormClassData } from './create-class-page';

const { actions: { loadItem, updateItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;
import { FontAwesome } from 'react-inline-icons';
const { IconCheck, IconTimes } = FontAwesome;

interface IEditClassPageProps {
  classId: string;
  classObject: IClassRecord | null;
  errors: FormErrors | undefined;
  loading: boolean;
  qualifications: List<IQualificationRecord>;
  students: List<ISimpleUser>;
  teachers: List<ISimpleUser>;
  internalQualityAssurers: List<ISimpleUser>;
  updated: boolean;
  updating: boolean;
  user: IProfile;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
  loadItem(type: keyof IItemsState, itemId: string): AxiosPromise;
  updateItem(
    type: keyof IItemsState,
    itemId: string,
    data: IPostTransformFormClassData
  ): void;
}

export class EditClassPage extends React.PureComponent<
  IEditClassPageProps,
  void
> {
  public componentWillMount() {
    const userTypeFilters = (userType: string, centre: string) =>
      Map({ user_type: userType, centre });
    this.props.getAllCollection('courses', {});
    this.props.loadItem('classes', this.props.classId).then(response => {
      const centre = response.data.centre;
      this.props.getAllCollection(
        'users/minimal',
        { filters: userTypeFilters('student', centre) },
        'students'
      );
      this.props.getAllCollection(
        'users/minimal',
        { filters: userTypeFilters('teacher', centre) },
        'teachers'
      );
      this.props.getAllCollection(
        'users/minimal',
        { filters: userTypeFilters('internal_quality_assurer', centre) },
        'internal_quality_assurers'
      );
    });
  }

  public render() {
    const {
      classObject,
      errors,
      loading,
      qualifications,
      students,
      teachers,
      internalQualityAssurers,
      updated,
      updating,
      user,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!classObject) {
      return (
        <Alert className="error">
          <p>
            Failed to load <Term>Class</Term>
          </p>
        </Alert>
      );
    }

    const selectedStudents = narrowToRecordArray(classObject.students).map(
      each => each.id
    );
    const selectedTeachers = narrowToRecordArray(classObject.teachers).map(
      each => each.id
    );
    const selectedIQAs = narrowToRecordArray(
      classObject.internal_quality_assurers
    ).map(each => each.id);

    const initialValues = _.assign({}, classObject.toJS(), {
      students: Set<string>(selectedStudents),
      teachers: Set<string>(selectedTeachers),
      internal_quality_assurers: Set<string>(selectedIQAs),
    });
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            Edit <Term>Class</Term>: {classObject.name}
          </h2>
        </ContentBoxHeader>
        {errors && (
          <Alert className="error">
            <p>
              Please correct the errors as highlighted in red on the form below{' '}
              <IconTimes className="icon icon-small icon-error" />
            </p>
          </Alert>
        )}
        {updated && (
          <Alert className="success">
            <p>
              <Term>Class</Term> updated successfully{' '}
              <IconCheck className="icon icon-small icon-success" />
            </p>
          </Alert>
        )}
        <ClassForm
          profile={user}
          onSubmit={this.updateClass.bind(this)}
          loading={loading}
          updating={updating}
          qualifications={qualifications}
          students={students}
          teachers={teachers}
          internalQualityAssurers={internalQualityAssurers}
          errors={errors}
          initialValues={initialValues}
          modeOptions={{
            currentUser: user,
            hasQualification: !!classObject.qualification,
            qualificationId: classObject.qualification,
            mode: 'EDITING',
            canSelectTeachers: canSelectClassTeacher(user),
          }}
        />
      </ContentBox>
    );
  }

  private updateClass(data: IClassFormData) {
    const { user } = this.props;

    const dataToSubmit: IPostTransformFormClassData = _.extend({}, data, {
      teachers: !canSelectClassTeacher(user)
        ? undefined
        : data.teachers ? data.teachers.toJS() : [],
      internal_quality_assurers: !canAssignIQAs(user)
        ? undefined
        : data.internal_quality_assurers
          ? data.internal_quality_assurers.toJS()
          : [],
    });
    this.props.updateItem('classes', this.props.classId, dataToSubmit);
  }
}

interface IRouteProps {
  params: {
    id: string;
  };
}
function mapStateToProps(
  { collectionsOld, itemsOld, profile, responses }: IStore,
  { params: { id: classId } }: IRouteProps
) {
  return {
    classId,
    classObject: itemsOld.classes,
    errors: getFormErrors(responses, UPDATE_ITEM, 'classes'),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'users/minimal'],
      [GET_COLLECTION, 'courses'],
      [LOAD_ITEM, 'classes'],
    ]),
    qualifications:
      getCollectionItems(collectionsOld.courses) ||
      List<IQualificationRecord>(),
    students:
      getCollectionItems(collectionsOld['users/minimal'], 'students') ||
      List<IProfile>(),
    teachers:
      getCollectionItems(collectionsOld['users/minimal'], 'teachers') ||
      List<IProfile>(),
    internalQualityAssurers:
      getCollectionItems(
        collectionsOld['users/minimal'],
        'internal_quality_assurers'
      ) || List<IProfile>(),
    updated: hasSucceeded(responses, UPDATE_ITEM, 'classes'),
    updating: isPending(responses, UPDATE_ITEM, 'classes'),
    user: profile,
  };
}
export default connect(mapStateToProps, {
  getAllCollection,
  loadItem,
  updateItem,
})(EditClassPage);
