import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { FormGroup } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import { WrappedFieldInputProps } from 'redux-form';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';

import { REQUEST_RATE_MS } from '../../constants';
import { collectionsModule } from '../../reducers/collections';
import { IStore } from '../../store';
import { ICentre } from '../../store/data-types/centres';
import { IRoleRecord } from '../../store/data-types/roles';
import { updateRoleAtIndex } from './role-picker';

const { actions: { getCollection } } = collectionsModule;

const CENTRE_PICKER = 'CENTRE_PICKER';

function getName(index: number) {
  return `${CENTRE_PICKER}-${index}`;
}

interface IExternalProps {
  input: WrappedFieldInputProps;
  roles: List<IRoleRecord>;
  index: number;
  value?: string;
  centreId?: string;
  disabled?: boolean;
  updateItem(option: ReactSelect.Option): IRoleRecord;
}

interface IStateProps {
  centres: List<ICentre>;
  searching: boolean;
}

interface IDispatchProps {
  getCollection(
    type: 'centres',
    options: ICollectionOptions,
    tag: string
  ): void;
}

type ReactSelectState = Readonly<{
  isFocused?: boolean;
}>;

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class SearchableCentrePicker extends React.PureComponent<IProps, void> {
  private reactSelectRef: ReactSelect;
  private setReactSelectRef: (reactSelect: ReactSelect) => ReactSelect;

  public constructor(props: IProps) {
    super(props);
    this.searchCentres = _.debounce(this.searchCentres, REQUEST_RATE_MS);
    this.setReactSelectRef = (reactSelect: ReactSelect) =>
      (this.reactSelectRef = reactSelect);
  }

  public componentWillMount() {
    this.searchCentres('', this.props.centreId);
  }

  public render() {
    const {
      centres,
      searching,
      updateItem,
      input,
      roles,
      index,
      value,
      disabled,
    } = this.props;

    const placeholder = 'Start typing to search and select a centre...';
    const centreOptions = centres.map(each => ({
      value: each.id,
      label: `${each.osms_data.name} (${each.osms_data.centre_number})`,
    }));

    let thisInputSearching = false;
    if (this.reactSelectRef) {
      thisInputSearching =
        searching &&
        Boolean((this.reactSelectRef.state as ReactSelectState).isFocused);
    }

    return (
      <FormGroup className="searchable-centre-picker-wrapper">
        <label>Centre</label>
        <ReactSelect
          ref={this.setReactSelectRef}
          className="searchable-centre-picker"
          options={centreOptions.toArray()}
          value={value || ''}
          multi={false}
          onInputChange={searchText => this.searchCentres(searchText)}
          onChange={(option: ReactSelect.Option) =>
            option &&
            updateRoleAtIndex(input, roles, index, updateItem(option))}
          isLoading={thisInputSearching}
          placeholder={placeholder}
          clearable
          disabled={disabled}
        />
      </FormGroup>
    );
  }

  private searchCentres(search: string, centreId?: string) {
    const filters = centreId ? Map({ id: centreId }) : undefined;
    this.props.getCollection(
      'centres',
      {
        search,
        filters,
      },
      getName(this.props.index)
    );
  }
}

function mapStateToProps(
  { collectionsOld, responses }: IStore,
  props: IExternalProps
): IExternalProps & IStateProps {
  return {
    ...props,
    centres: getCollectionItems(
      collectionsOld.get('centres'),
      getName(props.index)
    ),
    searching: isPending(responses, GET_COLLECTION, 'centres'),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(SearchableCentrePicker);
