import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';

import { setQualification } from '../../actions/dashboard';
import { loadProgressForCourse } from '../../actions/qualifications';
import {
  CENTRE_ADMIN,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  userHasRoles,
} from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
// tslint:disable-next-line:no-unused-variable
import { IProfile } from '../../store/data-types/profile';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';
import CourseSummary from './course-summary';
import { getComponentName } from './course-summary-student';
import QualificationMenu from './qualification-menu';

export const DASHBOARD_QUALIFICATION_OPTIONS =
  'courses/dashboard-qualification-options';

const { actions: { getAllCollection } } = collectionsModule;

interface IRouteParams {
  student: string;
}

interface IExternalProps {
  params?: IRouteParams;
}

interface IStateProps {
  courses: List<IDashboardQualificationOption>;
  profile: IProfile;
  currentCourse?: IDashboardQualificationOption;
  nextCourse?: IDashboardQualificationOption | null;
  remainingCourses?: List<IDashboardQualificationOption> | null;
}

interface IDispatchProps {
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  loadProgressForCourse(
    course: IDashboardQualificationOption,
    tag: string,
    studentId?: string
  ): void;
  setQualification(qualification: IDashboardQualificationOption): void;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class DashboardQualifications extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    const { params, profile } = this.props;
    const filters = params
      ? Map({ user: params.student })
      : userHasRoles(
          profile,
          Set.of(
            EXTERNAL_QUALITY_ASSURER,
            INTERNAL_QUALITY_ASSURER,
            CENTRE_ADMIN
          )
        )
        ? undefined
        : Map({ user: profile.id });
    this.props.getAllCollection(
      DASHBOARD_QUALIFICATION_OPTIONS,
      { filters },
      DASHBOARD_QUALIFICATION_OPTIONS
    );
  }

  public render() {
    const { currentCourse, params, nextCourse, remainingCourses } = this.props;

    return (
      <div>
        {currentCourse && (
          <ContentBox>
            <ContentBoxHeader>
              <h2 className="qualification-picker-title">
                {currentCourse.name}
              </h2>
              {nextCourse && (
                <a
                  href=""
                  id={nextCourse.id}
                  onClick={this.setQualification}
                  className="display-inline-block margin-left-large margin-bottom-large"
                >
                  {nextCourse.name}
                </a>
              )}
              {remainingCourses && remainingCourses.count() ? (
                <QualificationMenu
                  courses={remainingCourses}
                  setQualification={this.setQualification}
                />
              ) : null}
            </ContentBoxHeader>
            <CourseSummary
              course={currentCourse}
              studentId={params && params.student}
              loadCourseProgressForStudent={this.loadCourseProgressForStudent}
              loadCourseProgressForTeacher={this.loadCourseProgressForTeacher}
            />
          </ContentBox>
        )}
      </div>
    );
  }

  private setQualification = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { courses } = this.props;
    const selectedQualificationId = event.currentTarget.id;
    const selectedQualification =
      courses && courses.find(course => course.id === selectedQualificationId);
    if (selectedQualification) {
      this.props.setQualification(selectedQualification);
      this.loadCourseProgress(selectedQualification);
    }
  };

  private loadCourseProgress = (course: IDashboardQualificationOption) => {
    const { params } = this.props;
    const studentId = params && params.student;
    if (course.is_filtered_student) {
      this.loadCourseProgressForStudent(course, studentId);
    } else {
      this.loadCourseProgressForTeacher(course);
    }
  };

  private loadCourseProgressForStudent = (
    course: IDashboardQualificationOption,
    studentId?: string
  ) => {
    this.props.loadProgressForCourse(
      course,
      getComponentName(course.id),
      studentId
    );
  };

  private loadCourseProgressForTeacher = (
    course: IDashboardQualificationOption
  ) => {
    this.props.getAllCollection(
      'courses/student-progress',
      { filters: Map({ qualification: course.id }) },
      getComponentName(course.id)
    );
  };
}

export function mapStateToProps(
  {
    collectionsOld,
    profile,
    dashboardQualifications: { currentQualification },
  }: IStore,
  props: IExternalProps
): IExternalProps & IStateProps {
  const courses = getCollectionItems(
    collectionsOld[DASHBOARD_QUALIFICATION_OPTIONS],
    DASHBOARD_QUALIFICATION_OPTIONS
  );

  const currentCourse = currentQualification || courses.get(0);
  let nextCourse = null;
  let remainingCourses = null;

  if (currentCourse && courses.count() > 1) {
    remainingCourses = courses.filter(course => course.id !== currentCourse.id);
    nextCourse = remainingCourses.get(0);
    remainingCourses = remainingCourses.slice(1);
  }

  return {
    ...props,
    profile,
    currentCourse,
    nextCourse,
    remainingCourses,
    courses,
  };
}

export default connect<
  IStateProps,
  IDispatchProps,
  IExternalProps
>(mapStateToProps, {
  getAllCollection,
  loadProgressForCourse,
  setQualification,
})(DashboardQualifications);
