import { List } from 'immutable';
import * as roleActions from '../actions/roles';
import {
  ICentreOption,
  IRoleInput,
  // tslint:disable-next-line:no-unused-variable
  IRoleOption,
  // tslint:disable-next-line:no-unused-variable
  IRoleRecord,
  RoleRecord,
} from '../store/data-types/roles';
import { IAction } from '../types';

export function roles(
  state = List(),
  action: IAction<any, void>
): List<IRoleRecord> {
  switch (action.type) {
    case roleActions.GET_ROLES.FAILURE:
      return List();
    case roleActions.GET_ROLES.SUCCESS:
      return List(action.payload.roles).map((role: IRoleInput) =>
        RoleRecord(role)
      );
    default:
      return state;
  }
}

export interface ICurrentRoleAndCentreOptions {
  centreOptions?: ReadonlyArray<ICentreOption>;
  roleOptions?: ReadonlyArray<IRoleOption>;
}

export function currentRoleAndCentreOptions(
  state: ICurrentRoleAndCentreOptions = {},
  action: IAction<any, any>
) {
  switch (action.type) {
    case roleActions.GET_CURRENT_CENTRE_OPTIONS.SUCCESS:
      return {
        ...state,
        centreOptions: action.payload.results,
      };
    case roleActions.GET_CURRENT_ROLE_OPTIONS.SUCCESS:
      return {
        ...state,
        roleOptions: action.payload.results,
      };
    default:
      return state;
  }
}
