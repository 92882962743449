import * as classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { clickOutside } from '../utils';

interface IProps {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  className?: string | null | false;
  style?: {};
  display: boolean;
  toggleComponent: React.ReactElement<any>;
  onClickOutside?: (event: any) => void;
}

interface IState {
  top: number;
  left: number;
}

// FIXME: move this component to Roe
export default class Popover extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    // NOTE: this component is stateful as it is marked for moving to Roe and therefore cannot depend on redux state
    this.state = {
      top: 0,
      left: 0,
    };

    this.onWindowClick = this.onWindowClick.bind(this);
  }

  public componentDidUpdate() {
    this.updateDisplay();
  }

  public componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  public render() {
    const {
      children,
      className,
      display,
      top,
      right,
      bottom,
      left,
      toggleComponent,
      onClickOutside, // Removing from remaining props
      ...remainingProps,
    } = this.props;

    const { top: stateTop, left: stateLeft } = this.state;

    const myClassNames = classNames(
      'popover',
      top && 'top',
      right && 'right',
      bottom && 'bottom',
      left && 'left',
      className
    );

    return (
      <span className="popover-relative">
        {toggleComponent}
        {display && (
          <div
            className="popover-position"
            style={{
              position: 'fixed',
              overflow: 'visible',
              zIndex: 1,
              top: stateTop,
              left: stateLeft,
            }}
          >
            <div {...remainingProps} className={myClassNames}>
              {children}
            </div>
          </div>
        )}
      </span>
    );
  }

  private updateDisplay() {
    if (this.props.display) {
      const node = ReactDOM.findDOMNode(this);

      if (node) {
        const { bottom } = this.props;
        const rect = node.getBoundingClientRect();

        const stateTop = Math.floor(bottom ? rect.bottom : rect.top);
        const stateLeft = Math.floor(rect.left + rect.width / 2);

        if (stateTop !== this.state.top || stateLeft !== this.state.left) {
          this.setState({
            top: stateTop,
            left: stateLeft,
          });
        }
      }
    }
  }

  private onWindowClick(event: MouseEvent) {
    const thisElement = ReactDOM.findDOMNode(this);
    const { onClickOutside } = this.props;

    if (typeof onClickOutside === 'function') {
      clickOutside(thisElement, event.target as HTMLElement, () =>
        onClickOutside(event)
      );
    }
  }
}
