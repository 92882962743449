import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { LOAD_ITEM } from '../../items/actions';

import { GET_ROLES, getRoles } from '../../actions/roles';
import { EDIT_USER, editUser, IUserData } from '../../actions/users';
import {
  canEditOwnRoles,
  canManageStudentQuotas,
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  userHasRoles,
} from '../../permissions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import {
  IProfile,
  ProfileRecord,
  USER_STATUSES,
} from '../../store/data-types/profile';
import { IRoleRecord } from '../../store/data-types/roles';
import { RoleRecord } from '../../store/data-types/roles';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import Loading from '../loading';
import IfCan from '../permissions/if-can';
import { transformFormData } from './add';
import AddUserForm, { INITIAL_ROLES, IUserFormData } from './add-user-form';
import ToggleStatusButton from './toggle-status-button';

const { actions: { loadItem } } = itemsModule;

export const EDIT_USER_TAG = 'ADD_USER';

interface IProps {
  formErrors: FormErrors;
  loading: boolean;
  saving: boolean;
  success: boolean;
  profile: IProfile;
  loggedInUser: IProfile;
  params: {
    id: string;
  };
  user: IProfile | null;
  roles: List<IRoleRecord>;
  editUser(id: string, data: IUserData, tag: string): AxiosPromise;
  loadItem(type: keyof IItemsState, id: string): AxiosPromise;
  getRoles(id: string): AxiosPromise;
}

export class EditUser extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.editUser = this.editUser.bind(this);
    this.loadUserAndGetRoles = this.loadUserAndGetRoles.bind(this);
  }

  public componentWillMount() {
    this.loadUserAndGetRoles();
  }

  public render() {
    const {
      formErrors,
      profile,
      loading,
      saving,
      user,
      roles,
      success,
      loggedInUser,
    } = this.props;

    const initialValues = user
      ? {
          username: user.osms_data.username,
          birth_date: user.osms_data.birth_date,
          email: user.osms_data.email,
          family_name: user.osms_data.family_name,
          gender: user.osms_data.gender,
          given_name: user.osms_data.given_name,
          middle_name: user.osms_data.middle_name,
          one_time_password: user.osms_data.one_time_password,
          roles: roles || INITIAL_ROLES,
        }
      : {};

    const initialValuesWithQuotaAdjust =
      canManageStudentQuotas(loggedInUser) && user
        ? {
            ...initialValues,
            assets_quota_mb: user.assets_quota_mb,
            assets_quota_used_mb: user.assets_quota_used_mb,
          }
        : initialValues;

    const isArchived = user
      ? user.status === USER_STATUSES.ARCHIVED
      : undefined;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large display-inline-block">
            {isArchived ? 'View' : 'Edit'} User
          </h2>
          {isArchived && (
            <span>
              {' (This user is archived'}
              <IfCan
                can={userHasRoles(
                  profile,
                  Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
                )}
              >
                {user &&
                  user.id !== profile.id && (
                    <span>
                      {' - '}
                      <ToggleStatusButton
                        user={user}
                        onComplete={this.loadUserAndGetRoles}
                        inactiveStatusKey={USER_STATUSES.ARCHIVED}
                      />
                    </span>
                  )}
              </IfCan>
              {')'}
            </span>
          )}
        </ContentBoxHeader>
        {user && !loading ? (
          <AddUserForm
            key={(user && user.id) || ''}
            userId={(user && user.id) || ''}
            profile={profile}
            errors={formErrors}
            onSubmit={this.editUser}
            loading={saving}
            disabled={saving || isArchived}
            initialValues={initialValuesWithQuotaAdjust}
          >
            {!isArchived && (
              <ContentBoxFooter>
                <FormErrorsRenderer
                  formErrors={formErrors}
                  errorKey="non_field_errors"
                />
                {loading && <Loading />}
                {success && (
                  <Alert className="success text-align-left">
                    <p>Updated!</p>
                  </Alert>
                )}
                <SpacedGroup block className="margin-vertical-large">
                  <Button type="submit" disabled={loading}>
                    Save
                  </Button>
                  <Link to="/users/">
                    ...or cancel and return to user listing
                  </Link>
                </SpacedGroup>
              </ContentBoxFooter>
            )}
          </AddUserForm>
        ) : (
          <Loading />
        )}
        {isArchived && (
          <ContentBoxFooter>
            <SpacedGroup block className="margin-vertical-large">
              <Link to="/users/">return to user listing</Link>
            </SpacedGroup>
          </ContentBoxFooter>
        )}
      </ContentBox>
    );
  }

  private loadUserAndGetRoles() {
    this.props.loadItem('users', this.props.params.id);
    this.props.getRoles(this.props.params.id);
  }

  private editUser(data: IUserFormData) {
    const { user, profile } = this.props;
    const isSelf = profile.id === (user ? user.id : '');
    this.props
      .editUser(
        this.props.params.id,
        transformFormData(data, !isSelf || canEditOwnRoles(profile)),
        EDIT_USER_TAG
      )
      .then(() => this.props.getRoles(this.props.params.id))
      .then(() => this.props.loadItem('users', this.props.params.id))
      .catch(() => null);
  }
}

function mapStateToProps({ profile, responses, itemsOld, roles }: IStore) {
  const user = itemsOld.users;
  const loggedInUser = profile;
  return {
    formErrors: getFormErrors(responses, EDIT_USER, EDIT_USER_TAG),
    loading:
      isPending(responses, LOAD_ITEM, 'users') ||
      isPending(responses, GET_ROLES),
    profile,
    loggedInUser,
    saving: isPending(responses, EDIT_USER, EDIT_USER_TAG),
    success: hasSucceeded(responses, EDIT_USER, EDIT_USER_TAG),
    user,
    roles,
  };
}

export default connect(mapStateToProps, { editUser, loadItem, getRoles })(
  EditUser
);
