import * as React from 'react';
import { connect } from 'react-redux';
import { statusForUserRole } from '../../lookup-tables/status';
import { getHighestAuthorityRole } from '../../permissions';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import {
  ICourseworkProgressOverviewUserTask,
  INestedUserTask,
  IUserGradeListTaskRecord,
  IUserTaskRecord,
} from '../../store/data-types/tasks';

interface IExternalProps {
  task:
    | IUserTaskRecord
    | INestedUserTask
    | ICourseworkProgressOverviewUserTask
    | IUserGradeListTaskRecord;
}

interface IProps extends IExternalProps {
  profile: IProfile;
}

export class StatusIndicator extends React.PureComponent<IProps, void> {
  public render() {
    const { task, profile, children } = this.props;
    const role = getHighestAuthorityRole(profile);
    const { status, className } = statusForUserRole(task.status, role, task);
    return (
      <span className={className}>
        {status}
        {children}
      </span>
    );
  }
}

export function mapStateToProps(
  { profile }: IStore,
  externalProps: IExternalProps
): IProps {
  return { profile, ...externalProps };
}

export default connect(mapStateToProps)(StatusIndicator);
