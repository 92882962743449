import {
  Button,
  FormGroup,
  ModalFooter,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import {
  DataShape,
  Field,
  FormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { ISimpleUser } from '../../../store/data-types/profile';
import { IUnitAssigneeRecord } from '../../../store/data-types/tasks';
import { FormErrors } from '../../../utils';
import DateInput from '../../date-input';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import GroupField from '../../forms/group-field';
import Loading from '../../loading';
import Term from '../../terminology';

interface IBaseProps {
  students: ReadonlyArray<ISimpleUser>;
  unitAssignees: List<IUnitAssigneeRecord>;
  loading: boolean;
  minStart: moment.Moment | null;
  maxEnd: moment.Moment | null;
}

interface IUnitAssigneesField extends WrappedFieldProps<void> {
  students: ReadonlyArray<ISimpleUser>;
  unitAssignees: List<IUnitAssigneeRecord>;
  loading: boolean;
}

export function UnitAssigneesField({
  input,
  loading,
  students,
  unitAssignees,
}: IUnitAssigneesField) {
  const value: Set<string> = (input && input.value) || Set<string>();
  const onChange = input ? input.onChange : () => null;
  return (
    <Row>
      <Table fill>
        <TableHead>
          <TableRow>
            <TableHeader>Students</TableHeader>
            <TableHeader>Assigned</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map(student => {
            const unitAssignee = unitAssignees.find(
              each => each.id === student.id
            );
            const isAssigned = value.contains(student.id);
            const canBeUnassigned = unitAssignee
              ? !unitAssignee.has_been_interacted_with
              : true;

            return (
              <TableRow key={student.id}>
                <TableCell>{student.osms_data.name}</TableCell>
                <TableCell>
                  <input
                    checked={value.contains(student.id)}
                    type="checkbox"
                    disabled={!canBeUnassigned || loading}
                    onChange={event =>
                      onChange(
                        event.target.checked
                          ? value.add(student.id) as any
                          : value.remove(student.id) as any
                      )}
                  />
                  {!canBeUnassigned && (
                    <span className="info margin-left-base">
                      Can't unassign started <Term>Tasks</Term>.
                    </span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Row>
  );
}

interface IClassTaskDate {
  start_date: string | null;
  end_date: string | null;
  title: string;
}
export type IClassTaskDates = Map<string, IClassTaskDate>;
interface IClassTaskDatesField extends WrappedFieldProps<void> {
  formErrors: FormErrors;
  minStart: moment.Moment | null;
  maxEnd: moment.Moment | null;
  loading: boolean;
}
function ClassTaskDatesField(props: IClassTaskDatesField) {
  const { input } = props;
  if (!input) {
    return null;
  }
  const value: IClassTaskDates = input.value || Map();
  const sortedValue = value.toOrderedMap().sortBy((each, id) => id);
  return (
    <div>
      {sortedValue.map((each, id) => {
        // In reality these wont be any type but redux form has no idea
        const getOnChange = (fieldName: string) => (newValue: any) =>
          input.onChange(value.set(id, {
            ...value.get(id),
            [fieldName]: newValue,
          }) as any);
        const position =
          sortedValue
            .keySeq()
            .toList()
            .findIndex(key => key === id) || 0;
        return (
          <div>
            <label>
              <strong>{each.title}</strong>
            </label>
            <FormGroup>
              <label>Start</label>{' '}
              <DateInput
                {...props}
                minDate={props.minStart}
                maxDate={props.maxEnd}
                input={{
                  ...input,
                  value: each.start_date,
                  onChange: getOnChange('start_date'),
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>End</label>{' '}
              <DateInput
                {...props}
                minDate={props.minStart}
                maxDate={props.maxEnd}
                input={{
                  ...input,
                  value: each.end_date,
                  onChange: getOnChange('end_date'),
                }}
              />
            </FormGroup>
          </div>
        );
      })}
      <FormErrorsRenderer
        formErrors={props.formErrors}
        errorKey="class_tasks"
      />
    </div>
  );
}

const MANAGE_UNIT_FORM = 'MANAGE_UNIT_FORM';
interface IManageUnitForm
  extends IBaseProps,
    React.HTMLProps<JSX.Element>,
    FormProps<DataShape, void, void> {
  formErrors: FormErrors;
  is_mandatory: boolean;
}
export class ManageUnitForm extends React.PureComponent<IManageUnitForm, void> {
  public render() {
    const {
      handleSubmit,
      loading,
      students,
      unitAssignees,
      formErrors,
      minStart,
      maxEnd,
      is_mandatory,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GroupField
          label=""
          name="class_task_dates"
          type="text"
          formErrors={formErrors}
          component={field => (
            <ClassTaskDatesField
              {...field}
              loading={loading}
              minStart={minStart}
              maxEnd={maxEnd}
              formErrors={formErrors}
            />
          )}
        />
        {!is_mandatory && (
          <GroupField
            name="assignees"
            type="text"
            label=""
            formErrors={formErrors}
            component={field => (
              <UnitAssigneesField
                {...field}
                loading={loading}
                students={students}
                unitAssignees={unitAssignees}
              />
            )}
          />
        )}
        {loading && <Loading />}
        <FormErrorsRenderer formErrors={formErrors} errorKey="unit" />
        <FormErrorsRenderer formErrors={formErrors} errorKey="task_class" />
        <FormErrorsRenderer formErrors={formErrors} errorKey="qualification" />
        <FormErrorsRenderer
          formErrors={formErrors}
          errorKey="non_field_errors"
        />
        <ModalFooter>
          <Button
            disabled={loading}
            type="submit"
            className="margin-vertical-large"
          >
            Update
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

export default reduxForm({ form: MANAGE_UNIT_FORM })(ManageUnitForm);
