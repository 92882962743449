import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { getAssets } from '../../actions';
import { LOAD_ITEM } from '../../items/actions';
import { itemsModule } from '../../reducers/items';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import { convertMegaBytesToBytes } from '../../utils';
import Loading from '../loading';
import Assets from './assets';
import QuotaUsage from './quota-usage';

const { actions: { loadItem } } = itemsModule;

interface IRouteProps {
  params: { student: string };
}

interface IStateProps {
  isLoadingStudent: boolean;
  studentId: string;
  student: IProfile | null;
}

interface IProps extends IStateProps {
  loadItem: typeof loadItem;
  getAssets: typeof getAssets;
}

export class FilesPageStudent extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.loadItem('users', this.props.studentId);
    this.loadAssets();
  }

  public render() {
    const { student, isLoadingStudent } = this.props;

    if (isLoadingStudent) {
      return <Loading />;
    }

    if (!student) {
      return (
        <Alert className="error">
          <p>Failed to load student.</p>
        </Alert>
      );
    }

    const usage = convertMegaBytesToBytes(student.assets_quota_used_mb);
    const quotaBytes = convertMegaBytesToBytes(student.assets_quota_mb);

    return (
      <ContentBox>
        <ContentBoxHeader>
          <QuotaUsage
            className="float-right margin-vertical-small"
            usage={usage}
            quotaBytes={quotaBytes}
          />
          <h2 className="font-size-large">Files</h2>
        </ContentBoxHeader>
        <Assets student={student} loadAssets={this.loadAssets} />
      </ContentBox>
    );
  }

  private loadAssets = (page: number = 1, shouldAppend: boolean = false) => {
    // Show any files uploaded for or by the student
    this.props.getAssets(
      {
        owner__id: this.props.studentId,
        page,
      },
      undefined,
      shouldAppend
    );
  };
}

function mapStateToProps(
  { assets, profile, itemsOld, responses }: IStore,
  props: IRouteProps
): IStateProps {
  return {
    isLoadingStudent: isPending(responses, LOAD_ITEM, 'users'),
    studentId: props.params!.student,
    student: itemsOld.users,
  };
}

export default connect(mapStateToProps, { getAssets, loadItem })(
  FilesPageStudent
);
