import * as classNames from 'classnames';
import * as React from 'react';

export interface IProgressElement {
  progress: number;
  className?: string;
}

interface IProps {
  progressElements: IProgressElement[];
}

export class MultiProgressBar extends React.PureComponent<
  IProps & React.HTMLProps<HTMLDivElement>,
  void
> {
  public render() {
    const { className, progressElements, ...remainingProps } = this.props;

    const total = progressElements.reduce(
      (prev, curr) => prev + curr.progress,
      0
    );

    const progressWithWidths = progressElements.map(element => ({
      width: Math.round(element.progress / total * 100),
      ...element,
    }));

    return (
      <div
        {...remainingProps}
        className={classNames('multi-progress-bar', className)}
      >
        {progressWithWidths.map(
          element =>
            element.width > 0 && (
              <div
                key={element.className}
                className={element.className}
                style={{ width: `${element.width}%` }}
              >{`${element.width}%`}</div>
            )
        )}
      </div>
    );
  }
}
