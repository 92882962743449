import * as React from 'react';

import If from './if';

interface IProps {
  can: boolean;
}

export class IfCan extends React.PureComponent<IProps, void> {
  public render() {
    const { can, children } = this.props;
    return <If condition={can}>{children}</If>;
  }
}

export default IfCan;
