import {
  anyPending,
  hasSucceeded,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ContentBox,
  ContentBoxHeader,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  DataShape,
  Field,
  FormComponentProps,
  FormProps,
  reduxForm,
} from 'redux-form';
import { closeModal, openModal } from '../../actions/modals';
import { BULK_UPDATE_STATUS } from '../../actions/tasks';
import { bulkUpdateStatus, IBulkUpdateStatus } from '../../actions/tasks';
import { IRouteProps } from '../../index';
import { LOAD_ITEM } from '../../items/actions';
import { CENTRE_ADMIN, DA_ADMIN, DA_SUPPORT, TEACHER } from '../../permissions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import { STATUS } from '../../store/data-types/tasks';
import {
  IClassTaskRecord,
  IMarkschemeRecord,
  INestedUserTask,
} from '../../store/data-types/tasks';
import {
  constantToTitleCase,
  formatDate,
  formatDateForBackend,
  FormErrors,
} from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import Loading from '../loading';
import ConfirmModal from '../modals/confirm-modal';
import { default as SimpleTable, IColumnData } from '../tables/simple-table';
import Term from '../terminology';
import MarkschemeModal from './markscheme-modal';
import StatusIndicator from './status-indicator';
import TaskDetailBase from './task-detail-base';

const { actions: { loadItem } } = itemsModule;

function getHeaders(): Array<IColumnData<INestedUserTask>> {
  return [
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/`}>
          {narrowToRecord(data.user).osms_data.name}
        </Link>
      ),
      headerLabel: 'Name',
      key: 'name',
      sortable: false,
    },
    {
      content: data => <span>{data.tagged_asset_count}</span>,
      headerLabel: 'Files',
      key: 'files',
      sortable: false,
    },
    {
      content: data => formatDate(data.end_date),
      headerLabel: 'Hand-in Date',
      key: 'end_date',
      sortable: false,
    },
    {
      content: data => <StatusIndicator task={data} />,
      headerLabel: 'Status',
      key: 'status',
      sortable: false,
    },
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/checklist/`}>
          View Checklist
        </Link>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

interface IProps {
  classTaskId: string;
  classTask: IClassTaskRecord | null;
  markscheme: IMarkschemeRecord | null;
  loading: boolean;
  openModal: typeof openModal;
  closeModal: typeof closeModal;
  bulkUpdateStatus(tasks: List<IBulkUpdateStatus>): AxiosPromise;
  loadItem(type: keyof IItemsState, itemId: string): void;
}

export class TaskDetailEQA extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.openMarkschemeModal = this.openMarkschemeModal.bind(this);
    this.canSeeSignOff = this.canSeeSignOff.bind(this);
    this.canSignOff = this.canSignOff.bind(this);
    this.signOff = this.signOff.bind(this);
  }

  public openMarkschemeModal() {
    if (this.props.markscheme) {
      this.props.openModal(
        <MarkschemeModal
          closeModal={this.props.closeModal}
          markscheme={this.props.markscheme}
        />
      );
    }
  }

  public componentWillMount() {
    this.props.loadItem('tasks/class-task-markscheme', this.props.classTaskId);
  }

  public render() {
    const { classTask, loading } = this.props;
    return (
      <TaskDetailBase
        headers={getHeaders()}
        loading={loading}
        classTask={classTask}
        openMarkschemeModal={this.openMarkschemeModal}
      >
        {this.canSeeSignOff() && (
          <SpacedGroup block className="margin-top-large">
            <Button
              className="primary padding-horizontal-large"
              disabled={!this.canSignOff()}
              onClick={this.signOff}
            >
              Sign off
            </Button>
            <span className="info">
              Note: you need to review at least one <Term>Marksheet</Term>{' '}
              before signing off the <Term>Task</Term> for this{' '}
              <Term>Class</Term>.
            </span>
          </SpacedGroup>
        )}
      </TaskDetailBase>
    );
  }

  private canSignOff() {
    const { classTask } = this.props;
    if (!classTask) {
      return false;
    }
    return (
      classTask.user_tasks.every(
        userTask =>
          userTask.status === STATUS.PASSED_EQA ||
          userTask.status === STATUS.PASSED_IQA
      ) &&
      classTask.user_tasks.some(
        userTask => userTask.status === STATUS.PASSED_EQA
      )
    );
  }

  private canSeeSignOff() {
    const { classTask } = this.props;
    if (!classTask) {
      return false;
    }
    return classTask.user_tasks.some(
      userTask => userTask.status === STATUS.PASSED_IQA
    );
  }

  private signOff() {
    if (!this.props.classTask) {
      return;
    }
    const userTasks = this.props.classTask.user_tasks;

    this.props.openModal(
      <ConfirmModal
        closeModal={this.props.closeModal}
        title="Are you sure you want sign off this class?"
        body={<p>You will no longer be able to make any changes.</p>}
        okayLabel="Confirm"
        onCancelClick={this.props.closeModal}
        onOkayClick={() => {
          this.props.closeModal();
          const update = userTasks.map(userTask => {
            return {
              id: userTask.id,
              status: STATUS.PASSED_EQA,
            };
          });
          this.props.bulkUpdateStatus(update).then(() => {
            this.props.loadItem('tasks/class', this.props.classTaskId);
          });
        }}
      />
    );
  }
}

function mapStateToProps(
  { itemsOld, responses, profile }: IStore,
  props: IRouteProps
) {
  return {
    classTask: itemsOld.get('tasks/class'),
    markscheme: itemsOld.get('tasks/class-task-markscheme'),
    classTaskId: props.params.id,
    loading: anyPending(responses, [
      BULK_UPDATE_STATUS,
      [LOAD_ITEM, 'tasks/class'],
      [LOAD_ITEM, 'tasks/class-task-markscheme'],
    ]),
  };
}

export default connect(mapStateToProps, {
  loadItem,
  openModal,
  closeModal,
})(TaskDetailEQA);
