import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Column,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';

import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IExamBoardRecord, IExamSpecRecord } from '../../../store/data-types';
import { ICentre } from '../../../store/data-types/centres';
import GroupField from '../../forms/group-field';

const { actions: { clearCollection, getAllCollection } } = collectionsModule;

const COURSEWORK_PROGRESS_OVERVIEW_LANDING =
  'COURSEWORK_PROGRESS_OVERVIEW_LANDING';

interface IProps extends FormProps<DataShape, void, void> {
  centresCollection: ICollection<ICentre>;
  examBoardCollection: ICollection<IExamBoardRecord>;
  currentQualifications: List<IExamSpecRecord>;
  currentCentre: string | undefined;
  currentQualification: string | undefined;
  loading: boolean;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  clearCollection(type: keyof ICollectionsState, tag: string): void;
  push(url: string): void;
}

export class CourseworkProgressOverviewLanding extends React.Component<
  IProps,
  void
> {
  public componentWillMount() {
    this.props.getAllCollection(
      'centres',
      {},
      COURSEWORK_PROGRESS_OVERVIEW_LANDING
    );
  }

  public render() {
    const {
      centresCollection,
      currentCentre,
      currentQualification,
      currentQualifications,
      examBoardCollection,
      loading,
    } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Overview</h2>
        </ContentBoxHeader>
        <p className="info">
          First choose an Awarding Body and Qualification to filter by:
        </p>

        <Row className="margin-vertical-large">
          <form>
            <Column sm={4}>
              <GroupField
                label="Centre"
                name="centre"
                type="select"
                component="select"
                disabled={loading}
                onChange={this.onChangeCentre.bind(this)}
              >
                <option />
                {centresCollection.results.map(result => (
                  <option key={result.id} value={result.id}>
                    {result.osms_data.name}
                  </option>
                ))}
              </GroupField>
            </Column>
            <Column sm={4}>
              <GroupField
                label="Awarding Body"
                name="awardingBody"
                type="select"
                component="select"
                disabled={loading || examBoardCollection.results.count() === 0}
              >
                <option />
                {examBoardCollection.results.map(result => (
                  <option key={result.id} value={result.id}>
                    {result.name}
                  </option>
                ))}
              </GroupField>
            </Column>
            <Column sm={4}>
              <GroupField
                label="Qualification"
                name="qualification"
                type="select"
                component="select"
                disabled={loading || currentQualifications.count() === 0}
              >
                <option />
                {currentQualifications.map(result => (
                  <option key={result.id} value={result.id}>
                    {result.name}
                  </option>
                ))}
              </GroupField>
            </Column>
          </form>
        </Row>

        <ContentBoxFooter className="text-align-left">
          <p>
            <Button
              className="tertiary"
              disabled={!currentCentre || !currentQualification}
              onClick={this.onFilter.bind(this)}
            >
              Filter...
            </Button>
          </p>
        </ContentBoxFooter>
      </ContentBox>
    );
  }

  private onFilter(event: React.SyntheticEvent<any>) {
    this.props.push(
      `/tasks/overview/${this.props.currentCentre}/exam-spec/${this.props
        .currentQualification}/`
    );
  }

  private onChangeCentre(event: React.ChangeEvent<any>) {
    const centreId: string | undefined = event.target.value;
    if (centreId) {
      this.props.getAllCollection(
        'courses/exam-boards',
        {
          filters: Map({
            centre: event.target.value,
          }),
        },
        COURSEWORK_PROGRESS_OVERVIEW_LANDING
      );
      this.props.getAllCollection(
        'courses/running-exam-specs',
        {
          filters: Map({
            centre: event.target.value,
          }),
        },
        COURSEWORK_PROGRESS_OVERVIEW_LANDING
      );
    } else {
      this.props.clearCollection(
        'courses/exam-boards',
        COURSEWORK_PROGRESS_OVERVIEW_LANDING
      );
      this.props.clearCollection(
        'courses/running-exam-specs',
        COURSEWORK_PROGRESS_OVERVIEW_LANDING
      );
    }
  }
}

const selector = formValueSelector('coursework-progress-overview-landing');

function mapStateToProps(
  store: IStore,
  props: FormProps<DataShape, void, void>
) {
  const { collectionsOld, responses } = store;
  const { centre, awardingBody, qualification } = selector(
    store,
    'centre',
    'awardingBody',
    'qualification'
  );
  const filter = awardingBody
    ? (qual: IExamSpecRecord) => qual.exam_board.id === awardingBody
    : (qual: IExamSpecRecord) => true;

  const currentQualifications = getCollectionByName(
    collectionsOld.get('courses/running-exam-specs'),
    COURSEWORK_PROGRESS_OVERVIEW_LANDING
  ).results.filter(filter);

  return {
    centresCollection: getCollectionByName(
      collectionsOld.centres,
      COURSEWORK_PROGRESS_OVERVIEW_LANDING
    ),
    currentCentre: centre,
    currentQualification: qualification,
    currentQualifications,
    examBoardCollection: getCollectionByName(
      collectionsOld.get('courses/exam-boards'),
      COURSEWORK_PROGRESS_OVERVIEW_LANDING
    ),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'centres'],
      [GET_COLLECTION, 'courses/running-exam-specs'],
      [GET_COLLECTION, 'courses/exam-boards'],
    ]),
    ...props,
  };
}

const Formified = reduxForm({
  form: 'coursework-progress-overview-landing',
  destroyOnUnmount: false,
})(CourseworkProgressOverviewLanding);

export default connect(mapStateToProps, {
  clearCollection,
  getAllCollection,
  push,
})(Formified);
