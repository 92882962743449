import {
  Button,
  Column,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import Loading from '../loading';
import ModalCloseIcon from './modal-close-icon';

interface IItemListProps<T> {
  items: List<T>;
  actionText: string;
  loading?: boolean;
  noItemsMessage: string;
  lockedItems?: List<string>;
  lockedItemsMessage?: string;
  action(itemId: string): void;
  itemRenderer(item: T): React.ReactNode;
}

function ItemList<T extends { id: string }>({
  items,
  action,
  actionText,
  loading,
  noItemsMessage,
  itemRenderer,
  lockedItems,
  lockedItemsMessage,
}: IItemListProps<T>) {
  if (loading) {
    return (
      <ul className="journal-file-list fixed">
        <li>
          <Loading />
        </li>
      </ul>
    );
  }
  return (
    <ul className="journal-file-list fixed">
      {items.count() ? (
        items.map(item => {
          const isLocked = Boolean(
            lockedItems && lockedItems.contains(item.id)
          );
          return (
            <li key={item.id}>
              <Row>
                <Column xs={12} sm={7} lg={9} className="nowrap">
                  {itemRenderer(item)}
                </Column>
                <Column xs={12} sm={5} lg={3}>
                  {isLocked ? (
                    <span className="info">{lockedItemsMessage || ''}</span>
                  ) : (
                    <a onClick={() => action(item.id)}> {actionText}</a>
                  )}
                </Column>
              </Row>
            </li>
          );
        })
      ) : (
        <p>{noItemsMessage}</p>
      )}
    </ul>
  );
}

interface ISelectItemsModalProps<T extends { id: string }> {
  subTitle?: React.ReactChild;
  items: List<T>;
  loading: boolean;
  selectedItems: List<string>;
  itemLabel: string;
  targetLabel: string;
  lockedItems?: List<string>;
  lockedItemsMessage?: string;
  closeModal(): void;
  itemRenderer(item: T): React.ReactNode;
  onChangeSelected(selected: List<string>): void;
}

export default class SelectItemsModal<
  T extends { id: string }
> extends React.PureComponent<ISelectItemsModalProps<T>, void> {
  public removeItem(itemId: string): void {
    this.props.onChangeSelected(
      this.props.selectedItems.filterNot(id => id === itemId)
    );
  }

  public addItem(itemId: string): void {
    this.props.onChangeSelected(this.props.selectedItems.push(itemId));
  }

  public render() {
    const {
      itemLabel,
      itemRenderer,
      targetLabel,
      lockedItems,
      lockedItemsMessage,
      subTitle,
      children,
    } = this.props;

    const selectedItems = this.props.items.filter(item =>
      this.props.selectedItems.contains(item.id)
    );

    const unSelectedItems = this.props.items.filterNot(
      item =>
        this.props.selectedItems.contains(item.id) ||
        (this.props.lockedItems
          ? this.props.lockedItems.contains(item.id)
          : false)
    );

    return (
      <Modal onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          <p>
            Select {itemLabel} to add to your {targetLabel}
            {subTitle && (
              <span className="display-block font-size-small margin-top-small">
                {subTitle}
              </span>
            )}
          </p>
        </ModalHeader>

        <h4>Unselected {itemLabel}</h4>
        <ItemList
          items={unSelectedItems}
          loading={this.props.loading}
          actionText="add"
          noItemsMessage={`No unselected ${itemLabel}.`}
          action={this.addItem.bind(this)}
          itemRenderer={itemRenderer}
        />

        <h4>Selected {itemLabel}</h4>
        <ItemList
          items={selectedItems}
          loading={this.props.loading}
          actionText="remove"
          lockedItems={lockedItems}
          lockedItemsMessage={lockedItemsMessage}
          noItemsMessage={`No ${itemLabel} selected.`}
          action={this.removeItem.bind(this)}
          itemRenderer={itemRenderer}
        />

        {children}

        <ModalFooter>
          <p>
            <Button onClick={() => this.props.closeModal()}>Done</Button>
          </p>
        </ModalFooter>
      </Modal>
    );
  }
}
