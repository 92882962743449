import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';

export interface IInviteMeta {
  itemId: string;
}

export const SEND_INVITE = makeAsyncActionSet('SEND_INVITE');
export function sendInvite(studentId: string, tag?: string) {
  const meta: IInviteMeta = {
    itemId: studentId,
  };

  return dispatchGenericRequest(
    SEND_INVITE,
    `/api/users/${studentId}/invite/`,
    'POST',
    {},
    tag,
    meta
  );
}

export const CLEAR_INVITE_STATUS = 'CLEAR_INVITE_STATUS';
export function clearInviteStatus() {
  return { type: CLEAR_INVITE_STATUS };
}
