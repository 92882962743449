import { Button, Column, Row, SpacedGroup } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { clearUIState, setUIState } from '../../actions';
import { openModal } from '../../actions/modals';
import {
  CENTRE_ADMIN,
  EXTERNAL_QUALITY_ASSURER,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store/';
import { IProfile, SimpleUser } from '../../store/data-types/profile';
import { IQualificationStudentProgress } from '../../store/data-types/progress';
import { STATUS } from '../../store/data-types/tasks';
import FilesWizard from '../files/files-wizard';
import ProgressShort from '../progress-short';
import SimplePopover from '../simple-popover';
import SimpleTable, { IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';
import TasksByStatusPopover from './tasks-by-status-popover';

const { IconExclamationTriangle } = FontAwesome;

const getHeaders = (
  context: TasksTeacher,
  complexRoc: boolean,
  profile: IProfile
): Array<IColumnData<IQualificationStudentProgress>> => [
  {
    content: data =>
      userHasRoles(profile, Set.of(CENTRE_ADMIN, TEACHER)) ? (
        <a href={`/users/student/${data.id}/`}>{data.student_name}</a>
      ) : (
        data.student_name
      ),
    headerLabel: terminologyFromProfile(context.props.profile, 'Student'),
    key: 'student_name',
    sortable: false,
  },
  {
    content: data => data.qualification_name,
    headerLabel: 'Qualification',
    key: 'qualification_name',
    sortable: false,
  },
  {
    content: data => `${data.assigned_units} / ${data.minimum_units}`,
    headerLabel: 'Unit count',
    headerRenderer: (
      <div>
        <SimplePopover
          bottom
          hover
          width={180}
          toggleComponent={<span>Unit count</span>}
        >
          The total displayed in this column is the required minimum - some
          learners may exceed this.
        </SimplePopover>
        {complexRoc && (
          <SimplePopover
            bottom
            hover
            width={180}
            toggleComponent={<IconExclamationTriangle />}
          >
            ROC not automatically calculated for this qualification.
          </SimplePopover>
        )}
      </div>
    ),
    key: 'unit_count',
    sortable: false,
  },
  {
    content: data => (
      <ProgressShort progress={data.progress_through_assigned_units} />
    ),
    headerLabel: 'Overall progress',
    key: 'progress_through_assigned_units',
    sortable: false,
  },
  {
    content: data => (
      <TasksByStatusPopover
        studentName={data.student_name}
        tasksCount={data.tasks_awaiting_marking}
        userId={data.id}
        statuses={[STATUS.AWAITING_MARKING]}
        qualificationId={context.props.qualificationId}
      />
    ),
    headerLabel: `${terminologyFromProfile(
      context.props.profile,
      'Tasks'
    )} to mark`,
    headerRenderer: (
      <span>
        {terminologyFromProfile(context.props.profile, 'Tasks')} <br />to mark
      </span>
    ),
    key: 'tasks_to_mark',
    sortable: false,
  },
  {
    content: data => (
      <span className="tasks-completed text-align-center">
        <span>
          {data.tasks_past_marking}/{data.total_tasks}
        </span>
      </span>
    ),
    headerLabel: `${terminologyFromProfile(
      context.props.profile,
      'Tasks'
    )} completed`,
    headerRenderer: (
      <span>
        {terminologyFromProfile(context.props.profile, 'Tasks')} <br />completed
      </span>
    ),
    key: 'tasks_completed',
    sortable: false,
  },
  {
    content: data => (
      <span className="tasks-overdue text-align-center">
        <span>
          {data.tasks_overdue}/{data.total_tasks}
        </span>
      </span>
    ),
    headerLabel: `${terminologyFromProfile(
      context.props.profile,
      'Tasks'
    )} overdue`,
    headerRenderer: (
      <span>
        {terminologyFromProfile(context.props.profile, 'Tasks')} <br />overdue
      </span>
    ),
    key: 'tasks_overdue',
    sortable: false,
  },
  {
    content: data => {
      if (userHasRoles(profile, Set.of(EXTERNAL_QUALITY_ASSURER))) {
        return '--';
      }
      return (
        <div>
          <div className="margin-bottom-small">
            <a onClick={context.tagFiles.bind(context, data)}>
              Upload and tag files
            </a>
          </div>
          {userHasRoles(profile, Set.of(CENTRE_ADMIN, TEACHER)) && (
            <div>
              <Link
                to={`/tasks/overview/${data.qualification_centre_id}/exam-spec/${data.qualification_exam_spec_id}/?option=${data.qualification_option_id}&class=${data.class_id}&user=${data.id}`}
              >
                View Progress
              </Link>
            </div>
          )}
        </div>
      );
    },
    headerLabel: 'Actions',
    key: 'actions',
    sortable: false,
  },
];

interface ISortToggle {
  title: string;
  isActive: boolean;
  onClick(): void;
}
function SortToggle(props: ISortToggle) {
  return props.isActive ? (
    <strong>{props.title}</strong>
  ) : (
    <a onClick={props.onClick}>{props.title}</a>
  );
}

const LEAST_COMPLETE = 'LEAST_COMPLETE';
const SHOW_ALL = 'SHOW_ALL';
const TRIMMED_COUNT = 5;
const getSortStateName = (componentName: string) => `${componentName}-sort`;
const getShowAllStateName = (componentName: string) =>
  `${componentName}-showall`;
interface IExternalProps {
  items: List<IQualificationStudentProgress>;
  qualificationId: string;
  loading: boolean;
  componentName: string;
  complexRoc: boolean;
  refresh(): void;
}
interface IProps extends IExternalProps {
  sortMostComplete: boolean;
  showAll: boolean;
  profile: IProfile;
  clearUIState(key: string): void;
  setUIState(key: string, value: string): void;
  openModal(component: React.ReactNode): void;
}
export class TasksTeacher extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.toggleShowAll = this.toggleShowAll.bind(this);
    this.getCompleted = this.getCompleted.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
    this.tagFiles = this.tagFiles.bind(this);
  }

  public componentWillMount() {
    this.props.clearUIState(getSortStateName(this.props.componentName));
    this.props.clearUIState(getShowAllStateName(this.props.componentName));
  }

  public tagFiles(progressRecord: IQualificationStudentProgress) {
    // Make a dummy user to pass up to FilesWizards. If the picker updates
    // to require additional data, this may need to be replaced with a network call
    const user = SimpleUser({
      id: progressRecord.id,
      osms_data: {
        name: progressRecord.student_name,
      },
    });
    this.props.openModal(
      <FilesWizard
        filesTaggedCallback={this.props.refresh}
        initialUser={user}
      />
    );
  }

  public render() {
    const {
      items,
      loading,
      showAll,
      sortMostComplete,
      complexRoc,
      profile,
    } = this.props;

    const compare = (a: number, b: number) =>
      sortMostComplete ? b - a : a - b;
    const sortedItems = items.sort((a, b) =>
      compare(this.getCompleted(a), this.getCompleted(b))
    );
    const limitedItems = showAll
      ? sortedItems
      : sortedItems.slice(0, TRIMMED_COUNT);
    return (
      <div className="task-table">
        <Row>
          <Column xs={12} sm={6}>
            <h3>
              Individual <Term>Student</Term> Progress
            </h3>
          </Column>
          <Column xs={12} sm={6}>
            <div className="float-right">
              <SpacedGroup
                component="ul"
                className="nav font-size-base margin-top-base"
              >
                <li className="nav-item">Showing:</li>
                <li className="nav-item">
                  <SortToggle
                    isActive={sortMostComplete}
                    onClick={this.toggleSort}
                    title="most complete"
                  />
                </li>
                <li className="nav-item">
                  <SortToggle
                    isActive={!sortMostComplete}
                    onClick={this.toggleSort}
                    title="least complete"
                  />
                </li>
              </SpacedGroup>
            </div>
          </Column>
        </Row>
        <SimpleTable
          loading={loading}
          items={limitedItems}
          headers={getHeaders(this, complexRoc, profile)}
        />
        <Button className="margin-top-large" onClick={this.toggleShowAll}>
          view {showAll ? 'less' : 'more'}...
        </Button>
      </div>
    );
  }

  private getCompleted(item: IQualificationStudentProgress) {
    return item.tasks_past_marking;
  }

  private toggleSort() {
    const { componentName, sortMostComplete } = this.props;
    const sortName = getSortStateName(componentName);
    if (sortMostComplete) {
      this.props.setUIState(sortName, LEAST_COMPLETE);
    } else {
      this.props.clearUIState(sortName);
    }
  }

  private toggleShowAll() {
    const { showAll, componentName } = this.props;
    const stateName = getShowAllStateName(componentName);
    if (showAll) {
      this.props.clearUIState(stateName);
    } else {
      this.props.setUIState(stateName, SHOW_ALL);
    }
  }
}

function mapStateToProps(
  { uiState, profile }: IStore,
  { items, componentName, loading }: IExternalProps
) {
  return {
    componentName,
    items,
    profile,
    loading,
    showAll: uiState.get(getShowAllStateName(componentName)) === SHOW_ALL,
    sortMostComplete:
      uiState.get(getSortStateName(componentName)) !== LEAST_COMPLETE,
  };
}

export default connect(mapStateToProps, {
  clearUIState,
  setUIState,
  openModal,
})(TasksTeacher);
