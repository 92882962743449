import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { replace as routerReplace } from 'react-router-redux';
import { Dispatch } from 'redux';

import { GetStateCallback } from '../requests/types';

import {
  ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE,
  userHasRoles,
} from '../permissions';
import { IStore } from '../store';

export const GET_ROLES = makeAsyncActionSet('GET_ROLES');
export function getRoles(userId: string) {
  return dispatchGenericRequest(
    GET_ROLES,
    `/api/users/${userId}/roles/`,
    'GET',
    null,
    undefined
  );
}

export const GET_CURRENT_ROLE_OPTIONS = makeAsyncActionSet(
  'GET_CURRENT_ROLE_OPTIONS'
);
export function getRoleOptions() {
  return dispatchGenericRequest(
    GET_CURRENT_ROLE_OPTIONS,
    '/api/users/current-role-options/',
    'GET',
    null,
    undefined
  );
}

export const GET_CURRENT_CENTRE_OPTIONS = makeAsyncActionSet(
  'GET_CURRENT_CENTRE_OPTIONS'
);
export function getCentreOptions() {
  return dispatchGenericRequest(
    GET_CURRENT_CENTRE_OPTIONS,
    '/api/users/current-centre-options/',
    'GET',
    null,
    undefined
  );
}

export interface ICentreRoleUpdateData {
  current_role?: string;
  current_centre?: string;
}
export const UPDATE_CURRENT_CENTRE_ROLE = makeAsyncActionSet(
  'UPDATE_CURRENT_CENTRE_ROLE'
);
export function updateCurrentCentreRole(updateData: ICentreRoleUpdateData) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      UPDATE_CURRENT_CENTRE_ROLE,
      '/api/users/update-current-centre-role/',
      'PUT',
      { ...updateData },
      undefined
    )(dispatch, getState).then(() => {
      const profile = getState().profile;
      if (
        Boolean(profile.current_role) &&
        (Boolean(profile.current_centre) ||
          userHasRoles(profile, ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE))
      ) {
        /*
          NOTE: we need the window to reload in order to initialise the state correctly with things
          like the terminology for the centre etc
        */
        window.location.replace(profile.preferences.preference_homepage);
      } else {
        dispatch(getCentreOptions());
      }
    });
  };
}

export function clearCurrentCentreRole() {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      UPDATE_CURRENT_CENTRE_ROLE,
      '/api/users/clear-current-centre-role/',
      'PUT',
      null,
      undefined
    )(dispatch, getState).then(() => {
      dispatch(routerReplace('/select-role/'));
    });
  };
}
