import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Alert } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { IRouteProps } from '../../index';
import { LOAD_ITEM } from '../../items/actions';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  userHasRoles,
} from '../../permissions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store/';
import {
  // tslint:disable-next-line:no-unused-variable
  IQuotaRecord,
} from '../../store/data-types';
import { IClassRecord } from '../../store/data-types/classes';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import Loading from '../loading';
import Term from '../terminology';
import ClassDetailStudent from './class-detail-student';
import ClassDetailTeacher from './class-detail-teacher';

const { actions: { clearItem, loadItem } } = itemsModule;

const ClassFailedToLoadMessage = () => (
  <Alert className="error">
    <p>
      Failed to load <Term>Class</Term>
    </p>
  </Alert>
);

interface IClassPagePropsWithoutActions {
  class: IClassRecord | null;
  classId: string;
  loading: boolean;
  profile: IProfile;
  userId: string;
}

interface IClassPageProps extends IClassPagePropsWithoutActions {
  loadItem: typeof loadItem;
  clearItem: typeof clearItem;
}

export class ClassPage extends React.PureComponent<IClassPageProps, void> {
  public componentWillMount() {
    this.props.loadItem('classes', this.props.classId);
  }

  public componentWillUnmount() {
    this.props.clearItem('classes');
  }

  public render() {
    if (this.props.loading) {
      return <Loading />;
    }
    if (!this.props.class) {
      return <ClassFailedToLoadMessage />;
    }
    const teachers = narrowToRecordArray<ISimpleUser>(
      this.props.class.teachers
    );

    if (
      // necessary to check if user is in the teachers of the class as they may be a student in some classes
      _.find(teachers, each => each.id === this.props.userId) ||
      userHasRoles(
        this.props.profile,
        Set.of(CENTRE_ADMIN, DA_ADMIN, DA_SUPPORT)
      )
    ) {
      return <ClassDetailTeacher class={this.props.class} />;
    }
    return <ClassDetailStudent class={this.props.class} />;
  }
}

function mapStateToProps(
  { itemsOld, profile, responses }: IStore,
  props: IRouteProps
): IClassPagePropsWithoutActions {
  return {
    ...props,
    class: itemsOld.classes,
    classId: props.params.id,
    loading: isPending(responses, LOAD_ITEM, 'classes'),
    profile,
    userId: profile.id,
  };
}

export default connect(mapStateToProps, { clearItem, loadItem })(ClassPage);
