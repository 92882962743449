import {
  Button,
  Column,
  ContentBoxHeader,
  DabIpsum,
  Row,
  Well,
} from '@dabapps/roe';
import { Collection, List, Seq } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollection } from '../../collections/types';
import {
  AssetRecord,
  IAssetRecord,
  ICommentRecord,
} from '../../store/data-types';
import {
  ActivityEventRecord,
  IActivityEventRecord,
  IFileUploadedActivityEventRecord,
  IJournalPostActivityEventRecord,
  IMarksheetStatusChangeEventRecord,
  INewCommentActivityEventRecord,
} from '../../store/data-types/activity-events';
import {
  IJournalEntry,
  JournalEntryRecord,
} from '../../store/data-types/journals';
import { IUserTaskRecord, UserTaskRecord } from '../../store/data-types/tasks';
import { TTimelineData } from '../../store/data-types/timeline';
import { getAssetName } from '../../utils';
import Term from '../terminology';

const {
  IconFileTextO,
  IconCalendarCheckO,
  IconCommentO,
  IconCloudUpload,
  IconEnvelopeO,
  IconPencilSquareO,
} = FontAwesome;

interface ITasksDayProps {
  day: string;
  entries: List<IUserTaskRecord>;
}

interface IJournalEntriesDayProps {
  day: string;
  entries: List<IActivityEventRecord>;
}

interface IFileUploadsDayProps {
  day: string;
  entries: List<IActivityEventRecord>;
}

interface ICommentsDayProps {
  day: string;
  entries: List<IActivityEventRecord>;
}

interface IHandInsDayProps {
  day: string;
  entries: List<IActivityEventRecord>;
}

interface IMarksheetMarkedsDayProps {
  day: string;
  entries: List<IActivityEventRecord>;
}

interface IActivityEventOrTaskDayProps {
  day: string;
  entries: List<TTimelineData>;
}

export function isUserTaskRecord(arg: any): arg is IUserTaskRecord {
  // ActivityEvents do not have start/end
  return arg.start_date && arg.end_date;
}

type TJournalEntriesDayProps = IJournalEntriesDayProps &
  React.HTMLProps<HTMLDivElement>;

export const JournalEntriesDay: React.SFC<TJournalEntriesDayProps> = ({
  entries,
  day,
}) => (
  <div>
    <div className="day-info">
      <div className="icon-background xs-display-none md-display-block">
        <IconFileTextO className="icon" />
      </div>
      <p className="date">{day}</p>
      <p className="posts">
        You wrote {entries.count()} Journal Post{entries.count() > 1 && 's'}
      </p>
    </div>
    <div className="day-group">
      {entries.map(entry => {
        const journalEntry = entry.update_journal_post
          ? entry.update_journal_post.journal_entry
          : JournalEntryRecord();

        const noLongerExists = !journalEntry.id && !journalEntry.title;

        return (
          <Well className="margin-bottom-large" key={entry.id}>
            {Boolean(journalEntry.id) && (
              <Link
                to={`/journal/${journalEntry.id}/`}
                className="button float-right"
              >
                View
              </Link>
            )}
            {noLongerExists ? (
              <div>
                <h5 className="title font-size-base font-weight-bold error">
                  Removed
                </h5>
                <div>
                  <p className="error">Journal entry no longer exists</p>
                </div>
              </div>
            ) : (
              <div>
                <h5 className="title font-size-base font-weight-bold">
                  {journalEntry.title}
                </h5>
                <div
                  className="journal-item-preview"
                  dangerouslySetInnerHTML={{ __html: journalEntry.content }}
                />
              </div>
            )}
          </Well>
        );
      })}
    </div>
  </div>
);

export const FileUploadsDay: React.SFC<
  IFileUploadsDayProps & React.HTMLProps<HTMLDivElement>
> = ({ entries, day }) => (
  <div>
    <div className="day-info">
      <div className="icon-background alternative xs-display-none md-display-block">
        <IconCloudUpload className="icon" />
      </div>
      <p className="date">{day} - Uploaded asset</p>
    </div>
    <div className="day-group">
      {entries.map(entry => {
        const asset = entry.file_uploaded
          ? entry.file_uploaded.asset
          : AssetRecord({});

        const noLongerExists = !asset.filename;

        return (
          <Well className="margin-bottom-large" key={entry.id}>
            {noLongerExists ? (
              <h5 className="title font-size-base font-weight-bold error">
                File no longer exists
              </h5>
            ) : (
              <h5 className="title font-size-base font-weight-bold">
                {getAssetName(asset)}
              </h5>
            )}
          </Well>
        );
      })}
    </div>
  </div>
);

export const CommentsDay: React.SFC<
  ICommentsDayProps & React.HTMLProps<HTMLDivElement>
> = ({ entries, day }) => (
  <div>
    <div className="day-info">
      <div className="icon-background xs-display-none md-display-block">
        <IconCommentO className="icon" />
      </div>
      <p className="date">
        {day} - New Comment{entries.count() > 1 && 's'}
      </p>
    </div>
    <div className="day-group">
      {entries.map(entry => {
        if (!entry.new_comment) {
          return null;
        }
        const comment = entry.new_comment.comment;
        const journalEntry = entry.new_comment.journal_entry;
        const taskStatusChange = entry.new_comment.task_status_change_comment;
        const task = taskStatusChange && taskStatusChange.task;

        return (
          <Well className="margin-bottom-large" key={entry.id}>
            {journalEntry &&
              Boolean(journalEntry.id) && (
                <Link
                  to={`/journal/${journalEntry.id}/`}
                  className="button float-right"
                >
                  View
                </Link>
              )}
            {task &&
              Boolean(task.id) && (
                <Link
                  to={`/tasks/marksheet/${task.id}/`}
                  className="button float-right"
                >
                  View
                </Link>
              )}
            <h5 className="title font-size-base font-weight-bold">Comment</h5>
            <p className="content">{comment.content}</p>
          </Well>
        );
      })}
    </div>
  </div>
);

export const HandInsDay: React.SFC<
  IHandInsDayProps & React.HTMLProps<HTMLDivElement>
> = ({ entries, day }) => (
  <div>
    <div className="day-info">
      <div className="icon-background xs-display-none md-display-block">
        <IconEnvelopeO className="icon" />
      </div>
      <p className="date">
        {day} - New Hand In{entries.count() > 1 && 's'}
      </p>
    </div>
    <div className="day-group">
      {entries
        .filter(
          entry =>
            entry.marksheet_status_change &&
            entry.marksheet_status_change.status_change === 'HAND_IN'
        )
        .map(entry => {
          const userTask = entry.marksheet_status_change
            ? entry.marksheet_status_change.user_task
            : UserTaskRecord({});

          const noLongerExists = !userTask.id && !userTask.class_task;

          return (
            <Well className="margin-bottom-large" key={entry.id}>
              {Boolean(userTask.id) && (
                <Link
                  to={`/tasks/marksheet/${userTask.id}/`}
                  className="button float-right"
                >
                  View
                </Link>
              )}
              {noLongerExists ? (
                <h5 className="title font-size-base font-weight-bold error">
                  <Term>Task</Term> no longer exists
                </h5>
              ) : (
                <h5 className="title font-size-base font-weight-bold">
                  {userTask.class_task.component.title}
                </h5>
              )}
            </Well>
          );
        })}
    </div>
  </div>
);

export const MarksheetMarkedsDay: React.SFC<
  IMarksheetMarkedsDayProps & React.HTMLProps<HTMLDivElement>
> = ({ entries, day }) => (
  <div>
    <div className="day-info">
      <div className="icon-background xs-display-none md-display-block">
        <IconPencilSquareO className="icon" />
      </div>
      <p className="date">
        {day} - Marksheet{entries.count() > 1 && 's'} Marked
      </p>
    </div>
    <div className="day-group">
      {entries
        .filter(
          entry =>
            entry.marksheet_status_change &&
            entry.marksheet_status_change.status_change === 'MARKED'
        )
        .map(entry => {
          const userTask = entry.marksheet_status_change
            ? entry.marksheet_status_change.user_task
            : UserTaskRecord({});

          const noLongerExists = !userTask.id && !userTask.class_task;

          return (
            <Well className="margin-bottom-large" key={entry.id}>
              {Boolean(userTask.id) && (
                <Link
                  to={`/tasks/marksheet/${userTask.id}/`}
                  className="button float-right"
                >
                  View
                </Link>
              )}
              {noLongerExists ? (
                <h5 className="title font-size-base font-weight-bold error">
                  <Term>Task</Term> no longer exists
                </h5>
              ) : (
                <h5 className="title font-size-base font-weight-bold">
                  {userTask.class_task.component.title}
                </h5>
              )}
            </Well>
          );
        })}
    </div>
  </div>
);

export const TasksDay: React.SFC<
  ITasksDayProps & React.HTMLProps<HTMLDivElement>
> = ({ entries, day }) => (
  <div>
    <div className="day-info">
      <div className="icon-background deadline xs-display-none md-display-block">
        <IconCalendarCheckO className="icon" />
      </div>
      <p className="date">
        {day} - Deadline{entries.count() > 1 && 's'}
      </p>
    </div>
    <div className="day-group">
      {entries.map(entry => (
        <Well className="margin-bottom-large" key={entry.id}>
          {Boolean(entry.id) && (
            <Link
              to={`/tasks/marksheet/${entry.id}/`}
              className="button float-right"
            >
              View
            </Link>
          )}
          <h5 className="title font-size-base font-weight-bold">
            {entry.class_task.component.title || 'No title'}
          </h5>
          <p className="content">
            {entry.class_task.component.unit.about || 'No description'}
          </p>
        </Well>
      ))}
    </div>
  </div>
);

type TActivityEventOrTaskDayProps = IActivityEventOrTaskDayProps &
  React.HTMLProps<HTMLDivElement>;

export const TasksVariableDay: React.SFC<TActivityEventOrTaskDayProps> = ({
  entries,
  day,
}) => {
  const tasks = entries.filter(entry => isUserTaskRecord(entry)) as List<
    IUserTaskRecord
  >;

  const activityEvents = entries.filterNot(entry =>
    isUserTaskRecord(entry)
  ) as List<IActivityEventRecord>;

  const journalEntries = activityEvents.filter(
    event =>
      event.event_type === 'CREATE_JOURNAL_POST' ||
      event.event_type === 'UPDATE_JOURNAL_POST'
  );
  const fileUploads = activityEvents.filter(
    event => event.event_type === 'FILE_UPLOADED'
  );
  const comments = activityEvents.filter(
    event => event.event_type === 'NEW_COMMENT'
  );
  const handIns = activityEvents.filter(
    event =>
      event.event_type === 'MARKSHEET_STATUS_CHANGE' &&
      event.marksheet_status_change &&
      event.marksheet_status_change.status_change === 'HAND_IN'
  );
  const marksheetMarkeds = activityEvents.filter(
    event =>
      event.event_type === 'MARKSHEET_STATUS_CHANGE' &&
      event.marksheet_status_change &&
      event.marksheet_status_change.status_change === 'MARKED'
  );

  return (
    <div>
      {!!tasks.count() && <TasksDay entries={tasks} day={day} />}
      {!!journalEntries.count() && (
        <JournalEntriesDay entries={journalEntries} day={day} />
      )}
      {!!fileUploads.count() && (
        <FileUploadsDay entries={fileUploads} day={day} />
      )}
      {!!comments.count() && <CommentsDay entries={comments} day={day} />}
      {!!handIns.count() && <HandInsDay entries={handIns} day={day} />}
      {!!marksheetMarkeds.count() && (
        <MarksheetMarkedsDay entries={marksheetMarkeds} day={day} />
      )}
    </div>
  );
};

export default TasksVariableDay;
