import {
  Alert,
  Button,
  Column,
  FormGroup,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { Field, FormProps, reduxForm, WrappedFieldProps } from 'redux-form';
import {
  ALL_ADMIN_USERS,
  ALL_CENTRE_STAFF_USERS,
  ALL_CENTRE_USERS,
  ALL_QA_USERS,
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store';
import { IRadioButtonRecord, RadioButtonRecord } from '../../store/data-types';
import {
  HomepagePreferenceOptions,
  IProfile,
  TRole,
} from '../../store/data-types/profile';
import { FormErrors, normalizeBoolean } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';
import RadioButtons from '../forms/radio-buttons';
import Term from '../terminology';

export interface IFormData {
  preference_homepage: HomepagePreferenceOptions;
  preference_show_old_tasks: boolean;
  preference_receive_notifications: boolean;
}

interface IProps {
  hasFailed: boolean;
  formErrors?: FormErrors;
  isLoading: boolean;
  hasSucceeded: boolean;
  profile: IProfile;
  onSubmit(data: IFormData): void;
}

const radioButtonsHomepage = List<IRadioButtonRecord>([
  RadioButtonRecord({
    key: '/',
    label: 'Home',
  }),
  RadioButtonRecord({
    key: '/files/',
    label: 'Files',
  }),
  RadioButtonRecord({
    key: '/journal/',
    label: 'Journal',
  }),
  RadioButtonRecord({
    key: '/users/',
    label: 'Users',
  }),
  RadioButtonRecord({
    key: '/classes/',
    label: 'Classes',
  }),
  RadioButtonRecord({
    key: '/tasks/',
    label: 'Tasks',
  }),
]);

const preferencesToRoles: {
  [T in HomepagePreferenceOptions]: ReadonlyArray<TRole>
} = {
  '/': [...ALL_CENTRE_USERS.toArray(), ...ALL_QA_USERS.toArray()],
  '/files/': [...ALL_CENTRE_STAFF_USERS.toArray(), STUDENT],
  '/journal/': [STUDENT],
  '/users/': [
    ...ALL_CENTRE_STAFF_USERS.toArray(),
    ...ALL_ADMIN_USERS.toArray(),
  ],
  '/classes/': [...ALL_CENTRE_STAFF_USERS.toArray(), STUDENT],
  '/tasks/': [
    ...ALL_CENTRE_STAFF_USERS.toArray(),
    STUDENT,
    INTERNAL_QUALITY_ASSURER,
  ],
};

function getHomepageButtons(profile: IProfile): List<IRadioButtonRecord> {
  return radioButtonsHomepage.filter(each => {
    const preference = each.key as HomepagePreferenceOptions;
    const rolesAllowedToSeePreference = preferencesToRoles[preference] || []; // NOTE: this may be null as we are casting the preference
    return userHasRoles(profile, Set<TRole>(rolesAllowedToSeePreference));
  });
}

const radioButtonsOldTasks = List<IRadioButtonRecord>([
  RadioButtonRecord({
    key: true,
    label: 'Show',
  }),
  RadioButtonRecord({
    key: false,
    label: 'Hide',
  }),
]);

const radioButtonsNotifications = List<IRadioButtonRecord>([
  RadioButtonRecord({
    key: true,
    label: 'Yes',
  }),
  RadioButtonRecord({
    key: false,
    label: 'No',
  }),
]);

interface IHomepageRadioButtonsProps extends WrappedFieldProps<IStore> {
  profile: IProfile;
}

function HomepageRadioButtons(props: IHomepageRadioButtonsProps) {
  return (
    <RadioButtons
      defaultValue={props.input.value}
      value={props.input.value}
      onChange={props.input.onChange}
      buttons={getHomepageButtons(props.profile)}
    />
  );
}

function ShowOldTaskRadioButtons(props: WrappedFieldProps<IStore>) {
  return (
    <RadioButtons
      buttons={radioButtonsOldTasks}
      defaultValue={props.input.value}
      value={props.input.value}
      onChange={props.input.onChange}
    />
  );
}

function ReceiveNotificationsRadioButtons(props: WrappedFieldProps<IStore>) {
  return (
    <RadioButtons
      buttons={radioButtonsNotifications}
      defaultValue={props.input.value}
      value={props.input.value}
      onChange={props.input.onChange}
    />
  );
}

export class LandingForm extends React.PureComponent<
  FormProps<IFormData, {}, {}> & IProps,
  void
> {
  public render() {
    const {
      change,
      profile,
      hasSucceeded,
      isLoading,
      formErrors,
      hasFailed,
    } = this.props;

    return (
      <Row>
        <Column>
          <form onSubmit={this.props.handleSubmit}>
            <Section>
              <Row>
                <Column>
                  <h3>Options</h3>
                </Column>

                <Column md={10} mdPush={2}>
                  <FormGroup>
                    <label className="option-title">Homepage</label>
                    <Field
                      name="preference_homepage"
                      component={HomepageRadioButtons}
                      profile={profile}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="option-title">
                      Old <Term>Tasks</Term>
                    </label>
                    <Field
                      name="preference_show_old_tasks"
                      normalize={normalizeBoolean}
                      component={ShowOldTaskRadioButtons}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="option-title">
                      Receive Notifications
                    </label>
                    <Field
                      name="preference_receive_notifications"
                      normalize={normalizeBoolean}
                      component={ReceiveNotificationsRadioButtons}
                    />
                  </FormGroup>
                </Column>
                {hasSucceeded && (
                  <Column>
                    <Alert className="success">
                      <p>Your preferences have been successfully updated.</p>
                    </Alert>
                  </Column>
                )}
                <Column>
                  <Button type="submit" disabled={isLoading}>
                    Save
                  </Button>
                  <FormErrorsRenderer
                    formErrors={formErrors}
                    errorKey="non_field_errors"
                  />
                  {hasFailed &&
                    !formErrors && (
                      <Alert className="error">
                        <p>Something went wrong.</p>
                      </Alert>
                    )}
                </Column>
              </Row>
            </Section>
          </form>
        </Column>
      </Row>
    );
  }
}

export default reduxForm({
  form: 'profile-personal',
})(LandingForm);
