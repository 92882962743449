import * as React from 'react';

import { FormErrors } from '../../utils';
import GroupField from './group-field';

const TITLES = ['Miss', 'Ms', 'Mrs', 'Mr', 'Doctor', 'Professor', 'Reverand'];

interface IProps {
  formErrors?: FormErrors;
  disabled?: boolean;
}

export default class TitleSelect extends React.Component<IProps, void> {
  public render() {
    const { formErrors, disabled } = this.props;

    return (
      <GroupField
        name="title"
        type="text"
        label="Title"
        disabled={disabled}
        formErrors={formErrors}
        component={fieldProps => (
          <select {...fieldProps.input} disabled={disabled}>
            {TITLES.map(title => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
          </select>
        )}
      />
    );
  }
}
