import { UPDATE_ITEM } from '@dabapps/redux-api-collections/dist/items';
import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Modal, ModalCloseIcon, ModalHeader } from '@dabapps/roe';
import { Dict } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modals';
import { collections, ICollections } from '../../collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { IAssetRecord } from '../../store/data-types';
import { EvidenceType } from '../../store/data-types/constants';
import { FormErrors } from '../../utils';
import Loading from '../loading';
import FilePropertiesForm from './file-properties-form';

const { actions: { patchItem } } = collections;

type FilePropertiesData = Readonly<{
  evidence_type: string;
  user_supplied_name: string;
  description: string;
}>;

interface IExternalProps {
  asset: IAssetRecord;
  readOnly: boolean;
  onUpdated?(): void;
}

interface IStateProps {
  loading: boolean;
  evidenceTypes: ReadonlyArray<EvidenceType>;
  formErrors?: FormErrors;
}

interface IDispatchProps {
  patchItem(
    itemType: keyof ICollections,
    id: string,
    data: FilePropertiesData
  ): AxiosPromise;
  closeModal(): void;
}

type Props = IExternalProps & IStateProps & IDispatchProps;

export class FilePropertiesModal extends React.PureComponent<Props, void> {
  public constructor(props: Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    const { loading, asset, evidenceTypes, formErrors, readOnly } = this.props;
    return (
      <Modal onClickOutside={this.props.closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.props.closeModal} />
          <p>File Properties</p>
        </ModalHeader>
        <FilePropertiesForm
          loading={loading}
          asset={asset}
          closeModal={this.props.closeModal}
          evidenceTypes={evidenceTypes}
          initialValues={{
            ...asset.toJS(),
            user_supplied_name: asset.user_supplied_name || asset.filename,
          }}
          onSubmit={this.onSubmit}
          formErrors={formErrors}
          readOnly={readOnly}
        />
      </Modal>
    );
  }

  private onSubmit(values: Dict<string>) {
    if (this.props.readOnly) {
      return;
    }

    this.props
      .patchItem('assets', values.id, {
        evidence_type: values.evidence_type,
        user_supplied_name: values.user_supplied_name,
        description: values.description,
      })
      .then(() => {
        this.props.closeModal();
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
      });
  }
}

function mapStateToProps(
  state: IStore,
  props: IExternalProps
): IExternalProps & IStateProps {
  return {
    ...props,
    loading: isPending(state.responses, UPDATE_ITEM, 'assets'),
    evidenceTypes: state.serverConstants.evidence_types,
    formErrors: getFormErrors(state.responses, UPDATE_ITEM, 'assets'),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  patchItem,
})(FilePropertiesModal);
