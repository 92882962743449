import {
  anyPending,
  hasSucceeded,
} from '@dabapps/redux-api-collections/dist/requests';
import { FormGroup, InputGroup, InputGroupAddon } from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import {
  UPDATE_CURRENT_CENTRE,
  updateCurrentCentre,
} from '../../actions/profile';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { DA_ADMIN, DA_SUPPORT, isSupportUser } from '../../permissions';
import { collectionsModule } from '../../reducers/collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { ICentre } from '../../store/data-types/centres';
import { IProfile } from '../../store/data-types/profile';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import IfHasRoles from '../permissions/if-has-roles';
import SearchableCenterInputWrapper, {
  IInputWrapperProps,
} from './searchable-centre-input-wrapper';

const { actions: { getCollection } } = collectionsModule;

const CENTRE_SWITCHER = 'CENTRE_SWITCHER';

interface IExternalProps {
  reloadCurrentPage?: boolean;
  className?: string;
  inputWrapper?: React.ComponentClass<IInputWrapperProps>;
}

interface ISearchableCentreSwitcherWithoutActions extends IExternalProps {
  reloadCurrentPage: boolean;
  centres: List<ICentre>;
  errors?: FormErrors;
  loading: boolean;
  searching: boolean;
  profile: IProfile;
}
interface ISearchableCentreSwitcher
  extends ISearchableCentreSwitcherWithoutActions {
  getCollection(
    type: 'centres',
    options: ICollectionOptions,
    tag: string
  ): void;
  viewCentre(centreId: string | null, reloadCurrentPage: boolean): void;
}

export class SearchableCentreSwitcher extends React.PureComponent<
  ISearchableCentreSwitcher,
  void
> {
  public constructor(props: ISearchableCentreSwitcher) {
    super(props);
    this.searchCentres = this.searchCentres.bind(this);
  }

  public componentWillMount() {
    const { profile } = this.props;
    const currentCentreId = profile.current_centre && profile.current_centre.id;
    this.searchCentres('', currentCentreId);
  }

  public render() {
    const {
      className,
      centres,
      errors,
      loading,
      profile,
      searching,
      reloadCurrentPage,
      inputWrapper,
    } = this.props;

    const supportUser = isSupportUser(profile);

    const currentCentreId =
      (profile.current_centre && profile.current_centre.id) || '';
    const placeholder = 'Start typing to search and select a centre...';
    let centreOptions = centres.map(each => ({
      value: each.id,
      label: `${each.osms_data.name} (${each.osms_data.centre_number})`,
    }));

    centreOptions = supportUser
      ? centreOptions.push({ value: '', label: 'All my centres' })
      : centreOptions.push({ value: '', label: 'All' });

    const Wrapper = inputWrapper || SearchableCenterInputWrapper;
    return (
      <div className={className}>
        <IfHasRoles roles={Set.of(DA_ADMIN, DA_SUPPORT)}>
          <Wrapper errors={errors}>
            {loading ? (
              <span className="info">Loading&hellip;</span>
            ) : (
              <ReactSelect
                options={centreOptions.toArray()}
                value={currentCentreId}
                multi={false}
                clearable={!supportUser}
                onInputChange={searchText => this.searchCentres(searchText)}
                onChange={(option: ReactSelect.Option) =>
                  this.props.viewCentre(
                    (option && (option.value as string)) || null,
                    reloadCurrentPage
                  )}
                isLoading={searching}
                placeholder={placeholder}
              />
            )}
          </Wrapper>
        </IfHasRoles>
      </div>
    );
  }

  private searchCentres(search: string, currentCentreId?: string) {
    const filters = currentCentreId ? Map({ id: currentCentreId }) : undefined;
    this.props.getCollection(
      'centres',
      {
        search,
        filters,
      },
      CENTRE_SWITCHER
    );
  }
}

function mapStateToProps(
  { collectionsOld, profile, responses }: IStore,
  { reloadCurrentPage, inputWrapper }: IExternalProps
): ISearchableCentreSwitcherWithoutActions {
  return {
    inputWrapper,
    reloadCurrentPage: reloadCurrentPage || false,
    centres: getCollectionItems(collectionsOld.centres, CENTRE_SWITCHER),
    errors: getFormErrors(responses, UPDATE_CURRENT_CENTRE),
    // We still want to show loading as we will be refreshing the page
    loading: hasSucceeded(responses, UPDATE_CURRENT_CENTRE),
    profile,
    searching: anyPending(responses, [
      [GET_COLLECTION, 'centres'],
      UPDATE_CURRENT_CENTRE,
    ]),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  viewCentre: updateCurrentCentre,
})(SearchableCentreSwitcher);
