import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import { LOAD_ITEM } from '../../items/actions';

import { Button, SpacedGroup } from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { closeModal, openModal } from '../../actions/modals';
import { IRouteProps } from '../../index';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import {
  IClassTaskRecord,
  IMarkschemeRecord,
  INestedUserTask,
  STATUS,
} from '../../store/data-types/tasks';
import { formatDate, formatDateForBackend } from '../../utils';
import Loading from '../loading';
import ConfirmModal from '../modals/confirm-modal';
import { default as SimpleTable, IColumnData } from '../tables/simple-table';
import ExamResultsIndicator from '../tasks/exam-results-indicator';
import GradeIndicator from '../tasks/grade-indicator';
import StatusIndicator from '../tasks/status-indicator';
import { default as Term, terminologyFromProfile } from '../terminology';
import MarkschemeModal from './markscheme-modal';
import TaskDetailBase from './task-detail-base';

const { actions: { loadItem } } = itemsModule;

function getHeaders(
  canHandInAny: boolean
): Array<IColumnData<INestedUserTask>> {
  return [
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/`}>
          {narrowToRecord(data.user).osms_data.name}
        </Link>
      ),
      headerLabel: 'Name',
      key: 'name',
      sortable: false,
    },
    {
      content: data => <span>{data.tagged_asset_count}</span>,
      headerLabel: 'Files',
      key: 'files',
      sortable: false,
    },
    {
      content: data => formatDate(data.end_date),
      headerLabel: 'Assignment Due Date',
      key: 'end_date',
      sortable: false,
    },
    {
      content: data => (
        <StatusIndicator task={data}>
          <GradeIndicator grade={data.grade} prefix=": " />
          <ExamResultsIndicator examResults={data.exam_results} prefix=" " />
        </StatusIndicator>
      ),
      headerLabel: 'Status',
      key: 'status',
      sortable: false,
    },
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/checklist/`}>
          View Checklist
        </Link>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

function sortCallback(left: INestedUserTask, right: INestedUserTask): number {
  if (
    (left.status === 'MARKED' && right.status === 'MARKED') ||
    (left.status !== 'MARKED' && right.status !== 'MARKED')
  ) {
    if (left.id === right.id) {
      return 0;
    }
    return left.id < right.id ? -1 : 1;
  }
  return left.status === 'MARKED' ? -1 : 1;
}

interface IProps extends IRouteProps {
  canHandInAny: boolean;
  classTaskId: string;
  classTask: IClassTaskRecord | null;
  markscheme: IMarkschemeRecord | null;
  successfullyUpdated: boolean;
  loading: boolean;
  profile: IProfile;
  openModal: typeof openModal;
  closeModal: typeof closeModal;
  loadItem(type: keyof IItemsState, itemId: string): void;
}

export class TaskDetailIQA extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.openMarkschemeModal = this.openMarkschemeModal.bind(this);
  }

  public openMarkschemeModal() {
    if (this.props.markscheme) {
      this.props.openModal(
        <MarkschemeModal
          closeModal={this.props.closeModal}
          markscheme={this.props.markscheme}
        />
      );
    }
  }

  public componentWillMount() {
    this.props.loadItem('tasks/class-task-markscheme', this.props.classTaskId);
  }

  public render() {
    const {
      canHandInAny,
      classTask,
      loading,
      successfullyUpdated,
    } = this.props;
    return (
      <TaskDetailBase
        headers={getHeaders(canHandInAny)}
        loading={loading}
        classTask={classTask}
        sortCallback={sortCallback}
        openMarkschemeModal={this.openMarkschemeModal}
      />
    );
  }
}

function mapStateToProps(
  { itemsOld, responses, profile }: IStore,
  props: IRouteProps
) {
  const classTask = itemsOld.get('tasks/class');
  return {
    ...props,
    classTask,
    markscheme: itemsOld.get('tasks/class-task-markscheme'),
    classTaskId: props.params.id,
    loading: anyPending(responses, [
      [LOAD_ITEM, 'tasks/class'],
      [LOAD_ITEM, 'tasks/class-task-markscheme'],
    ]),
    profile,
  };
}

export default connect(mapStateToProps, {
  loadItem,
  openModal,
  closeModal,
})(TaskDetailIQA);
