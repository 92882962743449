import {
  apiRequest,
  AsyncActionSet,
  makeAsyncActionSet,
  setRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { push } from 'react-router-redux';
import { Dispatch } from 'redux';
import * as _ from 'underscore';
import { clearUploadFile, clearUploadFiles, setUploadProgress } from '.';
import { collectionsModule } from '../reducers/collections';
import { GetStateCallback, TActionAny, Thunk } from '../requests/types';
import { getAWSError } from '../requests/utils';
import { checkForLogout } from '../utils';

const { actions: { addItem } } = collectionsModule;
type TOnUploadFile = (fileId: string) => TActionAny | Thunk<any> | void;
export const UPLOAD_BULK_USER_IMPORT = makeAsyncActionSet(
  'UPLOAD_BULK_USER_IMPORT'
);
export function uploadBulkUserImport(
  importId: string,
  file: File,
  onUploadFile?: TOnUploadFile
) {
  return dispatchBulkUserImportUpload(
    UPLOAD_BULK_USER_IMPORT,
    importId,
    file,
    undefined,
    onUploadFile
  );
}

function dispatchBulkUserImportUpload(
  actionSet: AsyncActionSet,
  importId: string,
  file: File,
  tag?: string,
  onUploadFile?: TOnUploadFile
) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    dispatch({ type: actionSet.REQUEST });
    dispatch(setRequestState(actionSet, 'REQUEST', tag));

    // request S3 upload details
    return apiRequest(`/api/imports/${importId}/get_upload_url/`, 'POST', {
      filename: file.name,
    })
      .then(response => {
        const formData = new FormData();
        // pass through the form values the server hands to us (to make S3 happy)
        _.forEach(
          response.data.upload_form.fields,
          (value: string, key: string) => {
            formData.append(key, value);
          }
        );
        formData.append('Content-Type', file.type);
        formData.append('file', file);
        // send to S3
        return apiRequest(
          response.data.upload_form.url,
          'POST',
          formData,
          {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          (event: ProgressEvent) => dispatch(setUploadProgress(event, 1, 1))
        ).then(responseS3 => {
          dispatch(clearUploadFile(file.name));
          return apiRequest(
            response.data.complete_url,
            'POST'
          ).then(response2 => {
            if (onUploadFile && importId) {
              const action = onUploadFile(importId);
              if (action) {
                dispatch(action as any); // Types differ due to Thunk not being natively accepted
              }
            }
            return response2;
          });
        });
      })
      .then(response => {
        dispatch({ type: actionSet.SUCCESS, payload: response.data });
        dispatch(setRequestState(actionSet, 'SUCCESS', tag));
        dispatch(clearUploadFiles());
        return response;
      })
      .catch(error => {
        checkForLogout(error);
        const errorData =
          error &&
          (getAWSError(error) || (error.response && error.response.data));
        dispatch({ type: actionSet.FAILURE, payload: errorData });
        dispatch(setRequestState(actionSet, 'FAILURE', tag));
        return Promise.reject(error);
      });
  };
}

export function addImportAndRedirectToDetailPage() {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return addItem('imports', {})(dispatch, getState)
      .then(response => {
        const entryId = response && response.data.id;
        if (entryId) {
          return dispatch(push(`/users/bulk-import/${entryId}/`));
        }
      })
      .catch(error => null);
  };
}
