import { resetRequestState } from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';

import { connect } from 'react-redux';
import { openModal } from '../../actions/modals';
import { UPDATE_ITEM } from '../../items/actions';
import { IStore } from '../../store';
import {
  IProfile,
  TUserInactiveStatusKey,
  TUserStatus,
  USER_STATUSES,
} from '../../store/data-types/profile';
import ToggleStatusModal from './toggle-status-modal';

export const userStatusKeysToActionTextMapping: {
  [P in TUserInactiveStatusKey]: Partial<{ [P in TUserStatus]: string }>
} = {
  DISABLED: {
    ACTIVE: 'Disable',
    DISABLED: 'Enable',
  },
  ARCHIVED: {
    ACTIVE: 'Archive',
    ARCHIVED: 'Restore',
  },
};

export function getUserStatusActionText(
  inactiveStatusKey: TUserInactiveStatusKey,
  userStatus: TUserStatus
) {
  if (userStatus === inactiveStatusKey) {
    return userStatusKeysToActionTextMapping[inactiveStatusKey][
      inactiveStatusKey
    ];
  }
  return userStatusKeysToActionTextMapping[inactiveStatusKey][
    USER_STATUSES.ACTIVE
  ];
}

interface IExternalProps {
  user: IProfile | null;
  inactiveStatusKey: TUserInactiveStatusKey;
  onComplete?(): void;
}

interface IProps extends IExternalProps {
  resetRequestState: typeof resetRequestState;
  openModal: typeof openModal;
}

export class ToggleStatusButton extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  public render() {
    const { user, inactiveStatusKey } = this.props;
    return (
      <a className="error" onClick={this.openModal}>
        {user && getUserStatusActionText(inactiveStatusKey, user.status)}
      </a>
    );
  }

  private openModal() {
    const { onComplete, user, inactiveStatusKey } = this.props;
    this.props.resetRequestState(UPDATE_ITEM, 'users');
    this.props.openModal(
      <ToggleStatusModal
        user={user}
        onComplete={onComplete}
        inactiveStatusKey={inactiveStatusKey}
      />
    );
  }
}

function mapStateToProps(store: IStore, props: IExternalProps) {
  return props;
}

export default connect(mapStateToProps, { openModal, resetRequestState })(
  ToggleStatusButton
);
