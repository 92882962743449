import * as React from 'react';

import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import * as classNames from 'classnames';
import { Map, Set } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { WrappedFieldProps } from 'redux-form';
import { GET_COLLECTION } from '../../../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../../../collections/reducers';
import { ICollection } from '../../../../collections/types';
import { collectionsModule } from '../../../../reducers/collections';
import { IStore } from '../../../../store';
import { IProfile, ISimpleUser } from '../../../../store/data-types/profile';
import { FormErrors } from '../../../../utils';
import FormErrorsRenderer from '../../../forms/form-errors-renderer';
import GroupField from '../../../forms/group-field';
import Loading from '../../../loading';
import Term, { terminologyFromProfile } from '../../../terminology';
import { ISearchForm } from '../../../users/search';
import RemoveStudentsWarning from '../../remove-students-warning';
import EditStudentsTable from './table';

const { actions: { getAllCollection, getCollection } } = collectionsModule;

const STUDENTS_IN_GROUP_COLLECTION = 'STUDENTS_IN_GROUP_COLLECTION';
const STUDENTS_NOT_IN_GROUP_COLLECTION = 'STUDENTS_NOT_IN_GROUP_COLLECTION';
export const PAGE_SIZE = 10;

interface IExternalProps {
  centreId: string;
  groupName: string;
}
interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
  getCollection: typeof getCollection;
}

interface IStateProps extends IExternalProps {
  loading: boolean;
  studentsNotInGroup: ReadonlyArray<ISimpleUser>;
  studentsInGroup: ReadonlyArray<ISimpleUser>;
  search: string | undefined;
  profile: IProfile;
  studentsNotInGroupCollection: ICollection<ISimpleUser>;
}

type IProps = WrappedFieldProps<void> & IDispatchProps & IStateProps;

export class EditStudentsField extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.loadStudentsInGroup = this.loadStudentsInGroup.bind(this);
    this.loadStudentsNotInGroup = this.loadStudentsNotInGroup.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onAddStudent = this.onAddStudent.bind(this);
    this.onRemoveStudent = this.onRemoveStudent.bind(this);
    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  public componentWillMount() {
    this.loadStudentsInGroup();
    this.loadStudentsNotInGroup();
  }

  public render() {
    const {
      loading,
      studentsInGroup,
      studentsNotInGroup,
      input: { value, onChange },
      search,
      profile,
      groupName,
      studentsNotInGroupCollection,
    } = this.props;

    const selectedStudents: ReadonlyArray<string> = value || [];

    return (
      <Row>
        <Column xs={12} md={6}>
          <EditStudentsTable
            students={studentsNotInGroup}
            onSearch={this.onSearch}
            selectedStudents={selectedStudents}
            title={`${terminologyFromProfile(
              profile,
              'Student'
            )}s not in ${terminologyFromProfile(profile, 'Class')}`}
            onAction={this.onAddStudent}
            noStudentsMessage={`No ${terminologyFromProfile(
              profile,
              'Student'
            )}s found to add`}
            mode="ADD"
            actionName="Add >"
            search={search}
            loading={loading}
            collection={studentsNotInGroupCollection}
            onPaginationClick={this.onPaginationClick}
          />
        </Column>
        <Column xs={12} md={6}>
          <EditStudentsTable
            students={studentsInGroup}
            selectedStudents={selectedStudents}
            title={`${groupName} ${terminologyFromProfile(
              profile,
              'Student'
            )}s`}
            onAction={this.onRemoveStudent}
            noStudentsMessage={`No ${terminologyFromProfile(
              profile,
              'Student'
            )}s found in the ${terminologyFromProfile(profile, 'Class')}`}
            mode="REMOVE"
            actionName="< Remove"
            loading={loading}
          />
        </Column>
      </Row>
    );
  }

  private onAddStudent(studentId: string) {
    const {
      input: { value, onChange },
      search,
      studentsNotInGroupCollection: { page, count },
    } = this.props;
    const selectedStudents: ReadonlyArray<string> = value || [];
    const newStudents = [...selectedStudents, studentId];
    onChange(newStudents);
    this.loadStudentsInGroup(newStudents);

    const newMaximumPage = Math.max(1, Math.ceil((count - 1) / PAGE_SIZE));
    const newPage = Math.min(page, newMaximumPage);
    this.loadStudentsNotInGroup(search, newPage, newStudents);
  }

  private onRemoveStudent(studentId: string) {
    const {
      input: { value, onChange },
      search,
      studentsNotInGroupCollection: { page },
    } = this.props;

    const selectedStudents: ReadonlyArray<string> = value || [];
    const newStudents = selectedStudents.filter(each => each !== studentId);

    onChange(newStudents);
    this.loadStudentsNotInGroup(search, page, newStudents);
  }

  private loadStudentsNotInGroup(
    search?: string,
    page?: number,
    newlySelectedStudents?: ReadonlyArray<string>
  ) {
    const {
      centreId,
      studentsNotInGroupCollection,
      input: { value },
    } = this.props;
    const searchString = search === undefined ? '' : search;
    const searchFilter = searchString
      ? Map({ search: searchString })
      : Map<string, string>();

    const currentlySelectedStudents: ReadonlyArray<string> = value || [];

    this.props.getCollection(
      'users/minimal',
      {
        filters: Map({
          user_type: 'student',
          centreId,
          id__not_in:
            newlySelectedStudents || (currentlySelectedStudents as any),
        }).merge(searchFilter),
        pageSize: PAGE_SIZE,
        page: page || studentsNotInGroupCollection.page || 1,
      },
      STUDENTS_NOT_IN_GROUP_COLLECTION
    );
  }

  private loadStudentsInGroup(studentIds?: ReadonlyArray<string>) {
    const { centreId, input: { value } } = this.props;
    this.props.getAllCollection(
      'users/minimal',
      {
        filters: Map({
          user_type: 'student',
          centreId,
          id__in: studentIds || value,
        }),
      },
      STUDENTS_IN_GROUP_COLLECTION
    );
  }

  private onSearch({ search }: ISearchForm) {
    this.loadStudentsNotInGroup(search, 1);
  }

  private onPaginationClick(
    event: React.MouseEvent<HTMLButtonElement>,
    nextPage: number
  ) {
    const { search } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.loadStudentsNotInGroup(search, nextPage);
  }
}

function mapStateToProps(
  { responses, collectionsOld, profile }: IStore,
  props: IExternalProps
): IStateProps {
  const studentsInGroup = getCollectionItems(
    collectionsOld['users/minimal'],
    STUDENTS_IN_GROUP_COLLECTION
  );
  const studentsNotInGroup = getCollectionItems(
    collectionsOld['users/minimal'],
    STUDENTS_NOT_IN_GROUP_COLLECTION
  );
  const studentsNotInGroupCollection = getCollectionByName(
    collectionsOld['users/minimal'],
    STUDENTS_NOT_IN_GROUP_COLLECTION
  );
  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, 'users/minimal'),
    search:
      studentsNotInGroupCollection.filters &&
      studentsNotInGroupCollection.filters.get('search'),
    studentsNotInGroupCollection,
    studentsInGroup: studentsInGroup.toArray(),
    studentsNotInGroup: studentsNotInGroup.toArray(),
    profile,
  };
}

export default connect(mapStateToProps, { getAllCollection, getCollection })(
  EditStudentsField
);
