import * as React from 'react';
import { connect } from 'react-redux';
import { DataShape, FormProps, reduxForm } from 'redux-form';

import { ALLOW_ANOTHER_COMPLETION_ATTEMPT } from '../../../actions/tasks';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { FormErrors } from '../../../utils';
import GroupField from '../../forms/group-field';

export const FORM_NAME = 'ALLOW_ANOTHER_COMPLETION_ATTEMPT_FORM';

interface IProps extends FormProps<DataShape, void, void> {
  errors: FormErrors | undefined;
}

export function AllowAnotherAttemptForm(props: IProps) {
  return (
    <form>
      <GroupField
        label="Reason:"
        type="text"
        name="reason"
        formErrors={props.errors}
      />
    </form>
  );
}

function mapStateToProps(
  store: IStore,
  props: FormProps<DataShape, void, void>
) {
  return {
    errors: getFormErrors(store.responses, ALLOW_ANOTHER_COMPLETION_ATTEMPT),
  };
}

const connectedAllowAnotherAttemptForm = reduxForm({
  form: FORM_NAME,
})(AllowAnotherAttemptForm);

export default connect(mapStateToProps)(connectedAllowAnotherAttemptForm);
