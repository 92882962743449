import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Column, Row } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';

import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { ITaskStatus } from '../../store/data-types/classes';
import { TStatus } from '../../store/data-types/common';
import { IUserTaskRecord, STATUS } from '../../store/data-types/tasks';
import Loading from '../loading';
import ProgressRecalculating from '../progress-recalculating';
import SimplePopover from '../simple-popover';

const { actions: { getAllCollection } } = collectionsModule;

interface IExternalProps {
  studentName: string | undefined;
  tasksCount: number;
  userId: string;
  qualificationId?: string;
  statuses: ReadonlyArray<TStatus>;
}

interface IStateProps {
  tasks: List<IUserTaskRecord>;
  isLoading: boolean;
}

interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export function PopoverStudentTasks({
  tasks,
  studentName,
}: {
  tasks: ReadonlyArray<ITaskStatus>;
  studentName: string | undefined;
}): JSX.Element {
  return (
    <div className="student-popover">
      <Row className="task-name">
        <strong>{studentName || '--'}</strong>
      </Row>
      {tasks.length ? (
        tasks.map((task: ITaskStatus) => (
          <Row key={task.id}>
            <Column className="task-title">
              <Link to={`/tasks/marksheet/${task.id}/`}>{task.title}</Link>
            </Column>
            <Column className="task-progress">
              {task.progress === null ? (
                <ProgressRecalculating />
              ) : (
                `${(task.progress * 100).toFixed(0)}%`
              )}
            </Column>
          </Row>
        ))
      ) : (
        <span>No tasks found.</span>
      )}
    </div>
  );
}

function formatTaskForPopoverContent(task: IUserTaskRecord): ITaskStatus {
  return {
    id: task.id,
    status: task.status,
    title: task.class_task.component.title,
    progress: task.progress,
  };
}

const REQUEST_NAME_PREPEND = 'TASKS_BY_STATUS_POPOVER';
function buildCollectionName(
  userId: string,
  status: ReadonlyArray<TStatus>,
  qualificationId?: string
) {
  return `${REQUEST_NAME_PREPEND}-${userId}-${qualificationId}-${status}`;
}

export class TasksByStatusPopover extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.getTaskStatuses = this.getTaskStatuses.bind(this);
  }

  public render() {
    const { tasks, studentName, isLoading, tasksCount } = this.props;

    const TaskStatuses = tasks
      .map((task: IUserTaskRecord) => formatTaskForPopoverContent(task))
      .toArray();

    return tasksCount === 0 ? (
      <span>0</span>
    ) : (
      <SimplePopover
        bottom
        maxWidth={350}
        width={350}
        toggleComponent={<a onClick={() => undefined}>{tasksCount}</a>}
        onOpen={this.getTaskStatuses}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <PopoverStudentTasks tasks={TaskStatuses} studentName={studentName} />
        )}
      </SimplePopover>
    );
  }

  private getTaskStatuses() {
    const { userId, qualificationId, statuses } = this.props;
    const filters = Map({
      user: userId,
      status: statuses.join(','),
    });
    this.props.getAllCollection(
      'tasks',
      {
        filters: qualificationId
          ? filters.set('qualification', qualificationId)
          : filters,
      },
      buildCollectionName(userId, statuses, qualificationId)
    );
  }
}

function mapStateToProps(
  state: IStore,
  props: IExternalProps
): IStateProps & IExternalProps {
  const { collectionsOld, responses } = state;
  const { userId, qualificationId, statuses } = props;
  return {
    ...props,
    isLoading: isPending(responses, GET_COLLECTION, 'tasks'),
    tasks: getCollectionItems(
      collectionsOld.get('tasks'),
      buildCollectionName(userId, statuses, qualificationId)
    ),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
})(TasksByStatusPopover);
