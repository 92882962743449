import {
  AsyncActionSet,
  hasFailed,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, Button, SpacedGroup } from '@dabapps/roe';
import { AxiosPromise, AxiosResponse } from 'axios';
import { List, Map } from 'immutable';
import * as React from 'react';
import * as Dropzone from 'react-dropzone';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { LOAD_ITEM } from '../../items/actions';
import {
  AsyncAction,
  DispatchCallback,
  TActionAny,
  Thunk,
} from '../../requests/types';

import { setUploadFiles, UPLOAD_ASSET, uploadAssets } from '../../actions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store/';
import {
  IAssetRecord,
  IFileProgressRecord,
  IQuotaRecord,
  TResponseErrors,
} from '../../store/data-types';
import { convertMegaBytesToBytes, formatBytes } from '../../utils';

const { IconFileTextO, IconInfoCircle } = FontAwesome;
const { actions: { loadItem } } = itemsModule;

export const DEFAULT_FILE_SIZE_LIMIT = convertMegaBytesToBytes(100); // 100MB

// NOTE: this only gets called of you don't override uploadFiles
export type TAfterSuccessfulUpload = (newAssetIds: List<string>) => void;

interface IExternalProps {
  uploadActionSet?: AsyncActionSet;
  multiple?: boolean;
  ownerUserId: string;
  customSuccessMessage?: string;
  resetRequestStateOnUnmount?: boolean;

  afterSuccessfulUpload?: TAfterSuccessfulUpload;
  uploadFiles?(files: List<File>): void;
}

interface IConnectedProps {
  errors: TResponseErrors;
  failed: boolean;
  fileUploadProgress: IFileProgressRecord | null;
  filesToUpload: List<File>;
  hasFileLimitExemption: boolean;
  loading: boolean;
  uploaded: boolean;
  uploading: boolean;
  userId: string;
}

interface IDispatchProps {
  loadItem(type: keyof IItemsState, id: string): void;
  resetRequestState(actionSet: AsyncActionSet): void;
  setUploadFiles(file: List<File>): void;
  internalUploadFiles(file: List<File>): Promise<List<string>>;
}

type IProps = IExternalProps & IConnectedProps & IDispatchProps;

export class FileUpload extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.validateFiles = this.validateFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem('assets/quotas', this.props.userId);
  }

  public componentWillUnmount() {
    if (this.props.resetRequestStateOnUnmount) {
      this.props.resetRequestState(this.props.uploadActionSet || UPLOAD_ASSET);
    }
    this.props.setUploadFiles(List<File>());
  }

  public onFileChange(files: FileList | null) {
    this.props.setUploadFiles(List<File>(files || []));
    this.props.resetRequestState(this.props.uploadActionSet || UPLOAD_ASSET);
  }

  public uploadFiles() {
    const {
      filesToUpload,
      afterSuccessfulUpload,
      internalUploadFiles,
      uploadFiles,
    } = this.props;
    const isValid = this.validateFiles();

    if (filesToUpload && isValid) {
      if (uploadFiles) {
        uploadFiles(filesToUpload);
      } else {
        internalUploadFiles(filesToUpload).then(assetIds => {
          if (afterSuccessfulUpload) {
            afterSuccessfulUpload(assetIds);
          }
        });
      }
    }
  }

  public render() {
    const {
      filesToUpload,
      uploading,
      uploaded,
      failed,
      errors,
      fileUploadProgress,
      loading,
      hasFileLimitExemption,
      customSuccessMessage,
    } = this.props;

    const hasSelectedFiles = !!filesToUpload.count();
    const errorMessage =
      (errors && (errors.get('non_field_errors') || errors.get('file'))) ||
      'please try again later.';

    let helpText = '';

    if (loading) {
      helpText = 'Loading...';
    } else if (hasSelectedFiles) {
      if (this.props.multiple) {
        helpText = 'Drop new files here or click to change them...';
      } else {
        helpText = 'Drop new file here or click to change it...';
      }
    } else {
      if (this.props.multiple) {
        helpText = 'Drop files here or click to select them...';
      } else {
        helpText = 'Drop file here or click to select it...';
      }
    }

    const title = this.props.multiple ? 'Upload File(s)' : 'Upload File';
    const fileText = this.props.multiple ? 'Files' : 'File';

    let dropzoneRef: { open: () => void };

    let fileExemptionUsed = !hasFileLimitExemption;

    return (
      <div className="file-upload">
        <label>
          <strong>{title}</strong>
        </label>

        {!uploading && (
          <Dropzone
            // open is not exposed by Dropzone types, so we do the type-dance.
            ref={node => {
              dropzoneRef = node as any;
            }}
            onDrop={this.onFileChange}
            multiple={this.props.multiple}
            className="file-input"
          >
            {hasSelectedFiles ? (
              <ul>
                {filesToUpload.map((asset: File) => {
                  const tooLarge = asset.size > DEFAULT_FILE_SIZE_LIMIT;
                  const component = (
                    <li key={asset.name}>
                      <IconFileTextO />
                      {asset.name} ({formatBytes(asset.size)}){' '}
                      <Button
                        className="error small"
                        onClick={event => this.removeFile(event, asset)}
                      >
                        x
                      </Button>
                      {tooLarge &&
                        fileExemptionUsed && (
                          <div className="font-size-small error">
                            File too large (Limit is{' '}
                            {formatBytes(DEFAULT_FILE_SIZE_LIMIT)}).<br />
                          </div>
                        )}
                      {tooLarge &&
                        !fileExemptionUsed && (
                          <div className="font-size-small meta margin-left-base">
                            File limit exemption used
                          </div>
                        )}
                    </li>
                  );
                  fileExemptionUsed = fileExemptionUsed || tooLarge;
                  return component;
                })}
                <span className="text-help">
                  <IconInfoCircle />
                  {helpText}
                </span>
              </ul>
            ) : (
              <span className="text-help">
                <IconInfoCircle />
                {helpText}
              </span>
            )}
          </Dropzone>
        )}

        {uploading && (
          <div className="file-progress">
            <div className="progress-bar margin-top-large">
              <div
                className="progress-fill"
                style={{
                  width: `${fileUploadProgress
                    ? fileUploadProgress.progress
                    : 0}%`,
                }}
              />
            </div>
            {fileUploadProgress ? (
              <p>
                Uploading file {fileUploadProgress.fileNumber} of{' '}
                {fileUploadProgress.totalFiles}&hellip;
              </p>
            ) : (
              <p>Uploading files&hellip;</p>
            )}
          </div>
        )}

        <SpacedGroup>
          <Button
            disabled={uploading}
            onClick={() => {
              dropzoneRef.open();
            }}
          >
            Choose {fileText}
          </Button>
          <Button
            disabled={!hasSelectedFiles || uploading}
            onClick={() => this.uploadFiles()}
          >
            Upload {fileText}
          </Button>
        </SpacedGroup>

        {uploaded && (
          <Alert className="success">
            <p>
              {customSuccessMessage || (
                <span>{fileText} uploaded successfully!</span>
              )}
            </p>
          </Alert>
        )}
        {failed && (
          <Alert className="error">
            <p>Upload failed: {errorMessage}</p>
          </Alert>
        )}
      </div>
    );
  }

  private removeFile(event: React.MouseEvent<HTMLElement>, file: File) {
    event.preventDefault();
    event.stopPropagation();
    const { filesToUpload } = this.props;
    const fileIndex = filesToUpload.findIndex(each => each === file);
    if (filesToUpload.get(fileIndex)) {
      this.props.setUploadFiles(filesToUpload.remove(fileIndex));
    }
  }

  private validateFiles(): boolean {
    const { filesToUpload, hasFileLimitExemption } = this.props;
    return (
      hasFileLimitExemption ||
      filesToUpload
        .filter(file => file.size > DEFAULT_FILE_SIZE_LIMIT)
        .count() === 0
    );
  }
}

function mapStateToProps(
  {
    assetUploadErrors,
    filesToUpload,
    responses,
    fileUploadProgress,
    itemsOld,
    profile,
  }: IStore,
  props: IExternalProps
): IExternalProps & IConnectedProps {
  const actionSet = props.uploadActionSet || UPLOAD_ASSET;
  const quota = itemsOld.get('assets/quotas');
  const hasFileLimitExemption = (quota && quota.exemption) || false;
  return {
    ...props,
    errors: assetUploadErrors,
    failed: hasFailed(responses, actionSet),
    fileUploadProgress,
    filesToUpload,
    hasFileLimitExemption,
    loading: isPending(responses, LOAD_ITEM, 'assets/quotas'),
    uploaded: hasSucceeded(responses, actionSet),
    uploading: isPending(responses, actionSet),
    userId: profile.id,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<IStore>,
  props: IExternalProps & IConnectedProps
): IProps {
  return {
    ...props,
    loadItem: (type: keyof IItemsState, id: string) =>
      dispatch(loadItem(type, id)),
    resetRequestState: (actionSet: AsyncActionSet) =>
      dispatch(resetRequestState(actionSet)),
    setUploadFiles: (files: List<File>) => dispatch(setUploadFiles(files)),
    internalUploadFiles: (files: List<File>) =>
      dispatch(uploadAssets(files, props.ownerUserId, 1, files.count())),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
