import { ModalBody } from '@dabapps/roe';
import * as React from 'react';

export default class TagFiles extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <ModalBody className="scrollable">
        <p>Your files have been successfully uploaded and tagged.</p>
      </ModalBody>
    );
  }
}
