import { Button, FormGroup, Section, Well } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldInputProps,
  WrappedFieldProps,
} from 'redux-form';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import { FormErrors } from '../../../utils';
import DateInput from '../../date-input';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import Loading from '../../loading';
import QualificationMandatoryUnitsWarning from '../../qualification-mandatory-units-warning';
import Term from '../../terminology';
import QualificationWithoutDatesWarning from '../qualification-without-dates-warning';

const { IconUsers } = FontAwesome;

const CREATE_QUALIFICATION = 'CREATE_QUALIFICATION';

interface ISelectClassesProps extends WrappedFieldProps<void> {
  classes: List<IClassRecord>;
}
export function SelectClasses({ classes, input }: ISelectClassesProps) {
  const value: Set<string> = (input && input.value) || Set();
  const onChange = input ? input.onChange : () => null;
  const options = classes
    .filterNot(each => value.contains(each.id))
    .map(each => ({ value: each.id, label: each.name }))
    .toJS();
  return (
    <div>
      <Well className="margin-vertical-base padding-base">
        <label>
          Selected <Term>Classes</Term>
        </label>
        <ul className="list-style-none margin-vertical-base">
          {value.map(each => {
            const valueClass = classes.find(item => item.id === each);
            return (
              valueClass && (
                <li key={each} className="margin-bottom-small">
                  <IconUsers className="icon-small" />
                  <span className="margin-horizontal-base">
                    {valueClass.name}
                  </span>
                  <Button onClick={() => onChange(value.remove(each) as any)}>
                    Remove
                  </Button>
                </li>
              )
            );
          })}
          {value.count() === 0 && <li className="info">None selected...</li>}
        </ul>
      </Well>
      <ReactSelect
        options={options}
        onChange={(newValue: ReactSelect.Option) =>
          onChange(value.add(newValue.value as string) as any)}
      />
    </div>
  );
}

interface IExternalProps extends React.HTMLProps<JSX.Element> {
  classes: List<IClassRecord>;
  formErrors?: FormErrors;
  loading: boolean;
}

interface IProps extends IExternalProps {
  selected_start_date?: moment.Moment;
  selected_end_date?: moment.Moment;
}

interface ICreateQualificationFormProps
  extends FormProps<DataShape, void, void>,
    IProps {}

type TCreateQualificationFormProps = React.HTMLProps<JSX.Element> &
  FormProps<DataShape, void, void> &
  ICreateQualificationFormProps;

export class CreateQualificationForm extends React.PureComponent<
  TCreateQualificationFormProps,
  void
> {
  public render() {
    const {
      classes,
      formErrors,
      handleSubmit,
      loading,
      selected_start_date,
      selected_end_date,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Section>
          <FormGroup>
            <label className="margin-right-base">Start Date</label>
            <Field
              name="start_date"
              component={({ input }: WrappedFieldProps<IStore>) => (
                <DateInput input={input} minDate={moment.utc()} />
              )}
            />
          </FormGroup>
          <FormErrorsRenderer formErrors={formErrors} errorKey="start_date" />

          <FormGroup>
            <label className="margin-right-base">End Date</label>
            <Field
              name="end_date"
              component={({ input }: WrappedFieldProps<IStore>) => (
                <DateInput input={input} minDate={moment.utc()} />
              )}
            />
          </FormGroup>
          <FormErrorsRenderer formErrors={formErrors} errorKey="end_date" />
          {(!selected_start_date || !selected_end_date) && (
            <QualificationWithoutDatesWarning />
          )}
          <Section />
          <FormGroup block>
            <label className="margin-right-base">
              <Term>Classes</Term>
            </label>
            <Field
              name="classes"
              component={(props: WrappedFieldProps<IStore>) => (
                <SelectClasses {...props} classes={classes} />
              )}
            />
            <FormErrorsRenderer formErrors={formErrors} errorKey="classes" />
          </FormGroup>
          <QualificationMandatoryUnitsWarning />
        </Section>
        <Section>
          {loading && <Loading className="small" />}
          <Button className="tertiary" type="submit" disabled={loading}>
            Create Qualification
          </Button>
          <FormErrorsRenderer
            formErrors={formErrors}
            errorKey="non_field_errors"
          />
        </Section>
      </form>
    );
  }
}

const Formified = reduxForm({
  form: CREATE_QUALIFICATION,
})(CreateQualificationForm);

const selector = formValueSelector(CREATE_QUALIFICATION);

function mapStateToProps(store: IStore, props: IExternalProps): IProps {
  return {
    ...props,
    selected_start_date: selector(store, 'start_date'),
    selected_end_date: selector(store, 'end_date'),
  };
}

export default connect(mapStateToProps)(Formified);
