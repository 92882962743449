import * as React from 'react';

import { fromJS, List } from 'immutable';
import { FormErrors } from '../../utils';
import Term from '../terminology';

interface IProps {
  formErrors?: FormErrors;
  errorKey: string | List<string | number>;
}

// TODO: Replace this, as it is really, really awful
function getErrors({ formErrors, errorKey }: IProps): List<string> {
  if (!formErrors) {
    return List();
  }

  const immutableFormErrors = fromJS(formErrors);

  const errors: List<string> | string =
    typeof errorKey === 'string'
      ? immutableFormErrors.get(errorKey, List())
      : immutableFormErrors.getIn(errorKey, List());
  if (typeof errors === 'string') {
    return List.of(errors);
  }
  return errors;
}

export default class FormErrorsRenderer extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { formErrors } = this.props;

    if (!formErrors) {
      return null;
    }

    const errors: List<string> = getErrors(this.props);

    return (
      <div>
        {errors.map(error => (
          <p className="error" key={error}>
            <Term>{error}</Term>
          </p>
        ))}
      </div>
    );
  }
}
