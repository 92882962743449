import * as React from 'react';

import { Column } from '@dabapps/roe';

interface IProgressBarProps {
  progress: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

type TProgressBarProps = IProgressBarProps & React.HTMLProps<HTMLDivElement>;

export const ProgressBar: React.SFC<TProgressBarProps> = ({
  progress,
  xs,
  sm,
  md,
  lg,
}) => {
  const progressPercent =
    !progress && progress !== 0
      ? 'Nothing to display'
      : `${Math.round(progress * 100)}%`;

  return (
    <Column xs={xs} sm={sm} md={md} lg={lg}>
      <div className="progress-bar">
        <div className="progress-percent">{progressPercent}</div>
        <div
          className="progress-bar-inner"
          style={{ width: progressPercent }}
        />
      </div>
    </Column>
  );
};

export default ProgressBar;
