import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { MANAGE_EQAS_CENTRES } from '.';
import { getCollectionByName } from '../../../collections/reducers';
import {
  // tslint:disable-next-line:no-unused-variable
  ICollection,
  ICollectionOptions,
} from '../../../collections/types';
import { IStore } from '../../../store';
import { ICentre } from '../../../store/data-types/centres';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';

function getHeaders(): Array<IColumnData<ICentre>> {
  return [
    {
      content: data => (
        <span className="display-inline-block">
          {data.osms_data.name}
          <span className="font-size-small font-weight-normal display-block">
            {data.osms_data.centre_number}
          </span>
        </span>
      ),
      headerLabel: 'Name',
      key: 'centreprofilecache__name',
      sortable: false,
    },
    {
      content: data => data.assigned_external_quality_assurer_count,
      headerLabel: 'Assigned EQA Count',
      key: 'assigned_external_quality_assurer_count',
      sortable: false,
    },
    {
      content: data => {
        return (
          <Link to={`/users/manage-external-quality-assurers/${data.id}/`}>
            Manage External Quality Assurers
          </Link>
        );
      },
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

interface IExternalProps {
  loading?: boolean;
  loadCentres(options?: ICollectionOptions): void;
}

interface IStateProps {
  centresCollection: ICollection<ICentre>;
}

type IProps = IExternalProps & IStateProps;

export class CentresTable extends React.PureComponent<IProps, void> {
  public render() {
    const { centresCollection, loadCentres } = this.props;
    return (
      <CollectionTable
        headers={getHeaders()}
        loading={this.props.loading}
        collection={centresCollection}
        pageSize={10}
        getCollection={loadCentres}
      />
    );
  }
}

function mapStateToProps({ collectionsOld }: IStore, props: IExternalProps) {
  return {
    ...props,
    centresCollection: getCollectionByName(
      collectionsOld.centres,
      MANAGE_EQAS_CENTRES
    ),
  };
}

export default connect(mapStateToProps, {})(CentresTable);
