import { ModalBody } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { UPLOAD_ASSET } from '../../../actions';
import FileUpload from '../file-upload';

interface IExternalProps {
  ownerUser: string;
  afterSuccessfulUpload(assetIds: List<string>): void;
}

export default class UploadFiles extends React.PureComponent<
  IExternalProps,
  {}
> {
  public render() {
    const { ownerUser, afterSuccessfulUpload } = this.props;
    return (
      <ModalBody className="scrollable">
        <FileUpload
          ownerUserId={ownerUser}
          afterSuccessfulUpload={afterSuccessfulUpload}
          uploadActionSet={UPLOAD_ASSET}
          multiple
        />
      </ModalBody>
    );
  }
}
