import * as React from 'react';

import { List, Set } from 'immutable';
import { connect } from 'react-redux';
import { redirectIfNotRole } from '../../actions/permissions';
import {
  CAN_BULK_IMPORT,
  CAN_MANAGE_EQAS,
  CAN_VIEW_USERS,
  currentCentreAllowsMembersToCreateLearners,
  isOnStudentViewPage,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store';
import { IProfile, TRole } from '../../store/data-types/profile';
import NavItem from '../nav/item';
import SubNav from '../nav/sub';
import If from '../permissions/if';
import IfHasRoles from '../permissions/if-has-roles';

const MATCHES_USERS = /^\/users\/$/;
const MATCHES_BULK_IMPORT = /^\/users\/bulk-import\//;
const MATCHES_MANAGE_EQAS = /^\/users\/manage-external-quality-assurers\//;

interface IExternalProps {
  location: {
    pathname: string;
  };
}

interface IProps extends IExternalProps {
  profile: IProfile;
  redirectIfNotRole(
    roles: Set<TRole>,
    redirectPath: string,
    user: IProfile
  ): void;
}

export class UsersPage extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.checkPathAndRedirect(this.props);
  }

  public componentWillUpdate(nextProps: IProps) {
    this.checkPathAndRedirect(nextProps);
  }

  public render() {
    const { children, location: { pathname }, profile } = this.props;

    return (
      <div>
        {!isOnStudentViewPage(pathname) && (
          <SubNav>
            <NavItem href="/users/" pathname={pathname} matches={MATCHES_USERS}>
              Users List
            </NavItem>
            <If
              condition={
                userHasRoles(profile, CAN_BULK_IMPORT) &&
                currentCentreAllowsMembersToCreateLearners(profile)
              }
            >
              <NavItem
                href="/users/bulk-import/"
                pathname={pathname}
                matches={MATCHES_BULK_IMPORT}
              >
                Bulk Import Users
              </NavItem>
            </If>
            <IfHasRoles roles={CAN_MANAGE_EQAS}>
              <NavItem
                href="/users/manage-external-quality-assurers/"
                pathname={pathname}
                matches={MATCHES_MANAGE_EQAS}
              >
                Manage External Quality Assurers
              </NavItem>
            </IfHasRoles>
          </SubNav>
        )}

        {children}
      </div>
    );
  }

  private checkPathAndRedirect(props: IProps) {
    const { location: { pathname }, profile } = props;
    if (MATCHES_USERS.test(pathname)) {
      return props.redirectIfNotRole(CAN_VIEW_USERS, '/', profile);
    }
    if (MATCHES_BULK_IMPORT.test(pathname)) {
      return props.redirectIfNotRole(CAN_BULK_IMPORT, '/', profile);
    }
  }
}

function mapStateToProps({ profile }: IStore, { location }: IExternalProps) {
  return {
    location,
    profile,
  };
}

export default connect(mapStateToProps, { redirectIfNotRole })(UsersPage);
