import {
  hasFailed,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modals';
import {
  IUserBulkStatusUpdate,
  USER_BULK_STATUS_UPDATE,
  userBulkStatusUpdate,
} from '../../actions/users';
import { IStore } from '../../store';
import {
  IProfile,
  TUserInactiveStatusKey,
  TUserStatus,
  USER_STATUSES,
} from '../../store/data-types/profile';
import ModalCloseIcon from '../modals/modal-close-icon';
import { getUserStatusActionText } from './toggle-status-button';

export const userStatusToggleMapping: {
  [P in TUserInactiveStatusKey]: Partial<{ [P in TUserStatus]: TUserStatus }>
} = {
  DISABLED: {
    ACTIVE: 'DISABLED',
    DISABLED: 'ACTIVE',
  },
  ARCHIVED: {
    ACTIVE: 'ARCHIVED',
    ARCHIVED: 'ACTIVE',
    DISABLED: 'ARCHIVED',
  },
};

interface IExternalProps {
  currentStatusFilter: TUserStatus | undefined;
  bulkUserNames: List<string | undefined>;
  bulkUserIds: List<string>;
  inactiveStatusKey: TUserInactiveStatusKey;
  onComplete?(): void;
}

interface IDispatchProps {
  resetRequestState: typeof resetRequestState;
  closeModal(): void;
  userBulkStatusUpdate(data: IUserBulkStatusUpdate): AxiosPromise;
}

interface IStateProps {
  loading: boolean;
  failed: boolean;
  succeeded: boolean;
}

type IProps = IExternalProps & IDispatchProps & IStateProps;

export class BulkStatusUpdateModal extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.reset = this.reset.bind(this);
  }

  public componentWillMount() {
    this.reset();
  }

  public render() {
    const {
      loading,
      succeeded,
      failed,
      currentStatusFilter,
      bulkUserNames,
    } = this.props;
    const actionText =
      currentStatusFilter && currentStatusFilter === USER_STATUSES.ARCHIVED
        ? 'Restore'
        : 'Archive';
    return (
      <Modal scrollable onClickOutside={this.closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.closeModal} />
          <p>Bulk User Update</p>
        </ModalHeader>
        <ModalBody>
          <Section>
            <h3>{actionText} Users</h3>
            <div>
              {!succeeded && (
                <p>
                  Are you sure you want to{' '}
                  <strong>{actionText.toLowerCase()}</strong> the following
                  users?:
                </p>
              )}
              {failed && (
                <Alert className="error">
                  <p>
                    Something went wrong: unable to {actionText.toLowerCase()}{' '}
                    the selected users.
                  </p>
                </Alert>
              )}
              {succeeded && (
                <Alert className="success">
                  <p>
                    Successfully {actionText.toLowerCase()}d the following
                    users:
                  </p>
                </Alert>
              )}
              {!loading && (
                <ul>{bulkUserNames.map(name => <li key={name}>{name}</li>)}</ul>
              )}
            </div>
          </Section>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup className="display-block margin-vertical-large">
            {!succeeded && (
              <Button
                onClick={this.updateStatus}
                className="error"
                disabled={loading}
              >
                {actionText}
              </Button>
            )}
            <Button onClick={this.closeModal} disabled={loading}>
              {succeeded ? 'Close' : 'Cancel'}
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </Modal>
    );
  }

  private updateStatus() {
    const {
      onComplete,
      inactiveStatusKey,
      bulkUserIds,
      currentStatusFilter,
    } = this.props;
    if (bulkUserIds && currentStatusFilter) {
      const updateStatusPromise = this.props.userBulkStatusUpdate({
        user_ids: bulkUserIds.toArray(),
        status: userStatusToggleMapping[inactiveStatusKey][currentStatusFilter],
      });
      if (onComplete) {
        updateStatusPromise.then(() => onComplete());
      }
    }
  }

  private reset() {
    this.props.resetRequestState(USER_BULK_STATUS_UPDATE);
  }

  private closeModal() {
    this.props.closeModal();
    this.reset();
  }
}

function mapStateToProps({ responses }: IStore, props: IExternalProps) {
  return {
    ...props,
    loading: isPending(responses, USER_BULK_STATUS_UPDATE),
    failed: hasFailed(responses, USER_BULK_STATUS_UPDATE),
    succeeded: hasSucceeded(responses, USER_BULK_STATUS_UPDATE),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  userBulkStatusUpdate,
  resetRequestState,
})(BulkStatusUpdateModal);
