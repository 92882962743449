import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

import { IStore } from '../store/';

const { IconSpinner } = FontAwesome;

interface IExternalProps {
  className?: string;
}

interface IProps extends IExternalProps {
  loadingSpinners: number;
}

export class LoadingSpinnerRenderer extends React.PureComponent<IProps, void> {
  public render() {
    if (!this.props.loadingSpinners) {
      return null;
    }

    const { className } = this.props;

    return (
      <div className={classNames('loading-spinner', className)}>
        <IconSpinner />
      </div>
    );
  }
}

const mapStateToProps = (
  { loadingSpinners }: IStore,
  props: IExternalProps
) => ({
  ...props,
  loadingSpinners,
});

export default connect(mapStateToProps)(LoadingSpinnerRenderer);
