import {
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  SpacedGroup,
} from '@dabapps/roe';
import * as React from 'react';
import { Link } from 'react-router';
import { getCourseWorkProgressOverviewFor } from './utils';

export default class CourseworkProgressOverviewSubmit1 extends React.PureComponent<
  {},
  void
> {
  public render() {
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            {getCourseWorkProgressOverviewFor(
              'Submitting Coursework',
              null,
              null
            )}
          </h2>
        </ContentBoxHeader>
        <h3>Step 1/2 - Select submission type</h3>

        <p>
          You now have to make a choice dependent on whether or not you have
          already created a claim in OCR Interchange for the students and units
          being submitted for moderation.
        </p>

        <p>
          Choose <strong>Named</strong> if you have already created a claim and
          have a claim number. You will be able to enter the claim number and
          your MAPS3 password before submitting to OCR.
        </p>

        <p>
          Choose <strong>Unnamed</strong> if you haven't created a claim yet and
          want to do so directly from MAPS. You will be prompted to select which
          qualification to claim the units against, the claim will be created
          and the claim number populated. You will then be able to enter your
          MAPS3 password before submitting to OCR.
        </p>

        <ContentBoxFooter>
          <SpacedGroup className="margin-vertical-large float-left">
            <Link
              to="/tasks/overview/submit/step-2/named/"
              className="button tertiary"
            >
              Submit named entries
            </Link>
            <Link
              to="/tasks/overview/submit/step-2/unnamed/"
              className="button tertiary"
            >
              Submit unnamed entries
            </Link>
            <Link to="/tasks/overview/">Cancel</Link>
          </SpacedGroup>
        </ContentBoxFooter>
      </ContentBox>
    );
  }
}
