import * as React from 'react';

import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { FormGroup, SpacedGroup } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { Map, Set } from 'immutable';
import { connect } from 'react-redux';
import {
  ADD_COMMENT,
  addComment,
  closeNewReply,
  openNewReply,
  updateReply,
} from '../../actions/comments';
import { IStore } from '../../store/';
import { CommentRecord, ICommentRecord } from '../../store/data-types';

interface IExternalProps {
  commentId: string;
  initialText?: string;
  onCommentAdded?(): void;
}

interface IProps extends IExternalProps {
  openReplies: Set<string>;
  replies: Map<string, string>;
  loading: boolean;
  openNewReply(commentId: string): void;
  closeNewReply(commentId: string): void;
  addComment(content: string, parent: string | null): AxiosPromise;
  updateReply(commentId: string, content: string): void;
}

const KEYCODE_ENTER = 13;

export class CommentBox extends React.PureComponent<IProps, void> {
  public onClickOpenNewReply(commentId: string) {
    this.props.openNewReply(commentId);
  }

  public onClickCloseNewReply(commentId: string) {
    if (this.props.loading) {
      return;
    }

    this.props.closeNewReply(commentId);
  }

  public onClickAddComment(commentId: string) {
    this.submitComment(commentId);
  }

  public onChangeReply(
    event: React.ChangeEvent<HTMLTextAreaElement>,
    commentId: string
  ) {
    this.props.updateReply(commentId, event.target.value);
  }

  public submitComment(commentId: string) {
    if (this.props.loading) {
      return;
    }

    const addCommentPromise = this.props.addComment(
      this.props.replies.get(commentId, ''),
      commentId
    );

    if (this.props.onCommentAdded) {
      addCommentPromise.then(this.props.onCommentAdded);
    }
  }

  public onKeyDownHandleSubmit(
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    commentId: string
  ) {
    if (event.keyCode === KEYCODE_ENTER && event.shiftKey) {
      this.submitComment(commentId);
    }
  }

  public render() {
    const {
      commentId,
      openReplies,
      initialText = 'Reply',
      loading,
    } = this.props;

    return openReplies.includes(commentId) ? (
      <div>
        <FormGroup block>
          <textarea
            disabled={loading}
            placeholder="Your comment..."
            onChange={event => this.onChangeReply(event, commentId)}
            onKeyDown={event => this.onKeyDownHandleSubmit(event, commentId)}
          />
        </FormGroup>
        <SpacedGroup className="margin-vertical-large">
          <a
            disabled={loading}
            onClick={() => this.onClickCloseNewReply(commentId)}
          >
            Cancel
          </a>
          <a
            disabled={loading}
            onClick={() => this.onClickAddComment(commentId)}
          >
            Submit
          </a>
        </SpacedGroup>
      </div>
    ) : (
      <p>
        <a onClick={() => this.onClickOpenNewReply(commentId)}>{initialText}</a>
      </p>
    );
  }
}

function mapStateToProps(
  { openReplies, replies, responses }: IStore,
  props: IExternalProps
) {
  return {
    ...props,
    loading: isPending(responses, ADD_COMMENT),
    openReplies,
    replies,
  };
}

const mapDispatchToProps = {
  addComment,
  closeNewReply,
  openNewReply,
  updateReply,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentBox);
