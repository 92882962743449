import {
  anyPending,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Column, Row, SpacedGroup, Well } from '@dabapps/roe';
import { AxiosPromise, AxiosResponse } from 'axios';
import * as classNames from 'classnames';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

import { GET_ASSETS } from '../../actions';
import { closeModal, openModal } from '../../actions/modals';
import { DELETE_ASSET, deleteAsset } from '../../actions/quotas';
import { STUDENT, userHasRoles } from '../../permissions';
import { IStore } from '../../store/';
import { IAssetRecord } from '../../store/data-types';
import { EvidenceType } from '../../store/data-types/constants';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import {
  formatBytes,
  formatDate,
  getAssetName,
  getEvidenceTypeDisplay,
} from '../../utils';
import EllipsizedFileName from '../ellipsized-file-name';
import ImageHolder from '../image-holder';
import Loading from '../loading';
import MaybeAssetAnchor from '../maybe-asset-anchor';
import ConfirmModal from '../modals/confirm-modal';
import SimplePopover from '../simple-popover';
import Term from '../terminology';
import FileIcon from './file-icon';
import FilePropertiesModal from './file-properties-modal';
import TagFilesModal from './tag-files/tag-files-modal';

const { IconTrash, IconTags } = FontAwesome;

interface IExternalProps {
  student?: ISimpleUser;
  loadAssets(): void;
}

interface IFileListProps extends IExternalProps {
  assets: List<IAssetRecord>;
  profile: IProfile;
  loadingAssets: boolean;
  fileInteractionDisabled: boolean;
  evidenceTypes: ReadonlyArray<EvidenceType>;
  deleteAsset(assetId: string): AxiosPromise;
  openModal(element: React.ReactNode): AxiosPromise;
  closeModal(): AxiosPromise;
}

export class FileList extends React.PureComponent<IFileListProps, void> {
  public constructor(props: IFileListProps) {
    super(props);
    this.isPreventedFromTagOrDeleteFile = this.isPreventedFromTagOrDeleteFile.bind(
      this
    );
    this.deleteAsset = this.deleteAsset.bind(this);
  }

  public deleteAsset(asset: IAssetRecord): void {
    const { openModal, closeModal, profile, student } = this.props;

    openModal(
      <ConfirmModal
        closeModal={closeModal}
        title="Delete file"
        body={
          <p>
            About to <strong>delete</strong> {getAssetName(asset)} Are you sure?
          </p>
        }
        okayLabel="Delete"
        okayClass="error"
        onCancelClick={closeModal}
        onOkayClick={() => {
          this.props
            .deleteAsset(asset.id)
            .then((response: AxiosResponse) => {
              this.props.loadAssets();
              this.props.closeModal();
            })
            .catch((error: any) => console.error(error)); // tslint:disable-line:no-console
        }}
      />
    );
  }

  public render() {
    const {
      profile,
      assets,
      fileInteractionDisabled,
      loadingAssets,
      evidenceTypes,
      student,
    } = this.props;

    const fileClassName = [
      'column xs-12 sm-6 md-4 lg-3 file',
      fileInteractionDisabled ? 'disabled' : null,
    ];

    return (
      <Well className="file-list">
        {assets.count() ? (
          <ul className="files">
            {assets.map((asset: IAssetRecord) => (
              <li key={asset.id} className={classNames(fileClassName)}>
                <div className="file-info">
                  <ul>
                    <li>
                      <MaybeAssetAnchor className="file-name" asset={asset}>
                        <span className="file-icon">
                          <FileIcon fileName={getAssetName(asset)} />
                        </span>
                        <EllipsizedFileName>
                          {getAssetName(asset)}
                        </EllipsizedFileName>
                      </MaybeAssetAnchor>
                    </li>

                    <li>{formatDate(asset.modified)}</li>
                    <li>{formatBytes(asset.num_bytes)}</li>
                    <li>
                      Evidence Type:{' '}
                      {getEvidenceTypeDisplay(asset, evidenceTypes)}
                    </li>
                  </ul>
                </div>

                <SpacedGroup block className="margin-top-small">
                  {this.isPreventedFromTagOrDeleteFile(asset) ? (
                    <SimplePopover
                      top
                      right
                      hover
                      width={180}
                      toggleComponent={
                        <a className="disabled" onClick={() => undefined}>
                          Tag file <IconTags className="icon-small icon-tags" />
                        </a>
                      }
                    >
                      You cannot tag a file that your <Term>Teacher</Term>{' '}
                      uploaded.
                    </SimplePopover>
                  ) : (
                    <a
                      title="Tag file"
                      onClick={() =>
                        this.props.openModal(
                          <TagFilesModal
                            assetIds={List.of(asset.id)}
                            isLearner={userHasRoles(profile, Set.of(STUDENT))}
                            afterSuccessfulTag={this.props.loadAssets}
                            forUser={student}
                          />
                        )}
                    >
                      Tag file <IconTags className="icon-small icon-tags" />
                    </a>
                  )}
                  {this.isPreventedFromTagOrDeleteFile(asset) ||
                  asset.is_tagged_to_anything ? (
                    <SimplePopover
                      top
                      left
                      hover
                      width={180}
                      toggleComponent={
                        <a className="disabled">
                          Delete file
                          <IconTrash className="icon-small icon-trash" />
                        </a>
                      }
                    >
                      You cannot remove a file if it is tagged to any{' '}
                      <Term>Tasks</Term> or journal posts.
                    </SimplePopover>
                  ) : (
                    <a
                      title="Delete file"
                      className={
                        fileInteractionDisabled ? 'disabled' : undefined
                      }
                      onClick={() =>
                        !fileInteractionDisabled && this.deleteAsset(asset)}
                    >
                      Delete file
                      <IconTrash className="icon-small icon-trash" />
                    </a>
                  )}
                </SpacedGroup>
                <SpacedGroup block className="margin-top-small">
                  <a
                    title="File properties"
                    onClick={() =>
                      this.props.openModal(
                        <FilePropertiesModal
                          asset={asset}
                          onUpdated={this.props.loadAssets}
                          readOnly={this.isPreventedFromTagOrDeleteFile(asset)}
                        />
                      )}
                  >
                    File properties
                    <IconTags className="icon-small icon-tags" />
                  </a>
                </SpacedGroup>
              </li>
            ))}
          </ul>
        ) : loadingAssets ? (
          <Loading />
        ) : (
          <p className="info">No files</p>
        )}
      </Well>
    );
  }

  private isPreventedFromTagOrDeleteFile(asset: IAssetRecord) {
    const { profile } = this.props;
    return asset.owner !== asset.created_by && asset.owner === profile.id;
  }
}

function mapStateToProps(
  { assets, profile, responses, serverConstants }: IStore,
  props: IExternalProps
) {
  return {
    ...props,
    profile,
    assets,
    loadingAssets: isPending(responses, GET_ASSETS),
    fileInteractionDisabled: anyPending(responses, [GET_ASSETS, DELETE_ASSET]),
    evidenceTypes: serverConstants.evidence_types,
  };
}

export default connect(mapStateToProps, {
  deleteAsset,
  openModal,
  closeModal,
})(FileList);
