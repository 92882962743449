import {
  dispatchGenericRequest,
  UrlMethod,
} from '@dabapps/redux-api-collections/dist/requests';
// tslint:disable-next-line:no-unused-variable
import { AxiosResponse } from 'axios'; // Required for dist
import { Dispatch } from 'redux';

import {
  // tslint:disable-next-line:no-unused-variable
  DispatchCallback, // Required for dist
} from '../requests/types';
import { IAction, RecordInstance, TTypeToRecordMapping } from '../types';
import * as items from './actions';
import { setItemState, setItemStateIfMatchingItem } from './reducers';

export function itemsFunctor<T>(
  typeToRecordMapping: TTypeToRecordMapping<T>,
  ItemsStateRecord: () => RecordInstance<T>
) {
  function _updateItem(
    itemType: keyof T,
    url: string,
    method: UrlMethod,
    itemId: string,
    data: any
  ): (dispatch: Dispatch<any>, getState: () => any) => Promise<AxiosResponse> {
    return dispatchGenericRequest(
      items.UPDATE_ITEM,
      url,
      method,
      data,
      itemType,
      { itemId }
    );
  }

  function actionItemAction(
    type: keyof T,
    id: string,
    action: string,
    data: any
  ): (dispatch: Dispatch<any>, getState: () => any) => Promise<AxiosResponse> {
    return _updateItem(type, `/api/${type}/${id}/${action}/`, 'POST', id, data);
  }

  function clearItemAction(itemType: keyof T) {
    return {
      payload: {
        itemType,
      },
      type: items.CLEAR_ITEM,
    };
  }

  function loadItemAction(
    itemType: keyof T,
    itemId: string,
    preserveOriginal?: boolean
  ): (dispatch: Dispatch<any>, getState: () => any) => Promise<AxiosResponse> {
    const url = `/api/${itemType}/${itemId}/`;
    return dispatchGenericRequest(
      items.LOAD_ITEM,
      url,
      'GET',
      null,
      itemType,
      { itemId },
      preserveOriginal
    );
  }

  function patchItemAction(
    type: keyof T,
    id: string,
    data: any
  ): (dispatch: Dispatch<any>, getState: () => any) => Promise<AxiosResponse> {
    return _updateItem(type, `/api/${type}/${id}/`, 'PATCH', id, data);
  }

  function updateItemAction(
    type: keyof T,
    id: string,
    data: any
  ): (dispatch: Dispatch<any>, getState: () => any) => Promise<AxiosResponse> {
    return _updateItem(type, `/api/${type}/${id}/`, 'PUT', id, data);
  }

  function itemsReducer(
    state: RecordInstance<T> = ItemsStateRecord(),
    action: IAction<any, any>
  ) {
    // The types in here are a bit janky as there doesn't seem to be a way
    // of passing a closed generic with certain types for properties
    switch (action.type) {
      case items.CLEAR_ITEM:
        const itemType = action.payload.itemType;
        if (itemType in typeToRecordMapping) {
          return state.set(action.payload.itemType, null as any);
        }
        return state;
      case items.LOAD_ITEM.REQUEST:
        if (action.payload && action.payload.preserveOriginal) {
          return state;
        }
        return state.set(action.meta.tag, null as any);
      case items.LOAD_ITEM.SUCCESS:
        return setItemState(state, action, action.payload, typeToRecordMapping);
      case items.UPDATE_ITEM.SUCCESS:
        return setItemStateIfMatchingItem(
          state,
          action,
          action.payload,
          typeToRecordMapping
        );
      default:
        return state;
    }
  }

  return {
    actions: {
      actionItem: actionItemAction,
      clearItem: clearItemAction,
      loadItem: loadItemAction,
      patchItem: patchItemAction,
      updateItem: updateItemAction,
    },
    reducers: {
      items: itemsReducer,
    },
  };
}
