import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { Dict } from '@dabapps/simple-records';
import { EditorState } from 'draft-js';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  DataShape,
  Field,
  Form,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';

import { IGetAssetsOptions } from '../../actions';
import { IStore } from '../../store';
import { IAssetRecord } from '../../store/data-types';
import { IClassOption } from '../../store/data-types/classes';
import {
  IJournalEntry,
  IJournalTaskData,
} from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import { makeUIStateHandler } from '../../ui-state';
import { asPromise, FormErrors } from '../../utils';
import ClassSelect from '../classes/class-select';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';
import { terminologyFromProfile } from '../terminology';
import JournalEntryEditor from './journal-entry-editor';
import JournalFilesSidebar from './journal-files-sidebar';
import JournalTasksSidebar from './tasks-sidebar/journal-tasks-sidebar';

const { IconFileTextO } = FontAwesome;

export const JOURNAL_ENTRY_FORM = 'JournalEntryForm';
export const JOURNAL_ENTRY_EDITED = 'JOURNAL_ENTRY_EDITED';
export const NAVIGATION_WARNING_MESSAGE =
  'You have unsaved changes - are you sure you want to navigate away from this page?';

export const journalEntryEditedHandler = makeUIStateHandler(
  JOURNAL_ENTRY_EDITED,
  false
);

export interface IPreTransformFormData {
  title?: string;
  content?: EditorState;
  selectedAssets?: List<string>;
  entry_class?: string;
  user_task?: string;
  off_job_training_hours?: string;
}

interface IJournalEntryFormExternalProps {
  assets: List<IAssetRecord>;
  destroyOnUnmount: boolean;
  classes?: List<IClassOption>;
  profile: IProfile;
  isStudent: boolean;
  hideSidebar?: boolean;
  submitting: boolean;
  showTaskLink: boolean;
  journalEntry?: IJournalEntry;
  isOwner: boolean;
  loading: boolean;
  saveButtonText: string;
  formErrors?: FormErrors;
  getAssets(): void;
  onSubmit(dataIn: any): any;
  openModal(modal: React.ReactNode): void;
}
interface IJournalEntryFormConnectedProps {
  entryClass?: string;
  journalTaskData: IJournalTaskData;
}
interface IJournalEntryFormActionProps {
  setJournalEntryEdited: typeof journalEntryEditedHandler.setState;
}

type TJournalEntryFormProps = FormProps<DataShape, void, void> &
  IJournalEntryFormExternalProps &
  IJournalEntryFormConnectedProps &
  IJournalEntryFormActionProps;

class JournalEntryForm extends React.PureComponent<TJournalEntryFormProps, {}> {
  constructor(props: TJournalEntryFormProps) {
    super(props);
    this.setEdited = this.setEdited.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    const {
      isStudent,
      submitting,
      loading,
      formErrors,
      journalEntry,
      openModal,
      assets,
      profile,
      getAssets,
      saveButtonText,
      hideSidebar,
      handleSubmit,
      classes,
      showTaskLink,
      isOwner,
      entryClass,
      journalTaskData,
    } = this.props;
    const selectedTask =
      (journalEntry && journalEntry.user_task) ||
      journalTaskData.selectedTask ||
      null;

    return (
      <Form
        onSubmit={handleSubmit ? handleSubmit(this.onSubmit) : () => null}
        className="margin-bottom-base"
      >
        <Row>
          <Column sm={8}>
            <GroupField
              label={terminologyFromProfile(profile, 'Class')}
              name="entry_class"
              type="text"
              component={fieldProps => (
                <ClassSelect
                  {...fieldProps}
                  classes={classes || List()}
                  noClassLabel={
                    isStudent ? 'None - this is a post for my journal' : ''
                  }
                  disabled={!!selectedTask || loading || submitting}
                />
              )}
              disabled={!!selectedTask || loading || submitting}
              formErrors={formErrors}
            />

            <GroupField
              label="Post Title"
              name="title"
              type="text"
              disabled={loading || submitting}
              formErrors={formErrors}
              onChange={this.setEdited}
            />
            <GroupField
              label="Post Content"
              name="content"
              type="text"
              component={JournalEntryEditor}
              disabled={loading || submitting}
              formErrors={formErrors}
              onChange={this.setEdited}
            />

            <Button
              type="submit"
              className="primary"
              disabled={loading || submitting}
            >
              {saveButtonText}
            </Button>
            <FormErrorsRenderer
              formErrors={formErrors}
              errorKey="non_field_errors"
            />
          </Column>

          <Column sm={4}>
            {!hideSidebar && (
              <div>
                <Field
                  name="selectedAssets"
                  component={(fieldProps: WrappedFieldProps<IStore>) => (
                    <JournalFilesSidebar
                      {...fieldProps}
                      journalEntry={journalEntry}
                      openModal={openModal}
                      assets={assets}
                      profile={profile}
                      modeOptions={{ mode: 'CREATING' }}
                      getAssets={getAssets}
                    />
                  )}
                />
                <JournalTasksSidebar
                  entryClass={entryClass || null}
                  openModal={openModal}
                  journalEntry={journalEntry}
                  showTaskLink={showTaskLink}
                  modeOptions={{
                    mode: 'EDITING',
                    onChange: asPromise(() => undefined),
                  }}
                  profile={profile}
                />
              </div>
            )}
          </Column>
        </Row>
      </Form>
    );
  }

  private setEdited() {
    const { setJournalEntryEdited } = this.props;
    setJournalEntryEdited(true);
  }

  private onSubmit(data: Dict<string | undefined>) {
    const { journalTaskData, onSubmit } = this.props;
    return onSubmit({
      ...data,
      user_task: journalTaskData.selectedTask || undefined,
      off_job_training_hours: journalTaskData.offJobTrainingHours || undefined,
    });
  }
}

const form = reduxForm({
  form: JOURNAL_ENTRY_FORM,
})(JournalEntryForm);

const selector = formValueSelector(JOURNAL_ENTRY_FORM);

function mapStateToProps(
  state: IStore,
  props: IJournalEntryFormExternalProps & FormProps<DataShape, void, void>
): IJournalEntryFormExternalProps & IJournalEntryFormConnectedProps {
  return {
    ...props,
    journalTaskData: state.journalTaskSidebar,
    entryClass: selector(state, 'entry_class'),
  };
}

export default connect(mapStateToProps, {
  setJournalEntryEdited: journalEntryEditedHandler.setState,
})(form);
