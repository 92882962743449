import {
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
} from '@dabapps/roe';
import * as moment from 'moment';
import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import {
  clearTaskUpdateStatus,
  taskDatesUpdated,
} from '../../../actions/tasks';
import { UPDATE_ITEM } from '../../../items/actions';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IUserTaskRecord } from '../../../store/data-types/tasks';
import { formatDateForBackend, FormErrors } from '../../../utils';
import ModalCloseIcon from '../../modals/modal-close-icon';
import EditUserTaskForm, { IFormData } from './edit-user-task-form';

const { actions: { loadItem, patchItem } } = itemsModule;

interface IExternalProps {
  userTask: IUserTaskRecord;
}

interface IPropsWithoutActions extends IExternalProps {
  formErrors?: FormErrors;
  isLoading: boolean;
}

interface IActionProps {
  clearTaskUpdateStatus(): void;
  closeModal(): void;
  updateUserTask(updatedData: IFormData): void;
}

type IProps = IActionProps & IPropsWithoutActions;

class EditUserTaskModal extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.clearTaskUpdateStatus();
  }

  public render() {
    const {
      closeModal,
      userTask,
      updateUserTask,
      formErrors,
      isLoading,
    } = this.props;
    return (
      <Modal scrollable onClickOutside={closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={closeModal} />
          <p>Edit {userTask.class_task.component.title}</p>
        </ModalHeader>
        <ModalBody>
          <EditUserTaskForm
            onSubmit={updateUserTask}
            initialValues={{
              start_date: userTask.start_date,
              end_date: userTask.end_date,
            }}
            errors={formErrors}
            isLoading={isLoading}
            classTaskStartDate={userTask.class_task.start_date}
            classTaskEndDate={userTask.class_task.end_date}
          >
            <div>
              {isLoading && (
                <Alert type="info">
                  <p>Updating&hellip;</p>
                </Alert>
              )}
              <ModalFooter>
                <div className="margin-top-base margin-bottom-base">
                  <Button disabled={isLoading}>Save</Button>
                </div>
              </ModalFooter>
            </div>
          </EditUserTaskForm>
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  }
}

function mapStateToProps(
  { responses }: IStore,
  props: IExternalProps
): IPropsWithoutActions {
  return {
    ...props,
    isLoading: isPending(responses, UPDATE_ITEM, 'tasks'),
    formErrors: getFormErrors(responses, UPDATE_ITEM, 'tasks'),
  };
}

interface ISubmitData {
  start_date?: string | null;
  end_date?: string | null;
}

function transformIfNotString(
  date: moment.Moment | string | null | undefined
): string | null | undefined {
  if (typeof date === 'string') {
    return date;
  }
  return date ? formatDateForBackend(date) : date;
}

function transformFormData({ start_date, end_date }: IFormData): ISubmitData {
  return {
    start_date: transformIfNotString(start_date),
    end_date: transformIfNotString(end_date),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<void>,
  props: IExternalProps
): IActionProps {
  return {
    clearTaskUpdateStatus: () => dispatch(clearTaskUpdateStatus()),
    closeModal: () => dispatch(closeModal()),
    updateUserTask: (data: IFormData) =>
      dispatch(
        patchItem('tasks', props.userTask.id, transformFormData(data))
      ).then(() => {
        dispatch(resetRequestState(UPDATE_ITEM, 'tasks'));
        dispatch(closeModal());
        dispatch(taskDatesUpdated());
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserTaskModal);
