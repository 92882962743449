import {
  recordArray,
  recordOrId,
  RecordWithConstructor,
  SimpleRecord,
} from '@dabapps/simple-records';
import { List, Record } from 'immutable';
import * as moment from 'moment';
import { CommentRecord, ICommentRecord } from '.';
import { ClassRecord, IClassRecord } from './classes';
import { TStatus } from './common';
import {
  // tslint:disable-next-line:no-unused-variable
  IProfile,
  ISimpleUser,
  ProfileOsmsDataRecord,
  ProfileRecord,
  TRole,
  TUserStatus,
  USER_STATUSES,
} from './profile';
import { IQualificationRecord, QualificationRecord } from './qualifications';
import { momentize, RecordInstance, recordList } from './records';

export const STATUS: { [P in TStatus]: P } = {
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING_MARKING: 'AWAITING_MARKING',
  MARKED: 'MARKED',
  PENDING_SUBMISSION: 'PENDING_SUBMISSION',
  SUBMITTED: 'SUBMITTED',
  PASSED_IQA: 'PASSED_IQA',
  PASSED_EQA: 'PASSED_EQA',
};

export function isTStatus(status: any): status is TStatus {
  return typeof status === 'string' && status in STATUS;
}

export type TStatusChange =
  | 'HAND_IN'
  | 'RE_OPENED'
  | 'MARKED'
  | 'IQA_REJECTED'
  | 'IQA_PASSED'
  | 'EQA_REJECTED'
  | 'EQA_PASSED';

export type TGrade = 'FAIL' | 'PASS' | 'MERIT' | 'DISTINCTION';

export type IGrade = Readonly<{
  grade: TGrade;
  score: number | null;
  checklist: string;
}>;
export const GradeRecord = SimpleRecord<IGrade>({
  grade: 'FAIL',
  score: null,
  checklist: '',
});

export type ExamResultsList = ReadonlyArray<string>;

export type GradeList = ReadonlyArray<IGrade>;

export type IUserTaskGradeInput = Readonly<{
  grade: ReadonlyArray<Partial<IGrade>>;
}>;
export type IUserTaskGrade = Readonly<{
  grade: GradeList;
}>;
export const UserTaskGradeRecord = RecordWithConstructor<
  IUserTaskGradeInput,
  IUserTaskGrade
>(
  {
    grade: [],
  },
  input => {
    return {
      ...input,
      grade: recordArray(input.grade || [], GradeRecord),
    };
  }
);

export type INestedTaskAsset = Readonly<{
  asset: string;
  tagger_role: TRole;
}>;

export const NestedTaskAsset = SimpleRecord<INestedTaskAsset>({
  asset: '',
  tagger_role: 'STUDENT',
});

export type TCompletionAttemptTypes = 'ATTEMPT' | 'ALLOW_ANOTHER_ATTEMPT';

export type ICompletionAttemptInput = Readonly<{
  id?: string;
  created?: string | moment.Moment;
  attempt_type?: TCompletionAttemptTypes;
  user_task?: Partial<IUserTaskRecord>;
  user?: Partial<IProfile>;
  recorded_grades?: ReadonlyArray<Partial<IGrade>>;
  reason?: string;
}>;
export type ICompletionAttempt = Readonly<{
  id: string;
  created: moment.Moment;
  attempt_type: TCompletionAttemptTypes;
  user_task: IUserTaskRecord;
  user: IProfile;
  recorded_grades: GradeList;
  reason?: string;
}>;
export const CompletionAttemptRecord = RecordWithConstructor<
  ICompletionAttemptInput,
  ICompletionAttempt
>({}, input => {
  return {
    ...input,
    id: input.id || '',
    created: input.created ? momentize(input.created) : moment.utc(),
    attempt_type: input.attempt_type || 'ATTEMPT',
    user_task: UserTaskRecord(input.user_task || {}),
    user: ProfileRecord(input.user || {}),
    recorded_grades: recordArray(input.recorded_grades || [], GradeRecord),
  };
});

export type IUnit = Readonly<{
  id: string;
  title: string;
  about: string;
}>;
export const UnitRecord = SimpleRecord<IUnit>({
  id: '',
  title: '',
  about: '',
});

export interface IUnitAssignee {
  id: string;
  has_been_interacted_with: boolean;
}
export type IUnitAssigneeRecord = RecordInstance<IUnitAssignee>;
export const UnitAssigneeRecord = Record<IUnitAssignee>({
  id: '',
  has_been_interacted_with: false,
});
export interface IUnitClassTask {
  id: string;
  title: string;
  start_date?: string;
  end_date?: string;
}
export type IUnitClassTaskRecord = RecordInstance<IUnitClassTask>;
export const UnitClassTaskRecord = Record<IUnitClassTask>({
  id: '',
  title: '',
  start_date: '',
  end_date: '',
});
export interface IUnitManagment {
  id: string;
  title: string;
  about: string;
  is_mandatory: boolean;
  assignees: List<IUnitAssigneeRecord>;
  class_tasks: List<IUnitClassTaskRecord>;
}
export type IUnitManagementRecord = RecordInstance<IUnitManagment>;
export const UnitManagementRecordInner = Record<IUnitManagment>({
  id: '',
  title: '',
  about: '',
  is_mandatory: false,
  assignees: List<IUnitAssigneeRecord>(),
  class_tasks: List<IUnitClassTaskRecord>(),
});
export function UnitManagementRecord(
  input: Partial<IUnitManagment>
): IUnitManagementRecord {
  const inner = UnitManagementRecordInner(input);
  return inner
    .set('assignees', recordList(input.assignees, UnitAssigneeRecord))
    .set('class_tasks', recordList(input.class_tasks, ClassTaskRecord));
}

export interface IComponentShared {
  id: string;
  title: string;
  completion_attempts: number;
  about: string;
  off_job_training_percent?: number;
}
export type IComponentInput = Readonly<
  IComponentShared & {
    unit: IUnit;
  }
>;
export type IComponent = Readonly<
  IComponentShared & {
    unit: Readonly<IUnit>;
  }
>;
export const ComponentRecord = RecordWithConstructor<
  IComponentInput,
  IComponent
>(
  {
    completion_attempts: 0,
    id: '',
    title: '',
    unit: {
      id: '',
      title: '',
      about: '',
    },
    about: '',
  },
  input => {
    return {
      ...input,
      unit: UnitRecord(input.unit),
    };
  }
);

export type IResource = Readonly<{
  filename: string;
  id: string;
  url: string;
  description: string;
}>;
export const ResourceRecord = SimpleRecord<IResource>({
  filename: '',
  id: '',
  url: '',
  description: '',
});

export type IUnitDetailInput = Readonly<
  IUnit & {
    resources: ReadonlyArray<Partial<IResource>>;
  }
>;
export type IUnitDetail = Readonly<
  IUnit & {
    resources: ReadonlyArray<IResource>;
  }
>;
export const UnitDetailRecord = RecordWithConstructor<
  IUnitDetailInput,
  IUnitDetail
>(
  {
    id: '',
    resources: [],
    title: '',
    about: '',
  },
  input => {
    return {
      ...input,
      resources: recordArray(input.resources, ResourceRecord),
    };
  }
);

export interface ICriteriaStatsPMD {
  current_pass: number;
  max_pass: number;
  current_merit: number;
  max_merit: number;
  current_distinction: number;
  max_distinction: number;
}
export type ICriteriaStatsPMDRecord = RecordInstance<ICriteriaStatsPMD>;
export const CriteriaStatsPMDRecord = Record<ICriteriaStatsPMD>({
  current_pass: 0,
  max_pass: 0,
  current_merit: 0,
  max_merit: 0,
  current_distinction: 0,
  max_distinction: 0,
});

export interface ICriteriaStatsStandard {
  current: number;
  max: number;
}
export type ICriteriaStatsStandardRecord = RecordInstance<
  ICriteriaStatsStandard
>;
export const CriteriaStatsStandardRecord = Record<ICriteriaStatsStandard>({
  current: 0,
  max: 0,
});

export interface ICriteriaStats {
  pmd?: ICriteriaStatsPMDRecord;
  standard?: ICriteriaStatsStandardRecord;
}
export type ICriteriaStatsRecord = RecordInstance<ICriteriaStats>;
const CriteriaStatsRecordInner = Record<ICriteriaStats>({
  pmd: undefined,
  standard: undefined,
});
export function CriteriaStatsRecord(
  input: ICriteriaStats
): ICriteriaStatsRecord {
  return CriteriaStatsRecordInner(input)
    .set('pmd', input.pmd ? CriteriaStatsPMDRecord(input.pmd) : undefined)
    .set(
      'standard',
      input.standard ? CriteriaStatsStandardRecord(input.standard) : undefined
    );
}

export const INFINITE = 'INFINITE';

export interface IUserTask {
  id: string;
  created: moment.Moment;
  modified: moment.Moment;
  has_been_interacted_with: boolean;
  start_date: moment.Moment | null;
  end_date: moment.Moment | null;
  status: TStatus;
  progress: number | null;
  class_task: IClassTaskRecord;
  task_assets: ReadonlyArray<INestedTaskAsset>;
  tagged_asset_count: number; // NOTE: this also includes assets tagged via journal posts
  remaining_completion_attempts: number | typeof INFINITE;
  user: string | IProfile;
  can_owner_untag_files_or_journal_posts: boolean;
  can_teacher_untag_files_or_journal_posts: boolean;
  can_iqa_untag_files_or_journal_posts: boolean;
  can_user_mark: boolean;
  // The following are only available on UserTask
  unit: string;
  criteria_stats: ICriteriaStatsRecord;
  grade: GradeList;
  exam_results: ExamResultsList;
}

export type INestedUserTaskInput = Readonly<{
  id: string;
  end_date: string | null;
  status: TStatus;
  progress: number | null;
  tagged_asset_count: number; // NOTE: this also includes assets tagged via journal posts
  user: Partial<IProfile>;
  grade: ReadonlyArray<Partial<IGrade>>;
  exam_results?: ExamResultsList;
}>;
export type INestedUserTask = Readonly<{
  id: string;
  end_date: moment.Moment | null;
  status: TStatus;
  progress: number | null;
  tagged_asset_count: number; // NOTE: this also includes assets tagged via journal posts
  user: IProfile;
  grade: GradeList;
  exam_results: ExamResultsList;
}>;

export const NestedUserTask = RecordWithConstructor<
  INestedUserTaskInput,
  INestedUserTask
>(
  {
    id: '',
    status: 'IN_PROGRESS',
    end_date: null,
    progress: null,
    tagged_asset_count: 0,
    user: {},
    grade: [],
    exam_results: [],
  },
  input => {
    const { end_date, user, grade, exam_results, ...rest } = input;
    return {
      ...rest,
      end_date: end_date ? moment.utc(end_date) : null,
      user: ProfileRecord(user),
      grade: grade ? grade.map(each => GradeRecord(each)) : [],
      exam_results: exam_results || [],
    };
  }
);

export interface IClassTask {
  id: string;
  created: moment.Moment;
  is_mandatory: boolean;
  modified: moment.Moment;
  start_date: moment.Moment | null;
  end_date: moment.Moment | null;
  component: IComponent;
  qualification: IQualificationRecord;
  task_class: IClassRecord;
  user_tasks: List<INestedUserTask>;
}

export type IClassTaskRecord = RecordInstance<IClassTask>;

const ClassTaskRecordInner = Record<IClassTask>({
  component: ComponentRecord({}),
  created: moment.utc(),
  end_date: null,
  id: '',
  is_mandatory: false,
  modified: moment.utc(),
  qualification: QualificationRecord({}),
  start_date: null,
  task_class: ClassRecord({}),
  user_tasks: List(),
});

export function ClassTaskRecord(
  classTaskRecord: Partial<IClassTask>
): IClassTaskRecord {
  const inner = ClassTaskRecordInner(classTaskRecord);
  return inner
    .set('created', momentize(classTaskRecord.created))
    .set('modified', momentize(classTaskRecord.modified))
    .set(
      'start_date',
      classTaskRecord.start_date ? momentize(classTaskRecord.start_date) : null
    )
    .set(
      'end_date',
      classTaskRecord.end_date ? momentize(classTaskRecord.end_date) : null
    )
    .set('component', ComponentRecord(classTaskRecord.component || {}))
    .set(
      'qualification',
      QualificationRecord(classTaskRecord.qualification || {})
    )
    .set('task_class', ClassRecord(classTaskRecord.task_class || {}))
    .set('user_tasks', recordList(classTaskRecord.user_tasks, NestedUserTask));
}

export type IUserTaskRecord = RecordInstance<IUserTask>;

const UserTaskRecordInner = Record<IUserTask>({
  class_task: ClassTaskRecord({}),
  created: moment.utc(),
  end_date: null,
  has_been_interacted_with: false,
  id: '',
  modified: moment.utc(),
  progress: null,
  start_date: null,
  status: 'IN_PROGRESS',
  user: '',
  task_assets: [],
  tagged_asset_count: 0,
  remaining_completion_attempts: INFINITE,
  unit: '',
  criteria_stats: CriteriaStatsRecord({}),
  can_owner_untag_files_or_journal_posts: false,
  can_teacher_untag_files_or_journal_posts: false,
  can_iqa_untag_files_or_journal_posts: false,
  can_user_mark: false,
  grade: [],
  exam_results: [],
});

export function UserTaskRecord(input: Partial<IUserTask>): IUserTaskRecord {
  const item = UserTaskRecordInner(input);
  return item
    .set('created', momentize(input.created))
    .set('modified', momentize(input.modified))
    .set('start_date', input.start_date ? momentize(input.start_date) : null)
    .set('end_date', input.end_date ? momentize(input.end_date) : null)
    .set(
      'class_task',
      ClassTaskRecord(
        typeof input.class_task === 'object' ? input.class_task : {}
      )
    )
    .set(
      'task_assets',
      input.task_assets ? input.task_assets.map(NestedTaskAsset) : []
    )
    .set(
      'user',
      input.user
        ? recordOrId<IProfile, IProfile>(input.user, ProfileRecord)
        : ''
    )
    .set(
      'grade',
      input.grade ? input.grade.map(each => GradeRecord(each)) : []
    );
}

export interface IUserGradeListClassTask {
  id: string;
  end_date: moment.Moment | null;
  component_title: string;
  unit_title: string;
}

export type IUserGradeListClassTaskRecord = RecordInstance<
  IUserGradeListClassTask
>;

const UserGradeListClassTaskRecordInner = Record<IUserGradeListClassTask>({
  component_title: '',
  unit_title: '',
  end_date: null,
  id: '',
});

export function UserGradeListClassTaskRecord(
  classTaskRecord: Partial<IUserGradeListClassTask>
): IUserGradeListClassTaskRecord {
  const inner = UserGradeListClassTaskRecordInner(classTaskRecord);
  return inner.set(
    'end_date',
    classTaskRecord.end_date ? momentize(classTaskRecord.end_date) : null
  );
}

export interface IUserGradeListTask {
  id: string;
  end_date: moment.Moment | null;
  status: TStatus;
  progress: number | null;
  class_task: IUserGradeListClassTaskRecord;
  tagged_asset_count: number;
  grade: GradeList;
  exam_results: ExamResultsList;
}

export type IUserGradeListTaskRecord = RecordInstance<IUserGradeListTask>;

const UserGradeListTaskRecordInner = Record<IUserGradeListTask>({
  class_task: UserGradeListClassTaskRecord({}),
  end_date: null,
  id: '',
  progress: null,
  status: 'IN_PROGRESS',
  tagged_asset_count: 0,
  grade: [],
  exam_results: [],
});

export function UserGradeListTaskRecord(
  input: Partial<IUserGradeListTask>
): IUserGradeListTaskRecord {
  const item = UserGradeListTaskRecordInner(input);
  return item
    .set('end_date', input.end_date ? momentize(input.end_date) : null)
    .set(
      'class_task',
      UserGradeListClassTaskRecord(
        typeof input.class_task === 'object' ? input.class_task : {}
      )
    )
    .set(
      'grade',
      input.grade ? input.grade.map(each => GradeRecord(each)) : []
    );
}

export type ICourseworkProgressOverviewUserTaskInput = Readonly<{
  id: string;
  class_task: string;
  end_date: string | null;
  exam_results: ReadonlyArray<string>;
  grade: ReadonlyArray<Partial<IGrade>>;
  progress: number | null;
  status: TStatus;
  title: string;
  unit: string;
  user: string;
}>;
export type ICourseworkProgressOverviewUserTask = Readonly<{
  id: string;
  class_task: string;
  end_date: moment.Moment | null;
  exam_results: ExamResultsList;
  grade: GradeList;
  progress: number | null;
  status: TStatus;
  title: string;
  unit: string;
  user: string;
}>;

export const CourseworkProgressOverviewUserTask = RecordWithConstructor<
  ICourseworkProgressOverviewUserTaskInput,
  ICourseworkProgressOverviewUserTask
>(
  {
    id: '',
    class_task: '',
    end_date: '',
    exam_results: [],
    grade: [],
    progress: null,
    status: 'IN_PROGRESS',
    title: '',
    unit: '',
    user: '',
  },
  input => {
    return {
      ...input,
      end_date: input.end_date ? moment.utc(input.end_date) : null,
      exam_results: input.exam_results || [],
      grade: recordArray(input.grade || [], GradeRecord),
    };
  }
);

// tslint:disable-next-line:no-unused-variable
interface ICourseworkProgressOverviewInput extends ISimpleUser {
  user_tasks: ReadonlyArray<ICourseworkProgressOverviewUserTaskInput>;
  status: TUserStatus;
}
export interface ICourseworkProgressOverview extends ISimpleUser {
  user_tasks: ReadonlyArray<ICourseworkProgressOverviewUserTask>;
  status: TUserStatus;
}

export const CourseworkProgressOverviewRecord = RecordWithConstructor<
  ICourseworkProgressOverviewInput,
  ICourseworkProgressOverview
>(
  {
    id: '',
    osms_data: {},
    osms_user_id: '',
    user_tasks: [],
    status: USER_STATUSES.ACTIVE,
  },
  input => {
    return {
      ...input,
      osms_data: ProfileOsmsDataRecord(input.osms_data || {}),
      user_tasks: recordArray(
        input.user_tasks || [],
        CourseworkProgressOverviewUserTask
      ),
    };
  }
);

interface IMarkschemeBlock {
  id: string;
  title: string;
}
export type IMarkschemeBlockRecord = RecordInstance<IMarkschemeBlock>;
export const MarkschemeBlockRecord = Record<IMarkschemeBlock>({
  id: '',
  title: '',
});

interface IMarkschemeGroup {
  id: string;
  title: string;
  blocks: List<IMarkschemeBlockRecord>;
}
export type IMarkschemeGroupRecord = RecordInstance<IMarkschemeGroup>;
const MarkschemeGroupRecordInner = Record<IMarkschemeGroup>({
  id: '',
  title: '',
  blocks: List(),
});
export function MarkschemeGroupRecord(
  input: Partial<IMarkschemeGroup>
): IMarkschemeGroupRecord {
  const item = MarkschemeGroupRecordInner(input);
  return item.set('blocks', recordList(input.blocks, MarkschemeBlockRecord));
}

interface IMarkschemeStrand {
  id: string;
  title: string;
  groups: List<IMarkschemeGroupRecord>;
}
export type IMarkschemeStrandRecord = RecordInstance<IMarkschemeStrand>;
const MarkschemeStrandRecordInner = Record<IMarkschemeStrand>({
  id: '',
  title: '',
  groups: List(),
});
export function MarkschemeStrandRecord(
  input: Partial<IMarkschemeStrand>
): IMarkschemeStrandRecord {
  const item = MarkschemeStrandRecordInner(input);
  return item.set('groups', recordList(input.groups, MarkschemeGroupRecord));
}

interface IMarkscheme {
  id: string;
  title: string;
  strands: List<IMarkschemeStrandRecord>;
}
export type IMarkschemeRecord = RecordInstance<IMarkscheme>;
const MarkschemeRecordInner = Record<IMarkscheme>({
  id: '',
  title: '',
  strands: List(),
});
export function MarkschemeRecord(
  input: Partial<IMarkscheme>
): IMarkschemeRecord {
  const item = MarkschemeRecordInner(input);
  return item.set('strands', recordList(input.strands, MarkschemeStrandRecord));
}

interface ITaskStatusChangeComment {
  id: string;
  status_change: TStatusChange;
  task: IUserTaskRecord;
  comment: ICommentRecord;
}
export type ITaskStatusChangeCommentRecord = RecordInstance<
  ITaskStatusChangeComment
>;
const TaskStatusChangeCommentRecordInner = Record<ITaskStatusChangeComment>({
  id: '',
  task: UserTaskRecord({}),
  status_change: 'EQA_PASSED',
  comment: CommentRecord({}),
});
export function TaskStatusChangeCommentRecord(
  input: Partial<ITaskStatusChangeComment>
): ITaskStatusChangeCommentRecord {
  const item = TaskStatusChangeCommentRecordInner(input);
  return item
    .set('task', UserTaskRecord(input.task || {}))
    .set('comment', CommentRecord(input.comment || {}));
}
