import { RecordWithConstructor } from '@dabapps/simple-records';
import * as moment from 'moment';
import { IProfile, IProfileInput, ProfileRecord } from './profile';

interface IJournalEntryShared {
  assets: ReadonlyArray<string>;
  user_task: string | null;
  user_task_title: string | null;
  entry_class: string | null;
  content: string;
  can_be_modified_by_owner: boolean;
  can_be_modified_by_teacher: boolean;
  id: string;
  root_comment_id: string;
  title: string;
  off_job_training_hours: number;
}
export type IJournalEntryInput = Readonly<
  IJournalEntryShared & {
    created: string | Date | moment.Moment | number;
    modified: string | Date | moment.Moment | number;
    user: Partial<IProfileInput>;
  }
>;
export type IJournalEntry = Readonly<
  IJournalEntryShared & {
    created: moment.Moment;
    modified: moment.Moment;
    user: IProfile;
  }
>;
export const JournalEntryRecord = RecordWithConstructor<
  IJournalEntryInput,
  IJournalEntry
>(
  {
    assets: [],
    can_be_modified_by_owner: false,
    can_be_modified_by_teacher: false,
    entry_class: null,
    user_task: null,
    user_task_title: null,
    content: '',
    created: Date.now(),
    id: '',
    modified: Date.now(),
    root_comment_id: '',
    title: '',
    user: {},
    off_job_training_hours: 0,
  },
  input => {
    return {
      ...input,
      created: moment.utc(input.created),
      modified: moment.utc(input.created),
      user: ProfileRecord(input.user),
    };
  }
);

export type IJournalTaskData = Readonly<{
  offJobTrainingHours: string | null;
  selectedTask: string | null;
}>;
