import * as React from 'react';

import { Set } from 'immutable';
import { connect } from 'react-redux';
import {
  CENTRE_ADMIN,
  DA_SUPPORT,
  EXTERNAL_QUALITY_ASSURER,
  getViewingStudentIdIfRole,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import TasksLandingIQA from './tasks-landing-iqa';
import TasksLandingStudent from './tasks-landing-student';
import TasksLandingTeacher from './tasks-landing-teacher';

interface IProps {
  profile: IProfile;
  location: {
    query: {
      qualification?: string;
    };
  };
  params?: {
    student: string;
  };
}

export class TasksLanding extends React.PureComponent<IProps, void> {
  public render() {
    const { location, profile, params } = this.props;
    const qualificationFilterId = location.query.qualification;
    const viewingStudentId =
      params && getViewingStudentIdIfRole(profile, params.student);

    if (userHasRoles(profile, Set.of(STUDENT)) || viewingStudentId) {
      return (
        <TasksLandingStudent
          qualificationFilterId={qualificationFilterId}
          studentId={viewingStudentId}
        />
      );
    }
    if (
      userHasRoles(
        profile,
        Set.of(TEACHER, CENTRE_ADMIN, EXTERNAL_QUALITY_ASSURER, DA_SUPPORT)
      )
    ) {
      return (
        <TasksLandingTeacher qualificationFilterId={qualificationFilterId} />
      );
    }
    if (userHasRoles(profile, Set.of(INTERNAL_QUALITY_ASSURER))) {
      return <TasksLandingIQA qualificationFilterId={qualificationFilterId} />;
    }
    return null;
  }
}

function mapStateToProps({ profile }: IStore) {
  return {
    profile,
  };
}

export default connect(mapStateToProps, {})(TasksLanding);
