import { Column, Row, SpacedGroup } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { openModal } from '../../../actions/modals';
import { IStore } from '../../../store';
import { IAssetRecord } from '../../../store/data-types';
import { EvidenceType } from '../../../store/data-types/constants';
import { IUserTaskRecord } from '../../../store/data-types/tasks';
import {
  formatDate,
  getAssetName,
  getEvidenceTypeDisplay,
} from '../../../utils';
import EllipsizedFileName from '../../ellipsized-file-name';
import FileIcon from '../../files/file-icon';
import TagFilesModal from '../../files/tag-files/tag-files-modal';
import MaybeAssetAnchor from '../../maybe-asset-anchor';
import SimplePopover from '../../simple-popover';

const { IconFileTextO, IconTags, IconTimes, IconInfoCircle } = FontAwesome;

interface IExternalProps {
  asset: IAssetRecord;
  task: IUserTaskRecord;
  canTag: boolean;
  isLearner: boolean;
  selfMark: boolean;
  tagModalSubTitle?: React.ReactChild;
  onUntagAsset(id: string): void;
}

export interface IProps extends IExternalProps {
  evidenceTypes: ReadonlyArray<EvidenceType>;
  openModal: typeof openModal;
}

export class Asset extends React.PureComponent<IProps, void> {
  public render() {
    const {
      asset,
      task,
      canTag,
      isLearner,
      tagModalSubTitle,
      selfMark,
      evidenceTypes,
    } = this.props;

    return (
      <Column
        component="li"
        key={asset.id}
        className="file"
        xs={12}
        sm={6}
        md={4}
        lg={3}
      >
        <div className="file-info">
          <ul>
            <li>
              <MaybeAssetAnchor className="file-name" asset={asset}>
                <span className="file-icon">
                  <FileIcon fileName={getAssetName(asset)} />
                </span>
                <EllipsizedFileName>{getAssetName(asset)}</EllipsizedFileName>
              </MaybeAssetAnchor>
            </li>
            <li>
              Evidence Type: {getEvidenceTypeDisplay(asset, evidenceTypes)}{' '}
              {asset.description && (
                <SimplePopover
                  bottom
                  right
                  hover
                  width={180}
                  toggleComponent={<IconInfoCircle className="icon-small" />}
                >
                  {asset.description}
                </SimplePopover>
              )}
            </li>
            {canTag && (
              <SpacedGroup block className="margin-top-small">
                {selfMark ? (
                  <a
                    title="Manage tags"
                    onClick={() =>
                      this.props.openModal(
                        <TagFilesModal
                          subTitle={tagModalSubTitle}
                          individualTask={task}
                          isLearner={isLearner}
                          assetIds={List.of(asset.id)}
                        />
                      )}
                  >
                    Manage tags <IconTags className="icon-small icon-tags" />
                  </a>
                ) : null}
                <a
                  title="Un-tag file"
                  onClick={() => this.props.onUntagAsset(asset.id)}
                >
                  Un-tag
                  <IconTimes className="icon-small icon-trash" />
                </a>
              </SpacedGroup>
            )}
          </ul>
        </div>
      </Column>
    );
  }
}

function mapStateToProps(state: IStore, props: IExternalProps) {
  return {
    ...props,
    evidenceTypes: state.serverConstants.evidence_types,
  };
}

export default connect(mapStateToProps, { openModal })(Asset);
