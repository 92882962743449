import {
  Dict,
  RecordWithConstructor,
  SimpleRecord,
} from '@dabapps/simple-records';
import * as moment from 'moment';
import { CentreRecord, ICentre } from './centres';

type IOsmsScope = Readonly<{
  terminology: Dict<string> | undefined;
}>;
export const OsmsScopeRecord = SimpleRecord<IOsmsScope>({
  terminology: undefined,
});

export type IProfileOsmsDataInput = Readonly<{
  title?: string;
  birth_date?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  gender?: string;
  given_name?: string;
  middle_name?: string;
  name?: string;
  picture?: string;
  preferred_username?: string;
  username?: string;
  one_time_password?: string;
  unique_learner_number?: string;
  osms?: Partial<IOsmsScope>;
}>;
export type IProfileOsmsData = Readonly<{
  title?: string;
  birth_date?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  gender?: string;
  given_name?: string;
  middle_name?: string;
  name?: string;
  picture?: string;
  username?: string;
  one_time_password?: string;
  unique_learner_number?: string;
  osms?: IOsmsScope;
}>;
export const ProfileOsmsDataRecord = RecordWithConstructor<
  IProfileOsmsDataInput,
  IProfileOsmsData
>(
  {
    birth_date: undefined,
    email: undefined,
    email_verified: undefined,
    family_name: undefined,
    gender: undefined,
    given_name: undefined,
    middle_name: undefined,
    name: undefined,
    picture: undefined,
    username: undefined,
    title: undefined,
    unique_learner_number: undefined,
    one_time_password: undefined,
    osms: OsmsScopeRecord({}),
  },
  input => {
    const { osms, preferred_username, username, ...rest } = input;
    return {
      ...rest,
      osms: OsmsScopeRecord(osms || {}),
      username: preferred_username || username,
    };
  }
);

export type IHijackerProfile = Readonly<{
  id: string;
  name: string;
  role: string;
}>;
export const HijackerProfileRecord = SimpleRecord<IHijackerProfile>({
  id: '',
  name: '',
  role: '',
});

export type HomepagePreferenceOptions =
  | '/'
  | '/files/'
  | '/journal/'
  | '/users/'
  | '/classes/'
  | '/tasks/';
export const HomepagePreferenceDefault: HomepagePreferenceOptions = '/';

export type IUserPreferences = Readonly<{
  preference_homepage: HomepagePreferenceOptions;
  preference_show_old_tasks: boolean;
}>;
export const UserPreferencesRecord = SimpleRecord<IUserPreferences>({
  preference_homepage: HomepagePreferenceDefault,
  preference_show_old_tasks: false,
});

export type ISimpleUserInput = Readonly<{
  id: string;
  osms_data: Partial<IProfileOsmsData> | null;
  osms_user_id?: string;
}>;
export interface ISimpleUser extends ISimpleUserInput {
  osms_data: IProfileOsmsData;
}

export const SimpleUser = RecordWithConstructor<ISimpleUserInput, ISimpleUser>(
  {
    id: '',
    osms_user_id: '',
    osms_data: ProfileOsmsDataRecord({}),
  },
  input => {
    return {
      ...input,
      osms_data: ProfileOsmsDataRecord(input.osms_data || {}),
    };
  }
);

export type TRole =
  | 'TEACHER'
  | 'STUDENT'
  | 'CENTRE_ADMIN'
  | 'EXTERNAL_QUALITY_ASSURER'
  | 'INTERNAL_QUALITY_ASSURER'
  | 'DA_SUPPORT'
  | 'DA_ADMIN';

export type TUserStatus = 'ACTIVE' | 'ARCHIVED' | 'DISABLED';
export type TUserInactiveStatusKey = 'ARCHIVED' | 'DISABLED';

export const USER_STATUSES: { [P in TUserStatus]: P } = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DISABLED: 'DISABLED',
};

export interface IProfileShared {
  email?: string;
  quota_bytes: number;
  is_digital_assess_admin?: boolean;
  is_external_quality_assurer?: boolean;
  can_send_invite?: boolean;
  assets_quota_mb: number;
  assets_quota_used_mb: number;
}
export type IProfileInput = Readonly<
  ISimpleUserInput &
    IProfileShared & {
      created: string | moment.Moment;
      last_login: null | moment.Moment;
      display_roles: ReadonlyArray<TRole>;
      hijacker?: Partial<IHijackerProfile>;
      preferences: Partial<IUserPreferences>;
      current_role?: TRole;
      current_centre?: Partial<ICentre>;
      status: TUserStatus;
      can_change_role: boolean;
      roles_for_current_centre?: ReadonlyArray<TRole>;
    }
>;
export type IProfile = Readonly<
  ISimpleUser &
    IProfileShared & {
      created: moment.Moment;
      last_login: moment.Moment | null;
      hijacker?: IHijackerProfile;
      preferences: IUserPreferences;
      current_role?: TRole;
      current_centre?: ICentre;
      display_roles: ReadonlyArray<TRole>;
      status: TUserStatus;
      can_change_role: boolean;
      roles_for_current_centre?: ReadonlyArray<TRole>;
    }
>;

export const ProfileRecord: (
  input: Partial<IProfileInput>
) => IProfile = RecordWithConstructor<IProfileInput, IProfile>(
  {
    current_role: undefined,
    current_centre: undefined,
    created: '',
    last_login: null,
    email: undefined,
    hijacker: undefined,
    id: '',
    is_digital_assess_admin: undefined,
    is_external_quality_assurer: undefined,
    osms_data: ProfileOsmsDataRecord({}),
    osms_user_id: undefined,
    preferences: UserPreferencesRecord(),
    quota_bytes: 0,
    display_roles: [],
    can_send_invite: false,
    assets_quota_mb: 0,
    assets_quota_used_mb: 0,
    status: 'ACTIVE',
    can_change_role: false,
    roles_for_current_centre: undefined,
  },
  (input: IProfileInput) => {
    const {
      created,
      last_login,
      hijacker,
      preferences,
      current_centre,
      osms_data,
      ...rest,
    } = input;
    return {
      ...rest,
      created: created ? moment(created) : moment.utc(),
      last_login: last_login ? moment(last_login) : null,
      hijacker: hijacker && HijackerProfileRecord(hijacker),
      preferences: UserPreferencesRecord(preferences),
      current_centre: current_centre && CentreRecord(current_centre),
      osms_data: ProfileOsmsDataRecord(osms_data || {}),
    };
  }
);
