import { Alert, Column, Row } from '@dabapps/roe';
import * as React from 'react';

export default class SuccessMessage extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <Row>
        <Column>
          <Alert className="success">
            <p>{this.props.children}</p>
          </Alert>
        </Column>
      </Row>
    );
  }
}
