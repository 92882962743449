import {
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';

import {
  default as ChangePasswordForm,
  IFormData,
} from './change-password-form';

import { CHANGE_PASSWORD, changePassword } from '../../actions/profile';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { FormErrors } from '../../utils';

interface IProps {
  hasSucceeded: boolean;
  isLoading: boolean;
  formErrors?: FormErrors;
  changePassword(data: IFormData): void;
}

export class ChangePassword extends React.PureComponent<IProps, void> {
  public submit(formData: IFormData) {
    this.props.changePassword(formData);
  }

  public render() {
    return (
      <ChangePasswordForm
        onSubmit={this.submit.bind(this)}
        isLoading={this.props.isLoading}
        hasSucceeded={this.props.hasSucceeded}
        formErrors={this.props.formErrors}
      />
    );
  }
}

function mapStateToProps({ responses }: IStore, existing: Partial<IProps>) {
  return _.extend({}, existing, {
    formErrors: getFormErrors(responses, CHANGE_PASSWORD),
    hasSucceeded: hasSucceeded(responses, CHANGE_PASSWORD),
    isLoading: isPending(responses, CHANGE_PASSWORD),
  });
}

export default connect(mapStateToProps, { changePassword, resetRequestState })(
  ChangePassword
);
