import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';

import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IProfile } from '../../../store/data-types/profile';
import { ICompletionAttempt } from '../../../store/data-types/tasks';
import { formatDateTime, formatRoles } from '../../../utils';
import SimpleTable, { IColumnData } from '../../tables/simple-table';
import Term from '../../terminology';
import GradeIndicator from '../grade-indicator';
const { actions: { getAllCollection } } = collectionsModule;

const COMPLETION_ATTEMPTS = 'tasks/completion-attempts';

interface IExternalProps {
  taskId: string;
}

interface IPropsWithoutActions extends IExternalProps {
  completionAttempts: List<ICompletionAttempt>;
  loading: boolean;
  headers: Array<IColumnData<ICompletionAttempt>>;
}

interface IProps extends IPropsWithoutActions {
  getAllCollection: typeof getAllCollection;
}

export function getHeaders(
  profile: IProfile
): Array<IColumnData<ICompletionAttempt>> {
  return [
    {
      headerLabel: 'Attempt',
      key: 'attempt',
      sortable: false,
      content: data => {
        if (data.attempt_type === 'ALLOW_ANOTHER_ATTEMPT') {
          return (
            <span>
              <Term>IQA</Term> Reset
              {data.reason && (
                <span className="font-size-small display-block margin-top-small white-space-normal">
                  Reason - {data.reason}
                </span>
              )}
            </span>
          );
        }
        return <GradeIndicator loading={false} grade={data.recorded_grades} />;
      },
    },
    {
      headerLabel: 'Logged by',
      key: 'user__osms_data__name',
      sortable: false,
      content: data => (
        <span>
          {data.user.osms_data.name} ({data.attempt_type ===
          'ALLOW_ANOTHER_ATTEMPT' ? (
            <Term>IQA</Term>
          ) : (
            <Term>{formatRoles(data.user.display_roles, profile)}</Term>
          )})
        </span>
      ),
    },
    {
      headerLabel: 'When',
      key: 'created',
      sortable: false,
      content: data => formatDateTime(data.created),
    },
  ];
}

export class CompletionAttemptTable extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    const { taskId } = this.props;
    this.props.getAllCollection(COMPLETION_ATTEMPTS, {
      filters: Map<string, string>({ task: taskId }),
    });
  }

  public render() {
    const { loading, completionAttempts, headers } = this.props;
    return (
      <SimpleTable
        fixRowHeaders={false}
        loading={loading}
        items={completionAttempts}
        headers={headers}
        noItemsMessage="No attempts made yet."
        useCellForFirstColumn
      />
    );
  }
}

function mapStateToProps(
  { responses, collectionsOld, profile }: IStore,
  props: IExternalProps
): IPropsWithoutActions {
  return {
    ...props,
    headers: getHeaders(profile),
    loading: isPending(responses, GET_COLLECTION, COMPLETION_ATTEMPTS),
    completionAttempts: getCollectionItems(
      collectionsOld.get(COMPLETION_ATTEMPTS)
    ),
  };
}

export default connect(mapStateToProps, { getAllCollection })(
  CompletionAttemptTable
);
