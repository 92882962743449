import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
const { IconTimes } = FontAwesome;

import { ModalCloseIcon } from '@dabapps/roe';

const CustomModalCloseIcon: React.SFC<
  React.HTMLAttributes<HTMLDivElement>
> = props => {
  return (
    <ModalCloseIcon {...props}>
      <IconTimes />
    </ModalCloseIcon>
  );
};

export default CustomModalCloseIcon;
