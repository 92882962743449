import { getCollectionResultsByName } from '@dabapps/redux-api-collections/dist/collections';
import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Button, Modal, ModalFooter, ModalHeader } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../../collections/actions';
import { ICollectionOptions } from '../../../collections/types';
import { DispatchCallback } from '../../../requests/types';

import { clearUIState, setUIState } from '../../../actions';
import { addLearningOutcomeBlockComment } from '../../../actions/marksheet';
import { closeModal } from '../../../actions/modals';
import { collections } from '../../../collections';
import { IStore } from '../../../store';
import {
  ILearningOutcomeBlockCommentRecord,
  ILearningOutcomeBlockNewComment,
} from '../../../store/data-types/marksheets';
import { TRole } from '../../../store/data-types/profile';
import { formatTimeAgo } from '../../../utils';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import AddLearningOutcomeBlockCommentForm, {
  IFormData,
} from './learning-outcome-block-comments-form';

const { actions: { getCollection } } = collections;

const { IconCheck } = FontAwesome;

export const LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE =
  'marksheets/learning-outcome-block-comments';

export const getComponentName = (
  learningOutcomeBlockId: string,
  userRole: string
) => `${learningOutcomeBlockId}-${userRole}`;

interface IExternalProps {
  learningOutcomeBlockId: string;
  title: string;
  isAllowedToAddComments: boolean;
  evidenceTypeOral: boolean;
  userRole: TRole;
}

interface IStateProps {
  comments: ReadonlyArray<ILearningOutcomeBlockCommentRecord>;
  loading: boolean;
  isShowingForm: boolean;
  commenterId: string;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
  closeModal(): void;
  setUIState(key: string, value: boolean): void;
  clearUIState(key: string): void;
  addLearningOutcomeBlockComment(
    data: ILearningOutcomeBlockNewComment,
    tag?: string
  ): AxiosPromise;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class LearningOutcomeBlockCommentsModal extends React.PureComponent<
  IProps,
  void
> {
  public componentDidMount() {
    this.refreshFormStateAndGetCollection();
  }

  public render() {
    const {
      comments,
      closeModal,
      title,
      loading,
      isShowingForm,
      isAllowedToAddComments,
    } = this.props;
    return (
      <Modal onClickOutside={() => closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={closeModal} />
          <h3>
            {`Comments on `}
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h3>
        </ModalHeader>
        {loading ? (
          <Loading />
        ) : (
          <div className="padding-large">
            {comments.length ? (
              <ul className="comments">
                {comments.map((comment: ILearningOutcomeBlockCommentRecord) => (
                  <li key={comment.id} className="comment">
                    <div className="comment">
                      <p>{comment.comment}</p>
                      <p className="info">
                        {`by ${comment.commenter} ${formatTimeAgo(
                          moment.utc(comment.created)
                        )}`}
                        {this.props.evidenceTypeOral &&
                          comment.criteria_met_orally && (
                            <span>
                              &nbsp;- criteria met by oral comment
                              <IconCheck className="icon-small margin-left-small" />
                            </span>
                          )}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="italic">No comments</p>
            )}
            {isAllowedToAddComments && (
              <div>
                {isShowingForm ? (
                  <AddLearningOutcomeBlockCommentForm
                    onSubmit={this.addNewComment}
                    evidenceTypeOral={this.props.evidenceTypeOral}
                  />
                ) : (
                  <a onClick={this.showForm}>New comment</a>
                )}
              </div>
            )}
          </div>
        )}
        <ModalFooter>
          <p>
            <Button className="primary" onClick={() => closeModal()}>
              Close
            </Button>
          </p>
        </ModalFooter>
      </Modal>
    );
  }

  private showForm = () => {
    this.props.setUIState(
      getComponentName(this.props.learningOutcomeBlockId, this.props.userRole),
      true
    );
  };

  private refreshFormStateAndGetCollection = () => {
    const { learningOutcomeBlockId, userRole } = this.props;
    this.props.clearUIState(getComponentName(learningOutcomeBlockId, userRole));
    this.props.getCollection(
      LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE,
      {
        filters: {
          learning_outcome_block: learningOutcomeBlockId,
          commenter_role: userRole,
        },
      },
      getComponentName(learningOutcomeBlockId, userRole)
    );
  };

  private addNewComment = (data: IFormData) => {
    if (data.newComment) {
      this.props
        .addLearningOutcomeBlockComment({
          comment: data.newComment,
          learning_outcome_block: this.props.learningOutcomeBlockId,
          commenter: this.props.commenterId,
          commenter_role: this.props.userRole,
          criteria_met_orally: data.criteriaMetOrally,
        })
        .then(this.refreshFormStateAndGetCollection);
    }
  };
}

function mapStateToProps(
  { collections, profile, responses, uiState }: IStore,
  props: IExternalProps
): IExternalProps & IStateProps {
  const comments = getCollectionResultsByName(
    collections,
    LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE,
    getComponentName(props.learningOutcomeBlockId, props.userRole)
  );
  const isShowingFormState = uiState.get(
    getComponentName(props.learningOutcomeBlockId, props.userRole)
  );

  const loggedInUser = profile;
  return {
    ...props,
    comments: comments as ReadonlyArray<ILearningOutcomeBlockCommentRecord>,
    loading: isPending(
      responses,
      GET_COLLECTION,
      LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE
    ),
    isShowingForm: Boolean(!comments.length || isShowingFormState),
    commenterId: loggedInUser.id,
  };
}

export default connect<
  IStateProps,
  IDispatchProps,
  IExternalProps
>(mapStateToProps, {
  closeModal,
  getCollection,
  setUIState,
  clearUIState,
  addLearningOutcomeBlockComment,
})(LearningOutcomeBlockCommentsModal);
