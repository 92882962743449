import {
  hasFailed as hasRequestFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';
import { connect } from 'react-redux';

import { default as LandingForm, IFormData } from './landing-form';

import {
  UPDATE_USER_PREFERENCES,
  updateUserPreferences,
} from '../../actions/profile';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/';
import {
  HomepagePreferenceDefault,
  IProfile,
} from '../../store/data-types/profile';
import { FormErrors } from '../../utils';

interface IProps {
  profile: IProfile;
  updateUserPreferences: (data: IFormData) => void;
  hasSucceeded: boolean;
  isLoading: boolean;
  hasFailed: boolean;
  formErrors?: FormErrors;
}

export const RED = '#FF0000';
export const DEFAULT_VALUES: Readonly<IFormData> = {
  preference_homepage: HomepagePreferenceDefault,
  preference_show_old_tasks: true,
  preference_receive_notifications: true,
};

export class ProfileLanding extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  public render() {
    const {
      hasSucceeded,
      isLoading,
      hasFailed,
      formErrors,
      profile,
    } = this.props;

    return (
      <LandingForm
        profile={profile}
        onSubmit={this.submit.bind(this)}
        initialValues={this.getInitialValues()}
        isLoading={isLoading}
        hasFailed={hasFailed}
        hasSucceeded={hasSucceeded}
        formErrors={formErrors}
      />
    );
  }

  private submit(formData: IFormData) {
    this.props.updateUserPreferences(formData);
  }

  private getInitialValues(): Partial<IFormData> {
    const { profile: { preferences } } = this.props;

    return {
      ...DEFAULT_VALUES,
      ...preferences,
    };
  }
}

function mapStateToProps(
  { profile, responses }: IStore,
  existing: Partial<IProps>
) {
  return {
    ...existing,
    formErrors: getFormErrors(responses, UPDATE_USER_PREFERENCES),
    hasSucceeded: hasSucceeded(responses, UPDATE_USER_PREFERENCES),
    isLoading: isPending(responses, UPDATE_USER_PREFERENCES),
    hasFailed: hasRequestFailed(responses, UPDATE_USER_PREFERENCES),
    profile,
  };
}

export default connect(mapStateToProps, { updateUserPreferences })(
  ProfileLanding
);
