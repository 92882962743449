import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

const {
  IconFileO,
  IconFileImageO,
  IconFileAudioO,
  IconFileTextO,
  IconFileVideoO,
  IconFilePdfO,
} = FontAwesome;

const MATCHES_PDF_FORMAT = /\.pdf$/i;
const MATCHES_IMAGE_FORMAT = /\.(jpe?g|png|jfif|exif|tiff|gif|bmp|svg)$/i;
const MATCHES_VIDEO_FORMAT = /\.(mpe?g|webm|mp4|m4v|mkv|mov|qt|avi|wmv|gifv|3gp|2gp|flv|fl4|ogv)$/i;
const MATCHES_AUDIO_FORMAT = /\.(mp3|wav|wma|aa|aac|aiff|ogg|oga|m4a)$/i;
const MATCHES_TEXT_FORMAT = /\.(txt|rtf|doc|docx|pdf|csv|xml|html|css|md|mk|json|yaml|ini|ascii)$/i;

interface IProps {
  fileName?: string;
  className?: string;
}

const FileIcon = ({ fileName, className }: IProps) => {
  if (!fileName) {
    return <IconFileO className={className} />;
  }

  if (MATCHES_PDF_FORMAT.test(fileName)) {
    return <IconFilePdfO className={className} />;
  }

  if (MATCHES_IMAGE_FORMAT.test(fileName)) {
    return <IconFileImageO className={className} />;
  }

  if (MATCHES_VIDEO_FORMAT.test(fileName)) {
    return <IconFileVideoO className={className} />;
  }

  if (MATCHES_AUDIO_FORMAT.test(fileName)) {
    return <IconFileAudioO className={className} />;
  }

  if (MATCHES_TEXT_FORMAT.test(fileName)) {
    return <IconFileTextO className={className} />;
  }

  return <IconFileO className={className} />;
};

export default FileIcon;
