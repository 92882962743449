import * as React from 'react';

import { Alert, Section } from '@dabapps/roe';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import {
  ALL_ADMIN_USERS,
  ALL_CLASS_USERS,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store/';
import { ICommentRecord } from '../../store/data-types';
import { IProfile, TRole } from '../../store/data-types/profile';
import Loading from '../loading';
import Comment from './comment';
import CommentBox from './comment-box';

interface IExternalProps {
  loading: boolean;
  failed: boolean;
}

interface IProps extends IExternalProps {
  rootComment: ICommentRecord;
  profile: IProfile;
}

export class JournalComments extends React.PureComponent<IProps, void> {
  public renderLoading() {
    return <Loading />;
  }

  public renderLoaded() {
    const { profile, rootComment } = this.props;
    const showNewComment = userHasRoles(
      profile,
      Set<TRole>([...ALL_CLASS_USERS.toArray(), ...ALL_ADMIN_USERS.toArray()])
    );
    return (
      <div>
        {!rootComment.children.count() && <p className="info">No comments</p>}
        {showNewComment && (
          <CommentBox commentId={rootComment.id} initialText="New comment" />
        )}
        <Comment
          comment={rootComment}
          showRootComment={false}
          showNewComment={showNewComment}
          showRole={false}
          profile={profile}
        />
      </div>
    );
  }

  public renderError() {
    return (
      <Alert className="error">
        <p>There was an error getting the comments.</p>
      </Alert>
    );
  }

  public render() {
    let content;
    if (this.props.loading) {
      content = this.renderLoading();
    } else if (this.props.failed) {
      content = this.renderError();
    } else {
      content = this.renderLoaded();
    }

    return (
      <Section>
        <h3>Comments</h3>
        {content}
      </Section>
    );
  }
}

function mapStateToProps(
  { rootComment, profile }: IStore,
  props: IExternalProps
): IProps {
  return {
    ...props,
    profile,
    rootComment,
  };
}

export default connect(mapStateToProps)(JournalComments);
