import { Column, Row } from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';
import * as _ from 'underscore';
import { lookupStatusChange } from '../lookup-tables';
import { INTERNAL_QUALITY_ASSURER, STUDENT, TEACHER } from '../permissions';
import { IActivityEventRecord } from '../store/data-types/activity-events';
import { IProfile } from '../store/data-types/profile';
import { IUserTaskRecord, TStatusChange } from '../store/data-types/tasks';
import {
  constantToTitleCase,
  formatBytes,
  formatShortDate,
  formatTimeAgo,
  getAssetName,
} from '../utils';
import EllipsizedFileName from './ellipsized-file-name';
import FileIcon from './files/file-icon';
import ImageHolder from './image-holder';
import JournalInfo from './journals/journal-info';
import MaybeAssetAnchor from './maybe-asset-anchor';
import NewsFeedItem from './news-feed-item';
import { terminologyFromProfile } from './terminology';

const {
  IconFilesO,
  IconTags,
  IconUser,
  IconEnvelopeO,
  IconPencilSquareO,
} = FontAwesome;

interface IProps {
  eventRecord: IActivityEventRecord;
  profile: IProfile;
}
export default function NewsFeedItemContainer({
  eventRecord,
  profile,
}: IProps) {
  const { user } = eventRecord;
  const userName = user.id === profile.id ? 'You' : user.osms_data.name;
  const dateString = `on ${formatShortDate(
    eventRecord.created
  )} (${formatTimeAgo(eventRecord.created)})`;

  if (eventRecord.new_comment) {
    const post = eventRecord.new_comment.journal_entry;
    const task =
      eventRecord.new_comment.task_status_change_comment &&
      eventRecord.new_comment.task_status_change_comment.task;
    const owner = (post && post.user) || (task && narrowToRecord(task.user));
    const ownerName =
      (owner &&
        (profile.id === owner.id ? 'your' : `${owner.osms_data.name}'s`)) ||
      `unknown's`;

    return (
      <NewsFeedItem
        heading={`${userName} commented on ${ownerName} ${post
          ? 'journal post'
          : terminologyFromProfile(profile, 'Marksheet')} ${dateString}.`}
        type="comment"
      >
        {post && (
          <p>
            <Link to={`/journal/${post.id}/`}>{post.title}</Link>
          </p>
        )}
        {task && (
          <p>
            <Link to={`/tasks/marksheet/${task.id}/`}>
              {task.class_task.component.title}
            </Link>
          </p>
        )}
        <p className="info">{eventRecord.new_comment.comment.content}</p>
      </NewsFeedItem>
    );
  }
  if (eventRecord.update_journal_post) {
    const createdOrUpdated =
      eventRecord.event_type === 'UPDATE_JOURNAL_POST' ? 'updated' : 'created';
    const post = eventRecord.update_journal_post.journal_entry;

    return (
      <NewsFeedItem
        heading={`${userName} ${createdOrUpdated} a journal post ${dateString}.`}
        type="text"
      >
        <Row>
          <Column xs={12} sm={10}>
            <p className="margin-bottom-none">
              <strong>
                <Link to={`/journal/${post.id}/`}>{post.title}</Link>
              </strong>
            </p>
            <div
              className="journal-content-container"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </Column>
          <JournalInfo
            journalEntry={post}
            profile={profile}
            hideTaskLink={false}
          />
        </Row>
      </NewsFeedItem>
    );
  }
  if (eventRecord.file_uploaded) {
    // TODO: preview file with converted asset?
    const asset = eventRecord.file_uploaded.asset;
    const fileName = (
      <EllipsizedFileName>{getAssetName(asset)}</EllipsizedFileName>
    );
    return (
      <NewsFeedItem
        heading={`${userName} uploaded a file ${dateString}.`}
        type="files"
      >
        <ul className="files">
          <Column component="li" className="file" xs={12} sm={6} md={4} lg={3}>
            <div className="file-info">
              <ul>
                <li>
                  <MaybeAssetAnchor className="file-name" asset={asset}>
                    <span className="file-icon">
                      <FileIcon fileName={getAssetName(asset)} />
                    </span>
                    <EllipsizedFileName>
                      {getAssetName(asset)}
                    </EllipsizedFileName>
                  </MaybeAssetAnchor>
                </li>
                <li>{formatBytes(asset.num_bytes)}</li>
              </ul>
            </div>
          </Column>
        </ul>
      </NewsFeedItem>
    );
  }
  if (eventRecord.account_hijack) {
    return (
      <NewsFeedItem
        heading={`${userName} logged in as another user ${dateString}.`}
        type="user"
      >
        <p>
          <IconUser className="icon-small" />{' '}
          {eventRecord.account_hijack.hijacked_user.name}
        </p>
      </NewsFeedItem>
    );
  }

  if (
    eventRecord.event_type === 'MARKSHEET_STATUS_CHANGE' &&
    eventRecord.marksheet_status_change
  ) {
    const {
      user_task,
      status_change,
      task_status_change_comment,
    } = eventRecord.marksheet_status_change;

    const statusChangeDisplay: {
      [K in TStatusChange]: {
        icon: 'hand-in' | 'status-change-positive' | 'status-change-negative';
        className?: 'news-feed-item-positive' | 'news-feed-item-negative';
      }
    } = {
      HAND_IN: { icon: 'hand-in' },
      RE_OPENED: {
        icon: 'status-change-negative',
        className: 'news-feed-item-negative',
      },
      MARKED: {
        icon: 'status-change-positive',
        className: 'news-feed-item-positive',
      },
      IQA_REJECTED: {
        icon: 'status-change-negative',
        className: 'news-feed-item-negative',
      },
      IQA_PASSED: {
        icon: 'status-change-positive',
        className: 'news-feed-item-positive',
      },
      EQA_REJECTED: {
        icon: 'status-change-negative',
        className: 'news-feed-item-negative',
      },
      EQA_PASSED: {
        icon: 'status-change-positive',
        className: 'news-feed-item-positive',
      },
    };

    const displayAttributes = statusChangeDisplay[status_change];
    const icon = displayAttributes ? displayAttributes.icon : 'hand-in';
    const className = displayAttributes ? displayAttributes.className : '';

    return (
      <NewsFeedItem
        heading={`${userName} ${lookupStatusChange(
          status_change
        )} ${terminologyFromProfile(profile, 'a Marksheet')} ${dateString}.`}
        type={icon}
        className={className}
      >
        <p>
          <IconEnvelopeO className="icon-small" />{' '}
          <Link to={`/tasks/marksheet/${user_task.id}/`}>
            {user_task.class_task.component.title}
          </Link>
        </p>
        {task_status_change_comment && (
          <p className="info">{task_status_change_comment.comment.content}</p>
        )}
      </NewsFeedItem>
    );
  }

  if (
    _.contains(
      ['IQA_ADDED_TO_CENTRE', 'EQA_ADDED_TO_CENTRE'],
      eventRecord.event_type
    ) &&
    eventRecord.qa_added_to_centre
  ) {
    const role =
      eventRecord.event_type === 'IQA_ADDED_TO_CENTRE' ? 'IQA' : 'EQA';
    const targetName = eventRecord.qa_added_to_centre.user.osms_data.name;
    const centreName = eventRecord.qa_added_to_centre.centre.osms_data.name;
    return (
      <NewsFeedItem
        heading={`${userName} added ${targetName} as an ${role} to centre ${centreName} ${dateString}.`}
        type="info"
      />
    );
  }

  if (
    eventRecord.event_type === 'QUALIFICATION_ADDED_TO_CLASS' &&
    eventRecord.qualification_added_to_class
  ) {
    const qualName =
      eventRecord.qualification_added_to_class.qualification.name;
    const className = eventRecord.event_class.name;

    return (
      <NewsFeedItem
        heading={`${userName} added ${qualName} to ${terminologyFromProfile(
          profile,
          'Class'
        )} ${className} ${dateString}.`}
        type="info"
      />
    );
  }

  if (
    eventRecord.event_type === 'ALLOW_ANOTHER_COMPLETION_ATTEMPT' &&
    eventRecord.allow_another_completion_attempt
  ) {
    const task =
      eventRecord.allow_another_completion_attempt.attempt_record.user_task;

    return (
      <NewsFeedItem
        heading={`${userName} added another attempt to a ${terminologyFromProfile(
          profile,
          'Task'
        )} ${dateString}`}
        type="text"
      >
        <p>
          <Link to={`/tasks/marksheet/${task.id}/`}>
            {task.class_task.component.title}
          </Link>
        </p>
      </NewsFeedItem>
    );
  }

  if (
    eventRecord.event_type === 'IQA_REQUEST_EQA_REVIEW' &&
    eventRecord.iqa_request_eqa_review
  ) {
    const classTask = eventRecord.iqa_request_eqa_review.class_task;

    return (
      <NewsFeedItem
        heading={`${userName} requested EQA review on ${classTask.component
          .title} for ${classTask.task_class.name} ${dateString}`}
        type="review-requested"
      >
        <p>
          <Link to={`/tasks/${classTask.id}/`}>
            {classTask.component.title}
          </Link>
        </p>
      </NewsFeedItem>
    );
  }

  if (
    eventRecord.event_type === 'EQA_SIGN_OFF_CLASS_TASK' &&
    eventRecord.eqa_class_task_sign_off
  ) {
    const classTask = eventRecord.eqa_class_task_sign_off.class_task;

    return (
      <NewsFeedItem
        heading={`${userName} signed off ${terminologyFromProfile(
          profile,
          'Task'
        )} ${classTask.component.title} for ${classTask.task_class
          .name} ${dateString}`}
        type="status-change-positive"
        className="news-feed-item-positive"
      >
        <p>
          <Link to={`/tasks/${classTask.id}/`}>
            {classTask.component.title}
          </Link>
        </p>
      </NewsFeedItem>
    );
  }

  // if (
  //   eventRecord.event_type === 'EXAM_RESULTS_RECEIVED' &&
  //   eventRecord.exam_results_received
  // ) {
  //   return (
  //     <NewsFeedItem
  //       heading={`Exam results have been received ${dateString}`}
  //       type="list"
  //     >
  //       <p>{eventRecord.exam_results_received.qualification.name}</p>
  //       <p>
  //         <span className="info">
  //           {eventRecord.exam_results_received.component.title}
  //         </span>
  //         {': '}
  //         <span>
  //           ({
  //             eventRecord.exam_results_received.learning_outcome_block
  //               .recorded_result
  //           })
  //         </span>
  //       </p>
  //     </NewsFeedItem>
  //   );
  // }

  // if (
  //   eventRecord.event_type === 'USER_ADDED_TO_CLASS' &&
  //   eventRecord.user_added_to_class
  // ) {
  //   const ROLES = {
  //     [INTERNAL_QUALITY_ASSURER]: 'an IQA',
  //     [STUDENT]: 'a student',
  //     [TEACHER]: 'a teacher',
  //   };
  //   return (
  //     <NewsFeedItem
  //       heading={`${eventRecord.user.osms_data.name} added you as ${ROLES[
  //         eventRecord.user_added_to_class.role
  //       ]} to ${eventRecord.event_class.name} ${dateString}`}
  //       type="info"
  //     />
  //   );
  // }

  // Unknown log item
  // tslint:disable-next-line:no-console
  console.warn(`Unknown event type ${eventRecord.event_type} - not rendered`);
  return <noscript />;
}
