import * as React from 'react';

import { Button, SpacedGroup } from '@dabapps/roe';
import { Link } from 'react-router';
import { DataShape, Field, FormProps, reduxForm } from 'redux-form';
import { FormErrors } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import Term from '../../terminology';
import RemoveStudentsWarning from '../remove-students-warning';
import EditStudentsField from './field';

export const EDIT_STUDENTS_FORM = 'EDIT_STUDENTS_FORM';

export interface IEditStudentsFormData {
  students: ReadonlyArray<string>;
}

interface IExternalProps {
  updating: boolean;
  centreId: string;
  classId: string;
  groupName: string;
  errors?: FormErrors;
  shouldShowRemoveWarning: boolean;
  updateStudents(event: React.MouseEvent<HTMLButtonElement>): void;
}

type IProps = FormProps<DataShape, void, void> & IExternalProps;

export class EditStudentsForm extends React.PureComponent<IProps, void> {
  public render() {
    const {
      shouldShowRemoveWarning,
      updating,
      errors,
      centreId,
      classId,
      groupName,
      updateStudents,
    } = this.props;

    return (
      <div className="margin-vertical-base">
        <form>
          <Field
            component={EditStudentsField}
            centreId={centreId}
            disabled={updating}
            name="students"
            groupName={groupName}
          />
          {shouldShowRemoveWarning ? <RemoveStudentsWarning /> : null}
          <FormErrorsRenderer formErrors={errors} errorKey="students" />
          <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors" />
          {updating && <p>Updating...</p>}
          <SpacedGroup>
            <Button
              disabled={updating}
              className="primary"
              onClick={updateStudents}
            >
              Save <Term>Class</Term> <Term>Student</Term>s
            </Button>
            <Link disabled={updating} to={`/classes/${classId}/`}>
              Cancel
            </Link>
          </SpacedGroup>
        </form>
      </div>
    );
  }
}

export default reduxForm({ form: EDIT_STUDENTS_FORM })(EditStudentsForm);
