import * as React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';

import CourseSummaryStudent from './course-summary-student';
import CourseSummaryTeacher from './course-summary-teacher';

interface IExternalProps {
  studentId?: string;
  course: IDashboardQualificationOption;
  loadCourseProgressForStudent(
    course: IDashboardQualificationOption,
    studentId?: string
  ): void;
  loadCourseProgressForTeacher(course: IDashboardQualificationOption): void;
}

interface ICourseSummaryProps extends IExternalProps {
  profile: IProfile;
}

export class CourseSummary extends React.PureComponent<
  ICourseSummaryProps,
  {}
> {
  public render() {
    const {
      course,
      profile,
      studentId,
      loadCourseProgressForTeacher,
      loadCourseProgressForStudent,
    } = this.props;
    return (
      <div className="course-summary">
        {course.is_filtered_student ? (
          <CourseSummaryStudent
            course={course}
            studentId={studentId}
            loadProgress={loadCourseProgressForStudent}
          />
        ) : (
          <CourseSummaryTeacher
            course={course}
            profile={profile}
            loadProgress={loadCourseProgressForTeacher}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ profile }: IStore, props: IExternalProps) {
  return {
    ...props,
    profile,
  };
}

export default connect(mapStateToProps)(CourseSummary);
