import {
  anyPending,
  hasSucceeded,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ContentBox,
  ContentBoxHeader,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  DataShape,
  Field,
  FormComponentProps,
  FormProps,
  reduxForm,
} from 'redux-form';
import { closeModal, openModal } from '../../actions/modals';
import { BULK_UPDATE_STATUS } from '../../actions/tasks';
import { IRouteProps } from '../../index';
import { LOAD_ITEM } from '../../items/actions';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import {
  IClassTaskRecord,
  IMarkschemeRecord,
  INestedUserTask,
} from '../../store/data-types/tasks';
import { STATUS } from '../../store/data-types/tasks';
import {
  constantToTitleCase,
  formatDate,
  formatDateForBackend,
  FormErrors,
} from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import Loading from '../loading';
import ProgressShort from '../progress-short';
import { default as SimpleTable, IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';
import ExamResultsIndicator from './exam-results-indicator';
import GradeIndicator from './grade-indicator';
import MarkschemeModal from './markscheme-modal';
import StatusIndicator from './status-indicator';
import TaskDetailBase from './task-detail-base';

const { actions: { loadItem } } = itemsModule;

function getHeaders(
  canHandInAny: boolean,
  profile: IProfile
): Array<IColumnData<INestedUserTask>> {
  return [
    {
      content: data =>
        canHandInAny ? (
          <div>
            <Field
              title={
                data.status !== STATUS.IN_PROGRESS
                  ? `Cannot hand in a ${terminologyFromProfile(
                      profile,
                      'Task'
                    )} unless it's ${constantToTitleCase(STATUS.IN_PROGRESS)}`
                  : ''
              }
              name={data.id}
              component="input"
              type="checkbox"
              disabled={data.status !== STATUS.IN_PROGRESS}
            />
          </div>
        ) : (
          ''
        ),
      headerLabel: '',
      key: 'check',
      sortable: false,
    },
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/`}>
          {narrowToRecord(data.user).osms_data.name}
        </Link>
      ),
      headerLabel: 'Name',
      key: 'name',
      sortable: false,
    },
    {
      content: data => <span>{data.tagged_asset_count}</span>,
      headerLabel: 'Files',
      key: 'files',
      sortable: false,
    },
    {
      content: data => formatDate(data.end_date),
      headerLabel: 'Assignment Due Date',
      key: 'end_date',
      sortable: false,
    },
    {
      content: data => (
        <StatusIndicator task={data}>
          <GradeIndicator grade={data.grade} prefix=": " />
          <ExamResultsIndicator examResults={data.exam_results} prefix=" " />
        </StatusIndicator>
      ),
      headerLabel: 'Status',
      key: 'status',
      sortable: false,
    },
    {
      content: data => <ProgressShort progress={data.progress} />,
      headerLabel: 'Progress',
      key: 'progress',
      sortable: false,
    },
    {
      content: data => (
        <Link to={`/tasks/marksheet/${data.id}/checklist/`}>
          View Checklist
        </Link>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

interface IProps
  extends IRouteProps,
    FormComponentProps,
    FormProps<DataShape, void, void> {
  canHandInAny: boolean;
  classTaskId: string;
  classTask: IClassTaskRecord | null;
  errors: FormErrors;
  markscheme: IMarkschemeRecord | null;
  successfullyUpdated: boolean;
  loading: boolean;
  profile: IProfile;
  openModal: typeof openModal;
  closeModal: typeof closeModal;
  loadItem(type: keyof IItemsState, itemId: string): void;
}

export class TaskDetailTeacher extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.openMarkschemeModal = this.openMarkschemeModal.bind(this);
  }

  public openMarkschemeModal() {
    if (this.props.markscheme) {
      this.props.openModal(
        <MarkschemeModal
          closeModal={this.props.closeModal}
          markscheme={this.props.markscheme}
        />
      );
    }
  }

  public componentWillMount() {
    this.props.loadItem('tasks/class-task-markscheme', this.props.classTaskId);
  }

  public render() {
    const {
      canHandInAny,
      classTask,
      errors,
      handleSubmit,
      loading,
      successfullyUpdated,
      profile,
    } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <TaskDetailBase
          headers={getHeaders(canHandInAny, profile)}
          successMessage={
            successfullyUpdated
              ? `${terminologyFromProfile(profile, 'Task')} handed in!`
              : undefined
          }
          loading={loading}
          classTask={classTask}
          openMarkschemeModal={this.openMarkschemeModal}
        >
          <SpacedGroup>
            {canHandInAny && (
              <div>
                <Button
                  className="primary margin-top-large"
                  onClick={this.props.handleSubmit}
                >
                  Hand In
                </Button>
                <FormErrorsRenderer
                  formErrors={errors}
                  errorKey="non_field_errors"
                />
                <FormErrorsRenderer formErrors={errors} errorKey="status" />
              </div>
            )}
          </SpacedGroup>
        </TaskDetailBase>
      </form>
    );
  }
}

function mapStateToProps(
  { itemsOld, responses, profile }: IStore,
  props: IRouteProps & FormComponentProps
) {
  const classTask = itemsOld.get('tasks/class');
  return {
    ...props,
    canHandInAny:
      userHasRoles(
        profile,
        Set.of(TEACHER, CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
      ) &&
      !!classTask &&
      !classTask.user_tasks
        .filter(each => each.status === STATUS.IN_PROGRESS)
        .isEmpty(),
    classTask,
    errors: getFormErrors(responses, BULK_UPDATE_STATUS),
    markscheme: itemsOld.get('tasks/class-task-markscheme'),
    classTaskId: props.params.id,
    loading: anyPending(responses, [
      BULK_UPDATE_STATUS,
      [LOAD_ITEM, 'tasks/class'],
      [LOAD_ITEM, 'tasks/class-task-markscheme'],
    ]),
    profile,
    successfullyUpdated: hasSucceeded(responses, BULK_UPDATE_STATUS),
  };
}

export interface ITasksFormData {
  [userTaskId: string]: boolean;
}
export const TASK_DETAIL_TEACHER = 'task-detail-teacher';
const Formified = reduxForm({
  form: TASK_DETAIL_TEACHER,
})(TaskDetailTeacher);
export default connect(mapStateToProps, {
  loadItem,
  openModal,
  closeModal,
})(Formified);
