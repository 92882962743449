import { Container } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { ComponentClass, connect } from 'react-redux';
import { Link } from 'react-router';
const { IconLongArrowLeft } = FontAwesome;

import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';

import { UNHIJACK_ACCOUNT, unhijackAccount } from '../../actions/hijack';
import { formatRoles } from '../../utils';

interface IHijackBarProps {
  profile: IProfile;
  unhijackAccount(tag?: string): AxiosPromise;
}

export class HijackBar extends React.PureComponent<IHijackBarProps, void> {
  public unhijackAccount() {
    this.props.unhijackAccount().then(() => {
      window.location.reload();
    });
  }

  public render() {
    const { profile, unhijackAccount } = this.props;

    return profile.hijacker ? (
      <div className="hijack-bar padding-vertical-base md-padding-vertical-large">
        <Container fluid>
          <div>
            <a
              className="float-left margin-right-base"
              onClick={this.unhijackAccount.bind(this)}
            >
              <IconLongArrowLeft className="back-arrow" />
              <span> Back</span>
            </a>
            <span>
              <span className="bold">
                {profile.hijacker.name} ({profile.hijacker.role})
              </span>
              <span> logged in as </span>
              <span className="bold">
                {profile.osms_data.name} ({formatRoles(
                  profile.current_role ? [profile.current_role] : [],
                  profile
                )})
              </span>
            </span>
          </div>
        </Container>
      </div>
    ) : null;
  }
}
