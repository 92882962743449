import {
  anyPending,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { addClassAndRedirectToEditStudentsPage } from '../../actions/classes';
import { ADD_TO_COLLECTION, GET_COLLECTION } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { canSelectClassTeacher } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { FormErrors } from '../../utils';
import SearchableCentreSwitcher from '../centres/searchable-centre-switcher';
import Term from '../terminology';
import ClassForm, { IClassFormData } from './class-form';

interface ICreateClassPageProps {
  creating: boolean;
  errors: FormErrors | undefined;
  loading: boolean;
  profile: IProfile;
  qualifications: List<IQualificationRecord>;
  students: List<IProfile>;
  teachers: List<IProfile>;
  internalQualityAssurers: List<IProfile>;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
  addClass(data: IPostTransformFormClassData): void;
}

const { actions: { getAllCollection } } = collectionsModule;

export class CreateClassPage extends React.PureComponent<
  ICreateClassPageProps,
  void
> {
  public componentWillMount() {
    const userTypeFilters = (userType: string) => Map({ user_type: userType });
    this.props.getAllCollection(
      'users',
      { filters: userTypeFilters('student') },
      'students'
    );
    this.props.getAllCollection(
      'users',
      { filters: userTypeFilters('teacher') },
      'teachers'
    );
    this.props.getAllCollection(
      'users',
      { filters: userTypeFilters('internal_quality_assurer') },
      'internal_quality_assurers'
    );
    this.props.getAllCollection('courses', {});
  }

  public render() {
    const {
      creating,
      errors,
      loading,
      qualifications,
      students,
      teachers,
      internalQualityAssurers,
      profile,
    } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            Create <Term>Class</Term>
          </h2>
        </ContentBoxHeader>
        <SearchableCentreSwitcher reloadCurrentPage={true} />
        {profile.current_centre ? (
          <ClassForm
            profile={profile}
            onSubmit={this.createClass.bind(this)}
            loading={loading}
            updating={creating}
            qualifications={qualifications}
            students={students}
            teachers={teachers}
            internalQualityAssurers={internalQualityAssurers}
            errors={errors}
            modeOptions={{
              mode: 'CREATING',
              canSelectTeachers: canSelectClassTeacher(profile),
            }}
          />
        ) : (
          <p>Please select a centre first.</p>
        )}
      </ContentBox>
    );
  }

  private createClass(data: IClassFormData) {
    const teachers = !canSelectClassTeacher(this.props.profile)
      ? [this.props.profile.id]
      : data.teachers ? data.teachers.toJS() : [this.props.profile.id];

    const dataToSubmit: IPostTransformFormClassData = _.extend({}, data, {
      teachers,
    });
    this.props.addClass(dataToSubmit);
  }
}

export interface IPostTransformFormClassData {
  name?: string;
  description?: string;
  qualification?: string;
  teachers: string[];
  internal_quality_assurers: string[];
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  return {
    creating: isPending(responses, ADD_TO_COLLECTION, 'classes'),
    errors: getFormErrors(responses, ADD_TO_COLLECTION, 'classes'),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'users'],
      [GET_COLLECTION, 'courses'],
    ]),
    profile,
    qualifications:
      getCollectionItems(collectionsOld.courses) ||
      List<IQualificationRecord>(),
    students:
      getCollectionItems(collectionsOld.users, 'students') || List<IProfile>(),
    teachers:
      getCollectionItems(collectionsOld.users, 'teachers') || List<IProfile>(),
    internalQualityAssurers:
      getCollectionItems(collectionsOld.users, 'internal_quality_assurers') ||
      List<IProfile>(),
  };
}
export default connect(mapStateToProps, {
  getAllCollection,
  addClass: addClassAndRedirectToEditStudentsPage,
})(CreateClassPage);
