import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
} from '@dabapps/roe';
import * as React from 'react';
import { IMarkschemeRecord } from '../../store/data-types/tasks';
import ModalCloseIcon from '../modals/modal-close-icon';

interface IProps {
  markscheme: IMarkschemeRecord;
  closeModal(): void;
}

const MarkschemeModal = ({ closeModal, markscheme }: IProps) => (
  <Modal scrollable onClickOutside={closeModal}>
    <ModalHeader>
      <ModalCloseIcon onClick={closeModal} />
      <p>{markscheme.title}</p>
    </ModalHeader>
    <ModalBody>
      {markscheme.strands.map(strand => (
        <Section key={strand.id}>
          <h3>{strand.title}</h3>
          {strand.groups.map(group => (
            <div key={group.id}>
              <p>
                <strong>{group.title}</strong>
              </p>
              <ul>
                {group.blocks.map(block => (
                  <li key={block.id}>{block.title}</li>
                ))}
              </ul>
            </div>
          ))}
        </Section>
      ))}
    </ModalBody>
    <ModalFooter>
      <p>
        <Button onClick={closeModal}>Close</Button>
      </p>
    </ModalFooter>
  </Modal>
);

export default MarkschemeModal;
