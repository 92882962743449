import * as React from 'react';

import * as classNames from 'classnames';
import ProgressRecalculating from './progress-recalculating';

interface IProps {
  progress: number | null;
  className?: string;
  width?: string;
}

export default function ProgressShort({ progress, className, width }: IProps) {
  if (progress === null) {
    return <ProgressRecalculating />;
  }

  return (
    <div
      className={classNames('progress-short', className)}
      style={width ? { width } : {}}
    >
      <span
        style={{
          width: `${progress * 100}%`,
        }}
      >
        {`${Math.round(progress * 100)}%`}
      </span>
    </div>
  );
}
