import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { pairs } from '../../functional';
import { TBulkUploadResponseErrors } from '../../store/data-types/bulk-upload';

const { IconExclamationTriangle } = FontAwesome;

interface IProps {
  processingErrors: TBulkUploadResponseErrors;
}

const BulkImportErrors = ({ processingErrors }: IProps) => (
  <Table fill striped>
    <TableHead>
      <TableRow>
        <TableHeader>Row</TableHeader>
        <TableHeader>Problem</TableHeader>
      </TableRow>
    </TableHead>
    <TableBody>
      {pairs(processingErrors).map(([k, error]) => (
        <TableRow key={k}>
          <TableCell>{k}</TableCell>
          <TableCell>
            <IconExclamationTriangle className="icon-small ml-1 icon-error" />{' '}
            {error}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default BulkImportErrors;
