import * as React from 'react';

import { Alert, Button, Column, Row } from '@dabapps/roe';
import { FormProps, reduxForm } from 'redux-form';

import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';

export interface IFormData {
  old_password: string;
  password: string;
  password2: string;
}

interface IProps {
  formErrors?: FormErrors;
  isLoading: boolean;
  hasSucceeded: boolean;
  onSubmit(data: IFormData): void;
}

export class ChangePasswordForm extends React.PureComponent<
  FormProps<IFormData, {}, {}> & IProps,
  void
> {
  public render() {
    return (
      <Row>
        <Column>
          <form onSubmit={this.props.handleSubmit}>
            <GroupField
              label="Existing Password"
              name="old_password"
              type="password"
              formErrors={this.props.formErrors}
            />
            <GroupField
              label="New Password"
              name="password"
              type="password"
              formErrors={this.props.formErrors}
            />
            <GroupField
              label="Repeat Password"
              name="password2"
              type="password"
              formErrors={this.props.formErrors}
            />
            {this.props.hasSucceeded && (
              <Alert className="success">
                <p>Your password has been successfully changed.</p>
              </Alert>
            )}
            <p>
              <Button type="submit" disabled={this.props.isLoading}>
                Save
              </Button>
            </p>
            <FormErrorsRenderer
              formErrors={this.props.formErrors}
              errorKey="non_field_errors"
            />
          </form>
        </Column>
      </Row>
    );
  }
}

export default reduxForm({
  form: 'profile-change-password',
})(ChangePasswordForm);
