import { IAction } from '../types';

import { SET_MENU_DISPLAY, SET_QUALIFICATION } from '../actions/dashboard';
import { IDashboardQualificationOption } from '../store/data-types/qualifications';

export interface IDashboardQualificationsState {
  isShowingMenu: boolean;
  currentQualification: IDashboardQualificationOption | null;
}
export const dashboardQualificationsState: IDashboardQualificationsState = {
  isShowingMenu: false,
  currentQualification: null,
};
export function dashboardQualifications(
  state = dashboardQualificationsState,
  action: IAction<any, any>
) {
  switch (action.type) {
    case SET_QUALIFICATION:
      return {
        currentQualification: action.payload,
        isShowingMenu: false,
      };
    case SET_MENU_DISPLAY:
      return {
        ...state,
        isShowingMenu: action.payload,
      };
    default:
      return state;
  }
}
