import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store/';
import { IOptionRecord } from '../../store/data-types';
import { IProfile } from '../../store/data-types/profile';
import { IQualificationStudentProgress } from '../../store/data-types/progress';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';
import { sum } from '../../utils';
import Loading from '../loading';
import { MultiProgressBar } from '../multi-progress-bar';
import { ProgressRing } from '../progress-ring';
import Term from '../terminology';
import { getComponentName } from './course-summary-student';
import TasksTeacher from './tasks-table-teacher';

interface IExternalProps {
  course: IDashboardQualificationOption;
  profile: IProfile;
  loadProgress(course: IDashboardQualificationOption): void;
}

interface ICourseSummaryTeacherProps extends IExternalProps {
  items: List<IQualificationStudentProgress>;
  loading: boolean;
}

export class CourseSummaryTeacher extends React.PureComponent<
  ICourseSummaryTeacherProps,
  {}
> {
  constructor(props: ICourseSummaryTeacherProps) {
    super(props);
    this.refresh = this.refresh.bind(this);
  }

  public componentWillMount() {
    this.refresh();
  }

  public render() {
    const { course, loading, items, profile } = this.props;
    const progressOpen = sum(items.map(each => each.tasks_in_progress));
    const progressAwaitingMarked = sum(
      items.map(each => each.tasks_awaiting_marking)
    );
    const progressMarked = sum(items.map(each => each.tasks_marked));
    const progressPendingSubmmission = sum(
      items.map(each => each.tasks_pending_submission)
    );
    const progressQualityAssured = sum(
      items.map(each => each.tasks_quality_assured)
    );
    const progressSubmitted = sum(items.map(each => each.tasks_submitted));
    const progressMarkedTotal = sum(
      List.of(
        progressMarked,
        progressPendingSubmmission,
        progressQualityAssured
      )
    );

    const tasksProgress = [
      {
        className: 'progress-open',
        progress: progressOpen,
      },
      {
        className: 'progress-completed',
        progress: progressAwaitingMarked,
      },
      {
        className: 'progress-marked',
        progress: progressMarkedTotal,
      },
      // {
      //   className: 'progress-submitted',
      //   progress: progressSubmitted,
      // },
    ];

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Section>
          <Row>
            <Column xs={12} sm={8} md={9} lg={10}>
              <p>
                <Term>Task</Term> Breakdown
              </p>
              <MultiProgressBar progressElements={tasksProgress} />
              <p>
                <span className="progress-label">
                  <span className="progress-swatch progress-open" />
                  Open ({progressOpen})
                </span>
                <span className="progress-label">
                  <span className="progress-swatch progress-completed" />
                  Awaiting Marking ({progressAwaitingMarked})
                </span>
                <span className="progress-label">
                  <span className="progress-swatch progress-marked" />
                  Marked ({progressMarkedTotal})
                </span>
                {/* <span className="progress-label">
                  <span className="progress-swatch progress-submitted" />
                  Submitted ({progressSubmitted})
                </span> */}
              </p>
            </Column>
          </Row>
        </Section>

        <Section>
          <TasksTeacher
            items={items}
            loading={loading}
            componentName={getComponentName(course.id)}
            qualificationId={course.id}
            complexRoc={course.option_complex_roc}
            refresh={this.refresh}
          />
        </Section>
      </div>
    );
  }

  private refresh() {
    this.props.loadProgress(this.props.course);
  }
}

function mapStateToProps(
  { collectionsOld, responses }: IStore,
  { course, profile }: IExternalProps
) {
  return {
    course,
    items: getCollectionItems(
      collectionsOld.get('courses/student-progress'),
      getComponentName(course.id)
    ),
    loading: isPending(responses, GET_COLLECTION, 'courses/student-progress'),
    profile,
  };
}

export default connect(mapStateToProps)(CourseSummaryTeacher);
