import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';

import {
  Alert,
  Button,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List } from 'immutable';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import {
  CREATE_USER,
  createUser,
  ITransformedRole,
  IUserData,
} from '../../actions/users';
import { getFormErrors } from '../../responses';
const { IconInfoCircle } = FontAwesome;
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import { IRoleRecord } from '../../store/data-types/roles';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import Loading from '../loading';
import AddUserForm, { INITIAL_ROLES, IUserFormData } from './add-user-form';

export const ADD_USER = 'ADD_USER';

export function transformFormData(
  data: IUserFormData,
  includeRoles: boolean = true
): IUserData {
  const roles = data.roles || List<IRoleRecord>();
  const transformedRoles = roles
    .filter(each => !!each.role)
    .map(each => ({
      role: each.role,
      centre: (each.centre && each.centre.id) || '',
      exam_board: (each.exam_board && each.exam_board.id) || '',
    }))
    .toArray();
  return {
    ...data,
    roles: includeRoles ? transformedRoles : [],
  };
}

interface IProps {
  formErrors: FormErrors;
  loading: boolean;
  profile: IProfile;
  createUser(data: IUserData, tag: string): AxiosPromise;
  push(url: string): void;
}

export class AddUser extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.createUser = this.createUser.bind(this);
  }

  public render() {
    const { formErrors, profile, loading } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Add User</h2>
        </ContentBoxHeader>
        <AddUserForm
          profile={profile}
          errors={formErrors}
          onSubmit={this.createUser}
          loading={loading}
          disabled={loading}
          initialValues={{ roles: INITIAL_ROLES }}
        >
          <ContentBoxFooter>
            <FormErrorsRenderer
              formErrors={formErrors}
              errorKey="non_field_errors"
            />
            <SpacedGroup block className="margin-vertical-large">
              <Button type="submit" className="button primary">
                Add user
              </Button>
              <Link to="/users/">...or cancel and return to user listing</Link>
            </SpacedGroup>

            {loading && <Loading />}

            <Alert className="helper-text text-align-left">
              <h3>
                <IconInfoCircle className="icon-small margin-bottom-small margin-right-base" />What
                happens when I've added a user?
              </h3>
              <p>
                <strong>If an email address is provided</strong>, they will be
                emailed a link to log into Manage, along with their password,
                which they will have to change on login.
              </p>
              <p>
                <strong>
                  If they haven't an email address and a username is provided
                </strong>, then you can give them a{' '}
                <a href="/accounts/login/">link to the login screen</a> plus
                their username and password (as you set above) which they will
                have to change when they first log in.
              </p>
            </Alert>
          </ContentBoxFooter>
        </AddUserForm>
      </ContentBox>
    );
  }

  private createUser(data: IUserFormData) {
    this.props
      .createUser(transformFormData(data), ADD_USER)
      .then(() => this.props.push('/users/'))
      .catch(() => null);
  }
}

function mapStateToProps({ profile, responses }: IStore) {
  return {
    formErrors: getFormErrors(responses, CREATE_USER, ADD_USER),
    loading: isPending(responses, CREATE_USER, ADD_USER),
    profile,
  };
}

export default connect(mapStateToProps, { createUser, push })(AddUser);
