import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  InputGroup,
  InputGroupAddon,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { narrowToRecord, narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import * as classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  Selector,
  WrappedFieldProps,
} from 'redux-form';
import * as _ from 'underscore';
import { clearUIState, setUIState } from '../../../actions';
import {
  LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE,
  learningOutcomeBlockUpdateMultiple,
  resetRecordedResultStatus,
} from '../../../actions/marksheet';
import { UPDATE_ITEM } from '../../../items/actions';
import {
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
} from '../../../permissions';
import {
  getEQAChecklistPermissions,
  getIQAChecklistPermissions,
  getStudentChecklistPermissions,
  getTeacherChecklistPermissions,
  TCheckboxPermissions,
} from '../../../permissions/marksheets';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store/';
import { IAssetRecord } from '../../../store/data-types';
import { IClassRecord } from '../../../store/data-types/classes';
import { MARKING_CODES } from '../../../store/data-types/courses';
// tslint:disable-next-line:no-unused-variable
import {
  IChecklistRecord,
  ILearningOutcomeBlock,
  ILearningOutcomeBlockRecord,
  ILearningOutcomeBlockUpdate,
  ILearningOutcomeRecord,
  INestedLearningOutcomeBlockAsset,
  LearningOutcomeBlockUpdate,
} from '../../../store/data-types/marksheets';
import { IProfile, TRole } from '../../../store/data-types/profile';
import { IUserTaskRecord } from '../../../store/data-types/tasks';
import EllipsizedFileName from '../../ellipsized-file-name';
import MaybeAssetAnchor from '../../maybe-asset-anchor';
import { terminologyFromProfile } from '../../terminology';
import { ILearningOutcomeBlockInitialValues } from './checklist-page';
import LearningOutcomeBlockComments from './learning-outcome-block-comments';

const { actions: { updateItem } } = itemsModule;
const { IconCheck, IconSpinner, IconTimes } = FontAwesome;

interface IBaseCheckboxData {
  checked_by_teacher?: boolean;
  checked_by_student?: boolean;
  checked_by_iqa?: boolean;
  checked_by_eqa?: boolean;
}
type TCheckedKey = keyof IBaseCheckboxData;

const checkedKeyToManuallyCheckedParameterMapping: {
  [K in TCheckedKey]: keyof ILearningOutcomeBlock | null
} = {
  checked_by_teacher: 'checked_by_teacher_manually',
  checked_by_student: 'checked_by_student_manually',
  checked_by_iqa: 'checked_by_iqa_manually',
  checked_by_eqa: null,
};

const checkedKeyToAutomaticallyCheckedParameterMapping: {
  [K in TCheckedKey]: keyof ILearningOutcomeBlock | null
} = {
  checked_by_teacher: 'checked_by_teacher_automatically',
  checked_by_student: 'checked_by_student_automatically',
  checked_by_iqa: 'checked_by_iqa_automatically',
  checked_by_eqa: null,
};

const checkedKeyToUserRoleMapping: { [K in TCheckedKey]: TRole } = {
  checked_by_teacher: TEACHER,
  checked_by_student: STUDENT,
  checked_by_iqa: INTERNAL_QUALITY_ASSURER,
  checked_by_eqa: EXTERNAL_QUALITY_ASSURER,
};

type TUserSuffix = 'student' | 'teacher' | 'iqa' | 'eqa';

const checkedKeyToUserSuffixMapping: { [K in TCheckedKey]: TUserSuffix } = {
  checked_by_teacher: 'teacher',
  checked_by_student: 'student',
  checked_by_iqa: 'iqa',
  checked_by_eqa: 'eqa',
};

export interface ICheckboxData extends IBaseCheckboxData {
  recorded_result?: string;
}

interface IExternalProps {
  checklist: IChecklistRecord;
  form: string;
  initialValues: ILearningOutcomeBlockInitialValues;
  learningOutcome: ILearningOutcomeRecord;
  selfMark: boolean;
  task: IUserTaskRecord;
  taskClass: IClassRecord;
  onAfterUpdate?: () => void;
}

interface IPropsWithoutActions extends IExternalProps {
  recordedResultValues: Map<string, string | undefined>;
  recordedResultStatuses: Map<string, string>;
  currentValues: Map<string, ICheckboxData>;
  loading: boolean;
  profile: IProfile;
  uiState: Map<string, string>;
}

interface IProps extends IPropsWithoutActions {
  clearUIState: typeof clearUIState;
  setUIState: typeof setUIState;
  update(id: string, data: ICheckboxData): AxiosPromise;
  resetRecordedResultStatus(learningOutcomeBlockId: string): void;
  learningOutcomeBlockUpdateMultiple(
    items: ReadonlyArray<ILearningOutcomeBlockUpdate>,
    tag?: string
  ): AxiosPromise;
}

type TLearningOutcomeBlockFormProps = React.HTMLProps<JSX.Element> &
  FormProps<DataShape, void, void> &
  IProps;

const MAX_ASSET_COUNT = 5;

export class LearningOutcome extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.renderHeaderCell = this.renderHeaderCell.bind(this);
    this.renderCheckboxCell = this.renderCheckboxCell.bind(this);
    this.renderProgress = this.renderProgress.bind(this);
    this.renderStudentProgress = this.renderStudentProgress.bind(this);
    this.renderTeacherProgress = this.renderTeacherProgress.bind(this);
    this.renderIQAProgress = this.renderIQAProgress.bind(this);
    this.renderEQAProgress = this.renderEQAProgress.bind(this);
    this.getChecklistPermissions = this.getChecklistPermissions.bind(this);
    this.renderAssets = this.renderAssets.bind(this);
    this.updateLearningOutcomeBlock = this.updateLearningOutcomeBlock.bind(
      this
    );
    this.updateLearningOutcomeGroup = this.updateLearningOutcomeGroup.bind(
      this
    );
  }

  public render() {
    const {
      currentValues,
      learningOutcome,
      update,
      loading,
      profile,
      task,
      selfMark,
      recordedResultValues,
      recordedResultStatuses,
      checklist: { strand: { marking_code } },
    } = this.props;

    const user = narrowToRecord(task.user);
    const studentPermissions = this.getChecklistPermissions(
      'checked_by_student'
    );
    const teacherPermissions = this.getChecklistPermissions(
      'checked_by_teacher'
    );
    const iqaPermissions = this.getChecklistPermissions('checked_by_iqa');
    const eqaPermissions = this.getChecklistPermissions('checked_by_eqa');

    return (
      <TableBody>
        <TableRow className="highlight">
          <TableHeader colSpan={selfMark ? 1 : 2}>
            {learningOutcome.group.title}
          </TableHeader>
          {this.renderHeaderCell(
            studentPermissions,
            `${user.osms_data.name} (${terminologyFromProfile(
              profile,
              'Student'
            )})`,
            'checked_by_student'
          )}
          {this.renderHeaderCell(
            teacherPermissions,
            terminologyFromProfile(profile, 'Teacher'),
            'checked_by_teacher'
          )}
          {this.renderHeaderCell(
            iqaPermissions,
            terminologyFromProfile(profile, 'Internal Quality Assurer'),
            'checked_by_iqa'
          )}
          {this.renderHeaderCell(
            eqaPermissions,
            terminologyFromProfile(profile, 'External Quality Assurer'),
            'checked_by_eqa'
          )}
        </TableRow>
        {learningOutcome.learning_outcome_blocks.map(learningOutcomeBlock => (
          <TableRow key={learningOutcomeBlock.id}>
            <TableCell colSpan={selfMark ? 1 : 2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: learningOutcomeBlock.block.title,
                }}
              />
            </TableCell>
            {this.renderCheckboxCell(
              learningOutcomeBlock,
              studentPermissions,
              'checked_by_student'
            )}
            {this.renderCheckboxCell(
              learningOutcomeBlock,
              teacherPermissions,
              'checked_by_teacher'
            )}
            {this.renderCheckboxCell(
              learningOutcomeBlock,
              iqaPermissions,
              'checked_by_iqa'
            )}
            {this.renderCheckboxCell(
              learningOutcomeBlock,
              eqaPermissions,
              'checked_by_eqa'
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  private renderHeaderCell(
    permissions: TCheckboxPermissions,
    label: string,
    fieldName: TCheckedKey
  ): React.ReactNode {
    if (permissions === 'HIDE') {
      return false;
    }

    const { learningOutcome } = this.props;

    let progressRenderer: React.ReactNode | undefined;

    switch (fieldName) {
      case 'checked_by_teacher':
        progressRenderer = this.renderTeacherProgress();
        break;
      case 'checked_by_student':
        progressRenderer = this.renderStudentProgress();
        break;
      case 'checked_by_iqa':
        progressRenderer = this.renderIQAProgress();
        break;
      case 'checked_by_eqa':
        progressRenderer = this.renderEQAProgress();
        break;
      default:
        progressRenderer = undefined;
        break;
    }
    return (
      <TableHeader width={250}>
        <div className="text-align-center">
          <div>{label}</div>
          <div className="info font-weight-normal margin-top-small">
            {progressRenderer}
          </div>
          {this.isCheckboxOutcome() && (
            <Field
              name={`${fieldName}-${learningOutcome.id}`}
              component="input"
              type="checkbox"
              disabled={permissions === 'SHOW_DISABLED'}
              onChange={this.updateLearningOutcomeGroup.bind(
                null,
                learningOutcome,
                fieldName
              )}
            />
          )}
        </div>
      </TableHeader>
    );
  }

  private renderCheckboxCell(
    learningOutcomeBlock: ILearningOutcomeBlockRecord,
    permissions: TCheckboxPermissions,
    fieldName: TCheckedKey
  ) {
    const {
      update,
      currentValues,
      checklist: { strand: { marking_code } },
      recordedResultStatuses,
      task,
      recordedResultValues,
    } = this.props;
    const defaultData: ICheckboxData = { [fieldName]: false };
    const manuallyCheckedParameterName =
      checkedKeyToManuallyCheckedParameterMapping[fieldName];
    const automaticallyCheckedParameterName =
      checkedKeyToAutomaticallyCheckedParameterMapping[fieldName];

    const isAutomaticallyChecked = Boolean(
      automaticallyCheckedParameterName &&
        learningOutcomeBlock[automaticallyCheckedParameterName]
    );

    const isTeacherChecklist = fieldName === 'checked_by_teacher';
    const isStudentChecklist = fieldName === 'checked_by_student';
    const isIQAChecklist = fieldName === 'checked_by_iqa';
    const taskUserId = narrowToRecord(task.user).id;
    const taggedAssets = learningOutcomeBlock.learning_outcome_block_assets.filter(
      each => {
        if (isStudentChecklist) {
          return each.tagger_role === STUDENT;
        }
        if (isTeacherChecklist) {
          return each.tagger_role === TEACHER;
        }
        if (isIQAChecklist) {
          return each.tagger_role === INTERNAL_QUALITY_ASSURER;
        }
        return false;
      }
    );

    const fieldId = `${fieldName}-${learningOutcomeBlock.id}`;

    const currentCheckedValueForBlock = currentValues.get(
      learningOutcomeBlock.id,
      defaultData
    );
    const noOfCommentsKey = `number_of_comments_${checkedKeyToUserSuffixMapping[
      fieldName
    ]}` as keyof typeof learningOutcomeBlock;
    const criteriaMetOrallyKey = `criteria_met_orally_${checkedKeyToUserSuffixMapping[
      fieldName
    ]}` as keyof typeof learningOutcomeBlock;

    const hoverTitle = learningOutcomeBlock.is_result_from_external_system
      ? 'Result from exam system - cannot be overriden.'
      : '';

    return (
      permissions !== 'HIDE' && (
        <TableCell>
          <SpacedGroup block className="text-align-center">
            <Field
              name={fieldId}
              component="input"
              type="checkbox"
              onChange={this.updateLearningOutcomeBlock.bind(
                null,
                learningOutcomeBlock.id,
                {
                  [manuallyCheckedParameterName ||
                  fieldName]: !currentValues.get(
                    learningOutcomeBlock.id,
                    defaultData
                  )[fieldName],
                }
              )}
              disabled={
                permissions === 'SHOW_DISABLED' ||
                isAutomaticallyChecked ||
                learningOutcomeBlock.is_result_from_external_system
              }
            />
            {isTeacherChecklist &&
              marking_code === MARKING_CODES.RECR && (
                <InputGroup>
                  <Field
                    style={{ width: 60 }}
                    name={`recorded_result-${learningOutcomeBlock.id}`}
                    component="input"
                    placeholder="Result"
                    type="text"
                    onChange={() =>
                      this.props.resetRecordedResultStatus(
                        learningOutcomeBlock.id
                      )}
                    disabled={
                      permissions === 'SHOW_DISABLED' ||
                      learningOutcomeBlock.is_result_from_external_system
                    }
                    title={hoverTitle}
                  />
                  <InputGroupAddon
                    className={classNames(
                      'button primary add-result',
                      (permissions === 'SHOW_DISABLED' ||
                        learningOutcomeBlock.is_result_from_external_system) &&
                        'disabled'
                    )}
                    title={hoverTitle}
                    onClick={
                      permissions === 'SHOW_DISABLED' ||
                      learningOutcomeBlock.is_result_from_external_system
                        ? undefined
                        : (event: React.MouseEvent<HTMLDivElement>) =>
                            this.updateLearningOutcomeBlock(
                              learningOutcomeBlock.id,
                              {
                                recorded_result: recordedResultValues.get(
                                  `recorded_result-${learningOutcomeBlock.id}`
                                ),
                              }
                            )
                    }
                  >
                    {!recordedResultStatuses.get(learningOutcomeBlock.id) &&
                      'Save'}
                    {recordedResultStatuses.get(learningOutcomeBlock.id) ===
                      UPDATE_ITEM.REQUEST && (
                      <IconSpinner className="status-icon marksheet spinner" />
                    )}
                    {recordedResultStatuses.get(learningOutcomeBlock.id) ===
                      UPDATE_ITEM.SUCCESS && (
                      <IconCheck className="status-icon marksheet check" />
                    )}
                    {recordedResultStatuses.get(learningOutcomeBlock.id) ===
                      UPDATE_ITEM.FAILURE && (
                      <IconTimes className="status-icon marksheet times" />
                    )}
                  </InputGroupAddon>
                </InputGroup>
              )}
            {isTeacherChecklist &&
              marking_code === MARKING_CODES.RECR &&
              learningOutcomeBlock.exam_result_report && (
                <a
                  className="display-block"
                  href={learningOutcomeBlock.exam_result_report}
                  download
                >
                  Exam Report
                </a>
              )}
          </SpacedGroup>
          {taggedAssets && this.renderAssets(taggedAssets, fieldId)}
          {(isTeacherChecklist || currentCheckedValueForBlock[fieldName]) && (
            <LearningOutcomeBlockComments
              learningOutcomeBlockId={learningOutcomeBlock.id}
              numberOfComments={learningOutcomeBlock[noOfCommentsKey] as number}
              criteriaMetOrally={
                learningOutcomeBlock[criteriaMetOrallyKey] as boolean
              }
              title={learningOutcomeBlock.block.title}
              isAllowedToAddComments={permissions === 'SHOW_ENABLED'}
              evidenceTypeOral={learningOutcomeBlock.block.evidence_type_oral}
              userRole={checkedKeyToUserRoleMapping[fieldName]}
              loading={this.props.loading}
            />
          )}
        </TableCell>
      )
    );
  }

  private renderAssets(
    learningOutcomeBlockAssets: ReadonlyArray<INestedLearningOutcomeBlockAsset>,
    fieldId: string
  ) {
    const renderAllId = `renderAllAssets-${fieldId}`;
    const renderAll = this.props.uiState.get(renderAllId, false);
    const learningOutcomeBlockAssetsToRender = renderAll
      ? learningOutcomeBlockAssets
      : learningOutcomeBlockAssets.slice(0, MAX_ASSET_COUNT - 1);
    const hasMore =
      learningOutcomeBlockAssetsToRender.length <
      learningOutcomeBlockAssets.length;
    const hasLess =
      !hasMore &&
      renderAll &&
      learningOutcomeBlockAssetsToRender.length > MAX_ASSET_COUNT;
    return (
      <div className="text-align-center margin-top-small">
        {learningOutcomeBlockAssetsToRender.map(learningOutcomeBlockAsset => (
          <div
            className="learning-outcome-asset"
            key={learningOutcomeBlockAsset.id}
          >
            <MaybeAssetAnchor
              className="file-name"
              asset={learningOutcomeBlockAsset}
            >
              {learningOutcomeBlockAsset.asset_user_supplied_name ||
                learningOutcomeBlockAsset.asset_filename}
            </MaybeAssetAnchor>
            <span>,&nbsp;</span>
          </div>
        ))}
        {hasMore && (
          <div className="learning-outcome-asset">
            <a
              className="italic"
              onClick={() => this.props.setUIState(renderAllId, true)}
            >
              show all &#9662;
            </a>
          </div>
        )}
        {hasLess && (
          <div className="learning-outcome-asset">
            <a
              className="italic"
              onClick={() => this.props.clearUIState(renderAllId)}
            >
              show less &#9652;
            </a>
          </div>
        )}
      </div>
    );
  }

  private renderProgress(
    checkedKey: TCheckedKey
  ): React.ReactElement<HTMLDivElement> {
    const {
      checklist: { strand: { marking_code } },
      learningOutcome: {
        group: { lower_mark, upper_mark },
        learning_outcome_blocks,
      },
      currentValues,
    } = this.props;

    const total = learning_outcome_blocks.count();
    const checkedCount = currentValues
      .toList()
      .filter(block => block[checkedKey])
      .count();

    const max =
      marking_code === MARKING_CODES.R ? `(max: ${upper_mark || total})` : '';
    const required = typeof lower_mark === 'number' ? lower_mark : total;
    const more =
      required - checkedCount > 0 ? (
        <span className="warning">
          {`${required - checkedCount} more needed`}
        </span>
      ) : null;

    return (
      <div>
        {`${checkedCount} of ${required} ${max}`}
        {more ? ' - ' : ' '}
        {!more && <IconCheck className="icon-small success" />}
        {more}
      </div>
    );
  }

  private renderStudentProgress(): React.ReactElement<HTMLDivElement> {
    return this.renderProgress('checked_by_student');
  }

  private renderTeacherProgress(): React.ReactElement<HTMLDivElement> {
    return this.renderProgress('checked_by_teacher');
  }

  private renderIQAProgress(): React.ReactElement<HTMLDivElement> {
    return this.renderProgress('checked_by_iqa');
  }

  private renderEQAProgress(): React.ReactElement<HTMLDivElement> {
    return this.renderProgress('checked_by_eqa');
  }

  private getChecklistPermissions(
    permissionType: TCheckedKey
  ): TCheckboxPermissions {
    const {
      profile,
      task,
      taskClass,
      selfMark,
      checklist: { strand: { marking_code } },
      loading,
    } = this.props;

    const taskUser = narrowToRecord(task.user);
    const isRecr = marking_code === MARKING_CODES.RECR;
    const loggedInUser = profile;
    const isTaskUser = loggedInUser.id === taskUser.id;

    if (task.class_task.task_class.is_archived) {
      return 'SHOW_DISABLED';
    }

    switch (permissionType) {
      case 'checked_by_student':
        return getStudentChecklistPermissions(
          task,
          loggedInUser,
          isTaskUser,
          loading,
          isRecr,
          selfMark
        );
      case 'checked_by_teacher':
        return getTeacherChecklistPermissions(
          task,
          loggedInUser,
          isTaskUser,
          loading
        );
      case 'checked_by_iqa':
        return getIQAChecklistPermissions(
          task,
          loggedInUser,
          isTaskUser,
          loading,
          taskClass
        );

      case 'checked_by_eqa':
        return getEQAChecklistPermissions(
          task,
          loggedInUser,
          isTaskUser,
          loading
        );
      default:
        // Unreachable
        return 'HIDE';
    }
  }

  private updateLearningOutcomeBlock(id: string, data: ICheckboxData) {
    this.props.update(id, data).then(() => {
      if (this.props.onAfterUpdate) {
        this.props.onAfterUpdate();
      }
    });
  }

  private updateLearningOutcomeGroup(
    outcome: ILearningOutcomeRecord,
    field: TCheckedKey,
    event: React.FormEvent<HTMLInputElement>
  ) {
    const newValue = !(event.currentTarget.value === 'true'); // Browsers seem to report the old state, so we need to invert it
    const parameterName =
      checkedKeyToManuallyCheckedParameterMapping[field] || field;
    const autoParameterName =
      checkedKeyToAutomaticallyCheckedParameterMapping[field];

    const dataToSend = outcome.learning_outcome_blocks
      .filter(block => !(autoParameterName && block[autoParameterName]))
      .map(block =>
        LearningOutcomeBlockUpdate({
          id: block.id,
          [parameterName]: newValue,
        })
      );

    this.props.learningOutcomeBlockUpdateMultiple(
      dataToSend.toArray(),
      'CHECKLIST'
    );
  }

  private isCheckboxOutcome(): boolean {
    const { checklist: { strand: { marking_code } } } = this.props;
    return marking_code !== 'RECR';
  }
}

function mapStateToProps(
  store: IStore,
  props: IExternalProps
): IPropsWithoutActions {
  const { responses, profile, recordedResultStatuses, uiState } = store;
  const selector = formValueSelector(props.form);

  const currentValues: Map<
    string,
    ICheckboxData
  > = props.learningOutcome.learning_outcome_blocks.reduce((memo, block) => {
    return memo.set(block.id, {
      checked_by_student: selector(store, `checked_by_student-${block.id}`),
      checked_by_teacher: selector(store, `checked_by_teacher-${block.id}`),
      checked_by_iqa: selector(store, `checked_by_iqa-${block.id}`),
      checked_by_eqa: selector(store, `checked_by_eqa-${block.id}`),
    });
  }, Map<string, ICheckboxData>());

  const recordedResultValues = props.learningOutcome.learning_outcome_blocks
    .toKeyedSeq()
    .mapEntries(([index, block]) => [
      `recorded_result-${block.id}`,
      selector(store, `recorded_result-${block.id}`) as string | undefined,
    ])
    .toMap();

  return {
    ...props,
    recordedResultValues,
    recordedResultStatuses,
    currentValues,
    profile,
    loading: anyPending(responses, [
      [UPDATE_ITEM, 'marksheets/learning-outcome-blocks'],
      [LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE, 'CHECKLIST'],
    ]),
    uiState,
  };
}

export default reduxForm({ enableReinitialize: true })(
  connect(mapStateToProps, {
    clearUIState,
    setUIState,
    resetRecordedResultStatus,
    update: (id: string, data: ICheckboxData) =>
      updateItem('marksheets/learning-outcome-blocks', id, data),
    learningOutcomeBlockUpdateMultiple,
  })(LearningOutcome)
);
