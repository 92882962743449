import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as ReactSelect from 'react-select';
import { reduxForm } from 'redux-form';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { REQUEST_RATE_MS } from '../../constants';
import { CLASS_STATUS_CHOICES } from '../../constants/classes';
import { statusForUserRole } from '../../lookup-tables/status';
import { getViewingStudentIdIfRole } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { DispatchCallback } from '../../requests/types';
import { IStore } from '../../store';
import { CLASS_STATUSES } from '../../store/data-types/classes';
import { IProfile } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { IUserGradeListTaskRecord, STATUS } from '../../store/data-types/tasks';
import {
  formatDate,
  formatTaskStatusConstant,
  getCurrentCentreId,
  getCurrentCentreName,
} from '../../utils';
import ProgressShort from '../progress-short';
import CollectionInputFilter from '../tables/collection-input-filter';
import CollectionSelectFilter from '../tables/collection-select-filter';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';
import ExamResultsIndicator from './exam-results-indicator';
import GradeIndicator from './grade-indicator';
import StatusIndicator from './status-indicator';

const { actions: { getAllCollection, getCollection } } = collectionsModule;

interface IExternalProps {
  qualificationFilterId?: string;
  studentId?: string;
}

interface IProps extends IExternalProps {
  tasksCollection: ICollection<IUserGradeListTaskRecord>;
  loading: boolean;
  profile: IProfile;
  qualifications: List<IQualificationRecord>;
  getAllCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): void;
  getCollection(opts: ICollectionOptions, centre?: string): void;
}

const USER_TASK_LIST = 'USER_TASK_LIST';
const PAGE_SIZE = 10;

function getStudentVisibleStatusFilters(): List<{
  key: string;
  label: string;
}> {
  const statusKeyLabels = Map<
    keyof typeof STATUS,
    typeof STATUS[keyof typeof STATUS]
  >(STATUS)
    .keySeq()
    .toList();

  const statusKeyLabelsWithCorrectedLabels = statusKeyLabels.map(each => ({
    key: each,
    label: statusForUserRole(each, 'STUDENT').status,
  }));

  const statusKeyLabelsGroupedByLabel = statusKeyLabelsWithCorrectedLabels.groupBy(
    each => each.label
  );

  return statusKeyLabelsGroupedByLabel
    .map((each, label) => {
      return {
        key: each.map(item => item.key).join(','),
        label,
      };
    })
    .valueSeq()
    .toList();
}

const studentVisibleStatusFilters = getStudentVisibleStatusFilters();

export function getLinkToTask(
  profile: IProfile,
  task: IUserGradeListTaskRecord,
  studentId?: string
) {
  const viewingStudentId = getViewingStudentIdIfRole(profile, studentId);
  const baseTaskDetailLink = `/tasks/marksheet/${task.id}/`;
  return viewingStudentId
    ? `/users/student/${viewingStudentId}${baseTaskDetailLink}`
    : baseTaskDetailLink;
}

export class TasksLandingStudent extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.reloadCoursesByClassStatus = this.reloadCoursesByClassStatus.bind(
      this
    );
    this.getCollection = this.getCollection.bind(this);
  }

  public componentDidMount() {
    this.props.getAllCollection(
      'courses',
      {
        filters: Map({
          class_tasks__task_class__status: CLASS_STATUSES.ACTIVE,
        }),
      },
      USER_TASK_LIST
    );
  }

  public render() {
    const currentCentreName = getCurrentCentreName(this.props.profile);
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            My <Term>Tasks</Term> for {currentCentreName}
          </h2>
        </ContentBoxHeader>
        <Section>{this.filters()}</Section>
        <Section>
          <CollectionTable
            className={'class-table-tasks'}
            headers={this.getHeaders()}
            collection={this.props.tasksCollection}
            loading={this.props.loading}
            pageSize={PAGE_SIZE}
            getCollection={this.getCollection}
          />
        </Section>
      </ContentBox>
    );
  }

  private getCollection(options: ICollectionOptions) {
    const { profile, studentId } = this.props;
    const viewingStudentId = getViewingStudentIdIfRole(profile, studentId);

    const originalFilters =
      options.filters ||
      Map({ class_task__task_class__status: CLASS_STATUSES.ACTIVE });
    const filters = viewingStudentId
      ? originalFilters.set('user', viewingStudentId)
      : originalFilters;

    this.props.getCollection(
      {
        ...options,
        filters,
      },
      getCurrentCentreId(profile)
    );
  }

  private filters() {
    const { tasksCollection } = this.props;
    return (
      <Row>
        <Column sm={3}>
          <CollectionInputFilter
            formName="TasksLandingTeacher"
            label="Search"
            filterKey="search"
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={_.debounce(this.getCollection, REQUEST_RATE_MS)}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label="Qualification"
            name="qualification"
            filterKey="qualification"
            options={this.props.qualifications.map(({ id, name }) => ({
              key: id,
              label: name,
            }))}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={this.getCollection}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label="Status"
            name="status"
            filterKey="status"
            options={studentVisibleStatusFilters}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={this.getCollection}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label={
              <span>
                <Term>Class</Term> Status
              </span>
            }
            name="class_status"
            filterKey="class_task__task_class__status"
            options={CLASS_STATUS_CHOICES}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={this.getCollection}
            excludeNull
            onChange={this.reloadCoursesByClassStatus}
          />
        </Column>
      </Row>
    );
  }

  private reloadCoursesByClassStatus(value: string) {
    this.props.getAllCollection(
      'courses',
      {
        filters: Map({ class_tasks__task_class__status: value }),
      },
      USER_TASK_LIST
    );
  }

  private getHeaders(): Array<IColumnData<IUserGradeListTaskRecord>> {
    const { profile, studentId } = this.props;
    return [
      {
        content: data => (
          <div>
            <Link to={getLinkToTask(profile, data, studentId)}>
              {data.class_task.component_title}
            </Link>
            <div className="font-size-small">{data.class_task.unit_title}</div>
          </div>
        ),
        headerLabel: `${terminologyFromProfile(profile, 'Task')} Name`,
        key: 'class_task__component__title',
        sortable: true,
      },
      {
        content: data => <span>{data.tagged_asset_count}</span>,
        headerLabel: 'Files',
        key: 'files',
        sortable: false,
      },
      {
        content: data => formatDate(data.end_date),
        headerLabel: 'Due date',
        key: 'class_task__end_date',
        sortable: true,
      },
      {
        content: data => (
          <StatusIndicator task={data}>
            <GradeIndicator grade={data.grade} prefix=": " />
            <ExamResultsIndicator examResults={data.exam_results} prefix=" " />
          </StatusIndicator>
        ),
        headerLabel: 'Status',
        key: 'status',
        sortable: false,
      },
      {
        content: data => <ProgressShort progress={data.progress} />,
        headerLabel: 'Progress',
        key: 'progress',
        sortable: false,
      },
      {
        content: data => (
          <SpacedGroup>
            <Link to={`${getLinkToTask(profile, data, studentId)}checklist/`}>
              View Checklist
            </Link>
          </SpacedGroup>
        ),
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  }
}

function mapStateToProps(
  { collectionsOld, responses, profile }: IStore,
  existing: IExternalProps
) {
  const tasksCollection = getCollectionByName(
    collectionsOld.get('tasks/grades'),
    USER_TASK_LIST
  );
  const qualificationCollection = getCollectionByName(
    collectionsOld.get('courses'),
    USER_TASK_LIST
  );

  return _.extend({}, existing, {
    loading: anyPending(responses, [
      [GET_COLLECTION, 'tasks/grades'],
      [GET_COLLECTION, 'courses'],
    ]),
    profile,
    qualifications: qualificationCollection.results,
    tasksCollection,
  });
}

const Formified = reduxForm({ form: 'tasks-landing-student' })(
  TasksLandingStudent
);

function mapDispatchToProps(dispatch: DispatchCallback, props: IExternalProps) {
  return {
    getAllCollection: (
      type: keyof ICollectionsState,
      opts?: ICollectionOptions,
      tag?: string
    ) => dispatch(getAllCollection(type, opts, tag)),
    getCollection: (options: ICollectionOptions, centre?: string) => {
      const copiedOptions = { ...options };
      copiedOptions.filters = (copiedOptions.filters || Map<string, string>()
      ).set('centre', centre || '');
      const qualification = props.qualificationFilterId;
      copiedOptions.filters =
        qualification && !copiedOptions.filters.has('qualification')
          ? copiedOptions.filters.set('qualification', qualification)
          : copiedOptions.filters;
      dispatch(getCollection('tasks/grades', copiedOptions, USER_TASK_LIST));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Formified);
