import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Button, ContentBoxHeader, SpacedGroup } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { closeModal, openModal } from '../../actions/modals';
import { UPDATE_ITEM } from '../../items/actions';
import { itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import {
  CLASS_STATUSES,
  IClass,
  IClassRecord,
} from '../../store/data-types/classes';
import ConfirmModal from '../modals/confirm-modal';
import Term from '../terminology';

const { actions: { patchItem } } = itemsModule;

interface IExternalProps {
  canEdit?: boolean;
  class: IClassRecord;
}

interface IStateProps {
  isDisabled: boolean;
}

interface IDispatchProps {
  patchItem(url: string, id: string, data: Partial<IClass>): AxiosPromise;
  openModal(element: React.ReactNode): AxiosPromise;
  closeModal(): AxiosPromise;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class ClassHeader extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.confirmAndToggleClassStatus = this.confirmAndToggleClassStatus.bind(
      this
    );
    this.toggleClassStatus = this.toggleClassStatus.bind(this);
  }

  public render() {
    const {
      class: { name, description, id, is_archived },
      canEdit,
      isDisabled,
    } = this.props;
    return (
      <ContentBoxHeader>
        {canEdit && (
          <SpacedGroup className="float-right">
            <Link
              className="button primary margin-vertical-base"
              to={`/classes/${id}/edit/`}
            >
              Edit <Term>Class</Term>
            </Link>
            <Button
              className="primary margin-vertical-base"
              onClick={this.confirmAndToggleClassStatus}
              disabled={isDisabled}
            >
              {is_archived ? 'Restore ' : 'Archive '}
              <Term>Class</Term>
            </Button>
          </SpacedGroup>
        )}
        <h2 className="font-size-large">
          {name}
          {is_archived ? ' (ARCHIVED)' : ''}
        </h2>
        {description && <p className="meta">{description}</p>}
      </ContentBoxHeader>
    );
  }

  private confirmAndToggleClassStatus() {
    const { class: { is_archived, name }, closeModal } = this.props;
    const verb = is_archived ? 'Restore' : 'Archive';
    this.props.openModal(
      <ConfirmModal
        closeModal={closeModal}
        title={
          <span>
            {verb} <Term>Class</Term>
          </span>
        }
        body={
          <p>
            About to {verb.toLowerCase()} {name}. Are you sure?
          </p>
        }
        okayLabel={verb}
        onCancelClick={closeModal}
        onOkayClick={this.toggleClassStatus}
      />
    );
  }

  private toggleClassStatus() {
    const { class: { id, is_archived }, closeModal } = this.props;
    this.props
      .patchItem('classes', id, {
        status: is_archived ? CLASS_STATUSES.ACTIVE : CLASS_STATUSES.ARCHIVED,
      })
      .then(() => closeModal());
  }
}

function mapStateToProps(
  { responses }: IStore,
  props: IExternalProps
): IExternalProps & IStateProps {
  return {
    ...props,
    isDisabled: isPending(responses, UPDATE_ITEM, 'classes'),
  };
}

export default connect(mapStateToProps, {
  patchItem,
  openModal,
  closeModal,
})(ClassHeader);
