import { setPropsReducer } from '@dabapps/react-set-props';
import { responsesReducer } from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { FormReducer, FormState, reducer as formReducer } from 'redux-form';
import {
  CLEAR_UPLOAD_FILE,
  CLEAR_UPLOAD_FILES,
  CLOSE_FLYOUT,
  DISPLAY_LOADING_SPINNER,
  GET_ASSETS,
  HIDE_LOADING_SPINNER,
  OPEN_FLYOUT,
  SET_NEWS_FEED_COUNT,
  SET_UPLOAD_FILES,
  SET_UPLOAD_PROGRESS,
  UPLOAD_ASSET,
} from '../actions';
import {
  LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE,
  SUBMIT_PERFORMANCE,
} from '../actions/marksheet';
import * as profileActions from '../actions/profile';
import * as roleActions from '../actions/roles';
import { collections as modernCollections } from '../collections';
import { ICollection } from '../collections/types';
import { csvMappingReducer } from '../components/users/column-types';
import { ICollectionsStateRecord } from '../reducers/collections';
import { currentRoleAndCentreOptions } from '../reducers/roles';
import {
  AssetRecord,
  FeatureFlagsRecord,
  FileProgressRecord,
  IAssetRecord,
  IFeatureFlags,
  IFileProgressRecord,
  TResponseErrors,
} from '../store/data-types';
import { ServerConstants } from '../store/data-types/constants';
import {
  ChecklistRecord,
  IChecklistRecord,
  ILearningOutcomeBlockUpdate,
  LearningOutcomeBlockUpdate,
  PerformanceRecord,
} from '../store/data-types/marksheets';
import {
  IProfile,
  IProfileOsmsData,
  IUserPreferences,
  ProfileOsmsDataRecord,
  ProfileRecord,
  UserPreferencesRecord,
} from '../store/data-types/profile';
import { IAction } from '../types';
import { uiState } from '../ui-state';
import { collectionsModule } from './collections';
import { openReplies, replies, rootComment } from './comments';
import { dashboardQualifications } from './dashboard';
import { userInvites } from './emails';
import { itemsModule } from './items';
import { journalTaskSidebar } from './journals';
import { recordedResultStatuses } from './marksheet';
import { modals } from './modals';
import { roles } from './roles';
import { targetTaskStatus, taskDatesUpdated } from './tasks';
import { timeline } from './timeline';
import { studentPicker } from './users';

export interface IAssetsPageState {
  pageNumber: number;
  canLoadMore: boolean;
}
const DEFAULT_DRF_PAGE_SIZE = 50;
const assetsPageInitialState: IAssetsPageState = {
  pageNumber: 1,
  canLoadMore: false,
};
function assetsPage(state = assetsPageInitialState, action: IAction<any, any>) {
  switch (action.type) {
    case GET_ASSETS.SUCCESS:
      const numberOfPages = Math.ceil(
        action.payload.count / DEFAULT_DRF_PAGE_SIZE
      );
      const pageNumber = action.meta.shouldAppend ? state.pageNumber + 1 : 1;
      return {
        canLoadMore: numberOfPages > pageNumber,
        pageNumber,
      };
    default:
      return state;
  }
}

function assets(state = List<IAssetRecord>(), action: IAction<any, any>) {
  switch (action.type) {
    case GET_ASSETS.REQUEST:
      return state;
    case GET_ASSETS.SUCCESS:
      const newAssetItems = List(
        action.payload.results.map((item: IAssetRecord) => {
          return AssetRecord(item);
        })
      );
      return action.meta.shouldAppend
        ? state.concat(newAssetItems)
        : newAssetItems;
    case GET_ASSETS.FAILURE:
      return List();
    default:
      return state;
  }
}

function filesToUpload(state = List<File>(), action: IAction<any, any>) {
  switch (action.type) {
    case SET_UPLOAD_FILES:
      return action.payload;
    case CLEAR_UPLOAD_FILES:
      return List<File>();
    case CLEAR_UPLOAD_FILE:
      return state.filterNot(each => each.name === action.payload);
    default:
      return state;
  }
}

function assetUploadErrors(
  state: TResponseErrors = Map<string, string>(),
  action: IAction<any, any>
) {
  switch (action.type) {
    case UPLOAD_ASSET.REQUEST:
      return Map<string, string>();
    case UPLOAD_ASSET.FAILURE:
      return Map(action.payload);
    default:
      return state;
  }
}

export function profile(
  state = ProfileRecord({}),
  action: IAction<IProfile | IUserPreferences | IProfileOsmsData, any>
) {
  // NOTE: this may be the logged in user OR the currently viewed user - this is handled from the initial user data
  switch (action.type) {
    case profileActions.UPDATE_USER_PREFERENCES.SUCCESS:
      return {
        ...state,
        preferences: UserPreferencesRecord(action.payload),
      };
    case profileActions.UPDATE_PROFILE.SUCCESS:
    case profileActions.UPLOAD_PROFILE_PICTURE.SUCCESS:
      return {
        ...state,
        osms_data: ProfileOsmsDataRecord(action.payload || {}),
      };
    case roleActions.UPDATE_CURRENT_CENTRE_ROLE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const formPlugins = {
  'profile-change-password': (
    state: FormState | undefined,
    action: IAction<any, any>
  ) => {
    switch (action.type) {
      case profileActions.CHANGE_PASSWORD.SUCCESS:
        return undefined;
      default:
        return state;
    }
  },
};

export function fileUploadProgress(
  state: IFileProgressRecord | null = null,
  action: IAction<any, any>
) {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return FileProgressRecord(action.payload);
    case UPLOAD_ASSET.REQUEST:
      return null;
    default:
      return state;
  }
}

export function loadingSpinners(state = 0, action: IAction<any, any>) {
  switch (action.type) {
    case DISPLAY_LOADING_SPINNER:
      return state + 1;
    case HIDE_LOADING_SPINNER:
      return state - 1;
    default:
      return state;
  }
}

export function displayFlyout(state = false, action: IAction<any, any>) {
  switch (action.type) {
    case OPEN_FLYOUT:
      return true;
    case CLOSE_FLYOUT:
      return false;
    default:
      return state;
  }
}

export function newsFeedCount(state = 10, action: IAction<number, void>) {
  switch (action.type) {
    case SET_NEWS_FEED_COUNT:
      return action.payload;
    default:
      return state;
  }
}

export function featureFlags(
  state: IFeatureFlags = FeatureFlagsRecord({}),
  action: IAction<any, any>
) {
  return state;
}

export function serverConstants(
  state: ServerConstants = null as any, // Provided at initialization
  action: IAction<any, any>
) {
  return state;
}

export function collections(
  state: ICollectionsStateRecord | undefined,
  action: IAction<any, any>
): ICollectionsStateRecord {
  // TODO: We should be using the latest version of Collections, which has a safer way of doing this
  const collections: ICollectionsStateRecord = collectionsModule.reducers.collections(
    state,
    action
  ) as any;
  let tag: string;
  let collection: ICollection<IChecklistRecord>;

  switch (action.type) {
    case SUBMIT_PERFORMANCE.SUCCESS:
      const { itemId: checklistId } = action.meta;
      tag = action.meta.tag;
      collection = collections.getIn(['marksheets/checklists', tag]);
      const { results = List<IChecklistRecord>() } = collection;

      const index = results.findIndex(
        checklist => checklist.id === checklistId
      );

      if (index >= 0) {
        let checklist = results.get(index) || ChecklistRecord();
        checklist = checklist.set(
          'performance_records',
          (checklist.get('performance_records') || List()).push(
            PerformanceRecord(action.payload)
          )
        );

        const result = collections.setIn(['marksheets/checklists', tag], {
          ...collection,
          results: results.set(index, checklist),
        });

        return result;
      }

      return collections;
    case LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE.SUCCESS:
      tag = action.meta.tag;
      collection = collections.getIn(['marksheets/checklists', tag]);

      if (!collection) {
        return collections;
      }
      const items = action.payload.reduce(
        (
          memo: Dict<ILearningOutcomeBlockUpdate>,
          item: ILearningOutcomeBlockUpdate
        ) => {
          return {
            ...memo,
            [item.id]: LearningOutcomeBlockUpdate(item),
          };
        },
        {}
      );
      const updatedResults = collection.results.map(checklist => {
        return checklist.update('learning_outcomes', learningOutcomes => {
          return learningOutcomes.map(outcome => {
            return outcome.update(
              'learning_outcome_blocks',
              learningOutcomeBlocks => {
                return learningOutcomeBlocks.map(block => {
                  const updates = items[block.get('id')];
                  if (updates) {
                    return block.merge({
                      ...updates,
                    });
                  } else {
                    return block;
                  }
                });
              }
            );
          });
        });
      });
      const result = collections.setIn(['marksheets/checklists', tag], {
        ...collection,
        results: updatedResults,
      });
      return result;
    default:
      return collections;
  }
}

export default combineReducers({
  assetUploadErrors,
  assets,
  collections: modernCollections.reducers.collectionsReducer,
  collectionsOld: collections, // Deprecated, old-style Collections
  csvMapping: csvMappingReducer,
  displayFlyout,
  fileUploadProgress,
  form: ((formReducer as any) as FormReducer).plugin(formPlugins), // FIXME: Typings are slightly off
  items: modernCollections.reducers.collectionsReducer,
  itemsOld: itemsModule.reducers.items, // Deprecated, old-style Collections
  loadingSpinners,
  modals,
  newsFeedCount,
  openReplies,
  profile,
  currentRoleAndCentreOptions,
  replies,
  responses: responsesReducer,
  roles,
  rootComment,
  routing: routerReducer,
  timeline,
  uiState,
  filesToUpload,
  userInvites,
  recordedResultStatuses,
  setPropsReducer,
  targetTaskStatus,
  taskDatesUpdated,
  featureFlags,
  serverConstants,
  journalTaskSidebar,
  studentPicker,
  dashboardQualifications,
  assetsPage,
});
