import { Map } from 'immutable';
import { IAction } from './types';

export type UIState = Map<string, any>;

import { CLEAR_UI_STATE, SET_UI_STATE } from './actions'; // TODO: Move these into here

export function uiState(
  state: UIState = Map<string, any>(),
  action: IAction<any, any>
) {
  switch (action.type) {
    case SET_UI_STATE:
      return state.set(action.payload.key, action.payload.value);
    case CLEAR_UI_STATE:
      return state.delete(action.payload.key);
    default:
      return state;
  }
}

export function makeUIStateHandler<State>(name: string, defaultValue: State) {
  return {
    setState(value: State) {
      return {
        payload: {
          key: name,
          value,
        },
        type: SET_UI_STATE,
      };
    },
    clearState() {
      return {
        payload: {
          key: name,
        },
        type: CLEAR_UI_STATE,
      };
    },
    getValue(store: UIState): State {
      return store.get(name, defaultValue);
    },
  };
}
