import * as moment from 'moment';
import * as React from 'react';
import { FormProps, reduxForm } from 'redux-form';
import DateInput from '../../date-input';
import GroupField from '../../forms/group-field';

export const FORM_NAME = 'setPerformanceDate';

export interface IFormData {
  created?: moment.Moment | null | string;
}

type IFormProps = React.HTMLProps<JSX.Element> & FormProps<IFormData, {}, {}>;

function SetPerformanceDateForm({ initialValues }: IFormProps) {
  // const { created }: Partial<IFormData> = initialValues || {};
  const dformat = 'DD/MM/YYYY HH:mm';
  return (
    <form>
      <GroupField
        name="created"
        type="date"
        component={fieldProps => {
          const onChangeRaw = fieldProps.input.onChange;
          fieldProps.input.onChange = (value: any) => {
            onChangeRaw(moment(value, dformat));
          };
          return (
            <DateInput
              {...fieldProps}
              className="input"
              maxDate={moment.utc()}
              minDate={moment.utc().subtract(6, 'months')}
              showTimeSelect={true}
              showYearDropdown={false}
              dateFormat={dformat}
              readonly={true}
              closeOnSelect={true}
            />
          );
        }}
        label="Performance Date"
        metalabel="Defaults to today"
      />
    </form>
  );
}

export default reduxForm<IFormData, IFormProps>({
  form: FORM_NAME,
})(SetPerformanceDateForm);
