import { Button, Column, ContentBoxFooter, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import * as _ from 'underscore';
import { TBulkUploadResponseErrors } from '../../store/data-types/bulk-upload';
import BulkImportErrors from './bulk-import-errors';

const { IconExclamationTriangle } = FontAwesome;

interface IExternalProps {
  processing_errors: TBulkUploadResponseErrors;
  onBack(): void;
}

export default class Review extends React.PureComponent<IExternalProps, void> {
  public constructor(props: IExternalProps) {
    super(props);
    this.onBack = this.onBack.bind(this);
  }

  public render() {
    const { processing_errors } = this.props;

    return (
      <div className="review">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add students
              </li>
              <li className="active">
                <span className="number">2</span> Column types
              </li>
              <li className="active">
                <span className="number">3</span> Review
              </li>
              <li>
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <h3>Review uploaded file</h3>

            <p>
              {_.values(processing_errors).length} rows need your attention
              <IconExclamationTriangle className="icon-small icon-error ml-1" />
            </p>

            <p>
              Please fix these errors in a copy of your original CSV then return
              to the upload stage.
            </p>

            <BulkImportErrors processingErrors={processing_errors} />
          </Column>
        </Row>

        <ContentBoxFooter className="margin-vertical-base">
          <Button
            onClick={this.onBack}
            className="primary margin-vertical-base float-left"
          >
            Return to upload stage
          </Button>
        </ContentBoxFooter>
      </div>
    );
  }

  private onBack(): void {
    this.props.onBack();
  }
}
