import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { GET_COLLECTION } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { CLASS_STATUS_CHOICES } from '../../constants/classes';
import { canCreateClass } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { CLASS_STATUSES, IClassRecord } from '../../store/data-types/classes';
import { IProfile } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import CollectionSelectFilter from '../tables/collection-select-filter';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import Term from '../terminology';

import { terminologyFromProfile } from '../terminology';

const { actions: { getCollection, getAllCollection } } = collectionsModule;

interface IClassPageProps {
  classesCollection: ICollection<IClassRecord>;
  qualifications: List<IQualificationRecord>;
  loading: boolean;
  profile: IProfile;
  getCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
}

const PAGE_SIZE = 10;
const CLASS_LIST = 'CLASS_LIST';

const getHeaders = (context: ClassesPage): Array<IColumnData<IClassRecord>> => [
  {
    content: data => <Link to={`/classes/${data.id}/`}>{data.name}</Link>,
    headerLabel: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    content: data => data.description,
    headerLabel: 'Description',
    key: 'description',
    sortable: true,
  },
  {
    content(data: IClassRecord) {
      const matchingQualification = context.props.qualifications.find(
        qual => qual.id === data.qualification
      );

      if (!matchingQualification) {
        return null;
      }

      return matchingQualification.name;
    },
    headerLabel: 'Qualification',
    key: 'qualification',
    sortable: true,
    width: undefined,
  },
  {
    content: data => data.students.length,
    headerLabel: terminologyFromProfile(context.props.profile, 'Students'),
    key: 'student_count',
    sortable: true,
    width: undefined,
  },
  {
    content: data => data.teachers.length,
    headerLabel: terminologyFromProfile(context.props.profile, 'Teachers'),
    key: 'teacher_count',
    sortable: true,
  },
  {
    content: data => data.internal_quality_assurers.length,
    headerLabel: `${terminologyFromProfile(context.props.profile, 'IQA')}s`,
    key: 'internal_quality_assurer_count',
    sortable: true,
  },
  {
    content: data => <Link to={`/classes/${data.id}/`}>View</Link>,
    headerLabel: 'View',
    key: 'view',
    sortable: false,
    width: undefined,
  },
];

const DEFAULT_FILTERS = Map({
  status: CLASS_STATUSES.ACTIVE,
});

export class ClassesPage extends React.PureComponent<IClassPageProps, void> {
  public constructor(props: IClassPageProps) {
    super(props);
    this.getClasses = this.getClasses.bind(this);
  }

  public componentWillMount() {
    this.props.getAllCollection('courses', {});
  }

  public render() {
    return (
      <ContentBox>
        <ContentBoxHeader>
          <SpacedGroup className="float-right margin-vertical-base">
            <a
              href="/api/classes/export/"
              target="_blank"
              className="button primary"
            >
              Export CSV
            </a>
            {canCreateClass(this.props.profile) && (
              <Link to="/classes/create/" className="button primary">
                Create New <Term>Class</Term>
              </Link>
            )}
          </SpacedGroup>
          <h2 className="font-size-large">
            <Term>Classes</Term>
          </h2>
        </ContentBoxHeader>
        <Row>
          <Column sm={4}>
            <CollectionSelectFilter
              label="Filter by Status:"
              name="status"
              filterKey="status"
              options={CLASS_STATUS_CHOICES}
              collection={this.props.classesCollection}
              pageSize={PAGE_SIZE}
              getCollection={this.getClasses}
              disabled={this.props.loading}
              excludeNull
            />
          </Column>
        </Row>
        <CollectionTable
          headers={getHeaders(this)}
          collection={this.props.classesCollection}
          loading={this.props.loading}
          pageSize={PAGE_SIZE}
          defaultFilters={DEFAULT_FILTERS}
          getCollection={this.getClasses}
        />
      </ContentBox>
    );
  }

  private getClasses(options: ICollectionOptions) {
    this.props.getCollection('classes', options, CLASS_LIST);
  }
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  const classesCollection = getCollectionByName(
    collectionsOld.classes,
    CLASS_LIST
  );

  return {
    classesCollection,
    qualifications:
      getCollectionItems(collectionsOld.courses) ||
      List<IQualificationRecord>(),
    loading: isPending(responses, GET_COLLECTION, 'classes'),
    profile,
  };
}

export default connect(mapStateToProps, { getCollection, getAllCollection })(
  ClassesPage
);
