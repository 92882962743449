import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { change, DataShape, FormProps, WrappedFieldProps } from 'redux-form';
import { TActionAny, Thunk } from '../../requests/types';

import { IGetAssetsOptions } from '../../actions';
import store from '../../store';
import { IAssetRecord } from '../../store/data-types';
import { IClassRecord } from '../../store/data-types/classes';
import { IJournalEntry } from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { getAssetName } from '../../utils';
import EllipsizedFileName from '../ellipsized-file-name';
import AddFilesModal from '../files/add-files-modal';
import AddNewOrExistingFiles from '../files/add-new-or-existing-files';
import FileIcon from '../files/file-icon';
import MaybeAssetAnchor from '../maybe-asset-anchor';
import SelectJournalFilesModal from './select-journal-files-modal';

const { IconFileTextO } = FontAwesome;

interface ISelectedFilesProps {
  assets: List<IAssetRecord>;
  profile: IProfile;
  selectedAssets: ReadonlyArray<string>;
  editing?: boolean;
}

interface IOpenModalProps {
  openModal(modal: React.ReactNode): void;
}

function EntrySideBarSection({ children }: React.HTMLProps<JSX.Element>) {
  return (
    <Row>
      <Column>{children}</Column>
    </Row>
  );
}

interface IMaybeFileLinkProps {
  asset?: IAssetRecord;
  profile: IProfile;
}

function SelectedFiles(
  props: ISelectedFilesProps & Partial<WrappedFieldProps<void>>
) {
  const { assets, input, editing, profile, selectedAssets } = props;

  return selectedAssets && selectedAssets.length ? (
    <ul className="journal-file-list fixed">
      {selectedAssets.map((assetId: string, index: number) => {
        const asset = props.assets.find(each => each.get('id') === assetId);
        return (
          <li key={assetId}>
            <Row>
              <Column xs={12} sm={7} lg={9} className="nowrap file-info">
                <MaybeAssetAnchor className="file-name" asset={asset}>
                  <span className="file-icon">
                    <FileIcon fileName={asset && getAssetName(asset)} />
                  </span>
                  {asset ? (
                    <EllipsizedFileName>
                      {getAssetName(asset)}
                    </EllipsizedFileName>
                  ) : (
                    <span>Loading...</span>
                  )}
                </MaybeAssetAnchor>
              </Column>
              {editing &&
                input && (
                  <Column xs={12} sm={5} lg={3}>
                    <a
                      onClick={() =>
                        input.onChange(
                          selectedAssets.filter((_, idx) => index === idx)
                        )}
                    >
                      {' '}
                      remove
                    </a>
                  </Column>
                )}
            </Row>
          </li>
        );
      })}
    </ul>
  ) : editing ? (
    <p>No files added to this post - add some!</p>
  ) : (
    <p>No files added to this post.</p>
  );
}

interface IFilesSidebarProps {
  assets: List<IAssetRecord>;
  profile: IProfile;
  journalEntry?: IJournalEntry;
  modeOptions: TModeOptions;
  getAssets(): void;
}
interface ICreatingProps {
  mode: 'CREATING';
}
interface IEditingProps {
  mode: 'EDITING';
  selectedAssets: ReadonlyArray<string>;
  onChange(assets: List<string>): void;
}
interface IReadOnlyProps {
  mode: 'READONLY';
  selectedAssets: ReadonlyArray<string>;
}
type TModeOptions = IReadOnlyProps | IEditingProps | ICreatingProps;

export default function FilesSidebar(
  props: IFilesSidebarProps &
    Partial<IOpenModalProps> &
    Partial<WrappedFieldProps<void>>
) {
  const { modeOptions, input, meta, journalEntry, profile } = props;
  const showEditables =
    (modeOptions.mode === 'CREATING' && input && input.onChange) ||
    modeOptions.mode === 'EDITING';
  const selectedAssets =
    (modeOptions.mode === 'EDITING' || modeOptions.mode === 'READONLY'
      ? List<string>(modeOptions.selectedAssets)
      : input && (input.value as List<string>)) || List<string>();
  return (
    <EntrySideBarSection>
      <h6 className="font-size-base font-weight-bold">Related Files</h6>
      <SelectedFiles {...props} selectedAssets={selectedAssets.toArray()} />
      {showEditables && (
        <AddNewOrExistingFiles
          onNewClick={() => {
            if (props.openModal) {
              props.openModal(
                <AddFilesModal
                  ownerUserId={props.profile.id}
                  info="Upload new files here - this will add them to your files which you will be able to attach to journal entries."
                  afterSuccessfulUpload={(assetIds: List<string>) => {
                    props.getAssets();

                    if (modeOptions.mode === 'EDITING') {
                      return modeOptions.onChange(selectedAssets.concat(
                        assetIds
                      ) as List<string>);
                    } else if (input) {
                      input.onChange(selectedAssets.concat(assetIds));
                    }
                  }}
                />
              );
            }
          }}
          onExistingClick={() => {
            if (props.openModal && (input || modeOptions.mode === 'EDITING')) {
              props.openModal(
                <SelectJournalFilesModal
                  onChangeAssets={(newAssets: List<string>) =>
                    (modeOptions.mode === 'EDITING' &&
                      modeOptions.onChange(newAssets)) ||
                    (input && input.onChange(newAssets as any))}
                />
              );
            }
          }}
        />
      )}
    </EntrySideBarSection>
  );
}
