import { Button } from '@dabapps/roe';
import { Set } from 'immutable';
import * as React from 'react';
import {
  DataShape,
  Field,
  FormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { ICollectionOptions } from '../../collections/types';

import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import IfCan from '../permissions/if-can';
import UserTable from './user-table';

interface IExternalProps {
  loading: boolean;
  actionText: string;
  profile: IProfile;
  loadUsers(options?: ICollectionOptions): void;
}

type IProps = React.HTMLProps<JSX.Element> &
  FormProps<DataShape, void, void> &
  IExternalProps;

export class UserTableForm extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.renderUsersField = this.renderUsersField.bind(this);
  }

  public render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div>
          <Field name="selectedStudents" component={this.renderUsersField} />
          <IfCan
            can={userHasRoles(
              this.props.profile,
              Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
            )}
          >
            <Button
              className="error margin-bottom-large"
              type="submit"
              disabled={this.props.loading}
            >
              {this.props.actionText} Selected
            </Button>
          </IfCan>
        </div>
      </form>
    );
  }

  private renderUsersField(fieldProps: WrappedFieldProps<IStore>) {
    return (
      <UserTable
        value={fieldProps.input.value}
        onChange={fieldProps.input.onChange}
        loadUsers={this.props.loadUsers}
        loading={this.props.loading}
      />
    );
  }
}

export default reduxForm({
  form: 'toggleStudentsArchived',
})(UserTableForm);
