import { Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { userHasRoles } from '../../permissions';
import { IStore } from '../../store/';
import { TRole } from '../../store/data-types/profile';
import { IProfile } from '../../store/data-types/profile';

import If from './if';

interface IExternalProps {
  roles: Set<TRole>;
}

interface IProps extends IExternalProps {
  profile: IProfile;
}

export class IfHasRoles extends React.PureComponent<IProps, void> {
  public render() {
    const { roles, profile, children } = this.props;

    const condition = userHasRoles(profile, roles);

    return <If condition={condition}>{children}</If>;
  }
}

function mapStateToProps({ profile }: IStore, props: IExternalProps) {
  return {
    profile,
  };
}

export default connect(mapStateToProps)(IfHasRoles);
