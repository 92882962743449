import { RecordWithConstructor, SimpleRecord } from '@dabapps/simple-records';
import { List, Record } from 'immutable';
// tslint:disable-next-line:no-unused-variable
import { Moment } from 'moment';
import * as moment from 'moment';
// tslint:disable-next-line:no-unused-variable
import { AssetRecord, IAssetRecord } from '.';
import { TEACHER } from '../../permissions';
import {
  BlockCriterionRecord,
  BlockRecord,
  GroupRecord,
  IBlockCriterionRecord,
  IBlockRecord,
  IGroupRecord,
  IStrandRecord,
  StrandRecord,
} from './courses';
import { TRole } from './profile';
import { RecordInstance, recordList } from './records';

export interface IChecklistCriterion {
  block_criterion: IBlockCriterionRecord;
  id: string;
}
export type IChecklistCriterionRecord = RecordInstance<IChecklistCriterion>;
export const ChecklistCriterionInner = Record<IChecklistCriterion>({
  block_criterion: BlockCriterionRecord({}),
  id: '',
});
export function ChecklistCriterionRecord(data: Partial<IChecklistCriterion>) {
  return ChecklistCriterionInner(data).set(
    'block_criterion',
    BlockCriterionRecord(data.block_criterion || {})
  );
}

export type INestedLearningOutcomeBlockAsset = Readonly<{
  asset_download_url: string;
  asset_filename: string;
  asset_user_supplied_name: string;
  id: string;
  asset: string;
  tagger_role: TRole;
}>;

export const NestedLearningOutcomeBlockAsset = SimpleRecord<
  INestedLearningOutcomeBlockAsset
>({
  asset_download_url: '',
  asset_filename: '',
  asset_user_supplied_name: '',
  id: '',
  asset: '',
  tagger_role: 'STUDENT',
});

export interface ILearningOutcomeBlock {
  block: IBlockRecord;
  checked_by_student: boolean;
  checked_by_student_automatically: boolean;
  checked_by_student_manually: boolean;
  checked_by_teacher: boolean;
  checked_by_teacher_automatically: boolean;
  checked_by_teacher_manually: boolean;
  checked_by_iqa: boolean;
  checked_by_iqa_manually: boolean;
  checked_by_iqa_automatically: boolean;
  checked_by_eqa: boolean;
  is_result_from_external_system: boolean;
  recorded_result: string;
  exam_result_report?: string;
  external_result_description?: string;
  checklist_criteria: List<IChecklistCriterionRecord>;
  id: string;
  learning_outcome_block_assets: ReadonlyArray<
    INestedLearningOutcomeBlockAsset
  >;
  strand: IStrandRecord;
  group: IGroupRecord;
  number_of_comments_student: number;
  criteria_met_orally_student: boolean;
  number_of_comments_teacher: number;
  criteria_met_orally_teacher: boolean;
  number_of_comments_iqa: number;
  criteria_met_orally_iqa: boolean;
  number_of_comments_eqa: number;
  criteria_met_orally_eqa: boolean;
}
export type ILearningOutcomeBlockRecord = RecordInstance<ILearningOutcomeBlock>;
const LearningOutcomeBlockInner = Record<ILearningOutcomeBlock>({
  block: BlockRecord(),
  checked_by_student: false,
  checked_by_student_automatically: false,
  checked_by_student_manually: false,
  checked_by_teacher: false,
  checked_by_teacher_manually: false,
  checked_by_teacher_automatically: false,
  checked_by_iqa: false,
  checked_by_iqa_manually: false,
  checked_by_iqa_automatically: false,
  checked_by_eqa: false,
  is_result_from_external_system: false,
  recorded_result: '',
  exam_result_report: '',
  external_result_description: '',
  checklist_criteria: List(),
  id: '',
  learning_outcome_block_assets: [],
  strand: StrandRecord(),
  group: GroupRecord(),
  number_of_comments_student: 0,
  criteria_met_orally_student: false,
  number_of_comments_teacher: 0,
  criteria_met_orally_teacher: false,
  number_of_comments_iqa: 0,
  criteria_met_orally_iqa: false,
  number_of_comments_eqa: 0,
  criteria_met_orally_eqa: false,
});
export function LearningOutcomeBlockRecord(
  data?: Partial<ILearningOutcomeBlock>
) {
  return LearningOutcomeBlockInner(data)
    .set('block', BlockRecord((data && data.block) || {}))
    .set(
      'checklist_criteria',
      recordList(data && data.checklist_criteria, ChecklistCriterionRecord)
    )
    .set(
      'learning_outcome_block_assets',
      data && data.learning_outcome_block_assets
        ? data.learning_outcome_block_assets.map(
            NestedLearningOutcomeBlockAsset
          )
        : []
    );
}

// tslint:disable-next-line:no-unused-variable
type ILearningOutcomeBlockCommentRecordInput = Readonly<{
  comment: string;
  commenter: string;
  commenter_role: TRole;
  created: string | Date;
  id: string;
  criteria_met_orally: boolean;
}>;
export type ILearningOutcomeBlockCommentRecord = Readonly<{
  comment: string;
  commenter: string;
  commenter_role: TRole;
  created: moment.Moment;
  id: string;
  criteria_met_orally: boolean;
}>;
export const LearningOutcomeBlockCommentRecord = RecordWithConstructor<
  ILearningOutcomeBlockCommentRecordInput,
  ILearningOutcomeBlockCommentRecord
>(
  {
    comment: '',
    commenter: '',
    commenter_role: TEACHER,
    created: new Date(),
    id: '',
    criteria_met_orally: false,
  },
  input => {
    return {
      ...input,
      created: moment.utc(input.created),
    };
  }
);
export type ILearningOutcomeBlockNewComment = Readonly<{
  comment: string;
  commenter: string;
  commenter_role: TRole;
  learning_outcome_block: string;
  criteria_met_orally: boolean | undefined;
}>;

export type ILearningOutcomeBlockUpdate = Readonly<{
  id: string;
  checked_by_student_manually?: boolean;
  checked_by_teacher_manually?: boolean;
  checked_by_student?: boolean;
  checked_by_teacher?: boolean;
  checked_by_iqa?: boolean;
  checked_by_iqa_manually?: boolean;
  checked_by_eqa?: boolean;
}>;
export const LearningOutcomeBlockUpdate = SimpleRecord<
  ILearningOutcomeBlockUpdate
>({ id: '' });

export interface ILearningOutcome {
  group: IGroupRecord;
  id: string;
  learning_outcome_blocks: List<ILearningOutcomeBlockRecord>;
}
export type ILearningOutcomeRecord = RecordInstance<ILearningOutcome>;
const LearningOutcomeInner = Record<ILearningOutcome>({
  group: GroupRecord({}),
  id: '',
  learning_outcome_blocks: List(),
});
export function LearningOutcomeRecord(data: Partial<ILearningOutcome>) {
  return LearningOutcomeInner(data)
    .set('group', GroupRecord(data.group || {}))
    .set(
      'learning_outcome_blocks',
      recordList(data.learning_outcome_blocks, LearningOutcomeBlockRecord)
    );
}

export interface IPerformance {
  created: Moment;
  created_by: {
    id: string;
    name: string;
    osms_id: string;
  };
}

export type IPerformanceRecord = RecordInstance<IPerformance>;
export const PerformanceRecordInner = Record<IPerformance>({
  created: moment.utc(),
  created_by: {
    id: '',
    name: '',
    osms_id: '',
  },
});

export function PerformanceRecord(input: Partial<IPerformance>) {
  return PerformanceRecordInner(input).set(
    'created',
    moment.utc(input.created)
  );
}

export interface IChecklist {
  id: string;
  learning_outcomes: List<ILearningOutcomeRecord>;
  strand: IStrandRecord;
  performance_records: List<IPerformanceRecord>;
}
export type IChecklistRecord = RecordInstance<IChecklist>;
const ChecklistInner = Record<IChecklist>({
  id: '',
  learning_outcomes: List<ILearningOutcomeRecord>(),
  strand: StrandRecord({}),
  performance_records: List<IPerformanceRecord>(),
});
export function ChecklistRecord(data?: Partial<IChecklist>) {
  const performanceRecords = List<IPerformance>(
    (data && data.performance_records) || []
  ).map(performance => PerformanceRecord(performance));

  return ChecklistInner(data)
    .set('strand', StrandRecord((data && data.strand) || {}))
    .set(
      'learning_outcomes',
      recordList(data && data.learning_outcomes, LearningOutcomeRecord)
    )
    .set('performance_records', List<IPerformanceRecord>(performanceRecords));
}
