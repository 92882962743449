import { SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { Link } from 'react-router';

import NavItem from '../nav/item';
import SubNav from '../nav/sub';

interface IProps {
  pathname: string;
}

const MATCHES_ROOT = /^\/$/;
const MATCHES_USERS = /^\/newsfeed\/$/;
const MATCHES_REPORTS = /^\/my-activity\/$/;

function DashboardMenu(props: IProps) {
  const { pathname } = props;
  return (
    <SubNav className="highlight-active font-size-base">
      <NavItem href="/" matches={MATCHES_ROOT} pathname={pathname}>
        Dashboard
      </NavItem>
      <NavItem href="/newsfeed/" matches={MATCHES_USERS} pathname={pathname}>
        Newsfeed
      </NavItem>
      <NavItem
        href="/my-activity/"
        matches={MATCHES_REPORTS}
        pathname={pathname}
      >
        My Activity
      </NavItem>
    </SubNav>
  );
}

export default DashboardMenu;
