import * as React from 'react';
import { ComponentClass, StatelessComponent } from 'react';

import { FormGroup } from '@dabapps/roe';
import {
  ComponentConstructor,
  EventOrValueHandler,
  Field,
  WrappedFieldProps,
} from 'redux-form';

import { IStore } from '../../store';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from './form-errors-renderer';

interface IProps<S> {
  formErrors?: FormErrors;
  label: string;
  metalabel?: string;
  type: string;
  name: string;
  disabled?: boolean;
  component?:
    | StatelessComponent<WrappedFieldProps<S>>
    | ComponentClass<S>
    | 'input'
    | 'select'
    | 'textarea';
  onChange?: EventOrValueHandler<React.ChangeEvent<any>>;
}

export default class GroupField extends React.PureComponent<
  IProps<IStore>,
  void
> {
  public render() {
    const {
      component,
      disabled,
      formErrors,
      metalabel,
      label,
      name,
      onChange,
      type,
    } = this.props;
    const errorClass = formErrors && formErrors[name] ? 'error' : '';

    return (
      <FormGroup block>
        <label>
          {label} {metalabel && <span className="meta-label">{metalabel}</span>}
        </label>
        <Field
          placeholder={label}
          name={name}
          component={component || 'input'}
          type={type}
          disabled={disabled}
          className={errorClass}
          onChange={onChange}
        >
          {this.props.children}
        </Field>
        <FormErrorsRenderer formErrors={formErrors} errorKey={name} />
      </FormGroup>
    );
  }
}
