import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  ContentBox,
  ContentBoxHeader,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { AxiosPromise, AxiosResponse } from 'axios';
import * as classNames from 'classnames';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { EXEMPT_USER_QUOTA, exemptUser } from '../../actions/quotas';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { canExemptOneTimeFileSizeRestriction } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { IQuotaRecord } from '../../store/data-types';
import { IProfile } from '../../store/data-types/profile';
import { IAction } from '../../types';
import { formatBytes } from '../../utils';
import Loading from '../loading';
import Term from '../terminology';

const { actions: { getAllCollection } } = collectionsModule;
const QUOTAS: keyof ICollectionsState = 'assets/quotas';
const QUOTAS_FEED = 'QUOTAS_FEED';

interface IProps {
  quotasEntries: List<IQuotaRecord>;
  loggedInUser: IProfile;
  loading: boolean;
  userId: string;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  exemptUser(userId: string): AxiosPromise;
}

export class QuotaList extends React.PureComponent<IProps, void> {
  public componentWillMount(): void {
    this.props.getAllCollection('assets/quotas', {}, QUOTAS_FEED);
  }

  public exemptUser(userId: string): void {
    this.props
      .exemptUser(userId)
      .then((response: AxiosResponse) => {
        this.props.getAllCollection('assets/quotas', {}, QUOTAS_FEED);
      })
      .catch((error: any) => console.error(error)); // tslint:disable-line:no-console
  }

  public render() {
    const { quotasEntries, loading, loggedInUser } = this.props;

    return loading ? (
      <Loading />
    ) : (
      <div>
        <ContentBox>
          <ContentBoxHeader>
            <h2 className="font-size-large">
              Manage <Term>Student</Term> Quotas
            </h2>
          </ContentBoxHeader>
          <Row>
            <Table className="dont-error-me" fill>
              <TableHead>
                <TableRow>
                  <TableHeader>Name</TableHeader>
                  <TableHeader>Username</TableHeader>
                  <TableHeader>Quota usage</TableHeader>
                  {canExemptOneTimeFileSizeRestriction(loggedInUser) && (
                    <TableHeader>
                      Exempt next large file from 100MB limit?
                    </TableHeader>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {quotasEntries.count() ? (
                  quotasEntries.map(entry => (
                    <TableRow key={entry.id}>
                      <TableCell>{entry.name || '--'}</TableCell>
                      <TableCell>{entry.username || '--'}</TableCell>
                      <TableCell>
                        {formatBytes(entry.usage)} of {formatBytes(entry.quota)}
                      </TableCell>
                      {canExemptOneTimeFileSizeRestriction(loggedInUser) && (
                        <TableCell>
                          {entry.exemption ? 'Exempt' : 'Not Exempt'}
                          <Button
                            onClick={() => this.exemptUser(entry.id)}
                            className={classNames(
                              entry.exemption ? 'primary' : 'error',
                              'float-right'
                            )}
                          >
                            {entry.exemption ? 'Revoke' : 'Grant'}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <span className="info">No quotas to manage</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Row>
        </ContentBox>
      </div>
    );
  }
}

function mapStateToProps({
  collections,
  collectionsOld,
  profile,
  responses,
}: IStore) {
  const quotasEntries =
    getCollectionItems(collectionsOld.get('assets/quotas'), QUOTAS_FEED) ||
    List<IQuotaRecord>();
  return {
    loading: isPending(responses, GET_COLLECTION, QUOTAS),
    quotasEntries,
    userId: profile.id,
    loggedInUser: profile,
  };
}

export default connect(mapStateToProps, { getAllCollection, exemptUser })(
  QuotaList
);
