import {
  Dict,
  RecordWithConstructor,
  SimpleRecord,
} from '@dabapps/simple-records';
import * as moment from 'moment';

export type ICentreOsmsData = Readonly<{
  name: string;
  picture: string;
  centre_number: string;
  terminology?: Dict<string>;
}>;

export const CentreOsmsDataRecord = SimpleRecord<ICentreOsmsData>({
  name: '',
  picture: '',
  centre_number: '',
  terminology: undefined,
});

export type ICentreInput = Readonly<{
  id: string;
  created: moment.Moment;
  modified: moment.Moment;
  osms_data: Partial<ICentreOsmsData>;
  created_by: string;
  assigned_external_quality_assurer_count?: number;
  has_outstanding_reviews: boolean | undefined;
  members_can_create_learners?: boolean;
}>;

export interface ICentre extends ICentreInput {
  readonly osms_data: ICentreOsmsData;
}

export const CentreRecord = RecordWithConstructor<ICentreInput, ICentre>(
  {
    created: moment.utc(),
    created_by: '',
    id: '',
    assigned_external_quality_assurer_count: undefined,
    modified: moment.utc(),
    osms_data: CentreOsmsDataRecord({}),
    has_outstanding_reviews: undefined,
    members_can_create_learners: undefined,
  },
  input => {
    return {
      ...input,
      osms_data: CentreOsmsDataRecord(input.osms_data),
    };
  }
);
