import {
  Collection,
  CollectionOptions,
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections/dist/collections';
import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { LOAD_ITEM } from '../../../items/actions';

import { openModal } from '../../../actions/modals';
import { collections } from '../../../collections';
import { itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import { ICentreExamSpecRecord } from '../../../store/data-types/courses';
import Loading from '../../loading';
import CollectionInputFilter from '../../tables/collection-input-filter/modern';
import CollectionTable from '../../tables/collection-table/modern';
import ManageCentreEQAsModal from './manage-centre-eqas-modal';

const { actions: { getCollection } } = collections;
const { actions: { loadItem } } = itemsModule;

export const EXAM_SPECS_ROUTE = 'courses/centre-exam-specs';
export const CENTRES = 'centres';
export const PAGE_SIZE = 10;

interface IRouteParams {
  id: string;
}

interface IExternalProps {
  routeParams: IRouteParams;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
  loadItem: typeof loadItem;
  openModal: typeof openModal;
}

interface IStateProps {
  examSpecsCollection: Collection<ICentreExamSpecRecord>;
  centreName: string;
  hasSucceededCentre: boolean;
  isLoadingExamSpecs: boolean;
}

type IProps = IExternalProps & IDispatchProps & IStateProps;

export class ManageCentreEQAsTable extends React.PureComponent<IProps, void> {
  public componentDidMount() {
    this.props.loadItem(CENTRES, this.props.routeParams.id);
  }

  public render() {
    if (!this.props.hasSucceededCentre) {
      return <Loading />;
    }
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            Manage External Quality Assurers for {this.props.centreName}
          </h2>
        </ContentBoxHeader>
        <Section>
          <Row>
            <Column sm={3}>
              <CollectionInputFilter
                formName="ManageCentreEQAsTable"
                label="Search"
                filterKey="search"
                collection={this.props.examSpecsCollection}
                pageSize={PAGE_SIZE}
                getCollection={this.loadExamSpecs}
              />
            </Column>
          </Row>
        </Section>
        <CollectionTable
          headers={this.getHeaders()}
          loading={this.props.isLoadingExamSpecs}
          collection={this.props.examSpecsCollection}
          pageSize={PAGE_SIZE}
          getCollection={this.loadExamSpecs}
        />
      </ContentBox>
    );
  }

  private getHeaders = () => {
    return [
      {
        content: (data: ICentreExamSpecRecord) => data.name,
        headerLabel: 'Name',
        key: 'name',
        sortable: true,
      },
      {
        content: (data: ICentreExamSpecRecord) =>
          data.assigned_external_quality_assurer_count,
        headerLabel: 'No. of assigned EQAs',
        key: 'assigned_external_quality_assurer_count',
        sortable: true,
      },
      {
        content: (data: ICentreExamSpecRecord) => (
          <a
            className="warning"
            onClick={() => this.openEQAsModal(data.id, data.name)}
          >
            Manage External Quality Assurers
          </a>
        ),
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  };

  private loadExamSpecs = (options: CollectionOptions) => {
    this.props.getCollection(
      EXAM_SPECS_ROUTE,
      {
        ...options,
        filters: {
          ...options.filters,
          centre_id: this.props.routeParams.id,
        },
      },
      ''
    );
  };

  private openEQAsModal = (examSpecId: string, examSpecName: string) => {
    this.props.openModal(
      <ManageCentreEQAsModal
        centreId={this.props.routeParams.id}
        centreName={this.props.centreName}
        examSpecId={examSpecId}
        examSpecName={examSpecName}
        loadExamSpecs={this.loadExamSpecs}
      />
    );
  };
}

function mapStateToProps({
  collections,
  itemsOld,
  responses,
}: IStore): IStateProps {
  const centre = itemsOld.centres;
  // FIXME: work out why new collections has us casting collections/results explictly like this
  const examSpecsCollection = getCollectionByName(
    collections,
    EXAM_SPECS_ROUTE
  ) as Collection<ICentreExamSpecRecord>;
  return {
    examSpecsCollection,
    centreName: centre ? centre.osms_data.name : '',
    hasSucceededCentre: hasSucceeded(responses, LOAD_ITEM, CENTRES),
    isLoadingExamSpecs: isPending(responses, GET_COLLECTION, EXAM_SPECS_ROUTE),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  loadItem,
  openModal,
})(ManageCentreEQAsTable);
