import { Column, Row } from '@dabapps/roe';
import * as React from 'react';

import { List, Record } from 'immutable';
import { RecordInstance } from '../store/data-types/records';
import ProgressBar from './progress-bar';

interface IMarkingGroup {
  progress: number;
  count: number;
  max: number;
  title?: string;
}

export type IMarkingGroupRecord = RecordInstance<IMarkingGroup>;

export const MarkingGroup = Record<IMarkingGroup>({
  progress: 0,
  count: 0,
  max: 0,
  title: undefined,
});

interface IProps {
  title: string;
  groups: List<RecordInstance<IMarkingGroup>>;
}

export default class ProgressBarGroup extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { title, groups } = this.props;
    const [count, max] = this.totalTagging();
    const groupTitles = this.groupTitles();
    const [xs, sm, md] = groups.count() > 1 ? [12, 6, 4] : [12, 12, 12];
    return (
      <Column>
        <p>
          <strong>{title}</strong>
          <span className="tagged">
            {count} of {max} tagged {groupTitles}
          </span>
        </p>
        <Row>
          {groups.map(group => (
            <ProgressBar
              key={group.title}
              progress={group.progress}
              xs={xs}
              sm={sm}
              md={md}
            />
          ))}
        </Row>
      </Column>
    );
  }

  private totalTagging() {
    return this.props.groups.reduce(
      ([count, max], group) => {
        return [count + group.count, max + group.max];
      },
      [0, 0]
    );
  }

  private groupTitles() {
    if (this.props.groups.count() <= 1) {
      return '';
    }
    const groupLabels = this.props.groups.map(
      group => `${group.title}(${group.count}/${group.max})`
    );
    return groupLabels.join(', ');
  }
}
