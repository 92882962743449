import * as React from 'react';

export default function IconCaretDown() {
  return (
    <svg width="10px" height="6px" viewBox="0 0 10 6">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Change-Student-Nav-Item"
          transform="translate(-210.000000, -18.000000)"
          fill="#204864"
          fillRule="nonzero"
        >
          <g id="Group">
            <path
              d="M214.749989,23.8750075 L210.125,19.2812684 C210.041666,19.1979351 210,19.1093938 210,19.015644 C210,18.9218943 210.03125,18.833353 210.09375,18.7500197 L210.718748,18.1250212 C210.802082,18.0625213 210.895831,18.0312714 211,18.0312714 C211.104164,18.0312714 211.187497,18.0625213 211.249997,18.1250212 L214.999988,21.8437623 L218.749979,18.1250212 C218.812479,18.0625213 218.895812,18.0312714 218.999979,18.0312714 C219.104145,18.0312714 219.197895,18.0625213 219.281228,18.1250212 L219.906226,18.7500197 C219.968726,18.833353 219.999976,18.9218943 219.999976,19.015644 C219.999976,19.1093938 219.968726,19.1979351 219.906226,19.2812684 L215.281237,23.8750075 C215.197904,23.9375073 215.104154,23.9687572 214.999988,23.9687572 C214.895822,23.9687572 214.802072,23.9375073 214.718739,23.8750075 L214.749989,23.8750075 Z"
              id="a"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
