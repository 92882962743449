import {
  anyPending,
  AsyncActionSet,
  hasFailed,
  hasSucceeded,
  isPending,
  RequestStates,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, Section } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router';
import { destroy } from 'redux-form';
import * as _ from 'underscore';
import {
  GET_ASSETS,
  getAssets,
  IGetAssetsOptions,
  UPLOAD_ASSET,
} from '../../actions';
import { GET_COMMENTS, getComments } from '../../actions/comments';
import {
  clearJournalTaskData,
  setJournalTaskData,
} from '../../actions/journals';
import { openModal } from '../../actions/modals';
import { ADD_TO_COLLECTION, GET_COLLECTION } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { CLASS_OPTIONS_LIST } from '../../constants/classes';
import { LOAD_ITEM, UPDATE_ITEM } from '../../items/actions';
import { TEACHER, userHasRoles } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IItemsState, itemsModule } from '../../reducers/items';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/';
import { IAssetRecord, ICommentRecord } from '../../store/data-types';
import { IClassOption } from '../../store/data-types/classes';
import {
  IJournalEntry,
  IJournalTaskData,
} from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import { formatDate, formatDateTime, FormErrors } from '../../utils';
import JournalComments from '../comments/journal-comments';
import Loading from '../loading';
import {
  getDefaultJournalEntryValues,
  IPostTransformFormData,
  transformJournalData,
} from './add-journal-entry';
import JournalEntryForm, {
  IPreTransformFormData,
  JOURNAL_ENTRY_EDITED,
  JOURNAL_ENTRY_FORM,
  journalEntryEditedHandler,
  NAVIGATION_WARNING_MESSAGE,
} from './journal-entry-form';
import JournalEntryView from './journal-entry-view';
import JournalHeader from './journal-header';
import JournalPage from './journal-page';
import { JOURNAL_ENTRY_TASKS } from './tasks-sidebar/select-journal-tasks-modal';

const { actions: { getAllCollection } } = collectionsModule;
const { actions: { loadItem, updateItem } } = itemsModule;

export const EDIT_JOURNAL_ENTRY = 'EDIT_JOURNAL_ENTRY';

interface IParams {
  id: string;
}

interface IRouteProps {
  location: {
    pathname: string;
    query: {
      prevPathname?: string;
      prevName?: string;
    };
  };
}

interface IExternalProps extends RouteComponentProps<IParams, {}> {
  editing?: boolean;
  isViewingUnderStudentContext?: boolean;
}

interface IEditJournalEntryProps extends IExternalProps {
  classes: List<IClassOption>;
  assets: List<IAssetRecord>;
  commentsFailed: boolean;
  commentsLoading: boolean;
  editing: boolean | undefined;
  edited: boolean;
  failed: boolean;
  formErrors: FormErrors | undefined;
  journalEntry: IJournalEntry | null;
  journalEntryCreated: boolean;
  journalEntryId: string;
  updating: boolean;
  loading: boolean;
  profile: IProfile;
  rootComment: ICommentRecord;
  succeeded: boolean;

  clearJournalEntryEdited: typeof journalEntryEditedHandler.clearState;
  updateItem(
    itemType: keyof IItemsState,
    id: string,
    data: IPostTransformFormData
  ): AxiosPromise;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  getAssets(options: IGetAssetsOptions, tag?: string): void;
  loadItem(type: keyof IItemsState, id: string): AxiosPromise;
  openModal(modal: React.ReactNode): void;
  resetRequestState(actionSet: AsyncActionSet, tag?: string): void;
  getComments(rootCommentId: string): void;
  destroy(...formNames: string[]): void;
  setJournalTaskData(data: IJournalTaskData): void;
  clearJournalTaskData(): void;
}

export function getInitialValues(
  journalEntry: IJournalEntry | null
): IPreTransformFormData {
  if (!journalEntry) {
    return getDefaultJournalEntryValues();
  }
  const content = EditorState.createWithContent(
    stateFromHTML(journalEntry.content)
  );
  return {
    content,
    selectedAssets: List<string>(journalEntry.assets),
    title: journalEntry.title,
    entry_class: journalEntry.entry_class || undefined,
  };
}

export class EditJournalEntry extends React.PureComponent<
  IEditJournalEntryProps,
  void
> {
  constructor(props: IEditJournalEntryProps) {
    super(props);
    this.editJournalEntry = this.editJournalEntry.bind(this);
    this.openModal = this.openModal.bind(this);
    this.getAssets = this.getAssets.bind(this);
  }

  public componentWillMount() {
    this.props.clearJournalEntryEdited();
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.props.edited) {
        return NAVIGATION_WARNING_MESSAGE;
      }
    });

    this.props.clearJournalTaskData();
    this.props.loadItem('journals', this.props.journalEntryId).then(response =>
      this.props.setJournalTaskData({
        selectedTask: response.data.user_task || null,
        offJobTrainingHours: response.data.off_job_training_hours || null,
      })
    );
    this.props.getAllCollection(CLASS_OPTIONS_LIST, {}, EDIT_JOURNAL_ENTRY);
  }

  public componentWillUpdate(nextProps: IEditJournalEntryProps) {
    if (
      nextProps.journalEntry &&
      (!this.props.journalEntry ||
        nextProps.journalEntry.root_comment_id !==
          this.props.journalEntry.root_comment_id)
    ) {
      this.props.getComments(nextProps.journalEntry.root_comment_id);
    }

    const oldJournal = this.props.journalEntry;
    const newJournal = nextProps.journalEntry;
    const oldJournalId = oldJournal ? oldJournal.id : '';
    const newJournalId = newJournal ? newJournal.id : '';
    const journalUser = newJournal ? newJournal.user.id : '';

    if (oldJournalId !== newJournalId) {
      this.getAssets(newJournal);
    }
  }

  public componentWillUnmount() {
    this.props.destroy(JOURNAL_ENTRY_FORM);
  }

  public getAssets(journal?: IJournalEntry | null) {
    const journalEntry = journal || this.props.journalEntry;

    if (journalEntry) {
      this.props.getAssets(
        {
          owner__id: journalEntry.user.id,
          created_by__id: journalEntry.user.id,
        },
        EDIT_JOURNAL_ENTRY
      );
    }
  }

  public render() {
    const {
      assets,
      children,
      editing,
      failed,
      formErrors,
      journalEntry,
      journalEntryCreated,
      journalEntryId,
      updating,
      loading,
      location,
      profile,
      rootComment,
      succeeded,
      isViewingUnderStudentContext,
      classes,
    } = this.props;

    if (
      !loading &&
      ((failed && !journalEntry) ||
        (editing && journalEntry && journalEntry.user.id !== profile.id))
    ) {
      return (
        <JournalPage
          location={location}
          hideNav={Boolean(isViewingUnderStudentContext)}
        >
          <JournalHeader
            title="Failed to load"
            location={location}
            isViewingUnderStudentContext={isViewingUnderStudentContext}
          />
          <p>
            Failed to load Journal entry - it may not exist, or you may not have
            permission to {editing ? 'edit' : 'view'} it.
          </p>
        </JournalPage>
      );
    }

    if (!journalEntry) {
      return (
        <JournalPage
          location={location}
          hideNav={Boolean(isViewingUnderStudentContext)}
        >
          <JournalHeader
            title="Loading..."
            location={location}
            isViewingUnderStudentContext={isViewingUnderStudentContext}
          />
          <Loading />
        </JournalPage>
      );
    }

    const created = formatDate(journalEntry.created);
    const modified = formatDateTime(journalEntry.modified);
    const isOwner = journalEntry.user.id === profile.id;
    const readOnly =
      journalEntry.user.id !== profile.id || // We are vieiwng someone elses post as ourselves
      (isOwner && !journalEntry.can_be_modified_by_owner) || // We are viewing our own post but we cant modify the post
      (!isOwner && !journalEntry.can_be_modified_by_teacher); // We are viewing someone else but we cant modify the post
    const isEditing = editing && !readOnly;
    const showEdit =
      journalEntry.user.id === profile.id && !editing && !readOnly;
    return (
      <JournalPage
        location={location}
        hideNav={Boolean(isViewingUnderStudentContext)}
      >
        <JournalHeader
          title={journalEntry.title}
          showEdit={showEdit}
          journalId={journalEntry.id}
          location={location}
          isViewingUnderStudentContext={isViewingUnderStudentContext}
        >
          <p className="meta">
            by {journalEntry.user.osms_data.name || '[Unknown User]'} - created{' '}
            {created} - last updated {modified}
          </p>
        </JournalHeader>
        <Section>
          {isEditing ? (
            <JournalEntryForm
              key={journalEntry.id}
              journalEntry={journalEntry}
              getAssets={this.getAssets}
              isStudent={!userHasRoles(profile, Set.of(TEACHER))}
              profile={profile}
              assets={assets}
              loading={loading}
              submitting={updating}
              hideSidebar
              onSubmit={this.editJournalEntry}
              openModal={this.openModal}
              initialValues={getInitialValues(journalEntry)}
              saveButtonText="Save"
              formErrors={formErrors}
              destroyOnUnmount={false}
              isOwner={isOwner}
              showTaskLink={false}
              classes={classes}
            />
          ) : (
            <JournalEntryView
              getAssets={this.getAssets}
              journalEntry={journalEntry}
              profile={profile}
              assets={assets}
              isOwner={isOwner}
              readOnly={readOnly}
              journalEntryCreated={journalEntryCreated}
              loading={loading}
              isViewingUnderStudentContext={isViewingUnderStudentContext}
            />
          )}
          {succeeded &&
            !journalEntryCreated && (
              <Alert className="success">
                <p>Updated successfully.</p>
              </Alert>
            )}
          {failed && (
            <Alert className="error">
              <p>Failed to update journal entry.</p>
            </Alert>
          )}
        </Section>
        <JournalComments
          loading={this.props.commentsLoading}
          failed={this.props.commentsFailed}
        />
      </JournalPage>
    );
  }

  private editJournalEntry(data: IPreTransformFormData) {
    const dataWithFallbacks = {
      selectedTask: this.props.journalEntry
        ? this.props.journalEntry.user_task
        : null,
      ...data,
    };
    this.props
      .updateItem(
        'journals',
        this.props.journalEntryId,
        transformJournalData(dataWithFallbacks)
      )
      .then(() => {
        this.props.clearJournalEntryEdited();
      });
  }

  private openModal(component: React.ReactNode) {
    this.props.openModal(component);
    this.props.resetRequestState(UPLOAD_ASSET);
  }
}

function mapStateToProps(
  {
    assets,
    collectionsOld,
    itemsOld,
    profile,
    responses,
    rootComment,
    uiState,
  }: IStore,
  { editing, location, params }: IExternalProps
) {
  const classes =
    getCollectionItems(
      collectionsOld[CLASS_OPTIONS_LIST],
      EDIT_JOURNAL_ENTRY
    ) || List();
  return {
    classes,
    assets,
    commentsFailed: hasFailed(responses, GET_COMMENTS),
    commentsLoading: isPending(responses, GET_COMMENTS),
    editing,
    edited: journalEntryEditedHandler.getValue(uiState),
    failed:
      hasFailed(responses, UPDATE_ITEM, 'journals') ||
      hasFailed(responses, LOAD_ITEM, 'journals'),
    formErrors: getFormErrors(responses, UPDATE_ITEM, 'journals'),
    journalEntry: itemsOld.journals,
    journalEntryCreated: hasSucceeded(responses, ADD_TO_COLLECTION, 'journals'),
    journalEntryId: params.id,
    loading: anyPending(responses, [
      [GET_ASSETS, EDIT_JOURNAL_ENTRY],
      [LOAD_ITEM, 'journals'],
      [GET_COLLECTION, CLASS_OPTIONS_LIST],
    ]),
    updating: isPending(responses, UPDATE_ITEM, 'journals'),
    location,
    profile,
    rootComment,
    succeeded: hasSucceeded(responses, UPDATE_ITEM, 'journals'),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  getAssets,
  loadItem,
  openModal,
  resetRequestState,
  getComments,
  updateItem,
  destroy,
  clearJournalEntryEdited: journalEntryEditedHandler.clearState,
  setJournalTaskData,
  clearJournalTaskData,
})(EditJournalEntry);
