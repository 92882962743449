import * as React from 'react';

import { Button, TableCell, TableRow } from '@dabapps/roe';
import * as classNames from 'classnames';
import { ISimpleUser } from '../../../../store/data-types/profile';

export interface IActionProps {
  actionName: string;
  mode: 'ADD' | 'REMOVE';
  onAction(studentId: string): void;
}

interface IStudentRowProps extends IActionProps {
  student: ISimpleUser;
}

export default class StudentRow extends React.PureComponent<
  IStudentRowProps,
  void
> {
  public constructor(props: IStudentRowProps) {
    super(props);
    this.onAction = this.onAction.bind(this);
  }

  public render() {
    const { student, actionName, mode } = this.props;

    return (
      <TableRow>
        <TableCell>
          <strong>{student.osms_data.name}</strong>
          <br />
          <span className="font-size-small">
            {student.osms_data.email || student.osms_data.name}
          </span>
        </TableCell>
        <TableCell>
          <Button
            className={classNames(
              mode === 'ADD' ? 'success' : 'error',
              'float-right',
              'padding-horizontal-large padding-vertical-small'
            )}
            onClick={this.onAction}
          >
            {actionName}
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  private onAction(event: React.MouseEvent<HTMLButtonElement>) {
    const { student } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.props.onAction(student.id);
  }
}
