import { Alert } from '@dabapps/roe';
import * as React from 'react';
import { Link } from 'react-router';
import Term from './terminology';

interface IProps {
  qualificationId?: false | string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const QualificationMandatoryUnitsWarning = ({
  qualificationId,
  onClick,
}: IProps) => {
  const linkToQual = `/tasks/qualifications/${qualificationId
    ? qualificationId + '/'
    : ''}`;

  return (
    <Alert className="warning">
      <p>
        Note: assigning this <Term>Class</Term> to a qualification will only
        assign the users to mandatory units. You can assign users to optional
        units via the{' '}
        <Link to={linkToQual} onClick={onClick}>
          qualifications
        </Link>{' '}
        page.
      </p>
    </Alert>
  );
};

export default QualificationMandatoryUnitsWarning;
