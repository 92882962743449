import * as React from 'react';
import { connect } from 'react-redux';

import { displayLoadingSpinner, hideLoadingSpinner } from '../actions';

interface IProps {
  displayLoadingSpinner(): void;
  hideLoadingSpinner(): void;
}

export class LoadingSpinner extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.displayLoadingSpinner();
  }

  public componentWillUnmount() {
    this.props.hideLoadingSpinner();
  }

  public render() {
    return null;
  }
}

export default connect(undefined, {
  displayLoadingSpinner,
  hideLoadingSpinner,
})(LoadingSpinner);
