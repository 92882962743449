import { Container, SpacedGroup } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import * as classNames from 'classnames';
import { Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { ComponentClass, connect } from 'react-redux';
import { Link } from 'react-router';
import { unhijackAccount } from '../../actions/hijack';
import { clearCurrentCentreRole } from '../../actions/roles';
import { APP_NAME } from '../../constants';
import {
  ALL_CENTRE_USERS,
  ALL_CLASS_USERS,
  canGenerateReports,
  canViewCourseworkProgressOverview,
  canViewUsers,
  CENTRE_ADMIN,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
} from '../../permissions';
import { isOnSelectRolePage } from '../../permissions';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import { formatRoles } from '../../utils';
import IconSwitchRole from '../icon-switch-role';
import ImageHolder from '../image-holder';
import IfHasRoles from '../permissions/if-has-roles';
import MaybeLink from '../permissions/maybe-link';
import Term from '../terminology';
import { HijackBar } from './hijack-bar';
import NavItem from './item';

const {
  IconSignOut,
  IconHome,
  IconBook,
  IconTasks,
  IconListOl,
  IconFolderOpen,
  IconGraduationCap,
  IconUsers,
  IconUser,
  IconClipboard,
} = FontAwesome;

interface INavbarExternalProps {
  className?: string;
  pathname: string;
}

interface INavbarProps extends INavbarExternalProps {
  profile: IProfile;
  unhijackAccount(tag?: string): AxiosPromise;
  clearCurrentCentreRole(): void;
}

interface IRightNavProps {
  loggedIn: boolean;
  profile: IProfile;
  clearCurrentCentreRole(): void;
}

const LOGIN_OR_LOGOUT_TEXT = {
  login: 'Log in',
  logout: 'Log out',
};

const MATCHES_ROOT = /^\/(newsfeed\/)?(my-activity\/)?$/;
const MATCHES_JOURNAL = /^\/journal/;
const MATCHES_TASKS = /^\/tasks\/(?!overview)/;
const MATCHES_OVERVIEW = /^\/tasks\/overview\/.*/;
const MATCHES_FILES = /^\/files/;
const MATCHES_CLASSES = /^\/classes/;
const MATCHES_USERS = /^\/users/;
const MATCHES_REPORTS = /^\/reports/;

const RightNav: React.SFC<
  React.HTMLAttributes<HTMLUListElement> & IRightNavProps
> = ({ loggedIn, profile, className, clearCurrentCentreRole }) => {
  const loginOrLogout = loggedIn ? 'logout' : 'login';
  const { current_role, current_centre, can_change_role, osms_data } = profile;

  const centreNumber =
    current_centre && current_centre.osms_data.centre_number
      ? `(${current_centre.osms_data.centre_number})`
      : '';

  return (
    <ul className={classNames('nav right-nav', className)}>
      {loggedIn ? (
        <li>
          <a href="/profile/">{osms_data.name || 'Unnamed'}</a>
          {current_role && (
            <span className="role-display">
              {formatRoles([current_role], profile)}
            </span>
          )}
          <IconUser />
        </li>
      ) : null}
      {current_centre && (
        <li className="font-colour-light xs-display-none sm-display-block">
          {`${current_centre.osms_data.name} ${centreNumber}`}
        </li>
      )}
      <li>
        {current_role &&
          can_change_role && (
            <span className="select-role-link">
              <a onClick={clearCurrentCentreRole}>Switch role</a>
              <IconSwitchRole />
            </span>
          )}
        <a className="logout-link" href={`/accounts/${loginOrLogout}/`}>
          {LOGIN_OR_LOGOUT_TEXT[loginOrLogout]}
        </a>
        {loggedIn && <IconSignOut />}
      </li>
    </ul>
  );
};

class NavBar extends React.PureComponent<INavbarProps, void> {
  public render() {
    const {
      className,
      profile,
      unhijackAccount,
      pathname,
      clearCurrentCentreRole,
    } = this.props;
    const loggedIn = Boolean(profile.id);

    return (
      <div>
        <div className="navbar xs-display-block sm-display-none">
          <Container>
            <h1 className="brand">
              <Link to="/">{APP_NAME}</Link>
            </h1>
            <RightNav
              className="xs-display-block sm-display-none"
              loggedIn={loggedIn}
              profile={profile}
              clearCurrentCentreRole={clearCurrentCentreRole}
            />
          </Container>
        </div>

        <div className={classNames('navbar', className)}>
          <HijackBar profile={profile} unhijackAccount={unhijackAccount} />

          <Container fluid>
            <div className="top-nav-wrapper">
              <div className="icon-brand">
                {profile.current_centre && (
                  <div className="brand xs-display-none md-display-block centre-whitelabel">
                    <ImageHolder
                      fit="contain"
                      className="centre-whitelabel-picture"
                      src="/static/manageassess-logo.png"
                      alt="Logo"
                    />
                  </div>
                )}
                <h1 className="brand xs-display-none md-display-block">
                  <Link to="/">{APP_NAME}</Link>
                </h1>
              </div>
              {loggedIn && !isOnSelectRolePage(pathname) ? (
                <ul className="nav pills">
                  <IfHasRoles
                    roles={Set.of(
                      STUDENT,
                      TEACHER,
                      CENTRE_ADMIN,
                      EXTERNAL_QUALITY_ASSURER,
                      INTERNAL_QUALITY_ASSURER
                    )}
                  >
                    <NavItem
                      href="/"
                      pathname={pathname}
                      matches={MATCHES_ROOT}
                    >
                      <IconHome />
                      Home
                    </NavItem>
                  </IfHasRoles>
                  <IfHasRoles roles={Set.of(STUDENT, TEACHER)}>
                    <NavItem
                      href="/journal/"
                      pathname={pathname}
                      matches={MATCHES_JOURNAL}
                    >
                      <IconBook />
                      Journal
                    </NavItem>
                  </IfHasRoles>
                  <IfHasRoles
                    roles={ALL_CENTRE_USERS.toSet().concat(
                      INTERNAL_QUALITY_ASSURER,
                      EXTERNAL_QUALITY_ASSURER
                    )}
                  >
                    {profile.current_centre && (
                      <NavItem
                        href="/tasks/"
                        pathname={pathname}
                        matches={MATCHES_TASKS}
                      >
                        <IconTasks />
                        <Term>Tasks</Term>
                      </NavItem>
                    )}
                    {profile.current_centre &&
                      canViewCourseworkProgressOverview(profile) && (
                        <NavItem
                          href="/tasks/overview/"
                          pathname={pathname}
                          matches={MATCHES_OVERVIEW}
                        >
                          <IconListOl />
                          <Term>Overview</Term>
                        </NavItem>
                      )}
                  </IfHasRoles>
                  <IfHasRoles roles={ALL_CENTRE_USERS.toSet()}>
                    <NavItem
                      href="/files/"
                      pathname={pathname}
                      matches={MATCHES_FILES}
                    >
                      <IconFolderOpen />
                      Files
                    </NavItem>
                  </IfHasRoles>
                  <IfHasRoles roles={ALL_CLASS_USERS.toSet()}>
                    <NavItem
                      href="/classes/"
                      pathname={pathname}
                      matches={MATCHES_CLASSES}
                    >
                      <IconGraduationCap />
                      <Term>Classes</Term>
                    </NavItem>
                  </IfHasRoles>
                  {canViewUsers(profile) && (
                    <NavItem
                      href="/users/"
                      pathname={pathname}
                      matches={MATCHES_USERS}
                    >
                      <IconUsers />
                      Users
                    </NavItem>
                  )}
                  {canGenerateReports(profile) && (
                    <NavItem
                      href="/reports/"
                      pathname={pathname}
                      matches={MATCHES_REPORTS}
                    >
                      <IconClipboard />
                      Reports
                    </NavItem>
                  )}
                  <NavItem>
                    <a
                      href="https://www.vtct.org.uk/existing-centres/resources/manageassess/"
                      target="_blank"
                    >
                      Help
                    </a>
                  </NavItem>
                </ul>
              ) : null}
              <RightNav
                className="xs-display-none sm-display-block"
                loggedIn={loggedIn}
                profile={profile}
                clearCurrentCentreRole={clearCurrentCentreRole}
              />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(
  { profile }: IStore,
  { pathname }: INavbarExternalProps
) {
  return {
    pathname,
    profile,
  };
}

export default connect(mapStateToProps, {
  unhijackAccount,
  clearCurrentCentreRole,
})(NavBar);
