import * as React from 'react';

import { lookupGrade } from '../../lookup-tables/grade';
import { GradeList } from '../../store/data-types/tasks';

interface IProps {
  grade: GradeList | null;
  prefix?: string;
  loading?: boolean;
}

export default class GradeIndicator extends React.PureComponent<IProps, void> {
  public render() {
    const { grade, prefix, loading } = this.props;

    if (loading) {
      return (
        <span className="grade-indicator">
          {prefix || ''}Calculating&hellip;
        </span>
      );
    }

    if (!grade || grade.length === 0) {
      return null;
    }

    return (
      <span className="grade-indicator">
        {prefix || ''}
        {grade.map(each => (
          <span key={each.checklist} className="grade">
            {lookupGrade(each.grade)}{' '}
            {each.score !== null ? `(${each.score})` : ''}
          </span>
        ))}
      </span>
    );
  }
}
