import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Column,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  Row,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { pairs } from '../../functional';
import { LOAD_ITEM } from '../../items/actions';

import { Dict } from '@dabapps/simple-records';
import { IRouteProps } from '../../index';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IBulkUpload } from '../../store/data-types/bulk-upload';
import Loading from '../loading';
import AddStudents from './add-students';
import ColumnTypes from './column-types';
import Finished from './finished';
import Review from './review';

const { actions: { actionItem, loadItem, updateItem } } = itemsModule;

const { IconClockO } = FontAwesome;

interface IProps extends IRouteProps {
  importId: string;
  importObject: IBulkUpload | null;
  loading: boolean;
  loadItem(
    type: keyof IItemsState,
    itemId: string,
    tag?: string,
    preserveOriginal?: boolean
  ): void;
  updateItem(type: keyof IItemsState, itemId: string, item: IBulkUpload): void;
  actionItem(
    type: keyof IItemsState,
    itemId: string,
    actionId: string,
    actionData: any
  ): void;
}

export class BulkImportDetail extends React.PureComponent<IProps, void> {
  private pollRefresh: boolean = false;
  private timeoutHandle: null | NodeJS.Timer = null;

  public constructor(props: IProps) {
    super(props);
    this.onMapped = this.onMapped.bind(this);
    this.confirmProcess = this.confirmProcess.bind(this);
    this.cancelReview = this.cancelReview.bind(this);
    this.cancelMapping = this.cancelMapping.bind(this);
    this.cancelFixData = this.cancelFixData.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem('imports', this.props.importId);

    this.timeoutHandle = setInterval(() => {
      if (this.pollRefresh) {
        if (this.props.importObject) {
          this.props.loadItem('imports', this.props.importId, undefined, true);
        }
      }
    }, 15000);
  }

  public componentWillUnmount() {
    if (this.timeoutHandle) {
      clearInterval(this.timeoutHandle);
      this.timeoutHandle = null;
    }
  }

  public render() {
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Upload users via CSV</h2>
        </ContentBoxHeader>
        {this.getStateRenderer()}
      </ContentBox>
    );
  }

  private getStateRenderer() {
    this.pollRefresh = false;

    if (!this.props.importObject) {
      return this.renderLoading();
    }

    switch (this.props.importObject.state) {
      case 'UPLOAD':
        return this.renderUpload();
      case 'ANALYSING_HEADERS':
        this.pollRefresh = true;
        return this.renderAnalysingHeaders();
      case 'ASSIGN_HEADERS':
        return this.renderAssignHeaders();
      case 'CONFIRM_PROCESS':
        return this.renderConfirmProcess();
      case 'FIX_DATA_ISSUES':
        return this.renderFixDataIssues();
      case 'PROCESS':
        this.pollRefresh = true;
        return this.renderProcess();
      case 'PROCESSED':
        return this.renderProcessed();
      default:
        return this.renderLoading();
    }
  }

  private renderLoading() {
    return <Loading />;
  }

  private renderUpload() {
    return (
      <div>
        {this.props.importObject ? (
          <AddStudents
            importId={this.props.importId}
            importObject={this.props.importObject}
            onUploaded={() => {
              this.props.loadItem(
                'imports',
                this.props.importId,
                undefined,
                true
              );
            }}
          />
        ) : null}
      </div>
    );
  }

  private renderAnalysingHeaders() {
    return (
      <div className="add-students">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add students
              </li>
              <li className="active">
                <span className="number">2</span> Column types
              </li>
              <li>
                <span className="number">3</span> Review
              </li>
              <li>
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <p>
              <IconClockO className="icon-small" /> Please wait while the server
              is analysing headers.
            </p>
            <Loading />
          </Column>
        </Row>
      </div>
    );
  }

  private renderAssignHeaders() {
    return (
      <ColumnTypes
        requiredFields={
          this.props.importObject ? this.props.importObject.required_fields : []
        }
        optionalFields={
          this.props.importObject ? this.props.importObject.optional_fields : []
        }
        firstRows={
          this.props.importObject
            ? this.props.importObject.csv_first_rows
            : [[], [], []]
        }
        onMapped={this.onMapped}
        onCancel={this.cancelMapping}
      />
    );
  }

  private onMapped(mapped: Dict<string>) {
    if (mapped !== null && this.props.importObject !== null) {
      this.props.actionItem(
        'imports',
        this.props.importId,
        'assign_headings',
        mapped
      );
    }
  }

  private cancelMapping() {
    if (this.props.importObject !== null) {
      this.props.actionItem(
        'imports',
        this.props.importId,
        'cancel_assign_headings',
        undefined
      );
    }
  }

  private cancelFixData() {
    if (this.props.importObject !== null) {
      this.props.actionItem(
        'imports',
        this.props.importId,
        'cancel_fix_data',
        undefined
      );
    }
  }

  private renderConfirmProcess() {
    return (
      <div className="add-students">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add students
              </li>
              <li className="active">
                <span className="number">2</span> Column types
              </li>
              <li className="active">
                <span className="number">3</span> Review
              </li>
              <li>
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <p>
              Please review the summary. If you're happy then click on next to
              begin the import.
            </p>

            {this.props.importObject ? (
              <ul>
                <li>
                  There
                  <strong>
                    {this.props.importObject.csv_first_row_contains_headers
                      ? ' is '
                      : ' is not '}
                  </strong>
                  a header row.
                </li>
                {pairs(this.props.importObject.headings_map).map(([k, v]) => {
                  return (
                    <li key={k}>
                      <strong>{v}</strong> in your CSV is mapped to{' '}
                      <strong>{k}</strong>
                    </li>
                  );
                })}
              </ul>
            ) : null}
            <ContentBoxFooter>
              <div className="btn-footer">
                <Button
                  onClick={this.cancelReview}
                  className="float-left margin-vertical-base"
                >
                  Back
                </Button>
                <Button
                  onClick={this.confirmProcess}
                  className="primary float-right margin-vertical-base"
                >
                  Confirm Import
                </Button>
              </div>
            </ContentBoxFooter>
          </Column>
        </Row>
      </div>
    );
  }

  private renderFixDataIssues() {
    return (
      <Review
        processing_errors={
          this.props.importObject
            ? this.props.importObject.processing_errors
            : {}
        }
        onBack={this.cancelFixData}
      />
    );
  }

  private renderProcess() {
    return (
      <div className="add-students">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add students
              </li>
              <li className="active">
                <span className="number">2</span> Column types
              </li>
              <li className="active">
                <span className="number">3</span> Review
              </li>
              <li className="active">
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <p>
              <IconClockO className="icon-small" /> Please wait while the server
              is processing users.
            </p>
            <Loading />
          </Column>
        </Row>
      </div>
    );
  }

  private renderProcessed() {
    return <Finished />;
  }

  private cancelReview() {
    this.props.actionItem('imports', this.props.importId, 'cancel_process', {});
  }

  private confirmProcess() {
    this.props.actionItem('imports', this.props.importId, 'process', {});
  }
}

function mapStateToProps({ itemsOld, responses }: IStore, props: IRouteProps) {
  return _.extend({}, props, {
    importId: props.params.id,
    importObject: itemsOld.get('imports'),
    loading: isPending(responses, LOAD_ITEM, 'imports'),
  });
}

export default connect(mapStateToProps, {
  actionItem,
  loadItem,
  updateItem,
})(BulkImportDetail);
