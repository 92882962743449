import * as _ from 'underscore';

/*
 * This provides a stricter-typed version of Underscore's Pairs function
 */
export function pairs<T>(obj: {
  [key: string]: T;
}): ReadonlyArray<[string, T]> {
  return _.pairs(obj) as any;
}

/*
 * This provides a stricter-typed version of Underscore's Object function
 */
export function pairsToObject<T>(
  pairs: ReadonlyArray<[string, T]>
): { [key: string]: T } {
  return _.object(pairs) as any;
}

export const keys: (object: { [key: string]: any }) => string[] = _.keys;
