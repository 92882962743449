import { SpacedGroup } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { SEND_INVITE, sendInvite } from '../../actions/emails';
import { hijackAccount } from '../../actions/hijack';
import { openModal } from '../../actions/modals';
import { getCollectionByName } from '../../collections/reducers';
import {
  // tslint:disable-next-line:no-unused-variable
  ICollection,
  ICollectionOptions,
} from '../../collections/types';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store';
import {
  IProfile,
  ISimpleUser,
  TRole,
  USER_STATUSES,
} from '../../store/data-types/profile';
import { formatDate, formatDateTime, formatRoles } from '../../utils';
import FilesWizard from '../files/files-wizard';
import IfCan from '../permissions/if-can';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import { USERS_LIST } from './list';
import ToggleStatusButton from './toggle-status-button';

const { IconSpinner, IconCheck, IconTimes } = FontAwesome;
const FIRST_COLUMN_WIDTH = 230;

function getHeaders(
  profile: IProfile,
  userInvites: Map<string, string>,
  reloadUsers: () => void,
  getSelectedStudentIds: () => Set<string>,
  onChangeStudent: (userId: string) => void,
  hijackAccount: (userId: string) => void,
  sendInvite: (userId: string) => void,
  tagFiles: (user: ISimpleUser) => void
): Array<IColumnData<IProfile>> {
  const selectedStudents = getSelectedStudentIds();
  return [
    {
      content: (data: IProfile) => (
        <SpacedGroup>
          <IfCan
            can={userHasRoles(
              profile,
              Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
            )}
          >
            <span className="display-inline-block user-table-input-wrapper">
              {hasStudentDisplayRole(data) && (
                <input
                  type="checkbox"
                  onChange={() => onChangeStudent(data.id)}
                  checked={selectedStudents.contains(data.id)}
                />
              )}
            </span>
          </IfCan>
          <span className="display-inline-block">
            {data.osms_data.name}
            <span className="font-size-small font-weight-normal display-block">
              {formatRoles(data.display_roles, profile)}
            </span>
            {data.status === USER_STATUSES.DISABLED && (
              <span className="info display-block">
                {USER_STATUSES.DISABLED}
              </span>
            )}
          </span>
        </SpacedGroup>
      ),
      headerLabel: 'Name',
      key: 'userprofilecache__name',
      sortable: true,
      width: FIRST_COLUMN_WIDTH,
    },
    {
      content: data => data.osms_data.username,
      headerLabel: 'Username',
      key: 'userprofilecache__username',
      sortable: true,
    },
    {
      content: data => data.osms_data.email,
      headerLabel: 'Email',
      key: 'userprofilecache__email',
      sortable: true,
    },
    {
      content: data => formatDate(data.created),
      headerLabel: 'Date Created',
      key: 'created',
      sortable: true,
    },
    {
      content: data =>
        data.last_login ? formatDateTime(data.last_login) : '--',
      headerLabel: 'Last Login',
      key: 'last_login',
      sortable: true,
    },
    {
      content: (data: IProfile) => {
        const isArchived = data.status === USER_STATUSES.ARCHIVED;
        return (
          <SpacedGroup>
            <IfCan
              can={userHasRoles(
                profile,
                Set.of(TEACHER, CENTRE_ADMIN, DA_ADMIN, DA_SUPPORT)
              )}
            >
              <Link to={`/users/edit/${data.id}/`}>
                {isArchived ? 'View' : 'Edit'}
              </Link>
            </IfCan>
            <IfCan
              can={
                userHasRoles(profile, Set.of(TEACHER, CENTRE_ADMIN)) &&
                hasStudentRoleInCurrentCentre(data)
              }
            >
              <Link to={`/users/student/${data.id}/`}>View</Link>
            </IfCan>
            <IfCan can={userHasRoles(profile, Set.of(DA_ADMIN, DA_SUPPORT))}>
              <a className="warning" onClick={() => hijackAccount(data.id)}>
                Login as this user
              </a>
            </IfCan>
            {!isArchived && (
              <IfCan
                can={userHasRoles(
                  profile,
                  Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
                )}
              >
                {data.id !== profile.id && (
                  <ToggleStatusButton
                    user={data}
                    onComplete={reloadUsers}
                    inactiveStatusKey={USER_STATUSES.DISABLED}
                  />
                )}
              </IfCan>
            )}
            {hasStudentDisplayRole(data) && (
              <IfCan
                can={userHasRoles(
                  profile,
                  Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
                )}
              >
                {data.id !== profile.id && (
                  <ToggleStatusButton
                    user={data}
                    onComplete={reloadUsers}
                    inactiveStatusKey={USER_STATUSES.ARCHIVED}
                  />
                )}
              </IfCan>
            )}
            {data.can_send_invite && (
              <IfCan
                can={userHasRoles(
                  profile,
                  Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
                )}
              >
                {/* <a
                  disabled={userInvites.get(data.id) === SEND_INVITE.REQUEST}
                  onClick={() =>
                    userInvites.get(data.id) !== SEND_INVITE.REQUEST &&
                    sendInvite(data.id)}
                >
                  (Re)Send Invite
                  </a> */}
                {!userInvites.get(data.id) && (
                  <IconSpinner className="status-icon invites invisible" />
                )}
                {userInvites.get(data.id) === SEND_INVITE.REQUEST && (
                  <IconSpinner className="status-icon invites spinner" />
                )}
                {userInvites.get(data.id) === SEND_INVITE.SUCCESS && (
                  <IconCheck className="status-icon invites check" />
                )}
                {userInvites.get(data.id) === SEND_INVITE.FAILURE && (
                  <IconTimes className="status-icon invites times" />
                )}
              </IfCan>
            )}
            {tagFiles && (
              <IfCan can={userHasRoles(profile, Set.of(CENTRE_ADMIN, TEACHER))}>
                <a onClick={tagFiles.bind(null, data)}>Upload and tag files</a>
              </IfCan>
            )}
          </SpacedGroup>
        );
      },
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

function hasStudentDisplayRole(user: IProfile) {
  return (
    user.display_roles.filter((role: TRole) => role === STUDENT).length > 0
  );
}

function hasStudentRoleInCurrentCentre(user: IProfile) {
  if (user.roles_for_current_centre) {
    return (
      user.roles_for_current_centre.filter((role: TRole) => role === STUDENT)
        .length > 0
    );
  }
  return false;
}

interface IExternalProps {
  value?: any;
  onChange?: (value?: List<string>) => void;
  loading: boolean;
  loadUsers(options?: ICollectionOptions): void;
}

interface IStateProps {
  usersCollection: ICollection<IProfile>;
  userInvites: Map<string, string>;
  profile: IProfile;
}

interface IDispatchProps {
  openModal: typeof openModal;
  hijackAccount(userId: string, tag?: string): AxiosPromise;
  sendInvite(userId: string, tag?: string): AxiosPromise;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class UserTable extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.hijackAccount = this.hijackAccount.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
    this.tagFiles = this.tagFiles.bind(this);
    this.getSelectedStudentIds = this.getSelectedStudentIds.bind(this);
    this.getAllStudentIds = this.getAllStudentIds.bind(this);
    this.onChangeStudent = this.onChangeStudent.bind(this);
    this.onChangeAllStudents = this.onChangeAllStudents.bind(this);
  }

  public render() {
    const selectedStudents = this.getSelectedStudentIds();
    const allStudents = this.getAllStudentIds().toSet();
    const { profile } = this.props;
    return (
      <div>
        <IfCan
          can={userHasRoles(
            this.props.profile,
            Set.of(CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN)
          )}
        >
          <div className="margin-vertical-base">
            <label>
              <SpacedGroup>
                <input
                  type="checkbox"
                  checked={
                    allStudents.count() > 0 &&
                    allStudents.equals(selectedStudents)
                  }
                  onChange={event => this.onChangeAllStudents(event)}
                />
                <span>Select All / None</span>
              </SpacedGroup>
            </label>
          </div>
        </IfCan>
        <CollectionTable
          headers={getHeaders(
            profile,
            this.props.userInvites,
            this.props.loadUsers,
            this.getSelectedStudentIds,
            this.onChangeStudent,
            this.hijackAccount,
            this.sendInvite,
            this.tagFiles
          )}
          loading={this.props.loading}
          collection={this.props.usersCollection}
          pageSize={10}
          getCollection={this.props.loadUsers}
        />
      </div>
    );
  }

  private getSelectedStudentIds() {
    const { value } = this.props;
    return (value || List<string>()).toSet() as Set<string>;
  }

  private getAllStudentIds() {
    const { usersCollection } = this.props;
    return usersCollection.results
      .filter(user => hasStudentDisplayRole(user))
      .map(user => user.id);
  }

  private onChangeStudent(studentId: string): void {
    const { value, onChange } = this.props;
    const currentValue = value || List<string>();
    if (currentValue.contains(studentId)) {
      return (
        onChange &&
        onChange(currentValue.remove(currentValue.indexOf(studentId)))
      );
    }
    return onChange && onChange(currentValue.push(studentId));
  }

  private onChangeAllStudents(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { onChange } = this.props;
    const checked = event.target.checked;
    const newValue = checked ? this.getAllStudentIds() : List<string>();
    return onChange && onChange(newValue);
  }

  private hijackAccount(userId: string) {
    this.props.hijackAccount(userId).then(() => {
      window.location.replace('/');
    });
  }

  private sendInvite(userId: string) {
    this.props.sendInvite(userId);
  }

  private tagFiles(user: ISimpleUser) {
    this.props.openModal(<FilesWizard initialUser={user} />);
  }
}

function mapStateToProps(
  { collectionsOld, userInvites, responses, profile }: IStore,
  props: IExternalProps
) {
  return {
    ...props,
    profile,
    userInvites,
    usersCollection: getCollectionByName(collectionsOld.users, USERS_LIST),
  };
}

export default connect(mapStateToProps, {
  hijackAccount,
  sendInvite,
  openModal,
})(UserTable);
