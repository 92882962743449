import * as React from 'react';
import { IAssetRecord } from '../store/data-types';
import { INestedLearningOutcomeBlockAsset } from '../store/data-types/marksheets';

function getDownloadUrl(
  asset?: IAssetRecord | INestedLearningOutcomeBlockAsset
): string | undefined {
  if (!asset) {
    return undefined;
  }

  if ((asset as IAssetRecord).download_url) {
    return (asset as IAssetRecord).download_url;
  }

  return (asset as INestedLearningOutcomeBlockAsset).asset_download_url;
}

export default function MaybeAssetAnchor({
  asset,
  children,
  className,
}: React.HTMLProps<JSX.Element> & {
  asset?: IAssetRecord | INestedLearningOutcomeBlockAsset;
}) {
  const downloadUrl = getDownloadUrl(asset);
  if (typeof downloadUrl === 'string') {
    return (
      <a className={className} href={downloadUrl}>
        {children}
      </a>
    );
  }
  return <span className={className}>{children}</span>;
}
