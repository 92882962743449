import {
  hasFailed,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Column,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  FormGroup,
  Row,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

import {
  GET_CURRENT_CENTRE_OPTIONS,
  GET_CURRENT_ROLE_OPTIONS,
  getCentreOptions,
  getRoleOptions,
  ICentreRoleUpdateData,
  UPDATE_CURRENT_CENTRE_ROLE,
  updateCurrentCentreRole,
} from '../../actions/roles';
import {
  DA_ADMIN,
  DA_SUPPORT,
  EXTERNAL_QUALITY_ASSURER,
  ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store';
import { IProfile, TRole } from '../../store/data-types/profile';
import { ICentreOption, IRoleOption } from '../../store/data-types/roles';
import { roleConstantToTitleCaseAllWords } from '../../utils';
import Loading from '../loading';

const { IconInfoCircle } = FontAwesome;

interface IStateProps {
  profile: IProfile;
  roleOptions: ReadonlyArray<IRoleOption>;
  centreOptions: ReadonlyArray<ICentreOption>;
  isLoading: boolean;
  hasFailed: boolean;
}

interface IDispatchProps {
  updateCurrentCentreRole(updateData: ICentreRoleUpdateData): void;
  getRoleOptions(): void;
  getCentreOptions(): void;
}

type IProps = IStateProps & IDispatchProps;

export class CurrentRolePickerPage extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    const { profile } = this.props;
    this.props.getRoleOptions();
    if (
      profile.current_role &&
      !userHasRoles(profile, ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE) &&
      !profile.current_centre
    ) {
      this.props.getCentreOptions();
    }
  }

  public render() {
    const {
      profile,
      centreOptions,
      roleOptions,
      isLoading,
      hasFailed,
    } = this.props;
    const hasCurrentRole = Boolean(profile.current_role);
    const hasCurrentCentre = Boolean(profile.current_centre);
    const currentRoleOption =
      hasCurrentRole &&
      roleOptions &&
      (roleOptions.find(
        (role: IRoleOption) => role.type === profile.current_role
      ) as IRoleOption);

    const shouldShowCentrePicker = Boolean(
      hasCurrentRole &&
        !userHasRoles(profile, ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE) &&
        centreOptions.length > 1
    );

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Choose role and/or centre</h2>
        </ContentBoxHeader>
        {hasFailed ? (
          <Alert className="error">
            <p>Something went wrong.</p>
          </Alert>
        ) : isLoading ? (
          <Loading className="medium" />
        ) : (
          <Row>
            <Column xs={12} sm={6}>
              <FormGroup block>
                <label htmlFor="choose-current-role">Role</label>
                {profile.current_role &&
                (roleOptions && roleOptions.length === 1) ? (
                  <select disabled>
                    <option>
                      {roleConstantToTitleCaseAllWords(profile.current_role)}
                    </option>
                  </select>
                ) : roleOptions && roleOptions.length ? (
                  <select
                    value={currentRoleOption ? currentRoleOption.id : ''}
                    id="choose-current-role"
                    onChange={this.updateCurrentRole}
                  >
                    <option value="">--</option>
                    {roleOptions.map(role => (
                      <option key={`role-${role.id}`} value={role.id}>
                        {roleConstantToTitleCaseAllWords(role.type)}
                      </option>
                    ))}
                  </select>
                ) : null}
              </FormGroup>
            </Column>
            <Column xs={12} sm={6}>
              {shouldShowCentrePicker ? (
                <FormGroup block>
                  <label htmlFor="choose-current-centre">Centre</label>
                  <select
                    id="choose-current-centre"
                    onChange={this.updateCurrentCentre}
                  >
                    <option value="">--</option>
                    {centreOptions.map(centre => (
                      <option key={`centre-${centre.name}`} value={centre.id}>
                        {centre.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              ) : null}
            </Column>
          </Row>
        )}
        <ContentBoxFooter>
          <Alert className="helper-text text-align-left">
            <p>
              <IconInfoCircle className="icon-small margin-bottom-small margin-right-base" />
              You either have more than one type of role in ManageAssess or you
              belong to multiple centres. Please choose a role and/or a centre
              to run the session.
            </p>
          </Alert>
        </ContentBoxFooter>
      </ContentBox>
    );
  }

  private updateCurrentRole = ({
    target,
  }: React.FormEvent<HTMLSelectElement>) => {
    const value = (target as HTMLSelectElement).value;
    if (value) {
      this.props.updateCurrentCentreRole({ current_role: value });
    }
  };

  private updateCurrentCentre = ({
    target,
  }: React.FormEvent<HTMLSelectElement>) => {
    const value = (target as HTMLSelectElement).value;
    if (value) {
      this.props.updateCurrentCentreRole({ current_centre: value });
    }
  };
}

function mapStateToProps({
  profile,
  responses,
  currentRoleAndCentreOptions: { roleOptions, centreOptions },
}: IStore) {
  return {
    profile,
    roleOptions: roleOptions || [],
    centreOptions: centreOptions || [],
    hasFailed:
      hasFailed(responses, GET_CURRENT_ROLE_OPTIONS) ||
      hasFailed(responses, GET_CURRENT_CENTRE_OPTIONS) ||
      hasFailed(responses, UPDATE_CURRENT_CENTRE_ROLE),
    isLoading:
      isPending(responses, GET_CURRENT_ROLE_OPTIONS) ||
      isPending(responses, GET_CURRENT_CENTRE_OPTIONS) ||
      isPending(responses, UPDATE_CURRENT_CENTRE_ROLE),
  };
}

export default connect(mapStateToProps, {
  getCentreOptions,
  getRoleOptions,
  updateCurrentCentreRole,
})(CurrentRolePickerPage);
