import { Record } from 'immutable';
import { TRole } from './profile';

export interface IRoleData {
  id: string;
}

export interface IRoleDataRecord
  extends Record.Instance<IRoleData>,
    Readonly<IRoleData> {}

export const RoleDataRecord = Record<IRoleData>({
  id: '',
});

export interface IRoleInput {
  role: TRole | undefined;
  exam_board: IRoleData | undefined;
  centre: IRoleData | undefined;
}

export interface IRole extends IRoleInput {
  key: number;
  exam_board: IRoleDataRecord | undefined;
  centre: IRoleDataRecord | undefined;
}

export interface IRoleRecord extends Record.Instance<IRole>, Readonly<IRole> {}

export const RoleRecordInner = Record<IRole>({
  key: 0,
  role: undefined,
  exam_board: undefined,
  centre: undefined,
});

let roleInt = 0;

export const RoleRecord = (input?: IRoleInput) => {
  const record = RoleRecordInner(input)
    .set('key', roleInt)
    .set(
      'exam_board',
      (input && input.exam_board && RoleDataRecord(input.exam_board)) ||
        undefined
    )
    .set(
      'centre',
      (input && input.centre && RoleDataRecord(input.centre)) || undefined
    );

  roleInt += 1;

  return record;
};

export interface IRoleOption {
  id: string;
  type: string;
}

export interface ICentreOption {
  id: string;
  name: string;
}
