import {
  anyPending,
  hasSucceeded,
} from '@dabapps/redux-api-collections/dist/requests';
import { InputGroup, InputGroupAddon } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  UPDATE_CURRENT_CENTRE,
  updateCurrentCentre,
} from '../../actions/profile';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import {
  DA_ADMIN,
  DA_SUPPORT,
  EXTERNAL_QUALITY_ASSURER,
  userHasRoles,
} from '../../permissions';
import { collectionsModule } from '../../reducers/collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store';
import { ICentre } from '../../store/data-types/centres';
import { IProfile } from '../../store/data-types/profile';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';

const { actions: { getAllCollection } } = collectionsModule;

const { IconBuildingO } = FontAwesome;

const CENTRE_SWITCHER = 'CENTRE_SWITCHER';
interface ICentreSwitcher {
  centres: List<ICentre>;
  errors: FormErrors;
  loading: boolean;
  profile: IProfile;
  getAllCollection(
    type: 'centres',
    options: ICollectionOptions,
    tag: string
  ): void;
  viewCentre(centreId: string): void;
}

function sortByName(a: ICentre, b: ICentre) {
  if (a.osms_data.name < b.osms_data.name) {
    return -1;
  }

  if (b.osms_data.name < a.osms_data.name) {
    return 1;
  }

  return 0;
}

export class CentreSwitcher extends React.PureComponent<ICentreSwitcher, void> {
  public componentWillMount() {
    this.props.getAllCollection('centres', {}, CENTRE_SWITCHER);
  }

  public render() {
    const { centres, errors, loading, profile } = this.props;

    const currentCentreId =
      (profile.current_centre && profile.current_centre.id) || '';
    const orderedCentres = centres.sort(sortByName);
    const singleCentreUser = centres.count() === 1;
    const currentCentre = centres.find(centre => centre.id === currentCentreId);

    if (loading) {
      return (
        <li className="section">
          <div className="centre-switcher">
            <p className="header">Centre</p>
            <p className="info">Loading...</p>
          </div>
        </li>
      );
    }

    return (
      <li className="section">
        <div className="centre-switcher">
          <p className="header">Centre</p>
          {singleCentreUser && currentCentre ? (
            <p className="info">{currentCentre.osms_data.name}</p>
          ) : (
            <InputGroup>
              <InputGroupAddon width={undefined}>
                <IconBuildingO />
              </InputGroupAddon>

              <select
                value={currentCentreId}
                onChange={event => this.props.viewCentre(event.target.value)}
              >
                {userHasRoles(
                  profile,
                  Set.of(EXTERNAL_QUALITY_ASSURER, DA_ADMIN, DA_SUPPORT)
                ) && <option value="">None</option>}
                {orderedCentres.map(centre => (
                  <option key={centre.id} value={centre.id}>
                    {centre.osms_data.name}
                  </option>
                ))}
              </select>

              <FormErrorsRenderer
                formErrors={errors}
                errorKey="non_field_errors"
              />
              <FormErrorsRenderer
                formErrors={errors}
                errorKey="current_centre"
              />
            </InputGroup>
          )}
        </div>
      </li>
    );
  }
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  return {
    centres: getCollectionItems(collectionsOld.centres, CENTRE_SWITCHER),
    errors: getFormErrors(responses, UPDATE_CURRENT_CENTRE),
    // We still want to show loading as we will be refreshing the page
    loading:
      anyPending(
        responses,
        [[GET_COLLECTION, 'centres'], UPDATE_CURRENT_CENTRE]
      ) || hasSucceeded(responses, UPDATE_CURRENT_CENTRE),
    profile,
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  viewCentre: updateCurrentCentre,
})(CentreSwitcher);
