import * as React from 'react';

import { AxiosPromise } from 'axios';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { bulkUpdateStatus, IBulkUpdateStatus } from '../../actions/tasks';
import { IRouteProps } from '../../index';
import { isEQAUser, isIQAUser } from '../../permissions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import { STATUS } from '../../store/data-types/tasks';
import TaskDetailEQA from './task-detail-eqa';
import TaskDetailIQA from './task-detail-iqa';
import TaskDetailTeacher, {
  ITasksFormData,
  TASK_DETAIL_TEACHER,
} from './task-detail-teacher';

const { actions: { loadItem } } = itemsModule;

interface IProps extends IRouteProps {
  profile: IProfile;
  bulkUpdateStatus(tasks: List<IBulkUpdateStatus>): AxiosPromise;
  loadItem(type: keyof IItemsState, itemId: string): void;
  destroy(...formNames: string[]): void;
}

export class TaskDetailTeacherPage extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.handInTasks = this.handInTasks.bind(this);
    this.loadItems = this.loadItems.bind(this);
  }

  public componentWillMount() {
    this.loadItems();
  }

  public render() {
    if (isIQAUser(this.props.profile)) {
      return <TaskDetailIQA {...this.props} />;
    } else if (isEQAUser(this.props.profile)) {
      return <TaskDetailEQA {...this.props} />;
    } else {
      return <TaskDetailTeacher {...this.props} onSubmit={this.handInTasks} />;
    }
  }

  private loadItems() {
    this.props.loadItem('tasks/class', this.props.params.id);
  }

  private handInTasks(data: ITasksFormData) {
    const transFormedTasks = Map<string, boolean>(data)
      .filter(isChecked => isChecked)
      .keySeq()
      .map(each => ({ id: each, status: STATUS.AWAITING_MARKING }))
      .toList();
    this.props.bulkUpdateStatus(transFormedTasks).then(() => {
      this.loadItems();
      this.props.destroy(TASK_DETAIL_TEACHER);
    });
  }
}
export default connect(
  (store: IStore, props: IRouteProps) => {
    return { ...props, profile: store.profile };
  },
  {
    loadItem,
    destroy,
    bulkUpdateStatus,
  }
)(TaskDetailTeacherPage);
