import * as React from 'react';
import { submit } from 'redux-form';

import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Set } from 'immutable';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { TActionAny, Thunk } from '../../../requests/types';
const { IconInfoCircle } = FontAwesome;
import { closeModal } from '../../../actions/modals';
import { TAG_ASSETS } from '../../../actions/tag-assets';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../../permissions';
import { IStore } from '../../../store';
import { IProfile, ISimpleUser } from '../../../store/data-types/profile';
import { makeUIStateHandler } from '../../../ui-state';
import FileUpload, { TAfterSuccessfulUpload } from '../../files/file-upload';
import ModalCloseIcon from '../../modals/modal-close-icon';
import { TAG_FILES_FORM } from '../tag-files/tag-files-form';
import Complete from './complete';
import SelectStudent from './select-student';
import TagFiles from './tag-files';
import UploadFiles from './upload-files';

export enum CurrentPage {
  SelectStudent,
  UploadFiles,
  TagFiles,
  Complete,
}

const FILES_WIZARD_PAGE_HANDLER = 'FILES_WIZARD_PAGE_HANDLER';
const FILES_WIZARD_CURRENT_USER = 'FILES_WIZARD_CURRENT_USER';
const FILES_WIZARD_ASSET_IDS = 'FILES_WIZARD_ASSET_IDS';

const filesWizardPageHandler = makeUIStateHandler(
  FILES_WIZARD_PAGE_HANDLER,
  CurrentPage.SelectStudent
);
const filesWizardCurrentUser = makeUIStateHandler<ISimpleUser | null>(
  FILES_WIZARD_CURRENT_USER,
  null
);
const filesWizardAssetIdsHandler = makeUIStateHandler<List<string>>(
  FILES_WIZARD_ASSET_IDS,
  List()
);

interface IExternalProps {
  info?: string;
  initialUser?: ISimpleUser;
  filesUploadedCallback?(): void;
  filesTaggedCallback?(): void;
}

interface IConnectedProps {
  currentPage: CurrentPage;
  currentUser: ISimpleUser | null;
  profile: IProfile;
  assetIds: List<string>;
  isLoading: boolean;
}

interface IDispatchProps {
  clearCurrentPage: typeof filesWizardPageHandler.clearState;
  setCurrentPage: typeof filesWizardPageHandler.setState;
  clearCurrentUser: typeof filesWizardCurrentUser.clearState;
  setCurrentUser: typeof filesWizardCurrentUser.setState;
  setAssetIds: typeof filesWizardAssetIdsHandler.setState;
  clearAssetIds: typeof filesWizardAssetIdsHandler.clearState;
  closeModal(): void;
  submitTagFilesForm(): void;
}

type IProps = IExternalProps & IConnectedProps & IDispatchProps;

export class FilesWizardModal extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.onSelectStudent = this.onSelectStudent.bind(this);
    this.onUploadFiles = this.onUploadFiles.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFilesTagged = this.onFilesTagged.bind(this);
  }

  public render() {
    return (
      <Modal large onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          {this.renderTitle()}
        </ModalHeader>
        {this.props.info && (
          <p>
            <IconInfoCircle className="icon-small" />
            {this.props.info}
          </p>
        )}
        {this.getCurrentPage()}
        {this.renderFooter()}
      </Modal>
    );
  }

  public componentDidMount() {
    const { initialUser, profile } = this.props;
    this.props.clearCurrentPage();
    this.props.clearCurrentUser();

    if (
      !userHasRoles(
        profile,
        Set.of(TEACHER, CENTRE_ADMIN, DA_ADMIN, INTERNAL_QUALITY_ASSURER)
      )
    ) {
      // Students don't select a user, as they can only upload for themselves
      this.onSelectStudent(profile);
    } else if (initialUser) {
      // Sometimes we're opened with the context of a specific user
      this.onSelectStudent(initialUser);
    }
  }

  private renderTitle() {
    const { currentUser } = this.props;
    return currentUser ? (
      <p>Upload new files for {currentUser.osms_data.name || currentUser.id}</p>
    ) : (
      <p>Upload new files</p>
    );
  }

  private renderFooter() {
    return (
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          {this.props.currentPage === CurrentPage.TagFiles && (
            <Button
              className="primary"
              onClick={this.onSubmit}
              disabled={this.props.isLoading}
            >
              Submit
            </Button>
          )}
          <Button className="primary" onClick={this.props.closeModal}>
            {this.props.currentPage === CurrentPage.Complete
              ? 'Done'
              : 'Cancel'}
          </Button>
        </SpacedGroup>
      </ModalFooter>
    );
  }

  private getCurrentPage() {
    const { currentPage, profile, currentUser, assetIds } = this.props;
    switch (currentPage) {
      case CurrentPage.SelectStudent:
        return (
          <SelectStudent
            centre={profile.current_centre && profile.current_centre.id}
            onSelect={this.onSelectStudent}
          />
        );
      case CurrentPage.UploadFiles:
        if (!currentUser) {
          throw new Error('Cannot open UploadFiles without a currentUser');
        }
        return (
          <UploadFiles
            ownerUser={currentUser.id}
            afterSuccessfulUpload={this.onUploadFiles}
          />
        );
      case CurrentPage.TagFiles:
        if (!currentUser) {
          throw new Error('Cannot open TagFiles without a currentUser');
        }
        return (
          <TagFiles
            assetIds={assetIds}
            isLearner={userHasRoles(profile, Set.of(STUDENT))}
            afterSuccessfulTag={this.onFilesTagged}
            currentUser={currentUser}
          />
        );
      case CurrentPage.Complete:
        return <Complete />;
      default:
        throw new Error('Invalid page specified in FilesWizardModal');
    }
  }

  private onSelectStudent(student: ISimpleUser) {
    this.props.clearAssetIds();
    this.props.setCurrentPage(CurrentPage.UploadFiles);
    this.props.setCurrentUser(student);
  }

  private onUploadFiles(files: List<string>) {
    if (this.props.filesUploadedCallback) {
      this.props.filesUploadedCallback();
    }
    this.props.setAssetIds(files);
    this.props.setCurrentPage(CurrentPage.TagFiles);
  }

  private onSubmit() {
    this.props.submitTagFilesForm();
  }

  private onFilesTagged() {
    if (this.props.filesTaggedCallback) {
      this.props.filesTaggedCallback();
    }
    this.props.setCurrentPage(CurrentPage.Complete);
  }
}

function mapStateToProps(
  store: IStore,
  props: IExternalProps
): IExternalProps & IConnectedProps {
  return {
    ...props,
    currentPage: filesWizardPageHandler.getValue(store.uiState),
    currentUser: filesWizardCurrentUser.getValue(store.uiState),
    profile: store.profile,
    assetIds: filesWizardAssetIdsHandler.getValue(store.uiState),
    isLoading: isPending(store.responses, TAG_ASSETS),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  clearCurrentPage: filesWizardPageHandler.clearState,
  setCurrentPage: filesWizardPageHandler.setState,
  clearCurrentUser: filesWizardCurrentUser.clearState,
  setCurrentUser: filesWizardCurrentUser.setState,
  setAssetIds: filesWizardAssetIdsHandler.setState,
  clearAssetIds: filesWizardAssetIdsHandler.clearState,
  submitTagFilesForm: submit.bind(null, TAG_FILES_FORM),
})(FilesWizardModal);
