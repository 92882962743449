import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { getCollectionItems } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { IClassRecord } from '../../store/data-types/classes';
import { IQualificationRecord } from '../../store/data-types/qualifications';

const { actions: { getAllCollection } } = collectionsModule;

interface IExternalProps {
  class: IClassRecord;
}

interface IProps extends IExternalProps {
  qualifications: List<IQualificationRecord>;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
}

function getQualification(
  qualifications: List<IQualificationRecord>,
  myClass: IClassRecord
) {
  const matchingQualification = qualifications.find(
    qual => qual.id === myClass.qualification
  );

  if (!matchingQualification) {
    return null;
  }

  return matchingQualification.name;
}

export class ClassDetailInfo extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.getAllCollection('courses', {});
  }

  public render() {
    return (
      <h3>
        Qualification -{' '}
        {getQualification(this.props.qualifications, this.props.class)}
      </h3>
    );
  }
}

function mapStateToProps(
  { collectionsOld }: IStore,
  { class: myClass }: IExternalProps
) {
  return {
    class: myClass,
    qualifications:
      getCollectionItems(collectionsOld.courses) ||
      List<IQualificationRecord>(),
  };
}

export default connect(mapStateToProps, { getAllCollection })(ClassDetailInfo);
