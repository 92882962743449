import {
  anyPending,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import {
  CREATE_QUALIFICATION,
  createQualification,
  ICreateQualificationData,
} from '../../../actions/qualifications';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import { LOAD_ITEM } from '../../../items/actions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IOptionRecord } from '../../../store/data-types';
import { IClassRecord } from '../../../store/data-types/classes';
import { FormErrors } from '../../../utils';
import Loading from '../../loading';
import CreateQualificationForm from './create-qualification-form';

const { actions: { loadItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;

interface IQualificationData {
  start_date?: string;
  end_date?: string;
  classes?: Set<string>;
}

interface ICreateQualificationProps {
  classes: List<IClassRecord>;
  formErrors: FormErrors | undefined;
  templateQualificationId: string;
  templateQualification: IOptionRecord | null;
  loading: boolean;
  creatingOrCreated: boolean;
  createQualification(data: ICreateQualificationData): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions
  ): void;
  loadItem(type: keyof IItemsState, id: string): void;
}
export class CreateQualification extends React.PureComponent<
  ICreateQualificationProps,
  void
> {
  public componentWillMount() {
    const { templateQualificationId } = this.props;
    this.props.loadItem(
      'courses/qualification-options',
      templateQualificationId
    );
    const filters = Map({ no_qualification: 'true' });
    this.props.getAllCollection('classes', { filters });
  }

  public render() {
    const {
      classes,
      creatingOrCreated,
      formErrors,
      templateQualification,
      loading,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!templateQualification) {
      return (
        <Alert className="error">
          <p>Failed to load Qualification</p>
        </Alert>
      );
    }

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            Create {templateQualification.title}
          </h2>
        </ContentBoxHeader>
        <CreateQualificationForm
          classes={classes}
          loading={creatingOrCreated}
          formErrors={formErrors}
          onSubmit={this.createQualification.bind(this)}
        />
      </ContentBox>
    );
  }

  private createQualification(data: IQualificationData) {
    const { templateQualificationId } = this.props;
    const dataToSubmit: ICreateQualificationData = _.assign({}, data, {
      classes: data.classes ? data.classes.toJS() : [],
      option: templateQualificationId,
    });
    this.props.createQualification(dataToSubmit);
  }
}

interface IRouteProps {
  params: { id: string };
}
function mapStateToProps(
  { collectionsOld, itemsOld, responses }: IStore,
  props: IRouteProps
) {
  return {
    classes: getCollectionItems(collectionsOld.classes) || List(),
    creatingOrCreated:
      isPending(responses, CREATE_QUALIFICATION) ||
      hasSucceeded(responses, CREATE_QUALIFICATION),
    formErrors: getFormErrors(responses, CREATE_QUALIFICATION),
    loading: anyPending(responses, [
      [LOAD_ITEM, 'courses/qualification-options'],
      [GET_COLLECTION, 'classes'],
    ]),
    templateQualification: itemsOld.get('courses/qualification-options'),
    templateQualificationId: props.params.id,
  };
}

export default connect(mapStateToProps, {
  createQualification,
  getAllCollection,
  loadItem,
})(CreateQualification);
