import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { AxiosResponse } from 'axios';
import { List, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { getAssets, IGetAssetsOptions } from '../../actions';
import {
  ALL_ADMIN_USERS,
  ALL_CENTRE_STAFF_USERS,
  CENTRE_ADMIN,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../permissions';
import { IStore } from '../../store/';
import { IAssetRecord } from '../../store/data-types';
import { IProfile } from '../../store/data-types/profile';
import IfHasRoles from '../permissions/if-has-roles';
import Assets from './assets';
import QuotaList from './quota-list';
import QuotaUsage from './quota-usage';

interface IProps {
  profile: IProfile;
  assets: List<IAssetRecord>;
  getAssets(
    options: IGetAssetsOptions,
    tag?: string,
    shouldAppend?: boolean
  ): void;
}

export class FilesPage extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.loadAssets();
  }

  public render() {
    const { profile, assets } = this.props;

    const usage = assets.reduce((memo, asset) => memo + asset.num_bytes, 0);

    return (
      <div>
        <IfHasRoles
          roles={Set()
            .concat(ALL_CENTRE_STAFF_USERS)
            .concat(ALL_ADMIN_USERS)}
        >
          <QuotaList />
        </IfHasRoles>
        <IfHasRoles roles={Set.of(STUDENT, TEACHER, CENTRE_ADMIN)}>
          <ContentBox>
            <ContentBoxHeader>
              <IfHasRoles roles={Set.of(STUDENT)}>
                <QuotaUsage
                  className="float-right margin-vertical-small"
                  usage={usage}
                  quotaBytes={profile.quota_bytes}
                />
              </IfHasRoles>
              <h2 className="font-size-large">Files</h2>
            </ContentBoxHeader>
            <Assets loadAssets={this.loadAssets} />
          </ContentBox>
        </IfHasRoles>
      </div>
    );
  }

  private loadAssets = (page: number = 1, shouldAppend: boolean = false) => {
    // Load the assets list if we are a student - teachers don't see the list on this page.
    // Ensure students only see the files they uploaded themselves.
    if (userHasRoles(this.props.profile, Set.of(STUDENT))) {
      this.props.getAssets(
        {
          owner__id: this.props.profile.id,
          created_by__id: this.props.profile.id,
          page,
        },
        undefined,
        shouldAppend
      );
    }
  };
}

function mapStateToProps({ assets, profile }: IStore) {
  return {
    assets,
    profile,
  };
}

export default connect(mapStateToProps, { getAssets })(FilesPage);
