import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { canManageQualifications } from '../../../permissions';
import { IStore } from '../../../store';
import { IProfile } from '../../../store/data-types/profile';
import Term from '../../terminology';
import SubscriptionBankList from './subscription-bank-list';

interface IProps {
  profile: IProfile;
}

export function SubscriptionBankPage({ profile }: IProps) {
  if (!canManageQualifications(profile)) {
    return (
      <Alert className="error">
        <p>Sorry you are not allowed to see this page</p>
      </Alert>
    );
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <h2 className="font-size-large">Subscription Bank</h2>
        <p className="meta">
          See your subscriptions, create new qualifications for your centre and
          assign them to <Term>Classes</Term>.
        </p>
      </ContentBoxHeader>
      <SubscriptionBankList />
    </ContentBox>
  );
}

function mapStateToProps({ profile }: IStore) {
  return {
    profile,
  };
}

export default connect(mapStateToProps)(SubscriptionBankPage);
