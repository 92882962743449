import { Alert, Column, ContentBox, ContentBoxHeader, Row } from '@dabapps/roe';
import * as React from 'react';
import { APP_NAME } from '../constants';

interface ILoggedOut {
  isInactiveUser?: boolean;
}
export default class LoggedOut extends React.PureComponent<ILoggedOut, void> {
  public render() {
    return (
      <div>
        <Row>
          <Column>
            <ContentBox>
              <ContentBoxHeader>
                <h2 className="font-size-large">You are logged out</h2>
              </ContentBoxHeader>

              {this.props.isInactiveUser ? (
                <Alert className="error">
                  <p>
                    You must belong to a centre with an active subscription to
                    access {APP_NAME}.
                  </p>
                  <p>Please try again later or contact your administrator.</p>
                </Alert>
              ) : (
                <p>You are logged out - log in to use {APP_NAME}.</p>
              )}
              <p>
                <a href="/accounts/login/" className="button primary">
                  Log in
                </a>
              </p>
            </ContentBox>
          </Column>
        </Row>
      </div>
    );
  }
}
