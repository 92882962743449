import { List, Set } from 'immutable';
import * as React from 'react';
import { DataShape, FormProps, reduxForm } from 'redux-form';
import {
  // tslint:disable-next-line:no-unused-variable
  IClassRecord,
} from '../../../store/data-types/classes';
import {
  // tslint:disable-next-line:no-unused-variable
  IProfile,
} from '../../../store/data-types/profile';
import GroupField from '../../forms/group-field';
import { terminologyFromProfile } from '../../terminology';
import { SelectClasses } from '../subscription-bank/create-qualification-form';

export const ASSIGN_QUALIFICATION_FORM = 'ASSIGN_QUALIFICATION_FORM';
interface IAssignQualificationFormProps
  extends React.HTMLProps<JSX.Element>,
    FormProps<DataShape, void, void> {
  classes: List<IClassRecord>;
  profile: IProfile;
}
export function AssignQualificationForm({
  children,
  classes,
  handleSubmit,
  profile,
}: IAssignQualificationFormProps) {
  return (
    <form onSubmit={handleSubmit}>
      <GroupField
        name="selectedClasses"
        type="text"
        label={terminologyFromProfile(profile, 'Classes')}
        component={field => <SelectClasses {...field} classes={classes} />}
      />
      {children}
    </form>
  );
}

export interface IAssignQualificationFormData {
  selectedClasses?: Set<string>;
}

export default reduxForm({ form: ASSIGN_QUALIFICATION_FORM })(
  AssignQualificationForm
);
