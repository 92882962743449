import { List } from 'immutable';
import * as React from 'react';
import { Link } from 'react-router';
import { IProfile } from '../../store/data-types/profile';
import { IUserGradeListTaskRecord } from '../../store/data-types/tasks';
import { formatShortDate } from '../../utils';
import SimpleTable, { IColumnData } from '../tables/simple-table';
import ExamResultsIndicator from '../tasks/exam-results-indicator';
import GradeIndicator from '../tasks/grade-indicator';
import StatusIndicator from '../tasks/status-indicator';
import { default as Term, terminologyFromProfile } from '../terminology';

interface IProps {
  tasks: List<IUserGradeListTaskRecord>;
  loading: boolean;
  componentName: string;
  qualificationId: string;
  profile: IProfile;
  studentId?: string;
}

export default class TasksStudents extends React.PureComponent<IProps, void> {
  public render() {
    const { qualificationId, loading, tasks, studentId } = this.props;
    const baseUrl = studentId ? `/users/student/${studentId}` : '';
    const allTasksPath = `${baseUrl}/tasks/?qualification=${qualificationId}`;
    return (
      <div className="task-table">
        <h3>
          Next {tasks.count()} <Term>Tasks</Term>
        </h3>
        <SimpleTable
          loading={loading}
          items={tasks}
          headers={this.getHeaders(baseUrl)}
        />
        <Link className="button margin-top-large" to={allTasksPath}>
          View all <Term>Tasks</Term>
        </Link>
      </div>
    );
  }

  private getHeaders(
    baseUrl: string
  ): Array<IColumnData<IUserGradeListTaskRecord>> {
    const { profile } = this.props;
    return [
      {
        content: data => (
          <Link to={`${baseUrl}/tasks/marksheet/${data.id}/`}>
            {data.class_task.component_title}
          </Link>
        ),
        headerLabel: `${terminologyFromProfile(profile, 'Task')} Name`,
        key: 'name',
        sortable: false,
      },
      {
        content: data =>
          `due ${data.class_task.end_date
            ? formatShortDate(data.class_task.end_date)
            : 'unknown'}`,
        headerLabel: 'Due Date',
        key: 'due_date',
        sortable: false,
      },
      {
        content: data => `${data.tagged_asset_count} Files`,
        headerLabel: 'Files',
        key: 'files',
        sortable: false,
      },
      {
        content: data => (
          <StatusIndicator task={data}>
            <GradeIndicator grade={data.grade} prefix=": " />
            <ExamResultsIndicator examResults={data.exam_results} prefix=" " />
          </StatusIndicator>
        ),
        headerLabel: 'Status',
        key: 'status',
        sortable: false,
      },
      {
        content: data => <Link to={`/tasks/marksheet/${data.id}/`}>View</Link>,
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  }
}
