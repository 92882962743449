import {
  Collection,
  CollectionOptions,
} from '@dabapps/redux-api-collections/dist/collections';
import { IdKeyed } from '@dabapps/redux-api-collections/dist/utils';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import * as classNames from 'classnames';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';

import Pagination from '../../../components/pagination';
import Loading from '../../loading';
import { IBaseTableProps, IColumnData } from '../simple-table';
import SortIcon from '../sort-icon';

const { IconSort, IconSortAsc, IconSortDesc } = FontAwesome;

interface IProps<TData extends IdKeyed> extends IBaseTableProps<TData> {
  className?: string;
  pageSize: number;
  preventInitialRequest?: boolean;

  initializeCollection(): void;
  onPaginationClick(nextPage: number): void;
  sortBy(key: string): void;
  getResults(): {
    map<T>(callback: (item: TData) => T): ReadonlyArray<T> | List<T>;
  };
  getCount(): number;
  getPage(): number;
  getOrdering(): string | undefined;
  getReverseOrdering(): boolean | undefined;
}

export default class CollectionTableBase<
  TData extends { id: string }
> extends React.PureComponent<IProps<TData>, void> {
  public constructor(props: IProps<TData>) {
    super(props);

    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  public componentWillMount() {
    if (!this.props.preventInitialRequest) {
      this.props.initializeCollection();
    }
  }

  public onPaginationClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    nextPage: number
  ) => {
    event.preventDefault();
    this.props.onPaginationClick(nextPage);
  };

  public sortBy(key: string) {
    this.props.sortBy(key);
  }

  public render() {
    const {
      loading,
      headers,
      fixRowHeaders,
      getResults,
      getCount,
      getPage,
      pageSize,
      className,
    } = this.props;
    const firstColumnWidth = headers[0].width || 100;
    const tableProps = fixRowHeaders
      ? {
          fixRowHeaders,
          rowHeaderWidth: firstColumnWidth,
        }
      : {};
    const results = getResults();
    const count = getCount();
    return (
      <Row>
        <Table
          className={classNames('class-table', className || null)}
          fill
          {...tableProps}
        >
          {!loading && this.renderHeaders()}
          <TableBody>
            {loading ? (
              <tr>
                <td>
                  <Loading />
                </td>
              </tr>
            ) : count ? (
              results.map(collectionItem => {
                const first = _.first(headers);
                const rest = _.rest(headers);
                if (!first) {
                  return null;
                }

                return (
                  <TableRow key={collectionItem.id}>
                    <TableHeader width={first.width}>
                      {first.content(collectionItem)}
                    </TableHeader>
                    {rest.map(header => (
                      <TableCell key={header.headerLabel} width={header.width}>
                        {header.content(collectionItem)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <p className="info text-align-center">Nothing to display.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          count={count}
          pageSize={pageSize}
          currentPage={getPage()}
          onClick={this.onPaginationClick}
        />
      </Row>
    );
  }

  private renderHeaders() {
    const { headers, getOrdering, getReverseOrdering } = this.props;

    return (
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableHeader key={header.headerLabel} width={header.width}>
              {header.headerLabel}
              {header.sortable && (
                <SortIcon
                  orderingKey={header.key}
                  ordering={getOrdering()}
                  reverseOrdering={getReverseOrdering()}
                  onClick={this.sortBy.bind(this, header.key)}
                />
              )}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}
