import { FormGroup } from '@dabapps/roe';
import { Map } from 'immutable';
import * as React from 'react';
import { ICollection, ICollectionOptions } from '../../collections/types';

interface IOption {
  key: string;
  label: string;
}

interface IMappable<T> {
  map<U>(mapper: (value: T) => U): IMappable<U>;
}

interface IProps<T> {
  name: string;
  label: string | JSX.Element;
  disabled?: boolean;
  placeholder?: string;
  filterKey: string;
  options: IMappable<IOption>;
  collection: ICollection<T>;
  pageSize: number;
  excludeNull?: boolean;
  onChange?: (value: string) => void;
  getCollection(options: ICollectionOptions): void;
}

export default class CollectionSelectFilter<T> extends React.PureComponent<
  IProps<T>,
  void
> {
  public render() {
    const {
      collection,
      name,
      filterKey,
      label,
      excludeNull,
      options,
      placeholder,
      disabled,
    } = this.props;
    const filters = collection.filters || Map<string, string>();
    const value = filters.get(filterKey) || '';

    return (
      <FormGroup block>
        <label>{label}</label>

        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            this.onChangeFilters(event.target.value)}
        >
          {!excludeNull ? (
            <option value="">{value ? '' : placeholder}</option>
          ) : null}
          {options.map(({ key, label: optionLabel }: IOption) => (
            <option key={key} value={key}>
              {optionLabel}
            </option>
          ))}
        </select>
      </FormGroup>
    );
  }

  private onChangeFilters(value: string) {
    const filters = (this.props.collection.filters || Map<string, string>()
    ).set(this.props.filterKey, value);

    const options = {
      filters,
      page: 1,
      pageSize: this.props.pageSize,
    };

    this.props.getCollection(options);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
}
