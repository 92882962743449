import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { DispatchCallback, TActionAny } from '../../requests/types';
import { IStore } from '../../store';
import { IJournalEntry } from '../../store/data-types/journals';
import Loading from '../loading';
import Term from '../terminology';

const { actions: { getCollection } } = collectionsModule;

const { IconFileTextO } = FontAwesome;

export const CLASS_DETAIL_BLOG = 'CLASS_DETAIL_BLOG';
export const ENTRY_COUNT = 2;

interface IClassDetailBlogProps {
  classEntries: List<IJournalEntry> | undefined;
  classId: string;
  loading: boolean;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
}

export class ClassDetailBlog extends React.PureComponent<
  IClassDetailBlogProps,
  void
> {
  public componentWillMount() {
    const { classId } = this.props;
    const filters = Map({ class_id: classId });
    const options = { filters, pageSize: ENTRY_COUNT, page: 1 };
    this.props.getCollection('journals', options, CLASS_DETAIL_BLOG);
  }

  public render() {
    const { classEntries, loading, classId } = this.props;
    return loading ? (
      <Loading />
    ) : (
      <div className="class-detail-blog">
        <h3>
          <Term>Class</Term> Journal
        </h3>
        {classEntries && classEntries.count() ? (
          <ul>
            {classEntries.map(each => (
              <li key={each.id}>
                <IconFileTextO className="icon-small" />
                <Link to={`/journal/${each.id}/`}>{each.title}</Link>
              </li>
            ))}
            <li>
              <Link to={`/journal/class/${classId}/`}>
                <strong>more posts...</strong>
              </Link>
            </li>
          </ul>
        ) : (
          <p>
            There are no <Term>Class</Term> journal entries yet.
          </p>
        )}
      </div>
    );
  }
}

interface IExternalProps {
  classId: string;
}
function mapStateToProps(
  { collectionsOld, responses }: IStore,
  props: IExternalProps
) {
  return _.extend({}, props, {
    classEntries: getCollectionItems(
      collectionsOld.journals,
      CLASS_DETAIL_BLOG
    ),
    loading: isPending(responses, GET_COLLECTION, 'journals'),
  });
}

export default connect(mapStateToProps, { getCollection })(ClassDetailBlog);
