import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';

import { default as LandingForm, IFormData } from './landing-form';

import QuotaUsage from '../../components/files/quota-usage';
import { IStore } from '../../store/';
import { IAssetRecord } from '../../store/data-types';
import { IProfile } from '../../store/data-types/profile';

import { getAssets, IGetAssetsOptions } from '../../actions';

interface IProps {
  profile: IProfile;
  assets: List<IAssetRecord>;
  getAssets(options: IGetAssetsOptions, tag?: string): void;
}

export class ProfileLanding extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.getAssets({ owner__id: this.props.profile.id });
  }

  public render() {
    const { profile, assets } = this.props;

    let usage = 0;
    assets.forEach((asset: IAssetRecord) => {
      usage += asset.num_bytes;
    });
    return (
      <div className="margin-vertical-large">
        <QuotaUsage usage={usage} quotaBytes={profile.quota_bytes} />
      </div>
    );
  }
}

function mapStateToProps(
  { assets, profile }: IStore,
  existing: Partial<IProps>
) {
  return _.extend({}, existing, {
    assets,
    profile,
  });
}

export default connect(mapStateToProps, { getAssets })(ProfileLanding);
