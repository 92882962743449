import { Dict, RecordWithConstructor } from '@dabapps/simple-records';
import * as moment from 'moment';

export type TBulkUploadResponseErrors = Dict<string>;

interface IBulkUploadShared {
  csv_first_rows: [
    ReadonlyArray<string>,
    ReadonlyArray<string>,
    ReadonlyArray<string>
  ];
  csv_first_row_contains_headers: boolean;
  filename: string;
  headings_map: Dict<string>;
  id: string;
  num_bytes: number;
  state:
    | 'UPLOAD'
    | 'ANALYSING_HEADERS'
    | 'ASSIGN_HEADERS'
    | 'CONFIRM_PROCESS'
    | 'FIX_DATA_ISSUES'
    | 'PROCESS'
    | 'PROCESSED';
  processing_errors: TBulkUploadResponseErrors;
  required_fields: ReadonlyArray<string>;
  optional_fields: ReadonlyArray<string>;
}
export type IBulkUploadInput = Readonly<
  IBulkUploadShared & {
    created: string | Date;
    modified: string | Date;
  }
>;
export type IBulkUpload = Readonly<
  IBulkUploadShared & {
    created: moment.Moment;
    modified: moment.Moment;
  }
>;

export const BulkUploadRecord = RecordWithConstructor<
  IBulkUploadInput,
  IBulkUpload
>(
  {
    created: new Date(),
    csv_first_row_contains_headers: false,
    csv_first_rows: [[], [], []],
    filename: '',
    headings_map: {},
    id: '',
    modified: new Date(),
    num_bytes: 0,
    optional_fields: [],
    processing_errors: {},
    required_fields: [],
    state: 'UPLOAD',
  },
  input => {
    return {
      ...input,
      created: moment.utc(input.created),
      modified: moment.utc(input.modified),
    };
  }
);
