import {
  anyPending,
  AsyncActionSet,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ContentBox,
  ContentBoxHeader,
  Section,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { openModal } from '../../../actions/modals';
import { ASSIGN_UNIT, UPDATE_UNIT } from '../../../actions/qualifications';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import { IRouteProps } from '../../../index';
import { LOAD_ITEM } from '../../../items/actions';
import { canManageQualifications } from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import { IOptionRecord } from '../../../store/data-types';
import { IClassRecord } from '../../../store/data-types/classes';
import { IProfile } from '../../../store/data-types/profile';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import { IUnitManagementRecord } from '../../../store/data-types/tasks';
import { formatDate } from '../../../utils';
import Loading from '../../loading';
import SimpleTable, { IColumnData } from '../../tables/simple-table';
import AssignUnitModal from './assign-unit-modal';
import ManageUnitModal from './manage-unit-modal';

const { actions: { loadItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;

const { IconExclamationTriangle } = FontAwesome;

export const CLASS_QUALIFICATION = 'CLASS_QUALIFICATION';

interface IBaseProps {
  loading: boolean;
  qualificationClass: IClassRecord | null;
  openModal(component: React.ReactNode): void;
}
interface IAssignedUnits extends IBaseProps {
  assignedUnits: List<IUnitManagementRecord>;
  qualification: IQualificationRecord;
  qualificationClass: IClassRecord;
  onAfterUpdate(): void;
}
export function AssignedUnits(props: IAssignedUnits) {
  const {
    loading,
    qualification,
    assignedUnits,
    qualificationClass,
    onAfterUpdate,
  } = props;
  const assignedUnitHeaders: Array<IColumnData<IUnitManagementRecord>> = [
    {
      content: data => data.title,
      headerLabel: 'Name',
      key: 'name',
      sortable: true,
    },
    {
      content: data => (data.is_mandatory ? 'Mandatory' : 'Optional'),
      headerLabel: 'Mandatory Unit?',
      key: 'is_mandatory',
      sortable: true,
    },
    {
      content: data => (
        <Button
          onClick={() =>
            props.openModal(
              <ManageUnitModal
                unit={data}
                taskClass={qualificationClass}
                qualification={qualification}
                onAfterUpdate={onAfterUpdate}
              />
            )}
        >
          Manage
        </Button>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];

  return (
    <Section>
      <h5>Assigned Units</h5>
      <SimpleTable
        headers={assignedUnitHeaders}
        items={assignedUnits}
        loading={loading}
      />
    </Section>
  );
}

interface IUnassignedUnits extends IBaseProps {
  unassignedUnits: List<IUnitManagementRecord>;
  qualificationClass: IClassRecord;
  qualification: IQualificationRecord;
  onAfterAssign(): void;
}
export function UnassignedUnits(props: IUnassignedUnits) {
  const {
    qualification,
    loading,
    unassignedUnits,
    qualificationClass,
    onAfterAssign,
  } = props;
  const unassignedUnitHeaders: Array<IColumnData<IUnitManagementRecord>> = [
    {
      content: data => data.title,
      headerLabel: 'Name',
      key: 'name',
      sortable: true,
    },
    {
      content: data => (
        <Button
          onClick={() =>
            props.openModal(
              <AssignUnitModal
                unit={data}
                taskClass={qualificationClass}
                qualification={qualification}
                onAfterAssign={onAfterAssign}
              />
            )}
        >
          Assign
        </Button>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
  return (
    <Section>
      <h5>Unassigned Units</h5>
      <SimpleTable
        headers={unassignedUnitHeaders}
        items={unassignedUnits}
        loading={loading}
      />
    </Section>
  );
}

interface IClassQualificationPageProps extends IBaseProps {
  classId: string;
  profile: IProfile;
  qualificationId: string;
  qualification: IQualificationRecord | null;
  unitsLoading: boolean;
  assignedSuccessfully: boolean;
  updatedSuccessfully: boolean;
  assignedUnits: List<IUnitManagementRecord>;
  unassignedUnits: List<IUnitManagementRecord>;
  loadItem(type: keyof IItemsState, id: string): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  resetRequestState(action: AsyncActionSet, tag?: string): void;
}
export class ClassQualificationPage extends React.PureComponent<
  IClassQualificationPageProps,
  void
> {
  public constructor(props: IClassQualificationPageProps) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.loadUnits = this.loadUnits.bind(this);
  }

  public componentWillMount() {
    const { classId, qualificationId } = this.props;
    this.props.loadItem('classes', classId);
    this.props.loadItem('courses', qualificationId);
    this.loadUnits();
  }

  public render() {
    const {
      loading,
      profile,
      qualification,
      qualificationClass,
      assignedUnits,
      unassignedUnits,
      unitsLoading,
      assignedSuccessfully,
      updatedSuccessfully,
    } = this.props;
    const option = qualification && narrowToRecord(qualification.option);

    if (!canManageQualifications(profile)) {
      return (
        <Alert className="error">
          <p>Sorry you are not allowed to see this page</p>
        </Alert>
      );
    }

    if (loading) {
      return <Loading />;
    }

    if (!qualification || !qualificationClass) {
      return (
        <Alert className="error">
          <p>Failed to load.</p>
        </Alert>
      );
    }

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h3>
            {qualification.name} for {qualificationClass.name}
          </h3>
          <p className="info">
            {formatDate(qualification.start_date)} -{' '}
            {formatDate(qualification.end_date)}
          </p>
          {option &&
            option.complex_roc && (
              <p className="font-size-small">
                <IconExclamationTriangle className="icon-small" />
                ROC not automatically calculated for this qualification
              </p>
            )}
          {option && option.description && <p>{option.description}</p>}
        </ContentBoxHeader>
        {updatedSuccessfully && (
          <Alert className="success">
            <p>Unit updated successfully.</p>
          </Alert>
        )}
        {assignedSuccessfully && (
          <Alert className="success">
            <p>Unit assigned successfully.</p>
          </Alert>
        )}
        <AssignedUnits
          loading={unitsLoading}
          assignedUnits={assignedUnits}
          qualificationClass={qualificationClass}
          qualification={qualification}
          openModal={this.openModal}
          onAfterUpdate={this.loadUnits}
        />
        <UnassignedUnits
          loading={unitsLoading}
          unassignedUnits={unassignedUnits}
          qualificationClass={qualificationClass}
          qualification={qualification}
          openModal={this.openModal}
          onAfterAssign={this.loadUnits}
        />
      </ContentBox>
    );
  }

  private openModal(component: React.ReactNode) {
    this.props.resetRequestState(UPDATE_UNIT);
    this.props.resetRequestState(ASSIGN_UNIT);
    return this.props.openModal(component);
  }

  private loadUnits() {
    const { qualificationId, classId } = this.props;
    return this.props.getAllCollection(
      'courses/unit-management',
      { filters: Map({ qualification: qualificationId, task_class: classId }) },
      CLASS_QUALIFICATION
    );
  }
}

interface IClassQualificationPageRouteProps extends IRouteProps {
  params: {
    id: string;
    classid: string;
  };
}
export function mapStateToProps(
  { collectionsOld, itemsOld, responses, profile }: IStore,
  props: IClassQualificationPageRouteProps
) {
  const units = getCollectionItems(
    collectionsOld.get('courses/unit-management'),
    CLASS_QUALIFICATION
  );
  const assignedUnits = units.filter(each => each.class_tasks.count() > 0);
  const unassignedUnits = units.filterNot(each => each.class_tasks.count() > 0);
  return {
    classId: props.params.classid,
    loading: anyPending(responses, [
      [LOAD_ITEM, 'courses'],
      [LOAD_ITEM, 'classes'],
    ]),
    profile,
    qualification: itemsOld.courses,
    qualificationClass: itemsOld.classes,
    qualificationId: props.params.id,
    assignedUnits,
    unassignedUnits,
    assignedSuccessfully: hasSucceeded(responses, ASSIGN_UNIT),
    updatedSuccessfully: hasSucceeded(responses, UPDATE_UNIT),
    unitsLoading: isPending(
      responses,
      GET_COLLECTION,
      'courses/unit-management'
    ),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  loadItem,
  openModal,
  resetRequestState,
})(ClassQualificationPage);
