import { Alert, Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { IProfile } from '../../store/data-types/profile';
import { getFullName } from '../../utils';
const { IconFileO, IconUserCircleO } = FontAwesome;

import Term from '../terminology';

interface IClassTeachersProps {
  students: ReadonlyArray<{}>; // We use no member values, so don't need a type
}
export default function ClassDetailStudentDetails({
  students,
}: IClassTeachersProps) {
  return (
    <Row>
      <Column xs={12}>
        <h3>
          <Term>Students</Term> Info
        </h3>
      </Column>
      <Column xs={12}>
        {students.length} <Term>Students</Term>
      </Column>
    </Row>
  );
}
