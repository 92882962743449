import { Map } from 'immutable';
import {
  CLEAR_INVITE_STATUS,
  IInviteMeta,
  SEND_INVITE,
} from '../actions/emails';
import { IAction } from '../types';

export function userInvites(
  state = Map<string, string>(),
  action: IAction<void, IInviteMeta>
) {
  const userId = action.meta && action.meta.itemId;

  switch (action.type) {
    case CLEAR_INVITE_STATUS:
      return Map<string, string>();
    case SEND_INVITE.REQUEST:
      return typeof userId === 'undefined'
        ? state
        : state.set(userId, SEND_INVITE.REQUEST);
    case SEND_INVITE.SUCCESS:
      return typeof userId === 'undefined'
        ? state
        : state.set(userId, SEND_INVITE.SUCCESS);
    case SEND_INVITE.FAILURE:
      return typeof userId === 'undefined'
        ? state
        : state.set(userId, SEND_INVITE.FAILURE);
    default:
      return state;
  }
}
