import {
  Collection,
  CollectionOptions,
} from '@dabapps/redux-api-collections/dist/collections';
import { IdKeyed } from '@dabapps/redux-api-collections/dist/utils';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import * as classNames from 'classnames';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';

import Pagination from '../../../components/pagination';
import Loading from '../../loading';
import { IBaseTableProps, IColumnData } from '../simple-table';
import SortIcon from '../sort-icon';

import BaseCollectionTable from './base';

const { IconSort, IconSortAsc, IconSortDesc } = FontAwesome;

interface IProps<TData extends IdKeyed> extends IBaseTableProps<TData> {
  collection: Collection<TData>;
  className?: string;
  pageSize: number;
  preventInitialRequest?: boolean;
  getCollection(options: CollectionOptions): void;
}

export default class CollectionTable<
  TData extends { id: string }
> extends React.PureComponent<IProps<TData>, void> {
  public render() {
    return (
      <BaseCollectionTable
        {...this.props}
        initializeCollection={this.initializeCollection}
        onPaginationClick={this.onPaginationClick}
        sortBy={this.sortBy}
        getResults={this.getResults}
        getCount={this.getCount}
        getPage={this.getPage}
        getOrdering={this.getOrdering}
        getReverseOrdering={this.getReverseOrdering}
      />
    );
  }

  private initializeCollection = () => {
    const options = {
      ordering: this.props.collection.ordering,
      page: this.props.collection.page,
      pageSize: this.props.pageSize,
      reverseOrdering: this.props.collection.reverseOrdering,
    };

    this.props.getCollection(options);
  };

  private onPaginationClick = (nextPage: number) => {
    const options = {
      ordering: this.props.collection.ordering,
      page: nextPage,
      pageSize: this.props.pageSize,
      reverseOrdering: this.props.collection.reverseOrdering,
      filters: this.props.collection.filters || {},
    };

    this.props.getCollection(options);
  };

  private sortBy = (key: string) => {
    const { ordering, reverseOrdering = false } = this.props.collection;

    const options = {
      ordering: key,
      page: this.props.collection.page,
      pageSize: this.props.pageSize,
      reverseOrdering: ordering === key ? !reverseOrdering : reverseOrdering,
      filters: this.props.collection.filters || {},
    };

    this.props.getCollection(options);
  };

  private getResults = () => {
    return this.props.collection.results;
  };

  private getCount = () => {
    return this.props.collection.count;
  };

  private getPage = () => {
    return this.props.collection.page;
  };

  private getOrdering = () => {
    return this.props.collection.ordering;
  };

  private getReverseOrdering = () => {
    return this.props.collection.reverseOrdering;
  };
}
