import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { ICollection, ICollectionOptions } from '../../../collections/types';

import { Map } from 'immutable';

import { clearUIState, setUIState } from '../../../actions';
import { REQUEST_RATE_MS } from '../../../constants';
import { IStore } from '../../../store';
import CollectionInputFilterBase, { IBaseProps, uiKeyName } from './base';

interface IProps<T> extends IBaseProps {
  collection: ICollection<T>;
  getCollection(options: ICollectionOptions): void;
}

class CollectionInputFilter<T> extends React.PureComponent<IProps<T>, void> {
  public render() {
    const props = this.props;
    return (
      <CollectionInputFilterBase
        {...props}
        changeFilters={_.debounce(
          this.changeFilters.bind(this),
          REQUEST_RATE_MS
        )}
      />
    );
  }

  public changeFilters = (newValue: string) => {
    const filters = this.props.collection.filters || Map<string, string>();
    const options = {
      filters: filters.set(this.props.filterKey, newValue),
      page: this.props.collection.page,
      pageSize: this.props.pageSize,
    };

    this.props.getCollection(options);
  };
}

function mapStateToProps<T>({ uiState }: IStore, props: Partial<IProps<T>>) {
  return {
    uiValue: uiState.get(uiKeyName(props)) || '',
  };
}

export default connect(mapStateToProps, {
  clearUIState,
  setUIState,
})(CollectionInputFilter);
