import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  FormGroup,
  Row,
  SpacedGroup,
  TableCell,
  TableRow,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { reduxForm } from 'redux-form';
import { GET_COLLECTION } from '../../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
import {
  IExamBoardRecord,
  IOptionRecord,
  OptionRecord,
} from '../../../store/data-types';
import CollectionSelectFilter from '../../tables/collection-select-filter';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';

const { actions: { getAllCollection, getCollection } } = collectionsModule;

const PAGE_SIZE = 10;
const SUBSCRIPTION_BANK = 'SUBSCRIPTION_BANK';
const HEADERS: Array<IColumnData<IOptionRecord>> = [
  {
    content: record => narrowToRecord(record.exam_spec).exam_board.name,
    headerLabel: 'Exam Board',
    key: 'exam_board',
    sortable: false,
  },
  {
    content: record => record.title,
    headerLabel: 'Name',
    key: 'name',
    sortable: false,
  },
  {
    content: record => record.code,
    headerLabel: 'Code',
    key: 'code',
    sortable: false,
  },
  {
    content: record => (
      <Link
        className="button tertiary"
        to={`/tasks/subscription-bank/${record.id}/`}
      >
        Create Qualification
      </Link>
    ),
    headerLabel: 'Create',
    key: 'create',
    sortable: false,
  },
];

interface ISubscriptionBankListProps {
  loading: boolean;
  examBoards: List<IExamBoardRecord>;
  optionsCollection: ICollection<IOptionRecord>;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
}

export class SubscriptionBankList extends React.PureComponent<
  ISubscriptionBankListProps,
  void
> {
  public componentWillMount() {
    this.props.getAllCollection('courses/exam-boards', {}, SUBSCRIPTION_BANK);
  }

  public render() {
    const { loading, optionsCollection } = this.props;
    return (
      <div>
        {this.filters()}
        <CollectionTable
          headers={HEADERS}
          collection={optionsCollection}
          loading={loading}
          pageSize={PAGE_SIZE}
          getCollection={(options: ICollectionOptions) =>
            this.props.getCollection(
              'courses/qualification-options',
              options,
              SUBSCRIPTION_BANK
            )}
        />
      </div>
    );
  }

  private filters() {
    const getCollectionBound = (options: ICollectionOptions) =>
      this.props.getCollection(
        'courses/qualification-options',
        options,
        SUBSCRIPTION_BANK
      );
    const { optionsCollection, examBoards } = this.props;

    return (
      <Row>
        <Column sm={4}>
          <CollectionSelectFilter
            label="Exam Board"
            name="exam_board"
            filterKey="exam_board"
            options={examBoards.map(({ id, name }) => ({
              key: id,
              label: name,
            }))}
            collection={optionsCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Column>
      </Row>
    );
  }
}

const Formified = reduxForm({ form: 'tasks-landing-student' })(
  SubscriptionBankList
);

function mapStateToProps({ collectionsOld, responses }: IStore) {
  const optionsCollection = getCollectionByName(
    collectionsOld.get('courses/qualification-options'),
    SUBSCRIPTION_BANK
  );
  return {
    examBoards:
      getCollectionItems(
        collectionsOld.get('courses/exam-boards'),
        SUBSCRIPTION_BANK
      ) || List(),
    loading: isPending(
      responses,
      GET_COLLECTION,
      'courses/qualification-options'
    ),
    optionsCollection,
  };
}

export default connect(mapStateToProps, { getCollection, getAllCollection })(
  Formified
);
