import { List } from 'immutable';

import { ICollection } from './types';

export function Collection<T>(input?: Partial<ICollection<T>>): ICollection<T> {
  return {
    count: (input && input.count) || 0,
    filters: input && input.filters,
    next: input && input.next,
    ordering: input && input.ordering,
    page: (input && input.page) || 1,
    results: (input && input.results) || List<T>([]),
    reverseOrdering: input && input.reverseOrdering,
  };
}
