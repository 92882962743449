import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import { Alert, Button, Column, Row } from '@dabapps/roe';
import { AxiosResponse } from 'axios';
import { List, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { TActionAny } from '../../requests/types';

import {
  GET_ASSETS,
  UPLOAD_ASSET,
  uploadAssetsAndReloadAssets,
} from '../../actions';
import { openModal } from '../../actions/modals';
import { DELETE_ASSET } from '../../actions/quotas';
import { TAG_ASSETS } from '../../actions/tag-assets';
import { STUDENT, userHasRoles } from '../../permissions';
import { IStore } from '../../store/';
import { IAssetRecord } from '../../store/data-types';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import { formatBytes } from '../../utils';
import If from '../permissions/if';
import FileList from './file-list';
import FilesWizard from './files-wizard';
import TagFilesModal from './tag-files/tag-files-modal';

interface IExternalProps {
  student?: ISimpleUser;
  loadAssets(page?: number, shouldAppend?: boolean): void;
}

interface IConnectedProps {
  profile: IProfile;
  assets: List<IAssetRecord>;
  canLoadMore: boolean;
  pageNumber: number;
  awaitingResponses: boolean;
}

interface IDispatchProps {
  openModal(component: React.ReactNode): void;
}

type IProps = IConnectedProps & IDispatchProps & IExternalProps;

export class Assets extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.filesWizard = this.filesWizard.bind(this);
    this.loadMoreFiles = this.loadMoreFiles.bind(this);
  }

  public render() {
    const { profile, assets, student } = this.props;

    return (
      <div>
        <Row>
          <Column>
            <div className="file-upload">
              <Button onClick={this.filesWizard}>Upload Files</Button>
            </div>
          </Column>
        </Row>
        <If
          condition={userHasRoles(profile, Set.of(STUDENT)) || Boolean(student)}
        >
          <Row>
            <Column>
              <FileList student={student} loadAssets={this.props.loadAssets} />
              {this.props.canLoadMore && (
                <button
                  onClick={this.loadMoreFiles}
                  className="button margin-bottom-large"
                  disabled={this.props.awaitingResponses}
                >
                  Load more...
                </button>
              )}
            </Column>
          </Row>
        </If>
      </div>
    );
  }

  private loadMoreFiles() {
    this.props.loadAssets(this.props.pageNumber + 1, true);
  }

  private filesWizard() {
    this.props.openModal(
      <FilesWizard
        filesUploadedCallback={this.props.loadAssets}
        filesTaggedCallback={this.props.loadAssets}
        initialUser={this.props.student}
      />
    );
  }
}

function mapStateToProps(
  {
    assets,
    assetsPage: { canLoadMore, pageNumber },
    profile,
    responses,
  }: IStore,
  props: IExternalProps
): IConnectedProps {
  return {
    ...props,
    assets,
    canLoadMore,
    pageNumber,
    profile,
    awaitingResponses: anyPending(responses, [
      GET_ASSETS,
      DELETE_ASSET,
      UPLOAD_ASSET,
      TAG_ASSETS,
    ]),
  };
}

export default connect(mapStateToProps, {
  openModal,
})(Assets);
