import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';

import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import { IQualificationStudentProgress } from '../../store/data-types/progress';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';
import { IUserGradeListTaskRecord } from '../../store/data-types/tasks';
import { sum } from '../../utils';
import Loading from '../loading';
import { MultiProgressBar } from '../multi-progress-bar';
import { ProgressRing } from '../progress-ring';
import Term from '../terminology';
import TasksStudent from './tasks-table-student';

export const getComponentName = (qualificationId: string) =>
  `DASHBOARD_QUALIFICATION-${qualificationId}`;

interface IExternalProps {
  course: IDashboardQualificationOption;
  studentId?: string;
  loadProgress(course: IDashboardQualificationOption, studentId?: string): void;
}

interface IStateProps {
  tasks: List<IUserGradeListTaskRecord>;
  progress: IQualificationStudentProgress | undefined;
  loading: boolean;
  profile: IProfile;
}

type IProps = IExternalProps & IStateProps;

export class CourseSummaryStudent extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.loadProgress(this.props.course, this.props.studentId);
  }

  public render() {
    const { course, loading, tasks, progress, profile, studentId } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!progress) {
      return (
        <Alert className="error">
          <p>Failed to load progress</p>
        </Alert>
      );
    }

    const {
      tasks_in_progress,
      tasks_awaiting_marking,
      tasks_marked,
      tasks_quality_assured,
      tasks_submitted,
      progress_through_assigned_units,
    } = progress;

    const tasksProgress = [
      {
        className: 'progress-open',
        progress: tasks_in_progress,
      },
      {
        className: 'progress-completed',
        progress: tasks_awaiting_marking,
      },
      {
        className: 'progress-marked',
        progress: tasks_marked + tasks_quality_assured + tasks_submitted,
      },
    ];

    return (
      <div>
        <Section>
          <Row>
            <Column xs={6} sm={4} md={3} lg={2}>
              <ProgressRing
                progress={progress_through_assigned_units}
                displayPercentage={false}
                color={'#289664'}
              />
            </Column>
            <Column xs={12} sm={8} md={9} lg={10}>
              <p>
                Progress Through Assigned Work ({(progress_through_assigned_units *
                  100
                ).toFixed(1)}%)
              </p>
              <MultiProgressBar
                progressElements={this.calculateProgressThroughUnits()}
              />
              <p>
                <span className="progress-label">
                  <span className="progress-swatch progress-submitted" />
                  Completed
                </span>
                <span className="progress-label">
                  <span className="progress-swatch" />
                  Assigned Units
                </span>
                <span className="progress-label">
                  <span className="progress-swatch progress-not-assigned" />
                  Unassigned Units
                </span>
              </p>

              <p>
                <Term>Task</Term> Breakdown
              </p>
              <MultiProgressBar progressElements={tasksProgress} />

              <p>
                <span className="progress-label">
                  <span className="progress-swatch progress-open" />
                  Open ({tasks_in_progress})
                </span>
                <span className="progress-label">
                  <span className="progress-swatch progress-completed" />
                  Awaiting Marking ({tasks_awaiting_marking})
                </span>
                <span className="progress-label">
                  <span className="progress-swatch progress-marked" />
                  Marked ({tasks_marked +
                    tasks_quality_assured +
                    tasks_submitted})
                </span>
              </p>
            </Column>
          </Row>
        </Section>

        <Section>
          <TasksStudent
            tasks={tasks}
            loading={loading}
            componentName={getComponentName(course.id)}
            qualificationId={course.id}
            profile={profile}
            studentId={studentId}
          />
        </Section>
      </div>
    );
  }

  private calculateProgressThroughUnits() {
    const { progress } = this.props;

    if (!progress) {
      return [];
    }

    const {
      progress_through_assigned_units,
      assigned_units,
      units_not_assigned,
    } = progress;

    const normalisedProgress = assigned_units * progress_through_assigned_units;

    return [
      {
        className: 'progress-submitted',
        progress: normalisedProgress,
      },
      {
        className: '',
        progress: assigned_units - normalisedProgress,
      },
      {
        className: 'progress-not-assigned',
        progress: units_not_assigned,
      },
    ];
  }
}

function mapStateToProps(
  { collectionsOld, responses, profile }: IStore,
  { course }: IExternalProps
) {
  return {
    tasks: getCollectionItems(
      collectionsOld.get('tasks/grades'),
      getComponentName(course.id)
    ),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'courses/student-progress'],
      [GET_COLLECTION, 'tasks/grades'],
    ]),
    profile,
    progress: getCollectionItems(
      collectionsOld.get('courses/student-progress'),
      getComponentName(course.id)
    ).first(),
  };
}

export default connect(mapStateToProps)(CourseSummaryStudent);
