import { Button } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FormProps, reduxForm, WrappedFieldProps } from 'redux-form';
// tslint:disable-next-line:no-unused-variable
import { IProfile, ISimpleUser } from '../../../store/data-types/profile';
import UserSelect from '../../classes/class-form-user-select';
import GroupField from '../../forms/group-field';
import { terminologyFromProfile } from '../../terminology';

const MANAGE_EQAS_FORM = 'MANAGE_EQAS_FORM';

export interface IFormData {
  external_quality_assurers: Set<string>;
}

interface IProps extends FormProps<IFormData, void, void> {
  eqas: List<ISimpleUser>;
  profile: IProfile;
  onSubmit(data: IFormData): void;
}

function ManageCentreEQAsForm({ eqas, profile, handleSubmit }: IProps) {
  return (
    <form onSubmit={handleSubmit}>
      <GroupField
        label={terminologyFromProfile(profile, 'External Quality Assurers')}
        name="external_quality_assurers"
        type="text"
        component={(props: WrappedFieldProps<void>) => (
          <UserSelect
            {...props}
            users={eqas}
            label={terminologyFromProfile(profile, 'External Quality Assurers')}
            canRemoveUser={(userId: string, users: Set<string>) => true}
            multi
          />
        )}
      />
      <Button type="submit" className="primary margin-bottom-large">
        Save
      </Button>
    </form>
  );
}

export default reduxForm({
  form: MANAGE_EQAS_FORM,
  enableReinitialize: true,
})(ManageCentreEQAsForm);
