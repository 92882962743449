import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Column, ContentBox, ContentBoxHeader, Row } from '@dabapps/roe';
import { List, Range } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Slik from 'slik';
import { findIndex } from 'underscore';
import { GET_COLLECTION, ITEMS_PER_PAGE } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../collections/types';

import {
  ACTIVITY_EVENTS,
  getTimelineDataAroundDate,
  ITimelineOptions,
  setTimelineViewing,
  TASKS_TIMELINE,
  USER_TASKS,
} from '../../actions/timeline';
import { IStore } from '../../store';
import {
  ActivityEventRecord,
  IActivityEventRecord,
} from '../../store/data-types/activity-events';
import { IUserTaskRecord, UserTaskRecord } from '../../store/data-types/tasks';
import Term from '../terminology';

import {
  isActivityEventRecord,
  TTimelineData,
  TTimelineDataList,
  TTimelineGroupedData,
} from '../../store/data-types/timeline';
import Loading from '../loading';
import LoadingSpinner from '../loading-spinner';
import TasksMonth from './tasks-month';
import Timeline from './timeline';

const ANIMATE_SCROLL_DURATION_MILLIS = 500;
const ENOUGH_TIME_FOR_THE_NEXT_RENDER_MILLIS = 50;
const ENOUGH_TIME_FOR_THE_PAGE_TO_SCROLL_MILLIS =
  ANIMATE_SCROLL_DURATION_MILLIS * 2;
const CLOSE_ENOUGH_TO_NOT_CHANGE_TARGET_PERCENTAGE = 1;
const DAYS_IN_A_YEAR = 365;

const scrollAnimation = new Slik.Animation({
  duration: ANIMATE_SCROLL_DURATION_MILLIS,
  ease: Slik.Easing.EaseInOutSine,
});

interface IProps {
  today: moment.Moment;
  viewing: moment.Moment;
  data: TTimelineDataList;
  groupedData: TTimelineGroupedData;
  loading: boolean;
  getTimelineDataAroundDate(date: moment.Moment): void;
  setTimelineViewing(date: moment.Moment): void;
}

interface IClosestActivityMemo {
  closest?: number;
  index?: number;
}

export class TasksTimeline extends React.PureComponent<IProps, void> {
  private timelineScrolling = false;
  private lastTimelineScroll = new Date().getTime();
  private scrollFeedTimeout: NodeJS.Timer;

  public constructor(props: IProps) {
    super(props);

    this.scrollFeed = this.scrollFeed.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onTimelineScrollStart = this.onTimelineScrollStart.bind(this);
    this.onTimelineScrollEnd = this.onTimelineScrollEnd.bind(this);
  }

  public componentWillMount() {
    const { today } = this.props;

    this.props.setTimelineViewing(today);
    this.props.getTimelineDataAroundDate(today);
  }

  public componentDidMount() {
    const { viewing } = this.props;

    this.scrollFeedTimeout = setTimeout(
      this.scrollFeed,
      ENOUGH_TIME_FOR_THE_NEXT_RENDER_MILLIS
    );

    window.addEventListener('scroll', this.onScroll);
    this.unsubscribeUpdate = scrollAnimation.subscribe(
      'start',
      (value: number) => this.onTimelineScrollStart()
    );
    this.unsubscribeUpdate = scrollAnimation.subscribe('end', (value: number) =>
      this.onTimelineScrollEnd()
    );
    this.unsubscribeUpdate = scrollAnimation.subscribe(
      'update',
      (value: number) => this.setScrollTop(value)
    );
  }

  public componentWillUnmount() {
    clearTimeout(this.scrollFeedTimeout);
    window.removeEventListener('scroll', this.onScroll);
    scrollAnimation.stop();
    this.unsubscribeStart();
    this.unsubscribeEnd();
    this.unsubscribeUpdate();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { viewing } = this.props;

    this.props.getTimelineDataAroundDate(viewing);

    if (prevProps.data !== this.props.data) {
      this.timelineScrolling = true;
      this.scrollFeed(true);
      this.timelineScrolling = false;
      this.lastTimelineScroll = new Date().getTime();
    }
  }

  public setScrollTop(value: number) {
    if (document.documentElement) {
      document.documentElement.scrollTop = value;
    }

    if (document.body) {
      document.body.scrollTop = value;
    }
  }

  public getScrollTop() {
    return (
      (document.documentElement && document.documentElement.scrollTop) ||
      (document.body && document.body.scrollTop) ||
      0
    );
  }

  public scrollFeed(animate?: boolean) {
    clearTimeout(this.scrollFeedTimeout);

    const activities = List<HTMLDivElement>(
      document.querySelectorAll('.timeline-bar .timeline-point')
    );

    const initialMemo: IClosestActivityMemo = {};

    const closestActivityData = activities.reduce(
      (memo: IClosestActivityMemo, activity, index) => {
        const percentageFromCenter = Math.abs(
          50 -
            (parseFloat(activity.style.left || '0') +
              parseFloat(activity.style.width || '0'))
        );

        if (
          typeof memo.closest === 'undefined' ||
          (percentageFromCenter < memo.closest &&
            // Prevent jitter between activities within a day or two
            Math.abs(percentageFromCenter - memo.closest) >
              CLOSE_ENOUGH_TO_NOT_CHANGE_TARGET_PERCENTAGE)
        ) {
          memo.closest = percentageFromCenter;
          memo.index = index;
        }

        return memo;
      },
      initialMemo
    );

    if (
      typeof closestActivityData.index !== 'undefined' &&
      closestActivityData.index >= 0
    ) {
      const day = document.querySelectorAll('.tasks-list .day-group .well')[
        closestActivityData.index
      ];

      if (day) {
        const rect = day.getBoundingClientRect();
        const scrollTop = this.getScrollTop();

        const scrollToValue = scrollTop + rect.top - window.innerHeight / 4;

        if (animate) {
          scrollAnimation
            .from(scrollTop)
            .to(scrollToValue)
            .start();
        } else {
          this.setScrollTop(scrollToValue);
        }
      }
    }
  }

  public onTimelineScrollStart() {
    this.timelineScrolling = true;
  }

  public onTimelineScrollEnd() {
    this.timelineScrolling = false;
    this.lastTimelineScroll = new Date().getTime();
  }

  public onScroll(event: MouseEvent) {
    if (
      !this.timelineScrolling &&
      new Date().getTime() >=
        this.lastTimelineScroll + ENOUGH_TIME_FOR_THE_PAGE_TO_SCROLL_MILLIS
    ) {
      const { data } = this.props;
      const days = document.querySelectorAll('.tasks-list .day-group .well');
      const quarterOfScreenHeight = window.innerHeight / 4;

      const indexOfFirstDayOnScreen = findIndex(days, day => {
        const rect = day.getBoundingClientRect();
        return rect.top > quarterOfScreenHeight;
      });

      if (days.length) {
        const datum =
          indexOfFirstDayOnScreen >= 0
            ? data.get(indexOfFirstDayOnScreen)
            : data.last();

        if (datum && isActivityEventRecord(datum)) {
          this.props.setTimelineViewing(datum.get('created'));
        } else if (datum) {
          const endDate = datum.get('end_date');
          if (endDate) {
            this.props.setTimelineViewing(endDate);
          }
        }
      }
    }
  }

  public onChange(viewing: moment.Moment) {
    this.props.setTimelineViewing(viewing);

    this.scrollFeedTimeout = setTimeout(
      () => this.scrollFeed(true),
      ENOUGH_TIME_FOR_THE_NEXT_RENDER_MILLIS
    );
  }

  public render() {
    const { today, viewing, loading, data, groupedData } = this.props;

    return (
      <ContentBox className="tasks-list">
        <ContentBoxHeader>
          <h3>
            <Term>Tasks</Term> Timeline
          </h3>
        </ContentBoxHeader>

        {loading && <LoadingSpinner />}

        {loading && !groupedData.count() ? (
          <Loading />
        ) : (
          <Row>
            <Column>
              {groupedData.count() ? (
                groupedData
                  .map((days, month) => (
                    <TasksMonth key={month} days={days} month={month} />
                  ))
                  .toList()
              ) : (
                <p className="info">No events</p>
              )}
            </Column>
          </Row>
        )}

        <Timeline
          feed={data}
          today={today}
          viewing={viewing}
          onChange={this.onChange}
          onMouseDown={this.onTimelineScrollStart}
          onMouseUp={this.onTimelineScrollEnd}
        />
      </ContentBox>
    );
  }

  private unsubscribeStart: Slik.unsubscribe = () => undefined;
  private unsubscribeEnd: Slik.unsubscribe = () => undefined;
  private unsubscribeUpdate: Slik.unsubscribe = () => undefined;
}

function mapStateToProps(
  { responses, timeline: { data, groupedData, today, viewing } }: IStore,
  props: {}
) {
  const loading =
    isPending(responses, GET_COLLECTION, ACTIVITY_EVENTS) ||
    isPending(responses, GET_COLLECTION, USER_TASKS);

  return {
    data,
    groupedData,
    loading,
    today,
    viewing,
  };
}

export default connect(mapStateToProps, {
  getTimelineDataAroundDate,
  setTimelineViewing,
})(TasksTimeline);
