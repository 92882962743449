import { Column, Row, Well } from '@dabapps/roe';
import * as classNames from 'classnames';
import * as React from 'react';
import { HTMLAttributes } from 'react';
import { FontAwesome } from 'react-inline-icons';

const {
  IconFilesO,
  IconCommentsO,
  IconListOl,
  IconPencilSquareO,
  IconUser,
  IconInfoCircle,
  IconInbox,
  IconExclamationTriangle,
  IconCheckSquareO,
  IconListUl,
} = FontAwesome;

interface IProps extends HTMLAttributes<HTMLDivElement> {
  heading: React.ReactNode;
  type:
    | 'text'
    | 'files'
    | 'task'
    | 'comment'
    | 'user'
    | 'hand-in'
    | 'sign-off'
    | 'info'
    | 'review-requested'
    | 'status-change-positive'
    | 'status-change-negative'
    | 'list';
}

const NewsFeedItem: React.SFC<IProps> = props => {
  const { className, children, type, heading, ...remainingProps } = props;

  return (
    <Row
      {...remainingProps}
      className={classNames('news-feed-item', className)}
    >
      <Column className="news-feed-item-indent">
        <div className="news-feed-item-icon">
          {type === 'text' && <IconPencilSquareO />}
          {type === 'files' && <IconFilesO />}
          {type === 'task' && <IconListOl />}
          {type === 'comment' && <IconCommentsO />}
          {type === 'user' && <IconUser />}
          {type === 'hand-in' && <IconInbox />}
          {type === 'status-change-positive' && <IconCheckSquareO />}
          {type === 'status-change-negative' && <IconExclamationTriangle />}
          {type === 'sign-off' && <IconPencilSquareO />}
          {type === 'info' && <IconInfoCircle />}
          {type === 'review-requested' && <IconPencilSquareO />}
          {type === 'list' && <IconListUl />}
        </div>
        <p className="news-feed-item-header">{heading}</p>
        <div className="news-feed-item-content">
          {children && (
            <Well className="padding-horizontal-large">{children}</Well>
          )}
        </div>
      </Column>
    </Row>
  );
};

export default NewsFeedItem;
