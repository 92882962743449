import { ContentBox } from '@dabapps/roe';
import { Set } from 'immutable';
import * as React from 'react';

import NavItem from '../nav/item';
import SubNav from '../nav/sub';
import IfCan from '../permissions/if-can';
import IfHasRoles from '../permissions/if-has-roles';
import Term from '../terminology';

import { STUDENT, TEACHER } from '../../permissions';

interface IProps {
  location: {
    pathname: string;
  };
  hideNav?: boolean;
}

const MATCHES_JOURNAL = /^\/journal\/((?!class\/).)*$/;
const MATCHES_CLASS_JOURNAL = /^\/journal\/class\//;

export default class JournalPage extends React.PureComponent<IProps, void> {
  public render() {
    const { location: { pathname }, hideNav } = this.props;

    return (
      <div>
        {!hideNav && (
          <SubNav>
            <IfHasRoles roles={Set.of(STUDENT)}>
              <NavItem
                href="/journal/"
                pathname={pathname}
                matches={MATCHES_JOURNAL}
              >
                My Journal
              </NavItem>
            </IfHasRoles>
            <NavItem
              href="/journal/class/"
              pathname={pathname}
              matches={MATCHES_CLASS_JOURNAL}
            >
              <Term>Class</Term> Journal
            </NavItem>
          </SubNav>
        )}
        <ContentBox className="journal-page">{this.props.children}</ContentBox>
      </div>
    );
  }
}
