import { Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';
import { IJournalEntry } from '../../../store/data-types/journals';
import { formatDate } from '../../../utils';

const { IconFileTextO } = FontAwesome;

interface IProps {
  journalEntry: IJournalEntry;
  location: {
    pathname: string;
  };
}

export default class JournalItem extends React.PureComponent<IProps, void> {
  public render() {
    const { journalEntry, location } = this.props;
    return (
      <Row className="journal-item">
        <Column xs={12}>
          <div className="icon float-left">
            <IconFileTextO />
          </div>
          <div className="content float-left">
            <p className="margin-vertical-small">
              <Link
                to={`/journal/${journalEntry.id}/?prevPathname=${location.pathname}&prevName=Marksheet`}
              >
                {journalEntry.title}
              </Link>
            </p>
            <p className="margin-vertical-small">
              {formatDate(journalEntry.created)}
            </p>
          </div>
        </Column>
      </Row>
    );
  }
}
