import {
  hasFailed,
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { formValueSelector } from 'redux-form';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import { TActionAny, Thunk } from '../../../requests/types';

import { closeModal } from '../../../actions/modals';
import {
  ASSIGN_QUALIFICATION,
  assignQualification,
} from '../../../actions/qualifications';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import { IProfile } from '../../../store/data-types/profile';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import { FormErrors } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import QualificationMandatoryUnitsWarning from '../../qualification-mandatory-units-warning';
import Term from '../../terminology';
import QualificationWithoutDatesWarning from '../qualification-without-dates-warning';
import AssignQualificationForm, {
  ASSIGN_QUALIFICATION_FORM,
  IAssignQualificationFormData,
} from './assign-qualification-form';

const { actions: { getAllCollection } } = collectionsModule;

const ASSIGN_QUALIFICATION_MODAL = 'ASSIGN_QUALIFICATION_MODAL';
interface IAssignQualificationModalExternalProps {
  qualification: IQualificationRecord;
  onComplete(qualificationId: string): TActionAny | Thunk<any>;
}
interface IAssignQualificationModalWithoutActionsProps
  extends IAssignQualificationModalExternalProps {
  selected_start_date?: moment.Moment;
  selected_end_date?: moment.Moment;
  assigning: boolean;
  classes: List<IClassRecord>;
  loading: boolean;
  formErrors: FormErrors | undefined;
  profile: IProfile;
}

interface IAssignQualificationModalProps
  extends IAssignQualificationModalWithoutActionsProps {
  assignQualification(
    qualificationId: string,
    classes: Set<string>,
    onComplete: (qualificationId: string) => TActionAny | Thunk<any>
  ): void;
  closeModal(): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
}
export class AssignQualificationModal extends React.PureComponent<
  IAssignQualificationModalProps,
  void
> {
  public componentWillMount() {
    this.props.getAllCollection(
      'classes',
      { filters: Map({ no_qualification: 'true' }) },
      ASSIGN_QUALIFICATION_MODAL
    );
  }

  public render() {
    const {
      assigning,
      classes,
      loading,
      formErrors,
      qualification,
      profile,
      selected_start_date,
      selected_end_date,
    } = this.props;
    return (
      <Modal onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          <h3>
            Assign {qualification.name} to <Term>Classes</Term>
          </h3>
        </ModalHeader>

        {loading ? (
          <Loading />
        ) : (
          <AssignQualificationForm
            classes={classes}
            profile={profile}
            onSubmit={this.assignQualification.bind(this)}
          >
            <QualificationMandatoryUnitsWarning
              onClick={event => {
                event.preventDefault();
                this.props.closeModal();
              }}
            />
            {(!selected_start_date || !selected_end_date) && (
              <QualificationWithoutDatesWarning />
            )}
            {assigning && <Loading className="small" />}
            <ModalFooter className="padding-base">
              <FormErrorsRenderer formErrors={formErrors} errorKey="classes" />
              <FormErrorsRenderer
                formErrors={formErrors}
                errorKey="non_field_errors"
              />
              <SpacedGroup>
                <Button
                  disabled={assigning}
                  onClick={event => {
                    event.preventDefault();
                    this.props.closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button className="primary" disabled={assigning} type="submit">
                  Assign
                </Button>
              </SpacedGroup>
            </ModalFooter>
          </AssignQualificationForm>
        )}
      </Modal>
    );
  }

  private assignQualification(data: IAssignQualificationFormData) {
    const { assigning, qualification, loading, onComplete } = this.props;
    if (!loading && !assigning) {
      const classes = data.selectedClasses || Set();
      return this.props.assignQualification(
        qualification.id,
        classes,
        onComplete
      );
    }
  }
}

const selector = formValueSelector(ASSIGN_QUALIFICATION_FORM);

function mapStateToProps(
  store: IStore,
  props: IAssignQualificationModalExternalProps
): IAssignQualificationModalWithoutActionsProps {
  const { collectionsOld, responses, profile } = store;
  return {
    ...props,
    assigning: isPending(responses, ASSIGN_QUALIFICATION),
    classes: getCollectionItems(
      collectionsOld.classes,
      ASSIGN_QUALIFICATION_MODAL
    ),
    formErrors: getFormErrors(responses, ASSIGN_QUALIFICATION),
    loading: isPending(responses, GET_COLLECTION, 'classes'),
    selected_start_date: selector(store, 'start_date'),
    selected_end_date: selector(store, 'end_date'),
    profile,
  };
}

export default connect(mapStateToProps, {
  assignQualification,
  closeModal,
  getAllCollection,
})(AssignQualificationModal);
