import { Alert, Column, ContentBox, Row, Section } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';
import { IClassRecord } from '../../store/data-types/classes';
import { ISimpleUser } from '../../store/data-types/profile';
import ImageHolder from '../image-holder';
import ClassDetailBlog from './class-detail-class-blog';
import ClassDetailStudentDetails from './class-detail-student-details';
import ClassDetailTeacherList from './class-detail-teacher-list';
import ClassHeader from './class-header';
const { IconFileO, IconUserCircleO } = FontAwesome;

import Term from '../terminology';

interface IClassStudentProps {
  student: ISimpleUser;
}
function ClassStudent({ student }: IClassStudentProps) {
  return (
    <Column className="class-student" xs={12} sm={6} md={4} lg={3}>
      <Row>
        <Column xs={4}>
          <IconUserCircleO className="student-card-icon" />
        </Column>
        <Column xs={8} className="student-card-info">
          <h4>{student.osms_data.name || '--'}</h4>
        </Column>
      </Row>
    </Column>
  );
}

interface IClassStudentsProps {
  students: ReadonlyArray<ISimpleUser>;
}
function ClassStudents({ students }: IClassStudentsProps) {
  return students.length ? (
    <Row>
      <Column xs={12}>
        <h3>
          <Term>Students</Term>
        </h3>
      </Column>
      {students.map(student => (
        <ClassStudent key={student.id} student={student} />
      ))}
    </Row>
  ) : (
    <Alert className="info">
      <p>
        There are no <Term>Students</Term> in this <Term>Class</Term> yet.
      </p>
    </Alert>
  );
}

interface IClassDetailStudentProps {
  class: IClassRecord;
}
export default function ClassDetailStudent(props: IClassDetailStudentProps) {
  const { name, description, id } = props.class;
  const students = narrowToRecordArray(props.class.students);
  const teachers = narrowToRecordArray(props.class.teachers);
  return (
    <ContentBox>
      <ClassHeader class={props.class} />
      <Section>
        <Row>
          <Column xs={12} md={6} lg={4}>
            <ClassDetailStudentDetails students={students} />
          </Column>
          <Column xs={12} md={6} lg={4}>
            <ClassDetailTeacherList teachers={teachers} />
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ClassDetailBlog classId={id} />
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ClassStudents students={students} />
          </Column>
        </Row>
      </Section>
    </ContentBox>
  );
}
