import * as React from 'react';

import { ExamResultsList } from '../../store/data-types/tasks';

interface IProps {
  examResults: ExamResultsList | null;
  prefix?: string;
  loading?: boolean;
}

export default class ExamResultsIndicator extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { examResults, prefix, loading } = this.props;

    const renderableExamResults = examResults
      ? examResults.filter(each => Boolean(each))
      : [];

    if (loading) {
      return (
        <span className="exam-results-indicator">
          {prefix || ''}Calculating&hellip;
        </span>
      );
    }

    if (renderableExamResults.length === 0) {
      return null;
    }

    return (
      <span className="exam-results-indicator">
        {prefix || ''}
        {renderableExamResults.map(each => (
          <span key={each} className="exam-result">
            ({each})
          </span>
        ))}
      </span>
    );
  }
}
