import * as moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { WrappedFieldInputProps } from 'redux-form';

interface IDateInputProps {
  className?: string;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  input: WrappedFieldInputProps;
  disabled?: boolean;
  showTimeSelect?: boolean;
  showYearDropdown?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  readonly?: boolean;
  closeOnSelect?: boolean;
}
export default class DateInput extends React.PureComponent<
  IDateInputProps,
  void
> {
  public static defaultProps: Partial<IDateInputProps> = {
    showTimeSelect: false,
    showYearDropdown: true,
    dateFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm',
    readonly: false,
    closeOnSelect: true,
  };
  public render() {
    const {
      minDate,
      maxDate,
      className,
      disabled,
      showTimeSelect,
      showYearDropdown,
      dateFormat,
      timeFormat,
      readonly,
      closeOnSelect,
    } = this.props;

    const value = this.getValue();

    const timeProps = showTimeSelect ? { showTimeSelect, timeFormat } : {};

    return (
      <span
        className={
          typeof className === 'undefined' ? 'margin-left-base' : className
        }
      >
        <DatePicker
          className="no-touchevents-datepicker"
          fixedHeight={true}
          showYearDropdown={showYearDropdown}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          dateFormat={dateFormat}
          selected={value}
          onChange={newValue => this.handleChange(newValue)}
          disabled={disabled}
          readOnly={readonly}
          shouldCloseOnSelect={closeOnSelect}
          {...timeProps}
        />
        <input
          className="touchevents-datepicker"
          type="date"
          value={(value && value.format('YYYY-MM-DD')) || undefined}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.handleChange(moment.utc(event.target.value));
          }}
          disabled={disabled}
          readOnly={readonly}
        />
      </span>
    );
  }

  private handleChange(value: moment.Moment | null) {
    const { input, dateFormat } = this.props;
    const onChange = input && input.onChange;
    const newValue = value && value.format(dateFormat);
    if (onChange) {
      // value is case as "any" as redux form accepts values in addition to events but the type definition is wrong
      onChange(newValue as any);
    }
  }
  private getValue() {
    const { input } = this.props;
    const value = input && input.value;
    return value && moment.utc(value).isValid() ? moment.utc(value) : null;
  }
}
