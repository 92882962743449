import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { JournalType } from '../../actions/journals';
import { GET_COLLECTION, ITEMS_PER_PAGE } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { IJournalEntry } from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import Loading from '../loading';
import Pagination from '../pagination';
import JournalFeedItem from './journal-feed-item';

const { actions: { getCollection } } = collectionsModule;

const JOURNALS: keyof ICollectionsState = 'journals';
export const JOURNAL_FEED = 'JOURNAL_FEED';

interface IExternalProps {
  journalType?: JournalType;
  userId?: string;
  isViewingUnderStudentContext?: boolean;
  canEditPosts: boolean;
}

interface IProps extends IExternalProps {
  count: number;
  currentPage: number;
  journalEntries: List<IJournalEntry>;
  loading: boolean;
  userId: string;
  profile: IProfile;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
}

export class JournalEntryFeed extends React.PureComponent<IProps, void> {
  public onPaginationClick(
    event: React.MouseEvent<HTMLButtonElement>,
    nextPage: number
  ): void {
    const { journalType, userId } = this.props;
    const filters: TFilters = Map({
      journal_type: journalType || 'self',
      user: !journalType ? userId : '',
    }).filter(x => x);

    const options = {
      filters,
      page: nextPage,
      pageSize: ITEMS_PER_PAGE,
    };

    this.props.getCollection('journals', options, JOURNAL_FEED);
  }

  public render() {
    const {
      canEditPosts,
      count,
      currentPage,
      journalEntries,
      loading,
      userId,
      journalType,
      profile,
      isViewingUnderStudentContext,
    } = this.props;
    return loading ? (
      <Loading />
    ) : (
      <div className="journal-entry-feed">
        {journalEntries.count() ? (
          <div>
            {journalEntries.map(journalEntry => (
              <JournalFeedItem
                key={journalEntry.id}
                journalEntry={journalEntry}
                currentUserId={userId}
                canEditPosts={canEditPosts}
                profile={profile}
                isClassPostFeed={journalType === 'class'}
                isViewingUnderStudentContext={isViewingUnderStudentContext}
              />
            ))}
            <Pagination
              count={count}
              pageSize={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onClick={this.onPaginationClick.bind(this)}
            />
          </div>
        ) : (
          <p className="info">No journal entries</p>
        )}
      </div>
    );
  }
}
function mapStateToProps(
  { collectionsOld, profile, responses }: IStore,
  props: IExternalProps
) {
  const journalEntries =
    getCollectionItems(collectionsOld.journals, JOURNAL_FEED) ||
    List<IJournalEntry>();
  const journalEntriesCollection = getCollectionByName(
    collectionsOld.journals,
    JOURNAL_FEED
  );

  return _.extend({
    count: (journalEntriesCollection && journalEntriesCollection.count) || 0,
    currentPage:
      (journalEntriesCollection && journalEntriesCollection.page) || 1,
    journalEntries,
    loading: isPending(responses, GET_COLLECTION, JOURNALS),
    userId: props.userId || profile.id,
    profile,
  });
}

export default connect(mapStateToProps, { getCollection })(JournalEntryFeed);
