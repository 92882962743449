import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  UPDATE_CURRENT_CENTRE,
  updateCurrentCentre,
} from '../../actions/profile';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { ICentre } from '../../store/data-types/centres';
import { IProfile } from '../../store/data-types/profile';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';

const { actions: { getCollection } } = collectionsModule;

const CENTRE_LANDING_EQA = 'CENTRE_LANDING_EQA';

const getHeaders = (
  onCentreClick: (id: string) => void
): Array<IColumnData<ICentre>> => [
  {
    content: data => (
      <a onClick={onCentreClick.bind(null, data.id)}>{data.osms_data.name}</a>
    ),
    headerLabel: 'Centre Name',
    key: 'name',
    sortable: false,
  },
  {
    content: data =>
      data.has_outstanding_reviews ? 'Reviews outstanding' : 'Up to date',
    headerLabel: 'Status',
    key: 'status',
    sortable: false,
  },
];

interface IProps {
  centreCollection: ICollection<ICentre>;
  loading: boolean;
  profile: IProfile;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  updateCurrentCentre(
    centreId: string | null,
    reloadCurrentPage: boolean
  ): void;
}

export class CentreLandingEQA extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.onCentreClick = this.onCentreClick.bind(this);
  }

  public render() {
    const { centreCollection, loading } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">All my centres</h2>
        </ContentBoxHeader>
        <CollectionTable
          headers={getHeaders(this.onCentreClick)}
          collection={centreCollection}
          loading={loading}
          pageSize={10}
          getCollection={(options: ICollectionOptions) => {
            this.props.getCollection(
              'centres/eqa-stats',
              options,
              CENTRE_LANDING_EQA
            );
          }}
        />
      </ContentBox>
    );
  }

  public onCentreClick(id: string) {
    this.props.updateCurrentCentre(id, false);
  }
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  return {
    loading: anyPending(responses, [
      [GET_COLLECTION, CENTRE_LANDING_EQA],
      UPDATE_CURRENT_CENTRE,
    ]),
    profile,
    centreCollection: getCollectionByName(
      collectionsOld.get('centres/eqa-stats'),
      CENTRE_LANDING_EQA
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  updateCurrentCentre,
})(CentreLandingEQA);
