import { IQualificationRecord } from '../store/data-types/qualifications';

export const SET_QUALIFICATION = 'SET_QUALIFICATION';
export function setQualification(payload: IQualificationRecord) {
  return {
    type: SET_QUALIFICATION,
    payload,
  };
}

export const SET_MENU_DISPLAY = 'SET_MENU_DISPLAY';
export function setMenuDisplay(isShowing: boolean) {
  return {
    type: SET_MENU_DISPLAY,
    payload: isShowing,
  };
}
