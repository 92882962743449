import * as classNames from 'classnames';
import { Range } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

const { IconChevronLeft, IconChevronRight } = FontAwesome;

import {
  isActivityEventRecord,
  TTimelineDataList,
} from '../../store/data-types/timeline';

interface IProps {
  today: moment.Moment;
  viewing: moment.Moment;
  feed: TTimelineDataList;
  onChange(date: moment.Moment): void;
  onMouseDown(): void;
  onMouseUp(): void;
}

interface IPointerPosition {
  clientX: number;
}

const DAYS_IN_A_YEAR = 365;
const MONTHS_IN_A_YEAR = 12;
const ADDITIONAL_MONTHS = 2;
const MONTHS_TO_DISPLAY = MONTHS_IN_A_YEAR + ADDITIONAL_MONTHS * 2;
const ADDITIONAL_YEARS = 1;
const YEARS_TO_DISPLAY = 1 + ADDITIONAL_YEARS * 2;

export default class Timeline extends React.PureComponent<IProps, void> {
  private mouseDown = false;
  private lastPointerPosition: IPointerPosition | null = null;

  public constructor(props: IProps) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onClickChevronLeft = this.onClickChevronLeft.bind(this);
    this.onClickChevronRight = this.onClickChevronRight.bind(this);
  }

  public render() {
    const { today, viewing, feed } = this.props;

    const viewingStart = viewing.clone().subtract(6, 'months');
    const monthOffset = viewingStart
      .clone()
      .startOf('month')
      .diff(viewingStart, 'months', true);
    const yearOffset = viewingStart
      .clone()
      .startOf('year')
      .diff(viewingStart, 'years', true);
    const todayOffset = today.diff(viewingStart, 'years', true);

    return (
      <div
        className="timeline"
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onMouseDown}
      >
        <div className="timeline-key">
          <span className="timeline-key-item">
            <span className="timeline-key-box activity" />
            Activity
          </span>
          <span className="timeline-key-item">
            <span className="timeline-key-box deadline" />
            Deadline
          </span>
        </div>

        <div ref="timeline" className="timeline-bar-area">
          <IconChevronLeft
            className="timeline-chevron left"
            onClick={this.onClickChevronLeft}
          />
          <IconChevronRight
            className="timeline-chevron right"
            onClick={this.onClickChevronRight}
          />

          {Range(0, YEARS_TO_DISPLAY).map((index: number) => (
            <div
              key={index}
              className="timeline-year"
              style={{
                left: `${100 * (index - ADDITIONAL_YEARS + yearOffset)}%`,
              }}
            >
              {viewingStart
                .clone()
                .add(index - ADDITIONAL_YEARS, 'years')
                .format('YYYY')}
            </div>
          ))}
          {Range(0, MONTHS_TO_DISPLAY).map((index: number) => (
            <div
              key={index}
              className={classNames(
                'timeline-month',
                viewingStart
                  .clone()
                  .add(index - ADDITIONAL_MONTHS, 'months')
                  .month() % 2
                  ? 'even'
                  : 'odd'
              )}
              style={{
                left: `${100 /
                  MONTHS_IN_A_YEAR *
                  (index - ADDITIONAL_MONTHS + monthOffset)}%`,
              }}
            >
              {viewingStart
                .clone()
                .add(index - ADDITIONAL_MONTHS, 'months')
                .format('MMM')}
            </div>
          ))}
          <div className="timeline-bar">
            {feed.filter(item => item.id).map(item => {
              let yearDiff;

              if (isActivityEventRecord(item)) {
                yearDiff = moment
                  .utc(item.created)
                  .diff(viewingStart, 'years', true);

                return (
                  <div
                    key={item.id}
                    className={classNames('timeline-point solid')}
                    style={{ left: `${100 * yearDiff}%` }}
                  />
                );
              } else {
                if (!item.start_date || !item.end_date) {
                  return null;
                }

                yearDiff = moment
                  .utc(item.start_date)
                  .diff(viewingStart, 'years', true);

                const endDiff = moment
                  .utc(item.end_date)
                  .diff(item.start_date, 'years', true);

                return (
                  <div
                    key={item.id}
                    className={classNames('timeline-point')}
                    style={{
                      left: `${100 * yearDiff}%`,
                      width: `${100 * endDiff}%`,
                      transform: 'none',
                    }}
                  />
                );
              }
            })}
          </div>
          <div className="hide-today-overflow">
            <div
              className="marker-today"
              style={{ left: `${100 * todayOffset}%` }}
            />
          </div>
          <div className="marker-viewing" />
        </div>
      </div>
    );
  }

  private addMonthssToDate(months: number) {
    this.mouseDown = true;
    this.props.onMouseDown();

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.viewing.clone().add(months, 'months'));
    }

    this.mouseDown = false;
    this.props.onMouseUp();
  }

  private onClickChevronLeft() {
    this.addMonthssToDate(-1);
  }

  private onClickChevronRight() {
    this.addMonthssToDate(1);
  }

  private getPointerPosition(
    event:
      | (MouseEvent & TouchEvent)
      | (React.MouseEvent<HTMLDivElement> & React.TouchEvent<HTMLDivElement>)
  ) {
    return event.touches && event.touches.length
      ? {
          clientX: event.touches[0].clientX,
        }
      : {
          clientX: event.clientX,
        };
  }

  private onMouseDown(
    event: React.MouseEvent<HTMLDivElement> & React.TouchEvent<HTMLDivElement>
  ) {
    event.preventDefault();

    this.mouseDown = true;
    this.props.onMouseDown();
    this.lastPointerPosition = this.getPointerPosition(event);

    if (event.touches && event.touches.length) {
      window.addEventListener('touchmove', this.onMouseMove);
      window.addEventListener('touchend', this.onMouseUp);
    } else {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    }
  }

  private onMouseMove(event: MouseEvent & TouchEvent) {
    const pointerPosition = this.getPointerPosition(event);

    if (this.mouseDown && this.lastPointerPosition) {
      const pixelsMoved =
        this.lastPointerPosition.clientX - pointerPosition.clientX;
      const timelineWidth = (this.refs
        .timeline as HTMLDivElement).getBoundingClientRect().width;
      const daysMoved = pixelsMoved / timelineWidth * DAYS_IN_A_YEAR;

      if (typeof this.props.onChange === 'function') {
        this.props.onChange(this.props.viewing.clone().add(daysMoved, 'days'));
      }
    }

    this.lastPointerPosition = pointerPosition;
  }

  private onMouseUp() {
    this.mouseDown = false;
    this.props.onMouseUp();
    this.lastPointerPosition = null;

    window.removeEventListener('touchmove', this.onMouseMove);
    window.removeEventListener('touchend', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }
}
