import { SimpleRecord } from '@dabapps/simple-records';
import { Record } from 'immutable';
import { RecordInstance } from './records';

export interface IBlockCriterion {
  criterion_description: string;
  id: string;
}
export type IBlockCriterionRecord = RecordInstance<IBlockCriterion>;
export const BlockCriterionRecord = Record<IBlockCriterion>({
  criterion_description: '',
  id: '',
});

export interface IBlock {
  id: string;
  lower_mark: number;
  title: string;
  evidence_type_oral: boolean;
}
export type IBlockRecord = RecordInstance<IBlock>;
export const BlockRecord = Record<IBlock>({
  id: '',
  lower_mark: 0,
  title: '',
  evidence_type_oral: false,
});

export interface IGroup {
  id: string;
  title: string;
  lower_mark: number | null;
  total_marks: number;
  upper_mark: number;
}
export type IGroupRecord = RecordInstance<IGroup>;
export const GroupRecord = Record<IGroup>({
  id: '',
  title: '',
  lower_mark: 0,
  total_marks: 0,
  upper_mark: 0,
});

export type TMarkingCode = 'R' | 'PMD' | 'PF' | 'REFO' | 'RECR' | 'PorMD';

export const MARKING_CODES: { [P in TMarkingCode]: P } = {
  R: 'R',
  PMD: 'PMD',
  PF: 'PF',
  REFO: 'REFO',
  RECR: 'RECR',
  PorMD: 'PorMD',
};

export enum MarkingLevel {
  Pass = 1,
  Merit,
  Distinction,
}

export enum MarkingLevelPorMD {
  Pass = 1,
  MeritDistinction,
}

export interface IStrand {
  id: string;
  learner_tagging: boolean;
  marking_code: TMarkingCode;
  performance_record_count: number;
  title: string;
}
export type IStrandRecord = RecordInstance<IStrand>;
export const StrandRecord = Record<IStrand>({
  id: '',
  learner_tagging: false,
  marking_code: MARKING_CODES.R,
  performance_record_count: 0,
  title: '',
});

export type ICentreExamSpecRecord = Readonly<{
  id: string;
  name: string;
  assigned_external_quality_assurer_count: number;
}>;
export const CentreExamSpecRecord = SimpleRecord<ICentreExamSpecRecord>({
  id: '',
  name: '',
  assigned_external_quality_assurer_count: 0,
});
