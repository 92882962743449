import { Dict } from '@dabapps/simple-records';
import { AxiosError } from 'axios';
import { List, Map } from 'immutable';
import * as moment from 'moment';
import * as _ from 'underscore';

import { terminologyFromProfile } from './components/terminology';
import { IAssetRecord } from './store/data-types';
import { EvidenceType } from './store/data-types/constants';
import { MARKING_CODES } from './store/data-types/courses';
import {
  // tslint:disable-next-line:no-unused-variable
  IChecklistRecord,
  // tslint:disable-next-line:no-unused-variable
  ILearningOutcomeBlockRecord,
  LearningOutcomeBlockRecord,
} from './store/data-types/marksheets';
import {
  IProfile,
  // tslint:disable-next-line:no-unused-variable
  TRole,
} from './store/data-types/profile';
// tslint:disable-next-line:no-unused-variable
import { IUserTaskRecord } from './store/data-types/tasks';
import { ITaggingUserTask } from './store/data-types/tasks/tagging';

// No recursive types :(
export type ImmutableJson =
  | null
  | string
  | number
  | List<null | string | number>
  | Map<string, null | string | number>;
export type FormErrors = Dict<
  | ReadonlyArray<string>
  | string
  | ReadonlyArray<Dict<ReadonlyArray<string> | string>>
>;

export function getFullName(userData: IProfile) {
  const osmsData = userData.osms_data;
  const givenName = osmsData.given_name;
  const familyName = osmsData.family_name;
  return givenName ? [givenName, familyName].join(' ') : familyName;
}

export function getUsername(userData: IProfile) {
  return userData.osms_data.username;
}

export function getDaysAgo(date: moment.Moment): number {
  return moment.utc().diff(date, 'days');
}

export function formatDate(date: moment.Moment | null): string {
  return date ? date.local().format('ddd Do MMM YYYY') : '--';
}

export function formatShortDate(date: moment.Moment): string {
  return date.local().format('Do MMM');
}

export function formatTime(date: moment.Moment): string {
  return date.local().format('HH:mm');
}

export function formatDateForBackend(
  date: moment.Moment | null
): string | null {
  return date && date.format('YYYY-MM-DD');
}

export function formatDateTime(date: moment.Moment): string {
  return formatDate(date) + ' at ' + formatTime(date);
}

export function formatTimeAgo(date: moment.Moment): string {
  return moment.utc().diff(date, 'days') >= 1
    ? formatDateTime(date)
    : date.fromNow();
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

export function convertBytesToMegaBytes(bytes: number): number {
  return bytes / 1024 / 1024;
}

export function convertMegaBytesToBytes(megaBytes: number): number {
  return megaBytes * 1024 * 1024;
}

export function formatQueryParams(params?: {}): string {
  if (!params) {
    return '';
  }

  const filteredPairs = _.chain(params)
    .pairs()
    .filter(([key, value]) => value !== null && typeof value !== 'undefined')
    .value();

  if (!filteredPairs || !filteredPairs.length) {
    return '';
  }

  return '?' + filteredPairs.map(([key, value]) => `${key}=${value}`).join('&');
}

export function getCurrentCentreName(profile: IProfile): string {
  return profile.current_centre
    ? profile.current_centre.osms_data.name
    : 'UNKNOWN CENTRE';
}

export function getCurrentCentreId(profile: IProfile): string | undefined {
  return profile.current_centre ? profile.current_centre.id : undefined;
}

const regexAllUnderscores = /_/g;

export function formatRoles(
  roles: ReadonlyArray<TRole>,
  profile: IProfile
): string {
  if (roles.length === 0) {
    return 'Unknown Role';
  }

  return roles
    .map(item =>
      terminologyFromProfile(profile, roleConstantToTitleCaseAllWords(item))
    )
    .join(', ');
}

const MATCHES_QA = /\b[IE]?QA\b/gi;
const FIRST_LETTER = /\b\w/g;

export function constantToTitleCase(constant: string): string {
  return `${constant[0].toUpperCase()}${constant
    .substr(1)
    .toLowerCase()
    .replace(regexAllUnderscores, ' ')}`;
}

export function roleConstantToTitleCaseAllWords(constant: string): string {
  return constant
    .replace(regexAllUnderscores, ' ')
    .toLowerCase()
    .replace(FIRST_LETTER, firstLetter => firstLetter.toUpperCase());
}

export function formatTaskStatusConstant(constant: string): string {
  return constantToTitleCase(constant).replace(MATCHES_QA, match =>
    match.toUpperCase()
  );
}

export function constantToKebabCase(constant: string): string {
  return `${constant
    .toLowerCase()
    .replace('_', '-')
    .replace(' ', '-')}`;
}

export function sum(list: List<number>): number {
  return list.reduce((prev: number, current) => prev + current);
}

// tslint:disable-next-line:no-unused-variable
type TIdAndTitle = Readonly<{
  id: string;
  title: string;
}>;

export type TLearningOutcomeGroup = Readonly<
  TIdAndTitle & {
    blocks: List<ILearningOutcomeBlockRecord>;
  }
>;

export type TGroupedLearningOutcomeBlock = Readonly<
  TIdAndTitle & {
    blockIds: List<string>;
    strands: List<
      Readonly<
        TIdAndTitle & {
          groups: List<TLearningOutcomeGroup>;
        }
      >
    >;
  }
>;

export function getBlockIds(task: ITaggingUserTask): ReadonlyArray<string> {
  return _.flatten(
    task.checklists.map(checklist =>
      checklist.learning_outcomes.map(learningOutcome =>
        learningOutcome.learning_outcome_blocks.map(block => block.id)
      )
    )
  );
}

export function getOrdinal(value: number, total?: number) {
  const numString = Math.floor(value).toString();
  const finalDigit = numString.charAt(numString.length - 1);

  if (
    typeof total === 'number' &&
    Math.floor(value) === Math.floor(total) - 1
  ) {
    return 'final';
  }

  switch (finalDigit) {
    case '1':
      return `${numString}st`;
    case '2':
      return `${numString}nd`;
    case '3':
      return `${numString}rd`;
    default:
      return `${numString}th`;
  }
}

export function getLearningOutcomeBlockResults(
  checklists: List<IChecklistRecord>
) {
  return checklists
    .filter(checklist => checklist.strand.marking_code === MARKING_CODES.RECR)
    .map(checklist =>
      checklist.learning_outcomes.map(
        learningOutcome => learningOutcome.learning_outcome_blocks
      )
    )
    .flatten(2)
    .toList() as List<ILearningOutcomeBlockRecord>;
}

export function checkForLogout(error: AxiosError) {
  if (error.response) {
    const response = error.response;
    if (response.status === 401 || response.status === 403) {
      // make sure it's a 401/403 from manage, as we don't want to log out if we get
      // a 401/403 from s3 (which can happen).
      if (
        response.config &&
        response.config.url &&
        response.config.url.indexOf('/api/') === -1
      ) {
        // tslint:disable-next-line:no-console
        console.error(
          "We got a 401/403 from a non-manage URL. Not logging us out, but don't know what to do."
        );
      } else {
        // For now, log us out. In future this should probably show a dialog or something.
        window.location.replace('/accounts/logout/');
      }
    }
  }
}

export function clickOutside(
  element: HTMLElement | Element | null,
  eventTarget: HTMLElement | Element | null,
  callback: (...args: any[]) => any
) {
  let node: HTMLElement | Element | null = eventTarget;

  while (node && node.parentNode) {
    if (node === element) {
      return;
    }

    node = node.parentElement;
  }

  callback();
}

export const removeDuplicateAndUndefinedValues = (
  list: ReadonlyArray<string | number | undefined | null>
) => {
  return list.filter(
    (item, index) =>
      item !== null &&
      typeof item !== 'undefined' &&
      list.lastIndexOf(item) === index
  ) as ReadonlyArray<string | number>;
};

export function normalizeBoolean(
  value?: string | boolean | number | null
): boolean | undefined {
  return value === undefined ? value : Boolean(value);
}

export function getAssetName(asset: IAssetRecord): string {
  return asset.user_supplied_name || asset.filename;
}

export function asPromise<T, U>(
  callable: (data: T) => U
): (data: T) => Promise<U> {
  return (data: T) =>
    new Promise(resolve => {
      resolve(callable(data));
    });
}

// Avoid using this outside of mocking
export function asVariadicPromise<T>(
  callable: (...args: any[]) => T
): (...args: any[]) => Promise<T> {
  return (...args: any[]) =>
    new Promise(resolve => {
      resolve(callable.apply(null, args));
    });
}

export function getEvidenceTypeDisplay(
  asset: IAssetRecord,
  evidenceTypes: ReadonlyArray<EvidenceType>
): string {
  const evidenceType = evidenceTypes.find(
    each => each.key === asset.evidence_type
  );

  return evidenceType ? evidenceType.value : 'Not Set';
}
