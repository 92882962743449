import {
  CLEAR_TASK_UPDATE_STATUS,
  RESET_TARGET_TASK_STATUS,
  SET_TARGET_TASK_STATUS,
  TASK_DATES_UPDATED,
} from '../actions/tasks';
import { UPDATE_ITEM } from '../items/actions';
import { TStatus } from '../store/data-types/common';
import { isTStatus } from '../store/data-types/tasks';
import { IAction } from '../types';

export function targetTaskStatus(
  state: TStatus | null = null,
  action: IAction<TStatus | void, any>
) {
  switch (action.type) {
    case RESET_TARGET_TASK_STATUS:
      return null;
    case SET_TARGET_TASK_STATUS:
      if (isTStatus(action.payload)) {
        return action.payload;
      }
      // NOTE: in reality this will be unreachable
      return null;
    case UPDATE_ITEM.REQUEST:
      return null;
    default:
      return state;
  }
}

export function taskDatesUpdated(
  state: boolean = false,
  action: IAction<void, any>
) {
  switch (action.type) {
    case TASK_DATES_UPDATED:
      return true;
    case CLEAR_TASK_UPDATE_STATUS:
      return false;
    default:
      return state;
  }
}
