import { TStatusChange } from '../store/data-types/tasks';

type TStatusChangeLookupTable = { [StatusChange in TStatusChange]: string };

export const STATUS_CHANGES: TStatusChangeLookupTable = {
  HAND_IN: 'handed in',
  RE_OPENED: 're-opened',
  MARKED: 'marked',
  IQA_REJECTED: 'rejected',
  IQA_PASSED: 'passed',
  EQA_REJECTED: 'rejected',
  EQA_PASSED: 'passed',
};

export function lookupStatusChange(status: keyof typeof STATUS_CHANGES) {
  return STATUS_CHANGES[status];
}
