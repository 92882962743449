import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';

import { addImportAndRedirectToDetailPage } from '../../actions/bulk-user-import';
import { collectionsModule } from '../../reducers/collections';
import { IStore } from '../../store/';
import {
  BulkUploadRecord,
  IBulkUpload,
} from '../../store/data-types/bulk-upload';
import { IProfile } from '../../store/data-types/profile';
import { formatBytes, formatTimeAgo } from '../../utils';
import Loading from '../loading';

const { actions: { addItem, deleteItem, getCollection } } = collectionsModule;

const { IconUpload, IconTrash } = FontAwesome;

import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';

interface IProps {
  profile: IProfile;
  importsCollection: ICollection<IBulkUpload>;
  loading: boolean;
  deleteItem: typeof deleteItem;
  addImportAndRedirectToDetailPage(): void;
  getCollection(opts: ICollectionOptions): void;
}

const IMPORTS_LIST = 'IMPORTS_LIST';

function getHeaders(props: IProps): Array<IColumnData<IBulkUpload>> {
  return [
    {
      content: data => (
        <Link to={`/users/bulk-import/${data.id}/`}>
          {formatTimeAgo(data.created)}
        </Link>
      ),
      headerLabel: 'Date Created',
      key: 'created',
      sortable: false,
    },
    {
      content: data => formatTimeAgo(data.modified),
      headerLabel: 'Last Updated',
      key: 'modified',
      sortable: false,
    },
    {
      content: data => data.filename,
      headerLabel: 'Filename',
      key: 'filename',
      sortable: false,
    },
    {
      content: data => formatBytes(data.num_bytes),
      headerLabel: 'Size',
      key: 'num_bytes',
      sortable: false,
    },
    {
      content: data => data.state,
      headerLabel: 'State',
      key: 'state',
      sortable: false,
    },
    {
      content: data => (
        <IconTrash
          onClick={() => props.deleteItem('imports', data.id, IMPORTS_LIST)}
        />
      ),
      headerLabel: 'Delete',
      key: 'delete',
      sortable: false,
    },
  ];
}

export class BulkImport extends React.PureComponent<IProps, void> {
  public render() {
    const { importsCollection, loading } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Imported Users</h2>
        </ContentBoxHeader>
        <CollectionTable
          headers={getHeaders(this.props)}
          collection={importsCollection}
          loading={loading}
          pageSize={10}
          getCollection={this.props.getCollection}
        />
        <ContentBoxFooter className="padding-base">
          <Button
            className="primary"
            onClick={this.props.addImportAndRedirectToDetailPage}
          >
            <IconUpload className="icon-small" /> Upload New CSV
          </Button>
        </ContentBoxFooter>
      </ContentBox>
    );
  }
}

function mapStateToProps({ profile, collectionsOld, responses }: IStore) {
  const importsCollection = getCollectionByName(
    collectionsOld.get('imports'),
    IMPORTS_LIST
  );
  return {
    importsCollection,
    loading: isPending(responses, GET_COLLECTION, 'imports'),
    profile,
  };
}

export default connect(mapStateToProps, {
  addImportAndRedirectToDetailPage: () => addImportAndRedirectToDetailPage(),
  getCollection: (options: ICollectionOptions) => {
    return getCollection('imports', options, IMPORTS_LIST);
  },
  deleteItem,
})(BulkImport);
