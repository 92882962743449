import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import * as React from 'react';
import ModalCloseIcon from './modal-close-icon';

interface IProps {
  title: string | JSX.Element;
  okayLabel?: string;
  okayClass?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'warning'
    | 'success'
    | 'error';
  cancelLabel?: string;
  body?: React.ReactNode;
  closeModal(): void;
  onOkayClick(): void;
  onCancelClick(): void;
}

export default class ConfirmModal extends React.PureComponent<IProps, void> {
  public render() {
    const {
      body,
      cancelLabel,
      closeModal,
      okayLabel,
      onCancelClick,
      onOkayClick,
      okayClass,
      title,
    } = this.props;
    return (
      <Modal onClickOutside={() => closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => closeModal()} />
          <p>{title}</p>
        </ModalHeader>
        {body}
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-large">
            <Button onClick={() => onCancelClick()}>
              {cancelLabel || 'Cancel'}
            </Button>
            <Button
              className={okayClass || 'primary'}
              onClick={() => onOkayClick()}
            >
              {okayLabel || 'Okay'}
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </Modal>
    );
  }
}
