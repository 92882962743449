import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { CREATE_QUALIFICATION } from '../../../actions/qualifications';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { canManageQualifications } from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IProfile } from '../../../store/data-types/profile';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import { formatDate, getCurrentCentreName } from '../../../utils';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';

const { actions: { getCollection } } = collectionsModule;

const QUALIFICATION_LIST = 'QUALIFICATION_LIST';
const HEADERS: Array<IColumnData<IQualificationRecord>> = [
  {
    content: data => data.name,
    headerLabel: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    content: data => narrowToRecord(data.option).code,
    headerLabel: 'Code',
    key: 'option__code',
    sortable: true,
  },
  {
    content: data => formatDate(data.start_date),
    headerLabel: 'Start',
    key: 'start_date',
    sortable: true,
  },
  {
    content: data => formatDate(data.end_date),
    headerLabel: 'End',
    key: 'end_date',
    sortable: true,
  },
  {
    content: data => (
      <Link to={`/tasks/qualifications/${data.id}/`}>Manage</Link>
    ),
    headerLabel: 'Actions',
    key: 'actions',
    sortable: false,
  },
];

interface IQualificationManagement {
  getCollection: (
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ) => void;
  loading: boolean;
  profile: IProfile;
  qualificationCollection: ICollection<IQualificationRecord>;
  qualificationCreated: boolean;
}
export function QualificationManagement(props: IQualificationManagement) {
  const {
    loading,
    profile,
    qualificationCollection,
    qualificationCreated,
  } = props;
  if (!canManageQualifications(profile)) {
    return (
      <Alert className="error">
        <p>Sorry you are not allowed to see this page</p>
      </Alert>
    );
  }

  const currentCentreName = getCurrentCentreName(profile);
  return (
    <ContentBox>
      <ContentBoxHeader>
        <h2 className="font-size-large">
          Qualifications for {currentCentreName}
        </h2>
      </ContentBoxHeader>
      {qualificationCreated && (
        <Alert className="success">
          <p>Qualification added!</p>
        </Alert>
      )}
      <CollectionTable
        headers={HEADERS}
        collection={qualificationCollection}
        loading={loading}
        pageSize={10}
        getCollection={(options: ICollectionOptions) =>
          props.getCollection('courses', options, QUALIFICATION_LIST)}
      />
    </ContentBox>
  );
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  return {
    loading: isPending(responses, GET_COLLECTION, QUALIFICATION_LIST),
    profile,
    qualificationCollection: getCollectionByName(
      collectionsOld.courses,
      QUALIFICATION_LIST
    ),
    qualificationCreated: hasSucceeded(responses, CREATE_QUALIFICATION),
  };
}

export default connect(mapStateToProps, { getCollection })(
  QualificationManagement
);
