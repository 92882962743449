import * as React from 'react';
import { connect } from 'react-redux';

import {
  canManageQualifications,
  canSeeMyTasks,
  canSeeTimeline,
  canViewCourseworkProgressOverview,
} from '../../permissions';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import NavItem from '../nav/item';
import SubNav from '../nav/sub';
import Term from '../terminology';

interface IProps {
  location: {
    pathname: string;
  };
  profile: IProfile;
}

const MATCHES_TASKS = /^\/tasks\/[a-z0-9]{8}-[a-z0-9]{4}/;
const MATCHES_TIMELINE = /^\/tasks\/timeline\//;
const MATCHES_SUBSCRIPTION = /^\/tasks\/subscription-bank\//;
const MATCHES_QUALIFICATIONS = /^\/tasks\/qualifications\//;
const MATCHES_COURSEWORK = /^\/tasks\/coursework-progress-overview\//;

export class TasksPage extends React.PureComponent<IProps, void> {
  public render() {
    const { profile, location } = this.props;
    const { pathname } = location;

    return (
      <div>
        <SubNav>
          {canSeeMyTasks(profile) && (
            <NavItem href="/tasks/" pathname={pathname} matches={MATCHES_TASKS}>
              My <Term>Tasks</Term>
            </NavItem>
          )}
          {canSeeTimeline(profile) && (
            <NavItem
              href="/tasks/timeline/"
              matches={MATCHES_TIMELINE}
              pathname={pathname}
            >
              Timeline
            </NavItem>
          )}
          {canManageQualifications(profile) && (
            <NavItem
              href="/tasks/subscription-bank/"
              pathname={pathname}
              matches={MATCHES_SUBSCRIPTION}
            >
              Subscription Bank
            </NavItem>
          )}
          {canManageQualifications(profile) && (
            <NavItem
              href="/tasks/qualifications/"
              pathname={pathname}
              matches={MATCHES_QUALIFICATIONS}
            >
              Qualifications
            </NavItem>
          )}
          {canViewCourseworkProgressOverview(profile) && (
            <NavItem
              href="/tasks/overview/"
              pathname={pathname}
              matches={MATCHES_COURSEWORK}
            >
              Overview
            </NavItem>
          )}
        </SubNav>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps({ profile }: IStore) {
  return {
    profile,
  };
}

export default connect(mapStateToProps)(TasksPage);
