import { List } from 'immutable';
import { IAction } from '../types';

import * as timelineActions from '../actions/timeline';
import {
  ActivityEventRecord,
  // tslint:disable-next-line:no-unused-variable
  IActivityEvent,
} from '../store/data-types/activity-events';
// tslint:disable-next-line:no-unused-variable
import { IUserTask, UserTaskRecord } from '../store/data-types/tasks';
import {
  isActivityEventRecord,
  TimelineRecord,
  TTimelineData,
} from '../store/data-types/timeline';

type TKnownActivityTypes = [
  'CREATE_JOURNAL_POST',
  'UPDATE_JOURNAL_POST',
  'FILE_UPLOADED',
  'NEW_COMMENT',
  'MARKSHEET_HAND_IN',
  'MARKSHEET_MARKED'
];
const KNOWN_ACTIVITY_TYPES: TKnownActivityTypes = [
  'CREATE_JOURNAL_POST',
  'UPDATE_JOURNAL_POST',
  'FILE_UPLOADED',
  'NEW_COMMENT',
  'MARKSHEET_HAND_IN',
  'MARKSHEET_MARKED',
];

function group(groupKey: string): (input: TTimelineData) => string {
  return input => {
    if (isActivityEventRecord(input)) {
      return input.created.format(groupKey);
    }

    return input.end_date ? input.end_date.format(groupKey) : '';
  };
}

function groupFeed(input: TTimelineData): string {
  return group('MMMM Y')(input);
}

function groupMonth(input: TTimelineData): string {
  return group('Do MMMM Y')(input);
}

export function timeline(state = TimelineRecord(), action: IAction<any, any>) {
  switch (action.type) {
    case timelineActions.SET_TIMELINE_VIEWING:
      return state.set('viewing', action.payload);
    case timelineActions.GET_TIMELINE_DATA.REQUEST:
      const periods = (state.get('periods') || List()).push(action.payload);

      return state.set('periods', periods);
    case timelineActions.GET_TIMELINE_DATA.SUCCESS:
      const data = ((state.get('data') || List<TTimelineData>())
        .concat(
          List<IActivityEvent>(action.payload.activities)
            .map(activity => ActivityEventRecord(activity))
            .filter(
              activity =>
                KNOWN_ACTIVITY_TYPES.indexOf(activity.event_type as any) >= 0
            )
        )
        .concat(
          List<IUserTask>(action.payload.tasks).map(task =>
            UserTaskRecord(task)
          )
        ) as List<TTimelineData>)
        .filter(event => !isActivityEventRecord(event) || !event.account_hijack)
        .sort((a, b) => {
          const aDate = isActivityEventRecord(a) ? a.created : a.end_date;
          const bDate = isActivityEventRecord(b) ? b.created : b.end_date;

          if (!aDate && !bDate) {
            // both dates are not defined so they are sort equivalent
            return 0;
          }

          if (!aDate) {
            // the first date is not defined so should go after the second
            return 1;
          }

          if (!bDate) {
            // the second date is not defined so should go after the first
            return -1;
          }

          if (aDate < bDate) {
            return -1;
          } else if (bDate < aDate) {
            return 1;
          }

          return 0;
        });

      const groupedData = data
        .groupBy(groupFeed)
        .toMap()
        .map(month =>
          month
            .groupBy(groupMonth)
            .toMap()
            .map(entry => entry.toList())
        );

      return state.set('data', data).set('groupedData', groupedData);
    case timelineActions.GET_TIMELINE_DATA.FAILURE:
      return state.set('periods', List()).set('data', List());
    default:
      return state;
  }
}
