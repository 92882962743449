import * as classNames from 'classnames';
import { Editor, EditorState, RichUtils } from 'draft-js';
import * as React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

const EDITOR_INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
];

interface IStyleButtonProps {
  onToggle: (style: string) => void;
  active?: boolean;
  label: string;
  style: string;
}

interface IControlProps {
  onToggle: (value: EditorState) => void;
  value: EditorState;
}

function StyleButton({ active, onToggle, label, style }: IStyleButtonProps) {
  return (
    <span
      className={classNames('journal-editor-style-button', active && 'active')}
      onMouseDown={() => onToggle(style)}
    >
      {label}
    </span>
  );
}

const InlineStyleControls = ({ value, onToggle }: IControlProps) => {
  const currentStyle = value.getCurrentInlineStyle();
  return (
    <div className="journal-editor-controls">
      {EDITOR_INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={inlineStyle =>
            onToggle(RichUtils.toggleInlineStyle(value, inlineStyle))}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default function JournalEntryEditor({ input }: WrappedFieldProps<{}>) {
  if (!input || !input.value) {
    return <noscript />;
  }

  const { value, onChange } = input;

  return (
    <div className="journal-editor-root">
      <div className="journal-editor-toolbar">
        <InlineStyleControls
          value={value}
          onToggle={newValue => onChange(newValue)}
        />
      </div>
      <div className="journal-editor">
        <Editor
          editorState={value}
          spellCheck
          stripPastedStyles
          onChange={newState => onChange(newState as any)}
        />
      </div>
    </div>
  );
}
