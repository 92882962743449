import { Dict } from '@dabapps/simple-records';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { IStore } from '../store/';
import { IProfile } from '../store/data-types/profile';

interface IProps {
  profile: IProfile;
  children: string;
}

const startsWithVowel = /^[AEIOU]/i;

// Useful mixin instead of copy pasta manual lookup
export function terminologyFromProfile(
  profile: IProfile,
  term: string
): string {
  let table: Dict<string>;

  if (profile.current_centre && profile.current_centre.osms_data.terminology) {
    table = profile.current_centre.osms_data.terminology;
  } else if (profile.osms_data.osms && profile.osms_data.osms.terminology) {
    table = profile.osms_data.osms.terminology;
  } else {
    return term;
  }

  const inputSplit = term.split(' ');
  const lastIndex = inputSplit.length - 1;
  return _.reduce(
    inputSplit,
    (
      memo: { preceedingArticle: boolean; chain: ReadonlyArray<string> },
      currentWord,
      index
    ) => {
      const lowerCaseCurrentWord = currentWord.toLowerCase();
      // If we have an article, we want to note it and skip adding it
      if (
        index !== lastIndex &&
        (lowerCaseCurrentWord === 'a' || lowerCaseCurrentWord === 'an')
      ) {
        return {
          ...memo,
          preceedingArticle: true,
        };
      }

      if (memo.preceedingArticle) {
        const newTerm = table[currentWord] || currentWord;
        // If we were preceeded by an article, check whether it should be 'a' or 'an' and inject it
        if (startsWithVowel.test(newTerm)) {
          return {
            preceedingArticle: false,
            chain: memo.chain.concat(['an', newTerm]),
          };
        }
        return {
          preceedingArticle: false,
          chain: memo.chain.concat(['a', newTerm]),
        };
      }

      return {
        ...memo,
        chain: memo.chain.concat([table[currentWord] || currentWord]),
      };
    },
    {
      preceedingArticle: false,
      chain: [],
    }
  ).chain.join(' ');
}

// Handy component to do the lookup for you like...
// <Term>Students</Term>
export class Term extends React.PureComponent<IProps, void> {
  public render() {
    const term = this.props.children;
    return <span>{terminologyFromProfile(this.props.profile, term)}</span>;
  }
}

function mapStateToProps({ profile }: IStore) {
  return {
    profile,
  };
}

export default connect(mapStateToProps, {})(Term);
