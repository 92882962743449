import * as React from 'react';

interface IProps {
  condition: boolean;
}

type TProps = IProps & React.HTMLProps<HTMLDivElement>;

export default function If({ children, condition }: TProps) {
  return condition ? <span>{children}</span> : <noscript />;
}
