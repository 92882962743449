import { makeAsyncActionSet } from '@dabapps/redux-api-collections/dist/requests';
import axios from 'axios';
import { List, Map } from 'immutable';
import * as moment from 'moment';
import { Dispatch } from 'redux';
import { GetStateCallback } from '../requests/types';

import { collections } from '../collections';
import { collectionsModule as collectionsModuleOld } from '../reducers/collections';
import { IActivityEvent } from '../store/data-types/activity-events';
import { CLASS_STATUSES } from '../store/data-types/classes';
import { IUserTask } from '../store/data-types/tasks';

const { actions: { getAllCollection } } = collections;
// USER_TASKS still lives Oldin the old collections, so we must to a horrid rename import here
const {
  actions: { getAllCollection: getAllCollectionOld },
} = collectionsModuleOld;

export const ACTIVITY_EVENTS = 'activity-log';
export const USER_TASKS = 'tasks';
export const TASKS_TIMELINE = 'TASKS_TIMELINE';

const MONTHS_TO_REQUEST = 6;

export const GET_TIMELINE_DATA = makeAsyncActionSet('GET_TIMELINE_DATA');

export interface ITimelineOptions {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const SET_TIMELINE_VIEWING = 'SET_TIMELINE_VIEWING';

export function setTimelineViewing(date: moment.Moment) {
  return {
    payload: date,
    type: SET_TIMELINE_VIEWING,
  };
}

export function getTimelineData({ startDate, endDate }: ITimelineOptions) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    const period = startDate.format() + ' - ' + endDate.format();
    const periods = getState().timeline.get('periods') || List();

    if (periods.contains(period)) {
      return;
    }

    dispatch({
      payload: period,
      type: GET_TIMELINE_DATA.REQUEST,
    });

    const activityOptions = {
      filters: {
        created_after: startDate.format('YYYY-MM-DD'),
        created_before: endDate.format('YYYY-MM-DD'),
      },
    };

    const taskOptions = {
      filters: Map({
        ends_after: startDate.format('YYYY-MM-DD'),
        ends_before: endDate.format('YYYY-MM-DD'),
        user: getState().profile.id,
        class_task__task_class__status: CLASS_STATUSES.ACTIVE,
      }),
    };

    return axios
      .all([
        getAllCollection(ACTIVITY_EVENTS, activityOptions, TASKS_TIMELINE)(
          dispatch,
          getState,
          null
        ),
        getAllCollectionOld(USER_TASKS, taskOptions, TASKS_TIMELINE)(
          dispatch,
          getState
        ),
      ])
      .then(
        axios.spread((...args: any[]) => {
          const [activities, tasks] = args;

          return {
            activities: activities.data.results as IActivityEvent[],
            tasks: tasks.data.results as IUserTask[],
          };
        })
      )
      .then(result => {
        dispatch({
          payload: result,
          type: GET_TIMELINE_DATA.SUCCESS,
        });
      })
      .catch(error => {
        dispatch({
          payload: error,
          type: GET_TIMELINE_DATA.FAILURE,
        });

        return Promise.reject(error);
      });
  };
}

export function getOffsetTimelineData(offset: number) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    const { today } = getState().timeline;
    const monthOffset = offset * MONTHS_TO_REQUEST;

    getTimelineData({
      endDate: today
        .clone()
        .startOf('month')
        .add(monthOffset + MONTHS_TO_REQUEST / 2, 'months'),
      startDate: today
        .clone()
        .startOf('month')
        .add(monthOffset - MONTHS_TO_REQUEST / 2, 'months'),
    })(dispatch, getState);
  };
}

export function getTimelineDataAroundDate(date: moment.Moment) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    const { today, viewing } = getState().timeline;

    const chunkOffset = Math.round(
      // Next closest chunk
      viewing
        .clone()
        .startOf('month')
        .diff(today.clone().startOf('month'), 'months') / MONTHS_TO_REQUEST
    );

    getOffsetTimelineData(chunkOffset)(dispatch, getState);
    getOffsetTimelineData(chunkOffset - 1)(dispatch, getState);
    getOffsetTimelineData(chunkOffset + 1)(dispatch, getState);
  };
}
