import {
  recordOrIdArray,
  RecordWithConstructor,
  SimpleRecord,
} from '@dabapps/simple-records';
import { Record } from 'immutable';
import * as moment from 'moment';
import { TStatus } from './common';
import {
  // tslint:disable-next-line:no-unused-variable
  IProfile,
  // tslint:disable-next-line:no-unused-variable
  IProfileInput,
  IProfileOsmsData,
  ISimpleUser,
  // tslint:disable-next-line:no-unused-variable
  ISimpleUserInput,
  ProfileOsmsDataRecord,
  ProfileRecord,
} from './profile';
import { RecordInstance } from './records';

export type TClassStatus = 'ACTIVE' | 'ARCHIVED';

export const CLASS_STATUSES: { [P in TClassStatus]: P } = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export interface IClass {
  created: moment.Moment;
  centre: string;
  qualification?: string;
  description?: string;
  id: string;
  modified: moment.Moment;
  name: string;
  students: ReadonlyArray<ISimpleUser> | ReadonlyArray<string>;
  teachers: ReadonlyArray<ISimpleUser> | ReadonlyArray<string>;
  internal_quality_assurers: ReadonlyArray<ISimpleUser> | ReadonlyArray<string>;
  status: TClassStatus;
  is_archived: boolean;
}

export type IClassRecord = RecordInstance<IClass>;
const ClassInner = Record<IClass>({
  centre: '',
  created: moment.utc(),
  description: '',
  id: '',
  modified: moment.utc(),
  name: '',
  qualification: undefined,
  students: [],
  teachers: [],
  internal_quality_assurers: [],
  status: CLASS_STATUSES.ACTIVE,
  is_archived: false,
});

export function ClassRecord(input: Partial<IClass>): IClassRecord {
  const item = ClassInner(input);
  const students = input.students
    ? recordOrIdArray<ISimpleUserInput, ISimpleUser>(
        input.students,
        ProfileRecord
      )
    : [];
  const teachers = input.teachers
    ? recordOrIdArray<ISimpleUserInput, ISimpleUser>(
        input.teachers,
        ProfileRecord
      )
    : [];
  const internalQualityAssurers = input.internal_quality_assurers
    ? recordOrIdArray<ISimpleUserInput, ISimpleUser>(
        input.internal_quality_assurers,
        ProfileRecord
      )
    : [];
  return item
    .set('created', input.created ? moment.utc(input.created) : moment.utc())
    .set('modified', input.modified ? moment.utc(input.modified) : moment.utc())
    .set('students', students)
    .set('teachers', teachers)
    .set('internal_quality_assurers', internalQualityAssurers);
}

export interface IClassOption {
  id: string;
  name: string;
}
export const ClassOptionRecord = SimpleRecord<IClassOption>({
  id: '',
  name: '',
});

export type ITaskStatus = Readonly<{
  id: string;
  status: TStatus;
  title: string;
  progress: number | null;
}>;
export const TaskStatusRecord = SimpleRecord<ITaskStatus>({
  id: '',
  status: 'IN_PROGRESS',
  title: '',
  progress: null,
});

interface IClassStudentDetailsShared {
  id: string;
  osms_id: string;
  quota: number;
  usage: number;
}
export type IClassStudentDetailsInput = Readonly<
  IClassStudentDetailsShared & {
    tasks_in_progress_count: number;
    tasks_awaiting_marking_count: number;
    tasks_marked_count: number;
    tasks_awarded_count: number;
    osms_data: Partial<IProfileOsmsData>;
  }
>;
export type IClassStudentDetails = Readonly<
  IClassStudentDetailsShared & {
    osms_data: IProfileOsmsData;
    tasks_in_progress_count: number;
    tasks_awaiting_marking_count: number;
    tasks_marked_count: number;
    tasks_awarded_count: number;
  }
>;
export const ClassStudentDetailsRecord = RecordWithConstructor<
  IClassStudentDetailsInput,
  IClassStudentDetails
>(
  {
    id: '',
    osms_id: '',
    quota: 0,
    usage: 0,
    osms_data: {},
    tasks_in_progress_count: 0,
    tasks_awaiting_marking_count: 0,
    tasks_marked_count: 0,
    tasks_awarded_count: 0,
  },
  input => {
    return {
      ...input,
      osms_data: ProfileOsmsDataRecord(input.osms_data),
    };
  }
);
