import { ModalBody } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import TagFilesForm from '../tag-files/tag-files-form';

import { ISimpleUser } from '../../../store/data-types/profile';

interface IExternalProps {
  assetIds: List<string>;
  isLearner: boolean;
  currentUser: ISimpleUser;
  afterSuccessfulTag(): void;
}

export default class TagFiles extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const { assetIds, afterSuccessfulTag, isLearner, currentUser } = this.props;
    return (
      <ModalBody className="padding-none fix-table-column-headers">
        <TagFilesForm
          assetIds={assetIds}
          isLearner={isLearner}
          afterSuccessfulTag={afterSuccessfulTag}
          forUser={currentUser}
        />
      </ModalBody>
    );
  }
}
