import {
  Button,
  Column,
  ContentBoxHeader,
  DabIpsum,
  Row,
  Well,
} from '@dabapps/roe';
import { Collection, List, Map, Seq } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollection } from '../../collections/types';

import {
  ActivityEventRecord,
  IActivityEventRecord,
} from '../../store/data-types/activity-events';
import { IJournalEntry } from '../../store/data-types/journals';
import { IUserTaskRecord, UserTaskRecord } from '../../store/data-types/tasks';
import { TTimelineData } from '../../store/data-types/timeline';
import TasksVariableDay from './tasks-variable-day';

const { IconFileTextO, IconCalendarCheckO } = FontAwesome;

interface ITasksEventsMonthProps {
  month: string;
  days: Map<string, List<TTimelineData>>;
}

type TTasksEventsMonthProps = ITasksEventsMonthProps &
  React.HTMLProps<HTMLDivElement>;

export const TasksMonth: React.SFC<TTasksEventsMonthProps> = ({
  days,
  month,
}) => (
  <Row className="tasks-month">
    <Column
      className="icon-list xs-display-none md-display-block"
      sm={6}
      md={1}
    />
    <Column xs={12} md={10}>
      <div className="month">
        <span>{month}</span>
      </div>
      {days
        .map((entries, day) => (
          <TasksVariableDay key={day} entries={entries} day={day} />
        ))
        .toList()}
    </Column>
  </Row>
);

export default TasksMonth;
