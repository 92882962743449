import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { ICollection, ICollectionOptions } from '../../collections/types';
const { IconSort, IconSortAsc, IconSortDesc } = FontAwesome;
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import * as _ from 'underscore';

import Pagination from '../../components/pagination';
import Loading from '../loading';
import SortIcon from './sort-icon';

export interface IColumnData<TData> {
  headerLabel: string;
  headerRenderer?: React.ReactNode;
  key: string;
  sortable: boolean;
  width?: number | string;
  className?: string;
  content(data: TData): React.ReactNode;
}

export interface IBaseTableProps<TData> {
  loading?: boolean;
  headers: Array<IColumnData<TData>>;
  fixRowHeaders?: boolean;
  striped?: boolean;
  hover?: boolean;
}

interface IProps<TData> extends IBaseTableProps<TData> {
  tableCellClassName?: string;
  items: List<TData>;
  noItemsMessage?: string;
  useCellForFirstColumn?: boolean;
}

export default class CollectionTable<
  TData extends { id: string }
> extends React.PureComponent<IProps<TData>, void> {
  public constructor(props: IProps<TData>) {
    super(props);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderNoItemsMessage = this.renderNoItemsMessage.bind(this);
  }

  public render() {
    const { headers, items, loading } = this.props;
    const firstColumnWidth = this.props.headers[0].width || 100;
    const { striped, hover } = this.props;
    const tableProps = this.props.fixRowHeaders
      ? {
          fixRowHeaders: this.props.fixRowHeaders,
          rowHeaderWidth: firstColumnWidth,
        }
      : {};
    return (
      <Row>
        <Table
          className="class-table"
          fill
          striped={striped}
          hover={hover}
          {...tableProps}
        >
          {this.renderHeaders()}
          <TableBody>
            {loading ? (
              <tr>
                <td colSpan={this.props.headers.length}>
                  <Loading />
                </td>
              </tr>
            ) : items.count() > 0 ? (
              items.map(this.renderRow)
            ) : (
              this.renderNoItemsMessage()
            )}
          </TableBody>
        </Table>
      </Row>
    );
  }

  private renderNoItemsMessage() {
    const { noItemsMessage, headers } = this.props;
    return (
      <TableRow>
        <TableCell colSpan={headers.length}>
          <p className="info text-align-center">
            {noItemsMessage || 'Nothing to display.'}
          </p>
        </TableCell>
      </TableRow>
    );
  }

  private renderHeaders() {
    return (
      <TableHead>
        <TableRow>
          {this.props.headers.map((header, index) => (
            <TableHeader
              key={header.headerLabel}
              width={header.width}
              className={header.className}
            >
              {header.headerRenderer
                ? header.headerRenderer
                : header.headerLabel}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  private renderRow(collectionItem: TData) {
    const [first, ...rest] = this.props.headers;
    if (!first) {
      return;
    }
    const FirstColumnComponent = this.props.useCellForFirstColumn
      ? TableCell
      : TableHeader;
    return (
      <TableRow key={collectionItem.id}>
        <FirstColumnComponent
          width={first.width}
          className={this.props.tableCellClassName}
        >
          {first.content(collectionItem)}
        </FirstColumnComponent>
        {rest.map(header => (
          <TableCell
            key={header.headerLabel}
            width={header.width}
            className={this.props.tableCellClassName}
          >
            {header.content(collectionItem)}
          </TableCell>
        ))}
      </TableRow>
    );
  }
}
