import * as React from 'react';

interface IProps {
  onNewClick(): void;
  onExistingClick(): void;
}

export default class AddNewOrExistingAssets extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { onNewClick, onExistingClick } = this.props;
    return (
      <span>
        <a
          onClick={event => {
            event.preventDefault();
            onNewClick();
          }}
        >
          upload new
        </a>{' '}
        |{' '}
        <a
          onClick={event => {
            event.preventDefault();
            onExistingClick();
          }}
        >
          manage tagged files&hellip;
        </a>
      </span>
    );
  }
}
