import { recordOrId, SimpleRecord } from '@dabapps/simple-records';
import { List, Map, Record } from 'immutable';
import * as moment from 'moment';
import {
  IProfile, // TODO: We should really provide a cut down usertype here
  ProfileRecord,
} from './profile';
import { RecordInstance } from './records';

// TODO: Move Assets to SimpleRecord so we don't have to have this twice
export type IAssetRaw = Readonly<{
  owner: string;
  created_by: string;
  download_url: string;
  filename: string;
  id: string;
  modified: moment.Moment;
  num_bytes: number;
  thumbnail_url: string;
  is_tagged_to_anything: boolean;
  user_tasks: ReadonlyArray<string>;
  learning_outcome_blocks: ReadonlyArray<string>;
}>;

interface IAsset {
  created: moment.Moment;
  owner: string;
  created_by: string;
  download_url: string;
  filename: string;
  id: string;
  modified: moment.Moment;
  num_bytes: number;
  thumbnail_url: string;
  is_tagged_to_anything: boolean;
  user_tasks: List<string>;
  learning_outcome_blocks: List<string>;
  evidence_type: string;
  user_supplied_name: string;
  description: string;
}

export type IAssetRecord = RecordInstance<IAsset>;
const AssetRecordInner = Record<IAsset>({
  created: moment.utc(),
  created_by: '',
  owner: '',
  download_url: '',
  filename: '',
  id: '',
  modified: moment.utc(),
  num_bytes: 0,
  thumbnail_url: '',
  is_tagged_to_anything: true,
  user_tasks: List<string>(),
  learning_outcome_blocks: List<string>(),
  evidence_type: '',
  user_supplied_name: '',
  description: '',
});

export function AssetRecord(asset: Partial<IAsset>): IAssetRecord {
  const item = AssetRecordInner(asset);

  return item
    .set('created', asset.created ? moment.utc(asset.created) : moment.utc())
    .set('modified', asset.modified ? moment.utc(asset.modified) : moment.utc())
    .set('user_tasks', List<string>(item.user_tasks))
    .set('learning_outcome_blocks', List<string>(item.learning_outcome_blocks));
}

interface IComment {
  children: List<ICommentRecord>;
  content: string;
  created: moment.Moment;
  id: string;
  modified: moment.Moment;
  owner: IProfile;
  parent: string | null;
}

export type ICommentRecord = RecordInstance<IComment>;
export const CommentRecordInner = Record<IComment>({
  children: List<ICommentRecord>(),
  content: '',
  created: moment.utc(),
  id: '',
  modified: moment.utc(),
  owner: ProfileRecord({}),
  parent: null,
});

export function CommentRecord(comment: Partial<IComment>): ICommentRecord {
  const item = CommentRecordInner(comment);
  const owner = ProfileRecord(comment.owner || {});
  const children = List(
    (comment && comment.children) || []
  ).map((child: Partial<IComment>) => CommentRecord(child));

  return item
    .set(
      'created',
      comment.created ? moment.utc(comment.created) : moment.utc()
    )
    .set(
      'modified',
      comment.modified ? moment.utc(comment.modified) : moment.utc()
    )
    .set('children', List<ICommentRecord>(children))
    .set('owner', owner);
}

export type TResponseErrors = Map<string, string>;

interface IQuota {
  id: string;
  name: string;
  username: string;
  quota: number;
  usage: number;
  exemption: boolean;
}

export type IQuotaRecord = RecordInstance<IQuota>;
export const QuotaRecord = Record<IQuota>({
  exemption: false,
  name: '',
  username: '',
  id: '',
  quota: 0,
  usage: 0,
});

interface IFileProgress {
  fileNumber: number;
  progress: number;
  totalFiles: number;
}
export type IFileProgressRecord = RecordInstance<IFileProgress>;
export const FileProgressRecord = Record<IFileProgress>({
  fileNumber: 1,
  progress: 0,
  totalFiles: 1,
});

export interface IExamBoard {
  id: string;
  name: string;
}
export type IExamBoardRecord = RecordInstance<IExamBoard>;
export const ExamBoardRecord = Record<IExamBoard>({ name: '', id: '' });

export interface IExamSpec {
  id: string;
  name: string;
  subject_code: string;
  exam_board: IExamBoardRecord;
}
const ExamSpecInner = Record<IExamSpec>({
  exam_board: ExamBoardRecord({}),
  id: '',
  name: '',
  subject_code: '',
});
export type IExamSpecRecord = RecordInstance<IExamSpec>;
export function ExamSpecRecord(input: Partial<IExamSpec>): IExamSpecRecord {
  const item = ExamSpecInner(input);
  return item.set(
    'exam_board',
    input.exam_board ? ExamBoardRecord(input.exam_board) : ExamBoardRecord({})
  );
}

export interface IOption {
  id: string;
  exam_spec: string | IExamSpec;
  qca_number?: string;
  code?: string;
  title: string;
  complex_roc: boolean;
  description?: string;
}
const OptionInner = Record<IOption>({
  exam_spec: ExamSpecRecord({}),
  id: '',
  qca_number: '',
  code: '',
  title: '',
  complex_roc: false,
  description: '',
});
export type IOptionRecord = RecordInstance<IOption>;
export function OptionRecord(input: Partial<IOption>): IOptionRecord {
  const item = OptionInner(input);
  if (input.exam_spec) {
    const examSpec = recordOrId<IExamSpec, IExamSpecRecord>(
      input.exam_spec,
      ExamSpecRecord
    );
    item.set('exam_spec', examSpec);
  }
  return item;
}

export interface IRadioButton {
  key: string;
  label: string;
}

export type IRadioButtonRecord = RecordInstance<IRadioButton>;
export const RadioButtonRecord = Record<IRadioButton>({
  key: '',
  label: '',
});

export type IFeatureFlags = Readonly<{}>;
export const FeatureFlagsRecord = SimpleRecord<IFeatureFlags>({});
