import * as React from 'react';

import * as moment from 'moment';
import {
  DataShape,
  Field,
  FieldArray,
  FormComponentProps,
  FormProps,
  reduxForm,
} from 'redux-form';
import { formatDate, FormErrors } from '../../../utils';
import DateInput from '../../date-input';
import GroupField from '../../forms/group-field';

export const EDIT_USER_TASK_FORM = 'EDIT_USER_TASK_FORM';

interface IExternalProps extends FormComponentProps {
  errors?: FormErrors;
  initialValues?: IFormData;
  isLoading?: boolean;
  classTaskStartDate: moment.Moment | null;
  classTaskEndDate: moment.Moment | null;
}

type IProps = IExternalProps & FormProps<DataShape, void, void>;

function EditUserTaskForm(props: IProps) {
  const {
    handleSubmit,
    children,
    errors,
    isLoading,
    classTaskStartDate,
    classTaskEndDate,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <GroupField
        name="start_date"
        type="date"
        label="Start Date"
        disabled={isLoading}
        metalabel="optional"
        component={field => <DateInput {...field} />}
        formErrors={errors}
      />
      <GroupField
        name="end_date"
        type="date"
        label="End Date"
        disabled={isLoading}
        metalabel="optional"
        component={field => <DateInput {...field} />}
        formErrors={errors}
      />
      {children}
    </form>
  );
}

export interface IFormData {
  start_date?: moment.Moment | null | string;
  end_date?: moment.Moment | null | string;
}

export default reduxForm({ form: EDIT_USER_TASK_FORM })(EditUserTaskForm);
