import {
  anyPending,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Well,
} from '@dabapps/roe';
import { Dict, narrowToRecord } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { IRouteProps as IBaseRouteProps } from '../../../';
import {
  resetAllRecordedResultStatuses,
  SUBMIT_PERFORMANCE,
} from '../../../actions/marksheet';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../../collections/types';
import { LOAD_ITEM } from '../../../items/actions';
import {
  getViewingStudentIdIfRole,
  TEACHER,
  userHasRoles,
} from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import {
  IChecklistRecord,
  ILearningOutcomeBlockRecord,
  ILearningOutcomeRecord,
} from '../../../store/data-types/marksheets';
import { IProfile } from '../../../store/data-types/profile';
import {
  IUserTaskGrade,
  IUserTaskRecord,
  STATUS,
} from '../../../store/data-types/tasks';
import { FormErrors } from '../../../utils';
import {
  constantToTitleCase,
  formatDate,
  formatDateTime,
  getLearningOutcomeBlockResults,
  getOrdinal,
} from '../../../utils';
import Loading from '../../loading';
import ProgressBar from '../../progress-bar';
import Term from '../../terminology';
import ExamResultsIndicator from '../exam-results-indicator';
import GradeIndicator from '../grade-indicator';
import StatusIndicator from '../status-indicator';
import LearningOutcome from './learning-outcome';
import PerformanceRecords from './performance-records';

const { actions: { loadItem, updateItem } } = itemsModule;
const { actions: { clearCollection, getAllCollection } } = collectionsModule;

const {
  IconCheckSquare,
  IconSquareO,
  IconCheck,
  IconTimes,
  IconStackOverflow,
} = FontAwesome;

interface IRouteProps extends IBaseRouteProps {
  params: {
    id: string;
    student?: string;
  };
}

interface IPropsWithoutActions {
  checklists: List<IChecklistRecord>;
  learningOutcomeBlockResults: List<ILearningOutcomeBlockRecord>;
  isTeacher: boolean;
  loading: boolean;
  task: IUserTaskRecord | null;
  taskClass: IClassRecord | null;
  taskGrade: IUserTaskGrade | null;
  gradeLoading: boolean;
  profile: IProfile;
}

interface IProps extends IPropsWithoutActions {
  resetAllRecordedResultStatuses(): void;
  clearCollection(type: keyof ICollectionsState, tag?: string): AxiosPromise;
  loadItem(type: keyof IItemsState, itemId: string): AxiosPromise;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): AxiosPromise;
}

export type ILearningOutcomeBlockInitialValues = Dict<string | boolean>;

const CHECKLIST = 'CHECKLIST';

function allChecked(
  learningOutcome: ILearningOutcomeRecord,
  field: keyof ILearningOutcomeBlockRecord
): boolean {
  return learningOutcome.learning_outcome_blocks.every(block => !!block[field]);
}

function getInitialValuesFromLearningOutcome(
  learningOutcome: ILearningOutcomeRecord
): ILearningOutcomeBlockInitialValues {
  return learningOutcome.learning_outcome_blocks.reduce(
    (
      initialValues: ILearningOutcomeBlockInitialValues,
      learningOutcomeBlock
    ) => ({
      ...initialValues,
      [`checked_by_student-${learningOutcomeBlock.id}`]: learningOutcomeBlock.checked_by_student,
      [`checked_by_teacher-${learningOutcomeBlock.id}`]: learningOutcomeBlock.checked_by_teacher,
      [`checked_by_iqa-${learningOutcomeBlock.id}`]: learningOutcomeBlock.checked_by_iqa,
      [`checked_by_eqa-${learningOutcomeBlock.id}`]: learningOutcomeBlock.checked_by_eqa,
      [`recorded_result-${learningOutcomeBlock.id}`]: learningOutcomeBlock.recorded_result,
    }),
    {
      [`checked_by_student-${learningOutcome.id}`]: allChecked(
        learningOutcome,
        'checked_by_student'
      ),
      [`checked_by_teacher-${learningOutcome.id}`]: allChecked(
        learningOutcome,
        'checked_by_teacher'
      ),
      [`checked_by_iqa-${learningOutcome.id}`]: allChecked(
        learningOutcome,
        'checked_by_iqa'
      ),
      [`checked_by_eqa-${learningOutcome.id}`]: allChecked(
        learningOutcome,
        'checked_by_eqa'
      ),
    }
  );
}

export class ChecklistPage extends React.PureComponent<
  IRouteProps & IProps,
  void
> {
  public constructor(props: IRouteProps & IProps) {
    super(props);
    this.loadGrade = this.loadGrade.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem('tasks', this.props.params.id).then(response => {
      const data: IUserTaskRecord = response.data;
      return this.props.loadItem('classes', data.class_task.task_class.id);
    });
    this.loadGrade();
    this.props.getAllCollection(
      'marksheets/checklists',
      { filters: Map({ user_task: this.props.params.id }) },
      CHECKLIST
    );
  }

  public componentWillUnmount() {
    this.props.resetAllRecordedResultStatuses();
    this.props.clearCollection('marksheets/checklists', CHECKLIST);
  }

  public render() {
    const {
      isTeacher,
      task,
      taskClass,
      checklists,
      learningOutcomeBlockResults,
      loading,
      taskGrade,
      gradeLoading,
      profile,
    } = this.props;

    if (!task || !taskClass || loading) {
      return <Loading />;
    }

    const viewingStudentId = getViewingStudentIdIfRole(
      profile,
      this.props.params.student
    );
    const baseMarksheetLink = `/tasks/marksheet/${task.id}/`;
    const marksheetLink = viewingStudentId
      ? `/users/student/${viewingStudentId}${baseMarksheetLink}`
      : baseMarksheetLink;

    return (
      <ContentBox className="marksheet">
        <ContentBoxHeader>
          <h3>
            <Term>Marksheet</Term> Checklist
            {task.class_task.task_class.is_archived && (
              <span>
                {' '}
                (ARCHIVED <Term>CLASS</Term>)
              </span>
            )}
          </h3>
        </ContentBoxHeader>

        <Section>
          <Row>
            <Column>
              <h3>
                <strong>{task.class_task.component.title}</strong> for{' '}
                {narrowToRecord(task.user).osms_data.name}
              </h3>
              <div className="dates">
                <p>
                  <span>
                    Start date:{' '}
                    <strong>
                      {task.class_task.start_date
                        ? formatDate(task.class_task.start_date)
                        : '--'}
                    </strong>
                  </span>
                  <span>
                    End date:{' '}
                    <strong>
                      {task.class_task.end_date
                        ? formatDate(task.class_task.end_date)
                        : '--'}
                    </strong>
                  </span>
                </p>
              </div>
            </Column>
          </Row>
          <Row>
            <Column md={6}>
              <p>
                <span className="status">
                  <strong>Status: </strong>
                  <StatusIndicator task={task}>
                    <GradeIndicator
                      loading={gradeLoading}
                      grade={taskGrade && taskGrade.grade}
                      prefix=": "
                    />
                    <ExamResultsIndicator
                      examResults={task.exam_results}
                      prefix=" "
                    />
                  </StatusIndicator>
                </span>
              </p>
            </Column>

            <Column md={6}>
              <SpacedGroup className="md-float-right">
                <Link className="button margin-top-base" to={marksheetLink}>
                  <Term>Marksheet</Term>
                </Link>
              </SpacedGroup>
            </Column>
          </Row>
        </Section>

        {task.status !== 'IN_PROGRESS' &&
          learningOutcomeBlockResults.count() > 0 && (
            <Section>
              <Table striped bordered fill>
                <TableHead>
                  <TableRow>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Result</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {learningOutcomeBlockResults.map(block => (
                    <TableRow key={block.id}>
                      <TableCell>{block.block.title}</TableCell>
                      <TableCell>
                        {block.recorded_result || (
                          <span className="info">Nothing to display</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Section>
          )}

        <Section>
          <Table className="checklist" fill>
            {checklists.map(checklist => {
              const selfMark = checklist.strand.learner_tagging;

              return [
                <PerformanceRecords
                  key="performance-records"
                  selfMark={selfMark}
                  checklist={checklist}
                  isTeacher={isTeacher}
                  task={task}
                />,
                checklist.learning_outcomes.map(learningOutcome => (
                  <LearningOutcome
                    checklist={checklist}
                    key={learningOutcome.id}
                    task={task}
                    taskClass={taskClass}
                    initialValues={getInitialValuesFromLearningOutcome(
                      learningOutcome
                    )}
                    form={`learning-outcome-form-${learningOutcome.id}`}
                    learningOutcome={learningOutcome}
                    selfMark={selfMark}
                  />
                )),
              ];
            })}
          </Table>
        </Section>
        <Section>
          <Row>
            <Column xs={12}>
              <Link className="button margin-top-base" to={marksheetLink}>
                Back to <Term>Marksheet</Term>
              </Link>
            </Column>
          </Row>
        </Section>
      </ContentBox>
    );
  }

  private loadGrade() {
    this.props.loadItem('tasks/grade', this.props.params.id);
  }
}

function mapStateToProps(
  { collectionsOld, itemsOld, profile, responses }: IStore,
  props: IRouteProps
): IPropsWithoutActions {
  const checklists =
    getCollectionItems(
      collectionsOld.get('marksheets/checklists'),
      CHECKLIST
    ) || List<IChecklistRecord>();

  const learningOutcomeBlockResults = getLearningOutcomeBlockResults(
    checklists
  );

  return {
    checklists,
    learningOutcomeBlockResults,
    isTeacher: userHasRoles(profile, Set.of(TEACHER)),
    profile,
    task: itemsOld.get('tasks'),
    taskClass: itemsOld.get('classes'),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'marksheets/checklists'],
      [LOAD_ITEM, 'tasks'],
      [LOAD_ITEM, 'classes'],
    ]),
    taskGrade: itemsOld.get('tasks/grade'),
    gradeLoading: isPending(responses, LOAD_ITEM, 'tasks/grade'),
    ...props,
  };
}

export default connect(mapStateToProps, {
  clearCollection,
  loadItem,
  getAllCollection,
  resetAllRecordedResultStatuses,
})(ChecklistPage);
