import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as ReactSelect from 'react-select';
import { reduxForm } from 'redux-form';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { DispatchCallback } from '../../requests/types';

import { CLASS_STATUS_CHOICES } from '../../constants/classes';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { CLASS_STATUSES, IClassRecord } from '../../store/data-types/classes';
import { IProfile, USER_STATUSES } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { IClassTaskRecord } from '../../store/data-types/tasks';
import { getCurrentCentreId, getCurrentCentreName } from '../../utils';
import CollectionInputFilter from '../tables/collection-input-filter';
import CollectionSelectFilter from '../tables/collection-select-filter';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';

const { actions: { getAllCollection, getCollection } } = collectionsModule;

interface IExternalProps {
  qualificationFilterId?: string;
}
interface IPropsWithoutActions extends IExternalProps {
  tasksCollection: ICollection<IClassTaskRecord>;
  loading: boolean;
  profile: IProfile;
  qualifications: List<IQualificationRecord>;
  classes: List<IClassRecord>;
}
interface IProps extends IPropsWithoutActions {
  getAllCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): void;
  getCollection(opts: ICollectionOptions, centre?: string): void;
}

const CLASS_TASK_LIST = 'CLASS_TASK_LIST';
const PAGE_SIZE = 10;

export class TasksLandingIQA extends React.PureComponent<IProps, void> {
  public componentDidMount() {
    this.props.getAllCollection('courses', {}, CLASS_TASK_LIST);
    this.props.getAllCollection('classes', {}, CLASS_TASK_LIST);
  }

  public render() {
    const currentCentreName = getCurrentCentreName(this.props.profile);
    const filters =
      this.props.tasksCollection.filters ||
      Map({ task_class__status: CLASS_STATUSES.ACTIVE });
    const getCollectionBound = (options: ICollectionOptions) =>
      this.props.getCollection(
        {
          ...options,
          filters,
        },
        getCurrentCentreId(this.props.profile)
      );
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            {currentCentreName} - all <Term>Tasks</Term>
          </h2>
        </ContentBoxHeader>
        <Section>{this.filters()}</Section>
        <Section>
          <CollectionTable
            headers={this.getHeaders()}
            collection={this.props.tasksCollection}
            loading={this.props.loading}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Section>
      </ContentBox>
    );
  }

  private filters() {
    const { tasksCollection, loading, profile } = this.props;
    const getCollectionBound = (options: ICollectionOptions) =>
      this.props.getCollection(options, getCurrentCentreId(this.props.profile));

    return (
      <Row>
        <Column sm={4}>
          <CollectionSelectFilter
            disabled={loading}
            label="Qualification"
            name="qualification"
            filterKey="qualification"
            placeholder="Please choose&hellip;"
            options={this.props.qualifications.map(({ id, name }) => ({
              key: id,
              label: name,
            }))}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Column>
        <Column sm={4}>
          <CollectionSelectFilter
            disabled={loading}
            label={terminologyFromProfile(profile, 'Class')}
            name="task_class"
            placeholder="Please choose&hellip;"
            filterKey="task_class"
            options={this.props.classes.map(({ id, name }) => ({
              key: id,
              label: name,
            }))}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Column>
        <Column sm={4}>
          <CollectionSelectFilter
            label={
              <span>
                <Term>Class</Term> Status
              </span>
            }
            name="class_status"
            filterKey="task_class__status"
            options={CLASS_STATUS_CHOICES}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
            excludeNull
          />
        </Column>
      </Row>
    );
  }

  private getHeaders(): Array<IColumnData<IClassTaskRecord>> {
    const { profile } = this.props;
    return [
      {
        content: data => (
          <Link to={`/tasks/${data.id}/`}>{data.component.title}</Link>
        ),
        headerLabel: terminologyFromProfile(profile, 'Task'),
        key: 'component__title',
        sortable: false,
      },
      {
        content: data => data.task_class.name,
        headerLabel: terminologyFromProfile(profile, 'Class'),
        key: 'task_class__name',
        sortable: false,
      },
      {
        content: data => data.qualification.name,
        headerLabel: 'Qualification',
        key: 'qualification__name',
        sortable: false,
      },
      {
        content: data => (
          <SpacedGroup>
            <Link to={`/tasks/${data.id}/`}>Review</Link>
          </SpacedGroup>
        ),
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  }
}

function mapStateToProps(
  { collectionsOld, responses, profile }: IStore,
  existing: IExternalProps
): IPropsWithoutActions {
  const tasksCollection = getCollectionByName(
    collectionsOld.get('tasks/class'),
    CLASS_TASK_LIST
  );

  return {
    ...existing,
    loading: anyPending(responses, [
      [GET_COLLECTION, 'tasks/class'],
      [GET_COLLECTION, 'courses'],
      [GET_COLLECTION, 'classes'],
    ]),
    profile,
    qualifications: getCollectionItems(
      collectionsOld.get('courses'),
      CLASS_TASK_LIST
    ),
    classes: getCollectionItems(collectionsOld.classes, CLASS_TASK_LIST),
    tasksCollection,
  };
}

const Formified = reduxForm({ form: 'tasks-landing-iqa' })(TasksLandingIQA);

function mapDispatchToProps(dispatch: DispatchCallback, props: IExternalProps) {
  return {
    getAllCollection: (
      type: keyof ICollectionsState,
      opts?: ICollectionOptions,
      tag?: string
    ) => dispatch(getAllCollection(type, opts, tag)),
    getCollection: (options: ICollectionOptions, centre?: string) => {
      const copiedOptions = _.extend({}, options);
      copiedOptions.filters = (copiedOptions.filters || Map<string, string>()
      ).set('centre', centre);
      const qualification = props.qualificationFilterId;
      copiedOptions.filters =
        qualification && !copiedOptions.filters.has('qualification')
          ? copiedOptions.filters.set('qualification', qualification)
          : copiedOptions.filters;
      copiedOptions.filters = copiedOptions.filters.set(
        'user_tasks__user__status',
        [USER_STATUSES.ACTIVE, USER_STATUSES.DISABLED]
      );
      dispatch(getCollection('tasks/class', copiedOptions, CLASS_TASK_LIST));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Formified);
