import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { CLASS_STATUSES } from '../../store/data-types/classes';
import { IProfile } from '../../store/data-types/profile';
import { IClassTaskRecord } from '../../store/data-types/tasks';
import { getCurrentCentreName } from '../../utils';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';

const { actions: { getCollection } } = collectionsModule;

const IQA_DASHBOARD = 'IQA_DASHBOARD';

interface IProps {
  classTaskCollection: ICollection<IClassTaskRecord>;
  loading: boolean;
  profile: IProfile;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
}

export class ClassTaskTableIQA extends React.PureComponent<IProps, void> {
  public render() {
    const { classTaskCollection, profile, loading } = this.props;

    const currentCentreName = getCurrentCentreName(profile);

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            <Term>Classes</Term> needing review for {currentCentreName} centre
          </h2>
        </ContentBoxHeader>
        <CollectionTable
          headers={this.getHeaders()}
          collection={classTaskCollection}
          loading={loading}
          pageSize={10}
          getCollection={(options: ICollectionOptions) => {
            options.filters = (options.filters || Map<string, string>())
              .set('user_tasks__status', 'MARKED')
              .set('task_class__status', CLASS_STATUSES.ACTIVE);
            this.props.getCollection('tasks/class', options, IQA_DASHBOARD);
          }}
        />
      </ContentBox>
    );
  }

  private getHeaders(): Array<IColumnData<IClassTaskRecord>> {
    const { profile } = this.props;
    return [
      {
        content: data => (
          <Link to={`/tasks/${data.id}/`}>{data.component.title}</Link>
        ),
        headerLabel: 'Component Name',
        key: 'name',
        sortable: true,
      },
      {
        content: data => data.qualification.name,
        headerLabel: 'Qualification',
        key: 'qualification',
        sortable: true,
      },
      {
        content: data => data.task_class.name,
        headerLabel: `${terminologyFromProfile(profile, 'Class')} name`,
        key: 'task_class',
        sortable: true,
      },
      {
        content: data => <Link to={`/tasks/${data.id}/`}>Review</Link>,
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  }
}

function mapStateToProps({ collectionsOld, profile, responses }: IStore) {
  return {
    loading: isPending(responses, GET_COLLECTION, IQA_DASHBOARD),
    profile,
    classTaskCollection: getCollectionByName(
      collectionsOld.get('tasks/class'),
      IQA_DASHBOARD
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(ClassTaskTableIQA);
