import * as React from 'react';

import { List, Set } from 'immutable';
import { Field, WrappedFieldProps } from 'redux-form';
import { IStore } from '../../../store';
import { removeDuplicateAndUndefinedValues } from '../../../utils';
import { IInputValue } from './tag-files-form';

interface IFormValue {
  user_tasks?: ReadonlyArray<string | undefined>;
  learning_outcome_blocks?: ReadonlyArray<string | undefined>;
}

export interface ITagFilesTaskInput extends WrappedFieldProps<IStore> {
  fileId: string;
  taskId: string;
  blockIds: ReadonlyArray<string>;
}

export default class TagFilesTaskInput extends React.PureComponent<
  ITagFilesTaskInput,
  void
> {
  public constructor(props: ITagFilesTaskInput) {
    super(props);
    this.transformUserTaskValue = this.transformUserTaskValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const { fileId, input: { value } } = this.props;
    const { blockIds, taskId } = this.props;
    const {
      user_tasks = [],
      learning_outcome_blocks = [],
    } = value as IFormValue;

    const selectedBlockIdsWithinThisTask = blockIds.filter(
      blockId => learning_outcome_blocks.indexOf(blockId) >= 0
    );

    return (
      <span>
        <input
          className="vertical-align-middle"
          disabled={selectedBlockIdsWithinThisTask.length > 0}
          type="checkbox"
          checked={user_tasks.indexOf(taskId) >= 0}
          onChange={this.onChange}
        />
        {blockIds.length ? (
          <span className="info vertical-align-middle margin-left-small">
            {selectedBlockIdsWithinThisTask.length}/{blockIds.length}
          </span>
        ) : null}
      </span>
    );
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.input.onChange(this.transformUserTaskValue(event));
  }

  private transformUserTaskValue(event: React.ChangeEvent<HTMLInputElement>) {
    const { taskId, input } = this.props;
    const value: IInputValue = input.value;
    const { user_tasks = [], learning_outcome_blocks = [], id } = value;

    if (event.target.checked) {
      return {
        user_tasks: removeDuplicateAndUndefinedValues(
          user_tasks.concat(taskId)
        ),
        learning_outcome_blocks,
        id,
      };
    }

    return {
      user_tasks: user_tasks.filter(task => task !== taskId),
      learning_outcome_blocks,
      id,
    };
  }
}
