import {
  CollectionOptions,
  getCollectionResultsByName,
} from '@dabapps/redux-api-collections/dist/collections';
import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  ADD_TO_COLLECTION,
  GET_COLLECTION,
} from '../../../collections/actions';
import { ICollectionOptions } from '../../../collections/types';

import { closeModal } from '../../../actions/modals';
import { collections } from '../../../collections';
import { IStore } from '../../../store';
import { IProfile, ISimpleUser } from '../../../store/data-types/profile';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import ManageCentreEQAsForm, { IFormData } from './manage-centre-eqas-form';

const { actions: { getAllCollection, addItem } } = collections;

export const EQAS_ROUTE = 'users/external-quality-assurers';
export const ASSIGNED_EQAS_ROUTE = 'users/assigned-external-quality-assurers';
export const ALL_EQAS = 'ALL_EQAS';
export const getAssignedEQAsTag = (centreId: string, examSpecId: string) =>
  `ASSIGNED_EQAS-${centreId}-${examSpecId}`;

interface IUpdateEQAsData {
  centre: string;
  exam_spec: string;
  external_quality_assurers: string[];
}

interface IExternalProps {
  centreId: string;
  centreName: string;
  examSpecId: string;
  examSpecName: string;
  loadExamSpecs(options?: CollectionOptions): void;
}

interface IStateProps {
  loading: boolean;
  profile: IProfile;
  allEQAs: ReadonlyArray<ISimpleUser>;
  assignedEQAs: ReadonlyArray<ISimpleUser>;
}

interface IDispatchProps {
  closeModal: typeof closeModal;
  getAllCollection: typeof getAllCollection;
  addItem(route: string, data: IUpdateEQAsData): AxiosPromise;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class ManageCentreEQAsModal extends React.PureComponent<IProps, void> {
  public componentDidMount() {
    const { centreId, examSpecId, allEQAs } = this.props;
    if (!allEQAs.length) {
      this.props.getAllCollection(EQAS_ROUTE, {}, ALL_EQAS);
    }
    this.props.getAllCollection(
      ASSIGNED_EQAS_ROUTE,
      {
        filters: {
          centre_id: this.props.centreId,
          examspec_id: this.props.examSpecId,
        },
      },
      getAssignedEQAsTag(centreId, examSpecId)
    );
  }

  public render() {
    const {
      loading,
      assignedEQAs,
      allEQAs,
      profile,
      closeModal,
      examSpecName,
      centreName,
    } = this.props;
    return (
      <Modal onClickOutside={() => closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => closeModal()} />
          <p>
            Manage External Quality Assurers for {examSpecName} at {centreName}
          </p>
        </ModalHeader>
        {loading ? (
          <Loading />
        ) : (
          <ManageCentreEQAsForm
            eqas={List<ISimpleUser>(allEQAs)}
            profile={profile}
            initialValues={
              assignedEQAs
                ? {
                    external_quality_assurers: Set(
                      assignedEQAs.map(each => each.id)
                    ),
                  }
                : {}
            }
            onSubmit={this.updateEQAs}
          />
        )}
        <ModalFooter>
          <p>
            <SpacedGroup>
              <Button disabled={loading} onClick={closeModal}>
                Cancel
              </Button>
            </SpacedGroup>
          </p>
        </ModalFooter>
      </Modal>
    );
  }

  private updateEQAs = (data: IFormData) => {
    this.props
      .addItem(EQAS_ROUTE, {
        centre: this.props.centreId,
        exam_spec: this.props.examSpecId,
        external_quality_assurers: data.external_quality_assurers.toArray(),
      })
      .then(() => {
        this.props.loadExamSpecs({});
        this.props.closeModal();
      });
  };
}

function mapStateToProps(
  { responses, profile, collections }: IStore,
  props: IExternalProps
) {
  return {
    ...props,
    profile,
    loading: isPending(responses, GET_COLLECTION, EQAS_ROUTE),
    allEQAs: getCollectionResultsByName(collections, EQAS_ROUTE, ALL_EQAS),
    assignedEQAs: getCollectionResultsByName(
      collections,
      ASSIGNED_EQAS_ROUTE,
      getAssignedEQAsTag(props.centreId, props.examSpecId)
    ),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  getAllCollection,
  addItem,
})(ManageCentreEQAsModal);
