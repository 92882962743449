import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

const { IconBars } = FontAwesome;

interface IProps {
  onClick(): void;
}

const MenuButton: React.SFC<
  IProps & React.HTMLProps<HTMLDivElement>
> = props => {
  const { className, children, onClick, ...remainingProps } = props;

  return (
    <div className="menu-button-container">
      <div {...remainingProps} className={classNames('menu-button', className)}>
        <IconBars className="menu-icon" onClick={onClick} />
        {children}
      </div>
      <span className="menu-text">Menu</span>
    </div>
  );
};

export default MenuButton;
