import {
  apiRequest,
  AsyncActionSet,
  dispatchGenericRequest,
  makeAsyncActionSet,
  setRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { AxiosPromise, AxiosResponse } from 'axios';
import { List } from 'immutable';
import { Dispatch } from 'redux';
import * as _ from 'underscore';
import {
  GetStateCallback,
  IActionNoPayload,
  IActionWithPayload,
} from '../requests/types';
import { getAWSError } from '../requests/utils';
import { IStore } from '../store';
import { IAction } from '../types';
import { checkForLogout } from '../utils';
import { deleteAsset } from './quotas';

export function emptyAction(): IActionNoPayload {
  return {
    type: '',
  };
}

export const SET_UPLOAD_FILES = 'SET_UPLOAD_FILES';

export function setUploadFiles(files: List<File>): IAction<any, void> {
  return {
    payload: files,
    type: SET_UPLOAD_FILES,
  };
}

export const CLEAR_UPLOAD_FILES = 'CLEAR_UPLOAD_FILES';
export function clearUploadFiles(): IActionNoPayload {
  return {
    type: CLEAR_UPLOAD_FILES,
  };
}

export const CLEAR_UPLOAD_FILE = 'CLEAR_UPLOAD_FILE';
export function clearUploadFile(
  fileName: string
): IActionWithPayload<string, void> {
  return {
    payload: fileName,
    type: CLEAR_UPLOAD_FILE,
  };
}

export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export function setUploadProgress(
  event: ProgressEvent,
  fileNumber: number,
  totalFiles: number
) {
  const progress = Math.round(event.loaded * 100 / event.total);
  return {
    payload: {
      fileNumber,
      progress,
      totalFiles,
    },
    type: SET_UPLOAD_PROGRESS,
  };
}
function dispatchUpload(
  actionSet: AsyncActionSet,
  url: string,
  file: File,
  ownerUserId: string,
  fileNumber: number,
  totalFiles: number,
  tag?: string
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    dispatch({ type: actionSet.REQUEST });
    dispatch(setRequestState(actionSet, 'REQUEST', tag));
    const errorHandler = (error: any) => {
      checkForLogout(error);
      const errorData =
        error &&
        (getAWSError(error) || (error.response && error.response.data));
      dispatch({ type: actionSet.FAILURE, payload: errorData });
      dispatch(setRequestState(actionSet, 'FAILURE', tag));
      return Promise.reject(error);
    };

    return apiRequest(url, 'POST', { filename: file.name, owner: ownerUserId })
      .then(createAssetResponse => {
        const formData = new FormData();
        // pass through the form values the server hands to us (to make S3 happy)
        _.forEach(
          createAssetResponse.data.upload_form.fields,
          (value: string, key: string) => {
            formData.append(key, value);
          }
        );
        formData.append('Content-Type', file.type);
        formData.append('file', file);
        return apiRequest(
          createAssetResponse.data.upload_form.url,
          'POST',
          formData,
          {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          (event: ProgressEvent) =>
            dispatch(setUploadProgress(event, fileNumber, totalFiles))
        )
          .then(() => {
            return apiRequest(createAssetResponse.data.complete_url, 'POST');
          })
          .then(response => {
            dispatch({ type: actionSet.SUCCESS, payload: response.data });
            dispatch(setRequestState(actionSet, 'SUCCESS', tag));
            if (fileNumber === totalFiles) {
              dispatch(clearUploadFiles());
            }
            return createAssetResponse;
          })
          .catch(errorHandler);
      })
      .catch(errorHandler);
  };
}

export function dispatchImageUpload(
  actionSet: AsyncActionSet,
  url: string,
  key: string,
  file: File,
  tag?: string
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    const form = new FormData();
    form.append(key, file);
    dispatch({ type: actionSet.REQUEST });
    dispatch(setRequestState(actionSet, 'REQUEST', tag));

    return apiRequest(
      url,
      'PUT',
      form,
      { 'Content-Type': 'multipart/form-data' },
      (event: ProgressEvent) => dispatch(setUploadProgress(event, 1, 1))
    )
      .then(response => {
        dispatch({ type: actionSet.SUCCESS, payload: response.data });
        dispatch(setRequestState(actionSet, 'SUCCESS', tag));
        dispatch(clearUploadFiles());
        return response;
      })
      .catch(error => {
        checkForLogout(error);
        dispatch({
          type: actionSet.FAILURE,
          payload: error && error.response && error.response.data,
        });
        dispatch(setRequestState(actionSet, 'FAILURE', tag));
        return Promise.reject(error);
      });
  };
}

export const USER_EXAMPLE = makeAsyncActionSet('USER_EXAMPLE');
export function userExample(tag?: string) {
  return dispatchGenericRequest(
    USER_EXAMPLE,
    '/api/users/example/',
    'GET',
    undefined,
    tag
  );
}

export const UPLOAD_ASSET = makeAsyncActionSet('UPLOAD_ASSET');
// NOTE: this should only be called by the file list chaining uploadAssets
function uploadAsset(
  file: File,
  ownerUserId: string,
  fileNumber: number,
  totalFiles: number
) {
  return dispatchUpload(
    UPLOAD_ASSET,
    '/api/assets/',
    file,
    ownerUserId,
    fileNumber,
    totalFiles,
    undefined
  );
}

export interface IGetAssetsOptions {
  owner__id?: string;
  created_by__id?: string;
  journal_entry_id?: string;
  page?: number;
}

export const GET_ASSETS = makeAsyncActionSet('GET_ASSETS');
export function getAssets(
  options: IGetAssetsOptions,
  tag?: string,
  shouldAppend: boolean = false
) {
  const queryParams = _.pairs(options)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

  const baseUrl = '/api/assets/';
  const url = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;

  return dispatchGenericRequest(GET_ASSETS, url, 'GET', undefined, tag, {
    shouldAppend,
  });
}

export function uploadAssets(
  files: List<File>,
  ownerUserId: string,
  fileNumber: number,
  totalFiles: number,
  assetIds: List<string> = List()
) {
  return (
    dispatch: Dispatch<IStore>,
    getState: GetStateCallback
  ): Promise<List<string>> => {
    const file = files.first();
    if (!file) {
      return Promise.resolve(assetIds);
    }
    return uploadAsset(file, ownerUserId, fileNumber, totalFiles)(
      dispatch,
      getState
    )
      .then(response =>
        uploadAssets(
          files.rest(),
          ownerUserId,
          fileNumber + 1,
          totalFiles,
          assetIds.push(response.data.id)
        )(dispatch, getState)
      )
      .catch(error => {
        // Delete any uploaded assets in the chain as the if one part of the chain fails they all fail
        assetIds.forEach(each => dispatch(deleteAsset(each)));
        // re raise the original error to stop chain
        return Promise.reject(error);
      });
  };
}

export function uploadAssetsAndReloadAssets(
  files: List<File>,
  ownerUserId: string,
  fileNumber: number = 1,
  totalFiles: number = files.count()
) {
  return (
    dispatch: Dispatch<IStore>,
    getState: GetStateCallback
  ): Promise<AxiosResponse> => {
    return uploadAssets(files, ownerUserId, fileNumber, totalFiles)(
      dispatch,
      getState
    )
      .catch(error => null)
      .then(() => getAssets({ owner__id: ownerUserId })(dispatch, getState));
  };
}

export const SET_UI_STATE = 'SET_UI_STATE';
export function setUIState(key: string, value: string | boolean) {
  return {
    payload: {
      key,
      value,
    },
    type: SET_UI_STATE,
  };
}

export const CLEAR_UI_STATE = 'CLEAR_UI_STATE';
export function clearUIState(key: string) {
  return {
    payload: {
      key,
    },
    type: CLEAR_UI_STATE,
  };
}

export const DISPLAY_LOADING_SPINNER = 'DISPLAY_LOADING_SPINNER';
export function displayLoadingSpinner() {
  return {
    type: DISPLAY_LOADING_SPINNER,
  };
}

export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER';
export function hideLoadingSpinner() {
  return {
    type: HIDE_LOADING_SPINNER,
  };
}

export const OPEN_FLYOUT = 'OPEN_FLYOUT';
export function openFlyout() {
  return {
    type: OPEN_FLYOUT,
  };
}

export const CLOSE_FLYOUT = 'CLOSE_FLYOUT';
export function closeFlyout() {
  return {
    type: CLOSE_FLYOUT,
  };
}

export const SET_NEWS_FEED_COUNT = 'SET_NEWS_FEED_COUNT';
export function setNewsFeedCount(payload: number) {
  return {
    type: SET_NEWS_FEED_COUNT,
    payload,
  };
}
