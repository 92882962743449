import { SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { formatBytes } from '../../utils';
import { ProgressRing } from '../progress-ring';

interface IQuotaUsageProps {
  usage: number;
  quotaBytes: number;
  className?: string;
}

const ERROR = '#AF4444'; // taken from @error in variables.less
const OK = '#289664'; // taken from @tertiary in variable.less

export default class QuotaUsage extends React.PureComponent<
  IQuotaUsageProps,
  void
> {
  public render() {
    const { usage, quotaBytes, className } = this.props;
    return (
      <div className={className}>
        <SpacedGroup>
          <div className="display-inline-block">
            <p className="margin-vertical-small">Quota Usage</p>
            <p className="margin-vertical-small">
              {formatBytes(usage)} / {formatBytes(quotaBytes)}
            </p>
          </div>
          <ProgressRing
            className="small display-inline-block"
            progress={quotaBytes ? usage / quotaBytes : 1}
            displayPercentage={true}
            color={usage >= quotaBytes ? ERROR : OK}
          />
        </SpacedGroup>
      </div>
    );
  }
}
