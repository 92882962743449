import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as ReactSelect from 'react-select';
import { reduxForm } from 'redux-form';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { DispatchCallback } from '../../requests/types';

import { CLASS_STATUS_CHOICES } from '../../constants/classes';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { CLASS_STATUSES } from '../../store/data-types/classes';
import { IProfile, USER_STATUSES } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { IClassTaskRecord, IUnitDetail } from '../../store/data-types/tasks';
import { getCurrentCentreId, getCurrentCentreName } from '../../utils';
import CollectionInputFilter from '../tables/collection-input-filter';
import CollectionSelectFilter from '../tables/collection-select-filter';
import CollectionTable from '../tables/collection-table';
import { IColumnData } from '../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../terminology';

const {
  actions: { clearCollection, getAllCollection, getCollection },
} = collectionsModule;

interface IExternalProps {
  qualificationFilterId?: string;
}
interface IProps extends IExternalProps {
  tasksCollection: ICollection<IClassTaskRecord>;
  loading: boolean;
  profile: IProfile;
  qualifications: List<IQualificationRecord>;
  units: List<IUnitDetail>;
  getAllCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): void;
  getCollection(opts: ICollectionOptions, centre?: string): void;
  clearCollection(type: keyof ICollectionsState): void;
}

const CLASS_TASK_LIST = 'CLASS_TASK_LIST';
const PAGE_SIZE = 10;

export class TasksLandingTeacher extends React.PureComponent<IProps, void> {
  public componentDidMount() {
    this.props.getAllCollection('courses', {}, CLASS_TASK_LIST);
  }

  public render() {
    const currentCentreName = getCurrentCentreName(this.props.profile);
    const filters =
      this.props.tasksCollection.filters ||
      Map({ task_class__status: CLASS_STATUSES.ACTIVE });
    const getCollectionBound = (options: ICollectionOptions) =>
      this.props.getCollection(
        {
          ...options,
          filters,
        },
        getCurrentCentreId(this.props.profile)
      );
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            <Term>Tasks</Term> for {currentCentreName}
          </h2>
        </ContentBoxHeader>
        <Section>{this.filters()}</Section>
        <Section>
          <CollectionTable
            headers={this.getHeaders()}
            collection={this.props.tasksCollection}
            loading={this.props.loading}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Section>
      </ContentBox>
    );
  }

  private filters() {
    const { tasksCollection } = this.props;
    const getCollectionBound = (options: ICollectionOptions) =>
      this.props.getCollection(options, getCurrentCentreId(this.props.profile));

    return (
      <Row>
        <Column sm={3}>
          <CollectionInputFilter
            formName="TasksLandingTeacher"
            label="Search"
            filterKey="search"
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label="Qualification"
            name="qualification"
            filterKey="qualification"
            options={this.props.qualifications.map(({ id, name }) => ({
              key: id,
              label: name,
            }))}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={options => {
              const filters = (options.filters || Map<string, string>()).remove(
                'component__unit'
              );
              const newOptions = {
                ...options,
                filters,
              };
              return getCollectionBound(newOptions);
            }}
            onChange={this.getUnits.bind(this)}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label="Unit"
            name="unit"
            filterKey="component__unit"
            options={this.props.units.map(({ id, title }) => ({
              key: id,
              label: title,
            }))}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
            disabled={this.props.units.count() === 0}
          />
        </Column>
        <Column sm={3}>
          <CollectionSelectFilter
            label={
              <span>
                <Term>Class</Term> Status
              </span>
            }
            name="class_status"
            filterKey="task_class__status"
            options={CLASS_STATUS_CHOICES}
            collection={tasksCollection}
            pageSize={PAGE_SIZE}
            getCollection={getCollectionBound}
            excludeNull
          />
        </Column>
      </Row>
    );
  }

  private getUnits(qualification: string) {
    if (qualification) {
      this.props.getAllCollection(
        'courses/units',
        {
          filters: Map({
            unit_options__option__qualification: qualification,
          }),
        },
        CLASS_TASK_LIST
      );
    } else {
      this.props.clearCollection('courses/units');
    }
  }

  private getHeaders(): Array<IColumnData<IClassTaskRecord>> {
    const { profile } = this.props;
    return [
      {
        content: data => (
          <Link to={`/tasks/${data.id}/`}>{data.component.title}</Link>
        ),
        headerLabel: 'Name',
        key: 'component__title',
        sortable: true,
      },
      {
        content: data => data.component.unit.title,
        headerLabel: 'Unit',
        key: 'component__unit__title',
        sortable: true,
      },
      {
        content: data => data.task_class.name,
        headerLabel: terminologyFromProfile(profile, 'Class'),
        key: 'task_class__name',
        sortable: true,
      },
      {
        content: data => (
          <SpacedGroup>
            <Link to={`/tasks/${data.id}/`}>View</Link>
          </SpacedGroup>
        ),
        headerLabel: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
  }
}

function mapStateToProps(
  { collectionsOld, responses, profile }: IStore,
  existing: IExternalProps
) {
  const tasksCollection = getCollectionByName(
    collectionsOld.get('tasks/class'),
    CLASS_TASK_LIST
  );
  const qualificationCollection = getCollectionByName(
    collectionsOld.get('courses'),
    CLASS_TASK_LIST
  );
  const unitCollection = getCollectionByName(
    collectionsOld.get('courses/units'),
    CLASS_TASK_LIST
  );

  return {
    ...existing,
    loading: anyPending(responses, [
      [GET_COLLECTION, 'tasks/class'],
      [GET_COLLECTION, 'courses'],
      [GET_COLLECTION, 'courses/units'],
    ]),
    profile,
    qualifications: qualificationCollection.results,
    units: unitCollection.results,
    tasksCollection,
  };
}

const Formified = reduxForm({ form: 'tasks-landing-student' })(
  TasksLandingTeacher
);

function mapDispatchToProps(dispatch: DispatchCallback, props: IExternalProps) {
  return {
    clearCollection: (type: keyof ICollectionsState) =>
      dispatch(clearCollection(type, CLASS_TASK_LIST)),
    getAllCollection: (
      type: keyof ICollectionsState,
      opts?: ICollectionOptions,
      tag?: string
    ) => dispatch(getAllCollection(type, opts, tag)),
    getCollection: (options: ICollectionOptions, centre?: string) => {
      const copiedOptions = _.extend({}, options);
      copiedOptions.filters = (copiedOptions.filters || Map<string, string>()
      ).set('centre', centre);
      const qualification = props.qualificationFilterId;
      copiedOptions.filters =
        qualification && !copiedOptions.filters.has('qualification')
          ? copiedOptions.filters.set('qualification', qualification)
          : copiedOptions.filters;
      copiedOptions.filters = copiedOptions.filters.set(
        'user_tasks__user__status',
        [USER_STATUSES.ACTIVE, USER_STATUSES.DISABLED]
      );
      dispatch(getCollection('tasks/class', copiedOptions, CLASS_TASK_LIST));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Formified);
