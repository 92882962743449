import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import * as React from 'react';
import Popover from './popover';

// tslint:disable-next-line:no-unused-variable
interface IExternalProps {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  hover?: boolean;
  className?: string | null | false;
  maxWidth?: number;
  width?: number;
  onOpen?: () => void;
  toggleComponent: React.ReactElement<any>;
}

// tslint:disable-next-line:no-unused-variable
interface ISetProps {
  display: boolean;
}

type Props = SetPropsInterface<ISetProps> & IExternalProps;

export class SimplePopover extends React.PureComponent<Props, void> {
  public constructor(props: Props) {
    super(props);

    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  public componentWillReceiveProps(nextProps: Props) {
    const { display, onOpen } = this.props;
    if (nextProps.display && display !== nextProps.display && onOpen) {
      onOpen();
    }
  }

  public render() {
    const {
      display,
      hover,
      children,
      toggleComponent,
      width,
      maxWidth = 250,
      setProps,
      dispatch,
      onOpen, // Removing from remaining props
      ...remainingProps,
    } = this.props;

    return (
      <Popover
        {...remainingProps}
        style={{ width, maxWidth }}
        display={display}
        onClickOutside={this.closePopover}
        toggleComponent={React.cloneElement(
          toggleComponent,
          hover
            ? { onMouseOver: this.openPopover, onMouseOut: this.closePopover }
            : { onClick: this.togglePopover }
        )}
      >
        {children}
      </Popover>
    );
  }

  private openPopover() {
    this.props.setProps({
      display: true,
    });
  }

  private closePopover() {
    this.props.setProps({
      display: false,
    });
  }

  private togglePopover() {
    this.props.setProps({
      display: !this.props.display,
    });
  }
}

const getInitialProps = () => ({ display: false });

export default withSetProps<ISetProps, IExternalProps>(getInitialProps)(
  SimplePopover
);
