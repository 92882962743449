import { Alert, Button, Column, FormGroup, Row } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { EditorState } from 'draft-js';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  DispatchCallback,
  GetStateCallback,
  TActionAny,
  Thunk,
} from '../../requests/types';
const { IconFileTextO } = FontAwesome;
import {
  DataShape,
  Field,
  FormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';

import { IGetAssetsOptions } from '../../actions';
import { setJournalAssets } from '../../actions/journals';
import { openModal } from '../../actions/modals';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IAssetRecord } from '../../store/data-types';
import {
  IJournalEntry,
  IJournalTaskData,
} from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';
import JournalEntryEditor from './journal-entry-editor';
import JournalFilesSidebar from './journal-files-sidebar';
import SelectJournalFilesModal from './select-journal-files-modal';
import JournalTasksSidebar from './tasks-sidebar/journal-tasks-sidebar';

const { actions: { loadItem, patchItem } } = itemsModule;

export const JOURNAL_ENTRY_FORM = 'JournalEntryForm';

interface ISelectedFilesProps {
  assets: List<IAssetRecord>;
}

interface IJournalEntryViewExternalProps extends React.HTMLProps<JSX.Element> {
  assets: List<IAssetRecord>;
  journalEntryCreated: boolean;
  journalEntry: IJournalEntry;
  loading?: boolean;
  isViewingUnderStudentContext?: boolean;
  profile: IProfile;
  isOwner: boolean;
  readOnly: boolean;
  getAssets(): void;
}

interface IJournalEntryViewProps extends IJournalEntryViewExternalProps {
  openModal(modal: React.ReactNode): void;
  loadItem(type: keyof IItemsState, id: string): void;
  patchItem(type: keyof IItemsState, id: string, data: any): AxiosPromise;
}

export class JournalEntryView extends React.PureComponent<
  IJournalEntryViewProps,
  void
> {
  public render() {
    const {
      assets,
      getAssets,
      journalEntry,
      journalEntryCreated,
      profile,
      readOnly,
      isOwner,
      isViewingUnderStudentContext,
    } = this.props;
    return (
      <Row>
        {journalEntryCreated && (
          <Column xs={12}>
            <Alert className="success">
              <p>Journal entry created!</p>
            </Alert>
          </Column>
        )}
        <Column sm={8}>
          <div dangerouslySetInnerHTML={{ __html: journalEntry.content }} />
        </Column>
        <Column sm={4}>
          <div>
            <JournalFilesSidebar
              journalEntry={journalEntry}
              assets={assets}
              profile={profile}
              openModal={this.props.openModal}
              getAssets={getAssets}
              modeOptions={
                readOnly
                  ? {
                      mode: 'READONLY',
                      selectedAssets: journalEntry.assets,
                    }
                  : {
                      mode: 'EDITING',
                      onChange: (newAssets: List<string>) =>
                        this.props.patchItem('journals', journalEntry.id, {
                          assets: newAssets,
                        }),
                      selectedAssets: journalEntry.assets,
                    }
              }
            />
            <JournalTasksSidebar
              openModal={this.props.openModal}
              entryClass={journalEntry.entry_class}
              journalEntry={journalEntry}
              profile={profile}
              showTaskLink
              isViewingUnderStudentContext={isViewingUnderStudentContext}
              modeOptions={
                readOnly
                  ? {
                      mode: 'READONLY',
                    }
                  : {
                      mode: 'EDITING',
                      onChange: (values: IJournalTaskData) =>
                        this.props.patchItem('journals', journalEntry.id, {
                          user_task: values.selectedTask,
                          off_job_training_hours: values.offJobTrainingHours,
                        }),
                    }
              }
            />
          </div>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (
  store: IStore,
  props: IJournalEntryViewExternalProps
) => props;
export default connect(mapStateToProps, { openModal, loadItem, patchItem })(
  JournalEntryView
);
