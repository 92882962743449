import { TGrade } from '../store/data-types/tasks';

type TGradeLookupTable = { [Grade in TGrade]: string };

export const GRADES: TGradeLookupTable = {
  FAIL: 'Fail',
  PASS: 'Pass',
  MERIT: 'Merit',
  DISTINCTION: 'Distinction',
};

export function lookupGrade(grade: keyof typeof GRADES) {
  return GRADES[grade];
}
