import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { CAN_MANAGE_EQAS } from '../../../permissions';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store/';
import { ICentre } from '../../../store/data-types/centres';
import IfHasRoles from '../../permissions/if-has-roles';
import CentreSearch, { ISearchForm } from './centre-search';
import CentresTable from './centres-table';

const { actions: { getCollection } } = collectionsModule;

export const MANAGE_EQAS_CENTRES = 'MANAGE_EQAS_CENTRES';

interface IProps {
  loading: boolean;
  centresCollection: ICollection<ICentre>;
  getCollection: typeof getCollection;
}

export class ManageEQAsPage extends React.Component<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.search = this.search.bind(this);
    this.loadCentres = this.loadCentres.bind(this);
  }

  public render() {
    const { loading, centresCollection } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Manage External Quality Assurers</h2>
        </ContentBoxHeader>
        <IfHasRoles roles={CAN_MANAGE_EQAS}>
          <Section className="padding-vertical-none">
            <Row>
              <Column sm={3}>
                <CentreSearch
                  loading={loading}
                  onSubmit={this.search}
                  initialValues={{
                    search:
                      centresCollection.filters &&
                      centresCollection.filters.get('search'),
                  }}
                />
              </Column>
            </Row>
          </Section>
          <CentresTable loadCentres={this.loadCentres} loading={loading} />
        </IfHasRoles>
      </ContentBox>
    );
  }

  private search({ search }: ISearchForm) {
    this.loadCentres({ search });
  }

  private loadCentres(options?: ICollectionOptions) {
    const { centresCollection } = this.props;
    const centresOptions = options || centresCollection;
    this.props.getCollection(
      'centres',
      { ...centresOptions },
      MANAGE_EQAS_CENTRES
    );
  }
}

function mapStateToProps({ collectionsOld, responses }: IStore) {
  return {
    loading: isPending(responses, GET_COLLECTION, 'centres'),
    centresCollection: getCollectionByName(
      collectionsOld.centres,
      MANAGE_EQAS_CENTRES
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(ManageEQAsPage);
