import * as moment from 'moment';
import { TStatus } from '../store/data-types/common';
import { TRole } from '../store/data-types/profile';
import {
  ICourseworkProgressOverviewUserTask,
  INestedUserTask,
  IUserGradeListTaskRecord,
  IUserTaskRecord,
} from '../store/data-types/tasks';
import { constantToKebabCase, formatTaskStatusConstant } from '../utils';

interface IStatusPair {
  status: string;
  className: string;
}
type TStatusCallback = (
  status: TStatus,
  currentRole: TRole | undefined,
  userTask?:
    | IUserTaskRecord
    | INestedUserTask
    | ICourseworkProgressOverviewUserTask
    | IUserGradeListTaskRecord
) => IStatusPair;
type TStatusLookupTable = { [Status in TStatus]: TStatusCallback };

function statusToPair(status: string): IStatusPair {
  // This has softer type requirements than the rest,
  // as it is used for normalizing more than just fixed statuses
  return {
    status: formatTaskStatusConstant(status),
    className: constantToKebabCase(status),
  };
}

function awaitingQACallback(status: TStatus) {
  return {
    status: formatTaskStatusConstant(status),
    className: 'awaiting-qa',
  };
}
function passedQACallback(status: TStatus) {
  return {
    status: formatTaskStatusConstant(status),
    className: 'passed-qa',
  };
}

const defaultStatusLookupTable: TStatusLookupTable = {
  IN_PROGRESS: (status, currentRole, userTask) => {
    const endDate = userTask && userTask.end_date; // NOTE: this is always the class task end date at the moment
    if (endDate && moment.utc().isAfter(endDate)) {
      return statusToPair('overdue');
    }
    return statusToPair('open');
  },
  AWAITING_MARKING: statusToPair.bind(null, 'handed in', undefined, undefined),
  MARKED: awaitingQACallback,
  PASSED_IQA: awaitingQACallback,
  PASSED_EQA: passedQACallback,
  PENDING_SUBMISSION: passedQACallback,
  SUBMITTED: passedQACallback,
};

const awaitingQAStudentCallback = statusToPair.bind(
  null,
  'awaiting qa',
  undefined,
  undefined
);
const passedQAStudentCallback = statusToPair.bind(
  null,
  'passed qa',
  undefined,
  undefined
);

const studentTeacherStatusLookupTable = {
  ...defaultStatusLookupTable,
  MARKED: awaitingQAStudentCallback,
  PASSED_IQA: awaitingQAStudentCallback,
  PASSED_EQA: passedQAStudentCallback,
  PENDING_SUBMISSION: passedQAStudentCallback,
  SUBMITTED: passedQAStudentCallback,
};

const statusLookup: { [k in TRole]: TStatusLookupTable } = {
  TEACHER: studentTeacherStatusLookupTable,
  STUDENT: studentTeacherStatusLookupTable,
  CENTRE_ADMIN: defaultStatusLookupTable,
  EXTERNAL_QUALITY_ASSURER: defaultStatusLookupTable,
  INTERNAL_QUALITY_ASSURER: defaultStatusLookupTable,
  DA_SUPPORT: defaultStatusLookupTable,
  DA_ADMIN: defaultStatusLookupTable,
};

export function statusForUserRole(
  status: TStatus,
  currentRole: TRole | undefined,
  userTask?:
    | IUserTaskRecord
    | INestedUserTask
    | ICourseworkProgressOverviewUserTask
    | IUserGradeListTaskRecord
): IStatusPair {
  if (currentRole) {
    return statusLookup[currentRole][status](status, currentRole, userTask);
  }
  return defaultStatusLookupTable[status](status, currentRole, userTask);
}
