import * as React from 'react';

import { FormGroup, SpacedGroup } from '@dabapps/roe';
import { Map, Set } from 'immutable';
import { connect } from 'react-redux';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { IStore } from '../../../store/';
import { TStatus } from '../../../store/data-types/common';

export const FORM_NAME = 'STATUS_TRANSITION_COMMENT_FORM';
export const FIELD_NAME = 'STATUS_TRANSITION_COMMENT';

export function getStatusTransitionCommentValue(store: IStore): string | null {
  return formValueSelector(FORM_NAME)(store, FIELD_NAME) || null;
}

interface ITitleHint {
  title: string;
  hint: string;
}
const DEFAULT_TITLE = 'Add comment';
const DEFAULT_HINT = 'Optional';
const STATUS_TO_TITLE_AND_HINT: { [K in TStatus]: ITitleHint | null } = {
  IN_PROGRESS: null,
  AWAITING_MARKING: {
    title: DEFAULT_TITLE,
    hint:
      'Optional. If reopen, comment goes to the student. If request IQA review, comment goes to the IQA.',
  },
  MARKED: {
    title: DEFAULT_TITLE,
    hint:
      'Optional. If reject, comment goes to the teacher. If accepting, comment goes to the EQA.',
  },
  PASSED_IQA: {
    title: 'Add IQA comment',
    hint: DEFAULT_HINT,
  },
  PASSED_EQA: null,
  PENDING_SUBMISSION: null,
  SUBMITTED: null,
};

interface IExternalProps {
  status: TStatus;
}
interface IProps extends IExternalProps, FormProps<DataShape, void, void> {}
export class StatusTransitionCommentBox extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { status } = this.props;
    const display = STATUS_TO_TITLE_AND_HINT[status];
    return (
      <form>
        <SpacedGroup>
          <p>
            <strong>{display ? display.title : DEFAULT_TITLE}</strong>
          </p>
          <p className="help">{display ? display.hint : DEFAULT_HINT}</p>
        </SpacedGroup>
        <FormGroup block>
          <Field
            name={FIELD_NAME}
            type="text"
            placeholder="Your comment..."
            component="textarea"
          />
        </FormGroup>
      </form>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
})(StatusTransitionCommentBox);
