import {
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
  Well,
} from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as React from 'react';

import {
  MARKING_CODES,
  MarkingLevel,
  MarkingLevelPorMD,
  TMarkingCode,
} from '../../../store/data-types/courses';
import {
  IChecklistRecord,
  ILearningOutcomeRecord,
} from '../../../store/data-types/marksheets';
import { IProfile } from '../../../store/data-types/profile';
import ProgressBarGroup, {
  IMarkingGroupRecord,
  MarkingGroup,
} from '../../progress-bar-group';
import { terminologyFromProfile } from '../../terminology';

interface IMarkingGroups {
  student?: List<IMarkingGroupRecord>;
  teacher: List<IMarkingGroupRecord>;
}

interface IProps {
  checklist: IChecklistRecord;
  profile: IProfile;
}

export default class ProgressSection extends React.PureComponent<IProps, void> {
  public render() {
    const { checklist, profile } = this.props;
    const markingGroups = checklistToMarkingGroups(checklist);
    const teacherTerm = terminologyFromProfile(profile, 'Teacher');
    const studentTerm = terminologyFromProfile(profile, 'Student');

    return (
      <Section>
        <h3>{checklist.strand.title}</h3>
        {markingGroups.student && (
          <Section>
            <Row>
              <ProgressBarGroup
                title={`Progress (as tagged by ${studentTerm})`}
                groups={markingGroups.student}
              />
            </Row>
          </Section>
        )}

        <Section>
          <Row>
            <ProgressBarGroup
              title={`Progress (as marked by ${teacherTerm})`}
              groups={markingGroups.teacher}
            />
          </Row>
        </Section>
      </Section>
    );
  }
}

function incrementPMDMarkingGroup(
  group: IMarkingGroupRecord,
  isChecked: boolean
): IMarkingGroupRecord {
  return group
    .set('max', group.max + 1)
    .set('count', isChecked ? group.count + 1 : group.count);
}

function setPMDMarkingGroupProgress(
  group: IMarkingGroupRecord
): IMarkingGroupRecord {
  return group.set('progress', Math.min(group.count / group.max, 1));
}

function checklistToPMDMarkingGroups(
  selfMark: boolean,
  checklist: IChecklistRecord
): IMarkingGroups {
  const groups = checklist.learning_outcomes.reduce(
    (groupsMemo, learningOutcome) =>
      learningOutcome.learning_outcome_blocks.reduce(
        (groupsMemoFocused, block) => {
          switch (block.block.lower_mark) {
            case MarkingLevel.Pass:
              return {
                ...groupsMemoFocused,
                studentPass: incrementPMDMarkingGroup(
                  groupsMemoFocused.studentPass,
                  block.checked_by_student
                ),
                teacherPass: incrementPMDMarkingGroup(
                  groupsMemoFocused.teacherPass,
                  block.checked_by_teacher
                ),
              };
            case MarkingLevel.Merit:
              return {
                ...groupsMemoFocused,
                studentMerit: incrementPMDMarkingGroup(
                  groupsMemoFocused.studentMerit,
                  block.checked_by_student
                ),
                teacherMerit: incrementPMDMarkingGroup(
                  groupsMemoFocused.teacherMerit,
                  block.checked_by_teacher
                ),
              };
            case MarkingLevel.Distinction:
              return {
                ...groupsMemoFocused,
                studentDistinction: incrementPMDMarkingGroup(
                  groupsMemoFocused.studentDistinction,
                  block.checked_by_student
                ),
                teacherDistinction: incrementPMDMarkingGroup(
                  groupsMemoFocused.teacherDistinction,
                  block.checked_by_teacher
                ),
              };
            default:
              // tslint:disable-next-line:no-console
              console.warn(
                `Block has unknown PMD level of ${block.block.lower_mark}`
              );
              return groupsMemoFocused;
          }
        },
        groupsMemo
      ),
    {
      studentPass: MarkingGroup({
        title: 'Pass',
      }),
      studentMerit: MarkingGroup({
        title: 'Merit',
      }),
      studentDistinction: MarkingGroup({
        title: 'Distinction',
      }),
      teacherPass: MarkingGroup({
        title: 'Pass',
      }),
      teacherMerit: MarkingGroup({
        title: 'Merit',
      }),
      teacherDistinction: MarkingGroup({
        title: 'Distinction',
      }),
    }
  );

  return {
    student: selfMark
      ? List.of(
          groups.studentPass,
          groups.studentMerit,
          groups.studentDistinction
        ).map(setPMDMarkingGroupProgress)
      : undefined,
    teacher: List.of(
      groups.teacherPass,
      groups.teacherMerit,
      groups.teacherDistinction
    ).map(setPMDMarkingGroupProgress),
  };
}

function incrementRangeOrPassFailMarkingGroup(
  group: IMarkingGroupRecord,
  isChecked: boolean,
  maxProgressCountRequired: number
): IMarkingGroupRecord {
  const currentProgress = group.get('progress');
  return incrementPMDMarkingGroup(group, isChecked).set(
    'progress',
    isChecked
      ? Math.min(currentProgress + 1 / maxProgressCountRequired, 1)
      : currentProgress
  );
}

function combineRangeOrPassFailMarkingGroups(
  groupsMemo: IMarkingGroupRecord,
  group: IMarkingGroupRecord
): IMarkingGroupRecord {
  const { count, progress, max } = groupsMemo;
  return groupsMemo
    .set('count', count + group.count)
    .set('progress', progress + group.progress)
    .set('max', max + group.max);
}

function averageRangeOrPassFailMarkingGroupProgress(
  group: IMarkingGroupRecord,
  individualGroupCount: number
): IMarkingGroupRecord {
  return group.progress && individualGroupCount
    ? group.set('progress', group.progress / individualGroupCount)
    : group;
}

function checklistToRangeOrPassFailMarkingGroups(
  selfMark: boolean,
  checklist: IChecklistRecord
): IMarkingGroups {
  const individualGroupMarkingGroups = checklist.learning_outcomes.map(
    learningOutcome => {
      return learningOutcome.learning_outcome_blocks.reduce(
        (groupsMemoFocused, block) => {
          return {
            ...groupsMemoFocused,
            studentPass: incrementRangeOrPassFailMarkingGroup(
              groupsMemoFocused.studentPass,
              block.checked_by_student,
              learningOutcome.group.lower_mark ||
                learningOutcome.learning_outcome_blocks.count()
            ),
            teacherPass: incrementRangeOrPassFailMarkingGroup(
              groupsMemoFocused.teacherPass,
              block.checked_by_teacher,
              learningOutcome.group.lower_mark ||
                learningOutcome.learning_outcome_blocks.count()
            ),
          };
        },
        {
          studentPass: MarkingGroup({
            title: 'Pass',
          }),
          teacherPass: MarkingGroup({
            title: 'Pass',
          }),
        }
      );
    }
  );
  const combinedMarkingGroups = individualGroupMarkingGroups.reduce(
    (groupsMemo, markingGroup) => {
      return {
        ...groupsMemo,
        studentPass: combineRangeOrPassFailMarkingGroups(
          groupsMemo.studentPass,
          markingGroup.studentPass
        ),
        teacherPass: combineRangeOrPassFailMarkingGroups(
          groupsMemo.teacherPass,
          markingGroup.teacherPass
        ),
      };
    },
    {
      studentPass: MarkingGroup({
        title: 'Pass',
      }),
      teacherPass: MarkingGroup({
        title: 'Pass',
      }),
    }
  );

  return {
    student: selfMark
      ? List.of(
          averageRangeOrPassFailMarkingGroupProgress(
            combinedMarkingGroups.studentPass,
            individualGroupMarkingGroups.count()
          )
        )
      : undefined,
    teacher: List.of(
      averageRangeOrPassFailMarkingGroupProgress(
        combinedMarkingGroups.teacherPass,
        individualGroupMarkingGroups.count()
      )
    ),
  };
}

function removePorMDGradedOutcomes(checklist: IChecklistRecord) {
  const cleanedOutcomes = checklist.learning_outcomes.filter(
    outcome => outcome.group.total_marks !== MarkingLevelPorMD.MeritDistinction
  );
  return checklist.set('learning_outcomes', cleanedOutcomes);
}

function checklistToMarkingGroups(checklist: IChecklistRecord): IMarkingGroups {
  const markingCode = checklist.strand.marking_code;
  const selfMark = checklist.strand.learner_tagging;
  const checklistWithoutGradedOutcomes =
    markingCode === MARKING_CODES.PorMD
      ? removePorMDGradedOutcomes(checklist)
      : checklist;

  if (markingCode === MARKING_CODES.PMD) {
    return checklistToPMDMarkingGroups(selfMark, checklist);
  } else {
    return checklistToRangeOrPassFailMarkingGroups(
      selfMark,
      checklistWithoutGradedOutcomes
    );
  }
}
