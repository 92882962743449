import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ContentBox,
  ContentBoxHeader,
  Section,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';
import { openModal } from '../../../actions/modals';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { IRouteProps } from '../../../index';
import { LOAD_ITEM } from '../../../items/actions';
import { ALL_ADMIN_USERS, canManageQualifications } from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import {
  CLASS_STATUSES,
  IClassRecord,
} from '../../../store/data-types/classes';
import { IProfile } from '../../../store/data-types/profile';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import { formatDate } from '../../../utils';
import ClassDetailSpecialisedUserList from '../../classes/class-detail-specialised-user-list';
import Loading from '../../loading';
import IfCan from '../../permissions/if-can';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';
import { default as Term, terminologyFromProfile } from '../../terminology';
import AssignQualificationModal from './assign-qualification-modal';

const { actions: { loadItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;

const MANAGE_QUALIFICATION = 'MANAGE_QUALIFICATION';
const FIRST_COLUMN_WIDTH = 150;

function getHeaders(qualificationId: string): Array<IColumnData<IClassRecord>> {
  return [
    {
      content: data => data.name,
      headerLabel: 'Name',
      key: 'name',
      sortable: true,
      width: FIRST_COLUMN_WIDTH,
    },
    {
      content: data => (
        <Link
          to={`/tasks/qualifications/${qualificationId}/classes/${data.id}/`}
        >
          Manage Qualification
        </Link>
      ),
      headerLabel: 'Actions',
      key: 'actions',
      sortable: false,
    },
  ];
}

interface IQualificationPagePropsWithoutActions {
  classesCollection: ICollection<IClassRecord>;
  classesLoading: boolean;
  loading: boolean;
  profile: IProfile;
  qualification: IQualificationRecord | null;
  qualificationId: string;
  externalQualityAssurers: ReadonlyArray<IProfile>;
}

interface IQualificationPageProps
  extends IQualificationPagePropsWithoutActions {
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  loadItem(type: keyof IItemsState, itemId: string): void;
  openModal(modal: React.ReactNode): void;
}
export class QualificationPage extends React.PureComponent<
  IQualificationPageProps,
  void
> {
  public constructor(props: IQualificationPageProps) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  public componentWillMount() {
    this.loadData();
  }

  public render() {
    const {
      classesCollection,
      classesLoading,
      loading,
      profile,
      qualification,
      qualificationId,
      externalQualityAssurers,
    } = this.props;

    if (!canManageQualifications(profile)) {
      return (
        <Alert className="error">
          <p>Sorry you are not allowed to see this page</p>
        </Alert>
      );
    }

    if (loading) {
      return <Loading />;
    }

    if (!qualification) {
      return (
        <Alert className="error">
          <p>Failed to load Qualification.</p>
        </Alert>
      );
    }

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">{qualification.name}</h2>
        </ContentBoxHeader>
        {qualification.description && (
          <Section>
            <p
              className="info pre"
              dangerouslySetInnerHTML={{ __html: qualification.description }}
            />
          </Section>
        )}
        <Section>
          <p>Start Date: {formatDate(qualification.start_date)}</p>
          <p>End Date: {formatDate(qualification.end_date)}</p>
        </Section>
        <Section>
          <ClassDetailSpecialisedUserList
            relatedLabel="for this qualification"
            users={externalQualityAssurers}
            label={terminologyFromProfile(profile, 'External Quality Assurers')}
          />
        </Section>
        <Section>
          <Button
            className="primary float-right"
            onClick={() =>
              this.props.openModal(
                <AssignQualificationModal
                  qualification={qualification}
                  onComplete={() =>
                    getAllCollection(
                      'classes',
                      { filters: Map({ qualification: qualificationId }) },
                      MANAGE_QUALIFICATION
                    )}
                />
              )}
          >
            Assign to <Term>Classes</Term>
          </Button>
          <h5>
            <Term>Classes</Term>
          </h5>
          <CollectionTable
            headers={getHeaders(qualificationId)}
            collection={classesCollection}
            loading={classesLoading}
            pageSize={10}
            getCollection={(options: ICollectionOptions) => {
              const filters = options.filters || Map<string, string>();
              return this.props.getAllCollection(
                'classes',
                _.assign({}, options, {
                  filters: filters
                    .set('qualification', qualificationId)
                    .set('status', CLASS_STATUSES.ACTIVE),
                }),
                MANAGE_QUALIFICATION
              );
            }}
          />
        </Section>
      </ContentBox>
    );
  }

  private loadData() {
    this.props.loadItem('courses', this.props.qualificationId);
  }
}

function mapStateToProps(
  { collectionsOld, itemsOld, responses, profile }: IStore,
  props: IRouteProps
): IQualificationPagePropsWithoutActions {
  const qualification = itemsOld.courses;

  return {
    classesCollection: getCollectionByName(
      collectionsOld.classes,
      MANAGE_QUALIFICATION
    ),
    classesLoading: isPending(responses, GET_COLLECTION, 'classes'),
    loading: isPending(responses, LOAD_ITEM, 'courses'),
    profile,
    qualification,
    qualificationId: props.params.id,
    externalQualityAssurers:
      qualification && qualification.external_quality_assurers
        ? qualification.external_quality_assurers.toArray() || []
        : [],
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  loadItem,
  openModal,
})(QualificationPage);
