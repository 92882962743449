import * as React from 'react';

import { Button, FormGroup, InputGroup } from '@dabapps/roe';
import * as classnames from 'classnames';
import { Field, FormProps, reduxForm } from 'redux-form';

export interface ISearchForm {
  search?: string;
}

interface IProps {
  placeholder?: string;
  loading: boolean;
  hideLabel?: boolean;
  form: string;
  className?: string;
  onSubmit(data: ISearchForm): void;
}

export class UserSearch extends React.PureComponent<
  FormProps<ISearchForm, {}, {}> & IProps,
  void
> {
  public render() {
    const { placeholder, loading, hideLabel, className } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className={classnames('user-search-form', className)}
      >
        <FormGroup block>
          {!hideLabel && <label>Search</label>}
          <InputGroup className="margin-none">
            <Field
              component="input"
              type="text"
              name="search"
              disabled={loading}
              placeholder={placeholder}
              normalize={(value?: string) => (!value ? '' : value)}
            />
            <Button
              onClick={this.props.handleSubmit}
              className="width-auto"
              component="div"
            >
              Go
            </Button>
          </InputGroup>
        </FormGroup>
      </form>
    );
  }
}

export default reduxForm({})(UserSearch);
