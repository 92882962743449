import { FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { Field, FormProps, reduxForm } from 'redux-form';

const FORM_NAME = 'addLearningOutcomeBlockComment';

interface IExternalProps {
  evidenceTypeOral: boolean;
}

export interface IFormData {
  newComment?: string;
  criteriaMetOrally?: boolean;
}

type IFormProps = React.HTMLProps<JSX.Element> & FormProps<IFormData, {}, {}>;

function AddLearningOutcomeBlockCommentForm({
  evidenceTypeOral,
  handleSubmit,
}: IFormProps & IExternalProps) {
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup block>
        <Field
          name="newComment"
          component="textarea"
          placeholder="Your comment..."
        />
      </FormGroup>
      {evidenceTypeOral && (
        <FormGroup block>
          <Field
            id="criteria-met-orally"
            name="criteriaMetOrally"
            component="input"
            type="checkbox"
          />
          <label htmlFor="criteria-met-orally">
            Criteria met through oral comment?
          </label>
        </FormGroup>
      )}
      <a onClick={handleSubmit}>Add comment</a>
    </form>
  );
}

export default reduxForm<IFormData, IFormProps>({
  form: FORM_NAME,
})(AddLearningOutcomeBlockCommentForm);
