import * as React from 'react';

import { ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import { redirectIfNotRole } from '../../actions/permissions';
import { CAN_GENERATE_REPORTS, isSuperUser } from '../../permissions';
import { IStore } from '../../store';
import { IProfile, TRole } from '../../store/data-types/profile';

interface IProps {
  profile: IProfile;
  redirectIfNotRole(
    roles: Set<TRole>,
    redirectPath: string,
    user: IProfile
  ): void;
}

const CENTRE_ACTIVITY_REPORT_URL = '/reports/centre-activity/';
const ADMIN_USERS_REPORT_URL = '/reports/admin-users/';
const CENTRES_WITH_UNASSURED_QUALIFICATIONS_REPORT_URL =
  '/reports/centres-with-unassured-qualifications/';

export class ReportsPage extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.checkPathAndRedirect(this.props);
  }

  public componentWillUpdate(nextProps: IProps) {
    this.checkPathAndRedirect(nextProps);
  }

  public render() {
    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">Reports</h2>
        </ContentBoxHeader>

        <Section>
          <p>
            Centre Activity Report:{' '}
            <a href={CENTRE_ACTIVITY_REPORT_URL} download>
              Generate & Download
            </a>
          </p>
          {isSuperUser(this.props.profile) && (
            <p>
              Admin Users Report:{' '}
              <a href={ADMIN_USERS_REPORT_URL} download>
                Generate & Download
              </a>
            </p>
          )}
          <p>
            Centres Without EQAs Assigned To Qualifications Report:{' '}
            <a href={CENTRES_WITH_UNASSURED_QUALIFICATIONS_REPORT_URL} download>
              Generate & Download
            </a>
          </p>
        </Section>
      </ContentBox>
    );
  }

  private checkPathAndRedirect(props: IProps) {
    const { profile } = props;
    return props.redirectIfNotRole(CAN_GENERATE_REPORTS, '/', profile);
  }
}

function mapStateToProps({ profile }: IStore) {
  return {
    profile,
  };
}

export default connect(mapStateToProps, { redirectIfNotRole })(ReportsPage);
