import * as React from 'react';

import { ICommentRecord } from '../../store/data-types';
import { IProfile } from '../../store/data-types/profile';
import { formatRoles, formatTimeAgo } from '../../utils';
import CommentBox from './comment-box';

interface IProps {
  comment: ICommentRecord;
  showRootComment: boolean;
  showRole: boolean;
  showNewComment: boolean;
  profile: IProfile;
  onCommentAdded?(): void;
}

export default class Comment extends React.PureComponent<IProps, void> {
  public render(): React.ReactElement<Comment> {
    const {
      comment,
      showRootComment,
      showRole,
      onCommentAdded,
      showNewComment,
      profile,
    } = this.props;

    return (
      <ul className="comments">
        <li className="comment">
          {(comment.parent || showRootComment) && (
            <div>
              <p>{comment.content}</p>
              <p className="info">
                by {comment.owner.osms_data.name}
                {showRole
                  ? ` (${formatRoles(comment.owner.display_roles, profile)})`
                  : ''}{' '}
                {formatTimeAgo(comment.created)}
              </p>
              <CommentBox
                commentId={comment.id}
                onCommentAdded={onCommentAdded}
              />
            </div>
          )}
          {comment.children.map((childComment: ICommentRecord) => (
            <Comment
              key={childComment.id}
              comment={childComment}
              showRootComment={showRootComment}
              showRole={showRole}
              onCommentAdded={onCommentAdded}
              profile={profile}
              showNewComment={showNewComment}
            />
          ))}
        </li>
      </ul>
    );
  }
}
