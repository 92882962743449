import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Column,
  Row,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { DataShape, FormProps, reduxForm } from 'redux-form';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';

import { CENTRE_ADMIN, TEACHER, userHasRoles } from '../../../permissions/';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IQuotaRecord } from '../../../store/data-types';
import {
  IClassStudentDetails,
  ITaskStatus,
} from '../../../store/data-types/classes';
import { IProfile } from '../../../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../../../store/data-types/tasks';
import { convertBytesToMegaBytes } from '../../../utils';
import { PopoverStudentTasks } from '../../dashboard/tasks-by-status-popover';
import TasksByStatusPopover from '../../dashboard/tasks-by-status-popover';
import Loading from '../../loading';
import Popover from '../../popover';
import SimplePopover from '../../simple-popover';
import { default as SimpleTable, IColumnData } from '../../tables/simple-table';
import { CLASS_DETAIL_TEACHER } from '../class-detail-teacher';

const { actions: { getAllCollection } } = collectionsModule;

import Term from '../../terminology';

const COLUMN_HEADER_WIDTH = 200;

interface IExternalStudentTableProps {
  classId: string;
}

interface IStudentTableWithoutActionsProps extends IExternalStudentTableProps {
  loading: boolean;
  studentDetails: List<IClassStudentDetails>;
  profile: IProfile;
}
interface IStudentTableProps extends IStudentTableWithoutActionsProps {
  getAllCollection: typeof getAllCollection;
}

function TaskCountPopover({
  tasks,
  studentName,
}: {
  tasks: ITaskStatus[];
  studentName: string | undefined;
}) {
  return tasks.length === 0 ? (
    <span>0</span>
  ) : (
    <SimplePopover
      bottom
      maxWidth={350}
      width={350}
      toggleComponent={<a onClick={() => undefined}>{tasks.length}</a>}
    >
      <PopoverStudentTasks studentName={studentName} tasks={tasks} />
    </SimplePopover>
  );
}

export class StudentTable extends React.PureComponent<
  // tslint:disable-line:max-classes-per-file
  IStudentTableProps,
  void
> {
  public constructor(props: IStudentTableProps) {
    super(props);
    this.loadStudents = this.loadStudents.bind(this);
  }

  public componentWillMount() {
    this.loadStudents();
  }

  public render() {
    const { classId, loading, studentDetails } = this.props;
    const allStudents = studentDetails.map(each => each.id).toSet();

    return (
      <div>
        <SimpleTable
          striped
          hover
          tableCellClassName="overflow-visible"
          items={studentDetails}
          headers={this.getHeaders()}
          loading={loading}
        />
      </div>
    );
  }

  private loadStudents() {
    const filters = Map({
      class_id: this.props.classId,
    });
    this.props.getAllCollection(
      'classes/student-details',
      { filters, ordering: 'userprofilecache__name' },
      CLASS_DETAIL_TEACHER
    );
  }

  private getHeaders(): Array<IColumnData<IClassStudentDetails>> {
    const headers = [
      {
        width: COLUMN_HEADER_WIDTH,
        key: 'name',
        sortable: false,
        headerLabel: 'Name',
        content: (data: IClassStudentDetails) =>
          userHasRoles(this.props.profile, Set.of(CENTRE_ADMIN, TEACHER)) ? (
            <a href={`/users/student/${data.id}/`}>
              <span>{data.osms_data.name || '--'}</span>
            </a>
          ) : (
            <span>{data.osms_data.name || '--'}</span>
          ),
      },
      {
        key: 'username',
        sortable: false,
        headerLabel: 'Username',
        content: (data: IClassStudentDetails) =>
          data.osms_data.username || '--',
      },
      {
        key: 'open',
        sortable: false,
        headerLabel: 'Open',
        content: (data: IClassStudentDetails) => (
          <TasksByStatusPopover
            studentName={data.osms_data.name}
            tasksCount={data.tasks_in_progress_count}
            userId={data.id}
            statuses={[STATUS.IN_PROGRESS]}
          />
        ),
      },
      {
        key: 'awaiting_marking',
        sortable: false,
        headerLabel: 'Awaiting marking',
        content: (data: IClassStudentDetails) => (
          <TasksByStatusPopover
            studentName={data.osms_data.name}
            tasksCount={data.tasks_awaiting_marking_count}
            userId={data.id}
            statuses={[STATUS.AWAITING_MARKING]}
          />
        ),
      },
      {
        key: 'marked',
        sortable: false,
        headerLabel: 'Marked',
        content: (data: IClassStudentDetails) => (
          <TasksByStatusPopover
            studentName={data.osms_data.name}
            tasksCount={data.tasks_marked_count}
            userId={data.id}
            statuses={[
              STATUS.MARKED,
              STATUS.PENDING_SUBMISSION,
              STATUS.SUBMITTED,
              STATUS.PASSED_IQA,
            ]}
          />
        ),
      },
      {
        key: 'awarded',
        sortable: false,
        headerLabel: 'Awarded',
        content: (data: IClassStudentDetails) => (
          <TasksByStatusPopover
            studentName={data.osms_data.name}
            tasksCount={data.tasks_awarded_count}
            userId={data.id}
            statuses={[
              STATUS.PASSED_EQA,
              STATUS.PENDING_SUBMISSION,
              STATUS.SUBMITTED,
            ]}
          />
        ),
      },
      {
        key: 'quota',
        sortable: false,
        headerLabel: 'Quota',
        content: (data: IClassStudentDetails) => {
          const quotaUsedSize = convertBytesToMegaBytes(data.usage).toFixed(2);
          const quotaUsedPercentage = Math.ceil(data.usage / data.quota * 100);
          return `${quotaUsedSize}mb (${quotaUsedPercentage}% used)`;
        },
      },
    ];
    if (userHasRoles(this.props.profile, Set.of(CENTRE_ADMIN, TEACHER))) {
      return [
        ...headers,
        {
          key: 'view',
          sortable: false,
          headerLabel: 'View',
          content: (data: IClassStudentDetails) => (
            <a className="button tertiary" href={`/users/student/${data.id}/`}>
              View
            </a>
          ),
        },
      ];
    }
    return headers;
  }
}

function mapStateToProps(
  { collectionsOld, responses, profile }: IStore,
  props: IExternalStudentTableProps
): IStudentTableWithoutActionsProps {
  return {
    studentDetails: getCollectionItems(
      collectionsOld.get('classes/student-details'),
      CLASS_DETAIL_TEACHER
    ),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'classes/student-details'],
    ]),
    profile,
    ...props,
  };
}

export default connect(mapStateToProps, { getAllCollection })(StudentTable);
