import { Alert, Button, Column, FormGroup, Row, Well } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import * as ReactSelect from 'react-select';
import { DataShape, FormProps, WrappedFieldProps } from 'redux-form';
import { ISimpleUser } from '../../store/data-types/profile';

import Term from '../terminology';

const { IconUser } = FontAwesome;

interface IUserSelectProps
  extends React.HTMLProps<JSX.Element>,
    WrappedFieldProps<void> {
  users: List<ISimpleUser>;
  multi: boolean;
  canRemoveUser(userId: string, users: Set<string>): boolean;
}

export default function UserSelect({
  canRemoveUser,
  input,
  label,
  users,
  multi,
}: IUserSelectProps) {
  const value: Set<string> = (input && input.value) || Set();
  const onChange = input ? input.onChange : () => null;
  const options = users
    .filterNot(each => value.contains(each.id))
    .map(each => ({ value: each.id, label: each.osms_data.name }))
    .toJS();
  return (
    <div>
      <ReactSelect
        placeholder="Start typing name"
        options={options}
        disabled={multi ? false : value.count() > 0}
        onChange={(newValue: ReactSelect.Option) =>
          onChange(value.add(newValue.value as string) as any)}
      />
      <Well className="margin-vertical-base padding-base fixed">
        <ul className="user-select">
          {value.map(each => {
            const valueUser = users.find(item => item.id === each);
            const userCanBeRemoved = canRemoveUser(each, value);

            return (
              valueUser && (
                <Column
                  component="li"
                  key={each}
                  className="margin-vertical-small"
                  sm={6}
                  lg={4}
                >
                  <div className="user-select-user">
                    <span className="flex-table">
                      <span className="flex-cell">
                        <IconUser className="icon-small" />
                      </span>
                      <span className="flex-cell">
                        {valueUser.osms_data.name}
                        <br />
                        {valueUser.osms_data.username}
                      </span>
                      {userCanBeRemoved && (
                        <span className="flex-cell">
                          <Button
                            className="error"
                            onClick={event => {
                              event.preventDefault();
                              onChange(value.remove(each) as any);
                            }}
                          >
                            Remove
                          </Button>
                        </span>
                      )}
                    </span>

                    {!userCanBeRemoved && (
                      <p className="info font-size-small margin-none">
                        Cannot remove the last <Term>Teacher</Term> or yourself.
                      </p>
                    )}
                  </div>
                </Column>
              )
            );
          })}
          {value.count() === 0 && (
            <li className="info">No {label || 'users'} selected...</li>
          )}
        </ul>
      </Well>
    </div>
  );
}
