import { Set } from 'immutable';
import {
  ALL_ADMIN_USERS,
  ALL_QA_USERS,
  CENTRE_ADMIN,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  TEACHER,
  userHasRoles,
} from '.';
import { IClassRecord } from '../store/data-types/classes';
import { TStatus } from '../store/data-types/common';
import { IProfile } from '../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../store/data-types/tasks';

export type TCheckboxPermissions = 'HIDE' | 'SHOW_DISABLED' | 'SHOW_ENABLED';

export function getStudentChecklistPermissions(
  task: IUserTaskRecord,
  loggedInUser: IProfile,
  isTaskUser: boolean,
  loading: boolean,
  isRecr: boolean,
  selfMark: boolean
): TCheckboxPermissions {
  const canSee = selfMark && !isRecr;

  if (!canSee) {
    return 'HIDE';
  }

  const hasInteractionRole =
    isTaskUser || userHasRoles(loggedInUser, ALL_ADMIN_USERS.toSet());

  const canInteract =
    !loading && task.status === 'IN_PROGRESS' && hasInteractionRole;

  return canInteract ? 'SHOW_ENABLED' : 'SHOW_DISABLED';
}

export function getTeacherChecklistPermissions(
  task: IUserTaskRecord,
  loggedInUser: IProfile,
  isTaskUser: boolean,
  loading: boolean
): TCheckboxPermissions {
  const isCorrectStatus =
    task.status === STATUS.IN_PROGRESS ||
    task.status === STATUS.AWAITING_MARKING;

  const hasInteractionRole =
    !isTaskUser &&
    userHasRoles(
      loggedInUser,
      Set()
        .concat(Set.of(TEACHER, CENTRE_ADMIN))
        .concat(ALL_ADMIN_USERS)
    );

  const canInteract = !loading && isCorrectStatus && hasInteractionRole;

  return canInteract ? 'SHOW_ENABLED' : 'SHOW_DISABLED';
}

export const getIQATaskStatusPermission = (status: TStatus) =>
  ([
    STATUS.IN_PROGRESS,
    STATUS.AWAITING_MARKING,
    STATUS.MARKED,
  ] as TStatus[]).indexOf(status) !== -1;

export function getIQAChecklistPermissions(
  task: IUserTaskRecord,
  loggedInUser: IProfile,
  isTaskUser: boolean,
  loading: boolean,
  taskClass: IClassRecord
): TCheckboxPermissions {
  const isCorrectStatus = getIQATaskStatusPermission(task.status);

  const canSee =
    !isTaskUser &&
    userHasRoles(
      loggedInUser,
      Set()
        .concat(ALL_ADMIN_USERS)
        .concat(ALL_QA_USERS)
        .concat(Set.of(TEACHER, CENTRE_ADMIN))
    );

  if (!canSee) {
    return 'HIDE';
  }

  const taskIQAIds = (taskClass.internal_quality_assurers as ReadonlyArray<
    IProfile
  >).map(iqa => iqa.id);
  const isInTaskIQAs = taskIQAIds.indexOf(loggedInUser.id) !== -1;

  const hasInteractionRole =
    !isTaskUser &&
    userHasRoles(loggedInUser, Set.of(INTERNAL_QUALITY_ASSURER)) &&
    isInTaskIQAs;

  const canInteract =
    !loading && canSee && hasInteractionRole && isCorrectStatus;

  return canInteract ? 'SHOW_ENABLED' : 'SHOW_DISABLED';
}

export const getEQATaskStatusPermission = (status: TStatus) =>
  ([
    STATUS.IN_PROGRESS,
    STATUS.AWAITING_MARKING,
    STATUS.MARKED,
    STATUS.PASSED_IQA,
  ] as TStatus[]).indexOf(status) !== -1;

export function getEQAChecklistPermissions(
  task: IUserTaskRecord,
  loggedInUser: IProfile,
  isTaskUser: boolean,
  loading: boolean
): TCheckboxPermissions {
  const isCorrectStatus = getEQATaskStatusPermission(task.status);

  const canSee =
    !isTaskUser &&
    userHasRoles(
      loggedInUser,
      Set()
        .concat(ALL_ADMIN_USERS)
        .concat(ALL_QA_USERS)
        .concat(Set.of(CENTRE_ADMIN))
    );

  if (!canSee) {
    return 'HIDE';
  }

  const hasInteractionRole =
    !isTaskUser && userHasRoles(loggedInUser, Set.of(EXTERNAL_QUALITY_ASSURER));

  const canInteract = canSee && hasInteractionRole && isCorrectStatus;

  return canInteract ? 'SHOW_ENABLED' : 'SHOW_DISABLED';
}
