import * as React from 'react';

import { FormGroup, SpacedGroup } from '@dabapps/roe';
import { List, Record } from 'immutable';
import { IRadioButtonRecord } from '../../store/data-types';
import Term from '../terminology';

export interface IProps {
  buttons: List<IRadioButtonRecord>;
  value?: string | boolean | number;
  defaultValue: string | boolean | number;
  onChange(key: string | boolean | number): void;
}

export default class RadioButtons extends React.PureComponent<IProps, void> {
  public render() {
    const {
      buttons,
      value,
      defaultValue,
      onChange,
      ...remainingProps,
    } = this.props;

    return (
      <SpacedGroup {...remainingProps}>
        {buttons.map(button => (
          <FormGroup
            onClick={() => onChange(button.key)}
            key={button.key}
            className="display-inline"
          >
            <input
              onChange={() => null}
              checked={
                value === undefined
                  ? button.key === defaultValue
                  : button.key === value
              }
              type="radio"
            />
            <label>
              <Term>{button.label}</Term>
            </label>
          </FormGroup>
        ))}
      </SpacedGroup>
    );
  }
}
