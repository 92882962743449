import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Column, ContentBoxHeader, Row } from '@dabapps/roe';
import { Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';

import { ICollectionOptions } from '../../collections/types';
import { LOAD_ITEM } from '../../items/actions';

import { JournalType } from '../../actions/journals';
import { redirectIfNotRole } from '../../actions/permissions';
import { STUDENT, TEACHER, userHasRoles } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IProfile, TRole } from '../../store/data-types/profile';
import Loading from '../loading';
import { terminologyFromProfile } from '../terminology';
import JournalEntryFeed, { JOURNAL_FEED } from './journal-feed';
import JournalPage from './journal-page';

const { actions: { getCollection } } = collectionsModule;
const { actions: { loadItem } } = itemsModule;

interface IExternalProps {
  journalType?: JournalType;
  isViewingUnderStudentContext?: boolean;
  userId?: string;
  classId?: string;
  location: {
    pathname: string;
  };
}

interface IProps extends IExternalProps {
  loading: boolean;
  user: IProfile | null;
  profile: IProfile;

  loadItem(type: keyof IItemsState, id: string): void;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  redirectIfNotRole(
    roles: Set<TRole>,
    redirectPath: string,
    user: IProfile
  ): void;
}

export class JournalFeedPage extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    const { journalType, userId, profile } = this.props;

    if (this.shouldRedirect()) {
      // this is the personal journal
      this.props.redirectIfNotRole(Set.of(STUDENT), '/journal/class/', profile);
    }

    if (journalType === 'user' && userId) {
      this.props.loadItem('users', userId);
    }
  }

  public componentDidMount() {
    if (!this.shouldRedirect()) {
      // prevent calling the API if we need to redirect
      const { journalType, profile, userId, classId } = this.props;

      const filters = Map({
        user_id: userId ? userId : '',
        journal_type: journalType ? journalType : 'self',
        class_id: classId ? classId : '',
      }).filter(x => x);

      this.props.getCollection('journals', { filters }, JOURNAL_FEED);
    }
  }

  public render() {
    const {
      journalType,
      loading,
      location,
      isViewingUnderStudentContext,
    } = this.props;

    if (this.shouldRedirect()) {
      // prevent rendering the child components with their API calls if we need to redirect
      return null;
    }

    return loading ? (
      <Loading />
    ) : (
      <JournalPage location={location} hideNav={journalType === 'user'}>
        <ContentBoxHeader>
          {this.canAddEntry() && (
            <Link
              to="/journal/add-entry/"
              className="button primary float-right margin-vertical-base"
            >
              Add New Entry
            </Link>
          )}
          <h2 className="font-size-large">{this.getJournalHeader()}</h2>
        </ContentBoxHeader>
        <Row>
          <Column>
            <JournalEntryFeed
              journalType={journalType}
              canEditPosts={this.canAddEntry()}
              isViewingUnderStudentContext={isViewingUnderStudentContext}
            />
          </Column>
        </Row>
      </JournalPage>
    );
  }

  private shouldRedirect() {
    const { journalType, profile, isViewingUnderStudentContext } = this.props;
    return (
      (!journalType || journalType === 'self') &&
      !userHasRoles(profile, Set.of(STUDENT)) &&
      !isViewingUnderStudentContext
    );
  }

  private getJournalHeader() {
    const { journalType, user, profile } = this.props;
    if (journalType === 'user') {
      return user ? `${user.osms_data.name}'s Journal` : 'Journal';
    }
    if (this.isMyJournal()) {
      return 'My Journal';
    }
    return `${terminologyFromProfile(profile, 'Class')} Journal`;
  }

  private isMyJournal() {
    const { journalType, profile } = this.props;

    return !journalType || journalType === 'self';
  }

  private canAddEntry() {
    const {
      journalType,
      user,
      profile,
      isViewingUnderStudentContext,
    } = this.props;
    const isTeacher = userHasRoles(profile, Set.of(TEACHER));
    return (
      !user &&
      ((journalType === 'class' && isTeacher) ||
        (this.isMyJournal() && !isViewingUnderStudentContext))
    );
  }
}

function mapStateToProps(
  { responses, itemsOld, profile }: IStore,
  props: IExternalProps
) {
  return {
    loading: isPending(responses, LOAD_ITEM, 'users'),
    profile,
    user: itemsOld.users,
    ...props,
  };
}

export default connect(mapStateToProps, {
  loadItem,
  getCollection,
  redirectIfNotRole,
})(JournalFeedPage);
