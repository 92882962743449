import { Alert, ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import * as React from 'react';
import {
  IClassTaskRecord,
  INestedUserTask,
} from '../../store/data-types/tasks';
import Loading from '../loading';
import { default as SimpleTable, IColumnData } from '../tables/simple-table';
import Term from '../terminology';

interface IProps {
  openMarkschemeModal: () => void;
  headers: Array<IColumnData<INestedUserTask>>;
  successMessage?: string;
  loading?: boolean;
  classTask: IClassTaskRecord | null;
  sortCallback?(left: INestedUserTask, right: INestedUserTask): number;
}

export default class TaskDetailBase extends React.PureComponent<IProps, void> {
  public render() {
    const { classTask, loading, successMessage, sortCallback } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large display-inline-block">
            {loading && 'Loading...'}
            {!loading && !classTask && 'Failed to load'}
            {!loading &&
              (classTask &&
                `Task: ${classTask.component.title} - ${classTask.task_class
                  .name}`)}
            {!loading &&
              classTask &&
              classTask.task_class.is_archived && (
                <span>
                  {' '}
                  (ARCHIVED <Term>CLASS</Term>)
                </span>
              )}
          </h2>
          <div className="float-right margin-top-base padding-top-base">
            <a onClick={this.props.openMarkschemeModal}>View markscheme</a>
          </div>
        </ContentBoxHeader>
        {loading && <Loading />}
        {!loading &&
          !classTask && (
            <p>
              Failed to load <Term>Task</Term> - it may not exist, or you may
              not have permission to view it
            </p>
          )}
        {classTask &&
          !loading && (
            <Section>
              {successMessage && (
                <Alert className="success">
                  <p>{successMessage}</p>
                </Alert>
              )}
              <SimpleTable
                items={
                  sortCallback
                    ? classTask.user_tasks.sort(sortCallback)
                    : classTask.user_tasks
                }
                headers={this.props.headers}
                loading={this.props.loading}
              />
              {this.props.children}
            </Section>
          )}
      </ContentBox>
    );
  }
}
