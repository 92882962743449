import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';

export const EXEMPT_USER_QUOTA = makeAsyncActionSet('EXEMPT_USER_QUOTA');
export function exemptUser(userId: string) {
  return dispatchGenericRequest(
    EXEMPT_USER_QUOTA,
    `/api/assets/quotas/${userId}/exempt/`,
    'PUT',
    null,
    undefined
  );
}

export const DELETE_ASSET = makeAsyncActionSet('DELETE_ASSET');
export function deleteAsset(assetId: string) {
  return dispatchGenericRequest(
    DELETE_ASSET,
    `/api/assets/${assetId}/`,
    'DELETE',
    null,
    undefined
  );
}
