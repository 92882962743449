import {
  hasFailed,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { change, DataShape, FormProps, WrappedFieldProps } from 'redux-form';
import {
  clearJournalTaskData,
  setJournalTaskData,
} from '../../../actions/journals';
import { LOAD_ITEM } from '../../../items/actions';
import { CENTRE_ADMIN, TEACHER, userHasRoles } from '../../../permissions';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { TActionAny, Thunk } from '../../../requests/types';
import store, { IStore } from '../../../store';
import { IAssetRecord } from '../../../store/data-types';
import { IClassRecord } from '../../../store/data-types/classes';
import {
  IJournalEntry,
  IJournalTaskData,
} from '../../../store/data-types/journals';
import { IProfile, ISimpleUser } from '../../../store/data-types/profile';
import {
  IUserTaskRecord,
  UserTaskRecord,
} from '../../../store/data-types/tasks';
import Term from '../../terminology';
import SelectJournalTasksModal from './select-journal-tasks-modal';

const { actions: { clearItem, loadItem } } = itemsModule;

interface IEditingProps {
  mode: 'EDITING';
  onChange(values: IJournalTaskData): AxiosPromise | Promise<void>;
}

interface IReadOnlyProps {
  mode: 'READONLY';
}

type TModeOptions = IReadOnlyProps | IEditingProps;

interface ITasksSidebarExternalProps {
  journalEntry?: IJournalEntry;
  isViewingUnderStudentContext?: boolean;
  showTaskLink: boolean;
  modeOptions: TModeOptions;
  profile: IProfile;
  entryClass: string | null;
  openModal(modal: React.ReactNode): void;
}

interface ITasksSidebarConnectedProps {
  loading: boolean;
  failed: boolean;
  journalTaskData: IJournalTaskData;
  userTask: IUserTaskRecord | null;
}

interface ITasksSidebarActionProps {
  clearItem(itemType: keyof IItemsState): AxiosPromise;
  loadItem(
    type: keyof IItemsState,
    id: string,
    preserveOriginal?: boolean
  ): AxiosPromise;
  setJournalTaskData(data: IJournalTaskData): void;
  clearJournalTaskData(): void;
}

export class TasksSidebar extends React.PureComponent<
  ITasksSidebarExternalProps &
    ITasksSidebarConnectedProps &
    ITasksSidebarActionProps,
  void
> {
  public constructor(
    props: ITasksSidebarExternalProps &
      ITasksSidebarConnectedProps &
      ITasksSidebarActionProps
  ) {
    super(props);
    this.clearTask = this.clearTask.bind(this);
  }
  public componentWillMount() {
    this.props.clearItem('tasks');
    this.loadTaskName();
  }

  public componentDidUpdate() {
    this.loadTaskName();
  }

  public render() {
    const {
      loading,
      modeOptions,
      profile,
      journalEntry,
      userTask,
      entryClass,
      journalTaskData,
    } = this.props;
    const isTeacher = userHasRoles(profile, Set.of(TEACHER, CENTRE_ADMIN));

    const shouldHide = Boolean(entryClass) || (isTeacher && !journalEntry);

    if (shouldHide) {
      return null;
    }

    const showEditables =
      !isTeacher && !entryClass && !loading && modeOptions.mode === 'EDITING';

    return (
      <Row>
        <Column>
          <h6 className="font-size-base font-weight-bold">
            Tagged <Term>Task</Term>
          </h6>
          {this.renderSelectedTask(journalTaskData)}
          {showEditables && (
            <span>
              {userTask ? (
                <a className="button" onClick={this.clearTask}>
                  Remove
                </a>
              ) : (
                <a
                  onClick={event => {
                    event.preventDefault();
                    if (
                      this.props.openModal &&
                      modeOptions.mode === 'EDITING'
                    ) {
                      this.props.openModal(
                        <SelectJournalTasksModal
                          profile={profile}
                          onChangeTask={(values: IJournalTaskData) => {
                            this.props.setJournalTaskData(values);
                            modeOptions.onChange(values);
                          }}
                        />
                      );
                    }
                  }}
                >
                  tag post to <Term>Task</Term>&hellip;
                </a>
              )}
            </span>
          )}
        </Column>
      </Row>
    );
  }

  private loadTaskName() {
    const { modeOptions, journalTaskData } = this.props;
    const selectedTask = journalTaskData.selectedTask;

    const { loading, failed, userTask } = this.props;

    if (
      (!userTask || userTask.id !== selectedTask) &&
      !loading &&
      selectedTask
    ) {
      this.props.loadItem('tasks', selectedTask);
    }
  }

  private renderTaskTitle(userTask: IUserTaskRecord, showTaskLink: boolean) {
    const { isViewingUnderStudentContext } = this.props;
    const baseTaskUrl = `/tasks/marksheet/${userTask.id}/`;
    const taskUrl = isViewingUnderStudentContext
      ? `/users/student/${(userTask.user as ISimpleUser).id}${baseTaskUrl}`
      : baseTaskUrl;
    if (showTaskLink) {
      return (
        <p>
          <Link to={taskUrl}>{userTask.class_task.component.title}</Link>
        </p>
      );
    }
    return <p>{userTask.class_task.component.title}</p>;
  }

  private renderSelectedTask(journalTaskData: IJournalTaskData) {
    const mode = this.props.modeOptions.mode;
    const editing = mode === 'EDITING';
    const {
      loading,
      failed,
      userTask,
      showTaskLink,
      journalEntry,
    } = this.props;

    const { selectedTask, offJobTrainingHours } = journalTaskData;

    if (!selectedTask) {
      return (
        <p>
          No <Term>Task</Term> added to this post.
        </p>
      );
    }

    if (loading) {
      return <p>Loading...</p>;
    }

    if (failed) {
      return (
        <p>
          Failed to load <Term>Task</Term>
        </p>
      );
    }

    if (userTask) {
      return (
        <div>
          {this.renderTaskTitle(userTask, showTaskLink)}
          {userTask.class_task.component.off_job_training_percent && (
            <p>
              {offJobTrainingHours || 0} hours of{' '}
              {userTask.class_task.component.off_job_training_percent}% off the
              job training
            </p>
          )}
        </div>
      );
    }
  }

  private clearTask() {
    const { modeOptions } = this.props;

    if (modeOptions.mode === 'EDITING') {
      this.props.clearJournalTaskData();
      // Eugh, AxiosPromise and ES6 promise aren't quite the same,
      // even though we just care about the .then call
      (modeOptions.onChange({
        offJobTrainingHours: null,
        selectedTask: null,
      }) as any).then(() => {
        this.props.clearItem('tasks');
      });
    }
  }
}

function mapStateToProps(
  { itemsOld, responses, journalTaskSidebar }: IStore,
  props: ITasksSidebarExternalProps
): ITasksSidebarConnectedProps & ITasksSidebarExternalProps {
  const userTask = itemsOld.tasks;

  return {
    ...props,
    userTask,
    journalTaskData: journalTaskSidebar,
    loading: isPending(responses, LOAD_ITEM, 'tasks'),
    failed: hasFailed(responses, LOAD_ITEM, 'tasks'),
  };
}

export default connect(mapStateToProps, {
  clearItem,
  loadItem,
  setJournalTaskData,
  clearJournalTaskData,
})(TasksSidebar);
