import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Column,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  Row,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldInputProps,
  WrappedFieldProps,
} from 'redux-form';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import { IExamSpecRecord, IOptionRecord } from '../../../store/data-types';
import { ICentre } from '../../../store/data-types/centres';
import { IClassRecord } from '../../../store/data-types/classes';
import { IProfile, USER_STATUSES } from '../../../store/data-types/profile';
import {
  ICourseworkProgressOverview,
  ICourseworkProgressOverviewUserTask,
  ICriteriaStatsRecord,
  IUnitDetail,
} from '../../../store/data-types/tasks';
import { constantToKebabCase, constantToTitleCase } from '../../../utils';
import ProgresShort from '../../progress-short';
import CollectionSelectFilter from '../../tables/collection-select-filter';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';
import ExamResultsIndicator from '../../tasks/exam-results-indicator';
import GradeIndicator from '../../tasks/grade-indicator';
import StatusIndicator from '../../tasks/status-indicator';
import { default as Term, terminologyFromProfile } from '../../terminology';
import { getCourseWorkProgressOverviewFor } from './utils';

const { actions: { loadItem } } = itemsModule;
const { actions: { getCollection, getAllCollection } } = collectionsModule;

export const FORM_NAME = 'coursework-progress-overview-detail';
const COURSEWORK_PROGRESS_OVERVIEW_DETAIL =
  'COURSEWORK_PROGRESS_OVERVIEW_DETAIL';
const PAGE_SIZE = 10;

interface IHeaderContext {
  value?: Set<ICourseworkProgressOverview>;
  onChange: (value: Set<ICourseworkProgressOverview>) => void;
}

const getHeaders = (
  context: IHeaderContext
): Array<IColumnData<ICourseworkProgressOverview>> => [
  {
    content(data: ICourseworkProgressOverview) {
      const value = context.value || Set<ICourseworkProgressOverview>();
      const checked = value.contains(data);

      return (
        <input
          type="checkbox"
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            context.onChange(checked ? value.delete(data) : value.add(data))}
        />
      );
    },
    headerLabel: '',
    key: 'checkbox',
    sortable: false,
  },
  {
    content: record =>
      record.osms_data.name +
      `${record.status === USER_STATUSES.ARCHIVED ? ' (ARCHIVED)' : ''}`,
    headerLabel: 'Name',
    key: 'name',
    sortable: false,
  },
  {
    content: record => record.osms_data.unique_learner_number || '-',
    headerLabel: 'ULN',
    key: 'uln',
    sortable: false,
  },
];

interface IRouteProps {
  params: { centre: string; examSpec: string };
  location: { query: { class?: string; option?: string; user?: string } };
}

interface IProps {
  centre: ICentre | null;
  studentsCollection: ICollection<IProfile>;
  classesCollection: ICollection<IClassRecord>;
  courseworkProgressOverviewCollection: ICollection<
    ICourseworkProgressOverview
  >;
  examSpec: IExamSpecRecord | null;
  loading: boolean;
  optionsCollection: ICollection<IOptionRecord>;
  unitCollection: ICollection<IUnitDetail>;
  isSingleStudentView: boolean;
  submitDisabled: boolean;
  profile: IProfile;
  push: typeof push;
  getCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): AxiosPromise;
  getAllCollection(
    type: keyof ICollectionsState,
    opts?: ICollectionOptions,
    tag?: string
  ): AxiosPromise;
  loadItem(type: keyof IItemsState, id: string): AxiosPromise;
}

export class CourseworkProgressOverviewDetail extends React.Component<
  IProps & FormProps<DataShape, void, void> & IRouteProps,
  void
> {
  public constructor(
    props: IProps & FormProps<DataShape, void, void> & IRouteProps
  ) {
    super(props);

    this.getCollection = this.getCollection.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeClass = this.changeClass.bind(this);
    this.changeStudent = this.changeStudent.bind(this);
    this.getMultipleStudentHeaders = this.getMultipleStudentHeaders.bind(this);
    this.getSingleStudentHeaders = this.getSingleStudentHeaders.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem('centres', this.props.params.centre);
    this.props.loadItem(
      'courses/running-exam-specs',
      this.props.params.examSpec
    );
    this.props
      .getCollection(
        'courses/qualification-options',
        {
          filters: Map({ exam_spec: this.props.params.examSpec }),
        },
        COURSEWORK_PROGRESS_OVERVIEW_DETAIL
      )
      .then(response => {
        const levelId: string | undefined =
          this.props.location.query.option ||
          (response.data.results.length && response.data.results[0].id);
        return this.changeLevel(levelId, true);
      });
    this.props.getAllCollection(
      'courses/units',
      {
        filters: Map({
          unit_options__option__exam_spec: this.props.params.examSpec,
        }),
      },
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    );
  }

  public render() {
    const {
      centre,
      examSpec,
      classesCollection,
      studentsCollection,
      courseworkProgressOverviewCollection,
      loading,
      optionsCollection,
      submitDisabled,
      profile,
      isSingleStudentView,
      unitCollection,
    } = this.props;

    return (
      <ContentBox>
        <form onSubmit={this.onSubmit}>
          <ContentBoxHeader>
            <h2 className="font-size-large">
              <span className="margin-right-base">
                {getCourseWorkProgressOverviewFor('Overview', centre, examSpec)}
              </span>
              <Link to="/tasks/overview/" className="font-size-base">
                Change
              </Link>
            </h2>
          </ContentBoxHeader>

          <Row className="margin-vertical-large">
            <Column sm={3}>
              <CollectionSelectFilter
                label="Level"
                name="centre"
                filterKey="option"
                excludeNull
                options={optionsCollection.results.map(option => {
                  return { key: option.id, label: option.title };
                })}
                collection={courseworkProgressOverviewCollection}
                pageSize={PAGE_SIZE}
                getCollection={this.getCollection}
                onChange={this.changeLevel}
              />
            </Column>

            <Column sm={3}>
              <CollectionSelectFilter
                label={`For ${terminologyFromProfile(profile, 'Class')}`}
                name="Class"
                filterKey="task_class"
                excludeNull
                options={classesCollection.results.map(classInstance => {
                  return { key: classInstance.id, label: classInstance.name };
                })}
                collection={courseworkProgressOverviewCollection}
                pageSize={PAGE_SIZE}
                getCollection={this.getCollection}
                onChange={this.changeClass}
              />
            </Column>

            <Column sm={3}>
              <CollectionSelectFilter
                label={`${terminologyFromProfile(profile, 'Student')} / ULN`}
                name="Student"
                filterKey="user"
                options={studentsCollection.results.map(student => {
                  return {
                    key: student.id,
                    label: `${student.osms_data.name} / ${student.osms_data
                      .unique_learner_number || '--'}`,
                  };
                })}
                collection={courseworkProgressOverviewCollection}
                pageSize={PAGE_SIZE}
                getCollection={this.getCollection}
                onChange={this.changeStudent}
              />
            </Column>

            <Column sm={3}>
              <CollectionSelectFilter
                label="With Status"
                name="qualification"
                filterKey="status"
                excludeNull
                options={List.of(
                  {
                    key: 'all_open',
                    label: 'All Open',
                  },
                  {
                    key: 'open',
                    label: 'Open',
                  },
                  {
                    key: 'handed_in',
                    label: 'Handed In',
                  },
                  {
                    key: 'marked',
                    label: 'Marked',
                  },
                  {
                    key: 'submitted',
                    label: 'Submitted',
                  }
                )}
                collection={courseworkProgressOverviewCollection}
                pageSize={PAGE_SIZE}
                getCollection={this.getCollection}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <div className="padding-horizontal-large">
                <Field
                  name="selectedUsers"
                  component={({
                    input: {
                      value = Set<ICourseworkProgressOverview>(),
                      onChange,
                    },
                  }: WrappedFieldProps<IStore>) =>
                    isSingleStudentView ? (
                      <CollectionTable
                        headers={this.getSingleStudentHeaders({
                          onChange,
                          value,
                        })}
                        className="progress-overview-table"
                        collection={unitCollection}
                        loading={loading}
                        pageSize={unitCollection.count}
                        getCollection={() => null}
                        preventInitialRequest
                        fixRowHeaders={false}
                      />
                    ) : (
                      <CollectionTable
                        headers={this.getMultipleStudentHeaders({
                          onChange,
                          value,
                        })}
                        collection={courseworkProgressOverviewCollection}
                        loading={loading}
                        pageSize={PAGE_SIZE}
                        getCollection={this.getCollection}
                        preventInitialRequest
                      />
                    )}
                />
              </div>
            </Column>
          </Row>

          <ContentBoxFooter className="text-align-left">
            {/* TODO: Un-comment these buttons when they are needed */}
            {/* TODO: For a single student view remember to pass in the single student manually as there are no checkboxes */}
            {/* <SpacedGroup block className="margin-vertical-base">
              <Button
                type="submit"
                className="tertiary"
                disabled={submitDisabled}
              >
                Submit marked work
              </Button>
              <Link to="/tasks/overview/">Cancel</Link>
            </SpacedGroup>
            <p className="info">
              Submitting work for a <Term>Student</Term> goes to the awarding
              body for assessment.
            </p>
            */}
          </ContentBoxFooter>
        </form>
      </ContentBox>
    );
  }

  private onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.push('/tasks/overview/submit/step-1/');
  }

  private getCollection(options: ICollectionOptions) {
    const newFilters = (options.filters || Map<string, string>())
      .set('centre', this.props.params.centre)
      .set('exam_spec', this.props.params.examSpec);

    return this.props.getCollection(
      'tasks/coursework-progress-overview',
      {
        ...options,
        filters: newFilters,
        page: options.page || 1,
      },
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    );
  }

  private changeLevel(levelId?: string, isInitialLoad?: boolean) {
    const currentFilters = (this.props.classesCollection.filters ||
      Map<string, string>()
    )
      .set('centre', this.props.params.centre)
      .set('qualification__exam_spec__id', this.props.params.examSpec)
      .set('qualification__option__id', levelId || '');

    return this.props
      .getCollection(
        'classes',
        { filters: currentFilters },
        COURSEWORK_PROGRESS_OVERVIEW_DETAIL
      )
      .then(response => {
        const queryClassId = this.props.location.query.class;
        const firstClassId = response.data.results.length
          ? response.data.results[0].id
          : undefined;
        return this.changeClass(
          (isInitialLoad && queryClassId) || firstClassId,
          currentFilters,
          isInitialLoad
        );
      });
  }

  private changeClass(
    classId: string,
    filters?: Map<string, string>,
    isInitialLoad?: boolean
  ) {
    const currentFilters = (filters ||
      this.props.courseworkProgressOverviewCollection.filters ||
      Map<string, string>()
    ).set('task_class', classId);

    const userFilters = currentFilters
      .set('group', currentFilters.get('task_class', ''))
      .set('user_type', 'student');

    return this.props
      .getAllCollection(
        'users',
        { filters: userFilters },
        COURSEWORK_PROGRESS_OVERVIEW_DETAIL
      )
      .then(response => {
        if (isInitialLoad && this.props.location.query.user) {
          return this.changeStudent(
            this.props.location.query.user,
            currentFilters
          );
        }
        return this.getCollection({
          filters: currentFilters,
        });
      });
  }

  private changeStudent(studentId: string, filters?: Map<string, string>) {
    const currentFilters =
      filters ||
      this.props.courseworkProgressOverviewCollection.filters ||
      Map<string, string>();
    return this.getCollection({
      filters: currentFilters.set('user', studentId),
    });
  }

  private getMultipleStudentHeaders(context: IHeaderContext) {
    const taskClass = this.getCurrentClass();
    const { profile } = this.props;
    return getHeaders(context)
      .concat([
        {
          content: record => taskClass,
          headerLabel: terminologyFromProfile(profile, 'Class'),
          key: 'task_class',
          sortable: false,
        },
      ])
      .concat(
        this.props.unitCollection.results
          .map(unit => {
            return {
              content: (record: ICourseworkProgressOverview) =>
                this.collateStatuses(record, unit),
              headerLabel: unit.title,
              key: unit.id,
              sortable: false,
            };
          })
          .toArray()
      );
  }

  private getSingleStudentHeaders(
    context: IHeaderContext
  ): Array<IColumnData<IUnitDetail>> {
    const taskClass = this.getCurrentClass();
    const { profile, courseworkProgressOverviewCollection } = this.props;
    return [
      {
        content: (record: IUnitDetail) => record.title,
        headerLabel: terminologyFromProfile(profile, 'Unit'),
        key: 'unit',
        sortable: false,
        width: '10%',
      },
      {
        content: (record: IUnitDetail) =>
          this.collateStatuses(
            courseworkProgressOverviewCollection.results.first(),
            record
          ),
        headerLabel: '',
        key: 'progress',
        sortable: false,
        className: 'padding-left-large',
      },
    ];
  }

  private getCurrentClass() {
    const {
      courseworkProgressOverviewCollection,
      classesCollection,
    } = this.props;
    const taskClassId =
      courseworkProgressOverviewCollection.filters &&
      courseworkProgressOverviewCollection.filters.get('task_class');
    const taskClass = classesCollection.results.find(c => c.id === taskClassId);
    if (taskClass) {
      return taskClass.name + (taskClass.is_archived ? ' (ARCHIVED)' : '');
    }
    return '-';
  }

  private collateStatuses(
    summary: ICourseworkProgressOverview | undefined,
    unit: IUnitDetail
  ): React.ReactNode {
    const tasks = summary
      ? summary.user_tasks.filter(task => task.unit === unit.id)
      : [];
    if (tasks.length) {
      return tasks.map(task => (
        <div
          title={task.title}
          key={task.id}
          className={`work-status ${constantToKebabCase(task.status)}`}
        >
          <Link to={`/tasks/marksheet/${task.id}/`} title={task.title}>
            <StatusIndicator task={task}>
              <GradeIndicator grade={task.grade} prefix=": " />
              <ExamResultsIndicator
                examResults={task.exam_results}
                prefix=" "
              />
              <ProgresShort
                progress={task.progress}
                className="display-inline-block margin-left-small margin-right-small"
                width="100px"
              />
            </StatusIndicator>
          </Link>
        </div>
      ));
    }
    return '-';
  }
}

const selector = formValueSelector(FORM_NAME);

const Formified = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
})(CourseworkProgressOverviewDetail);

function mapStateToProps(
  store: IStore,
  props: FormProps<DataShape, void, void> & IRouteProps
) {
  const { itemsOld, collectionsOld, responses, profile } = store;
  const selectedUsers: Set<ICourseworkProgressOverview> | undefined = selector(
    store,
    'selectedUsers'
  );

  const courseworkProgressOverviewCollection = getCollectionByName(
    collectionsOld.get('tasks/coursework-progress-overview'),
    COURSEWORK_PROGRESS_OVERVIEW_DETAIL
  );

  return {
    submitDisabled: !selectedUsers || !selectedUsers.count(),
    centre: itemsOld.get('centres'),
    studentsCollection: getCollectionByName(
      collectionsOld.get('users'),
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    ),
    classesCollection: getCollectionByName(
      collectionsOld.get('classes'),
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    ),
    courseworkProgressOverviewCollection,
    isSingleStudentView: Boolean(
      courseworkProgressOverviewCollection.filters &&
        courseworkProgressOverviewCollection.filters.get('user')
    ),
    examSpec: itemsOld.get('courses/running-exam-specs'),
    loading: anyPending(responses, [
      [GET_COLLECTION, 'centres'],
      [GET_COLLECTION, 'users'],
      [GET_COLLECTION, 'classes'],
      [GET_COLLECTION, 'tasks/coursework-progress-overview'],
      [GET_COLLECTION, 'courses/running-exam-specs'],
      [GET_COLLECTION, 'courses/qualification-options'],
    ]),
    optionsCollection: getCollectionByName(
      collectionsOld.get('courses/qualification-options'),
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    ),
    profile,
    unitCollection: getCollectionByName(
      collectionsOld.get('courses/units'),
      COURSEWORK_PROGRESS_OVERVIEW_DETAIL
    ),
    ...props,
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  getCollection,
  loadItem,
  push,
})(Formified);
