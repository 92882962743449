import * as React from 'react';

import { Alert } from '@dabapps/roe';
import Term from '../terminology';

export default class QualificationWithoutDatesWarning extends React.PureComponent<
  {},
  void
> {
  public render() {
    return (
      <Alert className="warning">
        <p>
          Note: assigning this <Term>Qualification</Term> without setting the
          start and end dates will not set start and end dates on any mandatory{' '}
          <Term>Tasks</Term> it creates.
        </p>
      </Alert>
    );
  }
}
