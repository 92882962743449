import * as React from 'react';

import { Field, WrappedFieldProps } from 'redux-form';
import { IStore } from '../../../store';
import { ITaggingLearningOutcomeInput } from '../../../store/data-types/tasks/tagging';
import {
  removeDuplicateAndUndefinedValues,
  TLearningOutcomeGroup,
} from '../../../utils';
import { IInputValue } from './tag-files-form';

export interface ITagFilesGroupInput extends WrappedFieldProps<IStore> {
  group: ITaggingLearningOutcomeInput;
  taskId: string;
}

export default class TagFilesBlockInput extends React.PureComponent<
  ITagFilesGroupInput,
  void
> {
  public constructor(props: ITagFilesGroupInput) {
    super(props);
    this.transformLearningGroupValue = this.transformLearningGroupValue.bind(
      this
    );
    this.onChange = this.onChange.bind(this);
    this.getGroupBlockIds = this.getGroupBlockIds.bind(this);
  }

  public render() {
    const { group, input: { value } } = this.props;

    const checkedLearningOutcomeBlocks: ReadonlyArray<string | undefined> =
      value.learning_outcome_blocks || [];

    const groupBlockIds = this.getGroupBlockIds();

    const selectedBlockIdsWithinThisGroup = groupBlockIds.filter(
      each => checkedLearningOutcomeBlocks.indexOf(each) >= 0
    );

    const isChecked = Boolean(selectedBlockIdsWithinThisGroup.length);

    return (
      <span>
        <input type="checkbox" checked={isChecked} onChange={this.onChange} />
        <span className="info vertical-align-top margin-left-small">
          {selectedBlockIdsWithinThisGroup.length}/{groupBlockIds.length}
        </span>
      </span>
    );
  }

  private getGroupBlockIds(): ReadonlyArray<string | undefined> {
    return this.props.group.learning_outcome_blocks.map(each => each.id);
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.input.onChange(this.transformLearningGroupValue(event));
  }

  private transformLearningGroupValue(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { taskId, input } = this.props;
    const value: IInputValue = input.value;
    const { user_tasks = [], learning_outcome_blocks = [], id } = value;

    const groupBlockIds = removeDuplicateAndUndefinedValues(
      this.getGroupBlockIds()
    );

    if (event.target.checked) {
      const newLearningOutcomeBlocks = removeDuplicateAndUndefinedValues(
        learning_outcome_blocks.concat(groupBlockIds as string[])
      );

      return {
        user_tasks: removeDuplicateAndUndefinedValues(
          user_tasks.concat(taskId)
        ),
        learning_outcome_blocks: newLearningOutcomeBlocks,
        id,
      };
    }

    const newLearningOutcomeBlocks = removeDuplicateAndUndefinedValues(
      learning_outcome_blocks
    ).filter(block => groupBlockIds.indexOf(block) < 0);

    return {
      user_tasks,
      learning_outcome_blocks: newLearningOutcomeBlocks,
      id,
    };
  }
}
