import 'core-js/shim';

import {
  AsyncActionSet,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Container, ModalRenderer } from '@dabapps/roe';
import * as classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { browserHistory, IndexRoute, Route, Router } from 'react-router';
import { push as routerPush, syncHistoryWithStore } from 'react-router-redux';
import { openFlyout, UPLOAD_ASSET } from './actions';
import {
  redirectIfRole,
  redirectWhenCurrentRoleNotSet,
} from './actions/permissions';
import {
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_USER_PREFERENCES,
  UPLOAD_CENTRE_PICTURE,
  UPLOAD_PROFILE_PICTURE,
} from './actions/profile';
import {
  ASSIGN_UNIT,
  CREATE_QUALIFICATION,
  UPDATE_UNIT,
} from './actions/qualifications';
import { BULK_UPDATE_STATUS } from './actions/tasks';
import { CREATE_USER, EDIT_USER } from './actions/users';
import { ADD_TO_COLLECTION } from './collections/actions';
import ClassPage from './components/classes/class-page';
import ClassesPage from './components/classes/classes-page';
import CreateClassPage from './components/classes/create-class-page';
import EditClassPage from './components/classes/edit-class-page';
import EditStudentsPage from './components/classes/edit-students';
import CurrentRolePickerPage from './components/current-role-picker';
import DashboardQualifications from './components/dashboard/dashboard-qualifications';
import FilesPage from './components/files/files-page';
import FilesPageStudent from './components/files/files-page-student';
import Footer from './components/footer';
import AddJournalEntry from './components/journals/add-journal-entry';
import EditJournalEntry from './components/journals/edit-journal-entry';
import JournalFeedPage from './components/journals/journal-feed-page';
import {
  COURSES_DASHBOARD,
  default as LandingPage,
} from './components/landing-page';
import LoadingSpinnerRenderer from './components/loading-spinner-renderer';
import Menu from './components/menu';
import FlyoutMenu from './components/menu/flyout-menu';
import MenuButton from './components/menu/menu-button';
import NavBar from './components/nav/navbar';
import NewsFeed from './components/news-feed';
import Profile from './components/profile';
import ChangePassword from './components/profile/change-password';
import ProfileLanding from './components/profile/landing';
import Personal from './components/profile/personal';
import Quota from './components/profile/quota';
import ReportsPage from './components/reports/';
import CourseworkProgressOverviewDetail from './components/tasks/coursework-progress-overview/detail';
import CourseworkProgressOverviewLanding from './components/tasks/coursework-progress-overview/landing';
import CourseworkProgressOverviewSubmit1 from './components/tasks/coursework-progress-overview/submit-step-1';
import CourseworkProgressOverviewSubmit2 from './components/tasks/coursework-progress-overview/submit-step-2';
import TaskMarksheet from './components/tasks/marksheet';
import ChecklistPage from './components/tasks/marksheet/checklist-page';
import ClassQualificationPage from './components/tasks/qualifications/class-qualification-page';
import QualificationManagement from './components/tasks/qualifications/qualification-management';
import QualificationPage from './components/tasks/qualifications/qualification-page';
import SubscriptionBank from './components/tasks/subscription-bank/';
import CreateQualification from './components/tasks/subscription-bank/create-qualification';
import TaskDetailTeacherPage from './components/tasks/task-detail-teacher-page';
import TasksLanding from './components/tasks/tasks-landing';
import TasksPage from './components/tasks/tasks-page';
import TasksTimeline from './components/tasks/tasks-timeline';
import UsersPage from './components/users/';
import AddUser, { ADD_USER } from './components/users/add';
import BulkImport from './components/users/bulk-import';
import BulkImportDetail from './components/users/bulk-import-detail';
import EditUser, { EDIT_USER_TAG } from './components/users/edit';
import UsersList from './components/users/list';
import ManageEQAsPage from './components/users/manage-eqas';
import ManageCentreEQAsTable from './components/users/manage-eqas/manage-centre-eqas-table';
import StudentViewPage from './components/users/student-view-page';
import cssLivereload from './css-livereload';
import { UPDATE_ITEM } from './items/actions';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  isOnSelectRolePage,
  TEACHER,
  userHasRoles,
} from './permissions';
import { collectionsModule } from './reducers/collections';
import { IItemsState, itemsModule } from './reducers/items';
import store from './store/';
import { IStore } from './store/';
import { IProfile, TRole } from './store/data-types/profile';

const { actions: { getCollection } } = collectionsModule;
const { actions: { clearItem: clearItemActionCreator } } = itemsModule;

if (process.env.CSS_LIVERELOAD) {
  cssLivereload();
}

export const historyObject = syncHistoryWithStore(browserHistory, store);

function resetRequestStateFor(key: AsyncActionSet, tag?: string) {
  store.dispatch(resetRequestState(key, tag));
}

function clearItem(itemType: keyof IItemsState) {
  store.dispatch(clearItemActionCreator(itemType));
}

function checkLoggedIn() {
  const {
    profile: { id },
    routing: { locationBeforeTransitions: { pathname } },
  } = store.getState();

  if (!id && pathname !== '/') {
    store.dispatch(routerPush('/'));
  }
}

function checkIsTeacherOrCentreAdmin() {
  const { profile } = store.getState();
  if (!userHasRoles(profile, Set.of(TEACHER, CENTRE_ADMIN))) {
    store.dispatch(routerPush('/users/'));
  }
}

export interface ILocation {
  pathname: string;
}

// tslint:disable-next-line:no-unused-variable
interface IProps {
  location: ILocation;
  modals: List<React.ReactNode>;
  profile: IProfile;
  redirectWhenCurrentRoleNotSet: typeof redirectWhenCurrentRoleNotSet;
  openFlyout(): void;
  redirectIfRole(roles: Set<TRole>, redirectPath: string, user: IProfile): void;
}

// FIXME: We should be using RouteComponentProps instead
export interface IRouteProps {
  params: { id: string };
  location: ILocation;
}

class App extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.checkLocationAndRedirect(this.props);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    this.checkLocationAndRedirect(nextProps);
  }

  public render() {
    const { location, modals, openFlyout, profile } = this.props;

    return (
      <div>
        <LoadingSpinnerRenderer />

        <NavBar pathname={location.pathname} className="bottom-mobile" />

        <Container
          fluid
          className={classNames(
            'has-navbar has-navbar-bottom-mobile',
            profile.hijacker ? 'is-hijacked' : null
          )}
        >
          {profile &&
            profile.id &&
            !isOnSelectRolePage(location.pathname) && (
              <MenuButton onClick={openFlyout}>
                <FlyoutMenu>
                  <Menu />
                </FlyoutMenu>
              </MenuButton>
            )}

          {this.props.children}
          <Footer />
        </Container>
        <ModalRenderer modals={modals.toArray()} />
      </div>
    );
  }

  private checkLocationAndRedirect(props: IProps) {
    const { location: { pathname }, profile } = props;
    const atApplicationRoot =
      pathname === '/' || pathname === '' || pathname === '/newsfeed/';
    if (atApplicationRoot) {
      props.redirectIfRole(Set.of(DA_ADMIN, DA_SUPPORT), '/users/', profile);
    }
    props.redirectWhenCurrentRoleNotSet(pathname, profile);
  }
}

function mapStateToProps({ modals, profile }: IStore) {
  return {
    modals,
    profile,
  };
}

const ConnectedApp = connect(mapStateToProps, {
  openFlyout,
  redirectIfRole,
  redirectWhenCurrentRoleNotSet,
})(App);

ReactDOM.render(
  <Provider store={store}>
    <Router history={historyObject}>
      <Route path="/" component={ConnectedApp}>
        <IndexRoute component={LandingPage} />
        <Route
          path="inactive-user/"
          component={props => <LandingPage {...props} isInactiveUser />}
        />
        <Route path="select-role/" component={CurrentRolePickerPage} />
        <Route path="newsfeed/" component={NewsFeed} />
        <Route path="my-activity/" component={NewsFeed} />
        <Route path="profile/" component={Profile} onEnter={checkLoggedIn}>
          <IndexRoute
            component={ProfileLanding}
            onEnter={() => resetRequestStateFor(UPDATE_USER_PREFERENCES)}
          />
          <Route
            path="personal/"
            component={Personal}
            onEnter={() => resetRequestStateFor(UPDATE_PROFILE)}
          />
          <Route
            path="password/"
            component={ChangePassword}
            onEnter={() => resetRequestStateFor(CHANGE_PASSWORD)}
          />
          <Route path="quota/" component={Quota} />
        </Route>
        <Route path="journal/" onEnter={checkLoggedIn}>
          <IndexRoute component={JournalFeedPage} />
          <Route
            path="user/:id/"
            getComponent={(nextState, callback) => {
              callback(null, props => (
                <JournalFeedPage
                  journalType="user"
                  location={props.location}
                  userId={props.params.id}
                />
              ));
            }}
          />
          <Route
            path="class/"
            getComponent={(nextState, callback) => {
              callback(null, props => (
                <JournalFeedPage
                  journalType="class"
                  location={props.location}
                />
              ));
            }}
          />
          <Route
            path="class/:id/"
            getComponent={(nextState, callback) => {
              callback(null, props => (
                <JournalFeedPage
                  journalType="class"
                  location={props.location}
                  classId={props.params.id}
                />
              ));
            }}
          />
          <Route
            path="add-entry/"
            component={AddJournalEntry}
            onEnter={() => {
              resetRequestStateFor(ADD_TO_COLLECTION, 'journals');
              resetRequestStateFor(UPLOAD_ASSET);
              clearItem('journals');
            }}
            onLeave={() => clearItem('journals')}
          />
          <Route path=":id/">
            <IndexRoute
              component={EditJournalEntry}
              onLeave={() =>
                resetRequestStateFor(ADD_TO_COLLECTION, 'journals')}
            />
            <Route
              path="edit/"
              getComponent={(nextState, callback) => {
                callback(null, props => (
                  <EditJournalEntry {...props} editing />
                ));
              }}
              onEnter={() => clearItem('journals')}
              onLeave={() => {
                resetRequestStateFor(UPDATE_ITEM, 'journals');
                clearItem('journals');
              }}
            />
          </Route>
        </Route>
        <Route path="classes/" onEnter={checkLoggedIn}>
          <IndexRoute component={ClassesPage} />
          <Route path="create/" component={CreateClassPage} />
          <Route path=":id/">
            <IndexRoute component={ClassPage} />
            <Route
              path="edit/"
              component={EditClassPage}
              onEnter={() => resetRequestStateFor(UPDATE_ITEM, 'classes')}
            />
            <Route
              path="edit-students/"
              component={EditStudentsPage}
              onEnter={() => resetRequestStateFor(UPDATE_ITEM, 'classes')}
            />
          </Route>
        </Route>
        <Route path="files/" onEnter={checkLoggedIn}>
          <IndexRoute
            component={FilesPage}
            onEnter={() => resetRequestStateFor(UPLOAD_ASSET)}
          />
        </Route>
        <Route path="tasks/" component={TasksPage} onEnter={checkLoggedIn}>
          <IndexRoute component={TasksLanding} />
          <Route path="timeline/" component={TasksTimeline} />
          <Route path="subscription-bank/">
            <IndexRoute component={SubscriptionBank} />
            <Route path=":id/" component={CreateQualification} />
          </Route>
          <Route path="qualifications/">
            <IndexRoute
              component={QualificationManagement}
              onLeave={() => resetRequestStateFor(CREATE_QUALIFICATION)}
            />
            <Route path=":id/" component={QualificationPage} />
            <Route
              onEnter={() => {
                resetRequestStateFor(UPDATE_UNIT);
                resetRequestStateFor(ASSIGN_UNIT);
              }}
              path=":id/classes/:classid/"
              component={ClassQualificationPage}
            />
          </Route>
          <Route path="overview/">
            <IndexRoute component={CourseworkProgressOverviewLanding} />
            <Route
              path=":centre/exam-spec/:examSpec/"
              component={CourseworkProgressOverviewDetail}
            />
            <Route
              path="submit/step-1/"
              component={CourseworkProgressOverviewSubmit1}
            />
            <Route
              path="submit/step-2/:named/"
              component={CourseworkProgressOverviewSubmit2}
            />
          </Route>
          <Route
            path=":id/"
            component={TaskDetailTeacherPage}
            onLeave={() => resetRequestStateFor(BULK_UPDATE_STATUS)}
          />
          <Route path="marksheet/:id/">
            <IndexRoute
              component={TaskMarksheet}
              onLeave={() => resetRequestStateFor(UPDATE_ITEM, 'tasks')}
            />
            <Route path="checklist/" component={ChecklistPage} />
          </Route>
        </Route>
        <Route path="users/" component={UsersPage} onEnter={checkLoggedIn}>
          <IndexRoute
            component={UsersList}
            onLeave={() => {
              resetRequestStateFor(CREATE_USER, ADD_USER);
              resetRequestStateFor(EDIT_USER, EDIT_USER_TAG);
            }}
          />
          <Route path="add/" component={AddUser} />
          <Route
            path="edit/:id/"
            component={EditUser}
            onEnter={() => {
              resetRequestStateFor(EDIT_USER, EDIT_USER_TAG);
            }}
          />
          <Route path="bulk-import/">
            <IndexRoute component={BulkImport} />
            <Route path=":id/" component={BulkImportDetail} />
          </Route>
          <Route path="manage-external-quality-assurers/">
            <IndexRoute component={ManageEQAsPage} />
            <Route path=":id/" component={ManageCentreEQAsTable} />
          </Route>
          <Route
            path="student/:student/"
            component={StudentViewPage}
            onEnter={checkIsTeacherOrCentreAdmin}
          >
            <IndexRoute component={DashboardQualifications} />
            <Route
              path="files/"
              component={FilesPageStudent}
              onEnter={() => resetRequestStateFor(UPLOAD_ASSET)}
            />
            <Route path="journal/">
              <IndexRoute
                getComponent={(nextState, callback) => {
                  callback(null, props => (
                    <JournalFeedPage
                      journalType="user"
                      location={props.location}
                      userId={props.params.student}
                      isViewingUnderStudentContext
                    />
                  ));
                }}
              />
              <Route
                path=":id/"
                getComponent={(nextState, callback) => {
                  callback(null, props => (
                    <EditJournalEntry {...props} isViewingUnderStudentContext />
                  ));
                }}
              />
            </Route>
            <Route path="tasks/">
              <IndexRoute component={TasksLanding} />
              <Route path="marksheet/:id/">
                <IndexRoute
                  component={TaskMarksheet}
                  onLeave={() => resetRequestStateFor(UPDATE_ITEM, 'tasks')}
                />
                <Route path="checklist/" component={ChecklistPage} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path="reports/"
          component={ReportsPage}
          onEnter={checkLoggedIn}
        />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('app')
);
