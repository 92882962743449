import * as React from 'react';

import { Alert } from '@dabapps/roe';
import Term from '../terminology';

export default class UnitWithoutDatesWarning extends React.PureComponent<
  {},
  void
> {
  public render() {
    return (
      <Alert className="warning">
        <p>
          Note: assigning this <Term>Unit</Term> without setting the start and
          end dates will not set start and end dates on any <Term>Tasks</Term>{' '}
          it creates.
        </p>
      </Alert>
    );
  }
}
