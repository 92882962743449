import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { Map, Set } from 'immutable';
import { push } from 'react-router-redux';
import { Dispatch } from 'redux';
import { GetStateCallback, TActionAny, Thunk } from '../requests/types';

import { collectionsModule } from '../reducers/collections';
import { IStore } from '../store';
import { CLASS_STATUSES } from '../store/data-types/classes';
import { IDashboardQualificationOption } from '../store/data-types/qualifications';
import { closeModal } from './modals';

const { actions: { getAllCollection, getCollection } } = collectionsModule;

export interface ICreateQualificationData {
  start_date?: string;
  end_date?: string;
  classes: string[];
  option: string;
}

export const CREATE_QUALIFICATION = makeAsyncActionSet('CREATE_QUALIFICATION');
export function createQualification(data: ICreateQualificationData) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      CREATE_QUALIFICATION,
      '/api/courses/',
      'POST',
      data
    )(dispatch, getState)
      .then(response => dispatch(push(`/tasks/qualifications/`)))
      .catch(error => null);
  };
}

export const ASSIGN_QUALIFICATION = makeAsyncActionSet('ASSIGN_QUALIFICATION');
export function assignQualification(
  qualificationId: string,
  classes: Set<string>,
  onComplete: (qualificationId: string) => TActionAny | Thunk<any>
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      ASSIGN_QUALIFICATION,
      `/api/courses/${qualificationId}/assign/`,
      'PUT',
      { classes: classes.toArray() }
    )(dispatch, getState)
      .then(response => {
        dispatch(closeModal());
        // FIXME: Our fake Redux types clash with the official ones. This needs normalising at some point
        return dispatch(onComplete(qualificationId) as any);
      })
      .catch(error => null);
  };
}

export interface IAssignUnitData {
  start_date: string;
  end_date: string;
  assignees: string[];
}
export const ASSIGN_UNIT = makeAsyncActionSet('ASSIGN_UNIT');
export function assignUnit(
  unit: string,
  qualification: string,
  taskClass: string,
  data: IAssignUnitData
) {
  return dispatchGenericRequest(
    ASSIGN_UNIT,
    `/api/courses/unit-management/${unit}/?qualification=${qualification}&task_class=${taskClass}`,
    'PUT',
    data
  );
}

export interface IClassTaskUnitData {
  start_date: string | null;
  end_date: string | null;
  id: string;
}
export interface IUpdateUnitData {
  assignees?: string[];
  class_tasks: IClassTaskUnitData[];
}
export const UPDATE_UNIT = makeAsyncActionSet('UPDATE_UNIT');
export function updateUnit(
  unit: string,
  qualification: string,
  taskClass: string,
  data: IUpdateUnitData
) {
  return dispatchGenericRequest(
    UPDATE_UNIT,
    `/api/courses/unit-management/${unit}/?qualification=${qualification}&task_class=${taskClass}`,
    'PUT',
    data
  );
}

export function loadProgressForCourse(
  course: IDashboardQualificationOption,
  tag: string,
  studentId?: string
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    const profile = getState().profile;
    let taskGradesFilters = Map({ qualification: course.id });
    let studentProgressFilters = Map({ qualification: course.id });

    // FIXME - Even if profile.preferences.preference_show_old_tasks is true
    // the courses list end point makes no allowance for that flag.
    // So the courses for archived classes wont show at all.
    // This code therefore does nothing!
    if (!profile.preferences.preference_show_old_tasks) {
      taskGradesFilters = taskGradesFilters.set(
        'class_task__task_class__status',
        CLASS_STATUSES.ACTIVE
      );
    }

    if (studentId) {
      studentProgressFilters = studentProgressFilters.set('student', studentId);
      taskGradesFilters = taskGradesFilters.set('user', studentId);
    }

    getAllCollection(
      'courses/student-progress',
      { filters: studentProgressFilters },
      tag
    )(dispatch, getState);
    getCollection(
      'tasks/grades',
      {
        filters: taskGradesFilters,
        pageSize: 5,
        ordering: 'class_task__end_date',
      },
      tag
    )(dispatch, getState);
  };
}
