import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dispatch } from 'redux';
import { GetStateCallback } from '../requests/types';

import { IStore } from '../store';
import { IUserPreferences } from '../store/data-types/profile';
import { dispatchImageUpload } from './';

import { IFormData as IProfilePersonalFormData } from '../components/profile/personal-form';

export const CHANGE_PASSWORD = makeAsyncActionSet('CHANGE_PASSWORD');
export function changePassword(
  data: { password: string; password2: string; old_password: string },
  tag?: string
) {
  return dispatchGenericRequest(
    CHANGE_PASSWORD,
    '/api/users/profile/password/',
    'PUT',
    data,
    tag
  );
}

export const UPLOAD_PROFILE_PICTURE = makeAsyncActionSet(
  'UPLOAD_PROFILE_PICTURE'
);
export function uploadProfilePicture(file: File) {
  return dispatchImageUpload(
    UPLOAD_PROFILE_PICTURE,
    '/api/users/profile/picture/',
    'picture',
    file
  );
}

export const UPLOAD_CENTRE_PICTURE = makeAsyncActionSet(
  'UPLOAD_CENTRE_PICTURE'
);
export function uploadCentrePicture(file: File, centreId: string) {
  return dispatchImageUpload(
    UPLOAD_CENTRE_PICTURE,
    `/api/centres/${centreId}/picture/`,
    'picture',
    file
  );
}

export const UPDATE_PROFILE = makeAsyncActionSet('UPDATE_PROFILE');
export function updateProfile(
  data: Partial<IProfilePersonalFormData>,
  tag?: string
) {
  return dispatchGenericRequest(
    UPDATE_PROFILE,
    '/api/users/profile/',
    'PUT',
    data,
    tag
  );
}

export const UPDATE_USER_PREFERENCES = makeAsyncActionSet(
  'UPDATE_USER_PREFERENCES'
);
export function updateUserPreferences(
  data: Partial<IUserPreferences>,
  tag?: string
) {
  return dispatchGenericRequest(
    UPDATE_USER_PREFERENCES,
    '/api/users/profile/preferences/',
    'PATCH',
    data,
    tag
  );
}

export const UPDATE_CURRENT_CENTRE = makeAsyncActionSet(
  'UPDATE_CURRENT_CENTRE'
);
export function updateCurrentCentre(
  centreId: string | null,
  reloadCurrentPage: boolean = false
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      UPDATE_CURRENT_CENTRE,
      '/api/users/switch-centre/',
      'PUT',
      { current_centre: centreId }
    )(dispatch, getState)
      .then(() => {
        if (reloadCurrentPage) {
          window.location.reload(true);
        } else {
          // redirect to the dashboard and force a refresh of the user profile / centre data
          window.location.replace('/');
        }
      })
      .catch(error => null);
  };
}
