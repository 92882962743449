import { GET_VIEWING_STUDENT, TOGGLE_STUDENT_PICKER } from '../actions/users';
import { IActionWithPayload } from '../requests/types';

export interface IViewingStudent {
  id: string;
  picture: string | null;
  name: string;
}

export interface IStudentPicker {
  viewingStudent?: IViewingStudent;
  isVisibleSelect: boolean;
}

const studentPickerDefaultState = {
  isVisibleSelect: false,
};

export function studentPicker(
  state: IStudentPicker = studentPickerDefaultState,
  action: IActionWithPayload<IViewingStudent, ReadonlyArray<string>>
) {
  switch (action.type) {
    case GET_VIEWING_STUDENT.SUCCESS:
      return {
        ...state,
        viewingStudent: action.payload,
        isVisibleSelect: false,
      };
    case GET_VIEWING_STUDENT.FAILURE:
      return {
        ...state,
        isVisibleSelect: false,
      };
    case TOGGLE_STUDENT_PICKER:
      return {
        ...state,
        isVisibleSelect: !state.isVisibleSelect,
      };
    default:
      return state;
  }
}
