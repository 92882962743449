import { Button, SpacedGroup } from '@dabapps/roe';
import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import * as _ from 'underscore';

const { IconChevronLeft, IconChevronRight } = FontAwesome;

interface IProps {
  className?: string;
  disabled?: boolean;
  count: number;
  pageSize: number;
  currentPage: number;
  onClick(event: React.MouseEvent<HTMLButtonElement>, nextPage: number): void;
}

const LEFT_BUTTONS = 2;
const RIGHT_BUTTONS = 2;
const MAX_BUTTONS = LEFT_BUTTONS + RIGHT_BUTTONS + 1;

export const Pagination = (props: IProps) => {
  const {
    className,
    disabled,
    count,
    pageSize,
    currentPage,
    onClick,
    ...remainingProps,
  } = props;

  const maxPages = Math.ceil(count / pageSize);
  const start = Math.max(
    Math.min(
      currentPage - LEFT_BUTTONS,
      maxPages - LEFT_BUTTONS - RIGHT_BUTTONS
    ),
    1
  );
  const end = Math.min(start + MAX_BUTTONS, maxPages + 1);

  return (
    <div {...remainingProps} className={classNames('pagination', className)}>
      <SpacedGroup>
        <Button
          disabled={currentPage === 1 || disabled}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            onClick(event, currentPage - 1)}
        >
          <IconChevronLeft />
        </Button>
        {_.range(start, end).map((page, index) => {
          let buttonType = currentPage === page ? 'primary' : undefined;
          let pageToGoTo = page;
          const morePages = maxPages > MAX_BUTTONS;
          const displayDots =
            morePages &&
            ((index === 1 && page > 2) ||
              (index === MAX_BUTTONS - 2 && page < maxPages - 1));
          const onClickPage = (event: React.MouseEvent<HTMLButtonElement>) =>
            currentPage !== page && !displayDots && onClick(event, pageToGoTo);

          if (displayDots) {
            buttonType = 'blank';
          }

          if (morePages && index === 0 && page > 1) {
            pageToGoTo = 1;
          } else if (
            morePages &&
            index === MAX_BUTTONS - 1 &&
            page < maxPages
          ) {
            pageToGoTo = maxPages;
          }

          return (
            <Button
              key={page}
              className={buttonType}
              disabled={count <= pageSize || disabled}
              onClick={onClickPage}
            >
              {displayDots ? '...' : pageToGoTo}
            </Button>
          );
        })}
        <Button
          disabled={!maxPages || currentPage === maxPages || disabled}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            onClick(event, currentPage + 1)}
        >
          <IconChevronRight />
        </Button>
      </SpacedGroup>
    </div>
  );
};

export default Pagination;
