import { recordArray, RecordWithConstructor } from '@dabapps/simple-records';

interface ITaggingLearningOutcomeBlockShared {
  id: string;
  block_title: string;
}
export type ITaggingLearningOutcomeBlockInput = Readonly<
  ITaggingLearningOutcomeBlockShared & {}
>;
export type ITaggingLearningOutcomeBlock = Readonly<
  ITaggingLearningOutcomeBlockShared & {}
>;
export const TaggingLearningOutcomeBlockRecord = RecordWithConstructor<
  ITaggingLearningOutcomeBlockInput,
  ITaggingLearningOutcomeBlock
>({ id: '', block_title: '' }, input => {
  return input;
});

interface ITaggingLearningOutcomeShared {
  id: string;
  group_title: string;
}
export type ITaggingLearningOutcomeInput = Readonly<
  ITaggingLearningOutcomeShared & {
    learning_outcome_blocks: ReadonlyArray<
      Partial<ITaggingLearningOutcomeBlockInput>
    >;
  }
>;
export type ITaggingLearningOutcome = Readonly<
  ITaggingLearningOutcomeShared & {
    learning_outcome_blocks: ReadonlyArray<ITaggingLearningOutcomeBlock>;
  }
>;
export const TaggingLearningOutcomeRecord = RecordWithConstructor<
  ITaggingLearningOutcomeInput,
  ITaggingLearningOutcome
>({ id: '', learning_outcome_blocks: [], group_title: '' }, input => {
  return {
    ...input,
    learning_outcome_blocks: recordArray(
      input.learning_outcome_blocks,
      TaggingLearningOutcomeBlockRecord
    ),
  };
});

interface ITaggingMarksheetShared {
  id: string;
  strand_title: string;
  strand_learner_tagging: boolean;
}
export type ITaggingMarksheetInput = Readonly<
  ITaggingMarksheetShared & {
    learning_outcomes: ReadonlyArray<Partial<ITaggingLearningOutcomeInput>>;
  }
>;
export type ITaggingMarksheet = Readonly<
  ITaggingMarksheetShared & {
    learning_outcomes: ReadonlyArray<ITaggingLearningOutcome>;
  }
>;
export const TaggingMarksheetRecord = RecordWithConstructor<
  ITaggingMarksheetInput,
  ITaggingMarksheet
>(
  {
    id: '',
    learning_outcomes: [],
    strand_title: '',
    strand_learner_tagging: false,
  },
  input => {
    return {
      ...input,
      learning_outcomes: recordArray(
        input.learning_outcomes,
        TaggingLearningOutcomeRecord
      ),
    };
  }
);

interface ITaggingUserTaskShared {
  id: string;
  component_title: string;
  qualification_name: string;
}
export type ITaggingUserTaskInput = Readonly<
  ITaggingUserTaskShared & {
    checklists: ReadonlyArray<Partial<ITaggingMarksheetInput>>;
  }
>;
export type ITaggingUserTask = Readonly<
  ITaggingUserTaskShared & {
    checklists: ReadonlyArray<ITaggingMarksheet>;
  }
>;
export const TaggingUserTaskRecord = RecordWithConstructor<
  ITaggingUserTaskInput,
  ITaggingUserTask
>(
  {
    id: '',
    component_title: '',
    qualification_name: '',
    checklists: [],
  },
  input => {
    return {
      ...input,
      checklists: recordArray(input.checklists, TaggingMarksheetRecord),
    };
  }
);
