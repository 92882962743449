import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';

import { Set } from 'immutable';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { UPDATE_PROFILE, updateProfile } from '../../actions/profile';
import { STUDENT, userHasRoles } from '../../permissions';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import { FormErrors } from '../../utils';
import { default as PersonalForm, IFormData } from './personal-form';

interface IProps {
  profile: IProfile;
  updateProfile: (data: IFormData) => void;
  hasSucceeded: boolean;
  isLoading: boolean;
  formErrors?: FormErrors;
}

export class Personal extends React.PureComponent<IProps, void> {
  public submit(formData: IFormData) {
    this.props.updateProfile(formData);
  }

  public render() {
    const osms_data = this.props.profile.osms_data;
    const initialValues: Partial<IFormData> = {
      email: osms_data.email,
      birth_date: osms_data.birth_date,
      gender: osms_data.gender,
      given_name: osms_data.given_name,
      middle_name: osms_data.middle_name,
      family_name: osms_data.family_name,
      username: osms_data.username,
      title: osms_data.title,
      unique_learner_number: osms_data.unique_learner_number,
    };

    return (
      <PersonalForm
        onSubmit={this.submit.bind(this)}
        initialValues={initialValues}
        isLoading={this.props.isLoading}
        hasSucceeded={this.props.hasSucceeded}
        formErrors={this.props.formErrors}
        isStudent={userHasRoles(this.props.profile, Set.of(STUDENT))}
      />
    );
  }
}

function mapStateToProps(
  { profile, responses }: IStore,
  existing: Partial<IProps>
) {
  return _.extend({}, existing, {
    formErrors: getFormErrors(responses, UPDATE_PROFILE),
    hasSucceeded: hasSucceeded(responses, UPDATE_PROFILE),
    isLoading: isPending(responses, UPDATE_PROFILE),
    profile,
  });
}

export default connect(mapStateToProps, { updateProfile })(Personal);
