import { CollectionStore } from '@dabapps/redux-api-collections/dist/collections';
import { ItemStore } from '@dabapps/redux-api-collections/dist/items';
import { ResponsesReducerState } from '@dabapps/redux-api-collections/dist/requests';
import { AxiosError, AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import {
  Action,
  applyMiddleware,
  compose,
  createStore,
  Dispatch,
  Middleware,
  Store,
} from 'redux';
import { FormStateMap } from 'redux-form';
import thunk, { ThunkAction } from 'redux-thunk';
import { IAction } from '../types';

import { ICollections } from '../collections';
import rootReducer, { IAssetsPageState } from '../reducers';
import { ICollectionsStateRecord } from '../reducers/collections';
import { IDashboardQualificationsState } from '../reducers/dashboard';
import { IItemsStateRecord } from '../reducers/items';
import { ICurrentRoleAndCentreOptions } from '../reducers/roles';
import { IStudentPicker } from '../reducers/users';
import { UIState } from '../ui-state';
import { checkForLogout } from '../utils';
import {
  FeatureFlagsRecord,
  // tslint:disable-next-line:no-unused-variable
  IAssetRecord,
  ICommentRecord,
  IFeatureFlags,
  IFileProgressRecord,
  TResponseErrors,
} from './data-types';
import { TStatus } from './data-types/common';
import { ServerConstants } from './data-types/constants';
import { IJournalTaskData } from './data-types/journals';
import { IProfile, ProfileRecord } from './data-types/profile';
import { IQualificationRecord } from './data-types/qualifications';
import {
  IRoleOption,
  // tslint:disable-next-line:no-unused-variable
  IRoleRecord,
} from './data-types/roles';
import { ITimelineRecord } from './data-types/timeline';

interface IRoutingReducerState {
  locationBeforeTransitions: {
    pathname: string;
  };
}

export interface IStore {
  assetUploadErrors: TResponseErrors;
  assets: List<IAssetRecord>;
  collections: CollectionStore<ICollections>;
  collectionsOld: ICollectionsStateRecord; // Deprecated, old-style Collections
  fileUploadProgress: IFileProgressRecord | null;
  form: FormStateMap;
  items: ItemStore<ICollections>;
  itemsOld: IItemsStateRecord; // Deprecated, old-style Collections
  profile: IProfile;
  currentRoleAndCentreOptions: ICurrentRoleAndCentreOptions;
  responses: ResponsesReducerState;
  rootComment: ICommentRecord;
  filesToUpload: List<File>;
  openReplies: Set<string>;
  replies: Map<string, string>;
  modals: List<React.ReactNode>;
  timeline: ITimelineRecord;
  uiState: UIState;
  csvMapping: Readonly<{ [k: string]: string }>;
  roles: List<IRoleRecord>;
  routing: IRoutingReducerState;
  loadingSpinners: number;
  displayFlyout: boolean;
  newsFeedCount: number;
  userInvites: Map<string, string>;
  recordedResultStatuses: Map<string, string>;
  targetTaskStatus: TStatus | null;
  taskDatesUpdated: boolean;
  featureFlags: IFeatureFlags;
  serverConstants: ServerConstants;
  journalTaskSidebar: IJournalTaskData;
  studentPicker: IStudentPicker;
  dashboardQualifications: IDashboardQualificationsState;
  assetsPage: IAssetsPageState;
}

function hasMeta(action: any): action is IAction<any, any> {
  return typeof action === 'object' && action.hasOwnProperty('meta');
}

const checkForLogoutMiddleware = (store: Store<IStore>) => (
  next: Dispatch<any>
) => (
  action:
    | IAction<any, any>
    | Action
    | ThunkAction<AxiosPromise, IStore, AxiosError>
) => {
  if (hasMeta(action)) {
    const { meta } = action;

    checkForLogout(meta);
  }

  return next(action as any); // Type defs are, of course, incorrect
};

const middlewares = [
  checkForLogoutMiddleware,
  thunk,
  routerMiddleware(browserHistory),
];

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ||
  compose;

export function configureStore(initialState?: Partial<IStore>) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

declare const INITIAL_USER_DATA: IProfile;
declare const SERVER_CONSTANTS: ServerConstants;

const userProfile = ProfileRecord(INITIAL_USER_DATA);

const store = configureStore({
  profile: userProfile,
  serverConstants: SERVER_CONSTANTS,
});

export default store;
