import { Alert, Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

import { ISimpleUser } from '../../store/data-types/profile';
import ImageHolder from '../image-holder';
import Term from '../terminology';

const { IconUserCircleO } = FontAwesome;

interface IClassSpecialisedUsersProps {
  users: ReadonlyArray<ISimpleUser>;
  label: string;
  relatedLabel?: string;
}
export default function ClassDetailSpecialisedUserList({
  users,
  label,
  relatedLabel,
}: IClassSpecialisedUsersProps) {
  return users.length ? (
    <Row>
      <Column xs={12}>
        <h3>{label}</h3>
      </Column>
      {users.map(user => (
        <Column xs={12} key={user.id}>
          <IconUserCircleO className="teacher-detail-picture" />
          {user.osms_data.name || '--'}
        </Column>
      ))}
    </Row>
  ) : (
    <Alert className="info">
      <p>
        There are no <Term>{label}</Term>{' '}
        {relatedLabel || (
          <span>
            in this <Term>Class</Term>
          </span>
        )}{' '}
        yet.
      </p>
    </Alert>
  );
}
