import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { Map } from 'immutable';
import { Dispatch } from 'redux';
import { GetStateCallback } from '../requests/types';

import { getComponentName } from '../components/dashboard/course-summary-student';
import { DASHBOARD_QUALIFICATION_OPTIONS } from '../components/dashboard/dashboard-qualifications';
import { collectionsModule } from '../reducers/collections';
import { IStore } from '../store';
import { TRole, TUserStatus } from '../store/data-types/profile';
import { IDashboardQualificationOption } from '../store/data-types/qualifications';
import { loadProgressForCourse } from './qualifications';

const { actions: { getAllCollection } } = collectionsModule;

export interface ITransformedRole {
  role?: TRole;
  centre?: string;
  exam_board?: string;
}

export interface IUserData {
  email?: string;
  password?: string;
  roles: ITransformedRole[];
  given_name?: string;
  middle_name?: string;
  family_name?: string;
  gender?: string;
  birth_date?: string;
}

export const CREATE_USER = makeAsyncActionSet('CREATE_USER');
export function createUser(data: IUserData, tag?: string) {
  return dispatchGenericRequest(CREATE_USER, '/api/users/', 'POST', data, tag);
}

export const SWITCH_VIEWING_USER = makeAsyncActionSet('SWITCH_VIEWING_USER');
export function switchViewingUser(userId: string | null, tag?: string) {
  return dispatchGenericRequest(
    SWITCH_VIEWING_USER,
    '/api/users/switch-user/',
    'PUT',
    { currently_viewing_user: userId },
    tag
  );
}

export function clearViewingUser(tag?: string) {
  return switchViewingUser(null, tag);
}

export const EDIT_USER = makeAsyncActionSet('EDIT_USER');
export function editUser(id: string, data: IUserData, tag?: string) {
  return dispatchGenericRequest(
    EDIT_USER,
    `/api/users/${id}/`,
    'PUT',
    data,
    tag
  );
}

export interface IUserBulkStatusUpdate {
  status: TUserStatus | undefined;
  user_ids: string[];
}
export const USER_BULK_STATUS_UPDATE = makeAsyncActionSet(
  'USER_BULK_STATUS_UPDATE'
);
export function userBulkStatusUpdate(data: IUserBulkStatusUpdate) {
  return dispatchGenericRequest(
    USER_BULK_STATUS_UPDATE,
    `/api/users/bulk-status-update/`,
    'PUT',
    data
  );
}

export const GET_VIEWING_STUDENT = makeAsyncActionSet('GET_VIEWING_STUDENT');
export function getViewingStudent(
  studentId: string,
  showingCourses: ReadonlyArray<string> = []
) {
  return (dispatch: Dispatch<IStore>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      GET_VIEWING_STUDENT,
      `/api/users/student/${studentId}/`,
      'GET',
      null,
      undefined
    )(dispatch, getState).then(() => {
      if (showingCourses.length) {
        const filters = Map({ user: studentId });
        getAllCollection(
          DASHBOARD_QUALIFICATION_OPTIONS,
          { filters },
          DASHBOARD_QUALIFICATION_OPTIONS
        )(dispatch, getState).then(response => {
          if (response.status === 200) {
            response.data.results.forEach(
              (course: IDashboardQualificationOption) => {
                // NOTE - newly rendering courses will load their progress automatically
                if (
                  showingCourses.find(
                    showingCourse => showingCourse === course.id
                  )
                ) {
                  loadProgressForCourse(
                    course,
                    getComponentName(course.id),
                    studentId
                  )(dispatch, getState);
                }
              }
            );
          }
        });
      }
    });
  };
}

export const TOGGLE_STUDENT_PICKER = 'TOGGLE_STUDENT_PICKER';
export function toggleStudentPicker() {
  return {
    type: TOGGLE_STUDENT_PICKER,
  };
}
