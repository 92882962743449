import { getCollectionResultsByName } from '@dabapps/redux-api-collections/dist/collections';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { DispatchCallback } from '../../../requests/types';

import { openModal } from '../../../actions/modals';
import { IStore } from '../../../store';
import { ILearningOutcomeBlockCommentRecord } from '../../../store/data-types/marksheets';
import { TRole } from '../../../store/data-types/profile';
import LearningOutcomeBlockCommentsModal, {
  getComponentName,
  LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE,
} from './learning-outcome-block-comments-modal';

const { IconCheck, IconCommentO, IconComments } = FontAwesome;

interface IExternalProps {
  learningOutcomeBlockId: string;
  numberOfComments: number;
  criteriaMetOrally: boolean;
  title: string;
  isAllowedToAddComments: boolean;
  evidenceTypeOral: boolean;
  userRole: TRole;
  loading: boolean;
}

interface IStateProps {
  numberOfCommentsInCollection: number;
  criteriaMetOrallyInCollection: boolean;
}

interface IDispatchProps {
  openModal(component: React.ReactNode): void;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class LearningOutcomeBlockComments extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const numberOfComments =
      this.props.numberOfCommentsInCollection || this.props.numberOfComments;
    const criteriaMetOrally =
      this.props.criteriaMetOrallyInCollection || this.props.criteriaMetOrally;
    const numberOfCommentsText = `${numberOfComments > 0
      ? numberOfComments
      : 'no'} comment${numberOfComments === 1 ? '' : 's'}`;
    return (
      <div className="margin-top-small text-align-center">
        <IconCommentO className="icon-small" />
        <a onClick={this.openCommentingModal} disabled={this.props.loading}>
          {numberOfCommentsText}
        </a>
        {this.props.evidenceTypeOral &&
          criteriaMetOrally && (
            <span title="Criteria met through oral questioning">
              <IconComments className="icon-small margin-left-base success no-pointer-events" />
              <IconCheck className="icon-small success no-pointer-events" />
            </span>
          )}
      </div>
    );
  }

  private openCommentingModal = () => {
    if (!this.props.loading) {
      this.props.openModal(
        <LearningOutcomeBlockCommentsModal
          title={this.props.title}
          learningOutcomeBlockId={this.props.learningOutcomeBlockId}
          isAllowedToAddComments={this.props.isAllowedToAddComments}
          evidenceTypeOral={this.props.evidenceTypeOral}
          userRole={this.props.userRole}
        />
      );
    }
  };
}

function mapStateToProps(
  { collections, responses }: IStore,
  props: IExternalProps
) {
  const commentsCollection = getCollectionResultsByName(
    collections,
    LEARNING_OUTCOME_BLOCK_COMMENTS_ROUTE,
    getComponentName(props.learningOutcomeBlockId, props.userRole)
  ) as ReadonlyArray<ILearningOutcomeBlockCommentRecord>;
  return {
    ...props,
    numberOfCommentsInCollection: commentsCollection.length,
    criteriaMetOrallyInCollection:
      commentsCollection.filter(comment => comment.criteria_met_orally).length >
      0,
  };
}

export default connect(mapStateToProps, {
  openModal,
})(LearningOutcomeBlockComments);
