import { Column, Row, SpacedGroup } from '@dabapps/roe';
import * as classNames from 'classnames';
import * as React from 'react';

interface IProps extends React.HTMLAttributes<HTMLUListElement> {
  noMargin?: boolean;
  fontClassName?: string;
}

const Nav: React.SFC<IProps> = ({
  className,
  children,
  fontClassName,
  noMargin,
  ...remainingProps,
}) => (
  <Row className="sub-nav">
    <Column>
      <SpacedGroup
        component="ul"
        {...remainingProps}
        className={classNames('nav', fontClassName || '', className)}
      >
        {children}
      </SpacedGroup>
    </Column>
  </Row>
);

export default Nav;
