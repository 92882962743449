import { List, Record } from 'immutable';
import * as moment from 'moment';
import * as _ from 'underscore';

export type RecordInstance<T> = Record.Instance<T> & Readonly<T>;
export function recordList<T>(
  data: List<T> | T[] | undefined,
  ctor: (item: Partial<T>) => RecordInstance<T>
): List<T> {
  return List(data || []).map((item: Partial<T>) => ctor(item));
}

export function recordOrIdList<T>(
  data: List<T> | T[] | List<string> | string[] | undefined,
  constructor: (item: Partial<T>) => RecordInstance<T>
): List<RecordInstance<T>> | List<string> {
  if (!data) {
    return List<string>();
  }
  const list = List<T | string>(data);
  const first = list.first();

  if (_.isString(first)) {
    return list as List<string>;
  } else {
    return (list as List<T>).map((item: Partial<T>) => constructor(item));
  }
}

// tslint:disable-next-line:no-unused-variable
export function narrowToRecordList<T>(input: List<T> | List<string>): List<T> {
  if (input.count() === 0) {
    return List();
  }
  const first = input.get(0);
  if (_.isString(first)) {
    throw new Error('Tried to narrow a List of IDs');
  }
  return input as List<T>;
}

export function momentize(
  input: string | moment.Moment | Date | undefined
): moment.Moment {
  if (input === undefined) {
    return moment.utc();
  }
  return moment.utc(input);
}
