import { Map } from 'immutable';
import {
  RESET_ALL_RECORDED_RESULT_STATUSES,
  RESET_RECORDED_RESULT_STATUS,
} from '../actions/marksheet';
import { UPDATE_ITEM } from '../items/actions';
import { IAction } from '../types';

interface IRecordedResultsMeta {
  itemId: string;
}

export function recordedResultStatuses(
  state = Map<string, string>(),
  action: IAction<void, IRecordedResultsMeta | string>
) {
  const learningOutcomeBlockId =
    typeof action.meta === 'object' && action.meta.itemId;

  switch (action.type) {
    case RESET_ALL_RECORDED_RESULT_STATUSES:
      return Map<string, string>();
    case RESET_RECORDED_RESULT_STATUS:
      return !action.payload || typeof action.payload !== 'string'
        ? state
        : state.delete(action.payload);
    case UPDATE_ITEM.REQUEST:
      return !learningOutcomeBlockId
        ? state
        : state.set(learningOutcomeBlockId, UPDATE_ITEM.REQUEST);
    case UPDATE_ITEM.SUCCESS:
      return !learningOutcomeBlockId
        ? state
        : state.set(learningOutcomeBlockId, UPDATE_ITEM.SUCCESS);
    case UPDATE_ITEM.FAILURE:
      return !learningOutcomeBlockId
        ? state
        : state.set(learningOutcomeBlockId, UPDATE_ITEM.FAILURE);
    default:
      return state;
  }
}
