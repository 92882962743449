import * as React from 'react';

import { Column } from '@dabapps/roe';
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';
import { IJournalEntry } from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { terminologyFromProfile } from '../terminology';

const { IconFilesO, IconTags } = FontAwesome;

interface IJournalInfoProps {
  journalEntry: IJournalEntry;
  profile: IProfile;
  hideTaskLink: boolean;
  isViewingUnderStudentContext?: boolean;
}

export default class JournalInfo extends React.PureComponent<
  IJournalInfoProps,
  void
> {
  public render() {
    const {
      journalEntry,
      profile,
      hideTaskLink,
      isViewingUnderStudentContext,
    } = this.props;

    const shouldShowTaskLink = !hideTaskLink && Boolean(journalEntry.user_task);
    const baseTaskUrl = `/tasks/marksheet/${journalEntry.user_task}/`;
    const taskUrl = isViewingUnderStudentContext
      ? `/users/student/${journalEntry.user.id}${baseTaskUrl}`
      : baseTaskUrl;

    const tasks = shouldShowTaskLink ? (
      <span className="wrap-text">
        <Link to={taskUrl}>
          <IconTags className="icon-small margin-right-small" />
          {journalEntry.user_task_title ||
            `1 ${terminologyFromProfile(profile, 'Task')}`}
        </Link>
      </span>
    ) : (
      <span>
        <IconTags className="icon-small margin-right-small" />
        {journalEntry.user_task ? '1' : '0'}{' '}
        {terminologyFromProfile(
          profile,
          `Task${journalEntry.user_task ? '' : 's'}`
        )}
      </span>
    );

    const fileCount = (
      <span>
        <IconFilesO className="icon-small margin-right-small" />
        {journalEntry.assets.length} files
      </span>
    );

    return (
      <Column xs={12} sm={2}>
        <div className="file-info margin-top-base">
          <ul>
            <li className="overflow-visible">{fileCount}</li>
            <li className="overflow-visible">{tasks}</li>
          </ul>
        </div>
      </Column>
    );
  }
}
