import * as React from 'react';

import { Alert, Column, Row, SpacedGroup } from '@dabapps/roe';
import { Link } from 'react-router';
import {
  // tslint:disable-next-line:no-unused-variable
  ISimpleUser,
} from '../../../store/data-types/profile';
import Term from '../../terminology';
import StudentTable from './student-table';

interface IClassStudentsProps {
  students: ReadonlyArray<ISimpleUser>;
  classId: string;
}
type IProps = React.HTMLProps<JSX.Element> & IClassStudentsProps;

export default function ClassDetailStudentTable(props: IProps) {
  const { students, classId } = props;
  return (
    <Row>
      <Column xs={12}>
        <h3 className="display-inline-block">
          <Term>Students</Term>
        </h3>
        <SpacedGroup className="float-right margin-vertical-base">
          <a
            href={`/api/classes/${classId}/export-students/`}
            target="_blank"
            className="button primary"
          >
            Export CSV
          </a>
          <Link
            to={`/classes/${classId}/edit-students/`}
            className="button primary"
          >
            Add/Remove <Term>Student</Term>s
          </Link>
        </SpacedGroup>
      </Column>
      <Column xs={12}>
        {students.length ? (
          <StudentTable classId={classId} />
        ) : (
          <Alert className="info">
            <p>
              There are no <Term>Students</Term> in this <Term>Class</Term> yet.
            </p>
          </Alert>
        )}
      </Column>
    </Row>
  );
}
