import * as React from 'react';

import { Set } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { closeFlyout } from '../../actions/';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  STUDENT,
  TEACHER,
} from '../../permissions';
import { IStore } from '../../store';
import { IProfile } from '../../store/data-types/profile';
import { formatRoles } from '../../utils';
import CentreSwitcher from '../centres/centre-switcher';
import ImageHolder from '../image-holder';
import IfCan from '../permissions/if-can';
import IfHasRoles from '../permissions/if-has-roles';
import Term from '../terminology';

interface IProps {
  profile: IProfile;
  closeFlyout(): void;
}

export class Menu extends React.PureComponent<IProps, void> {
  public render() {
    const { closeFlyout, profile } = this.props;

    return (
      <ul>
        <IfHasRoles roles={Set.of(STUDENT, TEACHER)}>
          <li className="section">
            <ul>
              <li>
                <Link to="/files/" onClick={closeFlyout}>
                  Add Files
                </Link>
              </li>
              <li>
                <Link to="/journal/add-entry/" onClick={closeFlyout}>
                  Add Journal Entry
                </Link>
              </li>
              <li>
                <Link to="/tasks/timeline/" onClick={closeFlyout}>
                  <Term>Task</Term> Timeline
                </Link>
              </li>
            </ul>
          </li>
        </IfHasRoles>
        <CentreSwitcher />
        <li className="section">
          <ul>
            <li className="profile">
              <p className="info font-size-small">
                {formatRoles(
                  profile.current_role ? [profile.current_role] : [],
                  profile
                )}
              </p>
              <p>
                <strong>{profile.osms_data.name}</strong>
              </p>
              <p>{profile.osms_data.username || '--'}</p>
              <p>
                <Link to="/profile/" onClick={closeFlyout}>
                  View Profile
                </Link>
              </p>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = ({ profile }: IStore) => ({
  profile,
});

export default connect(mapStateToProps, { closeFlyout })(Menu);
