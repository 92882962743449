import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { PAGE_SIZE } from '.';
import { ICollection } from '../../../../collections/types';
import { ISimpleUser } from '../../../../store/data-types/profile';
import Loading from '../../../loading';
import Pagination from '../../../pagination';
import UserSearch, { ISearchForm } from '../../../users/search';
import StudentRow, { IActionProps } from './row';

interface IStudentTableProps extends IActionProps {
  students: ReadonlyArray<ISimpleUser>;
  selectedStudents: ReadonlyArray<string>;
  title: string;
  noStudentsMessage: string;
  loading: boolean;
  search?: string;
  collection?: ICollection<ISimpleUser>;
  onSearch?: (data: ISearchForm) => void;
  onPaginationClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    nextPage: number
  ) => void;
}

export default function StudentTable({
  students,
  selectedStudents,
  mode,
  onSearch,
  title,
  actionName,
  onAction,
  noStudentsMessage,
  loading,
  search,
  collection,
  onPaginationClick,
}: IStudentTableProps) {
  const filteredStudents =
    mode === 'ADD'
      ? students.filter(each => selectedStudents.indexOf(each.id) === -1)
      : students.filter(each => selectedStudents.indexOf(each.id) !== -1);

  return (
    <div>
      <h6 className="margin-top-none">{title}</h6>
      <Table fill className="edit-students-table">
        <TableHead>
          <TableRow>
            <TableHeader>
              {onSearch ? (
                <UserSearch
                  hideLabel
                  loading={loading}
                  onSubmit={onSearch}
                  placeholder="name, username or email"
                  form="EDIT_STUDENTS_SEARCH"
                  className="compact"
                />
              ) : (
                'Name'
              )}
            </TableHeader>
            <TableHeader className="text-align-right">Action</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : filteredStudents.length ? (
            filteredStudents.map(each => (
              <StudentRow
                key={each.id}
                student={each}
                actionName={actionName}
                onAction={onAction}
                mode={mode}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2}>{noStudentsMessage}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {collection &&
        onPaginationClick && (
          <Pagination
            count={collection.count}
            pageSize={PAGE_SIZE}
            currentPage={collection.page}
            onClick={onPaginationClick}
          />
        )}
    </div>
  );
}
