import { Set } from 'immutable';
import { STUDENT } from '../../permissions';
import IfHasRoles from '../permissions/if-has-roles';

import { Column, Row } from '@dabapps/roe';
import * as React from 'react';

import { MATCHES_STUDENT_VIEW_BASE } from '../../permissions';
import NavItem from '../nav/item';
import Term from '../terminology';
import StudentPicker from './student-picker';

const MATCHES_ROOT = new RegExp(`${MATCHES_STUDENT_VIEW_BASE.source}$`);
const MATCHES_FILES = new RegExp(`${MATCHES_STUDENT_VIEW_BASE.source}files\/$`);
const MATCHES_JOURNAL = new RegExp(
  `${MATCHES_STUDENT_VIEW_BASE.source}journal\/.*`
);
const MATCHES_TASKS = new RegExp(
  `${MATCHES_STUDENT_VIEW_BASE.source}tasks\/.*`
);

interface IProps extends React.HTMLProps<HTMLDivElement> {
  location: {
    pathname: string;
  };
  params: {
    student: string;
  };
}

export default function StudentViewPage(props: IProps) {
  const { children, params: { student }, location: { pathname } } = props;
  const baseUrl = `/users/student/${student}/`;
  return (
    <div>
      <div className="sub-nav student-view-menu">
        <Row>
          <Column sm={6}>
            <StudentPicker studentId={student} pathName={pathname} />
          </Column>
          <Column sm={6}>
            <ul className="nav pills">
              <NavItem
                href={baseUrl}
                pathname={pathname}
                matches={MATCHES_ROOT}
              >
                Progress <Term>Overview</Term>
              </NavItem>
              <NavItem
                href={`${baseUrl}files/`}
                pathname={pathname}
                matches={MATCHES_FILES}
              >
                Files
              </NavItem>
              {/* only student can see their personal journal */}
              <IfHasRoles roles={Set.of(STUDENT)}>
                <NavItem
                  href={`${baseUrl}journal/`}
                  pathname={pathname}
                  matches={MATCHES_JOURNAL}
                >
                  Journal
                </NavItem>
              </IfHasRoles>
              <NavItem
                href={`${baseUrl}tasks/`}
                pathname={pathname}
                matches={MATCHES_TASKS}
              >
                <Term>Tasks</Term>
              </NavItem>
            </ul>
          </Column>
        </Row>
      </div>
      {children}
    </div>
  );
}
