import {
  AsyncActionSet,
  getErrorData,
  ResponsesReducerState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Dict } from '@dabapps/simple-records';
import * as _ from 'underscore';

export function getFormErrors(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): Dict<any> | undefined {
  const data = getErrorData(state, actionSet, tag);
  if (_.isObject(data)) {
    return data as any;
  }
  return undefined;
}
