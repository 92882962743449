import {
  anyPending,
  AsyncActionSet,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  ModalBody,
  ModalFooter,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import axios from 'axios';
import * as classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  FormProps,
  FormSection,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { createSelector } from 'reselect';
import * as _ from 'underscore';
import {
  ITaggedAssetData,
  ITaggedAssetFormSection,
  TAG_ASSETS,
  tagAssets,
} from '../../../actions/tag-assets';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import {
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
import {
  AssetRecord,
  IAssetRaw,
  IAssetRecord,
} from '../../../store/data-types';
import { IGroupRecord, IStrandRecord } from '../../../store/data-types/courses';
import { ILearningOutcomeBlockRecord } from '../../../store/data-types/marksheets';
import { ISimpleUser, TRole } from '../../../store/data-types/profile';
import { IProfile } from '../../../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../../../store/data-types/tasks';
import { ITaggingUserTask } from '../../../store/data-types/tasks/tagging';
import { makeUIStateHandler } from '../../../ui-state';
import { getAssetName, getBlockIds } from '../../../utils';
import Loading from '../../loading';
import Term from '../../terminology';
import TagFilesBlockInput from './tag-files-block-input';
import TagFilesGroupInput from './tag-files-group-input';
import TagFilesTaskInput from './tag-files-task-input';

const { actions: { getAllCollection, clearCollection } } = collectionsModule;

const { IconChevronRight, IconChevronDown } = FontAwesome;
const FIRST_COLUMN_WIDTH = 300;

const TAG_FILES_MODAL_OPEN_TASK = 'TAG_FILES_MODAL_OPEN_TASK';
const currentTaskHandler = makeUIStateHandler<string | null>(
  TAG_FILES_MODAL_OPEN_TASK,
  null
);

function getTaskStatusFiltersByRole(role: TRole) {
  if (role === TEACHER) {
    return `${STATUS.IN_PROGRESS},${STATUS.AWAITING_MARKING}`;
  }
  return STATUS.IN_PROGRESS;
}

export interface IFormData {
  [key: string]: ITaggedAssetFormSection;
}

export interface IInputValue {
  id: string;
  learning_outcome_blocks?: ReadonlyArray<string | undefined>;
  user_tasks?: ReadonlyArray<string | undefined>;
}

interface IExternalProps {
  assetIds: List<string>;
  individualTask?: IUserTaskRecord;
  className?: string;
  isLearner: boolean;
  isLoading?: boolean;
  forUser?: ISimpleUser;

  afterSuccessfulTag?(): void;
}

interface IConnectedProps {
  currentlyOpenUserTask: string | null;
  files: List<IAssetRecord>;
  profile: IProfile;
  groupedLearningOutcomeBlocks: ReadonlyArray<ITaggingUserTask>;
  submitting: boolean;
  submitted: boolean;
}

interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
  clearCollection: typeof clearCollection;
  resetRequestState: typeof resetRequestState;
  tagAssets(
    assets: ReadonlyArray<ITaggedAssetData>,
    userTaskId?: string
  ): AxiosPromise;
  setOpenTask(taskId: string): void;
  clearOpenTask(): void;
}

type IProps = IExternalProps &
  IConnectedProps &
  IDispatchProps &
  FormProps<IFormData, {}, {}>;

export const TAG_FILES_FORM = 'TAG_FILES_FORM';

export class TagFilesForm extends React.PureComponent<IProps, void> {
  constructor(props: IProps) {
    super(props);
    this.toggleUserTask = this.toggleUserTask.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getTaggerRole = this.getTaggerRole.bind(this);
  }

  public componentDidMount() {
    const { individualTask, isLearner, forUser } = this.props;

    this.props.clearOpenTask();
    this.props.clearCollection('assets', TAG_FILES_FORM);
    this.props.resetRequestState(TAG_ASSETS);

    const shouldLoadStudentOnlyTags = isLearner;
    const initialFilters = forUser
      ? Map({ user: forUser.id })
      : Map<string, string>({});
    const tasksFilter = individualTask
      ? initialFilters.set('id__in', individualTask.id)
      : initialFilters.set(
          'status',
          getTaskStatusFiltersByRole(this.getTaggerRole())
        );

    axios
      .all([
        this.props.getAllCollection(
          'assets',
          { filters: Map({ id__in: this.props.assetIds.join(',') }) },
          TAG_FILES_FORM
        ),
        this.props.getAllCollection(
          'tasks/tag-files',
          { filters: tasksFilter },
          TAG_FILES_FORM
        ),
      ])
      .then(([filesRequest, _]) => {
        const files: ReadonlyArray<IAssetRaw> = (filesRequest as any).data
          .results;
        const initialValues =
          files &&
          files.reduce(
            (memo, file) => ({
              ...memo,
              [file.id]: {
                user_tasks: file.user_tasks,
                learning_outcome_blocks: file.learning_outcome_blocks,
              },
            }),
            {}
          );

        if (this.props.initialize) {
          this.props.initialize(initialValues);
        }
      });
  }

  public componentWillUnmount() {
    this.props.clearOpenTask();
  }

  public render() {
    const {
      files,
      groupedLearningOutcomeBlocks,
      handleSubmit,
      submitting,
      currentlyOpenUserTask,
      className,
      individualTask,
      isLoading,
    } = this.props;

    return isLoading ? (
      <Loading />
    ) : (
      <Form
        onSubmit={handleSubmit ? handleSubmit(this.onSubmit) : () => null}
        className={className}
      >
        <Table scrollable={false} fill className="wrap-first-column">
          <TableHead>
            <TableRow>
              <TableHeader width={FIRST_COLUMN_WIDTH} />
              {files.map(file => (
                <TableHeader className="filename-heading" key={file.id}>
                  {getAssetName(file)}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          {groupedLearningOutcomeBlocks.length ? (
            groupedLearningOutcomeBlocks.map(task => {
              const { checklists, component_title, qualification_name } = task;
              const blockIds = getBlockIds(task);

              return (
                <TableBody key={task.id}>
                  {!individualTask && (
                    <TableRow
                      className={classNames('border-bottom', 'depth-0')}
                    >
                      <TableCell width={FIRST_COLUMN_WIDTH}>
                        {checklists.length ? (
                          <a onClick={() => this.toggleUserTask(task.id)}>
                            {currentlyOpenUserTask === task.id ? (
                              <IconChevronDown className="icon-collapse" />
                            ) : (
                              <IconChevronRight className="icon-collapse" />
                            )}
                            {component_title}
                          </a>
                        ) : (
                          <span>{component_title}</span>
                        )}
                        <br />
                        <small>{qualification_name}</small>
                      </TableCell>
                      {files.map(file => (
                        <TableCell key={file.id}>
                          <Field
                            name={file.id}
                            taskId={task.id}
                            blockIds={blockIds}
                            component={TagFilesTaskInput}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  {(individualTask || currentlyOpenUserTask === task.id) &&
                    !checklists.length && (
                      <TableRow className="depth-1">
                        <TableHeader colSpan={files.count() + 1}>
                          No learning objectives available for tagging in this
                          <Term>Task</Term>.
                        </TableHeader>
                      </TableRow>
                    )}
                  {checklists.map(checklist => {
                    const { learning_outcomes, strand_title } = checklist;

                    return [
                      (individualTask || currentlyOpenUserTask === task.id) && (
                        <TableRow className="depth-1" key={checklist.id}>
                          <TableHeader colSpan={files.count() + 1}>
                            {strand_title}
                          </TableHeader>
                        </TableRow>
                      ),
                      (individualTask || currentlyOpenUserTask === task.id) &&
                        learning_outcomes.map(learningOutcome => {
                          const {
                            learning_outcome_blocks,
                            group_title,
                          } = learningOutcome;

                          return [
                            <TableRow
                              className="depth-2 border-bottom-none"
                              key={learningOutcome.id}
                            >
                              <TableHeader colSpan={files.count() + 1}>
                                {group_title}
                              </TableHeader>
                            </TableRow>,
                            <TableRow className="depth-2">
                              <TableCell width={FIRST_COLUMN_WIDTH} />
                              {files.map(file => (
                                <TableCell key={file.id}>
                                  <Field
                                    key={file.id}
                                    name={file.id}
                                    taskId={task.id}
                                    group={learningOutcome}
                                    component={TagFilesGroupInput}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>,
                            learning_outcome_blocks.map(block => (
                              <TableRow className="depth-3" key={block.id}>
                                <TableCell width={FIRST_COLUMN_WIDTH}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: block.block_title,
                                    }}
                                  />
                                </TableCell>
                                {files.map(file => (
                                  <TableCell key={file.id}>
                                    <Field
                                      key={file.id}
                                      name={file.id}
                                      taskId={task.id}
                                      blockId={block.id}
                                      component={TagFilesBlockInput}
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                            )),
                          ];
                        }),
                    ];
                  })}
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableRow>
                <TableCell width="100%">
                  <p>
                    There are no components available for you to tag files to at
                    this time.
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Form>
    );
  }

  private toggleUserTask(taskId: string) {
    const { currentlyOpenUserTask, setOpenTask, clearOpenTask } = this.props;

    if (currentlyOpenUserTask === taskId) {
      clearOpenTask();
    } else {
      setOpenTask(taskId);
    }
  }

  private onSubmit(formData?: IFormData) {
    if (!formData) {
      return;
    }

    const { individualTask } = this.props;

    const taggerRole = this.getTaggerRole();
    const assetMappingList = (_.pairs(formData) as Array<
      [string, ITaggedAssetFormSection]
    >)
      .map(([id, fileFormData]) => ({
        ...fileFormData,
        id,
        tagger_role: taggerRole,
      }))
      .map(each => this.stripLockedTasksAndLearningOutcomes(each));

    this.props
      .tagAssets(assetMappingList, individualTask && individualTask.id)
      .then(() => {
        this.props.clearCollection('assets', TAG_FILES_FORM);
        if (this.props.afterSuccessfulTag) {
          this.props.afterSuccessfulTag();
        }
      })
      .catch(console.error);
  }

  private stripLockedTasksAndLearningOutcomes(
    fileFormData: ITaggedAssetData
  ): ITaggedAssetData {
    const { groupedLearningOutcomeBlocks, individualTask } = this.props;
    const userTasks = fileFormData.user_tasks || [];
    const learningOutcomeBlocks = fileFormData.learning_outcome_blocks || [];
    if (
      typeof userTasks === 'string' ||
      typeof learningOutcomeBlocks === 'string'
    ) {
      return fileFormData;
    }
    const taggableTasks = individualTask
      ? groupedLearningOutcomeBlocks.filter(
          each => each.id === individualTask.id
        )
      : groupedLearningOutcomeBlocks;
    const nonLockedUserTasks = taggableTasks.map(each => each.id);
    const nonLockedLearningOutcomeBlocks = _.flatten(
      taggableTasks.map(each => getBlockIds(each))
    );
    const nonLockedAndSelectedUserTasks = userTasks.filter(
      each => each && nonLockedUserTasks.indexOf(each) > -1
    );
    const nonLockedAndSelectedLearningOutcomeBlocks = learningOutcomeBlocks.filter(
      each => nonLockedLearningOutcomeBlocks.indexOf(each) > -1
    );
    return {
      ...fileFormData,
      user_tasks: nonLockedAndSelectedUserTasks,
      learning_outcome_blocks: nonLockedAndSelectedLearningOutcomeBlocks,
    };
  }

  private getTaggerRole() {
    const loggedInUser = this.props.profile;

    if (userHasRoles(loggedInUser, Set.of(STUDENT))) {
      return STUDENT;
    }

    if (userHasRoles(loggedInUser, Set.of(INTERNAL_QUALITY_ASSURER))) {
      return INTERNAL_QUALITY_ASSURER;
    }

    return TEACHER;
  }
}

const form = reduxForm({
  form: TAG_FILES_FORM,
})(TagFilesForm);

const emptyFiles = List<IAssetRecord>();
const emptyTasks = List<ITaggingUserTask>();
const filesSelector = (store: IStore) =>
  getCollectionItems(store.collectionsOld.get('assets'), TAG_FILES_FORM) ||
  emptyFiles;
const tasksSelector = (store: IStore) =>
  getCollectionItems(
    store.collectionsOld.get('tasks/tag-files'),
    TAG_FILES_FORM
  ) || emptyTasks;
// FIXME: These two selectors are near identical
const shouldSeeTasksWithNoBlocksSelector = (
  store: IStore,
  props: IExternalProps
) => !props.isLearner;
const studentTaggingOnlySelector = (store: IStore, props: IExternalProps) =>
  props.isLearner;

function taskHasBlocks(task: ITaggingUserTask): boolean {
  return (
    task.checklists.filter(checklist =>
      checklist.learning_outcomes.filter(
        learningOutcome => learningOutcome.learning_outcome_blocks.length > 0
      )
    ).length > 0
  );
}

export function filterTaskComponents(
  tasks: ReadonlyArray<ITaggingUserTask>,
  shouldSeeTasksWithNoBlocks: boolean,
  studentTaggingOnly: boolean
): ReadonlyArray<ITaggingUserTask> {
  return tasks
    .map(task => ({
      ...task,
      checklists: task.checklists.filter(
        checklist => !studentTaggingOnly || checklist.strand_learner_tagging
      ),
    }))
    .filter(task => shouldSeeTasksWithNoBlocks || taskHasBlocks(task));
}

const groupedLearningOutcomeBlocksSelector = createSelector(
  tasksSelector,
  shouldSeeTasksWithNoBlocksSelector,
  studentTaggingOnlySelector,
  (tasks, shouldSeeTasksWithNoBlocks, studentTaggingOnly) =>
    filterTaskComponents(
      tasks.toArray(),
      shouldSeeTasksWithNoBlocks,
      studentTaggingOnly
    )
);

function mapStateToProps(
  store: IStore,
  props: IExternalProps & FormProps<IFormData, {}, {}>
): IExternalProps & FormProps<IFormData, {}, {}> & IConnectedProps {
  const groupedLearningOutcomeBlocks = groupedLearningOutcomeBlocksSelector(
    store,
    props
  );

  const loading = anyPending(store.responses, [
    [GET_COLLECTION, 'tasks/tag-files', TAG_FILES_FORM],
    [GET_COLLECTION, 'assets', TAG_FILES_FORM],
    TAG_ASSETS,
  ]);

  return {
    ...props,
    files: filesSelector(store),
    currentlyOpenUserTask: currentTaskHandler.getValue(store.uiState),
    groupedLearningOutcomeBlocks,
    isLoading: props.isLoading || loading,
    submitting: isPending(store.responses, TAG_ASSETS),
    submitted: hasSucceeded(store.responses, TAG_ASSETS),
    profile: store.profile,
  };
}

export default connect(mapStateToProps, {
  setOpenTask: currentTaskHandler.setState,
  clearOpenTask: currentTaskHandler.clearState,
  resetRequestState,
  getAllCollection,
  clearCollection,
  tagAssets,
})(form);
