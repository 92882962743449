import { makeAsyncActionSet } from '@dabapps/redux-api-collections/dist/requests';
import { Map } from 'immutable';

import { DispatchCallback, GetStateCallback } from '../requests/types';
import { formatQueryParams } from '../utils';
import { ICollectionOptions } from './types';

export const ITEMS_PER_PAGE = 12;
export const WHOLE_COLLECTION_PAGE_SIZE = 10000;

export function formatCollectionQueryParams(
  options: ICollectionOptions = {}
): string {
  const {
    filters = Map<string, string>(),
    ordering,
    page = 1,
    pageSize = ITEMS_PER_PAGE,
    reverseOrdering,
    search,
  } = options;

  return formatQueryParams({
    ...filters.toObject(),
    ordering: ordering ? `${reverseOrdering ? '-' : ''}${ordering}` : null,
    page,
    page_size: pageSize,
    search: search || filters.get('search'),
  });
}

export const GET_COLLECTION = makeAsyncActionSet('GET_COLLECTION');
export const ADD_TO_COLLECTION = makeAsyncActionSet('ADD_TO_COLLECTION');
export const DELETE_FROM_COLLECTION = makeAsyncActionSet(
  'DELETE_FROM_COLLECTION'
);
export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';
