import { fromJS, List, Map } from 'immutable';
import * as xmlParser from 'xml-parser';

export function getAWSError(error: any): { file: string } | null {
  let xmlData;
  const data = error && error.response && error.response.data;
  try {
    xmlData = data && fromJS(xmlParser(data));
  } catch (e) {
    return null;
  }

  if (!xmlData || !xmlData.get('root')) {
    return null;
  }

  const errorMessage = xmlData
    .getIn(['root', 'children'], List<Map<string, any>>())
    .find(
      (each: Map<string, any>) => each.get('name') === 'Message',
      null,
      Map()
    )
    .get('content', 'An error has occurred');
  return { file: errorMessage };
}
