import {
  Button,
  Column,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Row,
} from '@dabapps/roe';
import { List, Map, Record, Set } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  DataShape,
  Field,
  FieldArray,
  FormComponentProps,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldInputProps,
} from 'redux-form';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import {
  canEditOwnRoles,
  canManageStudentQuotas,
  STUDENT,
} from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IStore } from '../../store';
import { IExamBoardRecord } from '../../store/data-types';
import { IProfile, TRole } from '../../store/data-types/profile';
import { IRoleRecord, RoleRecord } from '../../store/data-types/roles';
import { FormErrors } from '../../utils';
import DateInput from '../date-input';
import GenderSelect from '../forms/gender-select';
import GroupField from '../forms/group-field';
import RolePicker from './role-picker';

const { actions: { getAllCollection } } = collectionsModule;
const ADD_USER_FORM = 'ADD_USER_FORM';

interface IFieldProps {
  input: WrappedFieldInputProps;
}

interface IExternalProps extends FormComponentProps {
  profile: IProfile;
  errors: FormErrors;
  userId?: string;
  loading: boolean;
  disabled?: boolean;
  initialValues?: DataShape;
}

export const INITIAL_ROLES = List([RoleRecord()]);

interface IProps extends IExternalProps, FormProps<DataShape, void, void> {
  examBoards: List<IExamBoardRecord>;
  loggedInUser: IProfile;
  isSelf: boolean;
  isDisabledUsername: boolean;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
}

export class AddUserForm extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.renderRolePicker = this.renderRolePicker.bind(this);
  }

  public componentWillMount() {
    this.props.getAllCollection('courses/exam-boards', {}, ADD_USER_FORM);
  }

  public render() {
    const {
      children,
      errors,
      examBoards,
      handleSubmit,
      isSelf,
      profile,
      loading,
      disabled,
      isDisabledUsername,
      loggedInUser,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} disabled={disabled}>
        <Row>
          <Column xs={12} sm={6}>
            <p className="bold">User details</p>
            <GroupField
              name="username"
              type="text"
              label="Username"
              metalabel="Optional if email provided"
              formErrors={errors}
              disabled={disabled || isDisabledUsername}
            />
            <GroupField
              name="email"
              type="email"
              label="Email"
              metalabel="Optional if username provided"
              formErrors={errors}
              disabled={disabled}
            />
            <GroupField
              name="one_time_password"
              type="text"
              label="Temporary Password"
              metalabel="Changed by user on next login"
              formErrors={errors}
              disabled={disabled}
            />
            {canManageStudentQuotas(loggedInUser) && (
              <div>
                <GroupField
                  name="assets_quota_mb"
                  type="number"
                  label="Assets Quota (mb)"
                  metalabel="Cannot be less than the current usage"
                  disabled={disabled}
                />
                <GroupField
                  name="assets_quota_used_mb"
                  type="number"
                  label="Assets Usage (mb)"
                  disabled
                />
              </div>
            )}
            {(!isSelf || canEditOwnRoles(profile)) && (
              <GroupField
                name="roles"
                type="text"
                label="Roles"
                formErrors={errors}
                component={this.renderRolePicker}
                disabled={disabled}
              />
            )}
          </Column>
          <Column xs={12} sm={6}>
            <p className="bold">Personal details</p>
            <GroupField
              name="given_name"
              type="text"
              label="First Name"
              formErrors={errors}
              disabled={disabled}
            />
            <GroupField
              name="middle_name"
              type="text"
              label="Middle Name"
              metalabel="Optional"
              formErrors={errors}
              disabled={disabled}
            />
            <GroupField
              name="family_name"
              type="text"
              label="Last Name"
              formErrors={errors}
              disabled={disabled}
            />
            <GenderSelect formErrors={errors} disabled={disabled} />
            <GroupField
              name="birth_date"
              type="date"
              formErrors={errors}
              component={fieldProps => (
                <DateInput
                  {...fieldProps}
                  className="input"
                  maxDate={moment.utc()}
                />
              )}
              label="Date of Birth"
              metalabel="Optional"
              disabled={disabled}
            />
          </Column>
        </Row>
        {children}
      </form>
    );
  }

  private renderRolePicker(fieldProps: IFieldProps) {
    return (
      <RolePicker
        {...fieldProps}
        isSelf={this.props.isSelf}
        profile={this.props.profile}
        examBoards={this.props.examBoards}
        formErrors={this.props.errors}
      />
    );
  }
}

export interface IUserFormData {
  username?: string;
  email?: string;
  one_time_password?: string;
  roles?: List<IRoleRecord>;
  given_name?: string;
  middle_name?: string;
  family_name?: string;
  gender?: string;
  birth_date?: string;
}

function mapStateToProps(store: IStore, props: IExternalProps) {
  const { profile } = store;
  const isSelf = profile.id === props.userId;
  const roles = formValueSelector(ADD_USER_FORM)(store, 'roles');
  const isStudentUser =
    roles &&
    roles.filter((role: IRoleRecord) => role.get('role') === STUDENT).count() >
      0;
  const isDisabledUsername = props.userId && isStudentUser;
  return {
    isSelf,
    isDisabledUsername,
    examBoards: getCollectionItems(
      store.collectionsOld.get('courses/exam-boards'),
      ADD_USER_FORM
    ),
    loggedInUser: profile,
    ...props,
  };
}

const Formified = reduxForm({ form: ADD_USER_FORM })(AddUserForm);

export default connect(mapStateToProps, { getAllCollection })(Formified);
