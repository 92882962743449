import { CLASS_STATUSES } from '../store/data-types/classes';

export const CLASS_STATUS_CHOICES = [
  {
    key: CLASS_STATUSES.ACTIVE,
    label: 'Active',
  },
  {
    key: CLASS_STATUSES.ARCHIVED,
    label: 'Archived',
  },
];

export const CLASS_OPTIONS_LIST = 'classes/class-options';
