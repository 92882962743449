import {
  CLEAR_JOURNAL_TASK_DATA,
  SET_JOURNAL_TASK_DATA,
} from '../actions/journals';
import { IJournalTaskData } from '../store/data-types/journals';
import { IAction } from '../types';

export function journalTaskSidebar(
  state: IJournalTaskData = { offJobTrainingHours: null, selectedTask: null },
  action: IAction<any, any>
): IJournalTaskData {
  switch (action.type) {
    case SET_JOURNAL_TASK_DATA:
      return action.payload;
    case CLEAR_JOURNAL_TASK_DATA:
      return {
        offJobTrainingHours: null,
        selectedTask: null,
      };
    default:
      return state;
  }
}
