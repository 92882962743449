import { Button, FormGroup, InputGroup, InputGroupAddon } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { WrappedFieldInputProps } from 'redux-form';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';

import {
  CENTRE_ADMIN,
  currentCentreAllowsMembersToCreateLearners,
  DA_ADMIN,
  DA_SUPPORT,
  doesUserRoleSupersede,
  EXTERNAL_QUALITY_ASSURER,
  getHighestAuthorityRole,
  INTERNAL_QUALITY_ASSURER,
  ROLES_WITH_CENTRES,
  ROLES_WITH_EXAM_BOARDS,
  STUDENT,
  TEACHER,
  userCanCreateRole,
} from '../../permissions';
import { IExamBoardRecord } from '../../store/data-types';
import { ICentre } from '../../store/data-types/centres';
import { IProfile, TRole } from '../../store/data-types/profile';
import {
  IRoleRecord,
  RoleDataRecord,
  RoleRecord,
} from '../../store/data-types/roles';
import { terminologyFromProfile } from '../terminology';
import SearchableCentrePicker from './searchable-centre-picker';

const { IconPlus, IconTimes } = FontAwesome;

export interface ISelectOption {
  key: TRole;
  label: string;
}

export const USER_ROLES = List<ISelectOption>([
  { key: DA_ADMIN, label: 'Admin User' },
  { key: DA_SUPPORT, label: 'Support User' },
  { key: CENTRE_ADMIN, label: 'Centre Admin' },
  { key: EXTERNAL_QUALITY_ASSURER, label: 'External Quality Assurer' },
  { key: INTERNAL_QUALITY_ASSURER, label: 'Internal Quality Assurer' },
  { key: TEACHER, label: 'Teacher' },
  { key: STUDENT, label: 'Student' },
]);

export function updateRoleAtIndex(
  input: WrappedFieldInputProps,
  roles: List<IRoleRecord>,
  index: number,
  role: IRoleRecord
) {
  const newValue = roles.set(index, role);
  return input.onChange(newValue as any); // As any because the types for this handler are wrong
}

function addRole(
  event: React.MouseEvent<HTMLButtonElement>,
  input: WrappedFieldInputProps,
  roles: List<IRoleRecord>
) {
  event.preventDefault();
  const last = roles.last();
  const newValue = roles.push(RoleRecord());

  input.onChange(newValue as any); // As any because the types for this handler are wrong
}

function removeRole(
  input: WrappedFieldInputProps,
  roles: List<IRoleRecord>,
  index: number
) {
  if (roles.count() > 1) {
    const newValue = roles.delete(index);

    input.onChange(newValue as any); // As any because the types for this handler are wrong
  }
}

interface ISubRolePickerProps {
  disabled?: boolean;
  display?: boolean;
  label: string;
  input: WrappedFieldInputProps;
  roles: List<IRoleRecord>;
  options: React.ReactChild[] | List<React.ReactChild>;
  value?: string;
  index: number;
  updateItem(event: React.ChangeEvent<HTMLSelectElement>): IRoleRecord;
}

interface IRolePickerProps {
  input: WrappedFieldInputProps;
  isSelf: boolean;
  profile: IProfile;
  examBoards: List<IExamBoardRecord>;
  disabled?: boolean;
  formErrors?: FormErrors;
}

export const SubRolePicker = ({
  display,
  label,
  input,
  roles,
  options,
  value,
  index,
  updateItem,
  disabled,
}: ISubRolePickerProps) => {
  return display ? (
    <FormGroup>
      <label>{label}</label>
      <select
        value={value}
        onChange={event =>
          updateRoleAtIndex(input, roles, index, updateItem(event))}
        disabled={disabled}
      >
        <option />
        {options}
      </select>
    </FormGroup>
  ) : null;
};

export function RolePicker({
  input,
  profile,
  examBoards,
  disabled,
}: IRolePickerProps) {
  const roles = input.value as List<IRoleRecord>;

  const usersHighestRole = getHighestAuthorityRole(profile);
  const readOnlyRoles = usersHighestRole
    ? List.of(usersHighestRole)
    : List<TRole>();
  const roleOptions = USER_ROLES.filter(role =>
    userCanCreateRole(profile, role.key)
  );
  const isEditingStudent = Boolean(roles.find(role => role.role === STUDENT));

  return (
    <div>
      {roles &&
        roles.map((eachRole, index: number) => {
          const displayCentre =
            eachRole.role && ROLES_WITH_CENTRES.contains(eachRole.role);
          const displayExamBoard =
            eachRole.role && ROLES_WITH_EXAM_BOARDS.contains(eachRole.role);
          if (
            (eachRole.role && readOnlyRoles.contains(eachRole.role)) ||
            (eachRole.role === STUDENT &&
              !currentCentreAllowsMembersToCreateLearners(profile))
          ) {
            const role = USER_ROLES.find(each => each.key === eachRole.role);
            const examBoard =
              displayExamBoard &&
              examBoards.find(
                each =>
                  eachRole.exam_board
                    ? each.id === eachRole.exam_board.id
                    : false
              );
            return (
              <div key={eachRole.key}>
                <p>
                  <strong>{role ? role.label : 'Unknown Role'}</strong>
                  {displayExamBoard && ` for ${examBoard || 'Unknown'}`}{' '}
                  <span className="meta-label">
                    cannot edit or remove this role
                  </span>
                </p>
              </div>
            );
          }
          return (
            <div key={eachRole.key}>
              <FormGroup block>
                {
                  <InputGroup>
                    <select
                      value={eachRole.role}
                      onChange={({ target }) =>
                        updateRoleAtIndex(
                          input,
                          roles,
                          index,
                          eachRole.set('role', target.value as TRole)
                        )}
                      disabled={disabled}
                    >
                      <option />
                      {roleOptions.map(each => (
                        <option key={each.key} value={each.key}>
                          {terminologyFromProfile(profile, each.label)}
                        </option>
                      ))}
                    </select>
                    {!disabled && (
                      <InputGroupAddon>
                        <IconTimes
                          className="icon-addon"
                          onClick={() => removeRole(input, roles, index)}
                        />
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                }
              </FormGroup>

              <SubRolePicker
                display={displayExamBoard}
                label="Exam Board"
                value={eachRole.exam_board && eachRole.exam_board.id}
                index={index}
                input={input}
                roles={roles}
                options={examBoards
                  .map(each => (
                    <option key={each.id} value={each.id}>
                      {each.name}
                    </option>
                  ))
                  .toList()}
                updateItem={({ target }) =>
                  eachRole.set(
                    'exam_board',
                    RoleDataRecord({ id: target.value })
                  )}
                disabled={disabled}
              />

              {displayCentre && (
                <SearchableCentrePicker
                  index={index}
                  input={input}
                  roles={roles}
                  updateItem={option =>
                    eachRole.set(
                      'centre',
                      RoleDataRecord({ id: option.value })
                    )}
                  value={eachRole.centre && eachRole.centre.get('id')}
                  centreId={eachRole.centre && eachRole.centre.id}
                  disabled={disabled}
                />
              )}
            </div>
          );
        })}
      {!disabled &&
        !(
          isEditingStudent &&
          !currentCentreAllowsMembersToCreateLearners(profile)
        ) && (
          <p>
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                addRole(event, input, roles)}
            >
              Add another role <IconPlus className="icon-addon" />
            </Button>
          </p>
        )}
    </div>
  );
}

export default RolePicker;
