import {
  Alert,
  Button,
  Column,
  ContentBoxFooter,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { keys, pairs, pairsToObject } from '../../functional';
import { IAction } from '../../types';
const { IconInfoCircle } = FontAwesome;
import { Dict } from '@dabapps/simple-records';
import { IStore } from '../../store';

interface IExternalProps {
  firstRows: [
    ReadonlyArray<string>,
    ReadonlyArray<string>,
    ReadonlyArray<string>
  ];
  optionalFields: ReadonlyArray<string>;
  requiredFields: ReadonlyArray<string>;
  onCancel(): void;
  onMapped(mapping: Dict<string>): void;
}

interface IProps extends IExternalProps {
  csvMapping: Dict<string>;
  updateCsvHeaderMapping(fieldKey: string, fieldValue: string): void;
}

const UPDATE_CSV_HEADER_MAPPING = 'UPDATE_CSV_HEADER_MAPPING';
export function updateCsvHeaderMapping(fieldKey: string, fieldValue: string) {
  return {
    payload: {
      fieldKey,
      fieldValue,
    },
    type: UPDATE_CSV_HEADER_MAPPING,
  };
}

export function csvMappingReducer(
  state: Dict<string> = {},
  action: IAction<any, any>
) {
  switch (action.type) {
    case UPDATE_CSV_HEADER_MAPPING:
      const fieldKey = action.payload.fieldKey;
      const fieldValue = action.payload.fieldValue;
      // unmap any existing keys or values so that we
      // maintain a strict one-to-one mapping.
      let mapping = pairsToObject(
        pairs(state).filter(([k, v]) => k !== fieldKey && v !== fieldValue)
      );

      if (fieldKey !== '') {
        mapping = {
          ...mapping,
          [fieldKey]: fieldValue,
        };
      }
      return mapping;
    default:
      return state;
  }
}

class ColumnTypes extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.clickNext = this.clickNext.bind(this);
    this.clickBack = this.clickBack.bind(this);
  }

  public render() {
    const {
      requiredFields,
      optionalFields,
      firstRows,
      csvMapping,
    } = this.props;

    const row1 = firstRows[0];
    const row2 = firstRows[1];
    const row3 = firstRows[2];

    return (
      <div className="column-types">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add students
              </li>
              <li className="active">
                <span className="number">2</span> Column types
              </li>
              <li>
                <span className="number">3</span> Review
              </li>
              <li>
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <h3>Review column types</h3>
            <p>
              Displaying the first few entries to help determine the correct
              column names.
            </p>
            <Alert className="helper-text text-align-left">
              <p>
                <IconInfoCircle className="icon-small margin-bottom-small margin-right-base" />
                <strong>Please note:</strong> If you only have{' '}
                <strong>Email</strong>, set it as the <strong>Username</strong>{' '}
                column.
              </p>
            </Alert>

            <Column>
              {row1
                ? row1.map((fieldName: string, index: number) => (
                    <div className="column-type active" key={fieldName}>
                      <Table fill>
                        <TableBody>
                          <TableRow>
                            <TableHeader>{row1[index]}</TableHeader>
                          </TableRow>
                        </TableBody>
                      </Table>

                      <div className="header">
                        <select
                          value={_.invert(csvMapping)[fieldName] || ''}
                          onChange={(event: any) => {
                            this.props.updateCsvHeaderMapping(
                              event.target.value,
                              fieldName
                            );
                          }}
                        >
                          <option value={''}>-- Select Mapping --</option>
                          <optgroup label="Required Fields">
                            {requiredFields.map((requiredFieldName: string) => {
                              if (!csvMapping[requiredFieldName]) {
                                return (
                                  <option key={requiredFieldName}>
                                    {requiredFieldName}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    key={requiredFieldName}
                                    value={requiredFieldName}
                                    disabled={true}
                                  >
                                    {requiredFieldName} ({csvMapping[requiredFieldName]})
                                  </option>
                                );
                              }
                            })}
                          </optgroup>
                          <optgroup label="Optional Fields">
                            {optionalFields.map((optionalFieldname: string) => (
                              <option key={optionalFieldname}>
                                {optionalFieldname}
                              </option>
                            ))}
                          </optgroup>
                        </select>
                      </div>

                      <Table fill>
                        <TableBody>
                          {row2.length ? (
                            <TableRow>
                              <TableCell>{row2[index]}</TableCell>
                            </TableRow>
                          ) : null}
                          {row3.length ? (
                            <TableRow>
                              <TableCell>{row3[index]}</TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </div>
                  ))
                : null}
            </Column>

            <Column>
              <Row>
                <ContentBoxFooter>
                  <div className="btn-footer">
                    <Button
                      onClick={this.clickBack}
                      className="float-left margin-vertical-base"
                    >
                      Back
                    </Button>
                    <Button
                      disabled={!this.canProgress()}
                      onClick={this.clickNext}
                      className="primary float-right margin-vertical-base"
                    >
                      Next
                    </Button>
                  </div>
                </ContentBoxFooter>
              </Row>
            </Column>
          </Column>
        </Row>
      </div>
    );
  }

  private clickNext() {
    this.props.onMapped(this.props.csvMapping);
  }

  private clickBack() {
    this.props.onCancel();
  }

  private canProgress(): boolean {
    const mapping = this.props.csvMapping;
    const requiredSet = Set(this.props.requiredFields);
    const mappedSet = Set(keys(mapping));
    return requiredSet.subtract(mappedSet).count() === 0;
  }
}

function mapStateToProps(
  { csvMapping }: IStore,
  {
    requiredFields,
    optionalFields,
    firstRows,
    onMapped,
    onCancel,
  }: IExternalProps
) {
  return {
    csvMapping,
    firstRows,
    onCancel,
    onMapped,
    optionalFields,
    requiredFields,
  };
}

export default connect(mapStateToProps, { updateCsvHeaderMapping })(
  ColumnTypes
);
