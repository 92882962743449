import { SimpleRecord } from '@dabapps/simple-records';

export type IQualificationStudentProgress = Readonly<{
  id: string;
  qualification_name: string;
  qualification_centre_id: string;
  qualification_exam_spec_id: string;
  qualification_option_id: string;
  class_id: string;
  student_name: string;
  tasks_awaiting_marking: number; // awaiting marking
  tasks_in_progress: number; // in progress
  tasks_marked: number; // marked
  units_not_assigned: number; // minimum units - assigned units
  tasks_submitted: number; // submitted
  tasks_quality_assured: number; // quality assured
  tasks_pending_submission: number; // pending submission
  tasks_overdue: number; // in progress && deadline < now
  progress_through_assigned_units: number;
  minimum_units: number;
  assigned_units: number;
  total_tasks: number;
  tasks_past_marking: number;
}>;

export const QualificationStudentProgressRecord = SimpleRecord<
  IQualificationStudentProgress
>({
  id: '',
  qualification_name: 'Unknown',
  qualification_centre_id: '',
  qualification_exam_spec_id: '',
  qualification_option_id: '',
  class_id: '',
  student_name: 'Unknown',
  progress_through_assigned_units: 0,
  minimum_units: 0,
  assigned_units: 0,
  tasks_awaiting_marking: 0,
  tasks_in_progress: 0,
  tasks_marked: 0,
  units_not_assigned: 0,
  tasks_overdue: 0,
  tasks_pending_submission: 0,
  tasks_quality_assured: 0,
  tasks_submitted: 0,
  total_tasks: 0,
  tasks_past_marking: 0,
});
