import {
  getCollectionByName,
  getCollectionResultsByName,
} from '@dabapps/redux-api-collections/dist/collections';
import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { setNewsFeedCount } from '../actions';
import { collections, ICollections } from '../collections';
import { GET_COLLECTION } from '../collections/actions';
import { ICollectionOptions } from '../collections/types';
import { IStore } from '../store';
import { IActivityEventRecord } from '../store/data-types/activity-events';
import { IProfile } from '../store/data-types/profile';
import { IUserTaskRecord } from '../store/data-types/tasks';
import { formatDate, formatTimeAgo } from '../utils';
import DashboardMenu from './dashboard/dashboard-menu';
import Loading from './loading';
import LoadingSpinner from './loading-spinner';
import NavItem from './nav/item';
import NewsFeedItemContainer from './news-feed-item-container';
import Pagination from './pagination';

const { actions: { getCollection } } = collections;

const DISPLAY_COUNT_OPTIONS = [10, 20, 50, 100];
const NEWSFEED = 'NEWSFEED';

interface ILocation {
  pathname: string;
}

interface IExternalProps {
  location: ILocation;
}

interface IProps extends IExternalProps {
  count: number;
  currentPage: number;
  newsFeedCount: number;
  activityLog: ReadonlyArray<IActivityEventRecord>;
  loading: boolean;
  userId: string;
  profile: IProfile;
  getCollection: typeof getCollection;
  setNewsFeedCount(count: number): void;
}

export class NewsFeed extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.setNewsFeedCount = this.setNewsFeedCount.bind(this);
  }

  public componentWillMount() {
    this.getPage(1, this.props);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.newsFeedCount !== this.props.newsFeedCount
    ) {
      this.getPage(1, nextProps);
    }
  }

  public render() {
    const {
      activityLog,
      count,
      currentPage,
      loading,
      location,
      userId,
      profile,
      newsFeedCount,
    } = this.props;

    return (
      <div>
        <DashboardMenu pathname={location.pathname} />
        <ContentBox>
          <ContentBoxHeader>
            <Row>
              <Column sm={4}>
                <h2 className="font-size-large">Latest Activity</h2>
              </Column>
              <Column sm={8}>
                <div className="sm-float-right">
                  <SpacedGroup
                    component="ul"
                    className="nav highlight-active font-size-base"
                  >
                    <NavItem>Show:</NavItem>
                    <NavItem>
                      <select
                        className="news-feed-display"
                        value={newsFeedCount}
                        onChange={this.setNewsFeedCount}
                      >
                        {DISPLAY_COUNT_OPTIONS.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </NavItem>
                  </SpacedGroup>
                </div>
              </Column>
            </Row>
          </ContentBoxHeader>
          {loading ? (
            <Loading />
          ) : activityLog.length ? (
            activityLog.map(item => (
              <NewsFeedItemContainer
                key={item.id}
                eventRecord={item}
                profile={profile}
              />
            ))
          ) : (
            <p className="info text-align-center padding-base">
              Nothing to display.
            </p>
          )}
          <Pagination
            count={count}
            pageSize={newsFeedCount}
            currentPage={currentPage}
            onClick={this.onPaginationClick.bind(this)}
          />
        </ContentBox>
      </div>
    );
  }

  private setNewsFeedCount({ target }: React.ChangeEvent<HTMLSelectElement>) {
    this.props.setNewsFeedCount(parseInt(target.value, 10));
  }

  private onPaginationClick(
    event: React.MouseEvent<HTMLButtonElement>,
    nextPage: number
  ): void {
    this.getPage(nextPage, this.props);
  }

  private getPage(page: number, props: IProps) {
    const { location, userId, newsFeedCount } = props;
    const filters =
      location.pathname.indexOf('newsfeed') !== -1 ? {} : { user: userId };

    props.getCollection(
      'activity-log',
      {
        filters,
        page,
        pageSize: newsFeedCount,
      },
      NEWSFEED
    );
  }
}

function mapStateToProps(
  { collections, profile, responses, newsFeedCount }: IStore,
  props: IExternalProps
) {
  const activityLog = getCollectionResultsByName(
    collections,
    'activity-log',
    NEWSFEED
  );
  const activityLogCollection = getCollectionByName(
    collections,
    'activity-log',
    NEWSFEED
  );

  return {
    activityLog,
    count: activityLogCollection.count,
    currentPage: activityLogCollection.page || 1,
    loading: isPending(responses, GET_COLLECTION, 'activity-log'),
    profile,
    userId: profile.id,
    newsFeedCount,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  setNewsFeedCount,
})(NewsFeed);
