import { RecordWithConstructor, SimpleRecord } from '@dabapps/simple-records';
import { Record } from 'immutable';
import * as moment from 'moment';
import { AssetRecord, CommentRecord, IAssetRecord, ICommentRecord } from '.';
import { STUDENT } from '../../permissions';
import { CentreRecord, ICentre } from './centres';
import { ClassRecord, IClassRecord } from './classes';
import { IJournalEntry, JournalEntryRecord } from './journals';
import {
  ILearningOutcomeBlockRecord,
  LearningOutcomeBlockRecord,
} from './marksheets';
import {
  HijackerProfileRecord,
  IHijackerProfile,
  IProfile, // TODO: We should really provide a cut down usertype here
  IProfileInput,
  ProfileRecord,
  TRole,
} from './profile';
import { IQualificationRecord, QualificationRecord } from './qualifications';
import { RecordInstance } from './records';
import {
  ClassTaskRecord,
  CompletionAttemptRecord,
  ComponentRecord,
  IClassTaskRecord,
  ICompletionAttempt,
  IComponent,
  ITaskStatusChangeCommentRecord,
  IUserTaskRecord,
  TaskStatusChangeCommentRecord,
  TStatusChange,
  UserTaskRecord,
} from './tasks';

interface IJournalPostActivityEvent {
  journal_entry: IJournalEntry;
}

export type IJournalPostActivityEventRecord = RecordInstance<
  IJournalPostActivityEvent
>;
const JournalPostActivityEventRecordInner = Record<IJournalPostActivityEvent>({
  journal_entry: JournalEntryRecord({}),
});

export function JournalPostActivityEventRecord(
  input: Partial<IJournalPostActivityEvent>
): IJournalPostActivityEventRecord {
  const item = JournalPostActivityEventRecordInner(input);
  return item.set(
    'journal_entry',
    JournalEntryRecord(input.journal_entry || {})
  );
}

interface INewCommentActivityEvent {
  comment: ICommentRecord;
  journal_entry?: IJournalEntry;
  task_status_change_comment?: ITaskStatusChangeCommentRecord;
}

export type INewCommentActivityEventRecord = RecordInstance<
  INewCommentActivityEvent
>;
const NewCommentActivityEventRecordInner = Record<INewCommentActivityEvent>({
  comment: CommentRecord({}),
  journal_entry: undefined,
  task_status_change_comment: undefined,
});

export function NewCommentActivityEventRecord(
  input: Partial<INewCommentActivityEvent>
): INewCommentActivityEventRecord {
  const item = NewCommentActivityEventRecordInner(input);
  return item
    .set(
      'journal_entry',
      input.journal_entry ? JournalEntryRecord(input.journal_entry) : undefined
    )
    .set(
      'task_status_change_comment',
      input.task_status_change_comment
        ? TaskStatusChangeCommentRecord(input.task_status_change_comment)
        : undefined
    )
    .set('comment', CommentRecord(input.comment || {}));
}

interface IFileUploadedActivityEvent {
  asset: IAssetRecord;
}

export type IFileUploadedActivityEventRecord = RecordInstance<
  IFileUploadedActivityEvent
>;
const FileUploadedActivityEventRecordInner = Record<
  IFileUploadedActivityEvent
>({
  asset: AssetRecord({}),
});

export function FileUploadedActivityEventRecord(
  input: Partial<IFileUploadedActivityEvent>
): IFileUploadedActivityEventRecord {
  const item = FileUploadedActivityEventRecordInner(input);
  return item.set('asset', AssetRecord(input.asset || {}));
}

interface IAccountHijackActivityEvent {
  hijacked_user: IHijackerProfile;
}

export type IAccountHijackActivityEventRecord = RecordInstance<
  IAccountHijackActivityEvent
>;
const AccountHijackActivityEventRecordInner = Record<
  IAccountHijackActivityEvent
>({
  hijacked_user: HijackerProfileRecord({}),
});

export function AccountHijackActivityEventRecord(
  input: Partial<IAccountHijackActivityEvent>
): IAccountHijackActivityEventRecord {
  const item = AccountHijackActivityEventRecordInner(input);
  return item.set(
    'hijacked_user',
    HijackerProfileRecord(input.hijacked_user || {})
  );
}

interface IMarksheetStatusChangeEvent {
  status_change: TStatusChange;
  task_status_change_comment?: ITaskStatusChangeCommentRecord;
  user_task: IUserTaskRecord;
}
export type IMarksheetStatusChangeEventRecord = RecordInstance<
  IMarksheetStatusChangeEvent
>;
const MarksheetStatusChangeEventRecordInner = Record<
  IMarksheetStatusChangeEvent
>({
  status_change: 'HAND_IN',
  user_task: UserTaskRecord({}),
  task_status_change_comment: undefined,
});
export function MarksheetStatusChangeEventRecord(
  input: Partial<IMarksheetStatusChangeEventRecord>
): IMarksheetStatusChangeEventRecord {
  const item = MarksheetStatusChangeEventRecordInner(input);
  return item
    .set('user_task', UserTaskRecord(input.user_task || {}))
    .set(
      'task_status_change_comment',
      input.task_status_change_comment &&
        TaskStatusChangeCommentRecord(input.task_status_change_comment)
    );
}

interface IEQAClassTaskSignOffEvent {
  class_task: IClassTaskRecord;
}
export type IEQAClassTaskSignOffEventRecord = RecordInstance<
  IEQAClassTaskSignOffEvent
>;
const EQAClassTaskSignOffEventRecordInner = Record<IEQAClassTaskSignOffEvent>({
  class_task: ClassTaskRecord({}),
});
export function EQAClassTaskSignOffEventRecord(
  input: Partial<IEQAClassTaskSignOffEventRecord>
): IEQAClassTaskSignOffEventRecord {
  const item = EQAClassTaskSignOffEventRecordInner(input);
  return item.set('class_task', ClassTaskRecord(input.class_task || {}));
}

interface IIQARequestEQAReviewEvent {
  class_task: IClassTaskRecord;
}
export type IIQARequestEQAReviewEventRecord = RecordInstance<
  IIQARequestEQAReviewEvent
>;
const IQARequestEQAReviewEventRecordInner = Record<IIQARequestEQAReviewEvent>({
  class_task: ClassTaskRecord({}),
});
export function IQARequestEQAReviewEventRecord(
  input: Partial<IIQARequestEQAReviewEventRecord>
): IIQARequestEQAReviewEventRecord {
  const item = IQARequestEQAReviewEventRecordInner(input);
  return item.set('class_task', ClassTaskRecord(input.class_task || {}));
}

interface IQualificationAddedToClassEvent {
  qualification: IQualificationRecord;
}
export type IQualificationAddedToClassEventRecord = RecordInstance<
  IQualificationAddedToClassEvent
>;
const QualificationAddedToClassEventRecordInner = Record<
  IQualificationAddedToClassEvent
>({ qualification: QualificationRecord({}) });
export function QualificationAddedToClassEventRecord(
  input: Partial<IQualificationAddedToClassEventRecord>
): IQualificationAddedToClassEventRecord {
  const item = QualificationAddedToClassEventRecordInner(input);
  return item.set(
    'qualification',
    QualificationRecord(input.qualification || {})
  );
}

interface IQAAddedToCentreEvent {
  centre: ICentre;
  user: IProfile;
}
export type IQAAddedToCentreEventRecord = RecordInstance<IQAAddedToCentreEvent>;
const QAAddedToCentreEventRecordInner = Record<IQAAddedToCentreEvent>({
  centre: CentreRecord({}),
  user: ProfileRecord({}),
});
export function QAAddedToCentreEventRecord(
  input: Partial<IQAAddedToCentreEventRecord>
): IQAAddedToCentreEventRecord {
  const item = QAAddedToCentreEventRecordInner(input);
  return item
    .set('centre', CentreRecord(input.centre || {}))
    .set('user', ProfileRecord(input.user || {}));
}

export type IAllowAnotherCompletionAttemptEventInput = Readonly<{
  attempt_record: Partial<ICompletionAttempt>;
  user: Partial<IProfileInput>;
}>;
export type IAllowAnotherCompletionAttemptEvent = Readonly<{
  attempt_record: ICompletionAttempt;
  user: IProfile;
}>;
export const AllowAnotherCompletionAttemptEventRecord = RecordWithConstructor<
  IAllowAnotherCompletionAttemptEventInput,
  IAllowAnotherCompletionAttemptEvent
>(
  {
    attempt_record: CompletionAttemptRecord({}),
    user: {},
  },
  input => {
    return {
      ...input,
      attempt_record: CompletionAttemptRecord(input.attempt_record),
      user: ProfileRecord(input.user),
    };
  }
);

export type IExamResultsReceivedEvent = Readonly<{
  qualification: Partial<IQualificationRecord>;
  component: Partial<IComponent>;
  learning_outcome_block: Partial<ILearningOutcomeBlockRecord>;
}>;
export const ExamResultsReceivedEventRecord = SimpleRecord<
  IExamResultsReceivedEvent
>({
  qualification: QualificationRecord({}),
  component: ComponentRecord({}),
  learning_outcome_block: LearningOutcomeBlockRecord({}),
});

export type IUserAddedToClassEvent = Readonly<{
  role: TRole;
}>;
export const UserAddedToClassRecord = SimpleRecord<IUserAddedToClassEvent>({
  role: STUDENT,
});

export type TEventType =
  | 'UPDATE_JOURNAL_POST'
  | 'CREATE_JOURNAL_POST'
  | 'NEW_CLASS_POST'
  | 'NEW_COMMENT'
  | 'FILE_UPLOADED'
  | 'ACCOUNT_HIJACK'
  | 'QUALIFICATION_ADDED_TO_CLASS'
  | 'IQA_ADDED_TO_CENTRE'
  | 'EQA_ADDED_TO_CENTRE'
  | 'MARKSHEET_STATUS_CHANGE'
  | 'EQA_SIGN_OFF_CLASS_TASK'
  | 'IQA_REQUEST_EQA_REVIEW'
  | 'ALLOW_ANOTHER_COMPLETION_ATTEMPT'
  | 'EXAM_RESULTS_RECEIVED'
  | 'USER_ADDED_TO_CLASS';

export interface IActivityEvent {
  id: string;
  created: moment.Moment;
  modified: moment.Moment;
  user: IProfile;
  event_class: IClassRecord;
  event_type: TEventType;
  account_hijack: IAccountHijackActivityEventRecord | null;
  new_comment: INewCommentActivityEventRecord | null;
  file_uploaded: IFileUploadedActivityEventRecord | null;
  update_journal_post: IJournalPostActivityEventRecord | null;
  marksheet_status_change: IMarksheetStatusChangeEventRecord | null;
  qualification_added_to_class: IQualificationAddedToClassEventRecord | null;
  qa_added_to_centre: IQAAddedToCentreEventRecord | null;
  allow_another_completion_attempt: IAllowAnotherCompletionAttemptEvent | null;
  eqa_class_task_sign_off: IEQAClassTaskSignOffEventRecord | null;
  iqa_request_eqa_review: IIQARequestEQAReviewEventRecord | null;
  exam_results_received: IExamResultsReceivedEvent | null;
  user_added_to_class: IUserAddedToClassEvent | null;
}

export type IActivityEventRecord = RecordInstance<IActivityEvent>;
const ActivityEventRecordInner = Record<IActivityEvent>({
  account_hijack: null,
  created: moment.utc(),
  event_class: ClassRecord({}),
  event_type: 'UPDATE_JOURNAL_POST',
  file_uploaded: null,
  id: '',
  modified: moment.utc(),
  marksheet_status_change: null,
  new_comment: null,
  update_journal_post: null,
  user: ProfileRecord({}),
  qualification_added_to_class: null,
  qa_added_to_centre: null,
  allow_another_completion_attempt: null,
  eqa_class_task_sign_off: null,
  iqa_request_eqa_review: null,
  exam_results_received: null,
  user_added_to_class: null,
});

export function ActivityEventRecord(
  input: Partial<IActivityEvent>
): IActivityEventRecord {
  const item = ActivityEventRecordInner(input);

  const {
    new_comment,
    update_journal_post,
    file_uploaded,
    account_hijack,
    marksheet_status_change,
    qualification_added_to_class,
    qa_added_to_centre,
    event_class,
    allow_another_completion_attempt,
    eqa_class_task_sign_off,
    iqa_request_eqa_review,
    exam_results_received,
    user_added_to_class,
  } = input;

  return item
    .set('user', input.user ? ProfileRecord(input.user) : ProfileRecord({}))
    .set('created', input.created ? moment.utc(input.created) : moment.utc())
    .set('modified', input.modified ? moment.utc(input.modified) : moment.utc())
    .set('event_class', ClassRecord(event_class || {}))
    .set(
      'new_comment',
      new_comment ? NewCommentActivityEventRecord(new_comment) : null
    )
    .set(
      'update_journal_post',
      update_journal_post
        ? JournalPostActivityEventRecord(update_journal_post)
        : null
    )
    .set(
      'file_uploaded',
      file_uploaded ? FileUploadedActivityEventRecord(file_uploaded) : null
    )
    .set(
      'account_hijack',
      account_hijack ? AccountHijackActivityEventRecord(account_hijack) : null
    )
    .set(
      'marksheet_status_change',
      marksheet_status_change
        ? MarksheetStatusChangeEventRecord(marksheet_status_change)
        : null
    )
    .set(
      'qualification_added_to_class',
      qualification_added_to_class
        ? QualificationAddedToClassEventRecord(qualification_added_to_class)
        : null
    )
    .set(
      'qa_added_to_centre',
      qa_added_to_centre ? QAAddedToCentreEventRecord(qa_added_to_centre) : null
    )
    .set(
      'allow_another_completion_attempt',
      allow_another_completion_attempt
        ? AllowAnotherCompletionAttemptEventRecord(
            allow_another_completion_attempt
          )
        : null
    )
    .set(
      'eqa_class_task_sign_off',
      eqa_class_task_sign_off
        ? EQAClassTaskSignOffEventRecord(eqa_class_task_sign_off)
        : null
    )
    .set(
      'iqa_request_eqa_review',
      iqa_request_eqa_review
        ? IQARequestEQAReviewEventRecord(iqa_request_eqa_review)
        : null
    )
    .set(
      'exam_results_received',
      exam_results_received
        ? ExamResultsReceivedEventRecord(exam_results_received)
        : null
    )
    .set(
      'user_added_to_class',
      user_added_to_class ? UserAddedToClassRecord(user_added_to_class) : null
    );
}
