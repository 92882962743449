import * as React from 'react';

import { Alert } from '@dabapps/roe';
import { Link } from 'react-router';
import Term from '../terminology';

export default function RemoveStudentsWarning() {
  return (
    <Alert className="warning">
      <p>
        Note: If you remove any <Term>Students</Term> from this{' '}
        <Term>Class</Term> it will hide the qualification and associated{' '}
        <Term>Tasks</Term> from them but it will not archive the{' '}
        <Term>Students</Term>. You can archive <Term>Students</Term> from the{' '}
        <Link to="/users/">users page</Link>.
      </p>
    </Alert>
  );
}
