import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { List, Map } from 'immutable';
import { push } from 'react-router-redux';
import { Dispatch } from 'redux';
import { IPostTransformFormData } from '../components/journals/add-journal-entry';
import { collectionsModule } from '../reducers/collections';
import { GetStateCallback } from '../requests/types';
import { IJournalTaskData } from '../store/data-types/journals';

const { actions: { addItem, getCollection } } = collectionsModule;

const JOURNAL_FEED = 'JOURNAL_FEED';

export type JournalType = 'self' | 'class' | 'user';

export function addJournalEntryAndRedirectToDetailPage(
  data: IPostTransformFormData,
  tag?: string
) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return addItem('journals', data, tag)(dispatch, getState)
      .then(response => {
        const entryId = response && response.data.id;
        if (entryId) {
          return dispatch(push(`/journal/${entryId}/`));
        }
      })
      .catch(error => null);
  };
}

export function getJournalEntries(userId: string, journalType: JournalType) {
  const filters = Map({
    journal_type: journalType,
    user_id: userId,
  });
  return getCollection('journals', { filters }, JOURNAL_FEED);
}

const SET_JOURNAL_ASSETS = makeAsyncActionSet('SET_JOURNAL_ASSETS');
export function setJournalAssets(
  journalId: string,
  assets: List<string>,
  tag?: string
) {
  return dispatchGenericRequest(
    SET_JOURNAL_ASSETS,
    `/api/journals/${journalId}/assets/`,
    'POST',
    { assets: assets.toArray() },
    tag
  );
}

export const SET_JOURNAL_TASK_DATA = 'SET_JOURNAL_TASK_DATA';
export function setJournalTaskData(data: IJournalTaskData) {
  return {
    payload: data,
    type: SET_JOURNAL_TASK_DATA,
  };
}

export const CLEAR_JOURNAL_TASK_DATA = 'CLEAR_JOURNAL_TASK_DATA';
export function clearJournalTaskData() {
  return { type: CLEAR_JOURNAL_TASK_DATA };
}
