import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
// tslint:disable-next-line:no-unused-variable
import { List, Map, Set } from 'immutable';
import { TRole } from '../store/data-types/profile';

export type ITaggedAssetFormSection = Readonly<{
  id: string;
  user_tasks: ReadonlyArray<string | undefined>;
  learning_outcome_blocks: ReadonlyArray<string | undefined>;
}>;

export interface ITaggedAssetData extends ITaggedAssetFormSection {
  tagger_role: TRole;
}

export const TAG_ASSETS = makeAsyncActionSet('TAG_ASSETS');
export function tagAssets(
  assetData: ReadonlyArray<ITaggedAssetData>,
  userTaskId?: string,
  tag?: string
) {
  const url = userTaskId
    ? `/api/assets/tag-assets/${userTaskId}/`
    : '/api/assets/tag-assets/';
  return dispatchGenericRequest(TAG_ASSETS, url, 'PUT', assetData, tag);
}
