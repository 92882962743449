import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';

export const ADD_COMMENT = makeAsyncActionSet('ADD_COMMENT');
export function addComment(content: string, parent: string) {
  return dispatchGenericRequest(ADD_COMMENT, '/api/comments/', 'POST', {
    content,
    parent,
  });
}

export const GET_COMMENTS = makeAsyncActionSet('GET_COMMENTS');
export function getComments(rootCommentId: string) {
  return dispatchGenericRequest(
    GET_COMMENTS,
    `/api/comments/${rootCommentId}/`,
    'GET'
  );
}

export const OPEN_NEW_REPLY = 'OPEN_NEW_REPLY';
export function openNewReply(commentId: string) {
  return { type: OPEN_NEW_REPLY, payload: commentId };
}

export const CLOSE_NEW_REPLY = 'CLOSE_NEW_REPLY';
export function closeNewReply(commentId: string) {
  return { type: CLOSE_NEW_REPLY, payload: commentId };
}

export const UPDATE_REPLY = 'UPDATE_REPLY';
export function updateReply(commentId: string, content: string) {
  return {
    payload: {
      commentId,
      content,
    },
    type: UPDATE_REPLY,
  };
}
