import { Set } from 'immutable';
import { replace as routerReplace } from 'react-router-redux';

import { shouldRedirectToRoleSelection, userHasRoles } from '../permissions';
import {
  IProfile,
  // tslint:disable-next-line:no-unused-variable
  TRole,
} from '../store/data-types/profile';
import { emptyAction } from './index';

export function redirectIfNotRole(
  roles: Set<TRole>,
  redirectPath: string,
  user: IProfile
) {
  // is an action because it returns the router action
  if (!userHasRoles(user, roles)) {
    return routerReplace(redirectPath);
  }

  return emptyAction();
}

export function redirectIfRole(
  roles: Set<TRole>,
  redirectPath: string,
  user: IProfile
) {
  // is an action because it returns the router action
  if (userHasRoles(user, roles)) {
    return routerReplace(redirectPath);
  }

  return emptyAction();
}

const SELECT_ROLE_PATH = '/select-role/';
export function redirectWhenCurrentRoleNotSet(
  currentPath: string | undefined,
  user: IProfile
) {
  // NOTE: is an action because it returns the router action
  if (shouldRedirectToRoleSelection(currentPath, user)) {
    return routerReplace(SELECT_ROLE_PATH);
  }
  return emptyAction();
}
