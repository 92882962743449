import { Alert, Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

import { ISimpleUser } from '../../store/data-types/profile';
import ImageHolder from '../image-holder';

import Term from '../terminology';

const { IconUserCircleO } = FontAwesome;

interface IClassTeachersProps {
  teachers: ReadonlyArray<ISimpleUser>;
}
export default function ClassDetailTeacherList({
  teachers,
}: IClassTeachersProps) {
  return teachers.length ? (
    <Row>
      <Column xs={12}>
        <h3>
          <Term>Teachers</Term>
        </h3>
      </Column>
      {teachers.map(teacher => (
        <Column xs={12} key={teacher.id}>
          <IconUserCircleO className="teacher-detail-picture" />
          {teacher.osms_data.name || '--'}
        </Column>
      ))}
    </Row>
  ) : (
    <Alert className="info">
      <p>
        There are no <Term>Teachers</Term> in this <Term>Class</Term> yet.
      </p>
    </Alert>
  );
}
