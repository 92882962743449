import * as React from 'react';

import { Column, ContentBox, Row, Tab, Tabs } from '@dabapps/roe';

import { Link } from 'react-router';

interface ILocation {
  pathname: string;
}

interface IProps {
  location: ILocation;
}

export class Profile extends React.PureComponent<IProps, void> {
  public render() {
    return (
      <Row>
        <Column>
          <Tabs className="margin-top-large">
            <Tab active={this.props.location.pathname === '/profile/'}>
              <Link to="/profile/">Preferences</Link>
            </Tab>
            <Tab active={this.props.location.pathname === '/profile/personal/'}>
              <Link to="/profile/personal/">Personal</Link>
            </Tab>
            <Tab active={this.props.location.pathname === '/profile/password/'}>
              <Link to="/profile/password/">Password</Link>
            </Tab>
            <Tab active={this.props.location.pathname === '/profile/quota/'}>
              <Link to="/profile/quota/">Quota</Link>
            </Tab>
          </Tabs>
          <ContentBox>{this.props.children}</ContentBox>
        </Column>
      </Row>
    );
  }
}

export default Profile;
