import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  ILearningOutcomeBlockNewComment,
  ILearningOutcomeBlockUpdate,
} from '../store/data-types/marksheets';

export const RESET_RECORDED_RESULT_STATUS = 'RESET_RECORDED_RESULT_STATUS';
export function resetRecordedResultStatus(learningOutcomeBockId: string) {
  return {
    type: RESET_RECORDED_RESULT_STATUS,
    payload: learningOutcomeBockId,
  };
}

export const RESET_ALL_RECORDED_RESULT_STATUSES =
  'RESET_ALL_RECORDED_RESULT_STATUSES';
export function resetAllRecordedResultStatuses() {
  return {
    type: RESET_ALL_RECORDED_RESULT_STATUSES,
  };
}

export const SUBMIT_PERFORMANCE = makeAsyncActionSet('SUBMIT_PERFORMANCE');
export function submitPerformance(
  checklist: string,
  created: string,
  tag?: string
) {
  return dispatchGenericRequest(
    SUBMIT_PERFORMANCE,
    '/api/marksheets/performance-records/',
    'POST',
    { checklist, created },
    tag,
    { itemId: checklist }
  );
}

export const LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE = makeAsyncActionSet(
  'LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE'
);
export function learningOutcomeBlockUpdateMultiple(
  items: ReadonlyArray<ILearningOutcomeBlockUpdate>,
  tag?: string
) {
  return dispatchGenericRequest(
    LEARNING_OUTCOME_BLOCK_UPDATE_MULTIPLE,
    '/api/marksheets/learning-outcome-blocks/update-multiple/',
    'POST',
    items,
    tag,
    {}
  );
}

export const LEARNING_OUTCOME_BLOCK_ADD_COMMENT = makeAsyncActionSet(
  'LEARNING_OUTCOME_BLOCK_ADD_COMMENT'
);
export function addLearningOutcomeBlockComment(
  data: ILearningOutcomeBlockNewComment,
  tag?: string
) {
  return dispatchGenericRequest(
    LEARNING_OUTCOME_BLOCK_ADD_COMMENT,
    '/api/marksheets/learning-outcome-block-comments/',
    'POST',
    data,
    tag,
    {}
  );
}
