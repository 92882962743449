import { Alert, Column, Row, Well } from '@dabapps/roe';
import { AxiosPromise, AxiosResponse } from 'axios';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import {
  UPLOAD_BULK_USER_IMPORT,
  uploadBulkUserImport,
} from '../../actions/bulk-user-import';
import { IStore } from '../../store/';
import { IBulkUpload } from '../../store/data-types/bulk-upload';
import { IProfile } from '../../store/data-types/profile';
import FileUpload from '../files/file-upload';
import BulkImportErrors from './bulk-import-errors';

import Term from '../terminology';

const { IconInfoCircle } = FontAwesome;

interface IExternalProps {
  importId: string;
  importObject: IBulkUpload | null;
  onUploaded(): void;
}

interface IProps extends IExternalProps {
  profile: IProfile;
  uploadBulkUserImport(importId: string, file: File): AxiosPromise;
}

export class AddStudents extends React.PureComponent<IProps, void> {
  public render() {
    const { onUploaded, importId, profile } = this.props;

    return (
      <div className="add-students">
        <Row>
          <Column xs={12}>
            <ul className="progress-tracker">
              <li className="active">
                <span className="number">1</span> Add <Term>Students</Term>
              </li>
              <li>
                <span className="number">2</span> Column types
              </li>
              <li>
                <span className="number">3</span> Review
              </li>
              <li>
                <span className="number">4</span> Finished
              </li>
            </ul>
          </Column>
        </Row>

        <Row>
          <Column xs={12}>
            <p>
              <strong>Please note:</strong> The CSV file should contain the
              following fields for each <Term>Student</Term>:
            </p>
            <p>
              <strong>
                LearnerID, VTCTCentreCode, Title (Optional), Username,
                GivenName, FamilyName, DateOfBirth (YYYY-MM-DD) (Optional),
                TemporaryPassword, Qualification (Optional), ULN (Optional),
                MiddleName (Optional), PhoneNumber (Optional), Gender
                (Optional), Email (Optional)
              </strong>
            </p>
            <p>
              If you only have <strong>Email</strong>, set it as the{' '}
              <strong>Username</strong> column on the next screen.
            </p>
            <p>
              <a href="/static/example.csv" download="example.csv">
                Download example CSV template.
              </a>
            </p>

            {this.renderErrorsIfAny()}

            <Well className="margin-vertical-base">
              <FileUpload
                resetRequestStateOnUnmount
                multiple={false}
                ownerUserId={profile.id}
                uploadFiles={(files: List<File>) => {
                  const file = files.first();
                  if (!file) {
                    return new Promise<AxiosResponse | null>(() => null);
                  }
                  return this.props
                    .uploadBulkUserImport(importId, file)
                    .then(() => {
                      onUploaded();
                    });
                }}
                uploadActionSet={UPLOAD_BULK_USER_IMPORT}
              />
            </Well>

            <Alert className="helper-text text-align-left">
              <h3>
                <IconInfoCircle className="icon-small margin-bottom-small margin-right-base" />What
                happens when I've imported users?
              </h3>
              <p>
                <strong>If an email address is provided for users</strong>, they
                will be emailed a link to log into Manage, along with their
                password, which they will have to change on login.
              </p>
              <p>
                <strong>
                  If they haven't an email address and a username is provided in
                  your CSV
                </strong>, then you can give them that username along with their
                password which they will have to change when they first log in.
              </p>
            </Alert>
          </Column>
        </Row>
      </div>
    );
  }

  private renderErrorsIfAny() {
    // NOTE: FIX_DATA_ISSUES state has its own error handling and displaying code.
    const { importObject } = this.props;

    if (
      importObject &&
      importObject.state !== 'FIX_DATA_ISSUES' &&
      importObject.processing_errors &&
      _.values(importObject.processing_errors).length > 0
    ) {
      return (
        <BulkImportErrors processingErrors={importObject.processing_errors} />
      );
    }
  }
}

function mapStateToProps(
  { profile }: IStore,
  { onUploaded, importId }: IExternalProps
) {
  return {
    importId,
    onUploaded,
    profile,
  };
}

export default connect(mapStateToProps, { uploadBulkUserImport })(AddStudents);
