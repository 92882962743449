import { Button } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

import { setMenuDisplay } from '../../actions/dashboard';
import { IStore } from '../../store';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';

const { IconTimes } = FontAwesome;

interface IExternalProps {
  courses: List<IDashboardQualificationOption>;
  setQualification(event: React.MouseEvent<HTMLAnchorElement>): void;
}

interface IStateProps {
  isShowingMenu: boolean;
}

interface IDispatchProps {
  setMenuDisplay(isShowing: boolean): void;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class QualificationMenu extends React.PureComponent<IProps, void> {
  public render() {
    const { courses, isShowingMenu } = this.props;
    return (
      <div
        className="qualification-picker position-relative display-inline-block"
        onMouseEnter={this.showCoursesMenu}
        onMouseLeave={this.hideCoursesMenu}
      >
        <Button className="primary" onClick={this.showCoursesMenu}>
          View More...
        </Button>
        {isShowingMenu && (
          <div className="qualification-picker-dropdown">
            <div className="padding-base position-relative">
              <button className="close-button" onClick={this.hideCoursesMenu}>
                <IconTimes />
              </button>
              <ul>
                {this.props.courses.map(course => (
                  <li key={course.id}>
                    <a
                      href=""
                      id={course.id}
                      onClick={this.props.setQualification}
                    >
                      {course.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }

  private showCoursesMenu = () => {
    this.props.setMenuDisplay(true);
  };

  private hideCoursesMenu = () => {
    this.props.setMenuDisplay(false);
  };
}

function mapStateToProps(
  { dashboardQualifications: { isShowingMenu } }: IStore,
  props: IExternalProps
) {
  return {
    ...props,
    isShowingMenu,
  };
}

export default connect(mapStateToProps, {
  setMenuDisplay,
})(QualificationMenu);
