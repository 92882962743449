import * as React from 'react';

import { Button, FormGroup, InputGroup, InputGroupAddon } from '@dabapps/roe';
import { List, Map } from 'immutable';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  doesUserRoleSupersede,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  ROLES_WITH_CENTRES,
  ROLES_WITH_EXAM_BOARDS,
  STUDENT,
  TEACHER,
  userCanSeeRole,
} from '../../permissions';
import { IStore } from '../../store';
import { IExamBoardRecord } from '../../store/data-types';
import { ICentre } from '../../store/data-types/centres';
import { IProfile, TRole } from '../../store/data-types/profile';
import {
  IRoleRecord,
  RoleDataRecord,
  RoleRecord,
} from '../../store/data-types/roles';
import { terminologyFromProfile } from '../terminology';

const { IconPlus, IconTimes } = FontAwesome;

export interface ISelectOption {
  key: TRole;
  label: string;
}

export const USER_ROLES = List<ISelectOption>([
  { key: DA_ADMIN, label: 'Admin User' },
  { key: DA_SUPPORT, label: 'Support User' },
  { key: CENTRE_ADMIN, label: 'Centre Admin' },
  { key: EXTERNAL_QUALITY_ASSURER, label: 'External Quality Assurer' },
  { key: INTERNAL_QUALITY_ASSURER, label: 'Internal Quality Assurer' },
  { key: TEACHER, label: 'Teacher' },
  { key: STUDENT, label: 'Student' },
]);

interface IExternalProps {
  loading: boolean;
  value?: TRole;
  className?: string;
  changeRole(role?: TRole): void;
}
interface IProps extends IExternalProps {
  profile: IProfile;
}

export class RoleFilter extends React.PureComponent<IProps, void> {
  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const { profile, changeRole, loading, value, className } = this.props;

    const roleOptions = USER_ROLES.filter(role =>
      userCanSeeRole(profile, role.key)
    );

    return (
      <FormGroup block>
        <label>Role</label>
        <select onChange={this.onChange} disabled={loading} value={value}>
          <option value="ALL">All</option>
          {roleOptions.map(each => (
            <option key={each.key} value={each.key}>
              {terminologyFromProfile(profile, each.label)}
            </option>
          ))}
        </select>
      </FormGroup>
    );
  }

  private onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    return this.props.changeRole(
      event.target.value ? event.target.value as TRole : undefined
    );
  }
}

function mapStateToProps({ profile }: IStore, props: IExternalProps): IProps {
  return {
    ...props,
    profile,
  };
}

export default connect(mapStateToProps)(RoleFilter);
