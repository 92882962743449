import * as React from 'react';

import { Button, Modal, ModalFooter, ModalHeader } from '@dabapps/roe';
import { List } from 'immutable';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { TActionAny, Thunk } from '../../requests/types';
const { IconInfoCircle } = FontAwesome;
import { uploadAssetsAndReloadAssets } from '../../actions';
import { closeModal } from '../../actions/modals';
import { IStore } from '../../store';
import FileUpload, { TAfterSuccessfulUpload } from '../files/file-upload';
import ModalCloseIcon from '../modals/modal-close-icon';

interface IAddFilesModalExternalProps {
  ownerUserId: string;
  afterSuccessfulUpload?: TAfterSuccessfulUpload;
  info?: string;
}

interface IAddFilesModalProps extends IAddFilesModalExternalProps {
  closeModal(): void;
}

export class AddFilesModal extends React.PureComponent<
  IAddFilesModalProps,
  void
> {
  public render() {
    return (
      <Modal onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          <p>Upload new files</p>
        </ModalHeader>
        {this.props.info && (
          <p>
            <IconInfoCircle className="icon-small" />
            {this.props.info}
          </p>
        )}
        <FileUpload
          resetRequestStateOnUnmount
          ownerUserId={this.props.ownerUserId}
          afterSuccessfulUpload={this.props.afterSuccessfulUpload}
        />
        <ModalFooter>
          <p>
            <Button className="primary" onClick={() => this.props.closeModal()}>
              Close
            </Button>
          </p>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (store: IStore, props: IAddFilesModalExternalProps) =>
  props;

export default connect(mapStateToProps, { closeModal })(AddFilesModal);
