import { Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { Link } from 'react-router';
import { IJournalEntry } from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import { getDaysAgo } from '../../utils';
import NewsFeedItem from '../news-feed-item';
import JournalInfo from './journal-info';

interface IJournalFeedItemProps {
  currentUserId: string;
  journalEntry: IJournalEntry;
  canEditPosts: boolean;
  profile: IProfile;
  isClassPostFeed: boolean;
  isViewingUnderStudentContext?: boolean;
}

function getUpdated(entry: IJournalEntry): string {
  return `(updated ${getDaysAgo(entry.modified || entry.created)} days ago)`;
}

function getJournalUrl(
  entry: IJournalEntry,
  isViewingUnderStudentContext: boolean = false
) {
  const baseJournalUrl = `/journal/${entry.id}/`;
  return isViewingUnderStudentContext
    ? `/users/student/${entry.user.id}${baseJournalUrl}`
    : baseJournalUrl;
}

const JournalTitle = ({
  journalEntry,
  isViewingUnderStudentContext,
}: {
  journalEntry: IJournalEntry;
  isViewingUnderStudentContext?: boolean;
}) => (
  <span>
    <Link to={getJournalUrl(journalEntry, isViewingUnderStudentContext)}>
      {journalEntry.title}
    </Link>
    <span className="info"> {getUpdated(journalEntry)}</span>
  </span>
);

export class JournalFeedItem extends React.Component<
  IJournalFeedItemProps,
  void
> {
  public render() {
    const {
      currentUserId,
      canEditPosts,
      journalEntry,
      profile,
      isClassPostFeed,
      isViewingUnderStudentContext,
    } = this.props;

    const journalDetailUrl = getJournalUrl(
      journalEntry,
      isViewingUnderStudentContext
    );

    return (
      <NewsFeedItem
        heading={
          <JournalTitle
            journalEntry={journalEntry}
            isViewingUnderStudentContext={isViewingUnderStudentContext}
          />
        }
        type="text"
      >
        <Row>
          <Column xs={12} sm={10}>
            {currentUserId === journalEntry.user.id &&
              canEditPosts &&
              journalEntry.can_be_modified_by_owner && (
                <p>
                  <Link to={`${journalDetailUrl}edit/`}>Edit</Link>
                </p>
              )}
            <div
              className="journal-content-container"
              dangerouslySetInnerHTML={{ __html: journalEntry.content }}
            />
            <span className="read-more">
              <Link to={journalDetailUrl}>Read More</Link>
            </span>
          </Column>
          <JournalInfo
            journalEntry={journalEntry}
            profile={profile}
            hideTaskLink={isClassPostFeed}
            isViewingUnderStudentContext={isViewingUnderStudentContext}
          />
        </Row>
      </NewsFeedItem>
    );
  }
}

export default JournalFeedItem;
