import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  ModalBody,
  SpacedGroup,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Well,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { fromJS, List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import {
  SUBMIT_PERFORMANCE,
  submitPerformance,
} from '../../../actions/marksheet';
import { closeModal, openModal } from '../../../actions/modals';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IChecklistRecord } from '../../../store/data-types/marksheets';
import { IUserTaskRecord, STATUS } from '../../../store/data-types/tasks';
import { formatDateTime, FormErrors, getOrdinal } from '../../../utils';
import ConfirmModal from '../../modals/confirm-modal';
import SetPerformanceDateForm, {
  FORM_NAME as SET_PERFORMANCE_DATE,
} from './performance-date-form';

import * as moment from 'moment';

import { formValueSelector } from 'redux-form';

const CHECKLIST = 'CHECKLIST';

interface IExternalProps {
  isTeacher: boolean;
  selfMark: boolean;
  checklist: IChecklistRecord;
  task: IUserTaskRecord;
  performanceDate?: any;
}
interface IProps extends IExternalProps {
  performanceDate: any;
  performanceErrors: FormErrors | undefined;
  submittingPerformance: boolean;
  submitPerformance(checklistId: string, created: string, tag?: string): void;
  closeModal(): void;
  openModal(component: React.ReactNode): void;
}

export class PerformanceRecords extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.submitPerformanceClick = this.submitPerformanceClick.bind(this);
  }
  public render() {
    const {
      selfMark,
      checklist,
      isTeacher,
      submittingPerformance,
      performanceErrors,
      task,
    } = this.props;
    return (
      <TableBody>
        <TableRow>
          <TableHeader colSpan={(selfMark ? 1 : 2) + 3}>
            <h3 className="strand-title">{checklist.strand.title}</h3>
          </TableHeader>
        </TableRow>
        {Boolean(checklist.strand.performance_record_count) && (
          <TableRow>
            <TableCell colSpan={(selfMark ? 1 : 2) + 3}>
              <Well>
                <SpacedGroup className="clearfix">
                  <h4 className="font-size-large float-left">
                    {checklist.performance_records.count()} Recorded
                    Performances (minimum of{' '}
                    {checklist.strand.performance_record_count} required)
                  </h4>
                  {!task.class_task.task_class.is_archived &&
                    isTeacher &&
                    _.contains(
                      [STATUS.IN_PROGRESS, STATUS.AWAITING_MARKING],
                      task.status
                    ) && (
                      <Button
                        className="primary float-left md-float-right display-block md-display-inline-block margin-top-large"
                        disabled={submittingPerformance}
                        onClick={this.submitPerformanceClick}
                      >
                        Record{' '}
                        {getOrdinal(
                          checklist.performance_records.count() + 1
                        )}{' '}
                        performance
                      </Button>
                    )}
                </SpacedGroup>
                {performanceErrors && (
                  <Alert className="error">
                    {fromJS(performanceErrors)
                      .get('checklist', List<string>())
                      .map((error: string) => <p key={error}>{error}</p>)}
                  </Alert>
                )}
                {checklist.performance_records.count() ? (
                  <ul>
                    {checklist.performance_records.map(performance => (
                      <li
                        key={
                          performance.created.format() +
                          performance.created_by.id
                        }
                      >
                        <strong>
                          Recorded by {performance.created_by.name} on{' '}
                          {formatDateTime(performance.created)}
                        </strong>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="info">No recorded performances.</p>
                )}
              </Well>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  private submitPerformanceClick() {
    const { checklist, task } = this.props;
    this.props.openModal(
      <ConfirmModal
        closeModal={this.props.closeModal}
        title="Record Performance"
        body={
          <ModalBody>
            <p>
              You are about to record a performance record for{' '}
              {task.class_task.component.title} for{' '}
              {narrowToRecord(task.user).osms_data.name} - is this correct?
            </p>
            <SetPerformanceDateForm initialValues={{ created: moment.utc() }} />
          </ModalBody>
        }
        okayLabel="Confirm"
        onCancelClick={this.props.closeModal}
        onOkayClick={() => {
          this.props.closeModal();
          const { performanceDate } = this.props;
          const dateuse =
            performanceDate && 'toISOString' in performanceDate
              ? performanceDate
              : moment.utc();
          this.props.submitPerformance(
            checklist.id,
            dateuse.toISOString(),
            CHECKLIST
          );
        }}
      />
    );
  }
}

function mapStateToProps(store: IStore, props: IExternalProps) {
  const { responses } = store;
  return {
    ...props,
    performanceErrors: getFormErrors(responses, SUBMIT_PERFORMANCE, CHECKLIST),
    submittingPerformance: isPending(responses, SUBMIT_PERFORMANCE, CHECKLIST),
    performanceDate: formValueSelector(SET_PERFORMANCE_DATE)(store, 'created'),
  };
}

export default connect(mapStateToProps, {
  submitPerformance,
  closeModal,
  openModal,
})(PerformanceRecords);
