import * as React from 'react';

import { FormGroup, InputGroup } from '@dabapps/roe';
import { FormErrors } from '../../utils';
import { IInputWrapperProps } from '../centres/searchable-centre-input-wrapper';

export default class UserListCentreSearchWrapper extends React.PureComponent<
  IInputWrapperProps,
  void
> {
  public render() {
    const { children } = this.props;
    return (
      <div className="form-group block searchable-centre-switcher">
        <label>Centre</label>
        <div className="input-wrapper">{children}</div>
      </div>
    );
  }
}
