import {
  anyPending,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Modal, ModalFooter, ModalHeader } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import {
  IClassTaskUnitData,
  IUpdateUnitData,
  UPDATE_UNIT,
  updateUnit,
} from '../../../actions/qualifications';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import {
  IClassTaskRecord,
  IUnitManagementRecord,
} from '../../../store/data-types/tasks';
import { formatDateForBackend, FormErrors } from '../../../utils';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import ManageUnitForm, { IClassTaskDates } from './manage-unit-form';

const { actions: { getAllCollection } } = collectionsModule;

interface IFormData {
  assignees: Set<string>;
  class_task_dates: IClassTaskDates;
}
function getInitialValues(
  unit: IUnitManagementRecord,
  classTasks: List<IClassTaskRecord>
): IFormData {
  return {
    assignees: unit.assignees.map(each => each.id).toSet(),
    class_task_dates: classTasks
      .toKeyedSeq()
      .mapEntries(entry => [
        entry[1].id,
        {
          start_date: formatDateForBackend(entry[1].start_date),
          end_date: formatDateForBackend(entry[1].end_date),
          title: entry[1].component.title,
        },
      ])
      .toMap(),
  };
}

const MANAGE_UNIT = 'MANAGE_UNIT';
interface IManageUnitModalExternalProps {
  unit: IUnitManagementRecord;
  qualification: IQualificationRecord;
  taskClass: IClassRecord;
  onAfterUpdate(): void;
}
interface IManageUnitModalInternalProps extends IManageUnitModalExternalProps {
  updating: boolean;
  classTasks: List<IClassTaskRecord>;
  formErrors: FormErrors;
  loading: boolean;
  closeModal(): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  updateUnit(
    unitId: string,
    qualification: string,
    taskClass: string,
    data: IUpdateUnitData
  ): AxiosPromise;
}
export class ManageUnitModal extends React.PureComponent<
  IManageUnitModalInternalProps,
  void
> {
  public constructor(props: IManageUnitModalInternalProps) {
    super(props);
    this.updateUnit = this.updateUnit.bind(this);
  }

  public componentWillMount() {
    const { qualification, unit, taskClass } = this.props;
    this.props.getAllCollection(
      'tasks/class',
      {
        filters: Map({
          qualification: qualification.id,
          unit: unit.id,
          task_class: taskClass.id,
        }),
      },
      MANAGE_UNIT
    );
  }

  public render() {
    const {
      updating,
      unit,
      loading,
      classTasks,
      qualification,
      taskClass,
      formErrors,
    } = this.props;
    if (loading) {
      return <Loading />;
    }
    return (
      <Modal onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          <h3>
            Manage <strong>{unit.title}</strong> for{' '}
            <strong>{taskClass.name}</strong>.
          </h3>
        </ModalHeader>
        {unit.about && (
          <div
            className="info margin-vertical-large"
            dangerouslySetInnerHTML={{ __html: unit.about }}
          />
        )}
        <ManageUnitForm
          formErrors={formErrors}
          unitAssignees={unit.assignees}
          students={narrowToRecordArray(taskClass.students)}
          loading={updating}
          is_mandatory={unit.is_mandatory}
          minStart={qualification.start_date}
          maxEnd={qualification.end_date}
          initialValues={getInitialValues(unit, classTasks)}
          onSubmit={this.updateUnit.bind(this)}
        />
      </Modal>
    );
  }

  private updateUnit(data: IFormData) {
    const { unit, taskClass, qualification } = this.props;
    const transformedDataWithoutAssignees: IUpdateUnitData = {
      class_tasks: data.class_task_dates
        .map((value, key) => ({
          id: key,
          start_date: value.start_date,
          end_date: value.end_date,
        }))
        .toArray(),
    };
    const transformedData: IUpdateUnitData = unit.is_mandatory
      ? transformedDataWithoutAssignees
      : {
          ...transformedDataWithoutAssignees,
          assignees: data.assignees.toArray(),
        };
    this.props
      .updateUnit(unit.id, qualification.id, taskClass.id, transformedData)
      .then(() => {
        this.props.onAfterUpdate();
        this.props.closeModal();
      })
      .catch(() => null);
  }
}
function mapStateToProps(
  { collectionsOld, responses }: IStore,
  props: IManageUnitModalExternalProps
) {
  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, 'tasks/class'),
    updating: isPending(responses, UPDATE_UNIT),
    formErrors: getFormErrors(responses, UPDATE_UNIT),
    classTasks: getCollectionItems(
      collectionsOld.get('tasks/class'),
      MANAGE_UNIT
    ),
  };
}
export default connect(mapStateToProps, {
  closeModal,
  updateUnit,
  getAllCollection,
})(ManageUnitModal);
