import * as React from 'react';

import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise, AxiosResponse } from 'axios';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { closeModal } from '../../actions/modals';
import {
  UPLOAD_CENTRE_PICTURE,
  uploadCentrePicture,
} from '../../actions/profile';
import { ICollectionOptions } from '../../collections/types';
import { LOAD_ITEM, UPDATE_ITEM } from '../../items/actions';
import { collectionsModule } from '../../reducers/collections';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import { IClassRecord } from '../../store/data-types/classes';
import { IProfile } from '../../store/data-types/profile';
import FileUpload from '../files/file-upload';
import Loading from '../loading';
import ModalCloseIcon from '../modals/modal-close-icon';
import { CLASS_DETAIL_TEACHER } from './class-detail-teacher';

const { actions: { getAllCollection } } = collectionsModule;
const { actions: { patchItem } } = itemsModule;

interface IExternalProps {
  studentsToRemove: ReadonlyArray<string>;
  group: IClassRecord;
}
interface IPropsWithoutActions extends IExternalProps {
  loading: boolean;
}
interface IProps extends IPropsWithoutActions {
  getAllCollection: typeof getAllCollection;
  closeModal(): void;
  patchItem(type: keyof IItemsState, id: string, data: any): AxiosPromise;
}

export class RemoveStudentsConfirmModal extends React.PureComponent<
  IProps,
  void
> {
  public constructor(props: IProps) {
    super(props);
    this.updateStudents = this.updateStudents.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public render() {
    const { loading, studentsToRemove, group } = this.props;
    const students = narrowToRecordArray(group.students);
    return (
      <Modal onClickOutside={() => this.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.closeModal()} />
          <p>Are you sure you want to remove students from {group.name}?</p>
        </ModalHeader>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <p>The following students will be removed:</p>
            <ul>
              {studentsToRemove.map(each => {
                const match = students.find(student => student.id === each);
                return (
                  <li key={each}>
                    {match ? match.osms_data.name : 'Unknown student'}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <ModalFooter>
          <p>
            <SpacedGroup>
              <Button disabled={loading} onClick={this.closeModal}>
                Cancel
              </Button>
              <Button
                className="error"
                disabled={loading}
                onClick={this.updateStudents}
              >
                Remove
              </Button>
            </SpacedGroup>
          </p>
        </ModalFooter>
      </Modal>
    );
  }

  private updateStudents() {
    const { group, studentsToRemove } = this.props;
    const students = narrowToRecordArray(group.students);
    const submissionData = {
      students: students
        .filter(each => !_.contains(studentsToRemove, each.id))
        .map(each => each.id),
    };
    this.props.patchItem('classes', group.id, submissionData).then(() => {
      this.props.closeModal();
      const filters = Map({
        class_id: group.id,
      });
      this.props.getAllCollection(
        'classes/student-details',
        { filters },
        CLASS_DETAIL_TEACHER
      );
    });
  }

  private closeModal() {
    const { loading } = this.props;

    if (loading) {
      return null;
    }

    return this.props.closeModal();
  }
}

function mapStateToProps(
  { responses, profile }: IStore,
  props: IExternalProps
): IPropsWithoutActions {
  return {
    loading: isPending(responses, UPDATE_ITEM, 'classes'),
    ...props,
  };
}

export default connect(mapStateToProps, {
  closeModal,
  patchItem,
  getAllCollection,
})(RemoveStudentsConfirmModal);
