import { IExamSpecRecord } from '../../../store/data-types';
import { ICentre } from '../../../store/data-types/centres';

export function getCourseWorkProgressOverviewFor(
  prefix: string,
  centre: ICentre | null,
  examSpec: IExamSpecRecord | null
) {
  if (centre && examSpec) {
    return `${prefix} for ${centre.osms_data.name} - ${examSpec.name}`;
  }
  return prefix;
}
