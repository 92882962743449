import * as classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router';

interface IHrefProps {
  href?: string;
}

export interface IProps extends IHrefProps {
  matches?: RegExp;
  pathname?: string;
}

const MaybeLink: React.SFC<IHrefProps> = ({ href, children }) =>
  href ? <Link to={href}>{children}</Link> : <span>{children}</span>;

const NavItem: React.SFC<IProps & React.HTMLAttributes<HTMLLIElement>> = ({
  href,
  pathname,
  matches,
  children,
  className,
  ...remainingProps,
}) => {
  const hrefMatches = href === pathname;
  const matchesMatches =
    typeof pathname !== 'undefined' && matches && matches.test(pathname);
  const activePath = href && (hrefMatches || matchesMatches);

  return (
    <li
      {...remainingProps}
      className={classNames('nav-item', activePath && 'active', className)}
    >
      <MaybeLink href={href}>{children}</MaybeLink>
    </li>
  );
};

export default NavItem;
