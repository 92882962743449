import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { closeFlyout } from '../../actions/';
import { IStore } from '../../store/';

const { IconTimes } = FontAwesome;

interface IProps {
  displayFlyout: boolean;
  closeFlyout(): void;
}

export class FlyoutMenu extends React.Component<
  IProps & React.HTMLProps<HTMLDivElement>,
  void
> {
  public render() {
    const {
      className,
      children,
      displayFlyout,
      closeFlyout,
      ...remainingProps,
    } = this.props;

    return (
      <CSSTransitionGroup
        transitionName="flyout-transition"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={200}
      >
        {displayFlyout ? (
          <div
            {...remainingProps}
            className={classNames('flyout-menu', className)}
          >
            <IconTimes className="close-button" onClick={closeFlyout} />
            {children}
          </div>
        ) : null}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = ({ displayFlyout }: IStore) => ({ displayFlyout });

export default connect(mapStateToProps, { closeFlyout })(FlyoutMenu);
