import { List, Map, Set } from 'immutable';
import {
  IProfile,
  // tslint:disable-next-line:no-unused-variable
  TRole,
} from '../store/data-types/profile';

export const STUDENT: TRole = 'STUDENT';
export const TEACHER: TRole = 'TEACHER';
export const CENTRE_ADMIN: TRole = 'CENTRE_ADMIN';
export const INTERNAL_QUALITY_ASSURER: TRole = 'INTERNAL_QUALITY_ASSURER';
export const EXTERNAL_QUALITY_ASSURER: TRole = 'EXTERNAL_QUALITY_ASSURER';
export const DA_SUPPORT: TRole = 'DA_SUPPORT';
export const DA_ADMIN: TRole = 'DA_ADMIN';

export const STUDENT_USERS = List<TRole>([STUDENT]);
export const ALL_CENTRE_STAFF_USERS = List<TRole>([TEACHER, CENTRE_ADMIN]);

export const ALL_ADMIN_USERS = List<TRole>([DA_ADMIN, DA_SUPPORT]);
export const ALL_CENTRE_USERS: List<TRole> = List()
  .concat(ALL_CENTRE_STAFF_USERS)
  .concat(STUDENT_USERS);

export const ALL_CLASS_USERS: List<TRole> = List().concat(ALL_CENTRE_USERS);

export const ALL_QA_USERS: List<TRole> = List<TRole>([
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
]);

export const ALL_USERS: ReadonlyArray<TRole> = [
  DA_ADMIN,
  DA_SUPPORT,
  CENTRE_ADMIN,
  TEACHER,
  STUDENT,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
];

export const ROLE_HEIRACHY = List<TRole>([
  DA_ADMIN,
  DA_SUPPORT,
  CENTRE_ADMIN,
  EXTERNAL_QUALITY_ASSURER,
  INTERNAL_QUALITY_ASSURER,
  TEACHER,
  STUDENT,
]);

export const ROLES_WITH_CENTRES = List<TRole>([
  CENTRE_ADMIN,
  TEACHER,
  STUDENT,
  INTERNAL_QUALITY_ASSURER,
]);

export const ROLES_WITH_EXAM_BOARDS = List<TRole>([DA_SUPPORT]);

export function doesRoleSupersedeOrMatch(superior: TRole, subordinate: TRole) {
  return ROLE_HEIRACHY.indexOf(superior) <= ROLE_HEIRACHY.indexOf(subordinate);
}

export function doesRoleSupersede(superior: TRole, subordinate: TRole) {
  return ROLE_HEIRACHY.indexOf(superior) < ROLE_HEIRACHY.indexOf(subordinate);
}

export function doesUserRoleSupersedeOrMatch(user: IProfile, role: TRole) {
  return user.current_role
    ? doesRoleSupersedeOrMatch(user.current_role, role)
    : false;
}

export function doesUserRoleSupersede(user: IProfile, role: TRole) {
  return user.current_role ? doesRoleSupersede(user.current_role, role) : false;
}

export function isSuperUser(user: IProfile) {
  return userHasRoles(user, Set.of(DA_ADMIN));
}

export function isSupportUser(user: IProfile) {
  return userHasRoles(user, Set.of(DA_SUPPORT));
}

export function isQualityUser(user: IProfile) {
  return userHasRoles(
    user,
    Set.of(INTERNAL_QUALITY_ASSURER, EXTERNAL_QUALITY_ASSURER)
  );
}

export function isIQAUser(user: IProfile) {
  return userHasRoles(user, Set.of(INTERNAL_QUALITY_ASSURER));
}

export function isEQAUser(user: IProfile) {
  return userHasRoles(user, Set.of(EXTERNAL_QUALITY_ASSURER));
}

export function canManageQualifications(user: IProfile) {
  return userHasRoles(
    user,
    Set()
      .concat(ALL_ADMIN_USERS)
      .concat(Set.of(TEACHER, CENTRE_ADMIN))
  );
}

export function canSeeTimeline(user: IProfile) {
  return userHasRoles(user, STUDENT_USERS.toSet());
}

export function canSeeMyTasks(user: IProfile) {
  return userHasRoles(user, ALL_CENTRE_USERS.toSet());
}

export function canCreateClass(user: IProfile) {
  return userHasRoles(
    user,
    List()
      .concat(ALL_ADMIN_USERS)
      .concat(List.of(CENTRE_ADMIN, TEACHER))
      .toSet()
  );
}

export function canSelectClassTeacher(user: IProfile) {
  return userHasRoles(
    user,
    List()
      .concat(ALL_ADMIN_USERS)
      .concat(List.of(CENTRE_ADMIN))
      .toSet()
  );
}

export function canViewCourseworkProgressOverview(user: IProfile) {
  return userHasRoles(
    user,
    List()
      .concat(ALL_ADMIN_USERS)
      .concat(ALL_CENTRE_STAFF_USERS)
      .toSet()
  );
}

export const CAN_BULK_IMPORT: Set<TRole> = Set.of(
  CENTRE_ADMIN,
  DA_SUPPORT,
  DA_ADMIN
);

export const CAN_MANAGE_EQAS = ALL_ADMIN_USERS.toSet();

export const CAN_GENERATE_REPORTS: Set<TRole> = Set.of(DA_SUPPORT, DA_ADMIN);
export function canGenerateReports(profile: IProfile) {
  return userHasRoles(profile, CAN_GENERATE_REPORTS);
}

export const CAN_ASSIGN_IQAS: Set<TRole> = Set.of(
  DA_ADMIN,
  DA_SUPPORT,
  CENTRE_ADMIN
);
export function canAssignIQAs(user: IProfile) {
  return userHasRoles(user, CAN_ASSIGN_IQAS);
}

export const CAN_VIEW_USERS: Set<TRole> = List()
  .concat(ALL_ADMIN_USERS)
  .concat(ALL_CENTRE_STAFF_USERS)
  .toSet();
export function canViewUsers(user: IProfile) {
  return userHasRoles(user, CAN_VIEW_USERS);
}

export function canManageStudentQuotas(user: IProfile) {
  return userHasRoles(user, ALL_ADMIN_USERS.toSet());
}

export const CAN_EXEMPT_ONE_TIME_FILE_SIZE_RESTRICTION: Set<TRole> = List()
  .concat(ALL_ADMIN_USERS)
  .concat(ALL_CENTRE_STAFF_USERS)
  .toSet();
export function canExemptOneTimeFileSizeRestriction(user: IProfile) {
  return userHasRoles(user, CAN_EXEMPT_ONE_TIME_FILE_SIZE_RESTRICTION);
}

export function canEditOwnRoles(user: IProfile): boolean {
  return userHasRoles(user, Set.of(CENTRE_ADMIN));
}

export function userHasRoles(user: IProfile, roles: Set<TRole>) {
  // Does the user have any of the roles
  return user.current_role ? roles.contains(user.current_role) : false;
}

const ROLE_CAN_SEE_ROLES = Map<TRole, Set<TRole>>({
  DA_ADMIN: List.of(
    DA_ADMIN,
    DA_SUPPORT,
    CENTRE_ADMIN,
    TEACHER,
    STUDENT,
    INTERNAL_QUALITY_ASSURER,
    EXTERNAL_QUALITY_ASSURER
  ).toSet(),
  DA_SUPPORT: List.of(
    CENTRE_ADMIN,
    TEACHER,
    STUDENT,
    INTERNAL_QUALITY_ASSURER,
    EXTERNAL_QUALITY_ASSURER
  ).toSet(),
  CENTRE_ADMIN: List.of(INTERNAL_QUALITY_ASSURER, TEACHER, STUDENT).toSet(),
  TEACHER: List.of(STUDENT).toSet(),
  EXTERNAL_QUALITY_ASSURER: Set<TRole>(),
  INTERNAL_QUALITY_ASSURER: Set<TRole>(),
  STUDENT: Set<TRole>(),
});

function getAllowedRoleCreateListForUser(user: IProfile): Set<TRole> {
  const allowedRolesForCurrentRole =
    (user.current_role && ROLE_CAN_SEE_ROLES.get(user.current_role)) ||
    Set<TRole>();

  if (
    !userHasRoles(user, ALL_ADMIN_USERS.toSet()) &&
    !currentCentreAllowsMembersToCreateLearners(user)
  ) {
    return allowedRolesForCurrentRole.filter(each => each !== STUDENT);
  }

  return allowedRolesForCurrentRole;
}

export function userCanCreateRole(user: IProfile, role: TRole): boolean {
  return getAllowedRoleCreateListForUser(user).contains(role);
}

export function userCanSeeRole(user: IProfile, role: TRole): boolean {
  return user.current_role
    ? ROLE_CAN_SEE_ROLES.get(user.current_role, Set<TRole>()).contains(role)
    : false;
}

export function currentCentreAllowsMembersToCreateLearners(
  user: IProfile
): boolean {
  if (!user.current_role) {
    return false;
  }
  if (ALL_ADMIN_USERS.contains(user.current_role)) {
    return true;
  }
  return user.current_centre
    ? Boolean(user.current_centre.members_can_create_learners)
    : false;
}

export function userCanCreateUsers(user: IProfile): boolean {
  return !getAllowedRoleCreateListForUser(user).isEmpty();
}

export function getHighestAuthorityRole(profile: IProfile): TRole | undefined {
  return ROLE_HEIRACHY.skipUntil(role => role === profile.current_role).first();
}

/*
  NOTE: Whilst EQAs must select a current centre to view items under the centre - they have their own
   dashboard that allows them to pick centres.
*/
export const ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE = Set.of(
  EXTERNAL_QUALITY_ASSURER,
  DA_ADMIN,
  DA_SUPPORT
);

export function matchesPath(currentPath: string | undefined, matcher: RegExp) {
  return currentPath ? matcher.test(currentPath) : false;
}

const MATCHES_SELECT_ROLE = /^\/select-role/;
export function isOnSelectRolePage(currentPath: string | undefined) {
  return matchesPath(currentPath, MATCHES_SELECT_ROLE);
}

const MATCHES_INACTIVE_USER = /^\/inactive-user/;
export function isOnInactiveUserPage(currentPath: string | undefined) {
  return matchesPath(currentPath, MATCHES_INACTIVE_USER);
}

export const MATCHES_UUID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

export const MATCHES_STUDENT_VIEW_BASE = new RegExp(
  `^\/users\/student\/${MATCHES_UUID_REGEX.source}\/`
);
export const MATCHES_STUDENT_VIEW = new RegExp(
  `${MATCHES_STUDENT_VIEW_BASE.source}.*`
);
export function isOnStudentViewPage(currentPath: string | undefined) {
  return matchesPath(currentPath, MATCHES_STUDENT_VIEW);
}

export const MATCHES_EXACT_STUDENT_VIEW_BASE = new RegExp(
  `${MATCHES_STUDENT_VIEW_BASE.source}$`
);
export function isOnStudentViewPageExact(currentPath: string | undefined) {
  return matchesPath(currentPath, MATCHES_EXACT_STUDENT_VIEW_BASE);
}

export function shouldRedirectToRoleSelection(
  currentPath: string | undefined,
  user: IProfile
): boolean {
  const isUserLoggedIn = Boolean(user.id);
  const isOnAllowedRoute =
    isOnSelectRolePage(currentPath) || isOnInactiveUserPage(currentPath);
  const hasCurrentRole = Boolean(user.current_role);
  const hasCurrentCentre = Boolean(user.current_centre);
  const currentRoleRequiresCentre = !userHasRoles(
    user,
    ROLES_THAT_DONT_NEED_A_CURRENT_CENTRE
  );

  if (!isUserLoggedIn || isOnAllowedRoute) {
    return false;
  }

  if (!hasCurrentRole || (!hasCurrentCentre && currentRoleRequiresCentre)) {
    return true;
  }

  return false;
}

export function getViewingStudentIdIfRole(
  profile: IProfile,
  id?: string
): string | undefined {
  if (userHasRoles(profile, Set.of(CENTRE_ADMIN, TEACHER)) && id) {
    return id;
  }
}
