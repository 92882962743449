import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';
const { IconCheckCircle, IconInfoCircle } = FontAwesome;
import { Alert, Column, Row } from '@dabapps/roe';

export default class Finished extends React.PureComponent<{}, void> {
  public render() {
    return (
      <div className="finished">
        <Row>
          <Column xs={12}>
            <h3>
              <IconCheckCircle className="icon icon-large margin-right-base" />Import
              complete - <Link to={'/users/'}>Back to Users List.</Link>
            </h3>

            <Alert className="helper-text text-align-left">
              <h3>
                <IconInfoCircle className="icon-small margin-bottom-small margin-right-base" />What
                happens next?
              </h3>
              <p>
                <strong>If your users have email addresses</strong>, they will
                be emailed a link to log into Manage, along with their password,
                which they will have to change on login.
              </p>
              <p>
                <strong>
                  If they haven't an email address and a username is provided
                </strong>, then you can give them that username along with their
                password which they will have to change when they first log in.
              </p>
            </Alert>
          </Column>
        </Row>
      </div>
    );
  }
}
