import {
  hasFailed,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as ReactSelect from 'react-select';
import * as _ from 'underscore';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { DispatchCallback } from '../../requests/types';

import {
  GET_VIEWING_STUDENT,
  getViewingStudent,
  toggleStudentPicker,
} from '../../actions/users';
import { REQUEST_RATE_MS } from '../../constants';
import { isOnStudentViewPageExact } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IViewingStudent } from '../../reducers/users';
import { IStore } from '../../store';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import { IDashboardQualificationOption } from '../../store/data-types/qualifications';
import { DASHBOARD_QUALIFICATION_OPTIONS } from '../dashboard/dashboard-qualifications';
import IconCaretDown from '../icon-caret-down';
import IconSearch from '../icon-search';
import ImageHolder from '../image-holder';

import { FontAwesome } from 'react-inline-icons';

const { IconUser } = FontAwesome;

const { actions: { getCollection } } = collectionsModule;

const STUDENT_OPTIONS_TYPE = 'users/minimal';
const STUDENT_OPTIONS_TAG = 'viewing-student-options';
const PAGE_SIZE = 10;

interface IStudentOption {
  value: string;
  label?: string;
}

interface IExternalProps {
  studentId: string;
  pathName: string;
}

interface IStateProps {
  students: List<ISimpleUser>;
  viewingStudent?: IViewingStudent;
  isVisibleSelect: boolean;
  isLoadingStudentOptions: boolean;
  isLoadingStudent: boolean;
  hasFailed: boolean;
  courses: List<IDashboardQualificationOption>;
}

interface IDispatchProps {
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  getViewingStudent(
    studentId: string,
    showingCourses?: ReadonlyArray<string>
  ): void;
  push(path: string): void;
  toggleStudentPicker(): void;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class StudentPicker extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.getStudents = _.debounce(this.getStudents, REQUEST_RATE_MS);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.studentId !== prevProps.studentId) {
      const shouldReloadCourses =
        isOnStudentViewPageExact(this.props.pathName) &&
        isOnStudentViewPageExact(prevProps.pathName);
      // courses which must have their progress reloaded
      const showingCourses = shouldReloadCourses
        ? this.props.courses.map(course => course.id).toArray()
        : [];
      this.props.getViewingStudent(this.props.studentId, showingCourses);
    }
  }

  public componentWillMount() {
    this.props.getViewingStudent(this.props.studentId);
  }

  public render() {
    const {
      students,
      isVisibleSelect,
      viewingStudent,
      isLoadingStudentOptions,
      isLoadingStudent,
      hasFailed,
    } = this.props;
    const studentOptions: List<IStudentOption> = students.map(student => ({
      value: student.id,
      label: student.osms_data.name,
    }));
    return isLoadingStudent ? (
      <div className="student-picker student-picker-feedback is-loading-student">
        Loading student...
      </div>
    ) : hasFailed ? (
      <div className="student-picker student-picker-feedback error">
        Failed to load student.
      </div>
    ) : isVisibleSelect ? (
      <div className="student-picker">
        <IconSearch />
        <ReactSelect
          options={studentOptions.toArray()}
          onInputChange={this.getStudents}
          onChange={this.navigateToStudent}
          isLoading={isLoadingStudentOptions}
          placeholder="Search for student"
          onBlur={this.props.toggleStudentPicker}
          autofocus
        />
      </div>
    ) : viewingStudent ? (
      <div className="student-picker">
        <div className="student-picker-avatar">
          <IconUser />
        </div>
        <a className="student-picker-name" onClick={this.toggleStudentPicker}>
          {viewingStudent.name}
        </a>
        <IconCaretDown />
      </div>
    ) : null;
  }

  private navigateToStudent = (studentOption: IStudentOption) => {
    this.props.push(`/users/student/${studentOption.value}/`);
  };

  private toggleStudentPicker = () => {
    this.props.toggleStudentPicker();
    this.getStudents('');
  };

  private getStudents = (search: string) => {
    this.props.getCollection(
      STUDENT_OPTIONS_TYPE,
      {
        filters: Map({
          user_type: 'student',
        }),
        search,
        pageSize: PAGE_SIZE,
      },
      STUDENT_OPTIONS_TAG
    );
  };
}

function mapStateToProps(
  { collectionsOld, studentPicker, responses, routing }: IStore,
  props: IExternalProps
): IStateProps & IExternalProps {
  return {
    ...props,
    students: (getCollectionItems(
      collectionsOld[STUDENT_OPTIONS_TYPE],
      STUDENT_OPTIONS_TAG
    ) || List<ISimpleUser>()) as List<ISimpleUser>,
    courses: getCollectionItems(
      collectionsOld[DASHBOARD_QUALIFICATION_OPTIONS],
      DASHBOARD_QUALIFICATION_OPTIONS
    ),
    viewingStudent: studentPicker.viewingStudent,
    isVisibleSelect: studentPicker.isVisibleSelect,
    isLoadingStudentOptions: isPending(
      responses,
      GET_COLLECTION,
      STUDENT_OPTIONS_TYPE
    ),
    isLoadingStudent: isPending(responses, GET_VIEWING_STUDENT),
    hasFailed:
      hasFailed(responses, GET_VIEWING_STUDENT) ||
      hasFailed(responses, GET_COLLECTION, STUDENT_OPTIONS_TYPE),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getViewingStudent,
  push,
  toggleStudentPicker,
})(StudentPicker);
