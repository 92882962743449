import { Button, FormGroup, InputGroup } from '@dabapps/roe';
import * as React from 'react';
import { Field, FormProps, reduxForm } from 'redux-form';

export interface ISearchForm {
  search?: string;
}

interface IProps {
  loading: boolean;
  onSubmit(data: ISearchForm): void;
}

export class CentreSearch extends React.PureComponent<
  FormProps<ISearchForm, {}, {}> & IProps,
  void
> {
  public render() {
    const { loading } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit} className="centre-search-form">
        <FormGroup block>
          <label>Search</label>
          <InputGroup className="margin-none">
            <Field
              component="input"
              type="text"
              name="search"
              disabled={loading}
              placeholder="search for centres..."
              normalize={(value?: string) => (!value ? '' : value)}
            />
            <Button
              onClick={this.props.handleSubmit}
              className="width-auto"
              component="div"
            >
              Go
            </Button>
          </InputGroup>
        </FormGroup>
      </form>
    );
  }
}

export default reduxForm({ form: 'CENTRE_SEARCH' })(CentreSearch);
