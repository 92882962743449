import { ModalBody } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import { createSelector } from 'reselect';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import { ISimpleUser } from '../../../store/data-types/profile';

import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IStore } from '../../../store';
const { actions: { getAllCollection } } = collectionsModule;

type StudentOption = Readonly<{
  value: string;
  label: string;
  user: ISimpleUser;
}>;

interface ISelectStudentExternalProps {
  centre?: string;
  onSelect(user: ISimpleUser): void;
}

interface ISelectStudentConnectedProps {
  options: ReactSelect.Option[]; // Our legacy version of React-Select does not take generics here :(
}

interface ISelectStudentDispatchProps {
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag?: string
  ): void;
}

type IProps = ISelectStudentExternalProps &
  ISelectStudentConnectedProps &
  ISelectStudentDispatchProps;

const defaultFilters = Map({ user_type: 'student' });

export class SelectStudent extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const { options } = this.props;

    return (
      <ModalBody className="scrollable select-student">
        <ReactSelect
          placeholder="Start typing name"
          options={options}
          onChange={this.onChange}
        />
      </ModalBody>
    );
  }

  public componentDidMount() {
    const { centre } = this.props;
    const filters = centre
      ? defaultFilters.set('centre', centre)
      : defaultFilters;
    this.props.getAllCollection('users/minimal', { filters }, 'students');
  }

  private onChange(newValue: StudentOption) {
    this.props.onSelect(newValue.user);
  }
}

const emptyUsers = List<ISimpleUser>();
const usersSelector = (store: IStore) =>
  getCollectionItems(store.collectionsOld['users/minimal'], 'students') ||
  emptyUsers;
const optionsSelector = createSelector(usersSelector, users =>
  users.toArray().map<StudentOption>(each => ({
    value: each.id,
    label: each.osms_data.name || each.id,
    user: each,
  }))
);

function mapStateToProps(
  store: IStore,
  props: ISelectStudentExternalProps
): ISelectStudentExternalProps & ISelectStudentConnectedProps {
  return {
    ...props,
    options: optionsSelector(store),
  };
}

export default connect(mapStateToProps, { getAllCollection })(SelectStudent);
