import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Collapse } from '@dabapps/roe';
import * as React from 'react';
import { IComponent } from '../../../store/data-types/tasks';

interface IExternalProps {
  component: IComponent;
}

// tslint:disable-next-line:no-unused-variable
interface ISetProps {
  open: boolean;
}

type Props = IExternalProps & SetPropsInterface<ISetProps>;

export class Description extends React.PureComponent<Props, {}> {
  public render() {
    const { open, component } = this.props;

    return (
      <div>
        <Collapse open={open} maxCollapsedHeight={200} fadeOut>
          <p
            dangerouslySetInnerHTML={{
              __html: component.unit.about,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: component.about || '',
            }}
          />
        </Collapse>
        <p>
          <a onClick={this.onToggleCollapse}>{open ? 'Collapse' : 'Expand'}</a>
        </p>
      </div>
    );
  }

  private onToggleCollapse = () => {
    const { open } = this.props;

    this.props.setProps({
      open: !open,
    });
  };
}

const getInitialProps = () => ({ open: false });

export default withSetProps<ISetProps, IExternalProps>(getInitialProps)(
  Description
);
