import {
  hasFailed,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import * as React from 'react';

import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modals';
import { UPDATE_ITEM } from '../../items/actions';
import { IItemsState, itemsModule } from '../../reducers/items';
import { IStore } from '../../store';
import {
  IProfile,
  TUserInactiveStatusKey,
  TUserStatus,
} from '../../store/data-types/profile';
import ModalCloseIcon from '../modals/modal-close-icon';
const { actions: { patchItem } } = itemsModule;
import { getUserStatusActionText } from './toggle-status-button';

export const userStatusToggleMapping: {
  [P in TUserInactiveStatusKey]: Partial<{ [P in TUserStatus]: TUserStatus }>
} = {
  DISABLED: {
    ACTIVE: 'DISABLED',
    DISABLED: 'ACTIVE',
  },
  ARCHIVED: {
    ACTIVE: 'ARCHIVED',
    ARCHIVED: 'ACTIVE',
    DISABLED: 'ARCHIVED',
  },
};

interface IUserUpdate {
  status: TUserStatus | undefined;
}

interface IExternalProps {
  user: IProfile | null;
  inactiveStatusKey: TUserInactiveStatusKey;
  onComplete?(): void;
}

interface IProps extends IExternalProps {
  loading: boolean;
  failed: boolean;
  succeeded: boolean;
  resetRequestState: typeof resetRequestState;
  closeModal(): void;
  patchItem(
    type: keyof IItemsState,
    id: string,
    data: IUserUpdate
  ): AxiosPromise;
}

export class ToggleStatusModal extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.reset = this.reset.bind(this);
  }

  public componentWillMount() {
    this.reset();
  }

  public render() {
    const { loading, user, succeeded, failed, inactiveStatusKey } = this.props;
    const userDisplay = user
      ? `${user.osms_data.name} (${user.osms_data.username})`
      : '';
    const actionText = user
      ? getUserStatusActionText(inactiveStatusKey, user.status)
      : '';
    return (
      <Modal scrollable onClickOutside={this.closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.closeModal} />
          <p>{userDisplay}</p>
        </ModalHeader>
        <ModalBody>
          <Section>
            <h3>
              {actionText} {userDisplay}
            </h3>
            <div>
              {!succeeded && (
                <p>
                  Are you sure you want to{' '}
                  <strong>{actionText && actionText.toLowerCase()}</strong>{' '}
                  {userDisplay}?
                </p>
              )}
              {failed && (
                <Alert className="error">
                  <p>
                    Something went wrong: unable to{' '}
                    {actionText && actionText.toLowerCase()} {userDisplay}.
                  </p>
                </Alert>
              )}
              {succeeded && (
                <Alert className="success">
                  <p>
                    Successfully {actionText && actionText.toLowerCase()}d{' '}
                    {userDisplay}.
                  </p>
                </Alert>
              )}
            </div>
          </Section>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup className="display-block margin-vertical-large">
            {!succeeded && (
              <Button
                onClick={this.toggleStatus}
                className="error"
                disabled={loading}
              >
                {actionText}
              </Button>
            )}
            <Button onClick={this.closeModal} disabled={loading}>
              {succeeded ? 'Close' : 'Cancel'}
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </Modal>
    );
  }

  private toggleStatus() {
    const { user, onComplete, inactiveStatusKey } = this.props;
    if (user) {
      const patchPromise = this.props.patchItem('users', user.id, {
        status: userStatusToggleMapping[inactiveStatusKey][user.status],
      });
      if (onComplete) {
        patchPromise.then(() => onComplete());
      }
    }
  }

  private reset() {
    this.props.resetRequestState(UPDATE_ITEM, 'users');
  }

  private closeModal() {
    this.props.closeModal();
    this.reset();
  }
}

function mapStateToProps({ responses }: IStore, props: IExternalProps) {
  return {
    ...props,
    loading: isPending(responses, UPDATE_ITEM, 'users'),
    failed: hasFailed(responses, UPDATE_ITEM, 'users'),
    succeeded: hasSucceeded(responses, UPDATE_ITEM, 'users'),
  };
}

export default connect(mapStateToProps, {
  closeModal,
  patchItem,
  resetRequestState,
})(ToggleStatusModal);
