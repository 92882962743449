import { anyPending } from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Column,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { DataShape, Form, FormProps, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { closeModal } from '../../../actions/modals';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { UPDATE_ITEM } from '../../../items/actions';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store';
import {
  IJournalEntry,
  IJournalTaskData,
} from '../../../store/data-types/journals';
import { IProfile } from '../../../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../../../store/data-types/tasks';
import { makeUIStateHandler } from '../../../ui-state';
import GroupField from '../../forms/group-field';
import ItemListSelector from '../../modals/item-list-selector';
import ModalCloseIcon from '../../modals/modal-close-icon';
import SelectItemModal from '../../modals/select-item-modal';
import { default as Term, terminologyFromProfile } from '../../terminology';

const { actions: { getAllCollection } } = collectionsModule;

export const JOURNAL_ENTRY_TASKS_FORM = 'JournalEntryTasksForm';
export const JOURNAL_ENTRY_TASKS = 'JOURNAL_ENTRY_TASKS';

const SET_JOURNAL_TASKS_MODAL_SELECTED_TASK =
  'SET_JOURNAL_TASKS_MODAL_SELECTED_TASK';

const selectedTaskHandler = makeUIStateHandler(
  SET_JOURNAL_TASKS_MODAL_SELECTED_TASK,
  ''
);

interface ISelectJournalTasksModalExternalProps {
  profile: IProfile;
  onChangeTask(values: IJournalTaskData): void;
}

interface ISelectJournalTasksModalConnectedProps {
  tasks: List<IUserTaskRecord>;
  selectedTask: string | null;
  loading: boolean;
  realizedTask: IUserTaskRecord | null;
  journalItem: IJournalEntry | null;
}

interface ISelectJournalTasksModalActionProps {
  getAllCollection: typeof getAllCollection;
  closeModal(): void;
  setSelectedTask(task: string): void;
}

interface IFormValues {
  off_job_training_hours?: string;
}

type IProps = ISelectJournalTasksModalExternalProps &
  ISelectJournalTasksModalConnectedProps &
  ISelectJournalTasksModalActionProps &
  FormProps<DataShape, void, void>;

export class SelectJournalTasksModal extends React.PureComponent<IProps, void> {
  constructor(props: IProps) {
    super(props);
    this.onSelectTask = this.onSelectTask.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onDone = this.onDone.bind(this);
  }

  public componentDidMount() {
    const { journalItem, profile } = this.props;
    const filters = Map({
      owner__id: profile.id,
      status: STATUS.IN_PROGRESS,
    });

    this.props.getAllCollection('tasks', { filters }, JOURNAL_ENTRY_TASKS);
    this.props.setSelectedTask('');
  }

  public render() {
    const {
      tasks,
      loading,
      selectedTask,
      closeModal,
      onChangeTask,
      profile,
      realizedTask,
      handleSubmit,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit ? handleSubmit(this.onDone) : () => null}>
        <Modal onClickOutside={this.props.closeModal}>
          <ModalHeader>
            <ModalCloseIcon onClick={this.props.closeModal} />
            <p>
              Select <Term>Task</Term> to tag to your journal entry
            </p>
          </ModalHeader>

          <Row className="margin-top-large">
            <Column>
              <ItemListSelector
                items={tasks}
                loading={this.props.loading}
                actionText="select"
                noItemsMessage={`No ${terminologyFromProfile(
                  profile,
                  'Tasks'
                )}.`}
                action={this.onSelectTask}
                itemRenderer={(task: IUserTaskRecord) => (
                  <span>{task.class_task.component.title}</span>
                )}
                canPerformAction={!loading}
              />
            </Column>
          </Row>
          <Row className="margin-bottom-large">
            <Column>
              {realizedTask
                ? realizedTask.class_task.component.title
                : `No ${terminologyFromProfile(profile, 'Task')} selected`}
              {realizedTask && (
                <a className="float-right" onClick={this.onRemove}>
                  remove
                </a>
              )}
            </Column>
          </Row>
          {realizedTask &&
            realizedTask.class_task.component.off_job_training_percent && (
              <Row className="margin-bottom-large">
                <Column>
                  <GroupField
                    label={`Hours of ${realizedTask.class_task.component
                      .off_job_training_percent}% off the job training`}
                    name="off_job_training_hours"
                    type="number"
                    formErrors={{}}
                    disabled={loading}
                  />
                </Column>
              </Row>
            )}
          <ModalFooter>
            <p>
              <Button type="submit">Done</Button>
            </p>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }

  private onSelectTask(task: string) {
    this.props.setSelectedTask(task);
  }

  private onRemove() {
    this.props.setSelectedTask('');
  }

  private onDone(values: IFormValues) {
    const { selectedTask, realizedTask } = this.props;
    const { off_job_training_hours } = values;

    const hoursToSend =
      realizedTask && realizedTask.class_task.component.off_job_training_percent
        ? off_job_training_hours
        : null;

    this.props.onChangeTask({
      selectedTask,
      offJobTrainingHours: off_job_training_hours || null,
    });
    this.props.closeModal();
  }
}

const noTasks = List(); // Avoid recreating the list in the selector, as it breaks the cache;
const journalItemSelector = (store: IStore) => store.itemsOld.journals;
const selectedTaskSelector = (store: IStore) =>
  selectedTaskHandler.getValue(store.uiState);
const tasksSelector = (store: IStore) =>
  getCollectionItems(store.collectionsOld.get('tasks'), JOURNAL_ENTRY_TASKS) ||
  noTasks;
const realizedTaskSelector = createSelector(
  selectedTaskSelector,
  tasksSelector,
  (selectedTask, tasks) =>
    (selectedTask && tasks.find(task => task.id === selectedTask)) || null
);

function mapStateToProps(
  store: IStore,
  props: ISelectJournalTasksModalExternalProps &
    FormProps<DataShape, void, void>
): ISelectJournalTasksModalExternalProps &
  ISelectJournalTasksModalConnectedProps &
  FormProps<DataShape, void, void> {
  const { responses } = store;

  return {
    ...props,
    tasks: tasksSelector(store),
    selectedTask: selectedTaskSelector(store),
    realizedTask: realizedTaskSelector(store),
    journalItem: journalItemSelector(store),
    loading: anyPending(responses, [
      [UPDATE_ITEM, 'journals'],
      [GET_COLLECTION, 'tasks'],
    ]),
  };
}

const formified = reduxForm({
  form: JOURNAL_ENTRY_TASKS_FORM,
})(SelectJournalTasksModal);

export default connect(mapStateToProps, {
  closeModal,
  getAllCollection,
  setSelectedTask: selectedTaskHandler.setState,
})(formified);
