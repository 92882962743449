import * as React from 'react';

import { List, Set } from 'immutable';
import { Field, WrappedFieldProps } from 'redux-form';
import { IStore } from '../../../store';
import { removeDuplicateAndUndefinedValues } from '../../../utils';
import { IInputValue } from './tag-files-form';

export interface ITagFilesBlockInput extends WrappedFieldProps<IStore> {
  blockId: string;
  taskId: string;
}

export default class TagFilesBlockInput extends React.PureComponent<
  ITagFilesBlockInput,
  void
> {
  public constructor(props: ITagFilesBlockInput) {
    super(props);
    this.transformLearningBlockValue = this.transformLearningBlockValue.bind(
      this
    );
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const { blockId, input: { value } } = this.props;
    return (
      <input
        type="checkbox"
        checked={
          value.learning_outcome_blocks &&
          value.learning_outcome_blocks.indexOf(blockId) >= 0
        }
        onChange={this.onChange}
      />
    );
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.input.onChange(this.transformLearningBlockValue(event));
  }

  private transformLearningBlockValue(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { taskId, blockId, input } = this.props;
    const value: IInputValue = input.value;
    const { user_tasks = [], learning_outcome_blocks = [], id } = value;

    if (event.target.checked) {
      return {
        user_tasks: removeDuplicateAndUndefinedValues(
          user_tasks.concat(taskId)
        ),
        learning_outcome_blocks: removeDuplicateAndUndefinedValues(
          learning_outcome_blocks.concat(blockId)
        ),
        id,
      };
    }

    const learningOutcomeBlocks = removeDuplicateAndUndefinedValues(
      learning_outcome_blocks
    ).filter(block => blockId !== block);

    return {
      user_tasks,
      learning_outcome_blocks: learningOutcomeBlocks,
      id,
    };
  }
}
