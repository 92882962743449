import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';
import { List } from 'immutable';
import { IActionNoPayload, IActionWithPayload } from '../requests/types';
import { TStatus } from '../store/data-types/common';

export interface IBulkUpdateStatus {
  id: string;
  status: TStatus;
}
export const BULK_UPDATE_STATUS = makeAsyncActionSet('BULK_UPDATE_STATUS');
export function bulkUpdateStatus(tasks: List<IBulkUpdateStatus>, tag?: string) {
  return dispatchGenericRequest(
    BULK_UPDATE_STATUS,
    '/api/tasks/bulk-update-status/',
    'POST',
    tasks.toArray(),
    tag
  );
}

export const ALLOW_ANOTHER_COMPLETION_ATTEMPT = makeAsyncActionSet(
  'ALLOW_ANOTHER_COMPLETION_ATTEMPT'
);
export function allowAnotherCompletionAttempt(
  taskId: string,
  reasonText: string,
  tag?: string
) {
  return dispatchGenericRequest(
    ALLOW_ANOTHER_COMPLETION_ATTEMPT,
    `/api/tasks/${taskId}/allow-another-completion-attempt/`,
    'POST',
    { reason: reasonText },
    tag
  );
}

export const RESET_TARGET_TASK_STATUS = 'RESET_TARGET_TASK_STATUS';
export function resetTargetTaskStatus(): IActionNoPayload {
  return {
    type: RESET_TARGET_TASK_STATUS,
  };
}

export const SET_TARGET_TASK_STATUS = 'SET_TARGET_TASK_STATUS';
export function setTargetTaskStatus(
  status: TStatus
): IActionWithPayload<string, void> {
  return {
    type: SET_TARGET_TASK_STATUS,
    payload: status,
  };
}

export const TASK_DATES_UPDATED = 'TASK_DATES_UPDATED';
export function taskDatesUpdated(): IActionNoPayload {
  return {
    type: TASK_DATES_UPDATED,
  };
}

export const CLEAR_TASK_UPDATE_STATUS = 'CLEAR_TASK_UPDATE_STATUS';
export function clearTaskUpdateStatus(): IActionNoPayload {
  return {
    type: CLEAR_TASK_UPDATE_STATUS,
  };
}
