import {
  anyPending,
  AsyncActionSet,
  hasFailed,
  hasSucceeded,
  isPending,
  RequestStates,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, Column, Row } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router';
import { push } from 'react-router-redux';
import { destroy } from 'redux-form';
import * as _ from 'underscore';
import {
  GET_ASSETS,
  getAssets,
  IGetAssetsOptions,
  UPLOAD_ASSET,
} from '../../actions';
import {
  addJournalEntryAndRedirectToDetailPage,
  clearJournalTaskData,
} from '../../actions/journals';
import { openModal } from '../../actions/modals';
import { ADD_TO_COLLECTION, GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { CLASS_OPTIONS_LIST } from '../../constants/classes';
import { TEACHER, userHasRoles } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/';
import { IAssetRecord } from '../../store/data-types';
import { IClassOption } from '../../store/data-types/classes';
import { IJournalTaskData } from '../../store/data-types/journals';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../../store/data-types/tasks';
import { FormErrors } from '../../utils';
import { getFullName, getUsername } from '../../utils';
import Loading from '../loading';
import JournalEntryForm, {
  IPreTransformFormData,
  JOURNAL_ENTRY_EDITED,
  JOURNAL_ENTRY_FORM,
  journalEntryEditedHandler,
  NAVIGATION_WARNING_MESSAGE,
} from './journal-entry-form';
import JournalHeader from './journal-header';
import JournalPage from './journal-page';
import { JOURNAL_ENTRY_TASKS } from './tasks-sidebar/select-journal-tasks-modal';

const { actions: { addItem, getAllCollection } } = collectionsModule;

export interface IPostTransformFormData {
  title?: string;
  content?: string;
  assets?: ReadonlyArray<string>;
  user_task: string | null;
  off_job_training_hours: string | null;
  entry_class?: string;
}

export function transformJournalData(
  data: IPreTransformFormData
): IPostTransformFormData {
  const content = data.content
    ? stateToHTML(data.content.getCurrentContent())
    : '';
  return {
    ...data,
    assets: data.selectedAssets ? data.selectedAssets.toArray() : [],
    content,
    user_task: (!data.entry_class && data.user_task) || null,
    off_job_training_hours:
      (!data.entry_class && data.off_job_training_hours) || null,
  };
}

interface IAddJournalEntryConnectedProps {
  assets: List<IAssetRecord>;
  classes: List<IClassOption>;
  failed: boolean;
  formErrors?: FormErrors;
  loading: boolean;
  creating: boolean;
  profile: IProfile;
  succeeded: boolean;
  edited: boolean;
}
interface IAddJournalEntryActionProps {
  clearJournalEntryEdited: typeof journalEntryEditedHandler.clearState;
  addJournalEntry(data: IPostTransformFormData): AxiosPromise;
  getAssets(options: IGetAssetsOptions, tag?: string): void;
  push(url: string): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): void;
  openModal(modal: React.ReactNode): void;
  resetRequestState(actionSet: AsyncActionSet, tag?: string): void;
  destroy(...formNames: string[]): void;
  clearJournalTaskData(): void;
}

type IAddJournalEntryProps = RouteComponentProps<{}, {}> &
  IAddJournalEntryConnectedProps &
  IAddJournalEntryActionProps;

export function getDefaultJournalEntryValues() {
  return {
    content: EditorState.createEmpty(),
    selectedAssets: List(),
  };
}

const ADD_JOURNAL_ENTRY = 'ADD_JOURNAL_ENTRY';
export class AddJournalEntry extends React.PureComponent<
  IAddJournalEntryProps,
  void
> {
  public componentWillMount() {
    this.props.clearJournalEntryEdited();
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.props.edited) {
        return NAVIGATION_WARNING_MESSAGE;
      }
    });
    this.getAssets();
    this.props.getAllCollection(CLASS_OPTIONS_LIST, {}, ADD_JOURNAL_ENTRY);
    this.props.clearJournalTaskData();
  }

  public componentWillUnmount() {
    this.props.destroy(JOURNAL_ENTRY_FORM);
  }

  public render() {
    const {
      addJournalEntry,
      assets,
      children,
      classes,
      failed,
      formErrors,
      creating,
      loading,
      location,
      profile,
      succeeded,
    } = this.props;
    const fullName = getFullName(profile);
    const username = getUsername(profile);

    if (loading) {
      return (
        <JournalPage location={location}>
          <JournalHeader title="Loading..." location={location} />
          <Loading />
        </JournalPage>
      );
    }

    return (
      <JournalPage location={location}>
        <JournalHeader title="Add Post" showEdit={false} location={location}>
          {fullName && fullName.trim().length > 0 ? (
            <p className="small">
              by {fullName} ({username})
            </p>
          ) : (
            <noscript />
          )}
        </JournalHeader>
        <JournalEntryForm
          isStudent={!userHasRoles(profile, Set.of(TEACHER))}
          assets={assets}
          getAssets={this.getAssets}
          classes={classes}
          submitting={creating}
          loading={loading}
          profile={profile}
          formErrors={formErrors}
          destroyOnUnmount={false}
          onSubmit={(data: IPreTransformFormData) => {
            const transformed = transformJournalData(data);
            addJournalEntry(transformed).then(response => {
              const entryId = response && response.data.id;
              if (entryId) {
                this.props.clearJournalEntryEdited();
                this.props.push(`/journal/${entryId}/`);
              }
            });
          }}
          openModal={(component: React.ReactNode) => {
            this.props.openModal(component);
            this.props.resetRequestState(UPLOAD_ASSET);
          }}
          initialValues={getDefaultJournalEntryValues()}
          saveButtonText="Add Post"
          isOwner
          showTaskLink={false}
        />

        {succeeded && (
          <p className="success">Successfully created journal post</p>
        )}

        {failed && <p className="error">Failed to add journal post.</p>}
      </JournalPage>
    );
  }

  private getAssets = () => {
    this.props.getAssets(
      {
        owner__id: this.props.profile.id,
        created_by__id: this.props.profile.id,
      },
      ADD_JOURNAL_ENTRY
    );
  };
}

function mapStateToProps(
  { assets, collectionsOld, profile, responses, uiState }: IStore,
  existing: RouteComponentProps<{}, {}>
): IAddJournalEntryConnectedProps & RouteComponentProps<{}, {}> {
  const classes =
    getCollectionItems(collectionsOld[CLASS_OPTIONS_LIST], ADD_JOURNAL_ENTRY) ||
    List();

  return {
    ...existing,
    assets,
    classes,
    failed: hasFailed(responses, ADD_TO_COLLECTION, 'journals'),
    formErrors: getFormErrors(responses, ADD_TO_COLLECTION, 'journals'),
    loading: anyPending(responses, [
      [GET_ASSETS, ADD_JOURNAL_ENTRY],
      [GET_COLLECTION, CLASS_OPTIONS_LIST],
    ]),
    creating: isPending(responses, ADD_TO_COLLECTION, 'journals'),
    profile,
    succeeded: hasSucceeded(responses, ADD_TO_COLLECTION, 'journals'),
    edited: journalEntryEditedHandler.getValue(uiState),
  };
}

export default connect(mapStateToProps, {
  addJournalEntry: addItem.bind(null, 'journals'),
  getAssets,
  getAllCollection,
  openModal,
  push,
  resetRequestState,
  destroy,
  clearJournalEntryEdited: journalEntryEditedHandler.clearState,
  clearJournalTaskData,
})(AddJournalEntry);
