import {
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
  Well,
} from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { IJournalEntry } from '../../../store/data-types/journals';
import { IUserTaskRecord } from '../../../store/data-types/tasks';

interface IProps {
  task: IUserTaskRecord;
  journalEntries: List<IJournalEntry>;
}

export default class OffTheJobTrainingSection extends React.PureComponent<
  IProps,
  void
> {
  public render() {
    const { task, journalEntries } = this.props;
    const totalHours = journalEntries.reduce(
      (memo, entry) => memo + entry.off_job_training_hours,
      0
    );
    return (
      <Section>
        <Row>
          <Column>
            <h3>
              {totalHours} hours of{' '}
              {task.class_task.component.off_job_training_percent}% off the job
              training
            </h3>
          </Column>
        </Row>
      </Section>
    );
  }
}
