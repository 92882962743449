import { List, Map, Set } from 'immutable';
import { IActionWithPayload } from '../requests/types';
import { IAction } from '../types';

import * as commentActions from '../actions/comments';
import { CommentRecord, ICommentRecord } from '../store/data-types';

export function findPathToComment(
  children: List<ICommentRecord>,
  id: string
): List<string | number> {
  return children.reduce(
    (memo: List<string | number>, child: ICommentRecord, index: number) => {
      if (memo.count()) {
        return memo;
      }

      if (child.id === id) {
        return memo.push('children').push(index);
      }

      const childMatch = findPathToComment(
        child.children || List<ICommentRecord>(),
        id
      );

      if (childMatch.count()) {
        return memo
          .push('children')
          .push(index)
          .concat(childMatch);
      }

      return memo;
    },
    List()
  );
}

export function rootComment(
  state = CommentRecord({}),
  action: IActionWithPayload<any, void>
) {
  switch (action.type) {
    case commentActions.GET_COMMENTS.REQUEST:
      return state;
    case commentActions.GET_COMMENTS.SUCCESS:
      return CommentRecord(action.payload);
    case commentActions.GET_COMMENTS.FAILURE:
      return CommentRecord({});
    case commentActions.ADD_COMMENT.SUCCESS:
      const children = state.children || List<ICommentRecord>();

      if (state.get('id') === action.payload.parent) {
        return state.set(
          'children',
          children.push(CommentRecord(action.payload))
        );
      }

      const pathToParent = findPathToComment(children, action.payload.parent);

      if (pathToParent.count()) {
        const pathToParentChildren = pathToParent.push('children');

        const parentChildren =
          state.getIn(pathToParentChildren) || List<ICommentRecord>();
        return state.setIn(
          pathToParentChildren,
          parentChildren.push(CommentRecord(action.payload))
        );
      }

      return state;
    default:
      return state;
  }
}

export function openReplies(state = Set(), action: IAction<string, void>) {
  switch (action.type) {
    case commentActions.OPEN_NEW_REPLY:
      return state.clear().add(action.payload);
    case commentActions.CLOSE_NEW_REPLY:
      return state.delete(action.payload);
    case commentActions.ADD_COMMENT.SUCCESS:
      return state.clear();
    default:
      return state;
  }
}

interface IReply {
  commentId: string;
  content: string;
}

export function replies(
  state = Map(),
  action: IActionWithPayload<IReply, void>
) {
  switch (action.type) {
    case commentActions.UPDATE_REPLY:
      return state.set(action.payload.commentId, action.payload.content);
    case commentActions.CLOSE_NEW_REPLY:
    case commentActions.ADD_COMMENT.SUCCESS:
      return state.clear();
    default:
      return state;
  }
}
