import { Column, Row } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import Loading from '../loading';

interface IItemListProps<T> {
  items: List<T>;
  actionText: string;
  loading?: boolean;
  noItemsMessage: string;
  canPerformAction: boolean;
  action(itemId: string): void;
  itemRenderer(item: T): React.ReactNode;
}

export default function ItemList<T extends { id: string }>({
  items,
  action,
  actionText,
  canPerformAction,
  loading,
  noItemsMessage,
  itemRenderer,
}: IItemListProps<T>) {
  if (loading) {
    return (
      <ul className="journal-file-list fixed">
        <li>
          <Loading />
        </li>
      </ul>
    );
  }

  return (
    <ul className="journal-file-list fixed">
      {items.count() ? (
        items.map(item => (
          <li key={item.id}>
            <Row>
              <Column xs={12} sm={7} lg={9} className="nowrap">
                {itemRenderer(item)}
              </Column>
              <Column xs={12} sm={5} lg={3}>
                {canPerformAction && (
                  <a onClick={() => action(item.id)}> {actionText}</a>
                )}
              </Column>
            </Row>
          </li>
        ))
      ) : (
        <p>{noItemsMessage}</p>
      )}
    </ul>
  );
}
