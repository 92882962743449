import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { UPDATE_ITEM } from '../../../items/actions';
const { IconFileTextO } = FontAwesome;
import { closeModal } from '../../../actions/modals';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IAssetRecord } from '../../../store/data-types';
import { IProfile, TRole } from '../../../store/data-types/profile';
import { IUserTaskRecord } from '../../../store/data-types/tasks';
import { FormErrors, getAssetName } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import SelectItemsModal from '../../modals/select-items-modal';
import { terminologyFromProfile } from '../../terminology';

interface IExternalProps {
  assets: List<IAssetRecord>;
  lockedAssets: List<string>;
  subTitle?: React.ReactChild;
  profile: IProfile;
  taggerRole: TRole;
  onChangeAssets(assets: List<string>): void;
}

interface ISelectTaskFilesModalProps extends IExternalProps {
  loading: boolean;
  selectedAssets: List<string>;
  task: IUserTaskRecord | null;
  errors: FormErrors | undefined;
  closeModal(): void;
}

export class SelectTaskFilesModal extends React.PureComponent<
  ISelectTaskFilesModalProps,
  void
> {
  public render() {
    const {
      assets,
      loading,
      selectedAssets,
      closeModal,
      lockedAssets,
      onChangeAssets,
      subTitle,
      profile,
      errors,
    } = this.props;
    return (
      <SelectItemsModal
        subTitle={subTitle}
        items={assets}
        lockedItems={lockedAssets}
        lockedItemsMessage="Uploaded or tagged by another user"
        loading={loading}
        selectedItems={selectedAssets}
        itemLabel="files"
        targetLabel={terminologyFromProfile(profile, 'Task')}
        closeModal={closeModal}
        itemRenderer={(asset: IAssetRecord) => (
          <span>
            <IconFileTextO />
            {getAssetName(asset)}
          </span>
        )}
        onChangeSelected={onChangeAssets}
      >
        <div>
          <FormErrorsRenderer formErrors={errors} errorKey="task_assets" />
          <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors" />
        </div>
      </SelectItemsModal>
    );
  }
}

const mapStateToProps = (
  { responses, itemsOld, profile }: IStore,
  props: IExternalProps
) => {
  const task = itemsOld.tasks;
  const selectedAssets = props.assets.filter(
    asset =>
      task && task.task_assets.filter(each => each.asset === asset.id).length
  );

  return {
    ...props,
    loading: isPending(responses, UPDATE_ITEM, 'tasks'),
    selectedAssets: selectedAssets.map(asset => asset.id),
    errors: getFormErrors(responses, UPDATE_ITEM, 'tasks'),
    profile,
  };
};

export default connect(mapStateToProps, { closeModal })(SelectTaskFilesModal);
