import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modals';
import { UPDATE_ITEM } from '../../items/actions';
import { IStore } from '../../store';
import { IAssetRecord } from '../../store/data-types';
import { getAssetName } from '../../utils';
import EllipsizedFileName from '../ellipsized-file-name';
import FileIcon from '../files/file-icon';
import SelectItemsModal from '../modals/select-items-modal';
import { JOURNAL_ENTRY_FORM } from './journal-entry-form';

interface IExternalProps {
  onChangeAssets(assets: List<string>): void;
}

interface ISelectJournalFilesModalPropsWithoutActions extends IExternalProps {
  assets: List<IAssetRecord>;
  loading: boolean;
  selectedAssets: List<string>;
}

interface ISelectJournalFilesModalProps
  extends ISelectJournalFilesModalPropsWithoutActions {
  closeModal(): void;
}

export class SelectJournalFilesModal extends React.PureComponent<
  ISelectJournalFilesModalProps,
  void
> {
  public render() {
    const {
      assets,
      loading,
      selectedAssets,
      closeModal,
      onChangeAssets,
    } = this.props;
    return (
      <SelectItemsModal
        items={assets}
        loading={loading}
        selectedItems={selectedAssets}
        itemLabel="files"
        targetLabel="journal entry"
        closeModal={closeModal}
        itemRenderer={(asset: IAssetRecord) => (
          <span className="file-info">
            <span className="file-name">
              <span className="file-icon">
                <FileIcon fileName={getAssetName(asset)} />
              </span>
              <EllipsizedFileName>{getAssetName(asset)}</EllipsizedFileName>
            </span>
          </span>
        )}
        onChangeSelected={onChangeAssets}
      />
    );
  }
}

const mapStateToProps = (
  { assets, form, itemsOld, responses }: IStore,
  props: IExternalProps
): ISelectJournalFilesModalPropsWithoutActions => {
  const formValues =
    form[JOURNAL_ENTRY_FORM] && form[JOURNAL_ENTRY_FORM].values;
  const journalEntry = itemsOld.journals;
  const formSelectedAssets: List<string> | undefined =
    formValues && (formValues.selectedAssets as any); // NOTE: form values can be anything
  const selectedAssets =
    formSelectedAssets ||
    (journalEntry && List<string>(journalEntry.assets)) ||
    List<string>();
  return {
    assets,
    loading: isPending(responses, UPDATE_ITEM, 'journals'),
    onChangeAssets: props.onChangeAssets,
    selectedAssets,
  };
};

export default connect(mapStateToProps, { closeModal })(
  SelectJournalFilesModal
);
