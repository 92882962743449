import { push } from 'react-router-redux';
import { Dispatch } from 'redux';
import { IPostTransformFormClassData } from '../components/classes/create-class-page';
import { collectionsModule } from '../reducers/collections';
import { GetStateCallback } from '../requests/types';

const { actions: { addItem } } = collectionsModule;

export function addClassAndRedirectToEditStudentsPage(
  data: IPostTransformFormClassData,
  tag?: string
) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return addItem('classes', data, tag)(dispatch, getState)
      .then(response => {
        const id = response && response.data.id;
        if (id) {
          return dispatch(push(`/classes/${id}/edit-students/`));
        }
      })
      .catch(error => null);
  };
}
