import { ContentBoxHeader, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { Link } from 'react-router';

interface IJournalHeaderProps extends React.HTMLProps<JSX.Element> {
  title: string;
  isViewingUnderStudentContext?: boolean;
  showEdit?: boolean;
  journalId?: string;
  location: {
    query: {
      prevPathname?: string;
      prevName?: string;
    };
  };
}

export default function JournalHeader({
  title,
  children,
  showEdit,
  journalId,
  location: { query: { prevPathname, prevName } },
  isViewingUnderStudentContext,
}: IJournalHeaderProps) {
  const query = prevPathname
    ? `?prevPathname=${prevPathname}&prevName=${prevName}`
    : '';

  return (
    <ContentBoxHeader>
      <SpacedGroup block className="float-right margin-vertical-large">
        {!isViewingUnderStudentContext && (
          <Link to={prevPathname || '/journal/'} className="button">
            Back to {prevName || 'Journal'}
          </Link>
        )}
        {showEdit && (
          <Link to={`/journal/${journalId}/edit/${query}`} className="button">
            Edit
          </Link>
        )}
      </SpacedGroup>
      <h2 className="font-size-large">{title}</h2>
      {children}
    </ContentBoxHeader>
  );
}
