import { Record } from 'immutable';
import { itemsFunctor } from '../items/';

import {
  IExamSpecRecord,
  IOptionRecord,
  IQuotaRecord,
} from '../store/data-types';
import { IBulkUpload } from '../store/data-types/bulk-upload';
import { ICentre } from '../store/data-types/centres';
import { IClassRecord } from '../store/data-types/classes';
import { IJournalEntry } from '../store/data-types/journals';
import { ILearningOutcomeBlockRecord } from '../store/data-types/marksheets';
import { IProfile } from '../store/data-types/profile';
import { IQualificationRecord } from '../store/data-types/qualifications';
import {
  IClassTaskRecord,
  IMarkschemeRecord,
  IUnitDetail,
  IUserTaskGrade,
  IUserTaskRecord,
} from '../store/data-types/tasks';
import { typeToRecordMapping } from './collections';

export interface IItemsState {
  'assets/quotas': IQuotaRecord | null;
  classes: IClassRecord | null;
  courses: IQualificationRecord | null;
  centres: ICentre | null;
  'courses/qualification-options': IOptionRecord | null;
  'courses/running-exam-specs': IExamSpecRecord | null;
  'courses/units': IUnitDetail | null;
  'marksheets/learning-outcome-blocks': ILearningOutcomeBlockRecord | null;
  journals: IJournalEntry | null;
  imports: IBulkUpload | null;
  users: IProfile | null;
  tasks: IUserTaskRecord | null;
  'tasks/grade': IUserTaskGrade | null;
  'tasks/class': IClassTaskRecord | null;
  'tasks/class-task-markscheme': IMarkschemeRecord | null;
}
export interface IItemsStateRecord
  extends Record.Instance<IItemsState>,
    Readonly<IItemsState> {}
export const ItemsStateRecord = Record<IItemsState>({
  'assets/quotas': null,
  centres: null,
  classes: null,
  courses: null,
  'courses/qualification-options': null,
  'courses/running-exam-specs': null,
  'courses/units': null,
  'marksheets/learning-outcome-blocks': null,
  imports: null,
  journals: null,
  tasks: null,
  'tasks/grade': null,
  'tasks/class': null,
  'tasks/class-task-markscheme': null,
  users: null,
});

// Please note that old-style collections is now DEPRECATED - please do not add any additional routes to this file
export const itemsModule = itemsFunctor(typeToRecordMapping, ItemsStateRecord);
