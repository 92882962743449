import * as React from 'react';

import * as classNames from 'classnames';

const DOT_DOT_DOT = '... ';
const NBSP = '\u00a0';
const SPACES = NBSP + NBSP + NBSP + NBSP;

interface IState {
  index: number;
}

interface IProps {
  className?: string;
}

export class Loading extends React.PureComponent<IProps, IState> {
  private interval: NodeJS.Timer;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      index: 0,
    };

    this.incrementIndex = this.incrementIndex.bind(this);
  }

  public componentDidMount() {
    this.setInterval(this.incrementIndex, 300);
  }

  public componentWillUnmount() {
    this.clearInterval();
  }

  public render() {
    const { index } = this.state;
    const { className } = this.props;

    return (
      <div className={classNames('loading-container', className)}>
        <p className="info">
          Loading{DOT_DOT_DOT.substring(0, index)}
          {SPACES.substring(index, SPACES.length)}
        </p>
      </div>
    );
  }

  private incrementIndex() {
    const { index } = this.state;
    const newIndex = (index + 1) % DOT_DOT_DOT.length;

    this.setState({
      index: newIndex,
    });
  }

  private setInterval(fn: () => any, time: number) {
    this.clearInterval();

    this.interval = setInterval(fn, time);
  }

  private clearInterval() {
    clearInterval(this.interval);
  }
}

export default Loading;
