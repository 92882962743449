import * as React from 'react';

import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { formValueSelector } from 'redux-form';
import { LOAD_ITEM, UPDATE_ITEM } from '../../../items/actions';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import { ISimpleUser } from '../../../store/data-types/profile';
import { FormErrors } from '../../../utils';
import Loading from '../../loading';
import Term from '../../terminology';
import EditStudentsForm, { EDIT_STUDENTS_FORM } from './form';

const { actions: { loadItem, patchItem } } = itemsModule;

interface IExternalProps {
  params: {
    id: string;
  };
}

interface IDispatchProps {
  push: typeof push;
  loadItem(type: keyof IItemsState, itemId: string): AxiosPromise;
  patchItem(
    type: keyof IItemsState,
    itemId: string,
    data: { students: ReadonlyArray<string> }
  ): AxiosPromise;
}

interface IStateProps {
  classId: string;
  classObject: IClassRecord | null;
  errors: FormErrors | undefined;
  loading: boolean;
  initiallySelectedStudents: ReadonlyArray<string>;
  selectedStudents?: ReadonlyArray<string>;
  updating: boolean;
}

type IProps = IStateProps & IDispatchProps;

export class EditStudentsPage extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.updateStudents = this.updateStudents.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem('classes', this.props.classId);
  }

  public render() {
    const {
      classObject,
      loading,
      updating,
      errors,
      initiallySelectedStudents,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!classObject) {
      return (
        <Alert className="error">
          <p>
            Failed to load <Term>Class</Term>
          </p>
        </Alert>
      );
    }

    return (
      <ContentBox className="padding-bottom-large">
        <ContentBoxHeader>
          <h2 className="font-size-large">
            Add <Term>Student</Term>s to {classObject.name}
          </h2>
        </ContentBoxHeader>

        <EditStudentsForm
          updating={updating}
          centreId={classObject.centre}
          classId={classObject.id}
          initialValues={{ students: initiallySelectedStudents }}
          errors={errors}
          shouldShowRemoveWarning={Boolean(classObject.qualification)}
          updateStudents={this.updateStudents}
          groupName={classObject.name}
        />
      </ContentBox>
    );
  }

  private updateStudents(event: React.MouseEvent<HTMLButtonElement>) {
    const { classId, selectedStudents } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.props
      .patchItem('classes', classId, {
        students: selectedStudents || [],
      })
      .then(() => this.props.push(`/classes/${classId}/`));
  }
}

const studentsSelector = formValueSelector(EDIT_STUDENTS_FORM);

function mapStateToProps(
  state: IStore,
  { params: { id: classId } }: IExternalProps
): IStateProps {
  const { responses, itemsOld } = state;

  const classObject = itemsOld.classes;
  const initiallySelectedStudents = classObject
    ? narrowToRecordArray(classObject.students).map(each => each.id)
    : [];

  return {
    classId,
    classObject,
    errors: getFormErrors(responses, UPDATE_ITEM, 'classes'),
    loading: isPending(responses, LOAD_ITEM, 'classes'),
    initiallySelectedStudents,
    selectedStudents: studentsSelector(state, 'students'),
    updating: isPending(responses, UPDATE_ITEM, 'classes'),
  };
}

export default connect(mapStateToProps, {
  loadItem,
  push,
  patchItem,
})(EditStudentsPage);
