import * as React from 'react';

import { Alert, Button, Column, Row } from '@dabapps/roe';
import * as moment from 'moment';
import { FormProps, reduxForm } from 'redux-form';
import { FormErrors } from '../../utils';
import DateInput from '../date-input';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GenderSelect from '../forms/gender-select';
import GroupField from '../forms/group-field';
import TitleSelect from '../forms/title-select';

export interface IFormData {
  unique_learner_number: string;
  title: string;
  email: string;
  given_name: string;
  middle_name: string;
  family_name: string;
  username: string;
  gender: string;
  birth_date: string;
}

interface IProps {
  formErrors?: FormErrors;
  isLoading: boolean;
  hasSucceeded: boolean;
  isStudent: boolean;
  onSubmit(data: IFormData): void;
}

interface IPersonalForm extends FormProps<IFormData, {}, {}>, IProps {}

type FormField = keyof IFormData;
const STUDENT_NON_UPDATEABLE_FIELDS: ReadonlyArray<FormField> = [
  'birth_date',
  'gender',
  'given_name',
  'family_name',
  'middle_name',
  'username',
  'title',
  'unique_learner_number',
];
const ALL_USERS_NON_UPDATABLE_FIELDS: ReadonlyArray<FormField> = ['birth_date'];

export class PersonalForm extends React.PureComponent<IPersonalForm, void> {
  public constructor(props: IPersonalForm) {
    super(props);
    this.isFieldDisabled = this.isFieldDisabled.bind(this);
  }

  public render() {
    const { formErrors, handleSubmit, isLoading, hasSucceeded } = this.props;

    const birthDateDisabled = this.isFieldDisabled('birth_date');

    return (
      <Row>
        <Column>
          <form onSubmit={handleSubmit}>
            <GroupField
              label="ULN"
              name="unique_learner_number"
              type="text"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('unique_learner_number')}
            />
            <GroupField
              label="Username"
              name="username"
              type="text"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('username')}
            />
            <GroupField
              label="Email"
              name="email"
              type="email"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('email')}
            />
            <GroupField
              label="Given Name"
              name="given_name"
              type="text"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('given_name')}
            />
            <GroupField
              label="Middle Name"
              name="middle_name"
              type="text"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('middle_name')}
            />
            <GroupField
              label="Family Name"
              name="family_name"
              type="text"
              formErrors={formErrors}
              disabled={this.isFieldDisabled('family_name')}
            />
            <TitleSelect
              formErrors={formErrors}
              disabled={this.isFieldDisabled('title')}
            />
            <GenderSelect
              formErrors={formErrors}
              disabled={this.isFieldDisabled('gender')}
            />
            <GroupField
              name="birth_date"
              type="date"
              formErrors={formErrors}
              disabled={birthDateDisabled}
              component={
                birthDateDisabled
                  ? undefined
                  : fieldProps => (
                      <DateInput
                        input={fieldProps.input}
                        maxDate={moment.utc()}
                      />
                    )
              }
              label="Date of Birth"
            />
            {hasSucceeded && (
              <Alert className="success">
                <p>Your profile has been successfully updated.</p>
              </Alert>
            )}
            <p>
              <Button type="submit" disabled={isLoading}>
                Save
              </Button>
            </p>
            <FormErrorsRenderer formErrors={formErrors} errorKey="detail" />
            <FormErrorsRenderer
              formErrors={formErrors}
              errorKey="non_field_errors"
            />
          </form>
        </Column>
      </Row>
    );
  }

  private isFieldDisabled(fieldName: keyof IFormData): boolean {
    const { isStudent, initialValues } = this.props;
    const nonUpdatableFields = isStudent
      ? STUDENT_NON_UPDATEABLE_FIELDS
      : ALL_USERS_NON_UPDATABLE_FIELDS;
    return (
      !!initialValues &&
      Boolean(initialValues[fieldName]) &&
      nonUpdatableFields.indexOf(fieldName) !== -1
    );
  }
}

export default reduxForm({
  form: 'profile-personal',
})(PersonalForm);
