import {
  anyPending,
  AsyncActionSet,
  hasFailed,
  hasSucceeded,
  isPending,
  RequestStates,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { Alert, Column, Row, Section } from '@dabapps/roe';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { destroy } from 'redux-form';
import * as _ from 'underscore';
import { ADD_TO_COLLECTION, GET_COLLECTION } from '../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { LOAD_ITEM, UPDATE_ITEM } from '../../items/actions';
import { TEACHER } from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { getFormErrors } from '../../responses';
import { IStore } from '../../store/';
import { IProfile } from '../../store/data-types/profile';
import {
  ITaskStatusChangeCommentRecord,
  IUserTaskRecord,
} from '../../store/data-types/tasks';
import Loading from '../loading';
import { terminologyFromProfile } from '../terminology';
import Comment from './comment';

const { actions: { getAllCollection } } = collectionsModule;

export const MARKSHEET_COMMENTS = 'MARKSHEET_COMMENTS';

interface IMarksheetCommentGroup {
  title: string;
  taskStatusChangeComments: List<ITaskStatusChangeCommentRecord>;
  profile: IProfile;
  onCommentAdded(): void;
}
function MarksheetCommentGroup({
  title,
  taskStatusChangeComments,
  onCommentAdded,
  profile,
}: IMarksheetCommentGroup) {
  return taskStatusChangeComments.count() > 0 ? (
    <Section>
      <Row>
        <Column>
          <h3>{title}</h3>
          {taskStatusChangeComments.map(each => (
            <div className="comment" key={each.id}>
              <Comment
                comment={each.comment}
                showRootComment
                showRole
                showNewComment
                onCommentAdded={onCommentAdded}
                profile={profile}
              />
            </div>
          ))}
        </Column>
      </Row>
    </Section>
  ) : null;
}

interface IExternalProps {
  loadComments: () => void;
  task: IUserTaskRecord;
}
interface IMarksheetCommentProps extends IExternalProps {
  taskStatusChangeComments: List<ITaskStatusChangeCommentRecord>;
  loading: boolean;
  loadingFailed: boolean;
  profile: IProfile;
}
export class MarksheetComments extends React.PureComponent<
  IMarksheetCommentProps,
  void
> {
  public render() {
    const {
      loading,
      loadingFailed,
      taskStatusChangeComments,
      profile,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (loadingFailed) {
      return (
        <Alert className="error">
          <p>Failed to load comments.</p>
        </Alert>
      );
    }

    if (taskStatusChangeComments.count() === 0) {
      return null;
    }

    const teacherStudentGroup = taskStatusChangeComments.filter(
      each => each.status_change === 'RE_OPENED'
    );
    const teacherIQAGroup = taskStatusChangeComments.filter(
      each =>
        each.status_change === 'MARKED' || each.status_change === 'IQA_REJECTED'
    );
    const IQAEQAGroup = taskStatusChangeComments.filter(
      each =>
        each.status_change === 'IQA_PASSED' ||
        each.status_change === 'EQA_REJECTED' ||
        each.status_change === 'EQA_PASSED'
    );

    const teacherTerm = terminologyFromProfile(profile, 'Teacher');
    const studentTerm = terminologyFromProfile(profile, 'Student');
    const iqaTerm = terminologyFromProfile(profile, 'IQA');
    const eqaTerm = terminologyFromProfile(profile, 'EQA');

    /*
      NOTE: we do not need to handle the permissions of rendering these groups here.
      if the backend has returned us any of these groups we have permission to see them.
    */
    return (
      <div>
        <MarksheetCommentGroup
          title={`${teacherTerm}/${studentTerm} Comments`}
          taskStatusChangeComments={teacherStudentGroup}
          onCommentAdded={this.props.loadComments}
          profile={profile}
        />
        <MarksheetCommentGroup
          title={`${iqaTerm}/${teacherTerm} Comments`}
          taskStatusChangeComments={teacherIQAGroup}
          onCommentAdded={this.props.loadComments}
          profile={profile}
        />
        <MarksheetCommentGroup
          title={`${eqaTerm}/${iqaTerm} Comments`}
          taskStatusChangeComments={IQAEQAGroup}
          onCommentAdded={this.props.loadComments}
          profile={profile}
        />
      </div>
    );
  }
}

function mapStateToProps(
  { responses, collectionsOld, profile }: IStore,
  props: IExternalProps
) {
  const taskStatusChangeComments =
    getCollectionItems(
      collectionsOld.get('tasks/task-status-change-comments'),
      MARKSHEET_COMMENTS
    ) || List();
  return {
    ...props,
    taskStatusChangeComments,
    loadingFailed: hasFailed(
      responses,
      GET_COLLECTION,
      'tasks/task-status-change-comments'
    ),
    loading: isPending(
      responses,
      GET_COLLECTION,
      'tasks/task-status-change-comments'
    ),
    profile,
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
})(MarksheetComments);
