import * as React from 'react';

import { FormGroup } from '@dabapps/roe';

export interface IBaseProps {
  formName: string;
  label: string;
  filterKey: string;
  pageSize: number;
  uiValue: string;
  setUIState(key: string, value: string): void;
  clearUIState(key: string): void;
}

export interface IInternalProps extends IBaseProps {
  changeFilters(newValue: string): void;
}

export function uiKeyName(props: Partial<IBaseProps>) {
  return `${props.formName}-${props.label}-${props.filterKey}`;
}

export default class CollectionInputFilterBase extends React.PureComponent<
  IInternalProps,
  void
> {
  public componentDidMount() {
    this.props.clearUIState(uiKeyName(this.props));
  }

  public render() {
    return (
      <FormGroup block>
        <label>{this.props.label}</label>
        <input
          value={this.props.uiValue}
          onChange={event => this.onChangeFilters(event.target.value)}
        />
      </FormGroup>
    );
  }

  private onChangeFilters(newValue: string) {
    this.props.setUIState(uiKeyName(this.props), newValue);
    this.props.changeFilters(newValue);
  }
}
