import { Collections } from '@dabapps/redux-api-collections';

import { AssetRecord, IAssetRecord } from './store/data-types';
import {
  ActivityEventRecord,
  IActivityEventRecord,
} from './store/data-types/activity-events';
import {
  CentreExamSpecRecord,
  ICentreExamSpecRecord,
} from './store/data-types/courses';
import {
  ILearningOutcomeBlockCommentRecord,
  LearningOutcomeBlockCommentRecord,
} from './store/data-types/marksheets';
import { ISimpleUser, SimpleUser } from './store/data-types/profile';

export interface ICollections {
  assets: IAssetRecord; // Beware - Asset Lists are still using the old reducers
  'activity-log': IActivityEventRecord;
  'marksheets/learning-outcome-block-comments': ILearningOutcomeBlockCommentRecord;
  'users/external-quality-assurers': ISimpleUser;
  'users/assigned-external-quality-assurers': ISimpleUser;
  'courses/centre-exam-specs': ICentreExamSpecRecord;
}

const collectionToRecordMapping = {
  assets: AssetRecord,
  'activity-log': ActivityEventRecord,
  'marksheets/learning-outcome-block-comments': LearningOutcomeBlockCommentRecord,
  'users/external-quality-assurers': SimpleUser,
  'users/assigned-external-quality-assurers': SimpleUser,
  'courses/centre-exam-specs': CentreExamSpecRecord,
};

export const collections = Collections<ICollections, ICollections>(
  collectionToRecordMapping,
  collectionToRecordMapping
);
