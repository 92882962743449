import { Map, Record } from 'immutable';
import { Collection } from '../collections/constructors';
import { collectionsFunctor } from '../collections/index';
import { ICollection, TStateRecord } from '../collections/types';

import {
  AssetRecord,
  ExamBoardRecord,
  ExamSpecRecord,
  IAssetRecord,
  IExamBoardRecord,
  IExamSpecRecord,
  IOptionRecord,
  IQuotaRecord,
  OptionRecord,
  QuotaRecord,
} from '../store/data-types';
import {
  ClassOptionRecord,
  ClassRecord,
  ClassStudentDetailsRecord,
  IClassOption,
  IClassRecord,
  IClassStudentDetails,
} from '../store/data-types/classes';
import {
  IJournalEntry,
  JournalEntryRecord,
} from '../store/data-types/journals';
import {
  IProfile,
  ISimpleUser,
  ProfileRecord,
  SimpleUser,
} from '../store/data-types/profile';
import {
  IQualificationStudentProgress,
  QualificationStudentProgressRecord,
} from '../store/data-types/progress';
import {
  DashboardQualificationOption,
  IDashboardQualificationOption,
  IQualificationRecord,
  QualificationRecord,
} from '../store/data-types/qualifications';
import {
  ClassTaskRecord,
  CompletionAttemptRecord,
  CourseworkProgressOverviewRecord,
  IClassTaskRecord,
  ICompletionAttempt,
  ICourseworkProgressOverview,
  IMarkschemeRecord,
  ITaskStatusChangeCommentRecord,
  IUnitDetail,
  IUnitManagementRecord,
  IUserGradeListTaskRecord,
  IUserTaskGrade,
  IUserTaskRecord,
  MarkschemeRecord,
  TaskStatusChangeCommentRecord,
  UnitDetailRecord,
  UnitManagementRecord,
  UserGradeListTaskRecord,
  UserTaskGradeRecord,
  UserTaskRecord,
} from '../store/data-types/tasks';
import {
  ITaggingUserTask,
  TaggingUserTaskRecord,
} from '../store/data-types/tasks/tagging';

import { CentreRecord, ICentre } from '../store/data-types/centres';

import { BulkUploadRecord, IBulkUpload } from '../store/data-types/bulk-upload';
import {
  ChecklistRecord,
  IChecklistRecord,
  ILearningOutcomeBlockRecord,
  LearningOutcomeBlockRecord,
} from '../store/data-types/marksheets';

type TCollectionConstructor<T> = (
  input?: Partial<ICollection<T>>
) => ICollection<T>;
export const CentreCollection: TCollectionConstructor<ICentre> = Collection;
export const ClassCollection: TCollectionConstructor<IClassRecord> = Collection;
export const ClassTaskCollection: TCollectionConstructor<
  IClassTaskRecord
> = Collection;
export const MarkschemeCollection: TCollectionConstructor<
  IMarkschemeRecord
> = Collection;
export const CourseCollection: TCollectionConstructor<
  IQualificationRecord
> = Collection;
export const JournalCollection: TCollectionConstructor<
  IJournalEntry
> = Collection;
export const OptionCollection: TCollectionConstructor<
  IOptionRecord
> = Collection;
export const QuotaCollection: TCollectionConstructor<IQuotaRecord> = Collection;

export const BulkUploadCollection: TCollectionConstructor<
  IBulkUpload
> = Collection;
export const UnitCollection: TCollectionConstructor<IUnitDetail> = Collection;
export const UnitManagementCollection: TCollectionConstructor<
  IUnitManagementRecord
> = Collection;
export const UserCollection: TCollectionConstructor<IProfile> = Collection;
export const MinimalUserCollection: TCollectionConstructor<
  ISimpleUser
> = Collection;
export const UserTaskCollection: TCollectionConstructor<
  IUserTaskRecord
> = Collection;
export const UserTaskGradeCollection: TCollectionConstructor<
  IUserGradeListTaskRecord
> = Collection;
export const TaskStatusChangeCommentCollection: TCollectionConstructor<
  ITaskStatusChangeCommentRecord
> = Collection;
export const QualificationProgressCollection: TCollectionConstructor<
  IQualificationStudentProgress
> = Collection;
export const ChecklistCollection: TCollectionConstructor<
  IChecklistRecord
> = Collection;
export const CompletionAttemptCollection: TCollectionConstructor<
  ICompletionAttempt
> = Collection;
export const ClassStudentDetailsCollection: TCollectionConstructor<
  IClassStudentDetails
> = Collection;
export const TaggingTaskCollection: TCollectionConstructor<
  IUserTaskRecord
> = Collection;

export const typeToRecordMapping = {
  assets: AssetRecord,
  'assets/quotas': QuotaRecord,
  'courses/dashboard-qualification-options': DashboardQualificationOption,
  'courses/exam-boards': ExamBoardRecord,
  'courses/qualification-options': OptionRecord,
  'courses/running-exam-specs': ExamSpecRecord,
  'courses/student-progress': QualificationStudentProgressRecord,
  'courses/units': UnitDetailRecord,
  'courses/unit-management': UnitManagementRecord,
  'marksheets/checklists': ChecklistRecord,
  'marksheets/learning-outcome-blocks': LearningOutcomeBlockRecord,
  'tasks/class-task-markscheme': MarkschemeRecord,
  'tasks/class': ClassTaskRecord,
  'tasks/coursework-progress-overview': CourseworkProgressOverviewRecord,
  'tasks/task-status-change-comments': TaskStatusChangeCommentRecord,
  centres: CentreRecord,
  'centres/eqa-stats': CentreRecord,
  classes: ClassRecord,
  'classes/class-options': ClassOptionRecord,
  courses: QualificationRecord,
  imports: BulkUploadRecord,
  journals: JournalEntryRecord,
  'tasks/grade': UserTaskGradeRecord,
  'tasks/completion-attempts': CompletionAttemptRecord,
  'tasks/grades': UserGradeListTaskRecord,
  'tasks/tag-files': TaggingUserTaskRecord,
  tasks: UserTaskRecord,
  users: ProfileRecord,
  'users/minimal': SimpleUser,
  'classes/student-details': ClassStudentDetailsRecord,
};

export interface ICollectionsState {
  assets: TStateRecord<IAssetRecord>;
  'assets/quotas': TStateRecord<IQuotaRecord>;
  centres: TStateRecord<ICentre>;
  'centres/eqa-stats': TStateRecord<ICentre>;
  classes: TStateRecord<IClassRecord>;
  'classes/class-options': TStateRecord<IClassOption>;
  courses: TStateRecord<IQualificationRecord>;
  'courses/dashboard-qualification-options': TStateRecord<
    IDashboardQualificationOption
  >;
  'courses/exam-boards': TStateRecord<IExamBoardRecord>;
  'courses/qualification-options': TStateRecord<IOptionRecord>;
  'courses/running-exam-specs': TStateRecord<IExamSpecRecord>;
  'courses/student-progress': TStateRecord<IQualificationStudentProgress>;
  'courses/units': TStateRecord<IUnitDetail>;
  'courses/unit-management': TStateRecord<IUnitManagementRecord>;
  imports: TStateRecord<IBulkUpload>;
  journals: TStateRecord<IJournalEntry>;
  'marksheets/checklists': TStateRecord<IChecklistRecord>;
  'marksheets/learning-outcome-blocks': TStateRecord<
    ILearningOutcomeBlockRecord
  >;
  tasks: TStateRecord<IUserTaskRecord>;
  'tasks/grades': TStateRecord<IUserGradeListTaskRecord>;
  'tasks/grade': TStateRecord<IUserTaskGrade>;
  'tasks/class': TStateRecord<IClassTaskRecord>;
  'tasks/class-task-markscheme': TStateRecord<IMarkschemeRecord>;
  'tasks/coursework-progress-overview': TStateRecord<
    ICourseworkProgressOverview
  >;
  'tasks/completion-attempts': TStateRecord<ICompletionAttempt>;
  'tasks/task-status-change-comments': TStateRecord<
    ITaskStatusChangeCommentRecord
  >;
  'tasks/tag-files': TStateRecord<ITaggingUserTask>;
  users: TStateRecord<IProfile>;
  'users/minimal': TStateRecord<ISimpleUser>;
  'classes/student-details': TStateRecord<IClassStudentDetails>;
}
export interface ICollectionsStateRecord
  extends Record.Instance<ICollectionsState>,
    Readonly<ICollectionsState> {}
export const CollectionStateRecord = Record<ICollectionsState>({
  assets: Map<string | undefined, ICollection<IAssetRecord>>(),
  'assets/quotas': Map<string | undefined, ICollection<IQuotaRecord>>(),
  centres: Map<string | undefined, ICollection<ICentre>>(),
  'centres/eqa-stats': Map<string | undefined, ICollection<ICentre>>(),
  classes: Map<string | undefined, ICollection<IClassRecord>>(),
  'classes/class-options': Map<string | undefined, ICollection<IClassOption>>(),
  'classes/student-details': Map<
    string | undefined,
    ICollection<IClassStudentDetails>
  >(),
  courses: Map<string | undefined, ICollection<IQualificationRecord>>(),
  'courses/dashboard-qualification-options': Map<
    string | undefined,
    ICollection<IDashboardQualificationOption>
  >(),
  'courses/exam-boards': Map<
    string | undefined,
    ICollection<IExamBoardRecord>
  >(),
  'courses/qualification-options': Map<
    string | undefined,
    ICollection<IOptionRecord>
  >(),
  'courses/running-exam-specs': Map<
    string | undefined,
    ICollection<IExamSpecRecord>
  >(),
  'courses/student-progress': Map<
    string | undefined,
    ICollection<IQualificationStudentProgress>
  >(),
  'courses/units': Map<string | undefined, ICollection<IUnitDetail>>(),
  'courses/unit-management': Map<
    string | undefined,
    ICollection<IUnitManagementRecord>
  >(),
  imports: Map<string | undefined, ICollection<IBulkUpload>>(),
  journals: Map<string | undefined, ICollection<IJournalEntry>>(),
  'marksheets/checklists': Map<
    string | undefined,
    ICollection<IChecklistRecord>
  >(),
  'marksheets/learning-outcome-blocks': Map<
    string | undefined,
    ICollection<ILearningOutcomeBlockRecord>
  >(),
  tasks: Map<string | undefined, ICollection<IUserTaskRecord>>(),
  'tasks/grades': Map<
    string | undefined,
    ICollection<IUserGradeListTaskRecord>
  >(),
  'tasks/grade': Map<string | undefined, ICollection<IUserTaskGrade>>(),
  'tasks/class': Map<string | undefined, ICollection<IClassTaskRecord>>(),
  'tasks/task-status-change-comments': Map<
    string | undefined,
    ICollection<ITaskStatusChangeCommentRecord>
  >(),
  'tasks/class-task-markscheme': Map<
    string | undefined,
    ICollection<IMarkschemeRecord>
  >(),
  'tasks/coursework-progress-overview': Map<
    string | undefined,
    ICollection<ICourseworkProgressOverview>
  >(),
  'tasks/completion-attempts': Map<
    string | undefined,
    ICollection<ICompletionAttempt>
  >(),
  'tasks/tag-files': Map<string | undefined, ICollection<ITaggingUserTask>>(),
  users: Map<string | undefined, ICollection<IProfile>>(),
  'users/minimal': Map<string | undefined, ICollection<ISimpleUser>>(),
});

// Please note that old-style collections is now DEPRECATED - please do not add any additional routes to this file
export const collectionsModule = collectionsFunctor(
  typeToRecordMapping,
  CollectionStateRecord
);
