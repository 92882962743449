import {
  hasSucceeded,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Alert,
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { List, Map, Seq, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { clearInviteStatus } from '../../actions/emails';
import { HIJACK_ACCOUNT, hijackAccount } from '../../actions/hijack';
import { openModal } from '../../actions/modals';
import { CREATE_USER } from '../../actions/users';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { userCanCreateUsers } from '../../permissions';
import { ICollectionsState } from '../../reducers/collections';
import { collectionsModule } from '../../reducers/collections';
import { IStore } from '../../store/';
import { ICentre } from '../../store/data-types/centres';
import {
  IProfile,
  TRole,
  TUserStatus,
  USER_STATUSES,
} from '../../store/data-types/profile';
import SearchableCentreSwitcher from '../centres/searchable-centre-switcher';
import CollectionSelectFilter from '../tables/collection-select-filter';
import { ADD_USER } from './add';
import BulkStatusUpdateModal from './bulk-status-update-modal';
import UserListCentreSearchWrapper from './list-centre-search-wrapper';
import RoleFilter from './role-filter';
import UserSearch, { ISearchForm } from './search';
import UserTableForm from './user-table-form';

const { actions: { getCollection, getAllCollection } } = collectionsModule;

const USER_STATUS_CHOICES = [
  {
    key: USER_STATUSES.ACTIVE,
    label: 'Active',
  },
  {
    key: USER_STATUSES.DISABLED,
    label: 'Disabled',
  },
  {
    key: USER_STATUSES.ARCHIVED,
    label: 'Archived',
  },
];

export const USERS_LIST = 'USERS_LIST';

interface IStudentsToUpdateStatusData {
  selectedStudents?: List<string>;
}

interface IProps {
  loading: boolean;
  profile: IProfile;
  userCreated: boolean;
  usersCollection: ICollection<IProfile>;
  openModal: typeof openModal;
  getCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    name?: string
  ): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    name?: string
  ): void;
  clearInviteStatus(): AxiosPromise;
}

export class UsersList extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.loadUsers = this.loadUsers.bind(this);
    this.search = this.search.bind(this);
    this.filterByRole = this.filterByRole.bind(this);
    this.bulkToggleUserStatus = this.bulkToggleUserStatus.bind(this);
    this.getCurrentStatusFilter = this.getCurrentStatusFilter.bind(this);
  }

  public componentWillUnmount() {
    this.props.clearInviteStatus();
  }

  public render() {
    const { loading, userCreated, usersCollection, profile } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <SpacedGroup className="float-right margin-vertical-base">
            <a
              href="/api/users/export/"
              target="_blank"
              className="button primary"
            >
              Export CSV
            </a>
            {userCanCreateUsers(profile) && (
              <Link to="/users/add/" className="button primary">
                Add user
              </Link>
            )}
          </SpacedGroup>
          <h2 className="font-size-large">Users List</h2>
        </ContentBoxHeader>

        {userCreated && (
          <Alert className="success">
            <p>New User Created!</p>
          </Alert>
        )}

        <Section>
          <Row>
            <Column sm={3}>
              <UserSearch
                loading={loading}
                onSubmit={this.search}
                placeholder="name, username or email"
                form="USER_SEARCH"
              />
            </Column>
            <Column sm={3}>
              <RoleFilter
                loading={loading}
                className="display-inline-block"
                changeRole={this.filterByRole}
                value={
                  usersCollection.filters && usersCollection.filters.get('role')
                    ? usersCollection.filters.get('role') as TRole
                    : undefined
                }
              />
            </Column>
            <Column sm={3}>
              <CollectionSelectFilter
                label="Status"
                name="status"
                filterKey="status"
                options={USER_STATUS_CHOICES}
                collection={usersCollection}
                pageSize={10}
                getCollection={(options: ICollectionOptions) =>
                  this.loadUsers(options)}
                excludeNull
              />
            </Column>
            <Column sm={3}>
              <SearchableCentreSwitcher
                className="display-inline-block"
                inputWrapper={UserListCentreSearchWrapper}
              />
            </Column>
          </Row>
        </Section>
        <UserTableForm
          loadUsers={this.loadUsers}
          onSubmit={this.bulkToggleUserStatus}
          loading={loading}
          actionText={
            this.getCurrentStatusFilter() === USER_STATUSES.ARCHIVED
              ? 'Restore'
              : 'Archive'
          }
          profile={profile}
        />
      </ContentBox>
    );
  }

  private getCurrentStatusFilter() {
    const { usersCollection } = this.props;
    const currentStatusFilter =
      usersCollection.filters &&
      (usersCollection.filters.get('status') as TUserStatus);
    return currentStatusFilter ? currentStatusFilter : USER_STATUSES.ACTIVE;
  }

  private bulkToggleUserStatus(data: IStudentsToUpdateStatusData) {
    const { usersCollection } = this.props;
    const selectedStudentIds = data.selectedStudents || List<string>();
    const selectedUserNames = usersCollection.results
      .filter(user => selectedStudentIds.contains(user.id))
      .map(user => user.osms_data.name);
    if (selectedStudentIds.count()) {
      this.props.openModal(
        <BulkStatusUpdateModal
          onComplete={this.loadUsers}
          inactiveStatusKey={USER_STATUSES.ARCHIVED}
          currentStatusFilter={this.getCurrentStatusFilter()}
          bulkUserNames={selectedUserNames}
          bulkUserIds={selectedStudentIds}
        />
      );
    }
  }

  private search({ search }: ISearchForm) {
    this.loadUsers({ filters: Map({ search: search || '' }) });
  }

  private filterByRole(role?: TRole) {
    const { usersCollection } = this.props;
    const currentFilters = usersCollection.filters || Map<string, string>();
    const filters = role
      ? currentFilters.set('role', role)
      : currentFilters.delete('role');
    this.loadUsers({ ...usersCollection, filters });
  }

  private loadUsers(options?: ICollectionOptions) {
    const { profile: { current_centre }, usersCollection } = this.props;
    const usersOptions = options || usersCollection;

    const collectionFilters = usersCollection.filters || Map<string, string>();
    const newFilters = (options && options.filters) || Map<string, string>();
    const mergedFilters = collectionFilters.merge(newFilters);
    const currentCentreId = current_centre && current_centre.id;
    const filters = currentCentreId
      ? mergedFilters.set('centre', currentCentreId)
      : mergedFilters;

    this.props.getCollection('users', { ...usersOptions, filters }, USERS_LIST);
  }
}

function mapStateToProps({ collectionsOld, responses, profile }: IStore) {
  const collection = getCollectionByName(collectionsOld.users, USERS_LIST);
  return {
    profile,
    loading:
      isPending(responses, GET_COLLECTION, 'users') ||
      isPending(responses, HIJACK_ACCOUNT),
    userCreated: hasSucceeded(responses, CREATE_USER, ADD_USER),
    usersCollection: getCollectionByName(collectionsOld.users, USERS_LIST),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getAllCollection,
  clearInviteStatus,
  openModal,
})(UsersList);
