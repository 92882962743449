import * as React from 'react';

import { FormErrors } from '../../utils';
import GroupField from './group-field';

const GENDERS = ['Male', 'Female', 'Other'];

interface IProps {
  formErrors?: FormErrors;
  disabled?: boolean;
}

export default class GenderSelect extends React.Component<IProps, void> {
  public render() {
    const { formErrors, disabled } = this.props;

    return (
      <GroupField
        name="gender"
        type="text"
        label="Gender"
        formErrors={formErrors}
        disabled={disabled}
        component={fieldProps => (
          <select {...fieldProps.input} disabled={disabled}>
            <option value="">Not Specified</option>
            {GENDERS.map(gender => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </select>
        )}
      />
    );
  }
}
