import * as Canvasimo from 'canvasimo';
import * as classNames from 'classnames';
import * as React from 'react';
import * as Slik from 'slik';

interface IProps {
  progress: number;
  displayPercentage?: boolean;
  size?: number;
  color?: string;
  className?: string;
}

interface IState {
  size: number;
  animated: number;
}

export class ProgressRing extends React.PureComponent<IProps, IState> {
  public canvas: Canvasimo;
  public animation: Slik.Animation;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      animated: 0,
      size: 0,
    };

    this.onResize = this.onResize.bind(this);
    this.onAnimate = this.onAnimate.bind(this);

    this.animation = new Slik.Animation({
      duration: 2000,
      ease: Slik.Easing.EaseInOutSine,
      from: 0,
      to: props.progress,
    });
  }

  public onAnimate(value: number) {
    this.setState({
      animated: value,
    });
  }

  public draw() {
    const { color = '#4B71AA', displayPercentage } = this.props;
    const { animated: progress } = this.state;
    const { canvas } = this;
    const { width } = canvas.getSize();
    const offset = width * 0.5;
    const fontSize = width * 0.3;
    const outerRadius = Math.max(Math.floor(width * 0.5) - 2, 0);
    const innerRadius = Math.max(Math.floor(width * 0.35) + 2, 0);
    const rotation = canvas.getRadiansFromDegrees(90);
    const filled = canvas.getRadiansFromDegrees(360 * progress);
    const full = Math.PI * 2;
    const percentageSign = displayPercentage ? '%' : '';
    const percentage = Math.round(Math.min(Math.max(progress, 0), 1) * 100);

    canvas
      .clearCanvas()
      .setStrokeWidth(1)
      .setFontFamily('arial')
      .setFontSize(fontSize)
      .setTextAlign('center')
      .setTextBaseline('middle')
      // Background
      .beginPath()
      .plotEllipse(offset, offset, outerRadius, outerRadius, rotation, 0, full)
      .plotEllipse(
        offset,
        offset,
        innerRadius,
        innerRadius,
        rotation,
        0,
        full,
        true
      )
      .closePath()
      .fill('#DDD')
      .stroke('#AAA')
      // Ring
      .beginPath()
      .plotEllipse(
        offset,
        offset,
        outerRadius,
        outerRadius,
        rotation,
        0,
        filled
      )
      .plotEllipse(
        offset,
        offset,
        innerRadius,
        innerRadius,
        rotation,
        filled,
        0,
        true
      )
      .closePath()
      .fill(color)
      .fillText(`${percentage}${percentageSign}`, offset, offset);
  }

  public onResize() {
    const rect = (this.refs.element as HTMLDivElement).getBoundingClientRect();
    const width = Math.floor(rect.width);

    if (this.state.size !== width) {
      this.setState({
        size: width,
      });
    }

    this.draw();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.progress !== this.props.progress) {
      this.animation
        .reset()
        .from(prevState.animated)
        .to(this.props.progress)
        .start();
    }

    this.onResize();
  }

  public componentDidMount() {
    this.canvas = new Canvasimo(this.refs.canvas as HTMLCanvasElement);
    this.onResize();

    this.animation.on('update', this.onAnimate).start();

    window.addEventListener('resize', this.onResize);
  }

  public componentWillUnmount() {
    this.animation.off('update', this.onAnimate);

    window.removeEventListener('resize', this.onResize);
  }

  public render() {
    const { size: stateSize } = this.state;
    const { size = stateSize, className } = this.props;

    return (
      <div ref="element" className={classNames('progress-ring', className)}>
        <canvas
          className="display-block"
          ref="canvas"
          style={{ width: size, height: size }}
          width={size * 2}
          height={size * 2}
        />
      </div>
    );
  }
}
