import { Button, Column, ContentBox, FormGroup, Row, Well } from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as ReactSelect from 'react-select';
import {
  DataShape,
  Field,
  FormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { CAN_ASSIGN_IQAS } from '../../permissions';
import { IStore } from '../../store';
import { IProfile, ISimpleUser } from '../../store/data-types/profile';
import { IQualificationRecord } from '../../store/data-types/qualifications';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';
import Loading from '../loading';
import IfHasRoles from '../permissions/if-has-roles';
import QualificationMandatoryUnitsWarning from '../qualification-mandatory-units-warning';
import Term, { terminologyFromProfile } from '../terminology';
import UserSelect from './class-form-user-select';

const { IconUser } = FontAwesome;

const CLASS_FORM = 'CLASS_FORM';

export interface IClassFormData {
  name?: string;
  description?: string;
  qualification?: string;
  teachers?: Set<string>;
  internal_quality_assurers?: Set<string>;
}

interface IQualificationSelectProps
  extends React.HTMLProps<JSX.Element>,
    WrappedFieldProps<void> {
  cannotChangeQualification: boolean;
  hasSelectedQualification: boolean;
  qualifications: List<IQualificationRecord>;
  qualificationId?: false | string;
}
function QualificationSelect({
  cannotChangeQualification,
  hasSelectedQualification,
  input,
  disabled,
  qualifications,
  qualificationId,
}: IQualificationSelectProps) {
  const onChange = input ? input.onChange : () => null;

  return (
    <div>
      <select
        onChange={onChange}
        value={input && input.value}
        disabled={disabled || cannotChangeQualification}
      >
        <option value="">None</option>
        {qualifications.map(qualification => (
          <option key={qualification.id} value={qualification.id}>
            {qualification.name}
          </option>
        ))}
      </select>
      {cannotChangeQualification && (
        <p className="info">Qualifications cannot be changed once assigned.</p>
      )}
      {hasSelectedQualification && (
        <QualificationMandatoryUnitsWarning qualificationId={qualificationId} />
      )}
    </div>
  );
}

interface IEditingOptions {
  mode: 'EDITING';
  hasQualification: boolean; // This can never be changed once assigned
  qualificationId?: string;
  currentUser: IProfile;
  canSelectTeachers: boolean;
}

interface ICreatingOptions {
  mode: 'CREATING';
  canSelectTeachers: boolean;
}

type TModeOptions = IEditingOptions | ICreatingOptions;

interface IClassFormProps
  extends React.HTMLProps<JSX.Element>,
    FormProps<DataShape, void, void> {
  errors?: FormErrors;
  loading: boolean;
  modeOptions: TModeOptions;
  qualifications: List<IQualificationRecord>;
  students: List<ISimpleUser>;
  teachers: List<ISimpleUser>;
  internalQualityAssurers: List<ISimpleUser>;
  updating: boolean;
  profile: IProfile;
}
export function ClassForm({
  errors,
  handleSubmit,
  loading,
  modeOptions,
  qualifications,
  students,
  updating,
  teachers,
  internalQualityAssurers,
  profile,
}: IClassFormProps) {
  if (loading) {
    return <Loading />;
  }

  const showTeachersSelect = modeOptions.canSelectTeachers;

  return (
    <ContentBox>
      <p className="info">
        Note: Once you have created your group you will need to attach it to a{' '}
        <b>Qualification</b>. For guidance on this process open 'The Centre
        Administrator Journey' guide, page 24 onwards, which can be accessed via
        the Help button at the top of this page.
      </p>

      <form onSubmit={handleSubmit}>
        <Row>
          <Column>
            <GroupField
              label="Name"
              name="name"
              type="text"
              formErrors={errors}
              disabled={updating}
            />

            <GroupField
              label="Description"
              name="description"
              type="text"
              component="textarea"
              formErrors={errors}
              disabled={updating}
            />

            {showTeachersSelect && (
              <GroupField
                label={terminologyFromProfile(profile, 'Teachers')}
                name="teachers"
                type="text"
                component={(props: WrappedFieldProps<void>) => (
                  <UserSelect
                    {...props}
                    users={teachers}
                    label={terminologyFromProfile(profile, 'Teachers')}
                    canRemoveUser={(userId: string, users: Set<string>) =>
                      modeOptions.mode === 'CREATING' ||
                      (modeOptions.mode === 'EDITING' &&
                        users.count() !== 1 &&
                        userId !== modeOptions.currentUser.id)}
                    multi
                  />
                )}
                formErrors={errors}
                disabled={updating}
              />
            )}
            <IfHasRoles roles={CAN_ASSIGN_IQAS}>
              <GroupField
                label={terminologyFromProfile(
                  profile,
                  'Internal Quality Assurers'
                )}
                name="internal_quality_assurers"
                type="text"
                component={(props: WrappedFieldProps<void>) => (
                  <UserSelect
                    {...props}
                    users={internalQualityAssurers}
                    label={terminologyFromProfile(
                      profile,
                      'Internal Quality Assurers'
                    )}
                    canRemoveUser={() => true}
                    multi
                  />
                )}
                formErrors={errors}
                disabled={updating}
              />
            </IfHasRoles>
            <FormGroup>
              {updating ? (
                <Loading />
              ) : (
                <Button type="submit" className="primary">
                  {modeOptions.mode === 'CREATING' ? (
                    <span>
                      Create <Term>Class</Term>
                    </span>
                  ) : (
                    'Save'
                  )}
                </Button>
              )}
              <FormErrorsRenderer
                formErrors={errors}
                errorKey="non_field_errors"
              />
            </FormGroup>
          </Column>
        </Row>
      </form>
    </ContentBox>
  );
}

export default reduxForm({ form: CLASS_FORM })(ClassForm);
