import * as classNames from 'classnames';
import * as React from 'react';

export const MATCH_FILE_PARTS = /^(.+)(\..+?)$/;

const EllipsizedFileName: React.SFC<
  React.HTMLProps<HTMLDivElement>
> = props => {
  const { className, children, ...remainingProps } = props;

  const result =
    typeof children === 'string' ? MATCH_FILE_PARTS.exec(children) : null;

  return (
    <span
      {...remainingProps}
      className={classNames('ellipsized-file-name', className)}
    >
      {result ? (
        <span className="ellipsized-file-name-wrapper">
          <span className={'ellipsized-file-name-name'}>{result[1]}</span>
        </span>
      ) : (
        <span className="ellipsized-file-name-wrapper">{children}</span>
      )}
    </span>
  );
};

export default EllipsizedFileName;
