import {
  Column,
  ContentBox,
  Row,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import * as _ from 'underscore';
import { openModal } from '../../actions/modals';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionItems } from '../../collections/reducers';
import { ICollectionOptions } from '../../collections/types';
import { UPDATE_ITEM } from '../../items/actions';
import {
  canCreateClass,
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
} from '../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../reducers/collections';
import { IItemsState } from '../../reducers/items';
import { IStore } from '../../store';
import { IQuotaRecord } from '../../store/data-types';
import { IClassRecord } from '../../store/data-types/classes';
import { IProfile } from '../../store/data-types/profile';
import { IUserTaskRecord } from '../../store/data-types/tasks';
import { convertBytesToMegaBytes, getFullName } from '../../utils';
import IfHasRoles from '../permissions/if-has-roles';
import { terminologyFromProfile } from '../terminology';
import ClassDetailBlog from './class-detail-class-blog';
import ClassDetailInfo from './class-detail-info';
import ClassDetailSpecialisedUserList from './class-detail-specialised-user-list';
import ClassDetailStudentDetails from './class-detail-student-details';
import ClassDetailStudentTable from './class-detail-student-table';
import ClassHeader from './class-header';
import RemoveStudentsConfirmModal from './remove-students-confirm-modal';

const { IconFileO, IconUserCircleO } = FontAwesome;

interface IStudentsToRemoveData {
  selectedStudents?: List<string>;
}

interface IExternalClassDetailTeacherProps {
  class: IClassRecord;
}

interface IClassDetailTeacherProps extends IExternalClassDetailTeacherProps {
  profile: IProfile;
  canEditClass: boolean;
  openModal: typeof openModal;
}

export const CLASS_DETAIL_TEACHER = 'CLASS_DETAIL_TEACHER';
const POPOVER = CLASS_DETAIL_TEACHER + '_POPOVER';

export class ClassDetailTeacher extends React.PureComponent<
  IClassDetailTeacherProps,
  void
> {
  public render() {
    const {
      class: { name, description, id },
      canEditClass,
      profile,
    } = this.props;
    const students = narrowToRecordArray(this.props.class.students);
    const teachers = narrowToRecordArray(this.props.class.teachers);
    const iqas = narrowToRecordArray(
      this.props.class.internal_quality_assurers
    );

    return (
      <ContentBox>
        <ClassHeader class={this.props.class} canEdit={canEditClass} />
        <Section className="padding-vertical-none">
          <ClassDetailInfo class={this.props.class} />
        </Section>
        <Section>
          <Row>
            <Column xs={12} md={6} lg={4}>
              <ClassDetailStudentDetails students={students} />
            </Column>
            <Column xs={12} md={6} lg={4}>
              <ClassDetailSpecialisedUserList
                users={teachers}
                label={terminologyFromProfile(profile, 'Teachers')}
              />
            </Column>
          </Row>
          <Row>
            <IfHasRoles roles={Set.of(DA_ADMIN, DA_SUPPORT, CENTRE_ADMIN)}>
              <Column xs={12} md={6} lg={4}>
                <ClassDetailSpecialisedUserList
                  users={iqas}
                  label={terminologyFromProfile(
                    profile,
                    'Internal Quality Assurers'
                  )}
                />
              </Column>
            </IfHasRoles>
          </Row>
        </Section>
        <Section className="padding-vertical-none">
          <Row>
            <Column>
              <ClassDetailBlog classId={id} />
            </Column>
          </Row>
        </Section>
        <Section>
          <ClassDetailStudentTable
            students={students}
            onSubmit={this.updateStudents.bind(this)}
            classId={id}
          />
        </Section>
      </ContentBox>
    );
  }

  private updateStudents(data: IStudentsToRemoveData) {
    const studentsToRemove = data.selectedStudents
      ? data.selectedStudents.toArray()
      : [];
    this.props.openModal(
      <RemoveStudentsConfirmModal
        group={this.props.class}
        studentsToRemove={studentsToRemove}
      />
    );
  }
}

function mapStateToProps(
  { responses, profile, uiState }: IStore,
  props: IExternalClassDetailTeacherProps
) {
  return {
    ...props,
    profile,
    canEditClass: canCreateClass(profile),
  };
}

export default connect(mapStateToProps, {
  openModal,
})(ClassDetailTeacher);
