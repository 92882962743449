import { Button, ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { DataShape, FormProps, reduxForm } from 'redux-form';

import { IAssetRecord } from '../../store/data-types';
import { EvidenceType } from '../../store/data-types/constants';
import { FormErrors } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';

export const FILE_PROPERTIES_FORM = 'FILE_PROPERTIES_FORM';

interface IProps {
  asset: IAssetRecord;
  formErrors?: FormErrors;
  loading: boolean;
  evidenceTypes: ReadonlyArray<EvidenceType>;
  readOnly: boolean;
  closeModal(): void;
  onSubmit(dataIn: any): any;
}

export class FilePropertiesForm extends React.PureComponent<
  FormProps<DataShape, {}, {}> & IProps,
  void
> {
  public render() {
    const {
      handleSubmit,
      loading,
      closeModal,
      formErrors,
      evidenceTypes,
      readOnly,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} disabled={readOnly}>
        <ModalBody>
          <GroupField
            name="user_supplied_name"
            type="text"
            label="File name"
            formErrors={formErrors}
          />
          <GroupField
            label="Evidence Type"
            name="evidence_type"
            type="select"
            component="select"
            disabled={loading || readOnly}
            formErrors={formErrors}
          >
            <option value="">(None)</option>
            {evidenceTypes.map(evidenceType => (
              <option value={evidenceType.key} key={evidenceType.key}>
                {evidenceType.value}
              </option>
            ))}
          </GroupField>
          <GroupField
            name="description"
            type="text"
            component="textarea"
            label="Description"
            formErrors={formErrors}
          />
          <FormErrorsRenderer
            formErrors={formErrors}
            errorKey="non_field_errors"
          />
        </ModalBody>
        <ModalFooter>
          <SpacedGroup className="float-right margin-vertical-large">
            <Button type="button" onClick={closeModal}>
              Close
            </Button>
            {!readOnly && (
              <Button
                type="submit"
                className="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </Button>
            )}
          </SpacedGroup>
        </ModalFooter>
      </form>
    );
  }
}

export default reduxForm({
  form: FILE_PROPERTIES_FORM,
})(FilePropertiesForm);
