import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface IProps {
  alt: string;
  src: string;
  fit?: 'cover' | 'contain'; // default: contain
  aspect?: string | number;
}

interface IPartialImageStyles {
  position: 'absolute' | 'relative';
  width: string;
  left: string;
  top: string;
}

interface IImageStyles extends IPartialImageStyles {
  height: 'auto';
  display: 'none' | 'block';
}

interface IState {
  imageStyles: IImageStyles;
}

const MATCHES_ASPECT_STRING = /^(\d+(?:\.\d+)?)[x:](\d+(?:\.\d+)?)$/i;

export default class ImageHolder extends React.PureComponent<
  IProps & React.HTMLAttributes<HTMLDivElement>,
  IState
> {
  public image?: HTMLImageElement;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      imageStyles: {
        display: 'none',
        height: 'auto',
        left: '0%',
        position: 'relative',
        top: '0%',
        width: '100%',
      },
    };

    this.fitImage = this.fitImage.bind(this);
    this.onLoadFitImage = this.onLoadFitImage.bind(this);
    this.onErrorDisplayError = this.onErrorDisplayError.bind(this);
  }

  public calculateImageStyles(
    fit: 'cover' | 'contain',
    image: HTMLImageElement,
    rect: ClientRect
  ): IPartialImageStyles {
    const containerAspect = rect.height / rect.width;
    const imageAspect = image.height / image.width;
    const position = 'absolute';

    if (
      (fit === 'contain' && containerAspect >= imageAspect) ||
      (fit === 'cover' && containerAspect < imageAspect)
    ) {
      return {
        left: '0%',
        position,
        top: (containerAspect - imageAspect) / 2 * 100 / containerAspect + '%',
        width: '100%',
      };
    } else if (
      (fit === 'contain' && containerAspect < imageAspect) ||
      (fit === 'cover' && containerAspect >= imageAspect)
    ) {
      const percentWidth = containerAspect / imageAspect * 100;
      return {
        left: (100 - percentWidth) / 2 + '%',
        position,
        top: '0%',
        width: percentWidth + '%',
      };
    }

    return {
      left: '0%',
      position: 'relative',
      top: '0%',
      width: '100%',
    };
  }

  public fitImage() {
    const { image } = this;
    const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();

    if (image && image.width && image.height && rect.width && rect.height) {
      const { fit = 'contain' } = this.props;

      const { imageStyles } = this.state;
      const newImageStyles = this.calculateImageStyles(fit, image, rect);

      this.setState({
        imageStyles: {
          ...imageStyles,
          ...newImageStyles,
          display: 'block',
        },
      });
    }
  }

  public onLoadFitImage(event: React.ChangeEvent<HTMLImageElement>) {
    this.image = event.target;

    const { imageStyles } = this.state;

    this.setState({
      imageStyles: {
        ...imageStyles,
        display: 'block',
      },
    });

    this.fitImage();
  }

  public onErrorDisplayError(event: React.ChangeEvent<HTMLImageElement>) {
    // console.error(event.target);
  }

  public render() {
    const {
      fit = 'contain',
      alt,
      src,
      aspect = 1,
      width,
      style,
      ...remainingProps,
    } = this.props;

    const { imageStyles } = this.state;

    const containerStyles: React.CSSProperties = {
      overflow: 'hidden',
      position: 'relative',
      ...style,
    };

    let calculatedAspect;

    if (typeof aspect === 'string') {
      const aspectParts = MATCHES_ASPECT_STRING.exec(aspect);
      calculatedAspect = aspectParts
        ? parseFloat(aspectParts[2]) / parseFloat(aspectParts[1])
        : 1;
    } else {
      calculatedAspect = aspect;
    }

    const aspectStyles: React.CSSProperties = {
      height: 0,
      left: 0,
      paddingBottom: 100 * calculatedAspect + '%',
      position: 'relative',
      top: 0,
      width: '100%',
    };

    return (
      <div {...remainingProps} style={containerStyles}>
        <div style={aspectStyles} />
        <img
          alt={alt}
          src={src}
          style={imageStyles}
          onLoad={this.onLoadFitImage}
          onError={this.onErrorDisplayError}
        />
      </div>
    );
  }
}
