import { List, Map, Record } from 'immutable';
import * as moment from 'moment';

import { IActivityEventRecord } from './activity-events';
import { RecordInstance } from './records';
import { IUserTaskRecord } from './tasks';

export type TTimelineData = IUserTaskRecord | IActivityEventRecord;
export type TTimelineDataList = List<TTimelineData>;
export type TTimelineGroupedData = Map<
  string,
  Map<string, List<TTimelineData>>
>;

export const isActivityEventRecord = (
  input: TTimelineData
): input is IActivityEventRecord =>
  'account_hijack' in (input as IActivityEventRecord | IUserTaskRecord);

export interface ITimeline {
  data: TTimelineDataList;
  groupedData: TTimelineGroupedData;
  periods: List<string>;
  today: moment.Moment;
  viewing: moment.Moment;
}

export type ITimelineRecord = RecordInstance<ITimeline>;

export const TimelineRecord = Record<ITimeline>({
  data: List(),
  groupedData: Map<string, Map<string, List<TTimelineData>>>(),
  periods: List(),
  today: moment.utc(),
  viewing: moment.utc(),
});
