import {
  anyPending,
  AsyncActionSet,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import { fromJS, List, Map, Seq, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, submit } from 'redux-form';
import * as _ from 'underscore';
import { closeModal } from '../../../actions/modals';
import {
  ITaggedAssetData,
  ITaggedAssetFormSection,
  TAG_ASSETS,
} from '../../../actions/tag-assets';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../../collections/types';
import {
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
} from '../../../permissions';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { DispatchCallback } from '../../../requests/types';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { AssetRecord, IAssetRecord } from '../../../store/data-types';
import {
  ILearningOutcomeBlockRecord,
  LearningOutcomeBlockRecord,
} from '../../../store/data-types/marksheets';
import {
  IProfile,
  ISimpleUser,
  TRole,
} from '../../../store/data-types/profile';
import { IUserTaskRecord, STATUS } from '../../../store/data-types/tasks';
import { ITaggingUserTask } from '../../../store/data-types/tasks/tagging';
import { FormErrors, getBlockIds } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import TagFilesForm, { TAG_FILES_FORM } from './tag-files-form';

const TAG_FILES_MODAL = 'TAG_FILES_MODAL';
const TAG_FILES_MODAL_OPEN_TASK = 'TAG_FILES_MODAL_OPEN_TASK';

export function filterTaskComponents(
  tasks: ReadonlyArray<ITaggingUserTask>,
  studentTaggingOnly: boolean
): ReadonlyArray<ITaggingUserTask> {
  return tasks.map(task => ({
    ...task,
    checklists: task.checklists.filter(
      checklist => !studentTaggingOnly || checklist.strand_learner_tagging
    ),
  }));
}

interface IExternalProps {
  individualTask?: IUserTaskRecord;
  isLearner: boolean;
  assetIds: List<string>;
  subTitle?: React.ReactChild;
  forUser?: ISimpleUser;
  afterSuccessfulTag?(): void;
}

interface IConnectedProps {
  submitting: boolean;
  submitted: boolean;
  profile: IProfile;
  loading: boolean;
  errors?: FormErrors;
}

interface IDispatchProps {
  closeModal: typeof closeModal;
  resetRequestState: typeof resetRequestState;
  submit(): void;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): AxiosPromise;
}

type IProps = IExternalProps & IConnectedProps & IDispatchProps;

export class TagFilesModal extends React.PureComponent<IProps, void> {
  public constructor(props: IProps) {
    super(props);
    this.onSuccessfulSubmit = this.onSuccessfulSubmit.bind(this);
  }

  public render() {
    const {
      submitting,
      submitted,
      loading,
      individualTask,
      subTitle,
      assetIds,
      isLearner,
      errors,
      forUser,
    } = this.props;

    const isLoading = loading || submitting || submitted;

    return (
      <Modal scrollable large onClickOutside={() => null}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.props.closeModal} />
          <p>
            Tag Files
            {subTitle && (
              <span className="display-block font-size-small margin-top-small">
                {subTitle}
              </span>
            )}
          </p>
        </ModalHeader>
        <ModalBody className="padding-none fix-table-column-headers">
          <TagFilesForm
            assetIds={assetIds}
            isLoading={isLoading}
            isLearner={isLearner}
            individualTask={individualTask}
            forUser={forUser}
            className={isLoading ? 'files-form-hidden' : ''}
            afterSuccessfulTag={this.onSuccessfulSubmit}
          />
        </ModalBody>
        <ModalFooter className="padding-vertical-large">
          <FormErrorsRenderer formErrors={errors} errorKey="task_assets" />
          <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors" />
          <SpacedGroup className="float-right">
            <Button
              type="button"
              onClick={this.props.closeModal}
              disabled={isLoading}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="primary"
              onClick={this.props.submit}
              disabled={isLoading}
            >
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </Modal>
    );
  }

  private onSuccessfulSubmit() {
    this.props.closeModal();
    if (this.props.afterSuccessfulTag) {
      this.props.afterSuccessfulTag();
    }
  }
}

function mapStateToProps(
  state: IStore,
  props: IExternalProps
): IExternalProps & IConnectedProps {
  const { responses } = state;

  const loading = anyPending(responses, [
    [GET_COLLECTION, 'tasks/tag-files', TAG_FILES_FORM],
    [GET_COLLECTION, 'assets', TAG_FILES_FORM],
  ]);

  return {
    ...props,
    submitting: isPending(responses, TAG_ASSETS),
    submitted: hasSucceeded(responses, TAG_ASSETS),
    profile: state.profile,
    errors: getFormErrors(responses, TAG_ASSETS),
    loading,
  };
}

export default connect(mapStateToProps, {
  closeModal,
  resetRequestState,
  submit: submit.bind(null, TAG_FILES_FORM),
})(TagFilesModal);
