import {
  anyPending,
  isPending,
} from '@dabapps/redux-api-collections/dist/requests';
import { Modal, ModalFooter, ModalHeader } from '@dabapps/roe';
import { narrowToRecordArray } from '@dabapps/simple-records';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import {
  ASSIGN_UNIT,
  assignUnit,
  IAssignUnitData,
  IClassTaskUnitData,
} from '../../../actions/qualifications';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import { ICollectionOptions } from '../../../collections/types';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { getFormErrors } from '../../../responses';
import { IStore } from '../../../store';
import { IClassRecord } from '../../../store/data-types/classes';
import { IQualificationRecord } from '../../../store/data-types/qualifications';
import {
  IClassTaskRecord,
  IUnitManagementRecord,
} from '../../../store/data-types/tasks';
import { formatDateForBackend, FormErrors } from '../../../utils';
import Loading from '../../loading';
import ModalCloseIcon from '../../modals/modal-close-icon';
import AssignUnitForm from './assign-unit-form';

interface IFormData {
  assignees?: Set<string>;
  start_date: string;
  end_date: string;
}

interface IManageUnitModalExternalProps {
  unit: IUnitManagementRecord;
  qualification: IQualificationRecord;
  taskClass: IClassRecord;
  onAfterAssign(): void;
}
interface IManageUnitModalInternalProps extends IManageUnitModalExternalProps {
  assigning: boolean;
  formErrors: FormErrors;
  loading: boolean;
  closeModal(): void;
  assignUnit(
    unitId: string,
    qualification: string,
    taskClass: string,
    data: IAssignUnitData
  ): AxiosPromise;
}
export class ManageUnitModal extends React.PureComponent<
  IManageUnitModalInternalProps,
  void
> {
  public constructor(props: IManageUnitModalInternalProps) {
    super(props);
    this.assignUnit = this.assignUnit.bind(this);
  }
  public render() {
    const {
      assigning,
      unit,
      loading,
      qualification,
      taskClass,
      formErrors,
    } = this.props;
    if (loading) {
      return <Loading />;
    }
    return (
      <Modal onClickOutside={() => this.props.closeModal()}>
        <ModalHeader>
          <ModalCloseIcon onClick={() => this.props.closeModal()} />
          <h3>
            Manage <strong>{unit.title}</strong> for{' '}
            <strong>{taskClass.name}</strong>.
          </h3>
        </ModalHeader>
        {unit.about && (
          <div
            className="info margin-vertical-large"
            dangerouslySetInnerHTML={{ __html: unit.about }}
          />
        )}
        <AssignUnitForm
          formErrors={formErrors}
          students={narrowToRecordArray(taskClass.students)}
          loading={assigning}
          minStart={qualification.start_date}
          maxEnd={qualification.end_date}
          onSubmit={this.assignUnit.bind(this)}
        />
      </Modal>
    );
  }

  private assignUnit(data: IFormData) {
    const { unit, taskClass, qualification } = this.props;
    const transformedData: IAssignUnitData = {
      ...data,
      assignees: data.assignees ? data.assignees.toArray() : [],
    };
    this.props
      .assignUnit(unit.id, qualification.id, taskClass.id, transformedData)
      .then(() => {
        this.props.closeModal();
        this.props.onAfterAssign();
      });
  }
}
function mapStateToProps(
  { responses }: IStore,
  props: IManageUnitModalExternalProps
) {
  return {
    ...props,
    assigning: isPending(responses, ASSIGN_UNIT),
    formErrors: getFormErrors(responses, ASSIGN_UNIT),
  };
}
export default connect(mapStateToProps, {
  closeModal,
  assignUnit,
})(ManageUnitModal);
