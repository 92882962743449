import { Column, Row } from '@dabapps/roe';
import * as React from 'react';

export default class Footer extends React.PureComponent<{}, void> {
  public render() {
    return (
      <Row className="footer margin-vertical-base">
        <Column xs={6}>
          <img src="/static/vtct_logo.png" />
        </Column>
        <Column className="text-align-right" xs={6}>
          <a href="#">Terms and conditions</a>{' '}
          <span className="copyright">
            &copy; DigitalAssess {new Date().getFullYear()}
          </span>
        </Column>
      </Row>
    );
  }
}
