import { isPending } from '@dabapps/redux-api-collections/dist/requests';
import { Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { GET_COLLECTION } from '../collections/actions';

import {
  CENTRE_ADMIN,
  isEQAUser,
  isIQAUser,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../permissions';
import { IStore } from '../store/';
import { IProfile } from '../store/data-types/profile';
import CentreLandingEQA from './dashboard/centre-landing-eqa';
import ClassTaskTableEQA from './dashboard/class-task-table-eqa';
import ClassTaskTableIQA from './dashboard/class-task-table-iqa';
import DashboardMenu from './dashboard/dashboard-menu';
import DashboardQualifications from './dashboard/dashboard-qualifications';
import LoggedOut from './logged-out';

import Loading from './loading';
import LoadingSpinner from './loading-spinner';

export const COURSES_DASHBOARD = 'COURSES_DASHBOARD';

interface IProps {
  profile: IProfile;
  isInactiveUser?: boolean;
  pathname: string;
  loading: boolean;
  hideNav?: boolean;
}

export class LandingPage extends React.PureComponent<IProps, void> {
  public render() {
    const { profile, isInactiveUser, loading, pathname } = this.props;

    if (!profile.id) {
      return <LoggedOut isInactiveUser={isInactiveUser} />;
    }

    if (loading) {
      return (
        <div>
          <LoadingSpinner />
          <Loading />
        </div>
      );
    }

    // Handle specific dashboard components
    if (isIQAUser(profile)) {
      return (
        <div>
          <DashboardMenu pathname={pathname} />
          <ClassTaskTableIQA />
          <DashboardQualifications />
        </div>
      );
    } else if (isEQAUser(profile)) {
      if (profile.current_centre) {
        return (
          <div>
            <DashboardMenu pathname={pathname} />
            <ClassTaskTableEQA />
            <DashboardQualifications />
          </div>
        );
      }
      return (
        <div>
          <CentreLandingEQA />
        </div>
      );
    } else if (userHasRoles(profile, Set.of(STUDENT, TEACHER, CENTRE_ADMIN))) {
      return (
        <div>
          <DashboardMenu pathname={pathname} />
          <DashboardQualifications />
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps({
  profile,
  responses,
  routing: { locationBeforeTransitions: { pathname } },
}: IStore) {
  return {
    profile,
    loading: isPending(responses, GET_COLLECTION, COURSES_DASHBOARD),
    pathname,
  };
}

export default connect(mapStateToProps, {})(LandingPage);
