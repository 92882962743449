import {
  anyPending,
  hasSucceeded,
  resetRequestState,
} from '@dabapps/redux-api-collections/dist/requests';
import { AxiosPromise } from 'axios';
import { List, Map, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { formValueSelector } from 'redux-form';
import { GET_COLLECTION, ITEMS_PER_PAGE } from '../../../collections/actions';
import { getCollectionItems } from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
  TFilters,
} from '../../../collections/types';
import { LOAD_ITEM, UPDATE_ITEM } from '../../../items/actions';
const { IconFileTextO, IconStackOverflow } = FontAwesome;
import {
  Alert,
  Button,
  Column,
  ContentBox,
  ContentBoxHeader,
  ModalBody,
  Row,
  Section,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Well,
} from '@dabapps/roe';
import { narrowToRecord } from '@dabapps/simple-records';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';
import { IRouteProps as IBaseRouteProps } from '../../../';
import { closeModal, openModal } from '../../../actions/modals';
import {
  ALLOW_ANOTHER_COMPLETION_ATTEMPT,
  allowAnotherCompletionAttempt,
  clearTaskUpdateStatus,
  resetTargetTaskStatus,
  setTargetTaskStatus,
} from '../../../actions/tasks';
import {
  CENTRE_ADMIN,
  DA_ADMIN,
  DA_SUPPORT,
  EXTERNAL_QUALITY_ASSURER,
  getViewingStudentIdIfRole,
  INTERNAL_QUALITY_ASSURER,
  STUDENT,
  TEACHER,
  userHasRoles,
} from '../../../permissions';
import {
  getEQATaskStatusPermission,
  getIQATaskStatusPermission,
} from '../../../permissions/marksheets';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { IItemsState, itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import { AssetRecord, IAssetRecord } from '../../../store/data-types';
import { TStatus } from '../../../store/data-types/common';
import { MARKING_CODES, TMarkingCode } from '../../../store/data-types/courses';
import { IJournalEntry } from '../../../store/data-types/journals';
import {
  IChecklistRecord,
  ILearningOutcomeBlockRecord,
} from '../../../store/data-types/marksheets';
import { IProfile, TRole } from '../../../store/data-types/profile';
import {
  INFINITE,
  IUnitDetail,
  IUserTaskRecord,
  STATUS,
} from '../../../store/data-types/tasks';
import { formatDate, getLearningOutcomeBlockResults } from '../../../utils';
import MarksheetComments, {
  MARKSHEET_COMMENTS,
} from '../../comments/marksheet-comments';
import EllipsizedFileName from '../../ellipsized-file-name';
import AddFilesModal from '../../files/add-files-modal';
import AddNewOrExistingFiles from '../../files/add-new-or-existing-files';
import FileIcon from '../../files/file-icon';
import Loading from '../../loading';
import ConfirmModal from '../../modals/confirm-modal';
import SuccessMessage from '../../success-message';
import { default as Term, terminologyFromProfile } from '../../terminology';
import EditUserTaskModal from '../edit/edit-user-task-modal';
import ExamResultsIndicator from '../exam-results-indicator';
import GradeIndicator from '../grade-indicator';
import StatusIndicator from '../status-indicator';
import AllowAnotherAttemptForm, {
  FORM_NAME as ALLOW_ANOTHER_ATTEMPT_FORM_NAME,
} from './allow-another-attempt-form';
import Asset from './asset';
import CompletionAttemptTable from './completion-attempts-table';
import Description from './description';
import JournalItem from './journal-item';
import OffTheJobTrainingSection from './off-the-job-training-section';
import ProgressSection from './progress-section';
import SelectTaskFilesModal from './select-task-files-modal';
import StatusTransitionCommentBox, {
  getStatusTransitionCommentValue,
} from './status-transition-comment-box';

const { actions: { loadItem, patchItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;

const MARKSHEET = 'MARKSHEET';
const SINGLE_ROW_COLUMN_COUNT = 12;
const COMPLETION_ATTEMPTS_COLUMNS = 8;

interface IRouteProps extends IBaseRouteProps {
  params: {
    id: string;
    student?: string;
  };
}

interface IPropsWithoutActions {
  assets: List<IAssetRecord>;
  checklists: List<IChecklistRecord>;
  isStudent: boolean;
  isTeacher: boolean;
  isEQA: boolean;
  isIQA: boolean;
  journalEntries: List<IJournalEntry>;
  profile: IProfile;
  selfMark: boolean;
  studentAssets: List<IAssetRecord>;
  task: IUserTaskRecord | null;
  isArchivedGroup: boolean;
  teacherAssets: List<IAssetRecord>;
  iqaAssets: List<IAssetRecord>;
  unit: IUnitDetail | null;
  loading: boolean;
  succeeded: boolean;
  taskDatesUpdated: boolean;
  anotherAttemptAllowedSuccessfully: boolean;
  location: {
    pathname: string;
  };
  statusTransitionComment: string | null;
  targetTaskStatus: TStatus | null;
  allowAnotherAttemptReason: string;
}
interface IProps extends IPropsWithoutActions {
  resetRequestState: typeof resetRequestState;
  resetTargetTaskStatus: typeof resetTargetTaskStatus;
  setTargetTaskStatus: typeof setTargetTaskStatus;
  learningOutcomeBlockResults: List<ILearningOutcomeBlockRecord>;
  loadItem(type: keyof IItemsState, itemId: string): AxiosPromise;
  getAllCollection(
    type: keyof ICollectionsState,
    options: ICollectionOptions,
    tag: string
  ): AxiosPromise;
  openModal(component: React.ReactNode): void;
  closeModal(): void;
  clearTaskUpdateStatus(): void;
  patchItem(
    type: keyof ICollectionsState,
    id: string,
    data: { [key: string]: any }
  ): AxiosPromise;
  allowAnotherCompletionAttempt(taskId: string, reason: string): AxiosPromise;
}

export class Marksheet extends React.PureComponent<IRouteProps & IProps, void> {
  public constructor(props: IRouteProps & IProps) {
    super(props);

    this.reloadTask = this.reloadTask.bind(this);
    this.outOfAttemptsGiveMoreButton = this.outOfAttemptsGiveMoreButton.bind(
      this
    );
    this.addFilesLinks = this.addFilesLinks.bind(this);
    this.renderInProgressToAwaitingMarking = this.renderInProgressToAwaitingMarking.bind(
      this
    );
    this.renderAwaitingMarkingToMarked = this.renderAwaitingMarkingToMarked.bind(
      this
    );
    this.renderTransitionToPassedIQA = this.renderTransitionToPassedIQA.bind(
      this
    );
    this.renderAwaitingMarkingToInProgress = this.renderAwaitingMarkingToInProgress.bind(
      this
    );
    this.renderMarkedToAwaitingMarking = this.renderMarkedToAwaitingMarking.bind(
      this
    );
    this.renderPassedIQAToMarked = this.renderPassedIQAToMarked.bind(this);
    this.renderTransitionToEQAPassed = this.renderTransitionToEQAPassed.bind(
      this
    );
    this.renderHandInButtons = this.renderHandInButtons.bind(this);
    this.onUntagAsset = this.onUntagAsset.bind(this);
    this.allowAnotherCompletionAttempt = this.allowAnotherCompletionAttempt.bind(
      this
    );
    this.tagNewAssetsToTask = this.tagNewAssetsToTask.bind(this);
    this.getLockedAssetIds = this.getLockedAssetIds.bind(this);
    this.loadComments = this.loadComments.bind(this);
  }

  public componentWillMount() {
    this.props.resetTargetTaskStatus();
    this.props.clearTaskUpdateStatus();
    this.props.loadItem('tasks', this.props.params.id).then(response =>
      Promise.all([
        this.props.loadItem(
          'courses/units',
          response.data.class_task.component.unit.id
        ),
        this.props.getAllCollection(
          'assets',
          {
            filters: Map({
              owner__id: response.data.user.id,
            }),
          },
          MARKSHEET
        ),
      ])
    );

    this.props.getAllCollection(
      'journals',
      {
        filters: Map({
          user_task_id: this.props.params.id,
        }),
      },
      MARKSHEET
    );

    this.props.getAllCollection(
      'marksheets/checklists',
      { filters: Map({ user_task: this.props.params.id }) },
      MARKSHEET
    );

    this.loadComments();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (
      !this.props.anotherAttemptAllowedSuccessfully &&
      nextProps.anotherAttemptAllowedSuccessfully
    ) {
      this.props.closeModal();
    }
  }

  public render() {
    const {
      checklists,
      closeModal,
      openModal,
      isStudent,
      isTeacher,
      isIQA,
      isEQA,
      journalEntries,
      loading,
      selfMark,
      studentAssets,
      task,
      isArchivedGroup,
      teacherAssets,
      iqaAssets,
      unit,
      succeeded,
      location,
      profile,
      learningOutcomeBlockResults,
      anotherAttemptAllowedSuccessfully,
      targetTaskStatus,
      taskDatesUpdated,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!task || !unit) {
      return (
        <p>
          <Term>Task</Term> not found.
        </p>
      );
    }

    const noMoreAttempts = task.remaining_completion_attempts <= 0;
    const oneOrLessAttempts = task.remaining_completion_attempts <= 1;
    const hasInfiniteAttempts = task.remaining_completion_attempts === INFINITE;

    const completionAttemptsRowRemaingColumns =
      hasInfiniteAttempts || isStudent
        ? SINGLE_ROW_COLUMN_COUNT
        : SINGLE_ROW_COLUMN_COUNT - COMPLETION_ATTEMPTS_COLUMNS;

    const viewingStudentId = getViewingStudentIdIfRole(
      profile,
      this.props.params.student
    );
    const baseChecklistLink = `/tasks/marksheet/${task.id}/checklist/`;
    const checklistLink = viewingStudentId
      ? `/users/student/${viewingStudentId}${baseChecklistLink}`
      : baseChecklistLink;

    return (
      <ContentBox className="marksheet">
        <ContentBoxHeader>
          <h3>
            <Term>Marksheet</Term>
            {task.class_task.task_class.is_archived && (
              <span>
                {' '}
                (ARCHIVED <Term>CLASS</Term>)
              </span>
            )}
          </h3>
        </ContentBoxHeader>

        {isTeacher &&
          succeeded &&
          task.status === STATUS.IN_PROGRESS && (
            <SuccessMessage>
              Successfully handed back to <Term>Student</Term>
            </SuccessMessage>
          )}

        {succeeded &&
          task.status === STATUS.AWAITING_MARKING &&
          (isIQA ? (
            <SuccessMessage>
              Successfully handed back to <Term>Teacher</Term>
            </SuccessMessage>
          ) : targetTaskStatus === STATUS.MARKED ? (
            <SuccessMessage>
              Marked successfully but the <Term>Student</Term> failed - they
              have {task.remaining_completion_attempts} completion attempts
              remaining. Status has been set back to awaiting marking.
            </SuccessMessage>
          ) : (
            <SuccessMessage>Successfully handed in for marking</SuccessMessage>
          ))}

        {succeeded &&
          task.status === STATUS.MARKED &&
          isTeacher &&
          (isEQA ? (
            <SuccessMessage>
              Successfully handed back to <Term>IQA</Term>
            </SuccessMessage>
          ) : (
            <SuccessMessage>Successfully marked</SuccessMessage>
          ))}

        {succeeded &&
          task.status === STATUS.PASSED_IQA &&
          isIQA && <SuccessMessage>Successfully passed IQA</SuccessMessage>}

        {succeeded &&
          task.status === STATUS.PASSED_EQA &&
          isEQA && <SuccessMessage>Successfully passed EQA</SuccessMessage>}

        {anotherAttemptAllowedSuccessfully && (
          <SuccessMessage>Successfully allowed another attempt</SuccessMessage>
        )}

        {taskDatesUpdated && (
          <SuccessMessage>
            <Term>Task</Term> dates updated.
          </SuccessMessage>
        )}

        <Section>
          <Row>
            <Column>
              <h3>
                <strong>{task.class_task.component.title}</strong> for{' '}
                {narrowToRecord(task.user).osms_data.name} ({narrowToRecord(task.user).osms_data.username})
              </h3>
              <div className="dates">
                <p>
                  <span>
                    Start date: <strong>{formatDate(task.start_date)}</strong>
                  </span>
                  <span>
                    End date: <strong>{formatDate(task.end_date)}</strong>
                  </span>
                  {!isStudent &&
                    !isEQA && (
                      <a
                        onClick={() =>
                          openModal(<EditUserTaskModal userTask={task} />)}
                      >
                        Edit Dates
                      </a>
                    )}
                </p>
              </div>
            </Column>
          </Row>
          <Row>
            <Column md={completionAttemptsRowRemaingColumns / 2}>
              <ul className="list-style-none list-item-margin-small">
                <li>
                  <strong>Status: </strong>
                  <StatusIndicator task={task}>
                    <GradeIndicator
                      grade={task.grade}
                      prefix=": "
                      loading={false}
                    />
                    <ExamResultsIndicator
                      examResults={task.exam_results}
                      prefix=" "
                    />
                  </StatusIndicator>
                </li>
                {!hasInfiniteAttempts && (
                  <li>
                    <strong>Remaining attempts: </strong>
                    <span className={oneOrLessAttempts ? 'error' : 'warning'}>
                      {noMoreAttempts
                        ? 'None'
                        : task.remaining_completion_attempts}
                    </span>
                  </li>
                )}
                {!isArchivedGroup &&
                  !hasInfiniteAttempts &&
                  noMoreAttempts &&
                  isIQA && <li>{this.outOfAttemptsGiveMoreButton()}</li>}
              </ul>
            </Column>
            {!hasInfiniteAttempts &&
              !isStudent && (
                <Column md={COMPLETION_ATTEMPTS_COLUMNS}>
                  <CompletionAttemptTable taskId={task.id} />
                </Column>
              )}
            <Column md={completionAttemptsRowRemaingColumns / 2}>
              <SpacedGroup className="md-float-right margin-top-base">
                <Link className="button primary" to={checklistLink}>
                  Checklist
                </Link>
              </SpacedGroup>
            </Column>
          </Row>
        </Section>

        {learningOutcomeBlockResults.count() > 0 && (
          <Section>
            <Table striped bordered fill>
              <TableHead>
                <TableRow>
                  <TableHeader>Title</TableHeader>
                  <TableHeader>Result</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {learningOutcomeBlockResults.map(block => (
                  <TableRow key={block.id}>
                    <TableCell>{block.block.title}</TableCell>
                    <TableCell>
                      {block.recorded_result ? (
                        <span>
                          <span>{block.recorded_result}</span>
                          {block.external_result_description && (
                            <span>
                              {' - '}
                              <span className="info">
                                {block.external_result_description}
                              </span>
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="info">Nothing to display</span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        )}

        <Section>
          <Row>
            <Column xs={12}>
              <h3>
                Uploaded by <Term>Student</Term>
              </h3>

              <Well className="file-list">
                <ul className="files">
                  {studentAssets.map(asset => (
                    <Asset
                      key={asset.id}
                      asset={asset}
                      task={task}
                      onUntagAsset={this.onUntagAsset}
                      canTag={
                        (isStudent &&
                          task.can_owner_untag_files_or_journal_posts) ||
                        (isTeacher &&
                          task.can_teacher_untag_files_or_journal_posts)
                      }
                      selfMark={selfMark}
                      isLearner={isStudent}
                      tagModalSubTitle={`${task.class_task.component
                        .title} for ${narrowToRecord(task.user).osms_data
                        .name} (${narrowToRecord(task.user).osms_data
                        .username})`}
                    />
                  ))}
                </ul>
              </Well>
            </Column>
            {task.can_owner_untag_files_or_journal_posts &&
              !isTeacher &&
              this.addFilesLinks(STUDENT)}
            <Column xs={12}>
              <h3>
                Uploaded by <Term>Teacher</Term>
              </h3>

              <Well className="file-list">
                <ul className="files">
                  {teacherAssets.map(asset => (
                    <Asset
                      key={asset.id}
                      asset={asset}
                      task={task}
                      onUntagAsset={this.onUntagAsset}
                      canTag={
                        isTeacher &&
                        task.can_teacher_untag_files_or_journal_posts
                      }
                      selfMark
                      isLearner={false}
                      tagModalSubTitle={`${task.class_task.component
                        .title} for ${narrowToRecord(task.user).osms_data
                        .name} (${narrowToRecord(task.user).osms_data
                        .username})`}
                    />
                  ))}
                </ul>
              </Well>
            </Column>
            {task.can_teacher_untag_files_or_journal_posts &&
              isTeacher &&
              this.addFilesLinks(TEACHER)}
            {(isTeacher || isIQA || isEQA) && (
              <Column xs={12}>
                <h3>
                  Uploaded by <Term>Internal Quality Assurer</Term>
                </h3>

                <Well className="file-list">
                  <ul className="files">
                    {iqaAssets.map(asset => (
                      <Asset
                        key={asset.id}
                        asset={asset}
                        task={task}
                        onUntagAsset={this.onUntagAsset}
                        canTag={
                          isIQA && task.can_iqa_untag_files_or_journal_posts
                        }
                        selfMark
                        isLearner={false}
                        tagModalSubTitle={`${task.class_task.component
                          .title} for ${narrowToRecord(task.user).osms_data
                          .name} (${narrowToRecord(task.user).osms_data
                          .username})`}
                      />
                    ))}
                  </ul>
                </Well>
              </Column>
            )}
            {isIQA &&
              task.can_iqa_untag_files_or_journal_posts &&
              this.addFilesLinks(INTERNAL_QUALITY_ASSURER)}
          </Row>
        </Section>

        <Section>
          <Row>
            <Column xs={12}>
              <h3>Related posts</h3>
              {journalEntries.map(entry => (
                <JournalItem
                  key={entry.id}
                  journalEntry={entry}
                  location={location}
                />
              ))}
            </Column>
          </Row>
        </Section>

        {checklists.map(checklist => (
          <ProgressSection
            key={checklist.id}
            checklist={checklist}
            profile={profile}
          />
        ))}
        {task.class_task.component.off_job_training_percent && (
          <OffTheJobTrainingSection
            task={task}
            journalEntries={journalEntries}
          />
        )}
        <Section>
          <Row>
            <Column>
              <h3>Description</h3>

              <Description component={task.class_task.component} />
            </Column>
          </Row>
        </Section>

        <Section>
          <Row>
            <Column xs={12}>
              <h3>
                <Term>Task</Term> Resources
              </h3>
              {unit.resources.map(resource => (
                <Column xs={4} key={resource.id}>
                  <div className="file-info">
                    <ul>
                      <li>
                        <a target="_blank" href={resource.url}>
                          <span className="file-icon">
                            <FileIcon fileName={resource.url} />
                          </span>
                          <EllipsizedFileName>
                            {resource.filename}
                          </EllipsizedFileName>
                        </a>
                      </li>
                      <li
                        className="white-space-normal"
                        dangerouslySetInnerHTML={{
                          __html: resource.description,
                        }}
                      />
                    </ul>
                  </div>
                </Column>
              ))}
            </Column>
          </Row>
        </Section>

        <MarksheetComments task={task} loadComments={this.loadComments} />

        {!isArchivedGroup && this.renderHandInButtons()}
      </ContentBox>
    );
  }

  private reloadTask() {
    this.props.loadItem('tasks', this.props.params.id);
  }

  private allowAnotherCompletionAttempt() {
    const { allowAnotherAttemptReason, task } = this.props;
    if (task) {
      this.props
        .allowAnotherCompletionAttempt(task.id, allowAnotherAttemptReason)
        .then(this.reloadTask);
    }
  }

  private outOfAttemptsGiveMoreButton() {
    const {
      task,
      openModal,
      closeModal,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    const completionAttempts = task.class_task.component.completion_attempts;

    return (
      <Button
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title="Are you sure you want to allow one more attempt for this work?"
              body={
                <ModalBody>
                  <p>
                    The <Term>Student</Term> will get one more attempt at
                    completing this.
                  </p>
                  <AllowAnotherAttemptForm />
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={this.allowAnotherCompletionAttempt}
            />
          )}
      >
        Allow one more attempt
      </Button>
    );
  }

  private tagNewAssetsToTask(taggerRole: TRole, newAssets: List<string>) {
    const { task } = this.props;
    if (task) {
      return this.props
        .patchItem('tasks', task.id, {
          task_assets: task.task_assets.concat(
            newAssets
              .map(each => ({ asset: each, tagger_role: taggerRole }))
              .toArray()
          ),
        })
        .then(() => {
          this.props.resetRequestState(UPDATE_ITEM, 'tasks');
          this.props.getAllCollection(
            'assets',
            {
              filters: Map({
                owner__id: narrowToRecord(task.user).id,
              }),
            },
            MARKSHEET
          );
        });
    }
  }

  private getLockedAssetIds(
    task: IUserTaskRecord,
    assetsNotTaggedToJournals: List<IAssetRecord>,
    taggerRole: TRole
  ): List<string> {
    const { profile } = this.props;

    switch (taggerRole) {
      case STUDENT:
        const isTaggedBySomeoneOtherThanTheStudent = (asset: IAssetRecord) =>
          Boolean(
            task.task_assets.filter(
              taskAsset =>
                asset.id === taskAsset.asset &&
                taskAsset.tagger_role !== 'STUDENT'
            ).length
          );

        const isCreatedByTheStudent = (asset: IAssetRecord) =>
          asset.created_by === narrowToRecord(task.user).id;

        return assetsNotTaggedToJournals
          .filter(
            each =>
              isTaggedBySomeoneOtherThanTheStudent(each) ||
              !isCreatedByTheStudent(each)
          )
          .map(each => each.id);
      case INTERNAL_QUALITY_ASSURER:
        // iqa cannot tag and untag files that arent their own
        return assetsNotTaggedToJournals
          .filterNot(each => each.created_by === profile.id)
          .map(each => each.id);
      case TEACHER:
        // teacher cannot tag or untag files that arent their own of the students
        return assetsNotTaggedToJournals
          .filterNot(
            each =>
              each.created_by === profile.id ||
              each.created_by === narrowToRecord(task.user).id
          )
          .map(each => each.id);
      default:
        return List<string>();
    }
  }

  private addFilesLinks(taggerRole: TRole) {
    const props = this.props;
    const { assets, task, profile, journalEntries, isEQA } = props;
    if (isEQA) {
      return null;
    }
    if (task) {
      // Exclude Assets linked via Journal, as they cannot be removed conventionally
      const journalAssets = journalEntries.flatMap(
        entry => entry.assets
      ) as List<string>;

      const assetsNotTaggedToJournals = assets.filterNot(asset =>
        journalAssets.contains(asset.id)
      );

      const lockedAssetIds = this.getLockedAssetIds(
        task,
        assetsNotTaggedToJournals,
        taggerRole
      );

      return (
        <Column xs={12}>
          <AddNewOrExistingFiles
            onNewClick={() => {
              props.openModal(
                <AddFilesModal
                  ownerUserId={narrowToRecord(task.user).id}
                  afterSuccessfulUpload={this.tagNewAssetsToTask.bind(
                    this,
                    taggerRole
                  )}
                />
              );
            }}
            onExistingClick={() => {
              props.openModal(
                <SelectTaskFilesModal
                  subTitle={`${task.class_task.component
                    .title} for ${narrowToRecord(task.user).osms_data
                    .name} (${narrowToRecord(task.user).osms_data.username})`}
                  assets={assetsNotTaggedToJournals}
                  profile={profile}
                  lockedAssets={lockedAssetIds}
                  taggerRole={taggerRole}
                  onChangeAssets={(updatedAssets: List<string>) => {
                    const existingTaskAssets = task.task_assets;
                    const existingAssets = existingTaskAssets.map(
                      each => each.asset
                    );
                    const newAssets = updatedAssets.filter(
                      each => existingAssets.indexOf(each) === -1
                    );
                    const newTaskAssets = newAssets
                      .map(asset => ({
                        tagger_role: taggerRole,
                        asset,
                      }))
                      .toArray();

                    const updatedTaskAssets = existingTaskAssets
                      .filter(each => updatedAssets.indexOf(each.asset) !== -1) // remove any we have removed
                      .concat(newTaskAssets); // add any we have added

                    this.props
                      .patchItem('tasks', task.id, {
                        task_assets: updatedTaskAssets,
                      })
                      .then(() =>
                        this.props.resetRequestState(UPDATE_ITEM, 'tasks')
                      );
                  }}
                />
              );
            }}
          />
        </Column>
      );
    }
    return null;
  }

  private renderInProgressToAwaitingMarking() {
    const {
      task,
      isStudent,
      isTeacher,
      profile,
      openModal,
      closeModal,
    } = this.props;
    if (!task) {
      return;
    }
    const handInConfirmTitle = isTeacher
      ? `Are you sure you want to hand in all work for this ${terminologyFromProfile(
          profile,
          'Task'
        )}, to be marked, on behalf of this ${terminologyFromProfile(
          profile,
          'Student'
        )}?`
      : `Are you sure you want to hand in all work for this ${terminologyFromProfile(
          profile,
          'Task'
        )}, to be marked?`;
    const handInConfirmBodyText = isTeacher
      ? `The ${terminologyFromProfile(
          profile,
          'Student'
        )} will not be able to add additional work if you do this.`
      : 'You will not be able to add additional work if you do this.';

    return (
      <Button
        className="tertiary"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title={handInConfirmTitle}
              body={<p>{handInConfirmBodyText}</p>}
              okayLabel="Hand in"
              okayClass="tertiary"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem('tasks', task.id, {
                  status: STATUS.AWAITING_MARKING,
                });
              }}
            />
          )}
      >
        Hand In
      </Button>
    );
  }

  private renderAwaitingMarkingToMarked() {
    const {
      task,
      isStudent,
      isTeacher,
      profile,
      openModal,
      closeModal,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }
    return (
      <Button
        className="tertiary"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title="Are you sure you want to set this work as marked?"
              body={
                <ModalBody>
                  <p>You will not be able to mark it further.</p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>Comment to IQA</strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Mark"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.MARKED,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.MARKED }
                );
                this.props.setTargetTaskStatus(STATUS.MARKED);
              }}
            />
          )}
      >
        Request IQA review
      </Button>
    );
  }

  private renderTransitionToPassedIQA() {
    const { task, openModal, closeModal, statusTransitionComment } = this.props;
    if (!task) {
      return;
    }

    return (
      <Button
        className="tertiary"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title="Are you sure you want to approve this work?"
              body={
                <ModalBody>
                  <p>
                    You will not be able to make further changes. The{' '}
                    <Term>Student</Term> and <Term>Teacher(s)</Term> will also
                    not be able to make further changes.
                  </p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>Comment to EQA</strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.PASSED_IQA,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.PASSED_IQA }
                );
              }}
            />
          )}
      >
        Accept
      </Button>
    );
  }

  private renderAwaitingMarkingToInProgress() {
    const {
      task,
      openModal,
      closeModal,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    return (
      <Button
        className="error"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title={`Are you sure you want to hand this work back to the ${terminologyFromProfile(
                profile,
                'Student'
              )}?`}
              body={
                <ModalBody>
                  <p>
                    The <Term>Student</Term> will then be able to add additional
                    evidence and re-submit this <Term>Marksheet</Term>.
                  </p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>
                          Comment to <Term>Student</Term>
                        </strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.IN_PROGRESS,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.IN_PROGRESS }
                );
              }}
            />
          )}
      >
        Re-open
      </Button>
    );
  }

  private renderMarkedToAwaitingMarking() {
    const {
      task,
      openModal,
      closeModal,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    return (
      <Button
        className="error"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title={`Are you sure you want to hand this work back to the ${terminologyFromProfile(
                profile,
                'Teacher'
              )}?`}
              body={
                <ModalBody>
                  <p>
                    The <Term>Teacher</Term> will then be able to add additional
                    evidence and re-submit this <Term>Marksheet</Term>.
                  </p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>
                          Comment to <Term>Teacher</Term>
                        </strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.AWAITING_MARKING,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.AWAITING_MARKING }
                );
              }}
            />
          )}
      >
        Reject
      </Button>
    );
  }

  private renderPassedIQAToMarked() {
    const {
      task,
      openModal,
      closeModal,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    return (
      <Button
        className="error"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title={`Are you sure you want to hand this work back to the ${terminologyFromProfile(
                profile,
                'IQA'
              )}?`}
              body={
                <ModalBody>
                  <p>
                    The <Term>IQA</Term> will then be able to interact with and
                    and re-submit this <Term>Marksheet</Term>.
                  </p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>Comment to IQA</strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.MARKED,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.MARKED }
                );
              }}
            />
          )}
      >
        Reject
      </Button>
    );
  }

  private renderTransitionToEQAPassed() {
    const {
      task,
      openModal,
      closeModal,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    return (
      <Button
        className="tertiary"
        onClick={() =>
          openModal(
            <ConfirmModal
              closeModal={closeModal}
              title="Are you sure you want to approve this work?"
              body={
                <ModalBody>
                  <p>
                    You will not be able to make further changes. The{' '}
                    <Term>Student</Term>, <Term>Teacher(s)</Term> and{' '}
                    <Term>IQA(s)</Term> will also not be able to make further
                    changes.
                  </p>
                  {statusTransitionComment && (
                    <div className="margin-vertical-base">
                      <p>
                        <strong>Comment to IQA</strong>
                      </p>
                      <Well>
                        <p className="info">{statusTransitionComment}</p>
                      </Well>
                    </div>
                  )}
                </ModalBody>
              }
              okayLabel="Confirm"
              onCancelClick={closeModal}
              onOkayClick={() => {
                closeModal();
                this.props.patchItem(
                  'tasks',
                  task.id,
                  statusTransitionComment
                    ? {
                        status: STATUS.PASSED_EQA,
                        status_transition_comment: statusTransitionComment,
                      }
                    : { status: STATUS.PASSED_EQA }
                );
              }}
            />
          )}
      >
        Accept
      </Button>
    );
  }

  private renderHandInButtons() {
    const {
      task,
      isStudent,
      isTeacher,
      isIQA,
      isEQA,
      profile,
      statusTransitionComment,
    } = this.props;
    if (!task) {
      return;
    }

    const hasInfiniteAttempts = task.remaining_completion_attempts === INFINITE;
    const hasMoreAttempts =
      hasInfiniteAttempts || task.remaining_completion_attempts > 0;

    const shouldShowInProgressToAwaitingMarking =
      task.status === STATUS.IN_PROGRESS &&
      (isStudent || isTeacher) &&
      hasMoreAttempts;
    const shouldShowAwaitingMarkingToInProgress =
      task.status === STATUS.AWAITING_MARKING && isTeacher && hasMoreAttempts;
    const shouldShowAwaitingMarkingToMarked =
      (task.status === STATUS.AWAITING_MARKING ||
        task.status === STATUS.IN_PROGRESS) &&
      isTeacher;
    const shouldShowTransitionToPassedIQA =
      getIQATaskStatusPermission(task.status) && isIQA;
    const shouldShowMarkedToAwaitingMarking =
      task.status === STATUS.MARKED && isIQA;

    const shouldShowPassedIQAToMarked =
      task.status === STATUS.PASSED_IQA && isEQA;

    const shouldShowTransitionToEQAPassed =
      getEQATaskStatusPermission(task.status) && isEQA;

    if (
      !shouldShowInProgressToAwaitingMarking &&
      !shouldShowAwaitingMarkingToInProgress &&
      !shouldShowAwaitingMarkingToMarked &&
      !shouldShowTransitionToPassedIQA &&
      !shouldShowMarkedToAwaitingMarking &&
      !shouldShowPassedIQAToMarked &&
      !shouldShowTransitionToEQAPassed
    ) {
      return null;
    }

    // NOTE: not shown for shouldShowInProgressToAwaitingMarking
    const shouldShowStatusComment =
      shouldShowAwaitingMarkingToInProgress ||
      shouldShowAwaitingMarkingToMarked ||
      shouldShowTransitionToPassedIQA ||
      shouldShowMarkedToAwaitingMarking ||
      shouldShowPassedIQAToMarked ||
      shouldShowTransitionToEQAPassed;

    return (
      <div>
        {shouldShowStatusComment && (
          <Section>
            <StatusTransitionCommentBox status={task.status} />
          </Section>
        )}
        <Section>
          <SpacedGroup>
            {shouldShowInProgressToAwaitingMarking &&
              this.renderInProgressToAwaitingMarking()}
            {shouldShowAwaitingMarkingToInProgress &&
              this.renderAwaitingMarkingToInProgress()}
            {shouldShowAwaitingMarkingToMarked &&
              this.renderAwaitingMarkingToMarked()}
            {shouldShowTransitionToPassedIQA &&
              this.renderTransitionToPassedIQA()}
            {shouldShowMarkedToAwaitingMarking &&
              this.renderMarkedToAwaitingMarking()}
            {shouldShowPassedIQAToMarked && this.renderPassedIQAToMarked()}
            {shouldShowTransitionToEQAPassed &&
              this.renderTransitionToEQAPassed()}
          </SpacedGroup>
        </Section>
      </div>
    );
  }

  private onUntagAsset(id: string) {
    const { task } = this.props;

    if (task) {
      this.props
        .patchItem('tasks', task.id, {
          task_assets: task.task_assets.filter(each => each.asset !== id),
        })
        .then(() => this.props.resetRequestState(UPDATE_ITEM, 'tasks'));
    }
  }

  private loadComments() {
    this.props.getAllCollection(
      'tasks/task-status-change-comments',
      {
        filters: Map({
          task: this.props.params.id,
        }),
      },
      MARKSHEET_COMMENTS
    );
  }
}

function mapStateToProps(
  store: IStore,
  props: IRouteProps
): IPropsWithoutActions {
  const { collectionsOld, itemsOld, responses, taskDatesUpdated } = store;
  const journalEntries =
    getCollectionItems(collectionsOld.journals, MARKSHEET) ||
    List<IJournalEntry>();
  const checklists =
    getCollectionItems(
      collectionsOld.get('marksheets/checklists'),
      MARKSHEET
    ) || List<IChecklistRecord>();
  const selfMark = checklists.some(
    checklist => checklist.strand.learner_tagging
  );

  const assets =
    getCollectionItems(collectionsOld.assets, MARKSHEET) ||
    List<IAssetRecord>();

  const profile = store.profile;

  const task = itemsOld.get('tasks');
  const journalAssets = journalEntries.flatMap(entry => entry.assets);
  const isStudent =
    userHasRoles(profile, Set.of(STUDENT)) &&
    (!!task && narrowToRecord(task.user).id === profile.id);
  const isTeacher =
    !isStudent &&
    userHasRoles(profile, Set.of(TEACHER, CENTRE_ADMIN, DA_SUPPORT, DA_ADMIN));

  const isIQA = userHasRoles(profile, Set.of(INTERNAL_QUALITY_ASSURER));
  const isEQA = userHasRoles(profile, Set.of(EXTERNAL_QUALITY_ASSURER));

  const learningOutcomeBlockResults = getLearningOutcomeBlockResults(
    checklists
  );

  return {
    checklists,
    isStudent,
    isTeacher,
    isIQA,
    isEQA,
    journalEntries,
    assets,
    selfMark,
    learningOutcomeBlockResults,
    statusTransitionComment: getStatusTransitionCommentValue(store),
    studentAssets: assets.filter(
      asset =>
        task &&
        ((asset.created_by === narrowToRecord(task.user).id &&
          journalAssets.contains(asset.id)) ||
          task.task_assets.filter(
            each => each.asset === asset.id && each.tagger_role === STUDENT
          ).length)
    ),
    task,
    isArchivedGroup: task
      ? task.getIn(['class_task', 'task_class', 'is_archived'])
      : false,
    teacherAssets: assets.filter(
      asset =>
        task &&
        ((asset.created_by !== narrowToRecord(task.user).id &&
          journalAssets.contains(asset.id)) ||
          task.task_assets.filter(
            each => each.asset === asset.id && each.tagger_role === TEACHER
          ).length)
    ),
    iqaAssets: assets.filter(
      asset =>
        task &&
        task.task_assets.filter(
          each =>
            each.asset === asset.id &&
            each.tagger_role === INTERNAL_QUALITY_ASSURER
        ).length
    ),
    unit: itemsOld.get('courses/units'),
    profile,
    loading: anyPending(responses, [
      [UPDATE_ITEM, 'tasks'],
      [LOAD_ITEM, 'tasks'],
      [LOAD_ITEM, 'courses/units'],
      [LOAD_ITEM, 'marksheets/checklists'],
      ALLOW_ANOTHER_COMPLETION_ATTEMPT,
    ]),
    anotherAttemptAllowedSuccessfully: hasSucceeded(
      responses,
      ALLOW_ANOTHER_COMPLETION_ATTEMPT
    ),
    allowAnotherAttemptReason: formValueSelector(
      ALLOW_ANOTHER_ATTEMPT_FORM_NAME
    )(store, 'reason'),
    succeeded: hasSucceeded(responses, UPDATE_ITEM, 'tasks'),
    targetTaskStatus: store.targetTaskStatus,
    taskDatesUpdated,
    ...props,
  };
}

export default connect(mapStateToProps, {
  loadItem,
  getAllCollection,
  closeModal,
  openModal,
  patchItem,
  resetRequestState,
  resetTargetTaskStatus,
  setTargetTaskStatus,
  allowAnotherCompletionAttempt,
  clearTaskUpdateStatus,
})(Marksheet);
