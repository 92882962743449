import {
  Column,
  ContentBox,
  ContentBoxFooter,
  ContentBoxHeader,
  FormGroup,
  Row,
  Section,
  SpacedGroup,
  Well,
} from '@dabapps/roe';
import { Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { formValueSelector } from 'redux-form';
import { IStore } from '../../../store/';
import { ICourseworkProgressOverview } from '../../../store/data-types/tasks';
import SimpleTable, { IColumnData } from '../../tables/simple-table';
import { FORM_NAME } from './detail';
import { getCourseWorkProgressOverviewFor } from './utils';

import Term from '../../terminology';

const selector = formValueSelector(FORM_NAME);

interface IProps {
  selectedUsers: Set<ICourseworkProgressOverview>;
}

const HEADERS: Array<IColumnData<ICourseworkProgressOverview>> = [
  {
    headerLabel: 'Name',
    key: 'name',
    sortable: false,
    content: data => data.osms_data.name,
  },
];

export class CourseworkProgressOverviewSubmit2 extends React.Component<
  IProps,
  void
> {
  public render() {
    const { selectedUsers } = this.props;

    return (
      <ContentBox>
        <ContentBoxHeader>
          <h2 className="font-size-large">
            {getCourseWorkProgressOverviewFor(
              'Submitting Coursework',
              null,
              null
            )}
          </h2>
        </ContentBoxHeader>

        <h3>Step 2/2 - Confirm submission details</h3>

        <p>
          If using Named entries please enter the claim number from the online
          claim created in OCR Interchange and ensure the same{' '}
          <Term>Student</Term> units have been selected for submission as were
          included in the claim created in OCR Interchange.
        </p>

        <p>
          Please ensure the same <Term>Student</Term> units have been selected
          for submission as were included in the online claim created in OCR
          Interchange.
        </p>

        <p>
          Authentication of candidates’ work - internal (centre) assessors must
          be able to present upon request a written declaration authenticating
          the candidates' work and confirming they are satisfied the work
          produced is solely that of the candidate concerned and that all OCR
          procedures have been followed.
        </p>

        <Section>
          <Row>
            <Column>
              <SimpleTable items={selectedUsers.toList()} headers={HEADERS} />
            </Column>
          </Row>
        </Section>

        <div className="padding-vertical-large">
          <Well>
            <FormGroup>
              <input type="checkbox" />
              <label width="auto">
                By checking this box the centre representative is confirming the
                following:
              </label>
            </FormGroup>

            <ul>
              <li>
                A signed candidate authentication statement is held in the
                centre authenticating the work of each candidate for whom work
                is submitted
              </li>
              <li>
                Candidates' work was conducted and assessed under the conditions
                as laid dovifn in the specification and OCR requirements
              </li>
              <li>Internal standardisation has been carried out</li>
              <li>
                All grades (or marks where appropriate) have been correctly
                input onto the claim submitted on OCR Interchange
              </li>
              <li>
                Details of the assessors who have assessed the work are
                correctly input onto the claim
              </li>
            </ul>
          </Well>

          <Row>
            <Column sm={6} md={4} lg={3}>
              <FormGroup block>
                <label>MAPS3 Password</label>
                <input type="password" />
              </FormGroup>
            </Column>
            <Column sm={6} md={4} lg={3}>
              <FormGroup block>
                <label>MAPS3 Claims Number</label>
                <input type="text" />
              </FormGroup>
            </Column>
          </Row>
        </div>

        <ContentBoxFooter className="text-align-left">
          <p>
            <SpacedGroup>
              <Link to="/tasks/overview/submit/" className="button tertiary">
                Submit to awarding body
              </Link>
              <Link to="/tasks/overview/" className="button">
                Cancel
              </Link>
            </SpacedGroup>
          </p>
          <p className="info">
            Submitting work for a <Term>Student</Term> goes to the awarding body
            for assessment.
          </p>
        </ContentBoxFooter>
      </ContentBox>
    );
  }
}

function mapStateToProps(state: IStore) {
  const selectedUsers =
    selector(state, 'selectedUsers') || Set<ICourseworkProgressOverview>();

  return {
    selectedUsers,
  };
}

export default connect(mapStateToProps)(CourseworkProgressOverviewSubmit2);
