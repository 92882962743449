import {
  Collection,
  CollectionOptions,
} from '@dabapps/redux-api-collections/dist/collections';
import { IdKeyed } from '@dabapps/redux-api-collections/dist/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'underscore';

import { clearUIState, setUIState } from '../../../actions';
import { REQUEST_RATE_MS } from '../../../constants';
import { IStore } from '../../../store';
import CollectionInputFilterBase, { IBaseProps, uiKeyName } from './base';

interface IProps<T extends IdKeyed> extends IBaseProps {
  collection: Collection<T>;
  getCollection(options: CollectionOptions): void;
}

class ModernCollectionInputFilter<
  T extends IdKeyed
> extends React.PureComponent<IProps<T>, void> {
  public render() {
    const props = this.props;
    return (
      <CollectionInputFilterBase
        {...props}
        changeFilters={_.debounce(
          this.changeFilters.bind(this),
          REQUEST_RATE_MS
        )}
      />
    );
  }

  public changeFilters(newValue: string) {
    const filters = this.props.collection.filters || {};
    const options = {
      filters: {
        ...filters,
        [this.props.filterKey]: newValue,
      },
      page: this.props.collection.page,
      pageSize: this.props.pageSize,
    };

    this.props.getCollection(options);
  }
}

function mapStateToProps<T extends IdKeyed>(
  { uiState }: IStore,
  props: Partial<IProps<T>>
) {
  return {
    uiValue: uiState.get(uiKeyName(props)) || '',
  };
}

export default connect(mapStateToProps, {
  clearUIState,
  setUIState,
})(ModernCollectionInputFilter);
