import { recordOrId, SimpleRecord } from '@dabapps/simple-records';
import { List, Record } from 'immutable';
import * as moment from 'moment';
import {
  // tslint:disable-next-line:no-unused-variable
  IOption,
  IOptionRecord,
  OptionRecord,
} from '.';
import {
  // tslint:disable-next-line:no-unused-variable
  IProfile,
  ProfileRecord,
} from './profile';
import { RecordInstance } from './records';

export interface IQualification {
  id: string;
  name: string;
  description?: string;
  start_date: moment.Moment | null;
  end_date: moment.Moment | null;
  option: IOptionRecord | string;
  external_quality_assurers?: List<IProfile>;
}
export type IQualificationRecord = RecordInstance<IQualification>;
export const QualificationRecordInner = Record<IQualification>({
  end_date: null,
  id: '',
  name: '',
  start_date: null,
  external_quality_assurers: undefined,
  option: OptionRecord({}),
});

export function QualificationRecord(
  input: Partial<IQualification>
): IQualificationRecord {
  const item = QualificationRecordInner(input); // Workaround: the record provides default values
  const option = input.option
    ? recordOrId<IOption, IOptionRecord>(input.option, OptionRecord)
    : OptionRecord({});
  return item
    .set('start_date', input.start_date ? moment.utc(input.start_date) : null)
    .set('end_date', input.end_date ? moment.utc(input.end_date) : null)
    .set(
      'external_quality_assurers',
      input.external_quality_assurers &&
        List<IProfile>(input.external_quality_assurers.map(ProfileRecord))
    )
    .set('option', option);
}

export type IDashboardQualificationOption = Readonly<{
  id: string;
  name: string;
  is_filtered_student: boolean;
  option_complex_roc: boolean;
}>;
export const DashboardQualificationOption = SimpleRecord<
  IDashboardQualificationOption
>({
  id: '',
  name: '',
  is_filtered_student: false,
  option_complex_roc: false,
});
