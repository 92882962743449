import * as React from 'react';

import {
  Button,
  ModalFooter,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List, Map, Set } from 'immutable';
import * as moment from 'moment';
import { connect } from 'react-redux';
import {
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import { IStore } from '../../../store';
import { ISimpleUser } from '../../../store/data-types/profile';
import { IUnitAssigneeRecord } from '../../../store/data-types/tasks';
import { FormErrors } from '../../../utils';
import DateInput from '../../date-input';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import GroupField from '../../forms/group-field';
import Loading from '../../loading';
import UnitWithoutDatesWarning from '../unit-without-dates-warning';

interface IUnitAssigneesField extends WrappedFieldProps<IStore> {
  students: ReadonlyArray<ISimpleUser>;
  loading: boolean;
}
export function UnitAssigneesField({
  input,
  students,
  loading,
}: IUnitAssigneesField) {
  const value: Set<string> = (input && input.value) || Set<string>();
  const onChange = input ? input.onChange : () => null;
  return (
    <Row>
      <Table fill>
        <TableHead>
          <TableRow>
            <TableHeader>Students</TableHeader>
            <TableHeader>Assigned</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map(student => {
            const isAssigned = value.contains(student.id);
            return (
              <TableRow key={student.id}>
                <TableCell>
                  {student.osms_data.name} ({student.osms_data.username})
                </TableCell>
                <TableCell>
                  <input
                    checked={value.contains(student.id)}
                    type="checkbox"
                    disabled={loading}
                    onChange={event =>
                      onChange(
                        event.target.checked
                          ? value.add(student.id) as any
                          : value.remove(student.id) as any
                      )}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Row>
  );
}

const ASSIGN_UNIT_FORM = 'ASSIGN_UNIT_FORM';
interface IAssignUnitFormExternal extends React.HTMLProps<JSX.Element> {
  students: ReadonlyArray<ISimpleUser>;
  loading: boolean;
  formErrors: FormErrors;
  minStart: moment.Moment | null;
  maxEnd: moment.Moment | null;
}
interface IAssignUnitForm
  extends IAssignUnitFormExternal,
    FormProps<DataShape, void, void> {
  selected_start_date?: moment.Moment | null;
  selected_end_date?: moment.Moment | null;
}
export class AssignUnitForm extends React.PureComponent<IAssignUnitForm, void> {
  public render() {
    const {
      handleSubmit,
      loading,
      students,
      formErrors,
      minStart,
      maxEnd,
      selected_start_date,
      selected_end_date,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GroupField
          label="Start Date"
          name="start_date"
          type="text"
          formErrors={formErrors}
          component={field => (
            <DateInput {...field} minDate={minStart} maxDate={maxEnd} />
          )}
        />
        <GroupField
          label="End Date"
          name="end_date"
          type="text"
          formErrors={formErrors}
          component={field => (
            <DateInput {...field} minDate={minStart} maxDate={maxEnd} />
          )}
        />
        {(!selected_start_date || !selected_end_date) && (
          <UnitWithoutDatesWarning />
        )}
        <GroupField
          name="assignees"
          type="text"
          label=""
          formErrors={formErrors}
          component={field => (
            <UnitAssigneesField
              {...field}
              students={students}
              loading={loading}
            />
          )}
        />
        {loading && <Loading />}
        <FormErrorsRenderer formErrors={formErrors} errorKey="unit" />
        <FormErrorsRenderer formErrors={formErrors} errorKey="task_class" />
        <FormErrorsRenderer formErrors={formErrors} errorKey="qualification" />
        <FormErrorsRenderer
          formErrors={formErrors}
          errorKey="non_field_errors"
        />
        <ModalFooter>
          <Button
            disabled={loading}
            type="submit"
            className="margin-vertical-large"
          >
            Update
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

const Formified = reduxForm({ form: ASSIGN_UNIT_FORM })(AssignUnitForm);

const selector = formValueSelector(ASSIGN_UNIT_FORM);
function mapStateToProps(
  store: IStore,
  props: IAssignUnitFormExternal
): IAssignUnitForm {
  return {
    ...props,
    selected_start_date: selector(store, 'start_date'),
    selected_end_date: selector(store, 'end_date'),
  };
}

export default connect(mapStateToProps)(Formified);
