import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-api-collections/dist/requests';

export const HIJACK_ACCOUNT = makeAsyncActionSet('HIJACK_ACCOUNT');
export function hijackAccount(studentId: string, tag?: string) {
  return dispatchGenericRequest(
    HIJACK_ACCOUNT,
    `/api/users/hijack/${studentId}/`,
    'POST',
    {},
    tag
  );
}

export const UNHIJACK_ACCOUNT = makeAsyncActionSet('UNHIJACK_ACCOUNT');
export function unhijackAccount(tag?: string) {
  return dispatchGenericRequest(
    UNHIJACK_ACCOUNT,
    `/api/users/unhijack/`,
    'POST',
    {},
    tag
  );
}
