import { List } from 'immutable';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import {
  // tslint:disable-next-line:no-unused-variable
  IClassRecord,
} from '../../store/data-types/classes';

interface IClassSelectProps extends WrappedFieldProps<void> {
  classes: List<IClassRecord>;
  noClassLabel: string;
  disabled?: boolean;
}

export default function ClassSelect(props: IClassSelectProps) {
  return (
    <select {...props.input} disabled={props.disabled}>
      <option value="">{props.noClassLabel}</option>
      {props.classes.map(each => (
        <option key={each.id} value={each.id}>
          {each.name}
        </option>
      ))}
    </select>
  );
}
